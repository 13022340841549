import { Api } from '@monorepo/shared/apiClient';
import { ViewType } from 'mapistry-shared';
import { QueryConfig } from 'react-query';
import { useProjectViews } from './useProjectViews';

type UseViewsParams = Partial<Api.FetchProjectViewsParams> & {
  logId: string;
} & {
  config?: QueryConfig<Api.FetchProjectViewsResponse, Api.ErrorResponse>;
};

export const useLogViews = ({
  config,
  logId,
  organizationId,
  projectId,
}: UseViewsParams) => {
  const { projectViews, ...rest } = useProjectViews({
    config,
    organizationId,
    projectId,
  });

  const views = projectViews?.filter(
    (projectView) =>
      projectView.type === ViewType.LOG && projectView.originId === logId,
  );

  return { views, ...rest };
};
