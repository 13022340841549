import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateWorkflowCache,
  WorkflowCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import { setCache as setWorkflowCache } from '@monorepo/shared/hooks/workflows/useWorkflow';
import { MutationConfig, useMutation } from 'react-query';

type useWorkflowUpdateParams = {
  config?: MutationConfig<Api.UpdateWorkflowResponse, Api.ErrorResponse>;
};

export const useWorkflowUpdate = (params?: useWorkflowUpdateParams) =>
  useMutation(Api.updateWorkflow, {
    ...params?.config,
    onSuccess: (updatedWorkflow, mutationFuncParams) => {
      const { organizationId } = mutationFuncParams;
      const keyParams = {
        organizationId,
        workflowId: updatedWorkflow.id,
      };

      // Set cache for that single Workflow
      setWorkflowCache(keyParams, updatedWorkflow);

      // Invalidate any RQ cache that might show a list of existing Workflows
      invalidateWorkflowCache(WorkflowCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(updatedWorkflow, mutationFuncParams);
    },
  });
