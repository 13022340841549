import { Api } from '@monorepo/shared/apiClient';
import {
  LogCacheEvents,
  logCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';

import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFrequencyRequirementCacheEvents,
  logFrequencyRequirementCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFrequencyRequirementCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { DateLike } from 'mapistry-shared';
import { QueryConfig, queryCache, useQuery } from 'react-query';

type KeyParams = Api.FetchLogEventsParams;
const KEY_START = 'logEvents';

export const createQueryKey = ({
  endDate,
  logId,
  projectId,
  startDate,
}: KeyParams) => [KEY_START, logId, projectId, { startDate, endDate }] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogEvents
>;

type UseLogEventsParams = Partial<Api.FetchLogEventsParams> & {
  config?: QueryConfig<Api.FetchLogEventsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  logId: string,
  projectId: string,
  { startDate, endDate }: { startDate: DateLike; endDate: DateLike },
) => Api.fetchLogEvents({ endDate, logId, projectId, startDate });

export const useLogEvents = ({
  config: inputConfig,
  endDate,
  logId,
  projectId,
  startDate,
}: UseLogEventsParams) => {
  const isEnabled = !!endDate && !!logId && !!projectId && !!startDate;
  const config = getQueryConfig<Api.FetchLogEventsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({ endDate, logId, projectId, startDate })
    : undefined;
  const { data: logEvents, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    logEvents,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useLogEvents>['logEvents'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<
  KeyParams,
  'endDate' | 'projectId' | 'startDate'
> & {
  endDate?: DateLike;
  projectId?: string;
  startDate?: DateLike;
};

// allow invalidating all logEvent lists (across projects) for a log
export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.logId,
      keyParams.projectId,
      { endDate: keyParams.endDate, startDate: keyParams.startDate },
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useLogEvents',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logCacheRegister(
  [LogCacheEvents.CREATE, LogCacheEvents.UPDATE, LogCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

logFrequencyRequirementCacheRegister(
  [
    LogFrequencyRequirementCacheEvents.CREATE,
    LogFrequencyRequirementCacheEvents.UPDATE,
    LogFrequencyRequirementCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);
