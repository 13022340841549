import { format, subMilliseconds } from 'date-fns';
import { DateRange, localEquivalentOfUTC } from 'mapistry-shared';
import React from 'react';
import { NoWrap } from './styled';

export const DateRangeTableCell = ({
  value: { begin, end },
}: {
  value: DateRange;
}) => (
  <NoWrap>{`${format(
    localEquivalentOfUTC(new Date(begin)),
    'MM/dd/yyyy',
  )} - ${format(
    localEquivalentOfUTC(subMilliseconds(new Date(end), 1)),
    'MM/dd/yyyy',
  )}`}</NoWrap>
);
