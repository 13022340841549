import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import styled from 'styled-components';
import { LeftSide, StyledToolbar, Title } from './styled';

const OrangeErrorIcon = styled(ErrorIcon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.orange};
`;

export const QueryStepInvalidToolbar = () => (
  <StyledToolbar
    leftActions={
      <LeftSide>
        <Title>Add Query Step:</Title>
        <OrangeErrorIcon aria-hidden />
        Invalid final query step. Remove the last step and continue building
        your View.
      </LeftSide>
    }
    position="relative"
  />
);
