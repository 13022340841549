import { formatUnitAwareNumber } from '@monorepo/shared/utils/formatUnitAwareNumber';
import { format } from 'date-fns';
import {
  FieldDataType,
  localEquivalentOfUTC,
  SpecificFieldValue,
} from 'mapistry-shared';
import React from 'react';
import { fieldValueFactory } from './fieldValueFactory';
import { formulaDisplayFactory } from './formulaDisplayFactory';

interface FieldValueDisplayFactoryParams {
  fieldType: FieldDataType;
  fieldValue: SpecificFieldValue;
}

export const fieldValueDisplayFactory = ({
  fieldType,
  fieldValue,
}: FieldValueDisplayFactoryParams) => {
  const defaultResult = '';
  if (fieldValue.value == null) {
    return defaultResult;
  }
  switch (fieldType) {
    case FieldDataType.BOOLEAN: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value ? 'Yes' : 'No';
    }
    case FieldDataType.DATE: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value
        ? format(localEquivalentOfUTC(new Date(value)), 'P')
        : defaultResult;
    }
    case FieldDataType.DATETIME: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value
        ? format(localEquivalentOfUTC(new Date(value)), 'Pp')
        : defaultResult;
    }
    case FieldDataType.FORMULA: {
      const formulaField = fieldValueFactory({
        fieldValue,
        fieldType,
      });
      return formulaField ? formulaDisplayFactory(formulaField) : defaultResult;
    }
    case FieldDataType.LOCATION: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value?.name || defaultResult;
    }
    case FieldDataType.NUMERIC: {
      const { givenUnits, value } =
        fieldValueFactory({ fieldValue, fieldType }) || {};
      if (value == null) {
        return defaultResult;
      }
      return formatUnitAwareNumber(value, givenUnits);
    }
    case FieldDataType.PARAMETER: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value?.description || defaultResult;
    }
    case FieldDataType.RESOURCE: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value ? value.name : defaultResult;
    }
    case FieldDataType.SIGNATURE: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value?.signature ? (
        <img
          alt={`${value.name || value.email}'s signature`}
          src={value.signature}
        />
      ) : (
        defaultResult
      );
    }
    case FieldDataType.SINGLE_SELECT: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value || defaultResult;
    }
    case FieldDataType.SUBSTANCE: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value?.substanceName || defaultResult;
    }
    case FieldDataType.TEXT: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value || defaultResult;
    }
    case FieldDataType.TIME: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value || defaultResult;
    }
    case FieldDataType.USER: {
      const { value } = fieldValueFactory({ fieldValue, fieldType }) || {};
      return value?.name || value?.email || defaultResult;
    }
    default: {
      const exhaustiveCheck: never = fieldType;
      throw new Error(`Unhandled field data type - ${exhaustiveCheck}`);
    }
  }
};
