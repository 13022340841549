import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { StartDate } from '@monorepo/shared/componentsV2/styled/header';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import {
  useLogEntryFields,
  useLogFormulaFields,
} from '@monorepo/shared/hooks/logs/useLogFields';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { format } from 'date-fns';
import { getCategoryDisplayString } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { LogDateFieldPreview } from '../LogDateFieldPreview';
import {
  BackButton,
  ButtonContainer,
  ComplianceContainer,
  ComplianceInput,
  Container,
  FieldContainer,
  FieldSection,
  FieldsSectionLabel,
  InstructionsContainer,
  StyledMain,
} from './styled';

export function LogDetail() {
  const { logId, organizationId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const navigate = useNavigate();
  const { log, isLoading } = useLog({ logId, organizationId });
  const { logFields: logEntryFields } = useLogEntryFields({
    logId,
    organizationId,
  });
  const { logFields: logFormulaFields } = useLogFormulaFields({
    logId,
    organizationId,
  });

  const handleBack = useCallback(() => navigate('../..'), [navigate]);
  const handleEdit = useCallback(() => navigate('edit'), [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  // Should never happen, a contract with the BE is that it either returns a value or 404
  if (!log) {
    return null;
  }

  return (
    <StyledMain>
      <Header
        centerActions={
          <StartDate>
            {`Created ${format(new Date(log.createdAt), 'MM/dd/yy')}`}
          </StartDate>
        }
        leftActions={
          <BackButton onClick={handleBack}>
            <VisuallyHidden>Back</VisuallyHidden>
            <ArrowBackIcon />
          </BackButton>
        }
        rightActions={
          canEditOrgSettings ? (
            <ButtonContainer>
              <Button color="primary" onClick={handleEdit} variant="contained">
                Edit Log
              </Button>
            </ButtonContainer>
          ) : null
        }
        title={log.name}
      />
      <Container>
        <ComplianceContainer>
          <ComplianceInput
            label="Compliance Area"
            name="category"
            readOnly
            value={getCategoryDisplayString(log.category)}
          />
        </ComplianceContainer>
        <FieldSection>
          <FieldsSectionLabel>Log Fields</FieldsSectionLabel>
          {log.instructions && (
            <InstructionsContainer>{log.instructions}</InstructionsContainer>
          )}
          <FieldContainer>
            <LogDateFieldPreview key="log-date" />
            {logEntryFields?.map((entryField) => (
              <FieldTypePreviewFactory
                field={entryField}
                key={entryField.name}
              />
            ))}
          </FieldContainer>
        </FieldSection>
        {!!logFormulaFields?.length && (
          <FieldSection>
            <FieldsSectionLabel>Formulas</FieldsSectionLabel>
            <FieldContainer>
              {logFormulaFields?.map((formulaField) => (
                <FieldTypePreviewFactory
                  field={formulaField}
                  key={formulaField.name}
                />
              ))}
            </FieldContainer>
          </FieldSection>
        )}
      </Container>
    </StyledMain>
  );
}
