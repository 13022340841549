import { Api } from '@monorepo/shared/apiClient';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { FieldDataType, FormulaFieldResponse } from 'mapistry-shared';
import { useMemo } from 'react';
import { QueryConfig, queryCache, useQuery } from 'react-query';

type KeyParams = Api.FetchLogFieldsParams;

const KEY_START = 'logFields';
export const createQueryKey = ({ logId, organizationId }: KeyParams) =>
  [KEY_START, organizationId, logId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogFields
>;

type UseLogFieldsParams = Partial<Api.FetchLogFieldsParams> & {
  config?: QueryConfig<Api.FetchLogFieldsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (_: string, organizationId: string, logId: string) =>
  Api.fetchLogFields({ logId, organizationId });

export const useLogFields = ({
  config: inputConfig,
  logId,
  organizationId,
}: UseLogFieldsParams) => {
  const isEnabled = !!organizationId && !!logId;
  const config = getQueryConfig<Api.FetchLogFieldsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled ? createQueryKey({ logId, organizationId }) : undefined;
  const { data: logFields, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    logFields,
    ...queryInfo,
  };
};

export const useLogEntryFields = (params: UseLogFieldsParams) => {
  const { logFields, ...queryInfo } = useLogFields(params);

  const filteredLogFields = useMemo(
    () => logFields?.filter((field) => field.type !== FieldDataType.FORMULA),
    [logFields],
  );

  return { logFields: filteredLogFields, ...queryInfo };
};

export const useLogFormulaFields = (params: UseLogFieldsParams) => {
  const { logFields, ...queryInfo } = useLogFields(params);

  const filteredLogFields = useMemo<FormulaFieldResponse[] | undefined>(
    () =>
      logFields?.filter<FormulaFieldResponse>(
        (field): field is FormulaFieldResponse =>
          field.type === FieldDataType.FORMULA,
      ),
    [logFields],
  );

  return { logFields: filteredLogFields, ...queryInfo };
};

type QueryCache = ReturnType<typeof useLogFields>['logFields'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

type InvalidateCacheParams = Omit<KeyParams, 'logId'> & {
  logId?: KeyParams['logId'];
};

async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries([
    KEY_START,
    keyParams.organizationId,
    ...(keyParams.logId ? [keyParams.logId] : []),
  ]);
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

resourcePropertyCacheRegister([ResourcePropertyCacheEvents.UPDATE], {
  hookName: 'useLogFields',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
});

logFieldCacheRegister([LogFieldCacheEvents.UPDATE], {
  hookName: 'useLogFields',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
});
