import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { ButtonLink } from '@monorepo/shared/componentsV2/Link';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import {
  FrequencyRequirementFilterDisplay,
  getFilterAsText,
} from '@monorepo/shared/componentsV2/logs/FrequencyRequirementFilterDisplay';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { useFrequencyRequirements } from '@monorepo/shared/hooks/logs/frequencyRequirements/useFrequencyRequirements';
import { useLogEntryFields } from '@monorepo/shared/hooks/logs/useLogFields';
import {
  useHasOrgUpdatePermissionOrSuperAdmin,
  useHasProjectSettingsPermissions,
} from '@monorepo/shared/hooks/permissions/useHasPermissions';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import { linkTarget } from '@monorepo/shared/utils/linkTarget';
import AddIcon from '@svg/add.svg';
import { format } from 'date-fns';
import {
  CustomFrequencyMessage,
  FieldResponse,
  FrequencyRequirementResponse,
  FrequencyResponse,
  IntervalFrequencyFactory,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useHref, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { LogFrequencyRequirementActions } from './LogFrequencyRequirementActions';

const StyledMain = styled(Main)`
  ${verticalLayout}
`;

const StyledToolbar = styled(Toolbar)`
  & .MuiToolbar-root {
    padding-right: 1rem;
    padding-left: 1.5rem;
  }
`;

const H2 = styled.h2`
  ${header4}
  color: ${({ theme }) => theme.colors.gray333};
`;

const StyledTable = styled(Table)`
  ${fillHeightAndScrollable}
  padding-bottom: 4rem;
` as typeof Table;

const FilterDisplay = styled(FrequencyRequirementFilterDisplay)`
  padding: 0.25rem 0;
`;

function getFrequencyForDisplay(frequency: FrequencyResponse): string {
  return frequency.customFrequency
    ? new CustomFrequencyMessage(
        frequency.intervalFrequency,
        frequency.customFrequency,
      ).getMessage({ messageStart: '', omitPeriodPunctuation: true })
    : IntervalFrequencyFactory.For(
        frequency.intervalFrequency,
        frequency.customFrequency,
      ).toString();
}

function getRequirementAltText(
  frequencyRequirement: FrequencyRequirementResponse,
  logFields?: FieldResponse[],
): string {
  const filtersText =
    frequencyRequirement.filters
      .map((filter) => getFilterAsText(filter, logFields))
      .join(' and ') || '';
  return `Action menu for ${getFrequencyForDisplay(
    frequencyRequirement.frequency,
  )} logging ${filtersText}`;
}

export function LogFrequencyRequirementsTable({
  onEditEntry,
}: {
  onEditEntry: (frequency?: FrequencyRequirementResponse) => void;
}) {
  const { logId, organizationId, projectId } = useParams();
  if (!logId || !organizationId || !projectId) {
    throw new Error(
      'Frequency requirements table opened without the context of an organization, project, or log',
    );
  }

  const { logFields, isLoading: logFieldsIsLoading } = useLogEntryFields({
    logId,
    organizationId,
  });

  const { frequencyRequirements, isLoading: frequencyRequirementsIsLoading } =
    useFrequencyRequirements({ organizationId, logId, projectId });

  const canEditLogSiteSettings = useHasProjectSettingsPermissions(projectId);
  const handleAddFrequency = useCallback(() => onEditEntry(), [onEditEntry]);

  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const currentPath = useHref('.');
  const pathToOrgSettings = useMemo(() => {
    const orgPath = currentPath.split('/sites/')[0];
    return `${orgPath}/data-setup/logs/${logId}/edit/sites`;
  }, [currentPath, logId]);

  const tableColumns = useMemo(() => {
    const columns: TableColumn<FrequencyRequirementResponse>[] = [
      {
        id: 'frequency',
        header: 'Frequency',
        contents: ({ frequency }) => getFrequencyForDisplay(frequency),
      },
      {
        id: 'condition',
        header: 'Condition',
        // eslint-disable-next-line react/no-unstable-nested-components
        contents: (row) => (
          <div>
            {row.filters.map((filter) => (
              <FilterDisplay
                key={filter.id}
                filter={filter}
                logFields={logFields}
              />
            ))}
          </div>
        ),
      },
      {
        id: 'startDate',
        header: 'Start Date',
        contents: (row) =>
          format(localEquivalentOfUTC(new Date(row.frequency.startDate)), 'P'),
      },
    ];
    if (canEditLogSiteSettings) {
      columns.push({
        id: 'actions',
        header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        contents: (row) => (
          <LogFrequencyRequirementActions
            altText={getRequirementAltText(row, logFields)}
            frequencyRequirement={row}
            logId={logId}
            onEditEntry={onEditEntry}
            organizationId={organizationId}
            projectId={projectId}
          />
        ),
        align: 'center',
      });
    }
    return columns;
  }, [
    canEditLogSiteSettings,
    logFields,
    logId,
    onEditEntry,
    organizationId,
    projectId,
  ]);

  if (frequencyRequirementsIsLoading || logFieldsIsLoading) {
    return <Loading />;
  }

  return (
    <StyledMain>
      {frequencyRequirements?.length ? (
        <>
          <StyledToolbar
            variant="noDropShadow"
            leftActions={<H2>All Frequency Requirements</H2>}
            rightActions={
              canEditLogSiteSettings && (
                <ButtonsGroup>
                  {canEditOrgSettings && (
                    <ButtonLink
                      color="primary"
                      target={linkTarget.NEW_BROWSER_TAB}
                      to={pathToOrgSettings}
                      variant="text"
                    >
                      View all sites
                    </ButtonLink>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddFrequency}
                  >
                    Add frequency
                  </Button>
                </ButtonsGroup>
              )
            }
            size="small"
          />
          <StyledTable
            ariaLabel="Frequency requirements"
            rows={frequencyRequirements}
            columns={tableColumns}
          />
        </>
      ) : (
        <EmptyState
          primaryText="No frequency requirements set"
          secondaryText="Frequency requirements define how often a Log at this site needs to be submitted."
          actionButtonConfigs={
            canEditLogSiteSettings
              ? [
                  {
                    label: 'Add frequency',
                    onClick: handleAddFrequency,
                    icon: <AddIcon />,
                  },
                ]
              : []
          }
        />
      )}
    </StyledMain>
  );
}
