import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';
import { UseGenericLogDataInputProps } from './types';

export const createQueryKey = ({
  logProjectId,
  projectId,
}: Api.FetchFactorsProps) => ['FACTORS', projectId, logProjectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchFactors
>;

const fetcher: Fetcher = (
  _: string,
  projectId?: string,
  logProjectId?: string,
) => Api.fetchFactors({ logProjectId, projectId });

export const useFactors = ({
  config: inputConfig,
  logProjectId,
  projectId,
}: UseGenericLogDataInputProps<Api.FetchFactorsProps, Api.Factor[]>) => {
  const config = {
    ...inputConfig,
    enabled: logProjectId && projectId,
  };
  const key = createQueryKey({ logProjectId, projectId });
  const { data: factors, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    factors,
    ...queryInfo,
  };
};
