import { Loading } from '@monorepo/shared/components';
import { List } from '@monorepo/shared/componentsV2/List';
import { TextBlock } from '@monorepo/shared/componentsV2/TextBlock';
import {
  Content,
  Page,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { useWorkflows } from '@monorepo/shared/hooks/workflows/useWorkflows';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import { EmptyList } from '@monorepo/workflowDataSetup/src/components/WorkflowListPage/EmptyList';
import { WorkflowCard } from '@monorepo/workflowDataSetup/src/components/WorkflowListPage/WorkflowCard';
import { WorkflowListHeader } from '@monorepo/workflowDataSetup/src/components/WorkflowListPage/WorkflowListHeader';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { RouteEditMode } from '../types';

const ListItem = styled.li`
  margin-bottom: 1.2rem;
`;

export function WorkflowListPage() {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error(
      "WorkflowListPage page was rendered with url that doesn't have organizationId",
    );
  }

  const { workflows, isLoading } = useWorkflows({ organizationId });
  const [filterBy, setFilterBy] = useState('');

  const sortedWorkflows = useMemo(
    () =>
      workflows
        ? workflows.slice().sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [workflows],
  );
  const filteredWorkflows = useMemo(
    () =>
      sortedWorkflows.filter((w) =>
        lookFor(filterBy, w, ['name', 'description']),
      ),
    [filterBy, sortedWorkflows],
  );

  const navigate = useNavigate();
  const handleAddNew = useCallback(() => {
    navigate(RouteEditMode.NEW);
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  const hasWorkflows = !!workflows?.length;

  return (
    <Page>
      <WorkflowListHeader
        onAddNew={handleAddNew}
        onSearchChange={
          hasWorkflows ? (event) => setFilterBy(event.target.value) : undefined
        }
        organizationId={organizationId}
      />
      <Content>
        {hasWorkflows ? (
          <List>
            {filteredWorkflows.map((workflow) => (
              <ListItem key={workflow.id}>
                <WorkflowCard workflow={workflow} />
              </ListItem>
            ))}
            {!filteredWorkflows.length && (
              <TextBlock
                primaryText="No results found."
                secondaryText="We couldn’t find what you are looking for. Try searching again using different keywords."
              />
            )}
          </List>
        ) : (
          <EmptyList onAddNew={handleAddNew} organizationId={organizationId} />
        )}
      </Content>
    </Page>
  );
}
