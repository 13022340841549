import DuplicableWidget from '@monorepo/old-web/js/components/widgets/DuplicableWidget';
import React from 'react';
import { LimitMonitoringCard } from './LimitMonitoringCard';

type LimitMonitoringWidgetProps = {
  filterQuery: string;
  logProjectId: string;
  projectId: string;
  widgetDefaultName: string;
};

const LIMIT_MONITOR_WIDGET_SETTINGS_VERSION = '1';

export const LimitMonitoringWidget = ({
  filterQuery,
  logProjectId,
  projectId,
  widgetDefaultName,
}: LimitMonitoringWidgetProps) => (
  <DuplicableWidget
    filterQuery={filterQuery}
    widgetDefaultName={widgetDefaultName}
    widgetType={`limit-monitoring-${logProjectId}`}
    widgetSettingsVersion={LIMIT_MONITOR_WIDGET_SETTINGS_VERSION}
  >
    <LimitMonitoringCard
      logProjectId={logProjectId}
      projectId={projectId}
      widgetDefaultName={widgetDefaultName}
    />
  </DuplicableWidget>
);
