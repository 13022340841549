import {
  WizardHeader,
  WizardLink,
} from '@monorepo/shared/componentsV2/fullPageWizard/WizardHeader';
import { NonEmptyArray } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { Mode } from './types';

export enum WizardStep {
  DETAILS = 'Basic Info',
  FIELDS = 'Entry Fields',
  FORMULAS = 'Formula Fields',
  SITES = 'Sites',
}

// the last bit of the URL each one of these steps is found on
export const WizardStepUrlPart: Record<WizardStep, string> = {
  [WizardStep.DETAILS]: '',
  [WizardStep.FIELDS]: 'fields',
  [WizardStep.FORMULAS]: 'formulas',
  [WizardStep.SITES]: 'sites',
};

interface EditLogHeaderProps {
  canSubmit?: boolean;
  isSubmitting?: boolean;
  onSubmit?: () => unknown;
  wizardStep: WizardStep;
}

export function EditLogHeader({
  canSubmit,
  isSubmitting = false,
  onSubmit,
  wizardStep,
}: EditLogHeaderProps) {
  const { mode = Mode.new, logId } = useParams();
  const isEditMode = mode === Mode.edit;
  const logExists = !!logId;

  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    if (logExists) {
      switch (wizardStep) {
        case WizardStep.DETAILS:
          navigate('../../..');
          return;
        case WizardStep.FIELDS:
        case WizardStep.FORMULAS:
        case WizardStep.SITES:
          navigate('../../../..');
          return;
        default: {
          const exhaustiveCheck: never = wizardStep;
          throw new Error(`Invalid wizard step - ${exhaustiveCheck}`);
        }
      }
    }
    navigate('../..');
  }, [logExists, navigate, wizardStep]);

  const breadcrumbsLinks = useMemo<NonEmptyArray<WizardLink>>(() => {
    const isFirstStep = wizardStep === WizardStep.DETAILS;
    function getLinkTo(stepToGoTo: WizardStep): string {
      // Current wizard stepToGoTo is a link to itself
      if (stepToGoTo === wizardStep) return '.';

      const link = WizardStepUrlPart[stepToGoTo];
      const navigateUpAndApplyLink = link ? `../${link}` : '..';
      return isFirstStep ? link : navigateUpAndApplyLink;
    }

    const fieldsAreNotReady =
      !isEditMode &&
      (wizardStep === WizardStep.DETAILS ||
        (wizardStep === WizardStep.FIELDS && !canSubmit));
    return [
      {
        completed: logExists,
        label: WizardStep.DETAILS,
        to: getLinkTo(WizardStep.DETAILS),
      },
      {
        completed: [WizardStep.FORMULAS, WizardStep.SITES].includes(wizardStep),
        disabled: !logExists,
        label: WizardStep.FIELDS,
        to: getLinkTo(WizardStep.FIELDS),
      },
      {
        completed: [WizardStep.SITES].includes(wizardStep),
        disabled: !logExists || fieldsAreNotReady,
        label: WizardStep.FORMULAS,
        to: getLinkTo(WizardStep.FORMULAS),
      },
      {
        disabled: !logExists || fieldsAreNotReady,
        label: WizardStep.SITES,
        to: getLinkTo(WizardStep.SITES),
      },
    ];
  }, [canSubmit, isEditMode, logExists, wizardStep]);

  return (
    <WizardHeader
      canSubmit={canSubmit}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      links={breadcrumbsLinks}
      onClose={handleClose}
      onSubmit={onSubmit}
      title={isEditMode ? 'Edit Log' : 'New Log'}
      wizardStep={wizardStep}
    />
  );
}
