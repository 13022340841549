import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '../../cacheRegisters/data2/workflowCacheRegister';
import { getQueryConfig } from '../../utils/queryUtils';

type KeyParams = Api.FetchWorkflowListParams;
export const createKey = ({ organizationId }: KeyParams) =>
  ['org-workflow-list', organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchWorkflowList
>;

const fetcher: Fetcher = (_, organizationId) =>
  Api.fetchWorkflowList({ organizationId });

type UseWorkflowsParams = Partial<Api.FetchWorkflowListParams> & {
  config?: QueryConfig<Api.FetchWorkflowListResponse, Api.ErrorResponse>;
};

export function useWorkflows({
  config: inputConfig,
  organizationId,
}: UseWorkflowsParams) {
  const isEnabled = !!organizationId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId }) : undefined;
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    workflows: data,
  };
}

type QueryCache = ReturnType<typeof useWorkflows>['workflows'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

const cacheRegisterInvalidator = {
  hookName: 'useWorkflows',
  callback: (keyParams: KeyParams) => invalidateCache(keyParams),
};

workflowCacheRegister(
  [WorkflowCacheEvents.CREATE, WorkflowCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);
