import { header4 } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';
import { NavigationTabs } from './NavigationTabs';

const tabMinHeight = '2.25rem';

const StyledTabs = styled(NavigationTabs)`
  min-height: ${tabMinHeight};

  & .MuiTabs-indicator {
    display: block;
    background-color: ${({ theme }) => theme.colors.gray333};
  }

  & .MuiTab-root {
    ${header4}
    min-width: 10rem;
    min-height: ${tabMinHeight};
    padding: 0 1rem;
    color: ${({ theme }) => theme.colors.gray333};
    text-transform: none;

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.grayddd};
      border-radius: 4px;
      outline: none;
    }
  }
`;

type HorizontalNavigationTabsProps = Omit<
  React.ComponentProps<typeof NavigationTabs>,
  'orientation'
>;

export function HorizontalNavigationTabs(props: HorizontalNavigationTabsProps) {
  return <StyledTabs orientation="horizontal" {...props} />;
}
