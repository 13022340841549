import { Api } from '@monorepo/shared/apiClient';
import { RollingCalculation } from 'mapistry-shared';
import { useQuery } from 'react-query';
import { UseGenericLogDataInputProps } from './types';

export const createQueryKey = ({
  logProjectId,
  projectId,
}: Api.FetchRollingCalculationsProps) =>
  ['ROLLING_CALCULATIONS', projectId, logProjectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchRollingCalculations
>;

const fetcher: Fetcher = (
  _: string,
  projectId?: string,
  logProjectId?: string,
) => Api.fetchRollingCalculations({ logProjectId, projectId });

export const useRollingCalculations = ({
  config: inputConfig,
  logProjectId,
  projectId,
}: UseGenericLogDataInputProps<
  Api.FetchRollingCalculationsProps,
  RollingCalculation[]
>) => {
  const key = createQueryKey({ logProjectId, projectId });
  const config = {
    ...inputConfig,
    enabled: logProjectId && projectId,
  };
  const { data: rollingCalculations, ...queryInfo } = useQuery(
    key,
    fetcher,
    config,
  );
  return {
    rollingCalculations,
    ...queryInfo,
  };
};
