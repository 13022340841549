import { fetchCalendarSummaryAction } from '@monorepo/old-web/js/actions/calendar';
import { FormCalendar } from '@monorepo/old-web/js/components/propTypes';
import withProvider from '@monorepo/old-web/js/components/withProvider';
import APP from '@monorepo/old-web/js/config';
import { isNullOrUndefined } from '@monorepo/old-web/js/utils';
import { format } from 'date-fns';
import { CalendarName } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import BaseInspectionCalendarCard from '../BaseInspectionCalendarCard';

class AnnualEvaluationCalendarCard extends Component {
  constructor(props) {
    super(props);
    this.tooltipTitle = this.tooltipTitle.bind(this);
    this.state = {
      currentDate: new Date(),
    };
  }

  componentDidMount() {
    const { currentDate } = this.state;
    const { fetchSummary, projectId } = this.props;
    fetchSummary(
      projectId,
      CalendarName.STORMWATER_ANNUAL_EVALUATION,
      currentDate,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentDate } = this.state;
    const { fetchSummary, projectId } = this.props;
    if (prevState.currentDate.getTime() !== currentDate.getTime()) {
      fetchSummary(
        projectId,
        CalendarName.STORMWATER_ANNUAL_EVALUATION,
        currentDate,
      );
    }
  }

  tooltipTitle(events, tooMuchData, summary) {
    if (isNullOrUndefined(summary)) {
      return '';
    }
    const { routineInspections, locations } = summary;
    const { dueDate, startDate } = events[0];
    const headerDate =
      startDate.getFullYear() === dueDate.getFullYear()
        ? format(startDate, 'yyyy')
        : `${format(startDate, 'yyyy')} - ${format(dueDate, 'yyyy')} `;
    return (
      <>
        <div className="tooltip__header">{`${headerDate} Stormwater Summary`}</div>
        <div className="tooltip__content">
          <div className="tooltip__section">
            <div className="tooltip__section-header">Stormwater Samples</div>
            {locations.map((location) => (
              <div className="tooltip__section-content" key={location.id}>
                {`${location.completed} / ${location.total} samples collected at ${location.name}`}
              </div>
            ))}
          </div>
          <div className="tooltip__section">
            <div className="tooltip__section-header">Routine Inspections</div>
            <div className="tooltip__section-content">
              {`${routineInspections.completed} / ${routineInspections.total} routine inspections completed`}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { calendar, organizationId, summary } = this.props;

    return (
      <BaseInspectionCalendarCard
        calendar={calendar}
        tooltipData={summary}
        tooltipTitle={this.tooltipTitle}
        onDateChange={(newDate) => this.setState({ currentDate: newDate })}
        organizationId={organizationId}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchSummary: (projectId, calendarName, date) =>
    dispatch(
      fetchCalendarSummaryAction({
        projectId,
        calendarName,
        date,
      }),
    ),
});

const mapStateToProps = (state) => ({
  projectId: APP.projectId,
  summary: state.calendar.summary,
});

AnnualEvaluationCalendarCard.defaultProps = {
  organizationId: null,
  summary: null,
};

AnnualEvaluationCalendarCard.propTypes = {
  calendar: FormCalendar.isRequired,
  fetchSummary: PropTypes.func.isRequired,
  organizationId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        completed: PropTypes.number,
        total: PropTypes.number,
      }),
    ),
    routineInspections: PropTypes.shape({
      completed: PropTypes.number,
      total: PropTypes.number,
    }),
  }),
};

// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AnnualEvaluationCalendarCard),
);
