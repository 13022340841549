import React from 'react';
import { Field } from 'react-final-form';
import FieldWrapper from '../FieldWrapper';
import DatePicker from '../DatePicker';

type IntervalFieldSetProps = {
  names: [string, string];
  label: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function IntervalFieldSet(props: IntervalFieldSetProps) {
  const { names, label } = props;
  const [startName, endName] = names;

  return (
    <div className="field-wrapper">
      <FieldWrapper label={label}>
        <div className="filter-settings__datepicker-row">
          <Field name={startName}>
            {(field) => (
              <DatePicker
                autoOk
                className="date-field"
                clearable
                error={Boolean(field.meta.touched && field.meta.error)}
                maxDate={new Date()}
                onChange={(date) => field.input.onChange(date)}
                value={field.input.value}
              />
            )}
          </Field>
          <span className="text__spacer">to</span>
          <Field name={endName}>
            {(field) => (
              <DatePicker
                autoOk
                className="date-field"
                clearable
                error={Boolean(field.meta.touched && field.meta.error)}
                maxDate={new Date()}
                onChange={(date) => field.input.onChange(date)}
                value={field.input.value}
              />
            )}
          </Field>
        </div>
      </FieldWrapper>
    </div>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default IntervalFieldSet;
