import { Api } from '@monorepo/shared/apiClient';
import { isStringErrorResponse } from '@monorepo/shared/apiClient/httpClient';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useToast } from '@monorepo/shared/contexts';
import * as Sentry from '@sentry/browser';
import { differenceInCalendarDays } from 'date-fns';
import { localEquivalentOfUTC } from 'mapistry-shared';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoSitesAssignedToUser = () => {
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { error, success } = useToast();

  useEffect(() => {
    const getEmailLog = async () => {
      try {
        const { lastEmailSentAt } = await Api.fetchProjectAccessRequestEmails();
        const daysSinceLastEmail = differenceInCalendarDays(
          new Date(),
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          localEquivalentOfUTC(lastEmailSentAt),
        );
        if (daysSinceLastEmail <= 1) {
          setRequestSent(true);
        }
      } catch (e) {
        Sentry.captureException(e);
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.error(e.message);
      }
      setIsLoading(false);
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getEmailLog();
  }, []);

  const sendRequestAccessEmail = async () => {
    try {
      const { orgAdminName } = await Api.sendProjectAccessRequestEmail();
      success(
        `${orgAdminName} has been notified of your request. Please follow up with them if you aren't added to a site within a few days.`,
        { autoHideDuration: 45000 },
      );
      setRequestSent(true);
    } catch (e) {
      const additionalInfo =
        isStringErrorResponse(e) &&
        e.data === 'User is not a member of any organization'
          ? `${e.data}.`
          : null;
      error(
        <span>
          There was a problem sending the email. {additionalInfo || ''} Please
          contact
          <a href="mailto:help@mapistry.com"> help@mapistry.com </a> for
          assistance.
        </span>,
        { autoHideDuration: 45000 },
      );
    }
  };

  if (isLoading) {
    return <Loading size="medium" />;
  }

  return (
    <div>
      <h1> You haven’t been assigned to a site in Mapistry. </h1>
      <ButtonContainer>
        {!requestSent ? (
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={sendRequestAccessEmail}
            size="large"
          >
            Request Access
          </Button>
        ) : (
          <p>
            And you have already sent an email to your organization
            administrator within the past 24 hours.
          </p>
        )}
      </ButtonContainer>
    </div>
  );
};
