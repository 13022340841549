import { withPermissions } from '@monorepo/shared/hooks/permissions/withPermissions';
import AddIcon from '@svg/add.svg';
import EditIcon from '@svg/edit.svg';
import { CalendarEventStatus } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchInspectionCalendarStencils } from '../../../../actions/calendar';
import APP from '../../../../config';
import { FormCalendar, MenuOptionType } from '../../../propTypes';
import withProvider from '../../../withProvider';
import BaseInspectionCalendarCard from '../BaseInspectionCalendarCard';

class FixedFrequencyInspectionCalendarCard extends Component {
  constructor(props) {
    super(props);
    this.handleOpenStencil = this.handleOpenStencil.bind(this);
    this.tooltipTitle = this.tooltipTitle.bind(this);
  }

  componentDidMount() {
    const { calendar, fetchStencils, projectId } = this.props;
    fetchStencils(projectId, calendar.name);
  }

  handleOpenStencil() {
    const { projectId, inspectionStencils, calendar } = this.props;
    if (inspectionStencils.length) {
      this.goToFormStencilEditor(
        projectId,
        inspectionStencils[0].formTemplateSlug,
        inspectionStencils[0].formSubmissionSlug,
        calendar.name,
      );
    }
  }

  goToFormStencilEditor(projectId, templateSlug, submissionSlug, calendarName) {
    const url = `/projects/${projectId}/form-submissions/${templateSlug}/${submissionSlug}/${calendarName}/stencil`;
    window.location.href = url;
  }

  stencilOptionLabel() {
    const { inspectionStencils } = this.props;
    return inspectionStencils.length && inspectionStencils[0].id
      ? { label: 'Edit Site Template', icon: <EditIcon className="m-icon" /> }
      : { label: 'Create Site Template', icon: <AddIcon className="m-icon" /> };
  }

  menuOptions() {
    const { hasProjectSettingsPermission, menuOptions } = this.props;
    const options = [...menuOptions];
    if (hasProjectSettingsPermission) {
      options.push({
        ...this.stencilOptionLabel(),
        onClick: this.handleOpenStencil,
      });
    }
    return options;
  }

  tooltipText(status, count, events) {
    switch (status) {
      case CalendarEventStatus.COMPLETE:
        return count === 1
          ? '1 Completed inspection'
          : `${count} Completed inspections`;
      case CalendarEventStatus.OVERDUE:
        return count === 1
          ? '1 Missed inspection'
          : `${count} Missed inspections`;
      case CalendarEventStatus.UPCOMING:
        return count === 1
          ? '1 Inspection due soon'
          : `${count} Inspections due soon`;
      case CalendarEventStatus.AS_NEEDED:
        return 'Create an inspection';
      case CalendarEventStatus.NOT_NEEDED:
        return 'Not Required';
      case CalendarEventStatus.EXTRA:
        return 'Create a new inspection';
      default:
        if (events[0].dueDate) {
          return count === 1
            ? '1 Future inspection'
            : `${count} Future inspections`;
        }
        return count === 1
          ? '1 Inspection started but not completed'
          : `${count} Inspections started but not completed`;
    }
  }

  tooltipTitle(events, tooMuchData) {
    if (tooMuchData) {
      return 'More Details';
    }
    const statusCount = events.reduce((acc, event) => {
      const existingStatus = acc[event.status];
      if (existingStatus) {
        acc[event.status] = existingStatus + 1;
      } else {
        acc[event.status] = 1;
      }
      return acc;
    }, {});
    const statusCountKeys = Object.keys(statusCount);
    return statusCountKeys.reduce((acc, status) => {
      if (
        status === CalendarEventStatus.AS_NEEDED &&
        statusCountKeys.length > 1
      ) {
        return acc;
      }
      const count = statusCount[status];
      acc.push(
        <div key={status}>{this.tooltipText(status, count, events)}</div>,
      );
      return acc;
    }, []);
  }

  render() {
    const { calendar, defaultView, onDateChange, organizationId } = this.props;

    return (
      <BaseInspectionCalendarCard
        calendar={calendar}
        defaultView={defaultView}
        menuOptions={this.menuOptions()}
        onDateChange={onDateChange}
        organizationId={organizationId}
        tooltipTitle={this.tooltipTitle}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchStencils: (projectId, calendarName) =>
    dispatch(
      fetchInspectionCalendarStencils({
        calendarName,
        projectId,
      }),
    ),
});

const mapStateToProps = (state, ownProps) => {
  const { calendar } = state;
  return {
    inspectionStencils: calendar.formStencils[ownProps.calendar.name],
    projectId: APP.projectId,
  };
};

const FormStencil = PropTypes.shape({
  id: PropTypes.number,
  formSubmissionSlug: PropTypes.string,
  formTemplateSlug: PropTypes.string,
});

FixedFrequencyInspectionCalendarCard.propTypes = {
  calendar: FormCalendar.isRequired,
  defaultView: PropTypes.node,
  fetchStencils: PropTypes.func.isRequired,
  hasProjectSettingsPermission: PropTypes.bool,
  inspectionStencils: PropTypes.arrayOf(FormStencil),
  menuOptions: PropTypes.arrayOf(MenuOptionType),
  onDateChange: PropTypes.func,
  organizationId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
};

FixedFrequencyInspectionCalendarCard.defaultProps = {
  defaultView: null,
  hasProjectSettingsPermission: false,
  inspectionStencils: [],
  menuOptions: [],
  organizationId: null,
  onDateChange: null,
};

// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withPermissions(FixedFrequencyInspectionCalendarCard)),
);
