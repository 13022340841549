import { FieldDataType } from 'mapistry-shared';
import React from 'react';
import { GenericDataType } from './GenericDataType';
import { NumericType } from './NumericType';
import { ResourceType } from './ResourceType';
import { SingleSelectType } from './SingleSelectType';

type DataTypeFactoryProps = Omit<
  React.ComponentProps<typeof GenericDataType>,
  'children'
> & {
  dataType?: FieldDataType;
  isEditing: boolean;
};

export function DataTypeFactory({
  dataType,
  ...restProps
}: DataTypeFactoryProps) {
  switch (dataType) {
    case FieldDataType.BOOLEAN:
    case FieldDataType.DATE:
    case FieldDataType.DATETIME:
    case FieldDataType.SIGNATURE:
    case FieldDataType.TEXT:
    case FieldDataType.TIME:
    case FieldDataType.USER:
      return <GenericDataType {...restProps} />;
    case FieldDataType.NUMERIC:
      return <NumericType {...restProps} />;
    case FieldDataType.RESOURCE:
      return <ResourceType {...restProps} />;
    case FieldDataType.SINGLE_SELECT:
      return <SingleSelectType {...restProps} />;
    default:
      return null;
  }
}
