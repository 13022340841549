import { Error404Page } from '@monorepo/shared/componentsV2/errorDisplays/Error404Page';
import React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';

export const RoutesWith404 = ({ children }: { children: React.ReactNode }) => (
  <Routes>
    {children}
    <Route path="*" element={<Error404Page />} />
  </Routes>
);
