import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateWorkflowCache,
  WorkflowCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type UseWorkflowStepUpdateParams = {
  config?: MutationConfig<Api.UpdateWorkflowStepResponse, Api.ErrorResponse>;
};

export const useWorkflowStepUpdate = (params?: UseWorkflowStepUpdateParams) =>
  useMutation(Api.updateWorkflowStep, {
    ...params?.config,
    onSuccess: (updatedWorkflowStep, mutationFuncParams) => {
      const { organizationId, workflowId } = mutationFuncParams;
      const keyParams = { organizationId, workflowId };

      invalidateWorkflowCache(WorkflowCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(
        updatedWorkflowStep,
        mutationFuncParams,
      );
    },
  });
