import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { LogEventsModal } from './LogEventsModal';

type LogEventsModalOpenerProps = Omit<
  React.ComponentProps<typeof LogEventsModal>,
  'onClose'
>;

export function LogEventsModalOpener(props: LogEventsModalOpenerProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    navigate(location.pathname, { replace: true });
  }, [location.pathname, navigate]);

  return <LogEventsModal onClose={onClose} {...props} />;
}
