import ErrorBoundaryLoggingToSentry from '@monorepo/shared/components/util/ErrorBoundaryLoggingToSentry';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import { CalendarName } from 'mapistry-shared';
import React, { useMemo } from 'react';
import Widget from '../../../../types/Widget';
import { Card } from '../../../elements/cards/Card';
import { CardContentErrorFallback } from '../../../elements/cards/CardContentErrorFallback';

export interface DashboardProps {
  widgets: Widget[];
  dates: string;
  filter?: string;
}

const SiteSummaryDashboard: React.FC<DashboardProps> = (props) => {
  const { widgets, dates, filter = '' } = props;

  const renderWidgets = useMemo((): JSX.Element[] => {
    let filteredWidgets = widgets.sort(Widget.sortByWidgetName);

    if (filter.length) {
      filteredWidgets = widgets.filter((widget) => {
        if (widget.handlesFilteringItself) return true;
        return lookFor(filter, widget, ['name']);
      });
    }

    return filteredWidgets.map((widget) => {
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/no-unstable-nested-components
      const CardErrorFallback = (fallbackProps) => (
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        <Card title={widget.name}>
          <CardContentErrorFallback {...fallbackProps} />
        </Card>
      );

      if (!widget.wrapper) {
        return (
          <React.Fragment key={widget.key}>
            <ErrorBoundaryLoggingToSentry FallbackComponent={CardErrorFallback}>
              {widget.renderComponent(filter)}
            </ErrorBoundaryLoggingToSentry>
          </React.Fragment>
        );
      }
      const outerClass =
        widget.outerClass ||
        (widget.custom
          ? 'flex flex-wrap align-items-center'
          : 'flex margin-16 dashboard-react-card');
      const innerClass =
        widget.innerClass ||
        (widget.custom ? 'min-w-400 margin-16 dashboard-react-card' : 'w-100');
      let extraClass = '';
      if (widget.name === CalendarName.AIR_EQUIPMENT_INSPECTION) {
        extraClass = 'method-22-inspection-container';
      } else if (widget.name === 'Min/Max pH over time') {
        extraClass = 'ph-log-container';
      } else if (widget.name === 'Water flow over time') {
        extraClass = 'flow-log-container';
      }

      return (
        <div key={widget.key} className={outerClass}>
          <ErrorBoundaryLoggingToSentry FallbackComponent={CardErrorFallback}>
            <div className={`${innerClass} ${extraClass}`}>
              {widget.renderComponent(filter)}
            </div>
          </ErrorBoundaryLoggingToSentry>
        </div>
      );
    });
  }, [widgets, filter]);

  return (
    <>
      <div className="row">
        <div className="col-xs-12 header">
          <span id="site-summary-dates">{dates}</span>
        </div>
      </div>
      <div className="site-widget-grid">
        {renderWidgets}

        {/* only gets shown if it's the only html child -
        it's difficult to filter since some widgets filter themselves */}
        <div className="dashboard-filter-too-specific-warning">
          No results found. Please try another search.
        </div>
      </div>
    </>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SiteSummaryDashboard;
