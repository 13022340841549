type Params = {
  projectId: string;
  taskId: string;
  dueDate?: string;
};

export const KEY_START = 'task';

export function createTaskKey(params: Params) {
  const { projectId, taskId, dueDate } = params;
  return [KEY_START, projectId, taskId, dueDate] as const;
}
