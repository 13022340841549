import {
  OnOrderChange,
  ReorderableElement,
  ReorderableList,
} from '@monorepo/shared/componentsV2/dragAndDrop/ReorderableList';
import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { FieldResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLogFieldCommonState } from '../../../hooks/useLogFieldCommonState';
import { LogDateFieldPreview } from '../../LogDateFieldPreview';
import { FieldList } from '../styled';

export type SortedLogFields = FieldResponse[];
export type UpdateLogFieldOrder = OnOrderChange;

const StyledFieldTypePreviewFactory = styled(FieldTypePreviewFactory)`
  margin: 0;

  /* so elements aren't see-through while being dragged over one another  */
  background-color: ${({ theme }) => theme.colors.white};
`;

const ReorderableFieldsSubList = styled.li`
  list-style-type: none;
`;

interface ReorderableLogFieldsProps {
  getMenu: ReturnType<typeof useLogFieldCommonState>['getMenu'];
  listLabelId: string;
  sortedLogFields: SortedLogFields;
  updateLogFieldOrder: UpdateLogFieldOrder;
}

export function ReorderableLogFields({
  getMenu,
  listLabelId,
  sortedLogFields,
  updateLogFieldOrder,
}: ReorderableLogFieldsProps) {
  const orderedElements: ReorderableElement[] = useMemo(
    () =>
      sortedLogFields.map((logField) => ({
        id: logField.id,
        component: (
          <StyledFieldTypePreviewFactory
            actionMenuOptions={getMenu(logField)}
            field={logField}
            outerElement="div" // we're handling the ul/li elements in wrapping components in the ReorderableList
          />
        ),
      })),
    [getMenu, sortedLogFields],
  );

  return (
    <FieldList aria-labelledby={listLabelId}>
      <LogDateFieldPreview key="log-date" />
      <ReorderableFieldsSubList aria-label="Sortable Log Fields">
        <ReorderableList
          orderedElements={orderedElements}
          onOrderChange={updateLogFieldOrder}
          uniqueDragListId="reorderableLogFields"
          listAriaLabel="Reorderable Log Fields"
        />
      </ReorderableFieldsSubList>
    </FieldList>
  );
}
