import { Subnav } from '@monorepo/shared/componentsV2/Subnav';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useMemo } from 'react';

export function DataSetupSubnav() {
  const { areWorkflowsEnabled } = useFeatureFlags();

  const options = useMemo(() => {
    const alwaysVisible = [
      {
        href: 'logs',
        label: 'Logs',
      },
      {
        href: 'resource-types',
        label: 'Resources',
      },
    ];
    return areWorkflowsEnabled
      ? [
          ...alwaysVisible,
          {
            href: 'workflows',
            label: 'Workflows',
          },
        ]
      : alwaysVisible;
  }, [areWorkflowsEnabled]);

  return <Subnav label="Data Setup" options={options} />;
}
