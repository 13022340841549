import { useReorderableItems } from '@monorepo/shared/componentsV2/dragAndDrop/useReorderableItems';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import { useLogEntryFields } from '@monorepo/shared/hooks/logs/useLogFields';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import {
  Content,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom-v5-compat';
import { EditLogHeader, WizardStep } from '../EditLogHeader';
import { Mode } from '../types';
import { EditLogFieldList } from './EditLogFieldList';
import { LogInstructionsSection } from './LogInstructionsSection';
import { useSubmitInstructionsAndFieldOrder } from './useSubmitInstructionsAndFieldOrder';

interface EditLogFieldsProps {
  maxInstructionsLength?: number; // mostly for tests
}

export function EditLogFields({ maxInstructionsLength }: EditLogFieldsProps) {
  useDisableSubnav();
  const { logId, mode = Mode.new, organizationId } = useParams();
  const { log } = useLog({ logId, organizationId });
  const { logFields } = useLogEntryFields({ logId, organizationId });

  const {
    hasBeenReordered,
    orderedItems: sortedLogFields,
    orderedItemIds,
    updateItemOrder: updateLogFieldOrder,
  } = useReorderableItems(logFields);

  const submitInstructionsAndFieldOrder = useSubmitInstructionsAndFieldOrder({
    logId,
    organizationId,
    mode,
  });

  const onSubmit = useCallback(
    async (logValues, form) => {
      const { dirty } = form.getState();

      await submitInstructionsAndFieldOrder(logValues, orderedItemIds, {
        instructionsHaveChanged: dirty,
        fieldOrderHasChanged: hasBeenReordered,
      });
    },
    [hasBeenReordered, orderedItemIds, submitInstructionsAndFieldOrder],
  );

  const canSubmit = useCallback(
    ({ invalid, pristine }: { invalid: boolean; pristine: boolean }) => {
      const instructionsChanged = !pristine;

      const isNewLogAndCanSubmit =
        mode === Mode.new && sortedLogFields?.length > 0;
      const isExistingLogAndCanSubmit =
        mode === Mode.edit &&
        sortedLogFields?.length > 0 &&
        (instructionsChanged || hasBeenReordered);

      return !invalid && (isNewLogAndCanSubmit || isExistingLogAndCanSubmit);
    },
    [hasBeenReordered, mode, sortedLogFields?.length],
  );

  if (!sortedLogFields) {
    return null;
  }

  return (
    <Page>
      <Form
        initialValues={log}
        onSubmit={onSubmit}
        subscription={{ submitting: true, invalid: true, pristine: true }}
      >
        {({ handleSubmit, invalid, submitting, pristine }) => (
          <>
            <EditLogHeader
              canSubmit={canSubmit({ invalid, pristine })}
              wizardStep={WizardStep.FIELDS}
              isSubmitting={submitting}
              onSubmit={handleSubmit}
            />
            <Content>
              <Title>Entry Fields</Title>
              <LogInstructionsSection
                maxInstructionsLength={maxInstructionsLength}
              />
              <EditLogFieldList
                logId={logId}
                organizationId={organizationId}
                sortedLogFields={sortedLogFields}
                updateLogFieldOrder={updateLogFieldOrder}
              />
            </Content>
          </>
        )}
      </Form>
    </Page>
  );
}
