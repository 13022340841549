import styled, { css } from 'styled-components';
import { Button } from './Button';
import { ButtonLink } from '../Link';

/**
 * icon-only buttons are based off their Button counterparts, since they should have
 *  almost the same styling as them
 */
const styling = css`
  min-width: fit-content;

  /* 1.5rem aligns with the size for the MUI IconButton */
  font-size: 1.5rem;

  /* undo styling in Button that assumes there is text to the right of the icon */
  & svg {
    margin: 0;
  }
`;

/**
 * To change the sizing of the svg rendered in the button, set font-size on the IconButton
 */
export const IconButton = styled(Button)`
  ${styling}
` as typeof Button;

export const IconButtonLink = styled(ButtonLink)`
  ${styling}
`;
