import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useDatasets } from '@monorepo/shared/hooks/workflows/useDatasets';
import { DatasetResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DatasetInfo } from '../../shared/DatasetPicker/DatasetInfo';
import { DatasetPicker } from '../../shared/DatasetPicker/DatasetPicker';
import { EmptyDatasetList } from '../../shared/DatasetPicker/EmptyDatasetList';
import { JoinToPrefixInput } from './JoinToPrefixInput';

interface JoinToDatasetPickerProps {
  isNewStep?: boolean;
  organizationId: string;
  workflowOutputDataset: DatasetResponse;
  selectedJoinToDataset?: DatasetResponse;
  stepInputDataset: DatasetResponse;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDatasetInfo = styled(DatasetInfo)`
  margin-bottom: 1.5rem;
`;

const StyledJoinToPrefixInput = styled(JoinToPrefixInput)`
  width: 35rem;
`;

export function JoinToDatasetPicker({
  isNewStep,
  organizationId,
  selectedJoinToDataset,
  stepInputDataset,
  workflowOutputDataset,
}: JoinToDatasetPickerProps) {
  const { datasets, isFetching } = useDatasets({
    organizationId,
  });

  const filteredDatasets = useMemo(() => {
    if (!datasets) {
      return [];
    }

    return datasets.filter(
      (d) => d.id !== stepInputDataset.id && d.id !== workflowOutputDataset.id,
    );
  }, [datasets, stepInputDataset.id, workflowOutputDataset.id]);

  if (isFetching) {
    return <Loading size="medium" />;
  }

  if (!filteredDatasets.length) {
    return <EmptyDatasetList />;
  }

  const showUneditableDatasetDisplay = () =>
    selectedJoinToDataset ? (
      <StyledDatasetInfo dataset={selectedJoinToDataset} isLocked />
    ) : (
      <Loading />
    );

  return (
    <Container>
      {isNewStep ? (
        <DatasetPicker
          datasets={filteredDatasets}
          fieldName="joinToDatasetId"
          label="Data Source 2"
          required
        />
      ) : (
        showUneditableDatasetDisplay()
      )}
      {/* todo@workflows: enable this when allowing to edit join steps */}
      <StyledJoinToPrefixInput
        datasets={filteredDatasets}
        disabled={!isNewStep}
      />
    </Container>
  );
}
