import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import isEqual from 'lodash.isequal';
import keyBy from 'lodash.keyby';
import { useEffect, useMemo, useState } from 'react';
import { compareOldSetToNewSet } from './reorderableItemsUtil';
import { ReorderableItem } from './types';

function orderItemsByIds<T extends ReorderableItem>(
  itemsToOrder: T[],
  orderedIds: string[],
) {
  const itemsById = keyBy(itemsToOrder, (item) => item.id);
  const reorderedItems = orderedIds
    .map((id) => itemsById[id])
    .filter((item) => item !== undefined); // while deleting an item, temporarily these lists can be out of sync

  return reorderedItems as T[];
}

/**
 *
 * Handles maintaining an array of sorted items that's being updated
 * and persisted based on an id array. Can add or delete one item at a time
 * without resetting the sort order.
 * Can be useful when used along with ReorderableList (which can also be used without)
 */
export function useReorderableItems<T extends ReorderableItem>(items?: T[]) {
  const [locallyOrderedItemIds, setLocallyOrderedItemIds] = useState<string[]>(
    [],
  );

  const oldItems = usePrevious(items);

  const [passedInItemsOrder, hasBeenReordered] = useMemo<
    [string[] | undefined, boolean]
  >(() => {
    const orderOfItemsPassedIn = items?.map((item) => item.id);
    const localOrderDiffersFromPassedInOrder = !isEqual(
      locallyOrderedItemIds,
      orderOfItemsPassedIn,
    );

    return [orderOfItemsPassedIn, localOrderDiffersFromPassedInOrder];
  }, [items, locallyOrderedItemIds]);

  const orderedItems = useMemo(
    () => orderItemsByIds(items || [], locallyOrderedItemIds),
    [items, locallyOrderedItemIds],
  );

  // when items get added or removed, try and keep the locally sorted order.
  // if the set of items changed "too much", start fresh with the locally sorted order
  useEffect(() => {
    if (!items) return;
    if (items === oldItems) return;

    const { isChanged, newlyAddedId, newlyDeletedId } = compareOldSetToNewSet(
      oldItems,
      items,
    );

    const oldIdOrder = locallyOrderedItemIds;
    const newIdOrder = passedInItemsOrder || [];

    let idOrderToSet;

    if (!isChanged) {
      idOrderToSet = oldIdOrder;
    } else if (newlyDeletedId) {
      idOrderToSet = oldIdOrder.filter((id) => id !== newlyDeletedId);
    } else if (newlyAddedId) {
      idOrderToSet = [...oldIdOrder, newlyAddedId];
    } else {
      // the set of items changed too much. e.g. a totally different set of items got rendered
      idOrderToSet = newIdOrder;
    }

    setLocallyOrderedItemIds(idOrderToSet);
  }, [items, oldItems, locallyOrderedItemIds, passedInItemsOrder]);

  return {
    hasBeenReordered,
    orderedItems,
    orderedItemIds: locallyOrderedItemIds,
    updateItemOrder: setLocallyOrderedItemIds,
  };
}
