import React from 'react';
import { useField } from 'react-final-form';
import { Checkbox } from '../Checkbox';

type CheckboxFieldProps = React.ComponentProps<typeof Checkbox> & {
  name: string;
};

// the type of the value of this field will always be a boolean since we're using
//  `type: 'checkbox'` and not passing `value`
// https://final-form.org/docs/react-final-form/types/FieldProps#type
// https://final-form.org/docs/react-final-form/types/FieldProps#without-value
export const CheckboxField = <ValueType extends boolean = boolean>({
  name,
  ...rest
}: CheckboxFieldProps) => {
  const { input } = useField<ValueType>(name, { type: 'checkbox' });
  return (
    <Checkbox
      {...rest}
      checked={input.checked}
      name={name}
      onChange={input.onChange}
    />
  );
};
