import { focusOutline } from '@monorepo/shared/styles/focus';
import styled from 'styled-components';

export const List = styled.ul`
  padding-left: 0;
`;

export const ListElement = styled.li`
  list-style: none;
`;

export const Element = styled.div`
  flex-grow: 1;
`;

export const DragHandle = styled.div`
  position: absolute; /* absolute positioning so we don't mess up styling of existing components. Requires a bit of white space to the left */
  left: -1.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem; /* padding to keep hover active between element and handle */

  &:focus-visible {
    ${focusOutline({ $outlineOffset: '-4px' })}
  }
`;

export const DraggableElement = styled.div<{
  $isDraggingThisItem: boolean;
  $isDraggingOverThisList: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;

  /* show drag handle only on hover over the parent, keyboard focus on itself, or while being dragged */
  ${DragHandle} {
    color: ${({ theme }) => theme.colors.gray666};
    opacity: ${(props) => (props.$isDraggingThisItem ? 1 : 0)};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.gray333};
    }

    &:focus-visible {
      opacity: 1; /* show drag handle when the drag handle has keyboard focus */
    }
  }

  &:hover ${DragHandle} {
    /* show drag handle when any part of the whole item is being hovered, unless
      it's being hovered because another item is dragged over it */
    opacity: ${(props) => (props.$isDraggingOverThisList ? 0 : 1)};
  }
`;
