import { useCallback, useEffect } from 'react';
import { useSubnav } from './useSubnav';

// when the subnav resizes, the page content expands/contracts.
// most elements will automatically resize. Some elements (most likely 3rd parties)
// might need to manually trigger a resize. This hook allows adding
// a callback that runs at the end of the resize.
export const useSubnavResizeListener = (resizeListener?: () => void) => {
  const { contentResizingWithSubnavRef } = useSubnav();
  const contentElement = contentResizingWithSubnavRef.current;

  const listenToTransitionEnd = useCallback(
    (evt) => {
      if (!resizeListener || evt.propertyName !== 'margin-left') {
        return;
      }

      resizeListener();
    },
    [resizeListener],
  );

  useEffect(() => {
    contentElement?.addEventListener('transitionend', listenToTransitionEnd);

    return () =>
      contentElement?.removeEventListener(
        'transitionend',
        listenToTransitionEnd,
      );
  }, [contentElement, listenToTransitionEnd]);

  return { contentResizingWithSubnavRef };
};
