import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { HorizontalNavigationTabs } from '@monorepo/shared/componentsV2/NavigationTabs/HorizontalNavigationTabs';
import { useLinkOnSameProject } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameProject';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import {
  LogsAndViewsPageContextProvider,
  useLogsAndViewsPageContext,
} from '../contexts/LogsAndViewsPageContext';
import { LogsPageHeader } from './LogsPage/LogsPageHeader';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledLogsPageHeader = styled(LogsPageHeader)`
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const StyledTabs = styled(HorizontalNavigationTabs)`
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const TabContent = styled.div`
  ${fillHeightAndScrollable}
`;

function LogsAndViewsPageContent() {
  const { project } = useCurrentProject();
  const { areWorkflowsEnabled } = useFeatureFlags();

  const { isHeaderVisible, isSearchVisible, setSearchFilter, searchFilter } =
    useLogsAndViewsPageContext();

  // clear filter when switching projects
  useEffect(() => {
    setSearchFilter('');
  }, [project?.id, setSearchFilter]);

  const logsPageLink = useLinkOnSameProject('/logs');
  const viewsPageLink = useLinkOnSameProject('/logs/views');

  const tabOptions = useMemo(
    () => [
      { label: 'All Logs', href: logsPageLink },
      ...(areWorkflowsEnabled ? [{ label: 'Views', href: viewsPageLink }] : []),
    ],
    [logsPageLink, areWorkflowsEnabled, viewsPageLink],
  );

  if (!project) {
    return <Loading />;
  }

  return (
    <Page>
      {isHeaderVisible && (
        <>
          <StyledLogsPageHeader
            onSearch={isSearchVisible ? setSearchFilter : undefined}
            filter={searchFilter}
          />
          <StyledTabs label="Logs or Views" options={tabOptions} />
        </>
      )}
      <TabContent>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </TabContent>
    </Page>
  );
}

export const LogsAndViewsPage = () => (
  <LogsAndViewsPageContextProvider>
    <LogsAndViewsPageContent />
  </LogsAndViewsPageContextProvider>
);
