import { DatetimePickerField } from '@monorepo/shared/componentsV2/fields/DatetimePickerField';
import { StyledFormGroup } from '@monorepo/shared/componentsV2/styled/form';
import { isRequired } from '@monorepo/shared/utils/validators';
import {
  DateLike,
  localEquivalentOfUTC,
  LogEntryResponse,
} from 'mapistry-shared';
import React, { useMemo } from 'react';

type EditLogEntryBuiltInFieldsProp = {
  existingLogEntry?: LogEntryResponse;
  initialLogDate?: DateLike;
};

export function EditLogEntryBuiltInFields({
  existingLogEntry,
  initialLogDate,
}: EditLogEntryBuiltInFieldsProp) {
  const initialLogDateValue = useMemo(() => {
    let value = new Date();
    if (existingLogEntry?.logDate) {
      value = localEquivalentOfUTC(new Date(existingLogEntry.logDate));
    } else if (initialLogDate) {
      value = new Date(initialLogDate);
    }
    return value;
  }, [existingLogEntry?.logDate, initialLogDate]);

  return (
    <StyledFormGroup row>
      <DatetimePickerField
        helperText=""
        label="Log Date"
        name="logDate"
        initialValue={initialLogDateValue}
        validate={isRequired}
        required
      />
    </StyledFormGroup>
  );
}
