import { ResourceKey } from '@monorepo/old-web/js/types/ts-types';
import { Interval, LogStatistic } from 'mapistry-shared';
import React from 'react';
import { LimitMonitorChartRow } from './LimitMonitorChartRow';

type ResourceKeyWithLimitIntervalFrequency = ResourceKey &
  Interval & {
    start: Date;
    end: Date;
    limits: string[];
  };

type LimitMonitorChartProps = {
  /** The resources that will be charted */
  resources?: ResourceKeyWithLimitIntervalFrequency[];
  /**
   * The associated resource statistics. This should be the same order
   *  as props.resources. Each statistic should have exactly one limit stat
   *  with it.
   */
  statistics?: LogStatistic[];
};

export const LimitMonitorChart = (props: LimitMonitorChartProps) => {
  const { resources, statistics } = props;
  if (!resources?.length || !statistics?.length) {
    return null;
  }
  return (
    <>
      {resources.map((resourceKey, index) => {
        const resourceStatistics = statistics[index];
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        const { label } = resourceStatistics.stats;
        const limitId = resourceKey.limits[0];
        return (
          <div className="production-limit-chart__row" key={limitId}>
            <div className="production-limit-chart__header">
              <div className="limit__label">{label}</div>
              {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
              <LimitMonitorChartRow statistics={resourceStatistics} />
            </div>
          </div>
        );
      })}
    </>
  );
};
