import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';
import { UseGenericLogDataInputProps } from './types';

export function createQueryKey({ id }: Api.FetchLogProjectProps) {
  return ['LOG_PROJECT', id] as const;
}

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogProject
>;

const fetcher: Fetcher = (_: string, id?: string) =>
  Api.fetchLogProject({ id });

export const useLogProject = ({
  config: inputConfig,
  id,
}: UseGenericLogDataInputProps<
  Api.FetchLogProjectProps,
  Api.FetchLogProjectResponse
>) => {
  const key = createQueryKey({ id });
  const config = {
    ...inputConfig,
    enabled: !!id,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    logProject: data,
    ...queryInfo,
  };
};
