import { ComboboxField } from '@monorepo/shared/componentsV2/fields/ComboboxField';
import { useResourceRefs } from '@monorepo/shared/hooks/resources/useResourceRefs';
import {
  ResourceFieldResponse,
  ResourceValue,
  SaveFieldValueRequest,
  SaveResourceValue,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormValues, isNullableString } from './types';
import {
  getUnexpectedTypeErrorAndLogToSentry,
  getValidateFunction,
} from './utils';

const StyledCombobox = styled(ComboboxField)`
  width: 18rem;
`;

type EditResourceFieldValueProps = {
  className?: string;
  disabled?: boolean;
  field: ResourceFieldResponse;
  fieldValue?: ResourceValue;
  fullWidth?: boolean;
  hiddenLabel?: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  organizationId: string;
  projectId: string | undefined;
};

export function EditResourceFieldValue({
  className,
  disabled = false,
  field,
  fieldValue,
  fullWidth,
  hiddenLabel = false,
  isRequired,
  label,
  name,
  organizationId,
  projectId,
}: EditResourceFieldValueProps) {
  const { resourceTypeId } = field;

  const { resourceRefs, isLoading } = useResourceRefs({
    organizationId,
    resourceTypeId,
    projectId,
    // currently nothing is done when this errors
    config: { useErrorBoundary: false },
  });

  const options = useMemo(
    () =>
      (resourceRefs || [])
        .sort((p1, p2) => p1.name.localeCompare(p2.name))
        .map((resource) => ({
          label: resource.name,
          value: resource.id,
        })),
    [resourceRefs],
  );

  if (resourceRefs && fieldValue?.value) {
    if (!resourceRefs.some((val) => val.id === fieldValue.value?.id)) {
      throw new Error('Unexpected value for Resource field');
    }
  }

  return (
    <StyledCombobox
      autoHighlight
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      initialValue={fieldValue?.value?.id}
      label={label}
      loading={isLoading}
      loadingText="Loading Resources"
      name={name}
      options={options}
      required={isRequired}
      validate={getValidateFunction(isRequired)}
    />
  );
}

export function getResourceFieldValueFromForm(
  field: ResourceFieldResponse,
  formValues: FormValues,
): SaveFieldValueRequest {
  const value = formValues[field.id] || null;
  if (!isNullableString(value)) {
    throw getUnexpectedTypeErrorAndLogToSentry(field, value);
  }

  const fieldValue: SaveResourceValue = { value };

  return {
    fieldId: field.id,
    fieldValue,
  };
}
