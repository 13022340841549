import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import { useFeatureFlags } from 'mapistry-shared/api';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { AddResourcesPage } from './AddResourcesPage/AddResourcesPage';
import { EditResourcePropertiesPage } from './EditResourcePropertiesPage/EditResourcePropertiesPage';
import { EditResourceTypePage } from './EditResourceTypePage/EditResourceTypePage';
import { ResourceDetails } from './ResourceDetails';
import { ResourceTypeDetailsPage } from './ResourceTypeDetailsPage/ResourceTypeDetailsPage';
import { ViewResourceTypes } from './ViewResourceTypes';
import { ViewResources } from './ViewResources';
import { ResourceTypeEditMode } from './routerTypes';

export function ResourceDataSetup() {
  const { isData2Enabled } = useFeatureFlags();

  return isData2Enabled ? (
    <RoutesWith404>
      <Route index element={<ViewResourceTypes />} />
      <Route
        path={ResourceTypeEditMode.NEW}
        element={<EditResourceTypePage />}
      />
      <Route path=":resourceTypeId">
        <Route index element={<ResourceTypeDetailsPage />} />
        <Route path="resources">
          <Route index element={<Navigate replace to="pages" />} />
          <Route path="pages">
            <Route index element={<Navigate replace to="0" />} />
            <Route path=":pageNumber">
              <Route index element={<ViewResources />} />
            </Route>
          </Route>
          <Route path=":resourceId" element={<ResourceDetails />} />
        </Route>
        <Route path=":mode">
          <Route index element={<Navigate replace to="basic-info" />} />
          <Route path="basic-info">
            <Route index element={<EditResourceTypePage />} />
          </Route>
          <Route path="properties">
            <Route index element={<EditResourcePropertiesPage />} />
          </Route>
          <Route path="add-resources">
            <Route index element={<AddResourcesPage />} />
          </Route>
        </Route>
      </Route>
    </RoutesWith404>
  ) : null;
}
