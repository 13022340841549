import { ResourceFieldResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useResourceType } from '../../../hooks/resources/useResourceType';
import { isRequired } from '../../../utils/validators';
import { InfoCard } from '../../InfoCard';
import { SelectResourceTypeField } from '../../fields/SelectResourceTypeField';
import { GenericDataType } from './GenericDataType';

const Container = styled.div`
  max-width: 26rem;
`;

const SelectResourceType = styled(SelectResourceTypeField)`
  max-width: 13.5rem;
` as typeof SelectResourceTypeField;

type ResourceTypeProps = Omit<
  React.ComponentProps<typeof GenericDataType>,
  'children'
> & { isEditing: boolean };

/**
 * if picking a resource type within the context of another one
 *  1. exclude the current resource
 *  2. only allow picking site specific resources if the current resource is also site specific
 */
function useResourceTypeFilters() {
  const { organizationId, resourceTypeId } = useParams<{
    organizationId: string;
    resourceTypeId: string;
  }>();
  const workingInTheContextOfAnotherResourceType = !!resourceTypeId;
  const { resourceType } = useResourceType({
    organizationId,
    resourceTypeId,
  });
  const excludeSiteSpecific =
    workingInTheContextOfAnotherResourceType && !resourceType?.isSiteSpecific;

  const filters = useMemo(
    () => ({
      excludedResourceTypeId: resourceTypeId,
      excludeSiteSpecific,
    }),
    [excludeSiteSpecific, resourceTypeId],
  );

  return filters;
}

export function ResourceType(props: ResourceTypeProps) {
  const { isEditing, ...restProps } = props;
  const filters = useResourceTypeFilters();

  return (
    <GenericDataType {...restProps}>
      <Container>
        <SelectResourceType<ResourceFieldResponse['resourceTypeId']>
          label="Choose a Resource Type"
          name="resourceTypeId"
          validate={isRequired}
          filters={filters}
          disabled={isEditing}
          required
        />
        <InfoCard>
          <span>
            Resource types are configured in Resources and appear here for you
            to use.
            {filters.excludeSiteSpecific
              ? ' Since this Resource type is not associated to sites, it cannot reference Resource types that are associated with specific sites.'
              : ''}
          </span>
        </InfoCard>
      </Container>
    </GenericDataType>
  );
}
