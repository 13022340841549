import AddIcon from '@svg/add.svg';
import TrashIcon from '@svg/trash.svg';
import arrayMutators from 'final-form-arrays';
import {
  GenericLogType,
  IntervalFrequencyEnum,
  UnitString,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  ChartType,
  ChartableGenericLogsResource,
  ResourceKey,
} from '../../../types/ts-types';
import Units from '../../../utils/units';
import {
  Button,
  ChartCardSettingsActions,
  ChartCardSettingsContent,
  IconButton,
} from '../../elements';
import Form from '../../elements/Form';
import IntervalFieldSet from '../../elements/Form/IntervalFieldSet';
import GenericLogsChartTypeField from '../GenericLogsChartTypeField';
import GenericLogsResourceField from '../GenericLogsResourceField';
import GenericLogsResourceFrequencyField from '../GenericLogsResourceFrequencyField';
import GenericLogsUnitField from '../GenericLogsUnitField';
import GenericLogsWidgetNameField from '../GenericLogsWidgetNameField';

type ResourceKeyWithChartParams = ResourceKey & {
  unit: UnitString;
  chartType: ChartType;
};

type FormValues = {
  resources: ResourceKeyWithChartParams[];
  frequency: IntervalFrequencyEnum;
  start: string;
  end: string;
  widgetName: string;
};

type ResourceError = {
  resourceId: string;
};

type FormErrors = {
  resources: ResourceError[];
  frequency?: string;
  start?: string;
  end?: string;
};

type AirEmissionChartSettingsFormProps = {
  airEmissionResources: ChartableGenericLogsResource[];
  initialValues?: FormValues;
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
};

function ChartSettingsForm(props: AirEmissionChartSettingsFormProps) {
  const { initialValues, onSubmit, onCancel, airEmissionResources } = props;

  const initialValuesWithDefaults = useMemo<FormValues>(() => {
    const defaultResource: ResourceKeyWithChartParams = {
      resourceType: '' as Exclude<GenericLogType, GenericLogType.LIMIT_ITEM>,
      resourceId: '',
      unit: '' as UnitString,
      chartType: 'bar',
    };
    if (initialValues && initialValues.resources.length) {
      return initialValues;
    }
    // If resources are empty they were deleted, provide default empty resource in this case
    if (initialValues && !initialValues.resources.length) {
      return {
        ...initialValues,
        resources: [defaultResource],
      };
    }
    const now = new Date();
    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(now.getDate() - 30);
    return {
      resources: [defaultResource],
      frequency: IntervalFrequencyEnum.DAY,
      start: thirtyDaysAgo.toISOString(),
      end: now.toISOString(),
      widgetName: '',
    };
  }, [initialValues]);

  const validate = useCallback((values) => {
    const errors: FormErrors = {
      resources: [],
    };
    // Required fields
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    values.resources.forEach((r, index) => {
      if (!r.resourceId) {
        errors.resources[index] = {
          resourceId: 'Factor is required',
        };
      }
    });
    if (!values.frequency) {
      errors.frequency = 'Frequency is required';
    }
    if (!values.start) {
      errors.start = 'Start date is required';
    }
    if (!values.end) {
      errors.end = 'End date is required';
    }
    // Ensure start date is less than end date
    if (values.start && values.end && values.start >= values.end) {
      const message = 'Start date must be before end date.';
      errors.start = message;
      errors.end = message;
    }
    return errors;
  }, []);

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValuesWithDefaults}
      validate={validate}
      mutators={{ ...arrayMutators }}
    >
      {(form) => (
        <Form
          className="generic-logs-chart-settings-form"
          onSubmit={form.handleSubmit}
        >
          <ChartCardSettingsContent>
            <GenericLogsWidgetNameField label="Widget Name" name="widgetName" />
            <FieldArray name="resources">
              {({ fields }) => (
                <>
                  {fields.map((name, fieldIndex) => (
                    <div className="field-row" key={name}>
                      <div className="w-40">
                        <GenericLogsResourceField
                          name={name}
                          label={
                            fieldIndex === 1 ? 'Right y-axis' : 'Left y-axis'
                          }
                          allResources={airEmissionResources}
                          selectedResources={fields.value}
                          // first axis is primary
                          isPrimaryField={fieldIndex === 0}
                          primaryFieldResource={fields.value[0]}
                          resourceUpdater={(selectedResourceKey) => {
                            // when the resource is updated we need to apply
                            // previous chart type value + possibly updated unit value
                            const prevValue = fields.value[fieldIndex];
                            // User can select only from airEmissionResources
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            const selectedResource = airEmissionResources.find(
                              (aer) =>
                                aer.resourceId ===
                                selectedResourceKey.resourceId,
                            )!;
                            const selectedResourceRelatedUnits =
                              Units.getRelatedUnits(
                                selectedResource.entity.units,
                              ).map((opt) => opt.value);
                            const updatedUnit =
                              selectedResourceRelatedUnits.includes(
                                prevValue.unit,
                              )
                                ? prevValue.unit
                                : selectedResource.entity.units;
                            return {
                              ...prevValue,
                              ...selectedResourceKey,
                              unit: updatedUnit,
                            };
                          }}
                          onReinitializeField={(index) => {
                            fields.update(index, {
                              ...fields.value[index],
                              resourceId: '',
                              resourceType: '',
                            });
                          }}
                          onForceFrequency={(frequency) => {
                            form.form.change('frequency', frequency);
                          }}
                        />
                      </div>
                      <div className="w-30">
                        <GenericLogsUnitField
                          name={`${name}.unit`}
                          label="Unit"
                          allResources={airEmissionResources}
                          showRelatedUnitsFor={fields.value[fieldIndex]}
                        />
                      </div>
                      <div className="w-20">
                        <GenericLogsChartTypeField
                          name={`${name}.chartType`}
                          label="Chart type"
                        />
                      </div>
                      <div className="w-10 flex flex-center">
                        {fieldIndex > 0 && (
                          <IconButton
                            className="delete-button"
                            tooltipText="Remove axis"
                            onClick={() => fields.remove(fieldIndex)}
                          >
                            <TrashIcon className="m-icon" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))}
                  {fields.value.length < 2 && (
                    <div className="field-wrapper">
                      <Button
                        type="button"
                        variant="text"
                        startIcon={<AddIcon className="m-icon" />}
                        onClick={() =>
                          fields.push({
                            resourceType: '',
                            resourceId: '',
                            chartType: 'line',
                          })
                        }
                      >
                        Add additional axis
                      </Button>
                    </div>
                  )}
                </>
              )}
            </FieldArray>
            <GenericLogsResourceFrequencyField
              name="frequency"
              label="Select a frequency"
              forcedFrequencyHelpText="Rolling calculations can only be graphed on their calculation frequency"
              allResources={airEmissionResources}
              selectedResources={form.values.resources}
            />
            <IntervalFieldSet
              names={['start', 'end']}
              label="Select a date range"
            />
          </ChartCardSettingsContent>
          <ChartCardSettingsActions>
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">Update Settings</Button>
          </ChartCardSettingsActions>
        </Form>
      )}
    </FinalForm>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ChartSettingsForm;
