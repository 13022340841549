import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { header2 } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  ${header2}
  padding: 0 0 1.25rem;

  & a {
    color: ${({ theme }) => theme.colors.gray333};
  }

  & .MuiBreadcrumbs-li {
    font-weight: 400;

    &:first-of-type {
      font-weight: 700;
    }
  }
`;

export interface Breadcrumb {
  label: string;
  to: string;
}

type BreadcrumbsProps = React.ComponentProps<typeof MuiBreadcrumbs> & {
  crumbs: Breadcrumb[];
};

export function Breadcrumbs({ crumbs, ...props }: BreadcrumbsProps) {
  return (
    <StyledBreadcrumbs separator=">" aria-label="breadcrumb" {...props}>
      {crumbs.map((crumb) => (
        <Link key={crumb.to} to={crumb.to} color="grey">
          {crumb.label}
        </Link>
      ))}
    </StyledBreadcrumbs>
  );
}
