import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { Description } from '@monorepo/shared/componentsV2/Description';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import {
  contentPaddingValue,
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditResourceModal } from '../EditResourceModal';
import {
  EditResourceTypeHeader,
  WizardStep,
} from '../EditResourceTypeHeader/EditResourceTypeHeader';
import { ViewResourcesTable } from '../ViewResourcesTable/ViewResourcesTable';
import { ResourcesUploadModal } from './ResourcesUploadModal';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledMain = styled(Main)`
  ${fillHeightAndScrollable}
`;

const Content = styled.div`
  ${verticalLayout}
`;

const Subheader = styled.h2`
  ${header4}
`;

const AboveTableContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${contentPaddingValue} ${contentPaddingValue} 0.5rem
    ${contentPaddingValue};
`;

const AddResourceButtonContainer = styled(ButtonsGroup)`
  margin-top: 1rem;
`;

const TableContainer = styled.div`
  ${fillHeightAndScrollable}
`;

function AddResourcesPageContent() {
  useDisableSubnav();
  const { organizationId, resourceTypeId } = useParams();

  const [isEditModalOpen, openEditModal, closeEditModal] = useOpenClose();
  const [isBulkUploadModalOpen, openBulkUploadModal, closeBulkUploadModal] =
    useOpenClose();

  const [resourceIdForEdit, setResourceIdForEdit] = useState<
    string | undefined
  >(undefined);

  const openResourceModal = useCallback(
    (resourceId?: string) => {
      setResourceIdForEdit(resourceId);
      openEditModal();
    },
    [setResourceIdForEdit, openEditModal],
  );

  const handleAddNew = useCallback(() => {
    openResourceModal();
  }, [openResourceModal]);

  const [pageNumber, setPageNumber] = useState(0);

  return (
    <Page>
      <EditResourceTypeHeader wizardStep={WizardStep.RESOURCES} />

      <StyledMain>
        <Content>
          <AboveTableContent>
            <div>
              <Subheader>Add Resources</Subheader>
              <Description>
                Add or upload Resources along with any associated properties.
              </Description>
            </div>
            <AddResourceButtonContainer>
              <Button color="primary" onClick={handleAddNew}>
                <AddCircleOutline />
                Add Resource
              </Button>
              <Button color="primary" onClick={openBulkUploadModal}>
                <ArrowUpward />
                Upload from file
              </Button>
            </AddResourceButtonContainer>
          </AboveTableContent>
          <TableContainer>
            <ViewResourcesTable
              onEditResource={(resourceId: string) =>
                openResourceModal(resourceId)
              }
              onPageChange={setPageNumber}
              pageNumber={pageNumber}
            />
          </TableContainer>
        </Content>
      </StyledMain>
      {isEditModalOpen && (
        <EditResourceModal
          organizationId={organizationId}
          resourceTypeId={resourceTypeId}
          open={isEditModalOpen}
          resourceId={resourceIdForEdit}
          onClose={closeEditModal}
        />
      )}
      {isBulkUploadModalOpen && organizationId && resourceTypeId && (
        <ErrorBoundary onError={closeBulkUploadModal}>
          <ResourcesUploadModal
            isOpen={isBulkUploadModalOpen}
            resourceTypeId={resourceTypeId}
            onClose={closeBulkUploadModal}
            organizationId={organizationId}
          />
        </ErrorBoundary>
      )}
    </Page>
  );
}

export const AddResourcesPage = () => (
  <ErrorBoundary>
    <AddResourcesPageContent />
  </ErrorBoundary>
);
