import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createCalendarAssignmentsAction,
  removeCalendarAssignmentsAction,
  resetCalendarErrorStateAction,
} from '../../../actions/calendar';
import APP from '../../../config';
import { delayedModalClose } from '../../../utils';
import { ProjectTaskType } from '../../propTypes';
import withProvider from '../../withProvider';
import RecurringTaskSaveModal from '../RecurringTaskSaveModal';
import InspectionTaskEditModal from './InspectionTaskEditModal';

const defaultErrorMessage =
  'There was a problem updating the task. Please try again later.';

class InspectionTaskEditModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignees: props.task.assignees,
      saveModalOpen: false,
      saveState: SaveState.CLEAN,
    };
  }

  componentDidUpdate(prevProps) {
    const { isSaving, serverError } = this.props;
    const { saveState } = this.state;

    if (saveState === SaveState.SAVING && prevProps.isSaving && !isSaving) {
      if (serverError) {
        this.setState({ saveState: SaveState.DIRTY });
      } else {
        this.setState({ saveState: SaveState.SAVED }, () => {
          delayedModalClose(this.handleClose);
        });
      }
    }
  }

  getChanges(newAssignees) {
    const { task } = this.props;
    const nonEmptyAssignees = newAssignees.filter((user) => !!user);
    const existingUserIds = task.assignees.map((user) => user.userId);
    const newUserIds = nonEmptyAssignees.map((user) => user.userId);
    const toRemove = task.assignees.filter(
      (user) => !newUserIds.includes(user.userId),
    );
    const toAdd = nonEmptyAssignees.filter(
      (user) => !existingUserIds.includes(user.userId),
    );

    return { toRemove, toAdd };
  }

  handleClose = () => {
    const { onClose, resetErrorState } = this.props;

    resetErrorState();
    onClose();
  };

  handleAssigneesChange = (newAssignees) => {
    const { toRemove, toAdd } = this.getChanges(newAssignees);
    const saveState =
      toRemove.length || toAdd.length ? SaveState.DIRTY : SaveState.CLEAN;

    this.setState({
      assignees: newAssignees,
      saveState,
    });
  };

  handleOpenSaveModal = () => {
    this.setState({ saveModalOpen: true });
  };

  cancelSave = () => {
    this.setState({ saveModalOpen: false });
  };

  handleSave = (forAllTasks) => {
    const { createAssignments, projectId, removeAssignments, task } =
      this.props;
    const { assignees } = this.state;
    const { calendarId, dueDate, startDate } = task;
    const interval = {
      start: startDate,
      end: forAllTasks ? null : dueDate,
    };
    const { toRemove, toAdd } = this.getChanges(assignees);

    this.setState(
      {
        saveModalOpen: false,
        saveState: SaveState.SAVING,
      },
      () => {
        if (toRemove.length) {
          removeAssignments(projectId, calendarId, toRemove, interval);
        }
        if (toAdd.length) {
          createAssignments(projectId, calendarId, toAdd, interval);
        }
      },
    );
  };

  render() {
    const { isOpen, serverError, task } = this.props;
    const { assignees, saveModalOpen, saveState } = this.state;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <>
        <InspectionTaskEditModal
          assignees={assignees}
          errors={serverError ? [defaultErrorMessage] : null}
          isOpen={isOpen}
          onChange={this.handleAssigneesChange}
          onClose={this.handleClose}
          onSave={this.handleOpenSaveModal}
          saveState={saveState}
          task={task}
        />
        <RecurringTaskSaveModal
          fields={['assignee']}
          isOpen={saveModalOpen}
          onAction={this.handleSave}
          onClose={this.cancelSave}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { calendar } = state;
  const creatingAssignees = _get(calendar.isFetching, 'createAssignees', false);
  const removingAssignees = _get(calendar.isFetching, 'removeAssignees', false);
  return {
    isSaving: creatingAssignees || removingAssignees,
    projectId: APP.projectId,
    serverError: calendar.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createAssignments: (projectId, calendarId, users, interval) =>
    dispatch(
      createCalendarAssignmentsAction(projectId, calendarId, users, interval),
    ),
  resetErrorState: () => dispatch(resetCalendarErrorStateAction()),
  removeAssignments: (projectId, calendarId, assignments, interval) =>
    dispatch(
      removeCalendarAssignmentsAction(
        projectId,
        calendarId,
        assignments,
        interval,
      ),
    ),
});

InspectionTaskEditModalContainer.propTypes = {
  createAssignments: PropTypes.func.isRequired,
  task: ProjectTaskType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  removeAssignments: PropTypes.func.isRequired,
  resetErrorState: PropTypes.func.isRequired,
  serverError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

InspectionTaskEditModalContainer.defaultProps = {
  serverError: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InspectionTaskEditModalContainer),
);
