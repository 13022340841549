import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogFieldCache,
  LogFieldCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  getCache as getLogFieldsCache,
  setCache as setLogFieldsCache,
} from '@monorepo/shared/hooks/logs/useLogFields';
import { MutationConfig, useMutation } from 'react-query';

type UseLogFieldUpdateParams = {
  config?: MutationConfig<Api.UpdateLogFieldResponse, Api.ErrorResponse>;
};

export const useLogFieldUpdate = (params?: UseLogFieldUpdateParams) =>
  useMutation(Api.updateLogField, {
    ...params?.config,
    onSuccess: (updatedLogField, updateLogFieldProps) => {
      const { logId, organizationId } = updateLogFieldProps;

      // Update list of logFields
      const currentItems = getLogFieldsCache({ logId, organizationId });
      // If there is cache, update it
      if (currentItems) {
        const newItems = currentItems.map((item) =>
          item.id === updatedLogField.id ? updatedLogField : item,
        );
        setLogFieldsCache({ logId, organizationId }, newItems);
      }

      // Invalidate any RQ cache that might use information about Log Fields
      invalidateLogFieldCache(LogFieldCacheEvents.UPDATE, {
        logFieldId: updatedLogField.id,
        logId,
        organizationId,
      });

      return params?.config?.onSuccess?.(updatedLogField, updateLogFieldProps);
    },
  });
