import { QueryStep, ViewQueryStep } from 'mapistry-shared';
import { PotentiallyIncompleteQueryStep } from './types';

export function isQueryStep(
  x: QueryStep | ViewQueryStep | PotentiallyIncompleteQueryStep,
): x is QueryStep {
  return 'operation' in x && !!x.operation;
}

export function filterIncompleteQuerySteps(
  steps: (QueryStep | ViewQueryStep | PotentiallyIncompleteQueryStep)[],
) {
  return steps.filter((qs): qs is QueryStep => isQueryStep(qs));
}
