import { StyledTextArea } from '@monorepo/shared/componentsV2/styled/form';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { Description, InstructionsInput, SubTitle } from '../styled';

const MAX_INSTRUCTION_CHAR_LENGTH = 1500;

export function LogInstructionsSection({
  maxInstructionsLength = MAX_INSTRUCTION_CHAR_LENGTH,
}) {
  const { logId, organizationId } = useParams();
  const { isLoading } = useLog({ logId, organizationId });

  const instructionsAreNotTooLong = useCallback(
    (value?: string) =>
      !value || value.length <= maxInstructionsLength
        ? undefined
        : `Must be ${maxInstructionsLength} characters or less`,
    [maxInstructionsLength],
  );

  return (
    <>
      <SubTitle>Instructions</SubTitle>
      <Description>
        Log instructions will appear every time a user submits an entry.
        Instructions are not required.
      </Description>
      <InstructionsInput>
        <StyledTextArea
          label="Instructions"
          hiddenLabel
          disabled={isLoading}
          rowsMax={15}
          multiline
          validate={instructionsAreNotTooLong}
          name="instructions"
          placeholder={
            isLoading ? '...' : 'Enter the required values for your Log entry.'
          }
        />
      </InstructionsInput>
    </>
  );
}
