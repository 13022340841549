import { NullableString } from 'mapistry-shared';
import { ReorderableItem } from './types';

/**
 * just for use with useReorderableItems - to understand
 * what change got made between two arrays
 * If just one item got added, newlyAddedId returns the id of that item.
 * If just one item got deleted, newlyDeletedId returns the id of that item.
 * isChanged is false only both arrays include elements with the same set of ids
 */
export function compareOldSetToNewSet(
  oldItems: ReorderableItem[] | undefined,
  newItems: ReorderableItem[],
): {
  newlyAddedId: NullableString;
  newlyDeletedId: NullableString;
  isChanged: boolean;
} {
  if (!oldItems) {
    return {
      isChanged: true,
      newlyAddedId: null,
      newlyDeletedId: null,
    };
  }

  const [oldItemIds, newItemIds] = [
    oldItems.map((item) => item.id),
    newItems.map((item) => item.id),
  ];

  const [oldItemIdsSet, newItemIdsSet] = [
    new Set<string>(oldItemIds),
    new Set<string>(newItemIds),
  ];

  const [newlyAdded, newlyDeleted] = [
    newItemIds.filter((id) => !oldItemIdsSet.has(id)),
    oldItemIds.filter((id) => !newItemIdsSet.has(id)),
  ];

  // unchanged
  if (newlyAdded.length === 0 && newlyDeleted.length === 0) {
    return {
      isChanged: false,
      newlyAddedId: null,
      newlyDeletedId: null,
    };
  }

  // one item got deleted
  if (newlyAdded.length === 0 && newlyDeleted.length === 1) {
    return {
      isChanged: true,
      newlyAddedId: null,
      newlyDeletedId: newlyDeleted[0] || null,
    };
  }

  // one item got added
  if (newlyAdded.length === 1 && newlyDeleted.length === 0) {
    return {
      isChanged: true,
      newlyAddedId: newlyAdded[0] || null,
      newlyDeletedId: null,
    };
  }

  return {
    isChanged: true,
    newlyAddedId: null,
    newlyDeletedId: null,
  };
}
