import { Loading } from '@monorepo/shared/components/Loading';
import { useLogEntries } from '@monorepo/shared/hooks/logs/useLogEntries';
import { format } from 'date-fns';
import { localEquivalentOfUTC, SortDirection } from 'mapistry-shared';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';

interface LastEntryProps {
  logId: string;
}

export function LastEntry({ logId }: LastEntryProps) {
  const { organizationId, projectId } = useParams();
  const { isFetching, isLoading, logEntries } = useLogEntries({
    logId,
    organizationId,
    projectId,
    /**
     * Always request the first page, we only care about the most recent
     * entry
     */
    requestedPage: 0,
    sortDirection: SortDirection.DESC,
    // currently nothing is done when this errors
    config: { useErrorBoundary: false },
  });

  if (isFetching || isLoading) {
    return <Loading size="small" />;
  }

  if (!logEntries?.[0]?.logDate) {
    return <span>-</span>;
  }

  return (
    <span>
      {format(localEquivalentOfUTC(new Date(logEntries[0].logDate)), 'Pp')}
    </span>
  );
}
