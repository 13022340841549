import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Error404Page } from '@monorepo/shared/componentsV2/errorDisplays/Error404Page';
import { FullPageFatalError } from '@monorepo/shared/componentsV2/errorDisplays/FullPageFatalError';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import { useHasAnalyticsAccess } from '@monorepo/shared/hooks/permissions/useHasAnalyticsPermissions';
import React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { AnalyticsDashboard } from './AnalyticsDashboard';

export function Analytics() {
  const hasAnalyticsAccess = useHasAnalyticsAccess();
  if (!hasAnalyticsAccess) {
    return <Error404Page />;
  }

  const dashboardWithErrorBoundary = (
    <ErrorBoundary Fallback={FullPageFatalError}>
      <AnalyticsDashboard />
    </ErrorBoundary>
  );

  return (
    <RoutesWith404>
      <Route index element={<Navigate replace to="dashboards" />} />
      <Route path="dashboards">
        <Route index element={dashboardWithErrorBoundary} />
        <Route path=":dashboardId" element={dashboardWithErrorBoundary} />
      </Route>
    </RoutesWith404>
  );
}
