import { CalendarEventStatus } from 'mapistry-shared';

type SortableCalendarEvent = {
  status: CalendarEventStatus;
  title: string;
};

const sortAlphabetically = (
  eventA: SortableCalendarEvent,
  eventB: SortableCalendarEvent,
) => eventA.title.localeCompare(eventB.title);

const getStatusSortOrder = (status: CalendarEventStatus): number => {
  switch (status) {
    case CalendarEventStatus.OVERDUE:
      return 1;
    case CalendarEventStatus.COMPLETE:
      return 3;
    default:
      return 2;
  }
};

export const sortByStatusThenAlphabetically = (
  eventA: SortableCalendarEvent,
  eventB: SortableCalendarEvent,
) => {
  const [aStatusSortOrder, bStatusSortOrder] = [
    getStatusSortOrder(eventA.status),
    getStatusSortOrder(eventB.status),
  ];

  if (aStatusSortOrder === bStatusSortOrder) {
    return sortAlphabetically(eventA, eventB);
  }
  if (aStatusSortOrder < bStatusSortOrder) {
    return -1;
  }
  return 1;
};

export const sortEventsWithinDay = (eventA: unknown, eventB: unknown) =>
  sortByStatusThenAlphabetically(
    // FullCalendar seems to not expose the internal types I'd need to type this right
    eventA as SortableCalendarEvent,
    eventB as SortableCalendarEvent,
  );
