import { NavigationTabs } from '@monorepo/shared/componentsV2/NavigationTabs';
import { BaseNavBar } from '@monorepo/shared/componentsV2/NavigationTabs/BaseNavBar';
import { useHasAnalyticsAccess } from '@monorepo/shared/hooks/permissions/useHasAnalyticsPermissions';
import { useLastProject } from '@monorepo/shared/hooks/useLastProject';
import Analytics from '@svg/analytics.svg';
import Vortex from '@svg/data-setup.svg';
import MapDashboard from '@svg/m-map-dashboard.svg';
import SiteDashboard from '@svg/m-site-dashboard.svg';
import classnames from 'classnames';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useMemo } from 'react';
import { SettingsTab } from './SettingsTab';
import { TabContainer, TabLabelContainer, TabLabelText } from './styled';

function getLabel(icon: React.ReactNode, label: string) {
  return ({ isActive }: { isActive: boolean }) => (
    <TabContainer>
      <TabLabelContainer className={classnames({ selected: isActive })}>
        <span aria-hidden>{icon}</span>
        <TabLabelText>{label}</TabLabelText>
      </TabLabelContainer>
    </TabContainer>
  );
}

export function NavBar() {
  const { project, isLoading } = useLastProject();
  const { isData2Enabled } = useFeatureFlags();
  const hasAnalyticsAccess = useHasAnalyticsAccess();

  const options = useMemo(
    () => [
      ...(hasAnalyticsAccess
        ? [
            {
              href: '/vortex/analytics',
              isLegacy: false,
              label: getLabel(<Analytics />, 'Analytics'),
            },
          ]
        : []),
      {
        href: `/vortex/organizations/${project?.organization_id}/sites/${project?.id}`,
        isLegacy: false,
        label: getLabel(<SiteDashboard />, 'Sites'),
      },
      {
        href: `/organizations/${project?.organization_id}/projects/${project?.id}`,
        isLegacy: true,
        label: getLabel(<MapDashboard />, 'Maps'),
      },
      ...(isData2Enabled
        ? [
            {
              href: `/vortex/organizations/${project?.organization_id}/data-setup`,
              isLegacy: false,
              label: getLabel(<Vortex />, 'Data Setup'),
            },
          ]
        : []),
    ],
    [hasAnalyticsAccess, isData2Enabled, project?.id, project?.organization_id],
  );

  return (
    <BaseNavBar>
      <NavigationTabs
        label="Main"
        isLoading={isLoading}
        orientation="vertical"
        options={options}
      />
      <SettingsTab />
    </BaseNavBar>
  );
}
