import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useOrganizationProjects } from '../hooks/useOrgProjects';
import { Select } from './Select';

type SitePickerForOrgProps = Omit<
  React.ComponentProps<typeof Select>,
  'options'
> & {
  organizationId?: string;
  placeholderWhileLoading?: string;
};

const StyledSelect = styled(Select)`
  width: 15rem;
`;

export function SitePickerForOrg({
  organizationId,
  label = 'Site',
  placeholder = 'Choose a site',
  placeholderWhileLoading = 'Loading sites',
  ...rest
}: SitePickerForOrgProps) {
  const { projects, isLoading } = useOrganizationProjects({ organizationId });
  const projectOptions = useMemo(
    () =>
      (projects || [])
        .map((proj) => ({
          label: proj.name,
          value: proj.id,
        }))
        .sort((p1, p2) => p1.label.localeCompare(p2.label)),
    [projects],
  );

  return (
    <StyledSelect
      {...rest}
      label={label}
      placeholder={isLoading ? placeholderWhileLoading : placeholder}
      options={projectOptions}
    />
  );
}
