import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import * as Sentry from '@sentry/browser';
import BreadcrumbArrowSeparator from '@svg/breadcrumb-arrow-separator.svg';
import BreadcrumbLineSeparator from '@svg/breadcrumb-line-separator.svg';
import classnames from 'classnames';
import { NonEmptyArray } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

const CloseButton = styled(IconButton)`
  min-width: 2rem;
  font-size: 0.875rem;
`;

const BackButton = styled(Button)`
  margin-right: 0.5rem;
`;

const FirstSeparator = styled.span`
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  margin-left: 2.5rem;
`;

const LastSeparator = styled.span`
  margin-top: 0.25rem;
  margin-left: 0.5rem;
`;

const Breadcrumbs = styled(MuiBreadcrumbs)`
  & .MuiBreadcrumbs-ol {
    height: 100%;

    & .MuiBreadcrumbs-li {
      display: flex;
      justify-content: center;
      min-width: 7rem;
    }
  }
`;

const StepNumber = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.gray666};
  border: solid 1px ${({ theme }) => theme.colors.gray666};
  border-radius: 50%;

  & svg {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

const LinkLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray333};

  &.disabled {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray666};
    opacity: 0.5;
  }

  &.selected {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.green};
    -webkit-text-stroke: 0.5px ${({ theme }) => theme.colors.darkerGreen};
    opacity: 1;

    & ${StepNumber} {
      background-color: ${({ theme }) => theme.colors.green};
      border: solid 1px ${({ theme }) => theme.colors.green};
    }
  }
`;

export interface WizardLink {
  completed?: boolean;
  disabled?: boolean;
  label: string;
  to: string;
}

interface WizardHeaderProps {
  canSubmit?: boolean;
  isEditMode: boolean;
  isSubmitting: boolean;
  links: NonEmptyArray<WizardLink>;
  onClose?: React.ComponentProps<typeof IconButton>['onClick'];
  onSubmit?: () => unknown;
  rightActions?: React.ComponentProps<typeof Header>['rightActions'];
  title: string;
  wizardStep: string;
}

export function WizardHeader({
  canSubmit = true,
  isEditMode,
  isSubmitting = false,
  links,
  onClose,
  onSubmit,
  rightActions: rightActionsProp,
  title,
  wizardStep,
}: WizardHeaderProps) {
  const separator = useMemo(
    () =>
      isEditMode ? <BreadcrumbLineSeparator /> : <BreadcrumbArrowSeparator />,
    [isEditMode],
  );

  const isFirstStep = links[0]?.label === wizardStep;
  const isLastStep = links[links.length - 1]?.label === wizardStep;

  const navigate = useNavigate();
  const goToPrevStep = useCallback(() => {
    const currentStepIdx = links.findIndex((s) => s.label === wizardStep);
    const prevStepBreadcrumb = links[currentStepIdx - 1];
    if (!prevStepBreadcrumb) {
      Sentry.captureException(
        '[WizardHeader Error]: goToPrevStep was invoked with non-existing wizardStep or on the first step in the wizard',
        { extra: { wizardStep } },
      );
      return;
    }
    navigate(prevStepBreadcrumb.to);
  }, [links, navigate, wizardStep]);

  const goToNextStep = useCallback(() => {
    const currentStepIdx = links.findIndex((s) => s.label === wizardStep);
    const nextStepBreadcrumb = links[currentStepIdx + 1];
    if (nextStepBreadcrumb) {
      navigate(nextStepBreadcrumb.to);
    } else {
      onClose();
    }
  }, [links, navigate, onClose, wizardStep]);

  const [submitButtonLabel, handleSubmitButtonClick] = useMemo(() => {
    const isCreateMode = !isEditMode;
    if (isCreateMode) {
      const handleSubmit = async () => {
        // when onSubmit is provided, that handles navigating itself
        onSubmit ? await onSubmit() : goToNextStep();
      };

      if (isSubmitting) {
        return ['Saving...', handleSubmit];
      }
      return [isLastStep ? 'Done' : 'Next', handleSubmit];
    }

    if (onSubmit) {
      return [isSubmitting ? 'Saving...' : 'Save changes', onSubmit];
    }

    return ['Done', onClose];
  }, [goToNextStep, isEditMode, isLastStep, isSubmitting, onClose, onSubmit]);

  const rightActions = useMemo(
    () =>
      rightActionsProp || (
        <>
          {!isFirstStep && !isEditMode && (
            <BackButton
              color="primary"
              disabled={isSubmitting}
              onClick={goToPrevStep}
              variant="text"
            >
              Back
            </BackButton>
          )}
          <Button
            color="primary"
            disabled={isSubmitting || !canSubmit}
            onClick={handleSubmitButtonClick}
            variant="contained"
          >
            {submitButtonLabel}
          </Button>
        </>
      ),
    [
      canSubmit,
      goToPrevStep,
      handleSubmitButtonClick,
      isEditMode,
      isFirstStep,
      isSubmitting,
      rightActionsProp,
      submitButtonLabel,
    ],
  );

  return (
    <Header
      centerActions={
        <>
          <FirstSeparator>{separator}</FirstSeparator>
          <Breadcrumbs separator={separator} aria-label="breadcrumb">
            {links.map((link, idx) =>
              idx === 0 || !link.disabled ? (
                <Link key={link.to} to={link.to} end={idx === 0} color="grey">
                  {({ isActive }) => (
                    <LinkLabel className={classnames({ selected: isActive })}>
                      {!isEditMode && (
                        <StepNumber>
                          {link.completed && !isActive ? (
                            <CheckIcon />
                          ) : (
                            idx + 1
                          )}
                        </StepNumber>
                      )}
                      {link.label}
                    </LinkLabel>
                  )}
                </Link>
              ) : (
                <LinkLabel key={link.to} className="disabled">
                  {!isEditMode && <StepNumber>{idx + 1}</StepNumber>}
                  {link.label}
                </LinkLabel>
              ),
            )}
          </Breadcrumbs>
          <LastSeparator>{separator}</LastSeparator>
        </>
      }
      leftActions={
        <CloseButton disabled={isSubmitting} onClick={onClose}>
          <VisuallyHidden>Close</VisuallyHidden>
          <CloseIcon />
        </CloseButton>
      }
      rightActions={rightActions}
      title={title}
    />
  );
}
