import { Api } from '@monorepo/shared/apiClient';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchLogParams;
export const createQueryKey = ({ logId, organizationId }: KeyParams) =>
  ['log', organizationId, logId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createQueryKey, typeof Api.fetchLog>;

type UseLogParams = Partial<Api.FetchLogParams> & {
  config?: QueryConfig<Api.FetchLogResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (_: string, organizationId: string, logId: string) =>
  Api.fetchLog({ logId, organizationId });

export const useLog = ({
  config: inputConfig,
  logId,
  organizationId,
}: UseLogParams) => {
  const isEnabled = !!organizationId && !!logId;
  const config = {
    ...inputConfig,
    enabled:
      inputConfig?.enabled != null
        ? inputConfig.enabled && isEnabled
        : isEnabled,
  };
  const key = isEnabled ? createQueryKey({ logId, organizationId }) : undefined;
  const { data: log, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    log,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useLog>['log'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createQueryKey(keyParams), item);
}
