import { useProject } from '@monorepo/shared/hooks/useProject';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

function addOrgIdentifierToUrl(urlWithoutOrgPart: string, orgId?: string) {
  const sitePortionOfUrl = `/sites/`;
  const orgAndSitePortionOfUrl = `/organizations/${orgId}/sites/`;

  const currentPathWithOrgPortion = urlWithoutOrgPart.replace(
    sitePortionOfUrl,
    orgAndSitePortionOfUrl,
  );

  return currentPathWithOrgPortion;
}

function useAddOrgIdToUrl(urlWithoutOrgPart: string, siteId?: string) {
  const { project, isFetched } = useProject({
    config: { enabled: !!siteId },
    variables: { projectId: siteId || '' },
  });

  if (isFetched) {
    // if organizationId is undefined because something is wrong with the project id or we don't have access to that project
    // we'll still forward to a route with /undefined/ in the URL, and that route needs to render an error
    const organizationId = project?.organizationId;
    return addOrgIdentifierToUrl(urlWithoutOrgPart, organizationId);
  }

  return undefined;
}

export function useRedirectToUrlWithOrgPath(
  urlWithoutOrgPath: string,
  siteId?: string,
) {
  const navigate = useNavigate();
  const urlWithOrgPath = useAddOrgIdToUrl(urlWithoutOrgPath, siteId);

  useEffect(() => {
    if (urlWithOrgPath) {
      navigate(urlWithOrgPath, { replace: true });
    }
  }, [urlWithOrgPath, navigate]);
}
