import { Loading } from '@monorepo/shared/components/Loading';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useProjectsForUser } from '../hooks/useProjectsForUser';
import { Combobox } from './Combobox';

const StyledCombobox = styled(Combobox)`
  width: 100%;
`;

type SitePickerForUserProps = Omit<
  React.ComponentProps<typeof Combobox>,
  'label' | 'options' | 'value'
> & {
  selectedProjectId: string;
};

export function SitePickerForUser({
  selectedProjectId,
  ...props
}: SitePickerForUserProps) {
  const { projects, isLoading } = useProjectsForUser();

  const comboboxOptions = useMemo(() => {
    const sortedProjects =
      projects?.sort((p1, p2) => p1.name.localeCompare(p2.name)) || [];
    return sortedProjects.map((p) => ({ label: p.name, value: p.id }));
  }, [projects]);

  if (isLoading) return <Loading size="small" />;

  return (
    <StyledCombobox
      {...props}
      label="Pick a site"
      value={selectedProjectId}
      options={comboboxOptions}
    />
  );
}
