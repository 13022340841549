import { useDispatch, useSelector } from 'react-redux';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';
import { fetchEmissionRollingCalculationsAction } from '../../actions/air';
import APP from '../../config';
import {
  getIsFetching,
  getRollingCalculations,
} from '../../selectors/genericLogs';
import { GenericLogsRollingCalculation } from '../../types/ts-types';
import useMountEffect from '../useMountEffect';
import { useStableReference } from '../useStableReference';

const deepEqual = _.isEqual;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useRollingCalculations(logProjectId: string) {
  const dispatch = useDispatch();
  const airEmissionRollingCalculations = useSelector((state) =>
    getRollingCalculations(state, logProjectId),
  ) as GenericLogsRollingCalculation[] | undefined;
  const isLoading = useSelector((state) =>
    getIsFetching(state, 'airEmissionsRollingCalculations'),
  ) as boolean;
  useMountEffect(() => {
    dispatch(
      fetchEmissionRollingCalculationsAction(APP.projectId, logProjectId),
    );
  });
  const stableAirEmissionRollingCalculations = useStableReference(
    airEmissionRollingCalculations,
    deepEqual,
  );
  return {
    airEmissionRollingCalculations: stableAirEmissionRollingCalculations,
    isLoading,
  };
}

export { useRollingCalculations };
