import { SwitchField } from '@monorepo/shared/componentsV2/fields/SwitchField';
import React from 'react';
import { ModalFieldWrapper } from '../styled';

export function AlphabetizeOptions() {
  return (
    <ModalFieldWrapper>
      <SwitchField
        label=""
        uncheckedLabel="Display options alphabetically"
        checkedLabel="Display options alphabetically"
        name="isOptionsAlphabetized"
      />
    </ModalFieldWrapper>
  );
}
