import { useLayoutEffect } from 'react';
import { useSubnav } from './useSubnav';

export const useDisableSubnav = () => {
  const { setIsSubnavDisabled } = useSubnav();

  useLayoutEffect(() => {
    setIsSubnavDisabled(true);

    // when unmounting the component, reenable the subnav
    return () => setIsSubnavDisabled(false);
  }, [setIsSubnavDisabled]);
};
