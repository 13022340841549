import { startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { UTCEquivalentOfLocal, localEquivalentOfUTC } from '../../../../utils';
import {
  Button,
  CardOverlay,
  DatePicker,
  FieldWrapper,
  Form,
  FormErrors,
  cardWidths,
} from '../../../elements';
import UserSelectSimple from '../../../elements/user/UserSelectSimple';
import { UserType } from '../../../propTypes';

const formId = 'task-card-filter-settings-form';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class FilterSettings extends Component {
  constructor(props) {
    super(props);
    const { assignees, start, end } = props.filters;
    this.initialValues = {
      assignees: assignees || [],
      start: start ? localEquivalentOfUTC(start) : null,
      end: end ? localEquivalentOfUTC(end) : null,
    };
  }

  handleSave = (formValues) => {
    const { onSave } = this.props;
    const { assignees, start, end } = formValues;
    const newFilters = {};
    if (assignees.length) {
      newFilters.assignees = assignees;
    }
    if (start && end) {
      newFilters.start = UTCEquivalentOfLocal(startOfDay(start));
      newFilters.end = UTCEquivalentOfLocal(startOfDay(end));
    }
    onSave(newFilters);
  };

  validate = (values) => {
    const { start, end } = values;
    const errors = {};
    if (start && !end) {
      errors.end = 'Provide both start and end dates.';
    }
    if (!start && end) {
      errors.start = 'Provide both start and end dates.';
    }
    if (start && end && start >= end) {
      errors.start = 'Start date must be before end date.';
      errors.end = 'End date must be after start date.';
    }
    return errors;
  };

  footer() {
    const { onCancel } = this.props;
    return (
      <>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button form={formId} type="submit">
          Update Filter
        </Button>
      </>
    );
  }

  render() {
    const { onCancel } = this.props;

    return (
      <FinalForm
        onSubmit={this.handleSave}
        initialValues={this.initialValues}
        validate={this.validate}
      >
        {(form) => (
          <Form id={formId} onSubmit={form.handleSubmit}>
            <CardOverlay
              footer={this.footer()}
              title="Filter Settings"
              onCancel={onCancel}
              widthStyle={cardWidths.MEDIUM}
            >
              {form.submitFailed && form.hasValidationErrors && (
                <div className="task-cart-filter-settings__errors">
                  <FormErrors errors={Object.values(form.errors)} />
                </div>
              )}
              <Field name="assignees">
                {(field) => (
                  <FieldWrapper label="Select assignees">
                    <div className="task-cart-filter-settings__field">
                      <UserSelectSimple
                        selected={field.input.value}
                        onChange={(assignees) =>
                          field.input.onChange(assignees)
                        }
                        isMulti
                      />
                    </div>
                  </FieldWrapper>
                )}
              </Field>
              <FieldWrapper label="Select a date range">
                <div className="task-cart-filter-settings__dates task-cart-filter-settings__field">
                  <Field name="start">
                    {(field) => (
                      <DatePicker
                        autoOk
                        className="task-cart-filter-settings__date-field"
                        clearable
                        error={Boolean(field.meta.touched && field.meta.error)}
                        onChange={(date) => field.input.onChange(date)}
                        value={field.input.value || null}
                      />
                    )}
                  </Field>
                  <span className="task-cart-filter-settings__date-separator">
                    to
                  </span>
                  <Field name="end">
                    {(field) => (
                      <DatePicker
                        autoOk
                        className="task-cart-filter-settings__date-field"
                        clearable
                        error={Boolean(field.meta.touched && field.meta.error)}
                        onChange={(date) => field.input.onChange(date)}
                        value={field.input.value || null}
                      />
                    )}
                  </Field>
                </div>
              </FieldWrapper>
            </CardOverlay>
          </Form>
        )}
      </FinalForm>
    );
  }
}

FilterSettings.propTypes = {
  filters: PropTypes.shape({
    assignees: PropTypes.arrayOf(UserType),
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
