import { Api } from '@monorepo/shared/apiClient';
import { LimitItem } from 'mapistry-shared';
import { useQuery } from 'react-query';
import { UseGenericLogDataInputProps } from './types';

export const createQueryKey = ({
  logProjectId,
  projectId,
}: Api.FetchLoggedItemsProps) =>
  ['LIMIT_ITEMS', projectId, logProjectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLimitItems
>;

const fetcher: Fetcher = (
  _: string,
  projectId?: string,
  logProjectId?: string,
) => Api.fetchLimitItems({ logProjectId, projectId });

export const useLimitItems = ({
  config: inputConfig,
  logProjectId,
  projectId,
}: UseGenericLogDataInputProps<Api.FetchLimitItemsProps, LimitItem[]>) => {
  const key = createQueryKey({ logProjectId, projectId });
  const config = {
    ...inputConfig,
    enabled: logProjectId && projectId,
  };
  const { data: limitItems, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    limitItems,
    ...queryInfo,
  };
};
