import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import React from 'react';
import { Route } from 'react-router-dom-v5-compat';
import { UsersPage } from './UsersPage';

export function Users() {
  return (
    <RoutesWith404>
      <Route index element={<UsersPage />} />
    </RoutesWith404>
  );
}
