import { Loading } from '@monorepo/shared/components/Loading';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { useResourceTypes } from '@monorepo/shared/hooks/resources/useResourceTypes';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { ResourceTypeEditMode } from '../routerTypes';
import { ViewResourceTypesHeader } from './ViewResourceTypesHeader';
import { ViewResourceTypesTable } from './ViewResourceTypesTable';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledViewResourceTypesTable = styled(ViewResourceTypesTable)`
  ${fillHeightAndScrollable}
`;

function ViewResourceTypesContent() {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error(
      "ViewResourceTypes page was rendered with url that doesn't have organizationId",
    );
  }
  const { resourceTypes, isLoading } = useResourceTypes({
    organizationId,
  });
  const navigate = useNavigate();
  const [filterBy, setFilterBy] = useState('');

  const handleAddNew = useCallback(() => {
    navigate(ResourceTypeEditMode.NEW);
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  const hasResourceTypes = !!resourceTypes?.length;

  return (
    <Page>
      <ViewResourceTypesHeader
        onSearchChange={
          hasResourceTypes
            ? (event) => setFilterBy(event.target.value)
            : undefined
        }
        onAddNew={handleAddNew}
        organizationId={organizationId}
      />
      <StyledViewResourceTypesTable
        filterBy={filterBy}
        onAddNew={handleAddNew}
      />
    </Page>
  );
}

export const ViewResourceTypes = () => (
  <ErrorBoundary>
    <ViewResourceTypesContent />
  </ErrorBoundary>
);
