import { CustomFrequencyValidator } from '@monorepo/shared/utils/CustomFrequencyValidator';
import {
  compose,
  isDate,
  isDateOrNullish,
  isRequired,
} from '@monorepo/shared/utils/validators';
import { FrequencyResponse } from 'mapistry-shared';
import React from 'react';
import { useField } from 'react-final-form';
import { FrequencyPicker } from '../FrequencyPicker';
import { FrequencyPickerErrors } from '../FrequencyPicker/FrequencyPicker';

type FrequencyPickerFieldProps = Omit<
  React.ComponentProps<typeof FrequencyPicker>,
  'value' | 'onChange'
> & {
  initialValue?: FrequencyResponse;
  name: string;
};

export function FrequencyPickerField({
  initialValue,
  name,
  startDateIsRequired,
  ...rest
}: FrequencyPickerFieldProps) {
  // build validator function for the full object
  const validate = (value?: Partial<FrequencyResponse>) => {
    let errors: FrequencyPickerErrors | undefined;
    if (!value?.intervalFrequency) {
      errors = { ...errors, intervalFrequency: 'Required' };
    }
    const startDateValidation = startDateIsRequired
      ? compose(isRequired, isDate)
      : isDateOrNullish;
    const startDateError = startDateValidation(value?.startDate, {});
    if (startDateError) {
      errors = { ...errors, startDate: startDateError };
    }
    if (value?.customFrequency && value?.intervalFrequency) {
      const customFrequencyValidationResults = new CustomFrequencyValidator(
        value.intervalFrequency,
        value.customFrequency,
      ).validate();
      if (customFrequencyValidationResults.messages?.length) {
        errors = {
          ...errors,
          customFrequency: customFrequencyValidationResults,
        };
      }
    }
    return errors;
  };

  const { input, meta } = useField<FrequencyResponse>(name, {
    validate,
    initialValue,
  });
  return (
    <FrequencyPicker
      errors={(meta.touched || meta.modified) && meta.error}
      onChange={input.onChange}
      startDateIsRequired={startDateIsRequired}
      value={input.value}
      {...rest}
    />
  );
}
