import {
  Chart,
  ChartCard,
  ChartCardSplashOverlay,
  Modal,
} from '@monorepo/old-web/js/components/elements';
import useWidgetDetails from '@monorepo/old-web/js/hooks/widgets/useWidgetDetails';
import {
  GenericLogType,
  Interval,
  IntervalFrequencyFactory,
  LimitItemResourceType,
  UTCEquivalentOfLocal,
  UnitString,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useLimitItems } from '../../../hooks/useLimitItems';
import { useResources } from '../../../hooks/useResources';
import { useStatistics } from '../../../hooks/useStatistics';
import { LimitMonitorChart } from './LimitMonitorChart';
import { LimitMonitoringSettingsForm } from './LimitMonitoringSettingsForm';

type LimitMonitoringWidgetSettings = {
  resources: { resourceId: string; resourceType: LimitItemResourceType }[];
  widgetName: string;
};

type ResourceKeyWithLimitAndOtherParams = Interval & {
  limits: string[];
  resourceId: string;
  resourceType: LimitItemResourceType;
  unit: UnitString;
};

type LimitMonitoringCardProps = {
  logProjectId: string;
  projectId: string;
  widgetDefaultName: string;
};

export const LimitMonitoringCard = (props: LimitMonitoringCardProps) => {
  const { projectId, logProjectId, widgetDefaultName } = props;

  const { isLoading: limitItemsAreLoading, limitItems } = useLimitItems({
    logProjectId,
    projectId,
  });
  const { isLoading: resourcesAreLoading, resources } = useResources({
    logProjectId,
    projectId,
    resourceTypes: [
      GenericLogType.CALCULATED_VALUE,
      GenericLogType.LOGGED_ITEM,
      GenericLogType.ROLLING_CALCULATION,
    ],
  });
  const {
    widgetId,
    widgetSettings,
    updateWidget,
    widgetSettingsModalIsVisible,
    toggleSettingsModalVisibility,
    menuOptions,
  } = useWidgetDetails<LimitMonitoringWidgetSettings>();

  const navigateToSettings = useCallback(
    (hash: string) => {
      const logSettingsUrl = `/projects/${projectId}/custom-logs/${logProjectId}/settings/${hash}`;
      window.location.href = logSettingsUrl;
    },
    [logProjectId, projectId],
  );
  const resourceKeysWithParams = useMemo(() => {
    if (!(widgetSettings && limitItems && resources)) return undefined;
    const now = new Date();
    return widgetSettings.resources.reduce<
      ResourceKeyWithLimitAndOtherParams[]
    >((acc, resourceKey) => {
      const resourceLimits = limitItems.filter(
        (li) => li.resourceId === resourceKey.resourceId,
      );
      const resourceLimitKeys = resourceLimits.map((li) => {
        const interval =
          IntervalFrequencyFactory.For(
            li.limitFrequency,
            li.limitCustomFrequency,
          ).getInterval(now) ?? ({} as Interval);
        return {
          ...resourceKey,
          end: UTCEquivalentOfLocal(interval.end),
          limits: [li.id],
          start: UTCEquivalentOfLocal(interval.start),
          unit: li.units,
        };
      });
      acc.push(...resourceLimitKeys);
      return acc;
    }, []);
  }, [widgetSettings, limitItems, resources]);
  const { statistics, isLoading: statisticsAreLoading } = useStatistics({
    logProjectId,
    projectId,
    resources: resourceKeysWithParams,
  });

  const isLoading =
    limitItemsAreLoading || resourcesAreLoading || statisticsAreLoading;
  const hasChartData = !!statistics?.length;
  const widgetTitle = widgetSettings?.widgetName || widgetDefaultName;

  return (
    <>
      {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
      <Modal
        fullWidth={false}
        header={<h4 className="modal-title">{widgetTitle}</h4>}
        maxWidth="sm"
        onClose={toggleSettingsModalVisibility}
        open={widgetSettingsModalIsVisible}
      >
        <LimitMonitoringSettingsForm
          initialValues={widgetSettings}
          onSubmit={(values) => {
            updateWidget(widgetId, values);
            toggleSettingsModalVisibility();
          }}
          resources={resources}
        />
      </Modal>
      <ChartCard
        title={widgetTitle}
        hasChartData={hasChartData}
        isLoading={isLoading}
        menuOptions={menuOptions}
        renderSplashCard={() => {
          if (!resources?.length) {
            return (
              <ChartCardSplashOverlay
                ctaText="Add logged items"
                onEngage={() => navigateToSettings('logged-items')}
              >
                You currently have no logged items.
              </ChartCardSplashOverlay>
            );
          }
          if (!limitItems?.length) {
            return (
              <ChartCardSplashOverlay
                ctaText="Add limits"
                onEngage={() => navigateToSettings('limit-items')}
              >
                You currently have no limits.
              </ChartCardSplashOverlay>
            );
          }
          return (
            <ChartCardSplashOverlay
              ctaText="Filter settings"
              onEngage={toggleSettingsModalVisibility}
            >
              To view emission limit status, configure settings by clicking the
              button below.
            </ChartCardSplashOverlay>
          );
        }}
        renderLoadingCard={() => <Chart isLoading={isLoading} />}
        renderGraphCard={() => (
          <LimitMonitorChart
            resources={resourceKeysWithParams}
            statistics={statistics}
          />
        )}
      />
    </>
  );
};
