import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum LogCalendarEventCacheEvents {
  UPDATE = 'UPDATE',
}

interface CacheParams {
  projectId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof LogCalendarEventCacheEvents,
  CacheParams
>(LogCalendarEventCacheEvents);

export const invalidateLogCalendarEventCache = cacheRegister.invalidate;
export const logCalendarEventCacheRegister = cacheRegister.register;
