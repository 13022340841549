import {
  removeFromCache as removeEmbedUrlFromCache,
  useSigmaEmbedUrl,
} from '@monorepo/analytics/src/hooks/useSigmaEmbedUrl';
import { DocumentTitle } from '@monorepo/shared/componentsV2/DocumentTitle';
import { UserFriendlyErrorWithExtraDetails } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Error404Page } from '@monorepo/shared/componentsV2/errorDisplays/Error404Page';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useHandleSigmaWindowEvents } from '../hooks/useHandleSigmaWindowEvents';

export const AnalyticsDashboard = () => {
  const { dashboardId } = useParams();

  const { embedResponse: { url: embedUrl } = {}, isLoading } =
    useSigmaEmbedUrl(dashboardId);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { dashboardLoadingError } = useHandleSigmaWindowEvents(
    iframeRef.current?.contentWindow || undefined,
  );

  /**
   * a sigma embed link can only be used one time, and then it is invalid
   * when the component unmounts, delete it from the RQ cache so we get a fresh embed URL when we return
   */
  useEffect(() => removeEmbedUrlFromCache, []);

  if (dashboardLoadingError) {
    const { code: errorCode, message } = dashboardLoadingError;
    if (errorCode === 'ENOENT') return <Error404Page />;

    // this could mean something wrong with the configuration (duplicate teams, account type is invalid, etc)
    throw new UserFriendlyErrorWithExtraDetails(
      'Error loading dashboard. Please try again later.',
      { sigmaErrorCode: errorCode, sigmaErrorMessage: message },
    );
  }

  return (
    <>
      <DocumentTitle title="Analytics" />
      {isLoading && <Loading />}
      <iframe
        src={isLoading ? '' : embedUrl}
        frameBorder="0"
        width="100%"
        height="100%"
        title="Analytics"
        style={isLoading ? { display: 'none' } : {}}
        ref={iframeRef}
      />
    </>
  );
};
