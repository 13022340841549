import { ComboboxField } from '@monorepo/shared/componentsV2/fields/ComboboxField';
import {
  MathUnit,
  NullableNumber,
  NumericFieldResponse,
  NumericValue,
  SaveFieldValueRequest,
  SaveNumericValue,
} from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import { StyledInput } from '../../../styled/form';
import { FormValues } from './types';
import {
  getUnexpectedTypeErrorAndLogToSentry,
  getValidateFunction,
  nullToUndefined,
} from './utils';

const Container = styled.div`
  display: flex;
`;

const UnitsCombobox = styled(ComboboxField)`
  width: 10rem;
  margin-left: 0.5rem;
`;

type EditNumericFieldValueProps = {
  className?: string;
  field: NumericFieldResponse;
  fieldValue?: NumericValue;
  isRequired: boolean;
  label: string;
  name: string;
};

export function EditNumericFieldValue({
  className,
  field,
  fieldValue,
  isRequired,
  label,
  name,
}: EditNumericFieldValueProps) {
  return (
    <Container className={className}>
      <StyledInput
        label={label}
        name={name}
        type="number"
        // the underlying implementation is an HTML input element, so even type='number'
        //  works with strings
        initialValue={nullToUndefined(fieldValue?.value?.toString())}
        validate={getValidateFunction(isRequired)}
        required={isRequired}
      />
      {field.units && (
        <UnitsCombobox
          options={MathUnit.getRelatedSelectableUnits(field.units)}
          initialValue={fieldValue?.givenUnits || field.units}
          label="Units"
          name={`${name}_unit`}
        />
      )}
    </Container>
  );
}

export function getNumericFieldValueFromForm(
  field: NumericFieldResponse,
  formValues: FormValues,
): SaveFieldValueRequest {
  const value = formValues[field.id] == null ? null : formValues[field.id];
  let parsedValue: NullableNumber;

  if (value === null) {
    parsedValue = value;
  } else if (typeof value === 'string') {
    parsedValue = parseFloat(value);
    if (Number.isNaN(parsedValue)) {
      throw getUnexpectedTypeErrorAndLogToSentry(field, value);
    }
  } else {
    throw getUnexpectedTypeErrorAndLogToSentry(field, value);
  }

  const givenUnits = formValues[`${field.id}_unit`];

  if (
    givenUnits != null &&
    !(typeof givenUnits === 'string' && MathUnit.isValid(givenUnits))
  ) {
    throw new Error(
      `Unexpected value provided for units of ${field.name} (${givenUnits}).`,
    );
  }

  const fieldValue: SaveNumericValue = {
    value: parsedValue,
    givenUnits: givenUnits || null,
  };
  return {
    fieldId: field.id,
    fieldValue,
  };
}
