import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useRedirectToUrlWithOrgPath } from '@monorepo/shared/hooks/navigation/useRedirectToUrlWithOrgPath';
import React, { useEffect } from 'react';
import { Route, useNavigate, useParams } from 'react-router-dom-v5-compat';

function RedirectToSiteHome() {
  const { projectId } = useParams();
  const newUrl = `/vortex/sites/${projectId}`;

  useRedirectToUrlWithOrgPath(newUrl, projectId);
  return <Loading />;
}

function RedirectToTaskUrl() {
  const { projectId, taskId, dueDate } = useParams();

  let taskUrl = `/vortex/sites/${projectId}/tasks?taskId=${taskId}`;
  if (dueDate) {
    taskUrl += `&dueDate=${dueDate}`;
  }

  useRedirectToUrlWithOrgPath(taskUrl, projectId);
  return <Loading />;
}

function RedirectToAnalyticsUrl() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/vortex/analytics', { replace: true });
  }, [navigate]);
  return <Loading />;
}

export function BackboneAppUrlsToRedirectToVortexUrls() {
  return (
    <>
      <Route path="dashboard/projects/:projectId">
        <Route index element={<RedirectToSiteHome />} />
        <Route path="tasks/:taskId/:dueDate" element={<RedirectToTaskUrl />} />
        <Route path="tasks/:taskId" element={<RedirectToTaskUrl />} />
      </Route>
      <Route path="analytics/*" element={<RedirectToAnalyticsUrl />} />
    </>
  );
}
