import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useLinkOnSameLog } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameLog';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../../contexts/EditViewInfoModalContext';
import { LogViewsTable } from './LogViewsTable';
import { ViewsToolbar } from '../ViewsToolbar';

const Container = styled(Main)`
  ${verticalLayout}
`;

const StyledLogViewsTable = styled(LogViewsTable)`
  ${fillHeightAndScrollable}
`;

export function LogViewsTab() {
  const navigate = useNavigate();
  const linkToNewViewPage = useLinkOnSameLog('/views/new');

  const navigateToNewViewPage = useCallback(() => {
    navigate(linkToNewViewPage);
  }, [linkToNewViewPage, navigate]);

  return (
    <ErrorBoundary>
      <EditViewInfoModalProvider>
        <Container>
          <ViewsToolbar
            onCreateNewView={navigateToNewViewPage}
            title="All Views"
          />
          <StyledLogViewsTable />
        </Container>
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}
