import ActionItemsWidget from '@monorepo/old-web/js/components/views/projectDashboard/TasksCard';
import { CurrentUserProvider } from '@monorepo/old-web/js/contexts/CurrentUserContext';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useSkipWidgetLink } from '@monorepo/shared/componentsV2/SkipWidgetLink';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import React from 'react';

import { useSearchParams } from 'react-router-dom-v5-compat';

export function ActionItemsWidgetWrapper() {
  const { project } = useCurrentProject();

  const [query] = useSearchParams();
  const taskId = query.get('taskId');

  const { SkipLink, SkipLinkTarget } = useSkipWidgetLink();

  if (!project) {
    return <Loading />;
  }

  return (
    <CurrentUserProvider>
      <SkipLink linkText="Skip Action Items" />
      <ActionItemsWidget key={`${project?.id} ${taskId}`} />
      <SkipLinkTarget />
    </CurrentUserProvider>
  );
}
