import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiFormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import { FieldDataType, FieldResponse, NonEmptyArray } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { focusOutline } from '../../../styles/focus';
import { isRequired } from '../../../utils/validators';
import { Main } from '../../Main';
import { VisuallyHidden } from '../../VisuallyHidden';
import { fieldTypeOptions } from '../fieldTypeOptions';

const FormLabel = styled(MuiFormLabel)`
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.gray333};

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray333};
  }
` as typeof MuiFormLabel;

const RadioButton = styled(Card)`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  width: 9rem;
  padding: 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray333};
  text-align: left;
  border: solid 1px ${({ theme }) => theme.colors.grayddd};
  box-shadow: none;

  & svg {
    height: 1.5rem;
    margin-right: 1rem;
    fill: ${({ theme }) => theme.colors.gray333};
  }

  &.selected {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.lightBlue};

    & svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const RadioGroup = styled(MuiRadioGroup)`
  flex-direction: row;
  width: 26rem;

  & .MuiIconButton-root {
    padding: 0;
    margin: 0.625rem 1.25rem 0.625rem 0;
    border-radius: 0.25rem;

    &:hover {
      background-color: inherit;
      box-shadow: 0 4px 8px ${({ theme }) => theme.colors.black30};
    }

    &.Mui-checked {
      &:hover {
        background-color: ${({ theme }) => theme.colors.darkBlue};
      }
    }

    &.Mui-focusVisible {
      ${focusOutline()}
    }
  }
`;

type DataTypeRadioProps = RadioProps & {
  label: React.ReactNode;
};

function DataTypeRadio({ label, ...props }: DataTypeRadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioButton className="selected">{label}</RadioButton>}
      icon={<RadioButton>{label}</RadioButton>}
      {...props}
    />
  );
}

interface FieldDataTypeSelectorProps {
  availableFieldTypes: NonEmptyArray<FieldDataType>;
  fieldTypeName?: string;
}

export function FieldDataTypeSelector({
  availableFieldTypes,
  fieldTypeName = 'field',
}: FieldDataTypeSelectorProps) {
  const { input, meta } = useField<FieldResponse['type']>('type', {
    validate: isRequired,
  });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  const onChange = useCallback((type) => () => input.onChange(type), [input]);
  return (
    <Main>
      <FormControl component="fieldset" error={hasError}>
        <FormLabel component="legend">
          {`Choose the type of data that you need for your ${fieldTypeName}.`}
        </FormLabel>
        <RadioGroup
          aria-label="field data type"
          name="type"
          value={input.value}
        >
          {availableFieldTypes.map((type) => (
            <React.Fragment key={type}>
              <VisuallyHidden>
                <InputLabel htmlFor={`field-data-type-${type}`}>
                  {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
                  {fieldTypeOptions[type].label}
                </InputLabel>
              </VisuallyHidden>
              <DataTypeRadio
                id={`field-data-type-${type}`}
                label={
                  <>
                    {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
                    {fieldTypeOptions[type].icon}
                    {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
                    {fieldTypeOptions[type].label}
                  </>
                }
                onChange={onChange(type)}
                value={type}
              />
            </React.Fragment>
          ))}
        </RadioGroup>
        {hasError ? <FormHelperText>{meta.error}</FormHelperText> : null}
      </FormControl>
    </Main>
  );
}
