// adding this 'material-ui' ThemeProvider in order to account for old-web - Vortex uses the 'styled-components' ThemeProvider
import { ThemeProvider } from '@material-ui/core/styles';
import { LimitMonitoringWidget } from '@monorepo/genericLogs/src/components/widgets/LimitMonitoringWidget';
import { ChartWidget } from '@monorepo/old-web/js/components/genericLogs/ChartWidget';
import GenericLogsCalendar from '@monorepo/old-web/js/components/genericLogs/GenericLogsCalendar';
import { InspectionFormTheme } from '@monorepo/old-web/js/components/themes/inspectionFormTheme';
import SiteSummaryDashboard from '@monorepo/old-web/js/components/views/projectDashboard/SiteSummaryDashboard';
import { CurrentUserProvider } from '@monorepo/old-web/js/contexts/CurrentUserContext';
import { FeatureFlagsProvider } from '@monorepo/old-web/js/contexts/FeatureFlagsContext';
import Widget from '@monorepo/old-web/js/types/Widget';
import { useProjectCalendars } from '@monorepo/shared/hooks/useProjectCalendars';
import { CalendarName, CalendarType, Category } from 'mapistry-shared';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const StyledWidgets = styled.div`
  margin-top: -1.75rem;
  margin-left: 0.6rem;
`;

type Calendar = {
  category: Category;
  id: string;
  isDownloadable: boolean;
  logProjectId: string;
  name: CalendarName | string;
  summaryTitle: string;
  type: CalendarType;
};

type CreateWidgetsProps = {
  isLoading: boolean;
  projectCalendars: Calendar[];
  projectId?: string;
  projectName?: string;
};

const createWidgets = ({
  isLoading,
  projectCalendars,
  projectId,
  projectName,
}: CreateWidgetsProps) => {
  if (!projectId || !projectName || isLoading) {
    return null;
  }

  const widgets: Widget[] = [];
  projectCalendars
    // this page only shows generic log calendars
    .filter((calendar: Calendar) => calendar.type === CalendarType.GENERIC_LOG)
    .forEach((calendar: Calendar) => {
      if (!calendar.summaryTitle) {
        return;
      }

      const widget = new Widget(calendar.summaryTitle, () => (
        <GenericLogsCalendar
          {...calendar}
          projectName={projectName}
          widgetSettingsType={calendar.logProjectId}
        />
      ));
      widget.wrapper = false;
      widgets.push(widget);

      const lastInAlphabet = 'ZZZZZZZZZZZZZZZ'; // hackily floating duplicable widget groups to the bottom
      const chartWidget = new Widget(lastInAlphabet, (filterQuery) => (
        <>
          <ChartWidget
            projectId={projectId}
            logProjectId={calendar.logProjectId}
            widgetDefaultName={`Logs over time | ${calendar.summaryTitle}`}
            filterQuery={filterQuery || ''}
          />
          <LimitMonitoringWidget
            projectId={projectId}
            logProjectId={calendar.logProjectId}
            widgetDefaultName={`Limit Tracking | ${calendar.summaryTitle}`}
            filterQuery={filterQuery || ''}
          />
        </>
      ));
      chartWidget.handlesFilteringItself = true;
      chartWidget.wrapper = false;
      widgets.push(chartWidget);
    });

  return widgets;
};

export const useCreateWidgetRenderMap = (
  projectId?: string,
  projectName?: string,
) => {
  const { projectCalendars, isLoading } = useProjectCalendars({ projectId });

  return useMemo(
    () =>
      createWidgets({ projectId, projectCalendars, projectName, isLoading }),
    [isLoading, projectCalendars, projectId, projectName],
  );
};

interface LogWidgetsProps {
  filter: string;
  widgets: Widget[];
}

export const LogsWidgets = ({ filter, widgets }: LogWidgetsProps) => {
  const featureFlags = useFeatureFlags();

  if (widgets.length === 0) return null;
  return (
    <CurrentUserProvider>
      <ThemeProvider theme={InspectionFormTheme}>
        <StyledWidgets className="dashboard-section">
          <FeatureFlagsProvider featureFlags={featureFlags}>
            <SiteSummaryDashboard filter={filter} dates="" widgets={widgets} />
          </FeatureFlagsProvider>
        </StyledWidgets>
      </ThemeProvider>
    </CurrentUserProvider>
  );
};
