import APP from '@monorepo/old-web/js/config';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useLogsWithSiteConfig } from '@monorepo/shared/hooks/logs/useLogsWithSiteConfig';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import styled from 'styled-components';
import { useLogsAndViewsPageContext } from '../../contexts/LogsAndViewsPageContext';
import { LogTable } from '../LogTable';
import { EmptyLogsSalesPage } from './EmptyLogsSalesPage';
import {
  LogsWidgets,
  useCreateWidgetRenderMap,
} from './GenericLogs/LogsWidgets';

const StyledLoading = styled(Loading)`
  margin-top: 5rem;
`;

export function LogsPageContent() {
  const { project } = useCurrentProject();
  const { isData2Enabled } = useFeatureFlags();
  const { setIsSearchVisible, searchFilter } = useLogsAndViewsPageContext();

  APP.projectId = project?.id; // Legacy code grabs projectId from the APP object

  const { logsWithSiteConfig = [], isLoading: isLoadingData2 } =
    useLogsWithSiteConfig({
      organizationId: project?.organization_id,
      projectId: project?.id,
      config: { enabled: !!isData2Enabled }, // boolean-ize this so we don't start loading logs if the feature flag hasn't loaded yet
    });

  const widgets = useCreateWidgetRenderMap(project?.id, project?.name);

  if (isLoadingData2 || !widgets) {
    return <StyledLoading />;
  }

  const hasLogsOnPage =
    (isData2Enabled && logsWithSiteConfig.length > 0) || widgets.length > 0;
  setIsSearchVisible(hasLogsOnPage);

  return (
    <>
      {isData2Enabled && (
        <LogTable
          filter={searchFilter}
          logsWithSiteConfig={logsWithSiteConfig}
        />
      )}
      <LogsWidgets filter={searchFilter} widgets={widgets} />
      {!hasLogsOnPage && <EmptyLogsSalesPage />}
    </>
  );
}
