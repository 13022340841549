import React from 'react';
import EventChoiceMenu from '../CalendarCard/EventChoiceMenu';

export const InspectionEventChoiceMenu = ({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  menuAnchor,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  eventChoices,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onEventClick,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  closeEventChoiceMenu,
}) => {
  const formatDate = (date) => {
    const abbreviatedMonths = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    return `${
      abbreviatedMonths[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  };

  const getAsNeededItemText = (calendarEvent) => {
    const existingEventText = `Inspection #${calendarEvent.eventNumber}`;
    const itemText = calendarEvent.attachments[0]?.isDefault
      ? 'Create a new inspection'
      : existingEventText;

    return itemText;
  };

  const getRecurringEventItemText = (calendarEvent) =>
    `Inspection #${calendarEvent.eventNumber} - Due Date: ${formatDate(
      calendarEvent.dueDate,
    )}`;

  return (
    <EventChoiceMenu
      eventChoices={eventChoices}
      menuAnchor={menuAnchor}
      onClose={closeEventChoiceMenu}
      onEventClick={onEventClick}
      getAsNeededItemText={getAsNeededItemText}
      getRecurringEventItemText={getRecurringEventItemText}
    />
  );
};
