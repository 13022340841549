import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TopLevelErrorBoundary } from '@monorepo/shared/componentsV2/errorDisplays/TopLevelErrorBoundary';
import { ModalProvider } from '@monorepo/shared/contexts/ModalContextV2';
import { ReactQueryConfig } from '@monorepo/shared/contexts/ReactQueryConfig';
import { SubnavProvider } from '@monorepo/shared/contexts/SubnavContext';
import { ToastProvider } from '@monorepo/shared/contexts/ToastContext';
import { DefaultThemeProvider } from '@monorepo/shared/themes/DefaultThemeProvider';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { AuthProvider } from '../contexts/AuthContext';
import { WebSocketSetup } from '../contexts/pushMessages/WebSocketSetup';

interface ProvidersProps {
  children: React.ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  return (
    <ReactQueryConfig>
      <DefaultThemeProvider>
        <HelmetProvider>
          <ToastProvider>
            <TopLevelErrorBoundary>
              <AuthProvider>
                <WebSocketSetup>
                  <SubnavProvider>
                    <ModalProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <BrowserRouter>
                          <CompatRouter>{children}</CompatRouter>
                        </BrowserRouter>
                      </MuiPickersUtilsProvider>
                    </ModalProvider>
                  </SubnavProvider>
                </WebSocketSetup>
              </AuthProvider>
            </TopLevelErrorBoundary>
          </ToastProvider>
        </HelmetProvider>
      </DefaultThemeProvider>
    </ReactQueryConfig>
  );
}
