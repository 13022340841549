import AnnualEvaluationCalendarCard from '@monorepo/old-web/js/components/views/projectDashboard/AnnualEvaluationCalendarCard';
import Covid19ResponseCard from '@monorepo/old-web/js/components/views/projectDashboard/Covid19ResponseCard';
import FixedFrequencyInspectionCalendarCard from '@monorepo/old-web/js/components/views/projectDashboard/FixedFrequencyInspectionCalendarCard';
import InspectionCalendarCard from '@monorepo/old-web/js/components/views/projectDashboard/InspectionCalendarCard';
import {
  OneTimeCalendar,
  OneTimeFormCalendarCard,
} from '@monorepo/old-web/js/components/views/projectDashboard/OneTimeFormCalendarCard';
import SiteSummaryDashboard from '@monorepo/old-web/js/components/views/projectDashboard/SiteSummaryDashboard';
import { CurrentUserProvider } from '@monorepo/old-web/js/contexts/CurrentUserContext';
import { FeatureFlagsProvider } from '@monorepo/old-web/js/contexts/FeatureFlagsContext';
import Widget from '@monorepo/old-web/js/types/Widget';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { useProjectCalendars } from '@monorepo/shared/hooks/useProjectCalendars';
import { useProjectModules } from '@monorepo/shared/hooks/useProjectModules';
import {
  CalendarType,
  ProjectModule,
  ProjectModuleName,
} from 'mapistry-shared';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const Spacer = styled.div`
  padding-top: 1rem;
`;

type InspectionsWidgetCalendarType = Exclude<
  CalendarType,
  CalendarType.BASE | CalendarType.GENERIC_LOG
>;

type Calendar = {
  name: string;
  summaryTitle: string;
  type: InspectionsWidgetCalendarType;
};

type CreateWidgetsProps = {
  isLoading: boolean;
  organizationId?: string;
  projectCalendars: Calendar[];
  projectId?: string;
  projectModules: ProjectModule[];
};

const TASK_WIDGET_CALENDAR_TYPES = new Set<InspectionsWidgetCalendarType>([
  CalendarType.ANNUAL_EVALUATION,
  CalendarType.CUSTOM_FORM,
  CalendarType.FIXED_FREQUENCY,
  CalendarType.MODULE_FORM,
  CalendarType.ONE_TIME_FORM,
]);

const createWidgets = ({
  isLoading,
  organizationId,
  projectCalendars,
  projectId,
  projectModules,
}: CreateWidgetsProps) => {
  if (isLoading) {
    return [];
  }

  const renderCalendar = (calendar: Calendar) => {
    switch (calendar.type) {
      case CalendarType.FIXED_FREQUENCY:
        return (
          <FixedFrequencyInspectionCalendarCard
            calendar={calendar}
            organizationId={organizationId}
            projectId={projectId}
          />
        );
      case CalendarType.ANNUAL_EVALUATION:
        return (
          <AnnualEvaluationCalendarCard
            calendar={calendar}
            organizationId={organizationId}
            projectId={projectId}
          />
        );
      case CalendarType.CUSTOM_FORM:
      case CalendarType.MODULE_FORM:
        return (
          <InspectionCalendarCard
            calendar={calendar}
            organizationId={organizationId}
            projectId={projectId}
          />
        );
      case CalendarType.ONE_TIME_FORM:
        return (
          <OneTimeFormCalendarCard
            calendar={calendar as OneTimeCalendar}
            organizationId={organizationId}
            projectId={projectId}
          />
        );
      default: {
        const exhaustiveCheck: never = calendar.type;
        return exhaustiveCheck;
      }
    }
  };

  const widgets: Widget[] = [];
  projectCalendars
    // this page excludes logs widgets
    ?.filter((calendar) => TASK_WIDGET_CALENDAR_TYPES.has(calendar.type))
    .forEach((calendar) => {
      if (!calendar.summaryTitle) {
        return;
      }

      const widget = new Widget(calendar.summaryTitle, () =>
        renderCalendar(calendar),
      );

      widgets.push(widget);
    });

  if (
    projectModules?.find(
      (pm) => pm.name === ProjectModuleName.COVID_19_RESPONSE_BUILDER,
    )
  ) {
    widgets.push(
      new Widget('COVID-19 Response Plan Builder', () => (
        <Covid19ResponseCard />
      )),
    );
  }

  return widgets;
};

const useCreateWidgetRenderMap = (
  projectId?: string,
  organizationId?: string,
) => {
  const { projectCalendars, isLoading } = useProjectCalendars({ projectId });
  const { projectModules } = useProjectModules({ projectId });

  return useMemo(
    () =>
      createWidgets({
        organizationId,
        projectId,
        projectCalendars,
        projectModules,
        isLoading,
      }),
    [isLoading, organizationId, projectCalendars, projectId, projectModules],
  );
};

type InspectionWidgetsProps = {
  filter?: string;
};

export const InspectionWidgets = ({ filter }: InspectionWidgetsProps) => {
  const { project } = useCurrentProject();
  const featureFlags = useFeatureFlags();
  const widgets = useCreateWidgetRenderMap(
    project?.id,
    project?.organization_id,
  );

  return (
    <CurrentUserProvider>
      <Spacer className="dashboard-section">
        <FeatureFlagsProvider featureFlags={featureFlags}>
          <SiteSummaryDashboard filter={filter} dates="" widgets={widgets} />
        </FeatureFlagsProvider>
      </Spacer>
    </CurrentUserProvider>
  );
};
