import DuplicateIcon from '@svg/duplicate-cards.svg';
import TrashIcon from '@svg/trash.svg';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import APP from '../../config';
import withProvider from '../withProvider';

import {
  deleteCurrentUserWidgetSettingAction,
  fetchCurrentUserWidgetSettingAction,
  setCurrentUserWidgetSettingAction,
} from '../../actions/currentUser';

import UserSettings from '../../types/UserSettings';

const withDuplication = (WrappedComponent, widgetSettingsType) => {
  class DuplicableWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {
        shouldCleanUpWidgetSettings: null,
      };
    }

    componentDidMount() {
      const { fetchCurrentUserWidgetSetting } = this.props;
      fetchCurrentUserWidgetSetting();
    }

    getOptions = (savedWidgetSettings) => {
      const { duplications } = this.props;
      const removeOption = {
        label: 'Remove',
        icon: <TrashIcon className="m-icon" />,
        danger: true,
        onClick: () =>
          this.removeSavedWidgetSettings(savedWidgetSettings.widgetSettingsId),
      };
      const options = [
        {
          label: 'Duplicate',
          icon: <DuplicateIcon className="m-icon" />,
          onClick: () => this.duplicateSavedWidgetSettings(savedWidgetSettings),
        },
      ];
      if (duplications && Object.keys(duplications).length > 1) {
        return [...options, removeOption];
      }
      return options;
    };

    duplicateSavedWidgetSettings = (savedWidgetSettings) => {
      const { duplications } = this.props;
      const updatedWidgetSettings = {
        ...duplications,
        [uuidv4().toUpperCase()]: savedWidgetSettings.widgetSettings,
      };
      this.saveWidgetSettings(updatedWidgetSettings);
    };

    removeSavedWidgetSettings = (widgetSettingsId) => {
      const { deleteCurrentUserWidgetSetting } = this.props;
      this.setState({ shouldCleanUpWidgetSettings: widgetSettingsId });
      deleteCurrentUserWidgetSetting(widgetSettingsId);
    };

    updateSavedWidgetSettings = (
      savedWidgetSettingsId,
      savedWidgetSettings,
    ) => {
      const { duplications } = this.props;
      const updatedWidgetSettings = {
        ...duplications,
        [savedWidgetSettingsId]: savedWidgetSettings,
      };
      this.saveWidgetSettings(updatedWidgetSettings);
    };

    saveWidgetSettings = (widgetSettings) => {
      const { setCurrentUserWidgetSetting } = this.props;
      const type = // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/destructuring-assignment
        (widgetSettingsType || this.props.widgetSettingsType).toUpperCase();
      setCurrentUserWidgetSetting({
        settingKey: UserSettings.DUPLICATED_WIDGETS,
        settingValue: {
          type,
          widgetSettings,
        },
      });
    };

    renderEmpty = () => {
      const { shouldCleanUpWidgetSettings } = this.state;
      const newWidgetSettings = {
        widgetSettingsId: uuidv4().toUpperCase(),
        widgetSettings: {},
      };
      return (
        <section
          key={newWidgetSettings.widgetSettingsId}
          className="min-w-400 margin-16 dashboard-react-card"
        >
          <WrappedComponent
            key={newWidgetSettings.widgetSettingsId}
            menuOptions={this.getOptions(newWidgetSettings)}
            savedWidgetSettingsId={newWidgetSettings.widgetSettingsId}
            savedWidgetSettings={newWidgetSettings.widgetSettings}
            setHasCleanedUp={() =>
              this.setState({ shouldCleanUpWidgetSettings: null })
            }
            shouldCleanUpWidgetSettings={shouldCleanUpWidgetSettings}
            updateSavedWidgetSettings={(updatedWidgetSettings) =>
              this.updateSavedWidgetSettings(
                newWidgetSettings.widgetSettingsId,
                updatedWidgetSettings,
              )
            }
            {...this.props}
          />
        </section>
      );
    };

    render() {
      const { shouldCleanUpWidgetSettings } = this.state;
      const { duplications, ...rest } = this.props;
      const duplicationKeys = Object.keys(duplications);
      return (
        <>
          {duplicationKeys.length > 0 &&
            duplicationKeys.map((key) => {
              const savedWidgetSettings = {
                widgetSettingsId: key,
                widgetSettings: duplications[key],
              };
              return (
                <section
                  key={savedWidgetSettings.widgetSettingsId}
                  className="min-w-400 margin-16 dashboard-react-card"
                >
                  <WrappedComponent
                    menuOptions={this.getOptions(savedWidgetSettings)}
                    savedWidgetSettingsId={savedWidgetSettings.widgetSettingsId}
                    savedWidgetSettings={savedWidgetSettings.widgetSettings}
                    setHasCleanedUp={() =>
                      this.setState({ shouldCleanUpWidgetSettings: null })
                    }
                    shouldCleanUpWidgetSettings={shouldCleanUpWidgetSettings}
                    updateSavedWidgetSettings={(updatedWidgetSettings) =>
                      this.updateSavedWidgetSettings(
                        savedWidgetSettings.widgetSettingsId,
                        updatedWidgetSettings,
                      )
                    }
                    {...rest}
                  />
                </section>
              );
            })}
          {!duplicationKeys.length && this.renderEmpty()}
        </>
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    const widgetSettingsSubpath = (
      widgetSettingsType || ownProps.widgetSettingsType
    ).toUpperCase();
    return {
      duplications: _get(
        currentUser.userSettings,
        `${APP.projectId}.${UserSettings.DUPLICATED_WIDGETS}.${widgetSettingsSubpath}`,
      ),
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchCurrentUserWidgetSetting: () =>
      dispatch(
        fetchCurrentUserWidgetSettingAction(
          APP.projectId,
          UserSettings.DUPLICATED_WIDGETS,
          (widgetSettingsType || ownProps.widgetSettingsType).toUpperCase(),
        ),
      ),
    setCurrentUserWidgetSetting: (widgetSettings) =>
      dispatch(
        setCurrentUserWidgetSettingAction(APP.projectId, widgetSettings),
      ),
    deleteCurrentUserWidgetSetting: (widgetSettingsId) =>
      dispatch(
        deleteCurrentUserWidgetSettingAction(
          APP.projectId,
          UserSettings.DUPLICATED_WIDGETS,
          (widgetSettingsType || ownProps.widgetSettingsType).toUpperCase(),
          widgetSettingsId,
        ),
      ),
  });

  DuplicableWidget.propTypes = {
    duplications: PropTypes.shape({}),
    fetchCurrentUserWidgetSetting: PropTypes.func.isRequired,
    setCurrentUserWidgetSetting: PropTypes.func.isRequired,
    deleteCurrentUserWidgetSetting: PropTypes.func.isRequired,
    widgetSettingsType: PropTypes.string,
  };

  DuplicableWidget.defaultProps = {
    duplications: {},
    widgetSettingsType: null,
  };

  return withProvider(
    connect(mapStateToProps, mapDispatchToProps)(DuplicableWidget),
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withDuplication;
