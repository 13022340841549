import styled from 'styled-components';

export const TabContainer = styled.div`
  display: block;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.gray666};
`;

export const TabLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 11px 0;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.gray666};

  & svg {
    width: 28px;
    height: 28px;
    fill: ${({ theme }) => theme.colors.white56};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white16};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.white16};

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 66px;
      content: '';
      background: ${({ theme }) => theme.colors.white};
    }

    & svg {
      fill: ${({ theme }) => theme.colors.white};
    }

    & div {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TabLabelText = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-shadow: ${({ theme }) => `${theme.colors.gray666} 0 0 1px`};
  opacity: 0.9;
`;
