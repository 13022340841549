import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateWorkflowCache,
  WorkflowCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type UseWorkflowStepDeleteParams = {
  config?: MutationConfig<void, Api.ErrorResponse>;
};
export const useWorkflowStepDelete = (params: UseWorkflowStepDeleteParams) =>
  useMutation(Api.deleteWorkflowStep, {
    ...params?.config,
    onSuccess: (data, mutationFuncParams) => {
      const { organizationId, workflowId } = mutationFuncParams;
      const keyParams = { organizationId, workflowId };

      invalidateWorkflowCache(WorkflowCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(undefined, mutationFuncParams);
    },
  });
