import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { bodyRegular } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';
import { focusOutline } from '../styles/focus';

const Label = styled(FormControlLabel)`
  & span.MuiTypography-body1 {
    ${bodyRegular}
  }

  & .MuiIconButton-root:hover {
    background-color: ${({ theme }) => theme.colors.blue04};
  }

  & .MuiIconButton-root.Mui-focusVisible {
    ${focusOutline({ $outlineOffset: '-2px' })}
  }

  & svg {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

export type CheckboxProps = React.ComponentProps<typeof MuiCheckbox> & {
  label: string | React.ReactNode;
  name: string;
};

export function Checkbox({ className, label, ...props }: CheckboxProps) {
  return (
    <FormControl className={className}>
      <Label control={<MuiCheckbox disableRipple {...props} />} label={label} />
    </FormControl>
  );
}
