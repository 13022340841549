import { SaveState } from '@monorepo/shared/types/SaveState';
import { GenericLogLoggedItemType, UnitString } from 'mapistry-shared';

export enum LogUploadFlatfileKeys {
  logDate = 'logDate',
  loggedItemId = 'loggedItemId',
  logTime = 'logTime',
  logValue = 'logValue',
  units = 'units',
  userId = 'userId',
}

export interface LoggedItemRecord {
  existingLogDatetime?: string;
  groupId: string | null;
  id: string | null; // null if log doesn't exist yet in db, string if it does
  itemType: GenericLogLoggedItemType;
  loggedItemId: string;
  name: string;
  selectTypeOptions?: SelectTypeOption[] | null;
  units: UnitString;
  value?: string | number;
}

export interface FormDraft {
  logDate: Date | string;
  logTime: Date | string;
  items: { [key: string]: LoggedItemRecord };
  userId: string;
}

export interface User {
  userId: string;
  userName: string;
}

export interface UserOption {
  label: string; // name
  value: string; // id
}

export interface FormError {
  displayable: string[];
  items: { [key: string]: { value: string } };
  logDate?: Date | string;
  logTime?: Date | string;
}

export interface LoggedItemGroups {
  [key: string]: { name: string; groupType: string; submitByApiOnly: boolean };
}

export interface EditLogModalProps {
  didWriteValues: boolean;
  formDraft: FormDraft;
  formErrors: FormError;
  formState: SaveState;
  isLoading?: boolean;
  loggedItemGroups: LoggedItemGroups;
  navigateToSettings: () => void;
  onClose: () => void;
  onDateChange: () => void;
  onEdit: (key: string, value: unknown, id?: string) => void;
  onOverwriteCancel: () => void;
  onOverwriteProceed: () => void;
  onSave: () => void;
  onTimeChange: () => void;
  originalReportingUserId: string;
  title?: string;
  willOverwriteValues: boolean;
}

export interface SelectTypeOption {
  label: string;
  value: string;
}

export interface LogBulkUpload {
  logValue: string | number;
  loggedItemId: string;
  units: UnitString;
  userId: string;
}

export interface LogBulkUploadFromFlatfile extends LogBulkUpload {
  logDate: string;
  logTime: string;
}

export interface LogBulkUploadForApi extends LogBulkUpload {
  logDatetime: string;
}
