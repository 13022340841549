import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useLastProject } from '@monorepo/shared/hooks/useLastProject';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom-v5-compat';

export function Project() {
  const { project, isLoading } = useLastProject();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      navigate(`${project?.id}`, { replace: true });
    }
  }, [isLoading, navigate, project?.id]);

  return isLoading ? <Loading /> : <Outlet />;
}
