import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { ResourceResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';

type KeyParams = Api.FetchResourceParams;

export const createKey = ({
  organizationId,
  resourceTypeId,
  resourceId,
}: KeyParams) =>
  ['org-resource', organizationId, resourceTypeId, resourceId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchResource>;

const fetcher: Fetcher = (_, organizationId, resourceTypeId, resourceId) =>
  Api.fetchResource({ organizationId, resourceTypeId, resourceId });

type UseResourceParams = Partial<Api.FetchResourceParams> & {
  config?: QueryConfig<ResourceResponse, Api.ErrorResponse>;
};

export function useResource({
  config: inputConfig,
  organizationId,
  resourceTypeId,
  resourceId,
}: UseResourceParams) {
  const isEnabled = !!organizationId && !!resourceTypeId && !!resourceId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createKey({ organizationId, resourceTypeId, resourceId })
    : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    resource: data,
  };
}

type QueryCache = ReturnType<typeof useResource>['resource'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

resourceCacheRegister([ResourceCacheEvents.DELETE], {
  hookName: 'useResource',
  callback: (keyParams: KeyParams) => invalidateCache(keyParams),
});
