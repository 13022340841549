import { Subnav } from '@monorepo/shared/componentsV2/Subnav';
import { SubnavHeaderWithProjectSelect } from '@monorepo/shared/componentsV2/Subnav/SubnavHeaderWithProjectSelect';
import React from 'react';

export function ProjectSubnav() {
  const options = [
    {
      href: `tasks`,
      label: 'Tasks',
      subtext: 'Compliance calendar, tasks and action items',
    },
    {
      href: `inspections`,
      label: 'Inspections',
      subtext: 'Inspections and forms',
    },
    {
      href: `logs`,
      label: 'Logs',
      subtext: 'Log and view data',
    },
    {
      href: `records`,
      label: 'Records',
      subtext: 'Water sampling and documents',
    },
    {
      href: `users`,
      label: 'Users',
      subtext: 'Manage site access',
    },
    {
      href: `site-details`,
      label: 'Site Details',
      subtext: 'Site tags, name, and address',
    },
  ];

  return (
    <Subnav
      header={<SubnavHeaderWithProjectSelect />}
      navAriaLabel="Sites"
      options={options}
    />
  );
}
