import { AggregationInterval, AggregationMethod } from 'mapistry-shared';

export const aggregationMethodLabels: { [key in AggregationMethod]: string } = {
  [AggregationMethod.AVERAGE]: 'Average',
  [AggregationMethod.COUNT]: 'Count',
  [AggregationMethod.COUNT_DISTINCT]: 'Unique values count',
  [AggregationMethod.GEOMETRIC_MEAN]: 'Geometric mean',
  [AggregationMethod.MAX]: 'Maximum',
  [AggregationMethod.MIN]: 'Minimum',
  [AggregationMethod.SUM]: 'Sum',
};

export const aggregationIntervalsInGrowingOrder = [
  AggregationInterval.DAY,
  AggregationInterval.WEEK,
  AggregationInterval.MONTH,
  AggregationInterval.QUARTER,
  AggregationInterval.YEAR,
];

export const aggregationIntervalLabels: {
  [key in AggregationInterval]: string;
} = {
  [AggregationInterval.DAY]: 'Days',
  [AggregationInterval.MONTH]: 'Months',
  [AggregationInterval.QUARTER]: 'Quarters',
  [AggregationInterval.SEMI_ANNUAL]: 'Semi-Annual',
  [AggregationInterval.WEEK]: 'Weeks',
  [AggregationInterval.YEAR]: 'Years',
};
