import styled from 'styled-components';
import { Main } from '../../Main';
import {
  contentPadding,
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '../../../styles/layout';
import { header4 } from '../../../styles/text';

export const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

export const Content = styled(Main)`
  ${fillHeightAndScrollable}
  ${contentPadding}
`;

export const Title = styled.h2`
  ${header4}
`;
