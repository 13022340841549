/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';

import {
  FETCH_FORM_SUBMISSIONS_BY_SLUG_REQUEST,
  FETCH_FORM_SUBMISSIONS_BY_SLUG_SUCCESS,
  FETCH_FORM_SUBMISSIONS_BY_SLUG_FAILURE,
} from '.';

const fetchFormSubmissionsBySlugRequest = createAction(
  FETCH_FORM_SUBMISSIONS_BY_SLUG_REQUEST,
);
const fetchFormSubmissionsBySlugSuccess = createAction(
  FETCH_FORM_SUBMISSIONS_BY_SLUG_SUCCESS,
);
const fetchFormSubmissionsBySlugFailure = createAction(
  FETCH_FORM_SUBMISSIONS_BY_SLUG_FAILURE,
);

export function fetchFormSubmissionsBySlugAction(
  projectId,
  submissionSlug,
  templateSlug,
) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/form-submissions/${submissionSlug}/${templateSlug}`,
      lifecycleActions: [
        fetchFormSubmissionsBySlugRequest,
        fetchFormSubmissionsBySlugSuccess,
        fetchFormSubmissionsBySlugFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}
