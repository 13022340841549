import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, usePaginatedQuery } from 'react-query';
import { OptionalKeys } from 'utility-types';
import { Api } from '../../apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '../../cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '../../cacheRegisters/data2/logFieldCacheRegister';

type KeyParams = Api.FetchLogEntriesParams;
type OptionalKeyParams = Pick<KeyParams, OptionalKeys<KeyParams>>;

function getCoreKeyPartsInOrder(
  keyParams: KeyParams,
): [string, string, string, string];
function getCoreKeyPartsInOrder(
  keyParams: Partial<KeyParams>,
): [string, string | undefined, string | undefined, string | undefined];
function getCoreKeyPartsInOrder(
  keyParams: KeyParams | Partial<KeyParams>,
):
  | [string, string, string, string]
  | [string, string | undefined, string | undefined, string | undefined] {
  const { organizationId, logId, projectId } = keyParams;
  return ['logEntries', organizationId, logId, projectId];
}

export const createQueryKey = ({
  after,
  before,
  perPage,
  requestedPage,
  sortDirection,
  userId,
  ...coreParams
}: KeyParams) =>
  [
    ...getCoreKeyPartsInOrder(coreParams),
    { after, before, perPage, requestedPage, sortDirection, userId },
  ] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogEntries
>;

type UseLogEntriesParams = Partial<Api.FetchLogEntriesParams> & {
  config?: QueryConfig<Api.FetchLogEntriesResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  logId: string,
  projectId: string,
  {
    after,
    before,
    perPage,
    requestedPage,
    sortDirection,
    userId,
  }: OptionalKeyParams,
) =>
  Api.fetchLogEntries({
    after,
    before,
    logId,
    organizationId,
    perPage,
    projectId,
    requestedPage,
    sortDirection,
    userId,
  });

export const useLogEntries = ({
  after,
  before,
  config: inputConfig,
  logId,
  organizationId,
  perPage,
  projectId,
  requestedPage,
  sortDirection,
  userId,
}: UseLogEntriesParams) => {
  const isEnabled = !!organizationId && !!logId && !!projectId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createQueryKey({
        after,
        before,
        logId,
        organizationId,
        perPage,
        projectId,
        requestedPage,
        sortDirection,
        userId,
      })
    : undefined;
  const {
    resolvedData: { data: logEntries = undefined, pagination = undefined } = {},
    ...queryInfo
  } = usePaginatedQuery(key, fetcher, config);
  return {
    logEntries,
    pagination,
    ...queryInfo,
  };
};

type QueryCache =
  | {
      data: ReturnType<typeof useLogEntries>['logEntries'];
      pagination: ReturnType<typeof useLogEntries>['pagination'];
    }
  | undefined;
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<
  KeyParams,
  keyof OptionalKeyParams | 'projectId' | 'logId'
> & {
  logId?: string;
  projectId?: string;
};

// allow invalidating:
//  - list of entries at a single project
//  - all entries lists across projects
//  - all entries lists in an org, across projects & logs (when a resource's values change)
export async function invalidateCache(
  invalidateCacheParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation(getCoreKeyPartsInOrder(invalidateCacheParams)),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useLogEntries',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

// Log entry completion status might have changed
logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

// Could affect the result of a formula field using a property value on that resource that has changed
resourceCacheRegister(
  [ResourceCacheEvents.UPDATE, ResourceCacheEvents.DELETE],
  cacheRegisterInvalidator,
);
