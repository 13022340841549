import { Button } from '@monorepo/shared/componentsV2/Button';
import { Tooltip } from '@monorepo/shared/componentsV2/Tooltip';
import { bodyMedium, bodyRegular } from '@monorepo/shared/styles/text';
import AddIcon from '@svg/add.svg';
import { FieldResponse } from 'mapistry-shared';
import React, { Fragment, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { FrequencyRequirementFilter } from './FrequencyRequirementFilter';
import { FormValues } from './types';

type FrequencyRequirementFiltersProps = {
  logFields: FieldResponse[];
  organizationId: string;
  projectId: string;
};

const WhereText = styled.div`
  ${bodyMedium};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.gray333};
`;

const AndText = styled.div`
  ${bodyRegular};
  margin: 0 0 1rem 1rem;
  color: ${({ theme }) => theme.colors.gray333};
`;

const AddConditionButton = styled(Button)`
  &.Mui-disabled {
    /* allow hovering over the conditions button when it's disabled so the tooltip can be accessed */
    pointer-events: auto;
  }
`;

export function FrequencyRequirementFilters({
  logFields,
  organizationId,
  projectId,
}: FrequencyRequirementFiltersProps) {
  const { values: currentFormValues } = useFormState<FormValues>();

  const addConditionButtonRef = useRef<HTMLButtonElement>(null);

  // the user can only add another condition when there are fewer conditions than the number of log fields available to
  //  filter on
  const shouldDisableAddConditionButton = (length?: number) =>
    (length || 0) >= logFields.length;

  return (
    <>
      {!!currentFormValues.filters?.length && <WhereText>Where</WhereText>}
      <FieldArray name="filters">
        {({ fields: formFields }) => (
          <>
            {formFields.map((formFieldName, formFieldIndex) => (
              <Fragment key={formFieldName}>
                {formFieldIndex !== 0 && <AndText>And</AndText>}
                <FrequencyRequirementFilter
                  formFieldIndex={formFieldIndex}
                  logFields={logFields}
                  formFieldName={formFieldName}
                  organizationId={organizationId}
                  onDelete={() => {
                    formFields.remove(formFieldIndex);
                    // focus the Add button so we don't lose the focus as this filter is removed
                    if (addConditionButtonRef?.current)
                      addConditionButtonRef.current.focus();
                  }}
                  projectId={projectId}
                />
              </Fragment>
            ))}
            <Tooltip
              title={
                shouldDisableAddConditionButton(formFields.length)
                  ? 'No more single select or Resource fields available to filter by.'
                  : 'Add a condition to set more granular frequency requirements based on specific Log field values.'
              }
            >
              <AddConditionButton
                ref={addConditionButtonRef}
                color="primary"
                disabled={shouldDisableAddConditionButton(formFields.length)}
                focusableWhenDisabled
                onClick={() => formFields.push(undefined)}
                variant="text"
              >
                <AddIcon />
                Add a condition
              </AddConditionButton>
            </Tooltip>
          </>
        )}
      </FieldArray>
    </>
  );
}
