import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '../../cacheRegisters/data2/workflowCacheRegister';

type KeyParams = Api.FetchWorkflowParams;

export const createKey = ({ organizationId, workflowId }: KeyParams) =>
  ['org-workflow', organizationId, workflowId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchWorkflow>;

const fetcher: Fetcher = (_, organizationId, workflowId) =>
  Api.fetchWorkflow({ organizationId, workflowId });

type UseWorkflowParams = Partial<Api.FetchWorkflowParams> & {
  config?: QueryConfig<Api.FetchWorkflowResponse, Api.ErrorResponse>;
};

export function useWorkflow({
  config: inputConfig,
  organizationId,
  workflowId,
}: UseWorkflowParams) {
  const isEnabled = !!organizationId && !!workflowId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId, workflowId }) : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    workflow: data,
  };
}

type QueryCache = ReturnType<typeof useWorkflow>['workflow'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

type InvalidateCacheParams = KeyParams;

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

const cacheRegisterInvalidator = {
  hookName: 'useWorkflow',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

workflowCacheRegister(
  [WorkflowCacheEvents.CREATE, WorkflowCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);
