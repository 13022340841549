import { Api } from '@monorepo/shared/apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { OptionalKeys } from 'utility-types';

type KeyParams = Api.CountLogEntriesParams;

type OptionalKeyParams = Pick<KeyParams, OptionalKeys<KeyParams>>;

export const createQueryKey = ({
  after,
  before,
  logId,
  organizationId,
  projectId,
}: KeyParams) =>
  [
    'countLogEntries',
    organizationId,
    logId,
    projectId,
    { after, before },
  ] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.countLogEntries
>;

type UseLogEntryCountParams = Partial<Api.CountLogEntriesParams> & {
  config?: QueryConfig<Api.CountLogEntriesResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  logId: string,
  projectId: string,
  { after, before }: OptionalKeyParams,
) => Api.countLogEntries({ after, before, logId, organizationId, projectId });

export const useLogEntryCount = ({
  after,
  before,
  config: inputConfig,
  logId,
  organizationId,
  projectId,
}: UseLogEntryCountParams) => {
  const isEnabled = !!organizationId && !!logId && !!projectId;
  const config = {
    ...inputConfig,
    enabled:
      inputConfig?.enabled != null
        ? inputConfig.enabled && isEnabled
        : isEnabled,
  };
  const key = isEnabled
    ? createQueryKey({ after, before, logId, organizationId, projectId })
    : undefined;
  const { data: { count = undefined } = {}, ...queryInfo } = useQuery(
    key,
    fetcher,
    config,
  );
  return {
    count,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useLogEntryCount>['count'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<KeyParams, keyof OptionalKeyParams>;
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(createQueryKey(keyParams));
}

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  {
    hookName: 'useLogEntryCount',
    callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
  },
);
