import { DocumentTitleTemplate } from '@monorepo/shared/componentsV2/DocumentTitle';
import { PageWithCollapsibleSubnav } from '@monorepo/shared/componentsV2/Subnav/PageWithCollapsibleSubnav';
import { useOrganization } from '@monorepo/shared/hooks/useOrganization';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom-v5-compat';
import { DataSetupSubnav } from './DataSetupSubnav';

export function DataSetup() {
  const { organizationId } = useParams();
  const { organization, isSuccess } = useOrganization({
    organizationId,
  });

  return (
    <PageWithCollapsibleSubnav subnavComponent={<DataSetupSubnav />}>
      {isSuccess && (
        <DocumentTitleTemplate titleTemplate={organization?.name} />
      )}
      <Outlet />
    </PageWithCollapsibleSubnav>
  );
}
