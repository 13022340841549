import React, { useCallback, useMemo } from 'react';
import {
  ResourceKeyWithParams,
  useMicroIntervalStatistics,
} from '../../../hooks/genericLogs/useMicroIntervalStatistics';
import useWidgetDetails from '../../../hooks/widgets/useWidgetDetails';
import {
  ChartableGenericLogsResource,
  Frequency,
  GenericLogsLimitItem,
} from '../../../types/ts-types';
import { Chart, Modal } from '../../elements';
import LoggedValuesOverTimeChart from '../../elements/LoggedValuesOverTimeChart';
import {
  ChartCard,
  ChartCardSplashOverlay,
} from '../../elements/cards/ChartCard';
import ChartSettingsForm from './ChartSettingsForm';

type LoggedValuesOverTimeChartProps = React.ComponentProps<
  typeof LoggedValuesOverTimeChart
>;

type ChartableResources = LoggedValuesOverTimeChartProps['resources'];

type GenericLogsChartCardWidgetSettings = {
  resources: ChartableResources;
  frequency: Frequency;
  start: string;
  end: string;
  widgetName: string;
};

type GenericLogsChartCardProps = {
  airEmissionResources?: ChartableGenericLogsResource[];
  airEmissionResourcesAreLoading: boolean;
  airEmissionLimitItems?: GenericLogsLimitItem[];
  airEmissionLimitItemsAreLoading: boolean;
  logProjectId: string;
  projectId: string;
  widgetDefaultName: string;
};

function GenericLogsChartCard(props: GenericLogsChartCardProps) {
  const {
    airEmissionResources,
    airEmissionResourcesAreLoading,
    airEmissionLimitItems,
    airEmissionLimitItemsAreLoading,
    logProjectId,
    projectId,
    widgetDefaultName,
  } = props;
  const {
    widgetId,
    widgetSettings,
    updateWidget,
    toggleSettingsModalVisibility,
    widgetSettingsModalIsVisible,
    menuOptions,
  } = useWidgetDetails<GenericLogsChartCardWidgetSettings>();

  // Filter out filter resources that are not in current available emission resources,
  // This might happen if a resource was deleted but filters haven't been updated yet
  const widgetSettingsWithoutDeletedResources = useMemo(() => {
    if (!widgetSettings || !airEmissionResources) return undefined;

    const filteredResources = widgetSettings.resources.filter((resourceKey) => {
      const resource = airEmissionResources.find(
        (r) => r.resourceId === resourceKey.resourceId,
      );
      return !!resource;
    });

    return {
      ...widgetSettings,
      resources: filteredResources,
    };
  }, [airEmissionResources, widgetSettings]);

  const resourceKeysWithParams = useMemo(() => {
    if (
      !widgetSettingsWithoutDeletedResources ||
      !widgetSettingsWithoutDeletedResources.resources.length ||
      !airEmissionLimitItems ||
      !airEmissionResources
    ) {
      return undefined;
    }

    const { frequency, start, end } = widgetSettingsWithoutDeletedResources;
    return widgetSettingsWithoutDeletedResources.resources.map(
      (resourceKey, index): ResourceKeyWithParams => {
        const { resourceType, resourceId, unit } = resourceKey;
        const isPrimaryResource = index === 0;
        const associatedLimit = isPrimaryResource
          ? airEmissionLimitItems.find((li) => li.resourceId === resourceId)
          : undefined;
        const limits =
          isPrimaryResource && associatedLimit ? [associatedLimit.id] : [];
        return {
          resourceType,
          resourceId,
          frequency,
          start,
          end,
          unit,
          limits,
        };
      },
    );
  }, [
    airEmissionLimitItems,
    airEmissionResources,
    widgetSettingsWithoutDeletedResources,
  ]);

  const {
    airEmissionMicroIntervalStatistics,
    isLoading: airEmissionMicroIntervalStatisticsAreLoading,
  } = useMicroIntervalStatistics(resourceKeysWithParams, logProjectId);

  const isLoading =
    airEmissionResourcesAreLoading ||
    airEmissionLimitItemsAreLoading ||
    airEmissionMicroIntervalStatisticsAreLoading;
  const hasChartData = Boolean(airEmissionMicroIntervalStatistics);

  const navigateToSettings = useCallback(() => {
    const settingsUrl = `/projects/${projectId}/custom-logs/${logProjectId}/settings`;
    window.location.href = settingsUrl;
  }, [logProjectId, projectId]);

  const widgetTitle = widgetSettings?.widgetName || widgetDefaultName;

  return (
    <>
      {airEmissionResources && (
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        <Modal
          fullWidth={false}
          header={<h4 className="modal-title">{widgetTitle}</h4>}
          maxWidth="sm"
          onClose={toggleSettingsModalVisibility}
          open={widgetSettingsModalIsVisible}
        >
          <ChartSettingsForm
            initialValues={widgetSettingsWithoutDeletedResources}
            onCancel={toggleSettingsModalVisibility}
            onSubmit={(values) => {
              updateWidget(widgetId, values);
              toggleSettingsModalVisibility();
            }}
            airEmissionResources={airEmissionResources}
          />
        </Modal>
      )}
      <ChartCard
        title={widgetTitle}
        menuOptions={menuOptions}
        hasChartData={hasChartData}
        isLoading={isLoading}
        renderSplashCard={() => {
          if (!airEmissionResources?.length) {
            return (
              <ChartCardSplashOverlay
                ctaText="Add logged items"
                onEngage={navigateToSettings}
              >
                You currently have no logged items
              </ChartCardSplashOverlay>
            );
          }
          return (
            <ChartCardSplashOverlay
              ctaText="Filter settings"
              onEngage={toggleSettingsModalVisibility}
            >
              To view emissions produced over time, configure settings by
              clicking the button below.
            </ChartCardSplashOverlay>
          );
        }}
        renderLoadingCard={() => <Chart isLoading={isLoading} />}
        renderGraphCard={() => (
          <LoggedValuesOverTimeChart
            // renderGraphCard will be called only if isLoading is false
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            resources={widgetSettingsWithoutDeletedResources!.resources}
            // renderGraphCard will be called only if isLoading is false
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            microIntervalStatistics={airEmissionMicroIntervalStatistics!}
          />
        )}
      />
    </>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsChartCard;
