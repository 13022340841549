import { createContext } from 'react';

type MenuOption = {
  label: string;
  icon: JSX.Element;
  danger?: boolean;
  onClick: () => void;
};

export type WidgetDetailsContextValue<S> = {
  widgetId: string;
  widgetSettings: S | undefined;
  updateWidget: (widgetId: string, widgetSettings: S | undefined) => void;
  deleteWidget: (widgetId: string) => void;
  duplicateWidget: (widgetId: string) => void;
  menuOptions: MenuOption[];
  toggleSettingsModalVisibility: () => void;
  widgetSettingsModalIsVisible: boolean;
};

const defaultValue = {
  widgetId: '',
  widgetSettings: null,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateWidget: () => {},
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteWidget: () => {},
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  duplicateWidget: () => {},
  menuOptions: [],
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSettingsModalVisibility: () => {},
  widgetSettingsModalIsVisible: false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as WidgetDetailsContextValue<any>;

const WidgetDetailsContext = createContext(defaultValue);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default WidgetDetailsContext;
