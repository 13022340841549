import { FormTemplateFieldTypes } from 'mapistry-shared';
import React from 'react';
import { EditBooleanFieldForm } from './EditBooleanFieldForm';
import { EditExplanationAreaFieldForm } from './EditExplanationAreaFieldForm';
import { EditSelectFieldForm } from './EditSelectFieldForm';
import { EditGenericFieldForm } from './GenericFieldForm/EditGenericFieldForm';

type EditFieldFormProps = {
  fieldType: FormTemplateFieldTypes;
  hasFieldsDependedOnByConditional: boolean;
};

export const EditFieldForm = (props: EditFieldFormProps) => {
  const { fieldType, hasFieldsDependedOnByConditional } = props;
  switch (fieldType) {
    case FormTemplateFieldTypes.BOOLEAN:
      return <EditBooleanFieldForm />;
    case FormTemplateFieldTypes.EXPLANATION_AREA:
      return <EditExplanationAreaFieldForm />;
    case FormTemplateFieldTypes.SINGLE_SELECT:
    case FormTemplateFieldTypes.SINGLE_SELECT_WITH_FREE_FORM:
    case FormTemplateFieldTypes.MULTI_SELECT:
    case FormTemplateFieldTypes.MULTI_SELECT_WITH_FREE_FORM:
      return (
        <EditSelectFieldForm
          hasFieldsDependedOnByConditional={hasFieldsDependedOnByConditional}
        />
      );
    default:
      return <EditGenericFieldForm />;
  }
};
