import { Api } from '@monorepo/shared/apiClient';
import { CalculatedValue } from 'mapistry-shared';
import { useQuery } from 'react-query';
import { UseGenericLogDataInputProps } from './types';

export const createQueryKey = ({
  logProjectId,
  projectId,
}: Api.FetchCalculatedValuesProps) =>
  ['CALCULATED_VALUES', projectId, logProjectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchCalculatedValues
>;

const fetcher: Fetcher = (
  _: string,
  projectId?: string,
  logProjectId?: string,
) => Api.fetchCalculatedValues({ logProjectId, projectId });

export const useCalculatedValues = ({
  config: inputConfig,
  logProjectId,
  projectId,
}: UseGenericLogDataInputProps<
  Api.FetchCalculatedValuesProps,
  CalculatedValue[]
>) => {
  const key = createQueryKey({ logProjectId, projectId });
  const config = {
    ...inputConfig,
    enabled: logProjectId && projectId,
  };
  const { data: calculatedValues, ...queryInfo } = useQuery(
    key,
    fetcher,
    config,
  );
  return {
    calculatedValues,
    ...queryInfo,
  };
};
