import {
  PushMessageTopic as PMT,
  ServerToClientMessages,
} from 'mapistry-shared';
import { useThrottledOnArgsMessageHandler } from '../webSocket';

// this is just for test messages in quick development - if it appears no longer useful, feel free to delete
const onTestMessage: ServerToClientMessages[PMT.Test] = (
  message: string,
  detail: string,
) => {
  console.log(`Test message: ${message} ${detail}`);
};

export function useTestMessageHandlers() {
  useThrottledOnArgsMessageHandler(PMT.Test, onTestMessage);
}
