import React, { Dispatch } from 'react';
import ReactQuill from 'react-quill-new';
import styled from 'styled-components';
import { Tooltip } from './Tooltip';

// handle some odd ReactQuill behavior
const StyledQuillWrapper = styled.div`
  .ql-editor ol li::before {
    content: unset;
  }

  .ql-editor ol li:not(.ql-direction-rtl) {
    padding-left: 0;
    list-style-type: disc;
  }
`;

const CustomLabel = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  color: #333;
`;

type WysiwygEditorProps = {
  value: string;
  setValue: Dispatch<string>;
  modules?: Record<string, unknown>;
  label: string;
  toolTipText?: string;
  isRequired?: boolean;
};

// defaults are what the mobile app is able to display
const defaultModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold'],
    [{ list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
  formats: ['header', 'bold', 'list', 'link', 'image'],
};

/*

author: @pvonpetrin
date: 10/24/24

Our current WYSIWYG editor is Quill via react-quill-new
https://quilljs.com/docs/api/
https://github.com/VaguelySerious/react-quill

react-quill-new does not use a form field, so requires special handling to 
work with react-final-form. 

For an implementaion example with react-final-form, see: 
packages/formTemplates/src/components/EditFormTemplatePage/EditFieldModal/EditExplanationAreaFieldForm.tsx

Also due to the lack of a form field, validation is easier to handle server-side.

*/

export function WysiwygEditor({
  value,
  setValue,
  modules = defaultModules,
  label,
  toolTipText,
  isRequired = false,
}: WysiwygEditorProps) {
  const required = isRequired ? '*' : '';

  const toolTip = toolTipText ? <Tooltip title={toolTipText} /> : '';

  return (
    <StyledQuillWrapper>
      <CustomLabel>
        {label} {required} {toolTip}
      </CustomLabel>

      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
      />
    </StyledQuillWrapper>
  );
}
