import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {
  Button,
  FieldWrapper,
  FormErrors,
  Modal,
  SaveButton,
} from '../../elements/index';
import AddAssignee from '../../elements/user/AddAssignee';
import { FormSaveStateType, ProjectTaskType, UserType } from '../../propTypes';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function InspectionTaskEditModal(props) {
  const {
    assignees,
    errors,
    isOpen,
    onChange,
    onClose,
    onSave,
    saveState,
    task,
  } = props;

  const footer = (
    <>
      <Button color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <SaveButton label="Update Task" onSave={onSave} saveState={saveState} />
    </>
  );

  return (
    <Modal
      header={<h4>{`Editing: ${task.title}`}</h4>}
      footer={footer}
      fullWidth={false}
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
    >
      <div className="inspection-task-edit-modal__content">
        {errors && (
          <div className="inspection-task-edit-modal__errors">
            <FormErrors errors={errors} />
          </div>
        )}
        <FieldWrapper label="Assignee">
          <AddAssignee assignees={assignees} multi onChange={onChange} />
        </FieldWrapper>
      </div>
    </Modal>
  );
}

InspectionTaskEditModal.propTypes = {
  assignees: PropTypes.arrayOf(UserType).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saveState: FormSaveStateType.isRequired,
  task: ProjectTaskType.isRequired,
};

InspectionTaskEditModal.defaultProps = {
  errors: null,
};
