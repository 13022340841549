import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  deleteCurrentUserWidgetSettingAction,
  setCurrentUserSpecificWidgetSettingAction,
} from '../../actions/currentUser';
import APP from '../../config';
import useWidgetDuplicates from '../../hooks/widgets/useWidgetDuplicates';
import UserSettings from '../../types/UserSettings';
import WidgetDetailsProvider from './WidgetDetailsProvider';

type DuplicableWidgetProps = {
  children: NonNullable<React.ReactNode>;
  filterQuery: string;
  widgetDefaultName: string;
  widgetType: string;
  widgetSettingsVersion: string;
};

function DuplicableWidget(props: DuplicableWidgetProps) {
  const {
    children,
    filterQuery,
    widgetDefaultName,
    widgetType,
    widgetSettingsVersion,
  } = props;
  const uppercaseWidgetType = widgetType.toUpperCase();
  const dispatch = useDispatch();
  const [initialWidgetId] = useState(() => uuidv4().toUpperCase());
  const duplicates = useWidgetDuplicates(
    uppercaseWidgetType,
    widgetSettingsVersion,
  );

  const updateWidget = useCallback(
    (widgetId, widgetSettings) => {
      dispatch(
        setCurrentUserSpecificWidgetSettingAction(
          APP.projectId,
          UserSettings.DUPLICATED_WIDGETS,
          uppercaseWidgetType,
          widgetId,
          widgetSettingsVersion,
          widgetSettings,
        ),
      );
    },
    [uppercaseWidgetType, widgetSettingsVersion, dispatch],
  );

  const deleteWidget = useCallback(
    (widgetId) => {
      dispatch(
        deleteCurrentUserWidgetSettingAction(
          APP.projectId,
          UserSettings.DUPLICATED_WIDGETS,
          uppercaseWidgetType,
          widgetId,
        ),
      );
    },
    [uppercaseWidgetType, dispatch],
  );

  const duplicateWidget = useCallback(
    (widgetId) => {
      const newWidgetId = uuidv4().toUpperCase();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const widgetSettings = duplicates![widgetId];
      updateWidget(newWidgetId, widgetSettings);
    },
    [duplicates, updateWidget],
  );

  const shouldDisplay = useCallback(
    (widgetName: string) => {
      if (!filterQuery || !widgetName) return true;
      return widgetName.toLowerCase().includes(filterQuery.toLowerCase());
    },
    [filterQuery],
  );

  // sort alphabetically and filter by filterQuery within the group
  const filteredAndSortedWidgets = useMemo(() => {
    if (!duplicates) return [];
    const filteredWidgets = Object.entries(duplicates).filter(
      ([, widgetSettings]) => {
        const widgetName = widgetSettings.widgetName || widgetDefaultName;
        return shouldDisplay(widgetName);
      },
    );
    const sortedWidgets = filteredWidgets.sort((a, b) => {
      const nameA = a[1].widgetName || widgetDefaultName;
      const nameB = b[1].widgetName || widgetDefaultName;
      if (nameA === nameB) return 0;
      return nameA > nameB ? 1 : -1;
    });
    return sortedWidgets;
  }, [duplicates, widgetDefaultName, shouldDisplay]);

  if (!duplicates) {
    if (shouldDisplay(widgetDefaultName)) {
      return (
        <WidgetDetailsProvider
          widgetId={initialWidgetId}
          updateWidget={updateWidget}
          deleteWidget={deleteWidget}
          duplicateWidget={duplicateWidget}
          disableDuplicateOption
          showRemoveOption={false}
        >
          {children}
        </WidgetDetailsProvider>
      );
    }
    return null;
  }

  return (
    <>
      {filteredAndSortedWidgets.map(([widgetId, widgetSettings]) => (
        <WidgetDetailsProvider
          key={widgetId}
          widgetId={widgetId}
          widgetSettings={widgetSettings}
          updateWidget={updateWidget}
          deleteWidget={deleteWidget}
          duplicateWidget={duplicateWidget}
          showRemoveOption={Object.keys(duplicates).length > 1}
        >
          {children}
        </WidgetDetailsProvider>
      ))}
    </>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DuplicableWidget;
