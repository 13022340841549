import MuiTab from '@material-ui/core/Tab';
import { A, Link } from '@monorepo/shared/componentsV2/Link';
import { focusOutline } from '@monorepo/shared/styles/focus';
import { header4 } from '@monorepo/shared/styles/text';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

const Tab = styled(MuiTab)`
  ${header4}
  min-width: ${({ theme }) => theme.sizes.navbarWidth};
  padding: 0;
  font-weight: inherit;
  text-transform: none;
  opacity: 1;

  & .MuiTab-wrapper {
    display: block;
  }

  /* Ideally would pass this in the Tab 'component' prop elements, but
   that is tough to make work with forwarding refs */
  &:focus-visible {
    ${focusOutline({ $outlineOffset: '-2px' })}
  }
` as typeof MuiTab;

interface NavigationTabProps {
  declareIsActive: (arg0: boolean) => void;
  isLegacy?: boolean;
  label: React.ReactNode | ((props: { isActive: boolean }) => React.ReactNode);
  to: string;
}

export function NavigationTab({
  declareIsActive,
  isLegacy,
  label,
  to,
}: NavigationTabProps) {
  const { pathname: currentLocationPathname } = useLocation();
  const { pathname: toPathname } = useResolvedPath(to);
  const isActive = useMemo(
    () =>
      currentLocationPathname === toPathname ||
      (currentLocationPathname.startsWith(toPathname) &&
        currentLocationPathname.charAt(toPathname.length) === '/'),
    [currentLocationPathname, toPathname],
  );
  declareIsActive(isActive);

  return (
    <Tab
      className={classnames({ selected: isActive })}
      component={isLegacy ? A : Link}
      disableRipple
      href={to}
      label={typeof label === 'function' ? label({ isActive }) : label}
      selected={isActive}
      tabIndex={isActive ? 0 : -1}
      to={to}
    />
  );
}
