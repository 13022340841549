import { useLoggedItems } from '@monorepo/genericLogs/src/hooks/useLoggedItems';
import { GenericLogType } from 'mapistry-shared';
import { useMemo } from 'react';
import {
  ChartableGenericLogsResource,
  GenericLogsCalculatedValueResource,
  GenericLogsLoggedItemResource,
  GenericLogsRollingCalculationResource,
} from '../../types/ts-types';
import { useCalculatedValues } from './useCalculatedValues';
import { useRollingCalculations } from './useRollingCalculations';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useChartableResources(projectId: string, logProjectId: string) {
  const { loggedItems, isLoading: loggedItemsAreLoading } = useLoggedItems({
    projectId,
    logProjectId,
  });
  const loggedItemResources = useMemo<
    GenericLogsLoggedItemResource[] | undefined
  >(
    () =>
      loggedItems?.map((item) => ({
        resourceType: GenericLogType.LOGGED_ITEM,
        resourceId: item.id,
        entity: item,
      })),
    [loggedItems],
  );
  const {
    airEmissionCalculatedValues,
    isLoading: airEmissionCalculatedValuesAreLoading,
  } = useCalculatedValues(logProjectId);
  const calculatedValueResources = useMemo<
    GenericLogsCalculatedValueResource[] | undefined
  >(
    () =>
      airEmissionCalculatedValues?.map((item) => ({
        resourceType: GenericLogType.CALCULATED_VALUE,
        resourceId: item.id,
        entity: item,
      })),
    [airEmissionCalculatedValues],
  );
  const {
    airEmissionRollingCalculations,
    isLoading: airEmissionRollingCalculationsAreLoading,
  } = useRollingCalculations(logProjectId);
  const rollingCalculationResources = useMemo<
    GenericLogsRollingCalculationResource[] | undefined
  >(
    () =>
      airEmissionRollingCalculations?.map((item) => ({
        resourceType: GenericLogType.ROLLING_CALCULATION,
        resourceId: item.id,
        entity: item,
      })),
    [airEmissionRollingCalculations],
  );
  const allDataLoaded =
    loggedItems &&
    airEmissionCalculatedValues &&
    airEmissionRollingCalculations;
  const airEmissionResources = useMemo<
    ChartableGenericLogsResource[] | undefined
  >(
    () =>
      allDataLoaded
        ? [
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...loggedItemResources!,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...calculatedValueResources!,
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...rollingCalculationResources!,
          ]
        : undefined,
    [
      allDataLoaded,
      loggedItemResources,
      calculatedValueResources,
      rollingCalculationResources,
    ],
  );
  const isLoading =
    loggedItemsAreLoading ||
    airEmissionCalculatedValuesAreLoading ||
    airEmissionRollingCalculationsAreLoading;
  return { airEmissionResources, isLoading };
}

export { useChartableResources };
