import { useEffect } from 'react';
import { queryCache, QueryConfig, useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom-v5-compat';
import { Api } from '../apiClient';

export const createLastProjectKey = () => ['lastProject'] as const;

type LastProjectFetcher = Api.DataHookQueryFn<
  typeof createLastProjectKey,
  typeof Api.fetchLastProject
>;

const fetcher: LastProjectFetcher = () => Api.fetchLastProject();

type UseLastProjectProps = {
  config?: QueryConfig<Api.FetchLastProjectResponse, Api.ErrorResponse>;
};

type QueryCache = ReturnType<typeof useLastProject>['project'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(): QueryCache {
  return queryCache.getQueryData<QueryCache>(createLastProjectKey());
}

export function setCache(project: NonEmptyQueryCache): void {
  queryCache.setQueryData(createLastProjectKey(), project);
}

export function useSetLastProject() {
  return useMutation(Api.setLastProject, {
    onMutate: () => {
      const oldLastProject = getCache();
      return oldLastProject;
    },
    onSuccess: (project) => {
      setCache(project);
    },
    onError: (error, projectId, oldLastProject) => {
      if (oldLastProject) {
        setCache(oldLastProject);
      }
    },
  });
}

export function useKeepLastProjectUpdated() {
  const { projectId } = useParams();
  const [setLastProject] = useSetLastProject();

  useEffect(() => {
    void setLastProject(projectId);
  }, [projectId, setLastProject]);
}

/**
 * useLastProject will always return the last valid project the user visited
 * (even if they saw error pages in the meantime)
 */
export function useLastProject(props?: UseLastProjectProps) {
  const key = Api.isDisabledDataHookInput(props)
    ? undefined
    : createLastProjectKey();
  const config = props?.config;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    project: data,
  };
}
