import GrabIcon from '@svg/grab.svg';
import React, { useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableStateSnapshot,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import {
  DragHandle,
  DraggableElement,
  Element,
  List,
  ListElement,
} from './styled';

export type OnOrderChange = (ids: string[]) => void;
export type ReorderableElement = {
  id: string;
  component: React.ReactChild;
};

function moveItemInArray<T>(
  array: T[],
  oldIndex: number,
  newIndex: number,
): T[] {
  const item = array[oldIndex] as T;
  const rearrangedArray = Array.from(array);
  rearrangedArray.splice(oldIndex, 1);
  rearrangedArray.splice(newIndex, 0, item);

  return rearrangedArray;
}

function DraggableItem({
  dragHandleProps,
  isDraggingThisItem,
  isDraggingOverThisList,
  children,
}: {
  isDraggingThisItem: DraggableStateSnapshot['isDragging'];
  isDraggingOverThisList: DroppableStateSnapshot['isDraggingOver'];
  children: React.ReactChild;
  dragHandleProps: DraggableProvided['dragHandleProps'];
}) {
  return (
    <DraggableElement
      $isDraggingThisItem={isDraggingThisItem}
      $isDraggingOverThisList={isDraggingOverThisList}
    >
      <DragHandle aria-label="Drag handle" {...dragHandleProps}>
        <GrabIcon />
      </DragHandle>
      <Element>{children}</Element>
    </DraggableElement>
  );
}

interface ReorderableListProps {
  orderedElements: ReorderableElement[];
  onOrderChange: OnOrderChange;
  /** when there are multiple reorderable lists on the page, each needs a unique id */
  uniqueDragListId?: string;
  listAriaLabel: string;
}

export function ReorderableList({
  orderedElements,
  onOrderChange,
  uniqueDragListId = 'reorderable-fields',
  listAriaLabel,
}: ReorderableListProps) {
  const handleDragEnd: OnDragEndResponder = useCallback(
    (result) => {
      const { destination, source } = result;
      if (!destination) {
        return; // drop got cancelled or dropped outside of drop-area
      }

      const [oldIndex, newIndex] = [source.index, destination.index];

      if (oldIndex === newIndex) {
        return; // got dropped in same place again
      }

      const oldOrder = orderedElements.map((element) => element.id);
      const newOrder = moveItemInArray(oldOrder, oldIndex, newIndex);

      onOrderChange(newOrder);
    },
    [orderedElements, onOrderChange],
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={uniqueDragListId}>
        {(dropProvided, dropSnapshot) => (
          <List
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            aria-label={listAriaLabel}
          >
            {orderedElements.map((element, index) => (
              <ListElement key={element.id}>
                <Draggable draggableId={element.id} index={index}>
                  {(dragProvided, dragSnapshot) => (
                    <div
                      {...dragProvided.draggableProps}
                      ref={dragProvided.innerRef}
                    >
                      <DraggableItem
                        isDraggingThisItem={dragSnapshot.isDragging}
                        isDraggingOverThisList={dropSnapshot.isDraggingOver}
                        dragHandleProps={dragProvided.dragHandleProps}
                      >
                        {element.component}
                      </DraggableItem>
                    </div>
                  )}
                </Draggable>
              </ListElement>
            ))}
            {dropProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
