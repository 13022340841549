import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { Api } from '@monorepo/shared/apiClient';
import { LoggedItem } from '@monorepo/shared/apiClient/types';
import { setWithMessage } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileRecordHelpers';
import {
  didValidationSucceed,
  validateDayField,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  ValidDateString,
  isValidDate,
  isValidNumber,
  isValidTime,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { format } from 'date-fns';
import {
  GenericLogLoggedItemType,
  SimpleUnits,
  isUnitString,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import Units from '../../../utils/units';
import { SelectTypeOption, UserOption } from './EditLogModalTypes';
import { dateAndTimeStringsToDatetime } from './logUploadHelpers';

// For each item getting logged, check whether the input is compatible with the
// type of item this logged value is supposed to be (e.g. number, text, boolean, user)
export function scanLoggedValueForErrors(
  loggedValueFieldId: string,
  record: FlatfileRecord,
  userOptions: UserOption[],
  loggedItem: LoggedItem,
): void {
  if (!loggedItem) {
    return;
  }

  const value = record.get(loggedValueFieldId);

  switch (loggedItem.itemType) {
    // ---- TYPE BOOLEAN
    case GenericLogLoggedItemType.BOOLEAN: {
      if (value !== 'Yes' && value !== 'No') {
        record.addError(
          loggedValueFieldId,
          'The value needs to be "Yes" or "No" for this item',
        );
      }
      return;
    }
    // ---- TYPE DATE
    case GenericLogLoggedItemType.DATE: {
      if (!isValidDate(value)) {
        record.addError(
          loggedValueFieldId,
          'The value needs to be a valid date, e.g. one of the following formats: "MM/DD/YYYY", "M/D/YY", "YYYY-MM-DD".',
        );
        return;
      }
      record.set(
        loggedValueFieldId,
        format(localEquivalentOfUTC(value), 'yyyy-MM-dd'),
      );
      return;
    }
    // ---- TYPE NUMBER
    case GenericLogLoggedItemType.NUMBER: {
      if (!isValidNumber(value)) {
        record.addError(
          loggedValueFieldId,
          'The value needs to be a valid number for this item',
        );
      }
      return;
    }
    // ---- TYPE SIGNATURE
    case GenericLogLoggedItemType.SIGNATURE: {
      record.addError(
        loggedValueFieldId,
        "We currently don't support uploading data of type 'Signature'",
      );
      return;
    }
    // ---- TYPE SINGLE SELECT
    case GenericLogLoggedItemType.SINGLE_SELECT: {
      // we know these options are defined for single select items
      const validOptions = loggedItem.selectTypeOptions as SelectTypeOption[];

      // for these single select options, label and value are always equal
      const chosenOption = validOptions.find((o) => o.label === value);
      if (!chosenOption) {
        const validOptionLabels = validOptions.map((o) => o.label);
        record.addError(
          loggedValueFieldId,
          `The value needs to be ${validOptionLabels.join(', ')}`,
        );
        return;
      }
      record.set(loggedValueFieldId, chosenOption.value);
      return;
    }
    // ---- TYPE TEXT
    case GenericLogLoggedItemType.TEXT: {
      if (typeof value !== 'string')
        record.addError(
          loggedValueFieldId,
          'The value needs to be a string for this item',
        );
      return;
    }
    // ---- TYPE TIME
    case GenericLogLoggedItemType.TIME: {
      if (!isValidTime(value)) {
        record.addError(
          loggedValueFieldId,
          'The value needs to be a valid time, e.g. in format "HH:MM PM"',
        );
        return;
      }
      if (value) {
        const todayDate = format(new Date(), 'yyyy-MM-dd');
        const timeDate = new Date(`${todayDate} ${value}`);
        record.set(loggedValueFieldId, format(new Date(timeDate), 'HHmm')); // API expects military time formatting)
      }
      return;
    }
    // ---- TYPE USER
    case GenericLogLoggedItemType.USER: {
      const selectedUser = userOptions.find((u) => u.label === value);
      if (!selectedUser) {
        const validUserNames = userOptions.map((u) => u.label);
        record.addError(
          loggedValueFieldId,
          `Needs to be one of these users: ${validUserNames.join(', ')}`,
        );
      }
    }
    // no default
  }
}

export function setDefaultUser(
  userFieldId: string,
  record: FlatfileRecord,
  defaultUserId?: string,
): void {
  const userValue = record.get(userFieldId);

  if (!userValue && defaultUserId) {
    setWithMessage(
      userFieldId,
      record,
      defaultUserId,
      'defaulted to current user',
    );
  }
}

// for each record to make, make sure that the unit is compatible with the unit of the log
export function scanLogUnitForErrors(
  unitFieldId: string,
  record: FlatfileRecord,
  loggedItem?: LoggedItem,
): void {
  // logged item is needed to decide whether the unit is valid or not
  if (!loggedItem) return;

  const unitFromSpreadsheet = record.get(unitFieldId);
  const loggedItemBaseUnit = loggedItem.units;
  if (!loggedItemBaseUnit || loggedItemBaseUnit === SimpleUnits.UNITLESS) {
    if (!unitFromSpreadsheet || unitFromSpreadsheet === SimpleUnits.UNITLESS) {
      return;
    }
    record.addError(unitFieldId, 'This item is expected to be unitless');
    return;
  }

  if (!unitFromSpreadsheet) {
    record.addError(unitFieldId, 'This item requires units');
    return;
  }

  const relatedUnits = Units.getRelatedUnits(loggedItemBaseUnit);
  const relatedUnitLabels = relatedUnits.map((u) => u.label);
  const relatedUnitsErrorText = `This item requires one of these units: ${relatedUnitLabels.join(
    ', ',
  )}`;

  if (!isUnitString(unitFromSpreadsheet)) {
    record.addError(unitFieldId, relatedUnitsErrorText);
    return;
  }

  const areRelated = Units.areRelated(loggedItemBaseUnit, unitFromSpreadsheet);
  if (areRelated) {
    return;
  }

  record.addError(unitFieldId, relatedUnitsErrorText);
}

// check whether the date is a valid date, and whether it's after the start date of the log
export function scanLogDateForErrorsAndParseDate(
  dateFieldId: string,
  record: FlatfileRecord,
  logProjectStartDate?: string | Date,
): void {
  validateDayField(dateFieldId, record);

  if (!record.get(dateFieldId) || !didValidationSucceed(dateFieldId, record)) {
    return;
  }

  if (logProjectStartDate) {
    // We know that dateFromSpreadsheet is a valid date string from "validateDayField" validation
    const logDate = new Date(record.get(dateFieldId) as ValidDateString);

    const localLogStartDate = localEquivalentOfUTC(logProjectStartDate);
    if (localLogStartDate > logDate) {
      const formattedLogStartDate = format(localLogStartDate, 'MM/dd/yyyy');
      record.addError(
        dateFieldId,
        `You are trying to log a value that falls before the start of this log on ${formattedLogStartDate}.
        Contact customer service to start your log earlier.`,
      );
    }
  }
}

// check whether this record already has a submission on that date
export async function scanForAlreadyExistingLogs(
  fieldToShowErrorOnKey: string,
  record: FlatfileRecord,
  projectId: string,
  logProjectId: string,
  dateFromSpreadsheet: string,
  timeFromSpreadsheet: string,
  loggedItemId: string,
): Promise<void> {
  const dateTime = dateAndTimeStringsToDatetime(
    dateFromSpreadsheet,
    timeFromSpreadsheet,
  );

  const recordAtThatTime = await Api.fetchExistingLog(
    projectId,
    logProjectId,
    loggedItemId,
    dateTime,
  );

  if (recordAtThatTime) {
    const relevantRecord = recordAtThatTime.record.loggedItems.find(
      (r) => r.loggedItemId === loggedItemId,
    );
    if (!relevantRecord) return;

    const relevantRecordExistsAtExactTime = !!relevantRecord.id;
    if (relevantRecordExistsAtExactTime) {
      record.addError(
        fieldToShowErrorOnKey,
        'There is already a log at this exact time.',
      );
      return;
    }
    const relevantRecordHasLogInTimeBucket =
      !!relevantRecord.existingLogDatetime;
    if (relevantRecordHasLogInTimeBucket) {
      const localDatetime = localEquivalentOfUTC(
        relevantRecord.existingLogDatetime,
      );
      if (localDatetime) {
        record.addError(
          fieldToShowErrorOnKey,
          `There is already a log for this time period on
          ${format(localDatetime, 'MM/dd/yyyy')} at
          ${format(localDatetime, 'HH:mm')}
          `,
        );
      }
    }
  }
}
