import { Loading } from '@monorepo/shared/components/Loading';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import {
  Content,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { useToast } from '@monorepo/shared/contexts';
import { useResourceCount } from '@monorepo/shared/hooks/resources/useResourceCount';
import { useResourceType } from '@monorepo/shared/hooks/resources/useResourceType';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import * as Sentry from '@sentry/browser';
import { ResourceTypeRefResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useResourceTypeCreate } from '../../hooks/useResourceTypeCreate';
import { useResourceTypeUpdate } from '../../hooks/useResourceTypeUpdate';
import {
  EditResourceTypeHeader,
  WizardStep,
} from '../EditResourceTypeHeader/EditResourceTypeHeader';
import { ResourceTypeEditMode } from '../routerTypes';
import {
  EditResourceTypeForm,
  FormValues,
  pickFormValues,
} from './EditResourceTypeForm';

function EditResourceTypePageContent() {
  useDisableSubnav();

  const {
    mode = ResourceTypeEditMode.NEW,
    organizationId,
    resourceTypeId,
  } = useParams();
  const resourceTypeExists = !!resourceTypeId;
  const isEditMode = mode === ResourceTypeEditMode.EDIT;

  const { resourceType, isLoading } = useResourceType({
    organizationId,
    resourceTypeId,
  });
  const initialValues = useMemo(
    () => resourceType && pickFormValues(resourceType),
    [resourceType],
  );

  const { hasResources, isLoading: isResourceCountLoading } = useResourceCount({
    organizationId,
    resourceTypeId,
  });

  // Changing the site toggle will also fail in some cases if
  // 1. this Resource Type is made site specific and is referenced from another Resource Type already
  // 2. this Resource Type is made general and is already referencing other Resource Types
  // but adding that test to the frontend seems more involved than it's worth, since the error message from the failed request is informative.
  const isSiteToggleDisabled = isResourceCountLoading || hasResources;

  const navigate = useNavigate();
  const { showUserFriendlyErrorToast, success } = useToast();
  const [creator] = useResourceTypeCreate({ config: { throwOnError: true } });
  const [updator] = useResourceTypeUpdate({ config: { throwOnError: true } });
  const navigateToNextStep = useCallback(
    (savedId: string) => {
      if (!resourceTypeExists) {
        navigate(`../${savedId}/new/properties`);
      } else {
        navigate('../../properties');
      }
    },
    [navigate, resourceTypeExists],
  );
  const onSubmit = useCallback(
    async (values: FormValues, form: FormRenderProps<FormValues>['form']) => {
      try {
        // If we are still in create new Resource type flow, user can go back to the first step,
        // and if they don't make any changes, we don't send server request and don't show success toast.
        const { pristine } = form.getState();
        if (pristine) {
          if (!resourceTypeId) {
            Sentry.captureException(
              '[EditResourceTypePage Error]: Create button is not disabled for pristine form',
            );
            throw new Error('Please, fill in the form before saving');
          }
          navigateToNextStep(resourceTypeId);
          return;
        }

        if (!organizationId) {
          const errMsg = `Couldn't save Resource type: this page doesn't have organization id`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }
        // RQ hook returns server response or undefined, probably bc throwOnError flag is false by default
        let saved: ResourceTypeRefResponse | undefined;
        if (!resourceTypeId) {
          saved = await creator({
            resourceType: values,
            organizationId,
          });
        } else {
          saved = await updator({
            organizationId,
            resourceTypeId,
            values,
          });
        }
        if (saved) {
          success(
            `${saved.name} has been ${resourceTypeId ? 'edited.' : 'created.'}`,
          );
          if (!isEditMode) {
            navigateToNextStep(saved.id);
          }
        }
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save ${values.name}.`, {
          dontAutoHide: true,
        });
      }
    },
    [
      creator,
      showUserFriendlyErrorToast,
      isEditMode,
      navigateToNextStep,
      organizationId,
      resourceTypeId,
      success,
      updator,
    ],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <Form<FormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ submitting: true, pristine: true }}
      >
        {({ handleSubmit, pristine, submitting }) => (
          <>
            <EditResourceTypeHeader
              canSubmit={!pristine || (resourceTypeExists && !isEditMode)}
              isSubmitting={submitting}
              onSubmit={handleSubmit}
              wizardStep={WizardStep.DETAILS}
            />
            <Content>
              <Title>Basic Info</Title>
              <EditResourceTypeForm
                isSiteToggleDisabled={isSiteToggleDisabled}
              />
            </Content>
          </>
        )}
      </Form>
    </Page>
  );
}

export const EditResourceTypePage = () => (
  <ErrorBoundary>
    <EditResourceTypePageContent />
  </ErrorBoundary>
);
