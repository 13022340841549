import { useProjectsForUser } from '@monorepo/shared/hooks/useProjectsForUser';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom-v5-compat';

import { CurrentProjectContext } from '../contexts/CurrentProjectContext';

export function useCurrentProject() {
  const context = useContext(CurrentProjectContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentProject must be used within a CurrentProjectContextProvider',
    );
  }
  return context;
}

export const useKeepCurrentProjectInSyncWithUrl = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { setCurrentProject } = useCurrentProject();
  const { projects } = useProjectsForUser();

  useEffect(() => {
    if (projectId) {
      const project = projects?.find((p) => p.id === projectId);
      setCurrentProject(project);
    }
  }, [projectId, projects, setCurrentProject]);
};
