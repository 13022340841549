import { Card as MuiCard } from '@material-ui/core';
import { Button } from '@monorepo/shared/components';
import { UserFriendlyErrorMessage } from '@monorepo/shared/componentsV2/errorDisplays/UserFriendlyErrorMessage';
import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';

const Text = styled.div`
  margin-bottom: 1rem;
`;

const StyledCard = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ComplianceCalendarErrorFallback: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => (
  <StyledCard>
    <Text>
      <UserFriendlyErrorMessage
        error={error}
        prependedText="Oops, something went wrong here."
      />
    </Text>
    <Button onClick={resetErrorBoundary} color="secondary">
      Reload Compliance Calendar
    </Button>
  </StyledCard>
);
