import { css } from 'styled-components';

// "page" ~= the container around the workspace of the app, minus nav & subnav
export const page = css`
  width: 100%;
  min-width: 500px; /* 500px is somewhat arbitrary, but we need to set some non-auto min-width so that children 
    on the page don't expand off screen when they have horizontal scrolling */
  height: 100%;
`;

export const verticalLayout = css`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

// use on child of container with `verticalLayout` to take up any remaining space left in the container,
//  but no more than the container's size
export const fillHeightAndScrollable = css`
  flex-grow: 1;
  min-height: 0; /* prevent from growing beyond the confines of its parent (from https://stackoverflow.com/a/36247448) */
  overflow-y: auto;
`;

export const contentPaddingValue = '1.5rem';

export const contentPadding = css`
  padding: ${contentPaddingValue};
`;
