import { useToast } from '@monorepo/shared/contexts/ToastContext';
import * as Sentry from '@sentry/browser';
import { UpdateLogRequest } from 'mapistry-shared';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useLogFieldsOrderUpdate } from '../../../hooks/useLogFieldsOrderUpdate';
import { useLogUpdate } from '../../../hooks/useLogUpdate';
import { WizardStep, WizardStepUrlPart } from '../EditLogHeader';
import { Mode } from '../types';

function usePersistLogFieldOrder(logId?: string, organizationId?: string) {
  const [submitLogFieldOrder] = useLogFieldsOrderUpdate();

  const persistLogFieldOrder = useCallback(
    async (logFieldOrder: string[]) => {
      if (!logId || !organizationId || !logFieldOrder) {
        return;
      }

      await submitLogFieldOrder(
        { logFieldOrder, logId, organizationId },
        { throwOnError: true },
      );
    },
    [logId, organizationId, submitLogFieldOrder],
  );

  return persistLogFieldOrder;
}

export function useSubmitInstructionsAndFieldOrder({
  logId,
  organizationId,
  mode,
}: {
  logId?: string;
  organizationId?: string;
  mode?: string;
}) {
  const navigate = useNavigate();
  const { showUserFriendlyErrorToast, success } = useToast();

  const isCreateNewLogMode = mode === Mode.new;

  const [persistLogDetails] = useLogUpdate({ config: { throwOnError: true } });
  const persistLogFieldOrder = usePersistLogFieldOrder(logId, organizationId);

  const onSubmit = useCallback(
    async (
      values: UpdateLogRequest,
      logFieldOrder: string[],
      {
        instructionsHaveChanged,
        fieldOrderHasChanged,
      }: { instructionsHaveChanged: boolean; fieldOrderHasChanged: boolean },
    ) => {
      try {
        if (!organizationId) {
          const errMsg = `Couldn't edit log: LogDataSetup page doesn't have organization (${organizationId})`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }

        if (instructionsHaveChanged || fieldOrderHasChanged) {
          await Promise.all([
            instructionsHaveChanged &&
              persistLogDetails({ log: values, organizationId }),
            fieldOrderHasChanged && persistLogFieldOrder(logFieldOrder),
          ]);

          success(`${values.name} has been edited.`);
        }

        if (isCreateNewLogMode) {
          const link = WizardStepUrlPart[WizardStep.FORMULAS];
          navigate(`../${link}`);
        }
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save ${values.name}.`);
      }
    },
    [
      isCreateNewLogMode,
      navigate,
      organizationId,
      persistLogDetails,
      persistLogFieldOrder,
      success,
      showUserFriendlyErrorToast,
    ],
  );

  return onSubmit;
}
