import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { SitePickerForOrg } from '../SitePickerForOrg';

type SitePickerFieldProps = Omit<
  React.ComponentProps<typeof SitePickerForOrg>,
  'value' | 'onChange' | 'error' | 'errorText'
> & {
  initialValue?: string;
  name: string;
  validate?: UseFieldConfig<string>['validate'];
};

export function SitePickerForOrgField({
  name,
  validate,
  initialValue,
  ...rest
}: SitePickerFieldProps) {
  const { input, meta } = useField<string>(name, {
    validate,
    initialValue,
  });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <SitePickerForOrg
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      value={input.value}
      onChange={input.onChange}
      name={name}
    />
  );
}
