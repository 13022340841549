import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  LogCacheEvents,
  logCacheRegister,
} from '../../cacheRegisters/data2/logCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '../../cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '../../cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '../../cacheRegisters/data2/resourceTypeCacheRegister';

type KeyParams = Api.FetchLogInfoParams;
type CacheKeyParams = Omit<KeyParams, 'logId'> & { logId?: KeyParams['logId'] };

/*
 * Order matters. TypeScript seems to only consider the first overload when looking at
 * return types. Swapping the order of these two overloads will result in the `fetcher`
 * variable below complaining about logId being of type `string | undefined`
 */
function createCacheKey(
  params: KeyParams,
): readonly ['logInfo', string, string];
function createCacheKey(
  params: CacheKeyParams,
): readonly ['logInfo', string, string] | ['logInfo', string];
function createCacheKey({ logId, organizationId }: CacheKeyParams | KeyParams) {
  return ['logInfo', organizationId, ...(logId ? [logId] : [])] as const;
}

const createQueryKey = (params: KeyParams) => createCacheKey(params);

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogInfo
>;

type UseLogInfoParams = Partial<Api.FetchLogInfoParams> & {
  config?: QueryConfig<Api.FetchLogInfoResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (_: string, organizationId: string, logId: string) =>
  Api.fetchLogInfo({ logId, organizationId });

export const useLogInfo = ({
  config: inputConfig,
  logId,
  organizationId,
}: UseLogInfoParams) => {
  const isEnabled = !!organizationId && !!logId;
  const config = {
    ...inputConfig,
    enabled:
      inputConfig?.enabled != null
        ? inputConfig.enabled && isEnabled
        : isEnabled,
  };
  const key = isEnabled ? createQueryKey({ logId, organizationId }) : undefined;
  const { data: logInfo, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    logInfo,
    ...queryInfo,
  };
};

async function invalidateCache(keyParams: CacheKeyParams): Promise<void> {
  await queryCache.invalidateQueries(createCacheKey(keyParams));
}

const cacheRegisterInvalidator = {
  hookName: 'useLogInfo',
  callback: (keyParams: CacheKeyParams) => invalidateCache(keyParams),
};

logCacheRegister(
  [LogCacheEvents.CREATE, LogCacheEvents.DELETE, LogCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);

logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.UPDATE,
    ResourceTypeCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourcePropertyCacheRegister(
  [
    ResourcePropertyCacheEvents.CREATE,
    ResourcePropertyCacheEvents.UPDATE,
    ResourcePropertyCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);
