import Store from '@monorepo/old-web/js/components/Store';
import { DocumentTitleTemplate } from '@monorepo/shared/componentsV2/DocumentTitle';
import { PageWithCollapsibleSubnav } from '@monorepo/shared/componentsV2/Subnav/PageWithCollapsibleSubnav';
import { CurrentProjectProvider } from '@monorepo/shared/contexts/CurrentProjectContext';
import {
  useCurrentProject,
  useKeepCurrentProjectInSyncWithUrl,
} from '@monorepo/shared/hooks/useCurrentProject';
import { useKeepLastProjectUpdated } from '@monorepo/shared/hooks/useLastProject';
import React from 'react';
import { Outlet } from 'react-router-dom-v5-compat';
import { ConfirmProjectAndOrgAccess } from './ConfirmProjectAndOrgAccess';
import { ProjectSubnav } from './ProjectSubnav';

const ProjectPage = () => {
  const { project } = useCurrentProject();
  useKeepLastProjectUpdated();
  useKeepCurrentProjectInSyncWithUrl();

  return (
    <PageWithCollapsibleSubnav subnavComponent={<ProjectSubnav />}>
      <DocumentTitleTemplate titleTemplate={project?.name} />

      <Outlet />
    </PageWithCollapsibleSubnav>
  );
};

export function ProjectLayout() {
  return (
    <ConfirmProjectAndOrgAccess>
      <CurrentProjectProvider>
        <Store>
          <ProjectPage />
        </Store>
      </CurrentProjectProvider>
    </ConfirmProjectAndOrgAccess>
  );
}
