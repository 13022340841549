import { useContext } from 'react';
import { ModalContext } from '../contexts/ModalContextV2/ModalContext';

export const useModal = () => {
  const modals = useContext(ModalContext);
  if (!modals) {
    throw new Error('Need to use the ModalProvider!');
  }
  return modals;
};
