import { useLastQueryStepIsInvalid } from '@monorepo/logs/src/contexts/QueryStepsContext';
import React, { useEffect } from 'react';
import styled from 'styled-components';

interface InvalidQueryStepProps {
  errorMessage: string;
}

// padding just to give it some space
const InvalidQueryStepMessaging = styled.div`
  padding: 1rem 0 0.5rem;
`;

export const InvalidQueryStep = ({
  errorMessage: message,
}: InvalidQueryStepProps) => {
  const { setLastQueryStepIsInvalid } = useLastQueryStepIsInvalid();

  useEffect(() => {
    setLastQueryStepIsInvalid(true);
  }, [setLastQueryStepIsInvalid]);

  return <InvalidQueryStepMessaging>{message}</InvalidQueryStepMessaging>;
};
