import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { FieldDataType } from 'mapistry-shared';
import React from 'react';

export function LogDateFieldPreview() {
  return (
    <FieldTypePreviewFactory
      field={{
        name: 'Log Date',
        type: FieldDataType.DATETIME,
        isRequired: true,
      }}
      fieldTypeName="field"
      isLocked
      lockedIconTooltip="The Log Date field cannot be edited. The Log Date matches a given Log entry to both the frequency requirement it fulfills and the time period the data will be aggregated to in Views and limits."
    />
  );
}
