import {
  FieldDataType,
  FieldResponse,
  FormulaFieldResponse,
  MathUnit,
  MathUnitName,
  NumericFieldResponse,
  ResourceFieldResponse,
} from 'mapistry-shared';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { fieldTypeOptions } from '../fieldTypeOptions';
import { FieldTypePreview } from './FieldTypePreview';
import { FormulaFieldTypePreviewAdditionalInfo } from './FormulaFieldTypePreviewAdditionalInfo';

type LinkInfo = {
  to: string;
  title: string;
};

type FieldInfo = (
  | Pick<FieldResponse, 'name' | 'type'>
  | Pick<NumericFieldResponse, 'name' | 'type' | 'units'>
) &
  Partial<Pick<FieldResponse, 'isRequired'>>;

type FieldTypePreviewFactoryProps = Omit<
  React.ComponentProps<typeof FieldTypePreview>,
  'icon' | 'fieldName' | 'isRequired'
> & {
  field: FieldInfo;
};

function getLinkToResourceType(
  organizationId: string | undefined,
  field: ResourceFieldResponse,
): LinkInfo | undefined {
  if (!field.resourceTypeId || !organizationId) {
    return undefined;
  }
  return {
    to: `/vortex/organizations/${organizationId}/data-setup/resource-types/${field.resourceTypeId}/resources`,
    title: `Go to Resource type for ${field.name}`,
  };
}

export function FieldTypePreviewFactory({
  field,
  ...restProps
}: FieldTypePreviewFactoryProps) {
  const { organizationId } = useParams();

  let additionalInfo: React.ReactElement | string | undefined;
  let fieldName: string | undefined;
  let icon: React.ReactElement | undefined;
  let link: LinkInfo | undefined;
  let tooltip: string | undefined;
  let units: MathUnitName | null | undefined;

  switch (field.type) {
    case FieldDataType.FORMULA:
      {
        const formulaField = field as FormulaFieldResponse;
        fieldName = formulaField.name;
        additionalInfo = (
          <FormulaFieldTypePreviewAdditionalInfo formulaField={formulaField} />
        );
      }
      break;
    case FieldDataType.NUMERIC:
      units = (field as NumericFieldResponse).units;
      fieldName = units
        ? `${field.name} (${MathUnit.getLabel(units)})`
        : field.name;
      ({ icon, label: tooltip } = fieldTypeOptions[field.type]);
      break;
    case FieldDataType.RESOURCE:
      fieldName = field.name;
      ({ icon, label: tooltip } = fieldTypeOptions[field.type]);
      link = getLinkToResourceType(
        organizationId,
        field as ResourceFieldResponse,
      );
      break;
    case FieldDataType.BOOLEAN:
    case FieldDataType.DATE:
    case FieldDataType.DATETIME:
    case FieldDataType.LOCATION:
    case FieldDataType.PARAMETER:
    case FieldDataType.SIGNATURE:
    case FieldDataType.SINGLE_SELECT:
    case FieldDataType.SUBSTANCE:
    case FieldDataType.TEXT:
    case FieldDataType.TIME:
    case FieldDataType.USER:
      fieldName = field.name;
      ({ icon, label: tooltip } = fieldTypeOptions[field.type]);
      break;
    default: {
      const exhaustive: never = field.type;
      throw new Error(`Invalid field type - ${exhaustive}`);
    }
  }

  return (
    <FieldTypePreview
      {...restProps}
      additionalInfo={additionalInfo}
      fieldName={fieldName}
      isRequired={field.isRequired}
      icon={icon}
      iconTooltip={tooltip}
      link={link}
    />
  );
}
