import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

type SubnavContext = {
  contentResizingWithSubnavRef: React.RefObject<HTMLDivElement>;
  isSubnavDisabled: boolean;
  isSubnavOpen: boolean;
  setIsSubnavDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSubnav: () => void;
};

export const SubnavContext = createContext<SubnavContext | undefined>(
  undefined,
);

type SubnavProviderProps = {
  children: React.ReactNode;
};

export const SubnavProvider = ({ children }: SubnavProviderProps) => {
  const contentResizingWithSubnavRef = useRef<HTMLDivElement>(null);

  const [isSubnavOpen, setIsSubnavOpen] = useState(true);
  const [isSubnavDisabled, setIsSubnavDisabled] = useState(false);

  const toggleSubnav = useCallback(() => {
    setIsSubnavOpen((isCurrentlyOpen) => !isCurrentlyOpen);
  }, [setIsSubnavOpen]);

  const value = useMemo(
    () => ({
      contentResizingWithSubnavRef,
      isSubnavDisabled,
      isSubnavOpen,
      setIsSubnavDisabled,
      toggleSubnav,
    }),
    [isSubnavDisabled, isSubnavOpen, toggleSubnav],
  );

  return (
    <SubnavContext.Provider value={value}>{children}</SubnavContext.Provider>
  );
};
