import {
  OnOrderChange,
  ReorderableElement,
  ReorderableList,
} from '@monorepo/shared/componentsV2/dragAndDrop/ReorderableList';
import { FieldResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NamePropertyPreview } from '../BuiltInPropertyPreviews/NamePropertyPreview';
import { SitePropertyPreview } from '../BuiltInPropertyPreviews/SitePropertyPreview';
import { ResourcePropertyPreview } from './ResourcePropertyPreview';

const FieldList = styled.ul`
  max-width: 38rem;
  padding: 0;
  margin: 0.25rem 0;
`;

const ReorderableFieldsSubList = styled.li`
  list-style-type: none;
`;

const StyledResourcePropertyPreview = styled(ResourcePropertyPreview)`
  margin: 0;

  /* so elements aren't see-through while being dragged over one another  */
  background-color: ${({ theme }) => theme.colors.white};
`;

interface ReorderableResourceFieldsProps {
  isSiteSpecific: boolean;
  listLabelId: string;
  sortedResourceFields: FieldResponse[];
  updateResourceFieldOrder: OnOrderChange;
  onDelete: (p: FieldResponse) => void;
  onEdit: (p: FieldResponse) => void;
}

export function ResourcePropertiesList({
  listLabelId,
  isSiteSpecific,
  sortedResourceFields,
  updateResourceFieldOrder,
  onDelete,
  onEdit,
}: ReorderableResourceFieldsProps) {
  const orderedElements: ReorderableElement[] = useMemo(
    () =>
      sortedResourceFields.map((resourceField) => ({
        id: resourceField.id,
        component: (
          <StyledResourcePropertyPreview
            outerElement="div"
            onDelete={onDelete}
            onEdit={onEdit}
            property={resourceField}
          />
        ),
      })),
    [onDelete, onEdit, sortedResourceFields],
  );

  return (
    <FieldList aria-labelledby={listLabelId}>
      <NamePropertyPreview key="name" />
      {isSiteSpecific && <SitePropertyPreview key="site" />}
      <ReorderableFieldsSubList>
        <ReorderableList
          orderedElements={orderedElements}
          onOrderChange={updateResourceFieldOrder}
          uniqueDragListId="reorderableResourceProperties"
          listAriaLabel="Reorderable Resource Properties"
        />
      </ReorderableFieldsSubList>
    </FieldList>
  );
}
