import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateTasksCache,
  TasksCacheEvents,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import React, { useCallback, useMemo } from 'react';
import { useDeleteTask } from '../../../hooks/tasks/useDeleteTask';
import { useSaveTask } from '../../../hooks/tasks/useSaveTask';
import { useTask } from '../../../hooks/tasks/useTask';
import TaskEditModalContainer from './TaskEditModalContainer';

type TaskEditModalDataProps = {
  taskId?: string;
  projectId: string;
  dueDate?: string;
  reloadTaskList?: () => void;

  // passthrough props, defined here since TaskEditModalContainer is not a tsx component
  isOpen: boolean;
  onClose: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TaskEditModalData(props: TaskEditModalDataProps) {
  const { projectId, taskId, dueDate, reloadTaskList, ...rest } = props;

  const {
    task,
    error: fetchError,
    status: fetchStatus,
  } = useTask(
    taskId && projectId
      ? { variables: { projectId, taskId, dueDate }, config: { staleTime: 0 } }
      : undefined,
  );
  const [saveTask, { error: savingError, status: savingStatus }] =
    useSaveTask();
  const [deleteTask, { error: deletingError, status: deletingStatus }] =
    useDeleteTask();

  const serverErrorMessage = useMemo(() => {
    const error =
      fetchError ?? savingError ?? (deletingError as Api.ErrorResponse);
    if (!error) return undefined;
    if (error instanceof Error) return error.message;
    if (error.status === 404) {
      return "Couldn't find a task with this id. It might've been deleted.";
    }
    return 'There was a problem with the request, please try again later.';
  }, [fetchError, savingError, deletingError]);

  const handleSaveTask = useCallback(
    async (_: unknown, taskValues: Api.TaskValues) => {
      if (projectId) {
        await saveTask({ projectId, taskValues });
        if (reloadTaskList) {
          reloadTaskList();
        }
      }
    },
    [saveTask, projectId, reloadTaskList],
  );

  const handleDeleteTask = useCallback(
    async (taskToDelete) => {
      await deleteTask(taskToDelete);
      invalidateTasksCache(TasksCacheEvents.DELETE, { projectId });
    },
    [deleteTask, projectId],
  );

  return (
    <TaskEditModalContainer
      {...rest}
      taskId={taskId}
      taskWithSubtasks={task}
      serverErrorMessage={serverErrorMessage}
      isLoading={fetchStatus === 'loading'}
      isSaving={savingStatus === 'loading'}
      saveTask={handleSaveTask}
      deleteTask={handleDeleteTask}
      isDeleting={deletingStatus === 'loading'}
      projectId={projectId}
    />
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TaskEditModalData;
