import {
  Content,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useToast } from '@monorepo/shared/contexts';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { useWorkflow } from '@monorepo/shared/hooks/workflows/useWorkflow';
import { useWorkflowUpdate } from '@monorepo/workflowDataSetup/src/hooks/useWorkflowUpdate';
import * as Sentry from '@sentry/browser';
import React, { useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useWorkflowCreate } from '../../hooks/useWorkflowCreate';
import { EditWorkflowHeader } from '../EditWorkflowHeader';
import { InfoContainer } from '../shared/DatasetPicker/styled';
import { RouteEditMode, WizardStep, WizardStepUrlPart } from '../types';
import {
  EditWorkflowForm,
  FormValues,
  pickFormValues,
} from './EditWorkflowForm';

function FormLoadingState() {
  return (
    <InfoContainer>
      <Loading size="medium" />
    </InfoContainer>
  );
}

export function EditWorkflowPage() {
  useDisableSubnav();

  const { mode = RouteEditMode.NEW, organizationId, workflowId } = useParams();
  const isNewMode = mode === RouteEditMode.NEW;
  const workflowExists = !!workflowId;

  const { workflow, isLoading } = useWorkflow({
    organizationId,
    workflowId,
  });
  const initialValues = useMemo(
    () => workflow && pickFormValues(workflow),
    [workflow],
  );

  const navigate = useNavigate();
  const navigateToNextStep = useCallback(
    (savedId: string) => {
      const stepsUrlPart = WizardStepUrlPart[WizardStep.STEPS];
      navigate(
        workflowExists
          ? `../../${stepsUrlPart}`
          : `../../${savedId}/new/${stepsUrlPart}`,
      );
    },
    [navigate, workflowExists],
  );

  const [creator] = useWorkflowCreate({ config: { throwOnError: true } });
  const [updator] = useWorkflowUpdate({ config: { throwOnError: true } });
  const { showUserFriendlyErrorToast, success: showSuccessToast } = useToast();
  const onSubmit = useCallback(
    async (values: FormValues, form: FormRenderProps<FormValues>['form']) => {
      try {
        // If we are still in create new Workflow flow, user can go back to the first step,
        // and if they don't make any changes, we don't send server request and don't show success toast.
        const { pristine } = form.getState();
        if (pristine) {
          if (!workflowId) {
            Sentry.captureException(
              '[EditWorkflowPage Error]: Create button is not disabled for pristine form',
            );
            throw new Error('Please, fill in the form before saving');
          }
          navigateToNextStep(workflowId);
          return;
        }

        if (!organizationId) {
          const errMsg = `Couldn't save Workflow: this page doesn't have organization id`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }

        const saved = workflowId
          ? await updator({
              organizationId,
              updatedValues: values,
              workflowId,
            })
          : await creator({
              organizationId,
              workflow: values,
            });

        // As far as TypeScript knows, RQ hook returns server response or undefined,
        // probably bc it doesn't know whether a hook received "throwOnError" config true or false
        if (!saved) {
          Sentry.captureException(
            '[EditWorkflowPage Error]: onSubmit callback expects RQ mutation hooks to throw on error',
          );
          throw new Error(
            'Something went wrong, your Workflow might not be saved',
          );
        }

        showSuccessToast(
          `${saved.name} has been ${workflowId ? 'edited.' : 'created.'}`,
        );
        if (isNewMode) {
          navigateToNextStep(saved.id);
        }
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save ${values.name}.`, {
          dontAutoHide: true,
        });
      }
    },
    [
      creator,
      isNewMode,
      navigateToNextStep,
      organizationId,
      showUserFriendlyErrorToast,
      showSuccessToast,
      updator,
      workflowId,
    ],
  );

  return (
    <Page>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ pristine: true, submitting: true }}
      >
        {({ handleSubmit, pristine, submitting }) => (
          <>
            <EditWorkflowHeader
              canSubmit={!pristine || (isNewMode && workflowExists)}
              isSubmitting={submitting}
              onSubmit={handleSubmit}
              wizardStep={WizardStep.DETAILS}
            />
            <Content>
              <Title>Basic Info</Title>
              {isLoading ? <FormLoadingState /> : <EditWorkflowForm />}
            </Content>
          </>
        )}
      </Form>
    </Page>
  );
}
