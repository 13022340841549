import React, { useEffect, useState } from 'react';
import { Api } from 'mapistry-shared/api';

// alternatively, use `withFeatureFlag` render prop component
export const FeatureFlagsContext = React.createContext({
  isLoading: false,
  featureFlags: {},
});

export const FeatureFlagsProvider = ({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  featureFlags: featureFlagsProp,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlags, setFeatureFlags] = useState(featureFlagsProp);
  useEffect(() => {
    if (!featureFlags) {
      setIsLoading(true);
      Api.fetchFeatureFlags()
        .then((ff) => {
          setFeatureFlags(ff);
          setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching feature flags', error);
          setIsLoading(false);
        });
    }
  }, [featureFlags]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isLoading,
    featureFlags,
  };
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-filename-extension
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
export const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;
