import { format } from 'date-fns';
import { NullableDate } from 'mapistry-shared';
import React, { useState } from 'react';
import CalendarIcon from '../../../public/svg/calendar.svg';
import { useDateRangeFilter } from '../hooks/useDateRangeFilter';
import { useOpenClose } from '../hooks/useOpenClose';
import { Button } from './Button';
import { DateRangePicker } from './DateRangePicker';
import { BaseModal } from './modals/BaseModal';

type DateRangeFilterModalProps = Omit<
  React.ComponentProps<typeof BaseModal>,
  'buttons' | 'title'
>;

function DateRangeFilterModal({ onClose, open }: DateRangeFilterModalProps) {
  const { filter, setFilter } = useDateRangeFilter();
  const [from, setFrom] = useState<NullableDate>(filter?.from || null);
  const [to, setTo] = useState<NullableDate>(filter?.to || null);

  return (
    <BaseModal
      buttons={
        <>
          <Button color="primary" onClick={onClose} type="button">
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={() => {
              setFilter({
                from,
                to,
              });
              onClose?.();
            }}
            type="submit"
            variant="contained"
          >
            Filter
          </Button>
        </>
      }
      onClose={onClose}
      open={open}
      showCloseButton
      title="Filter date range"
    >
      <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} />
    </BaseModal>
  );
}

export function DateRangeFilterButton() {
  const [isOpen, open, close] = useOpenClose();
  const { filter } = useDateRangeFilter();
  const hasFilter = !!filter && !!(filter.from || filter.to);

  let label = 'Filter date range';
  const fromDateLabel = filter?.from ? format(filter.from, 'MM/dd/yyyy') : null;
  const toDateLabel = filter?.to ? format(filter.to, 'MM/dd/yyyy') : null;
  if (fromDateLabel && toDateLabel) {
    label = `${fromDateLabel} - ${toDateLabel}`;
  } else if (fromDateLabel) {
    label = `After ${fromDateLabel}`;
  } else if (toDateLabel) {
    label = `Before ${toDateLabel}`;
  }

  return (
    <>
      <Button onClick={open} variant={hasFilter ? 'outlined' : undefined}>
        <CalendarIcon />
        {label}
      </Button>
      <DateRangeFilterModal onClose={close} open={isOpen} />
    </>
  );
}
