import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '../elements/index';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function RecurringTaskSaveModal(props) {
  const { fields, isOpen, onAction, onClose } = props;

  const numOfField = fields.length;
  const fieldsText = fields.reduce((text, field, index) => {
    if (index === 0) {
      return field;
    }
    const delimiter = index === numOfField - 1 ? ' and ' : ', ';
    return `${text}${delimiter}${field}`;
  }, '');
  const field = `field${numOfField === 1 ? '' : 's'}`;

  return (
    <Modal
      className="recurring-task-save-modal"
      fullWidth={false}
      header={<h4>Edit recurring task</h4>}
      hideBackdrop
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
      paperClassName="recurring-task-save-modal__paper"
    >
      <p className="recurring-task-save-modal__paragraph">
        {"You've changed the "}
        <b>{`task ${fieldsText}`}</b>
        {` ${field} of this recurring task.`}
      </p>
      <p className="recurring-task-save-modal__paragraph">
        {'Should these changes affect this '}
        <i>task only</i>
        {' or '}
        <i>all future recurring tasks</i>?
      </p>
      <div className="recurring-task-save-modal__buttons">
        <Button color="secondary" onClick={() => onAction(false)}>
          Update this task only
        </Button>
        <Button color="secondary" onClick={() => onAction(true)}>
          Update all future tasks
        </Button>
      </div>
    </Modal>
  );
}

RecurringTaskSaveModal.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
