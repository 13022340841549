import React from 'react';
import { Field } from 'react-final-form';
import { FieldWrapper, TextField } from '../elements';

type WidgetNameFieldProps = {
  label: string;
  name: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GenericLogsWidgetNameField(props: WidgetNameFieldProps) {
  const { name, label } = props;

  return (
    <Field name={name}>
      {(field) => (
        <div className="field-wrapper">
          <FieldWrapper label={label}>
            <TextField
              onChange={(evt) => field.input.onChange(evt.target.value)}
              value={field.input.value}
            />
          </FieldWrapper>
        </div>
      )}
    </Field>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsWidgetNameField;
