import { IntervalFrequencyEnum } from 'mapistry-shared';
import React, { useMemo, useCallback } from 'react';
import { Select } from '../elements';
import getFrequencyFromResource from '../../utils/genericLogs/getFrequencyFromResource';
import {
  ResourceKey,
  ChartableGenericLogsResource,
} from '../../types/ts-types';

type Value = ResourceKey;

type Option = {
  label: string;
  value: Value;
};

type GenericLogsResourceSelectProps = {
  error?: boolean;
  filterByFrequency?: IntervalFrequencyEnum;
  onChange: (value: Value) => void;
  resources: ChartableGenericLogsResource[];
  value: Value;
};

function GenericLogsResourceSelect(props: GenericLogsResourceSelectProps) {
  const { value, onChange, error, resources, filterByFrequency } = props;
  const resourceOptions = useMemo<Option[]>(() => {
    const filteredResources = filterByFrequency
      ? resources.filter(
          (r) => getFrequencyFromResource(r) === filterByFrequency,
        )
      : resources;
    return filteredResources.map((r) => ({
      label: r.entity.name,
      value: {
        resourceType: r.resourceType,
        resourceId: r.resourceId,
      },
    }));
  }, [resources, filterByFrequency]);

  const handleChange = useCallback(
    (opt: Option) => {
      onChange(opt.value);
    },
    [onChange],
  );

  return (
    <Select
      error={error}
      isFixed
      isClearable={false}
      options={resourceOptions}
      onChange={handleChange}
      value={resourceOptions.find(
        (o) => o.value.resourceId === value.resourceId,
      )}
    />
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsResourceSelect;
