import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum LogEntryCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  logEntryId?: string;
  logId: string;
  organizationId: string;
  projectId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof LogEntryCacheEvents,
  CacheParams
>(LogEntryCacheEvents);

export const invalidateLogEntryCache = cacheRegister.invalidate;
export const logEntryCacheRegister = cacheRegister.register;
