import { withPermissions } from '@monorepo/shared/hooks/permissions/withPermissions';
import AddIcon from '@svg/add.svg';
import Filter from '@svg/filter.svg';
import { format } from 'date-fns';
import { localEquivalentOfUTC } from 'mapistry-shared';
import React, { Component } from 'react';
import {
  Card,
  cardHeights,
  cardWidths,
  FiltersLine,
  IconButton,
} from '../../../elements';
import LoadingIndicator from '../../../elements/LoadingIndicator';
import { ProjectTaskType } from '../../../propTypes';
import { withParamsAndNavigate } from '../../../routing/withReactRouter';
import FilterSettings from './FilterSettings';
import { TasksCardSection } from './TasksCardSection';

interface TasksCardProps {
  filters: {
    assignees: { userId: string; name: string }[];
    end: Date | string;
    start: Date | string;
  };
  hasProjectUpdatePermission: boolean;
  isLoading: boolean;
  navigate(to: string): void;
  onFiltersChange(filters: typeof ProjectTaskType[]): void;
  projectId: string;
  tasks: {
    completedTasks: typeof ProjectTaskType[];
    currentTasks: typeof ProjectTaskType[];
    filteredTasks: typeof ProjectTaskType[];
    futureTasks: typeof ProjectTaskType[];
    overdueTasks: typeof ProjectTaskType[];
  };
}

class TasksCardPresentation extends Component<
  TasksCardProps,
  { showFilterSettings: boolean }
> {
  constructor(props: TasksCardProps) {
    super(props);
    this.state = {
      showFilterSettings: false,
    };
  }

  handleShowFilterSettings = () => {
    this.setState({ showFilterSettings: true });
  };

  handleCloseFilterSettings = () => {
    this.setState({ showFilterSettings: false });
  };

  handleOpenTaskModal = () => {
    const { navigate } = this.props;
    navigate('?taskId=new');
  };

  handleFilterSettingsSave = (newFilters: typeof ProjectTaskType[]) => {
    const { onFiltersChange } = this.props;
    this.setState({ showFilterSettings: false }, () =>
      onFiltersChange(newFilters),
    );
  };

  filterView() {
    return (
      <div className="card__filter-icon">
        <IconButton onClick={this.handleShowFilterSettings}>
          <Filter aria-hidden className="m-icon" />
          <div className="visually-hidden">Filter Settings</div>
        </IconButton>
      </div>
    );
  }

  filterOptions() {
    const { filters } = this.props;
    const { assignees, start, end } = filters;
    const options = [];
    if (assignees?.length) {
      assignees.forEach((user) => options.push(user.name));
    }
    if (start || end) {
      const localStart = localEquivalentOfUTC(start);
      const localEnd = localEquivalentOfUTC(end);
      const startText = localStart && format(localStart, 'M/d/yy');
      const endText = localEnd && format(localEnd, 'M/d/yy');
      if (!start) {
        options.push(`to ${endText}`);
      } else if (!end) {
        options.push(`from ${startText}`);
      } else {
        options.push(`${startText} to ${endText}`);
      }
    }
    return options;
  }

  menuOptions() {
    const { hasProjectUpdatePermission } = this.props;
    if (!hasProjectUpdatePermission) {
      return [];
    }

    return [
      {
        icon: <AddIcon className="m-icon" />,
        label: 'Add a Task',
        onClick: this.handleOpenTaskModal,
      },
    ];
  }

  renderCardContent() {
    const { isLoading, tasks, projectId } = this.props;

    const {
      completedTasks,
      currentTasks,
      filteredTasks,
      futureTasks,
      overdueTasks,
    } = tasks;

    const noTasks = Object.values(tasks).every(
      (taskGroup) => !taskGroup?.length,
    );
    if (isLoading && noTasks) {
      return (
        <LoadingIndicator
          className="tasks-card-loader"
          isAbsolute={false}
          size={30}
        />
      );
    }

    return (
      <div className="tasks-card-container">
        <FiltersLine filterOptions={this.filterOptions()} />
        <div className="tasks-card__list">
          {filteredTasks && (
            <TasksCardSection
              disableAccordion
              emptyMessage="There are no action items for this date range"
              tasks={filteredTasks}
              projectId={projectId}
            />
          )}
          {!filteredTasks && (
            <>
              <TasksCardSection
                emptyMessage="There are no action items that are currently due today"
                tasks={currentTasks}
                projectId={projectId}
                startExpanded
                title="Due Today"
              />
              {!!overdueTasks.length && (
                <TasksCardSection
                  tasks={overdueTasks}
                  projectId={projectId}
                  title="Overdue"
                />
              )}
              <TasksCardSection
                emptyMessage="There are no Future Action Items"
                tasks={futureTasks}
                projectId={projectId}
                startExpanded
                title="Future Action Items"
              />
              <TasksCardSection
                emptyMessage="No action items have recently been completed"
                tasks={completedTasks}
                projectId={projectId}
                startExpanded
                title="Recently Completed"
              />
            </>
          )}
        </div>
        {isLoading && (
          <LoadingIndicator
            className="tasks-card-loader tasks-card-loader--overtop"
            isAbsolute={false}
            size={30}
          />
        )}
      </div>
    );
  }

  render() {
    const { filters } = this.props;
    const { showFilterSettings } = this.state;

    if (showFilterSettings) {
      return (
        <FilterSettings
          filters={filters}
          onSave={this.handleFilterSettingsSave}
          onCancel={this.handleCloseFilterSettings}
        />
      );
    }

    return (
      /* @ts-expect-error Card is a JS component */
      <Card
        actions={this.filterView()}
        heightStyle={cardHeights.FLEX}
        menuOptions={this.menuOptions()}
        title="Action Items"
        widthStyle={cardWidths.MEDIUM}
      >
        {this.renderCardContent()}
      </Card>
    );
  }
}

export const TasksCard = withPermissions(
  withParamsAndNavigate(TasksCardPresentation),
);
