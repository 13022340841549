import { withParamsAndNavigate } from '@monorepo/old-web/js/components/routing/withReactRouter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskEditModal from './TaskEditModal';

class TaskModalOpener extends Component {
  handleTaskModalClose = () => {
    const { projectId } = this.props;
    this.goToProjectDashboard(projectId);
  };

  goToProjectDashboard() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const { navigate, params, projectId } = this.props;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const orgId = params.organizationId;

    const url = `/vortex/organizations/${orgId}/sites/${projectId}/tasks`;

    navigate(url, { replace: true });
  }

  render() {
    const { taskId, dueDate, projectId } = this.props;
    let taskIdToUse = taskId;

    if (!taskId) {
      return null;
    }

    if (taskId === 'new') {
      taskIdToUse = '';
    }

    return (
      <TaskEditModal
        isOpen
        onClose={this.handleTaskModalClose}
        taskId={taskIdToUse}
        projectId={projectId}
        dueDate={dueDate}
      />
    );
  }
}

TaskModalOpener.propTypes = {
  taskId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  dueDate: PropTypes.string,
};

TaskModalOpener.defaultProps = {
  taskId: null,
  dueDate: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withParamsAndNavigate(TaskModalOpener);
