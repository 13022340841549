import MuiDrawer from '@material-ui/core/Drawer';
import { bodySmall, header1 } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const Drawer = styled(MuiDrawer)`
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${({ theme }) => theme.sizes.subnavWidth};
    box-shadow: 0 4px 13px ${({ theme }) => theme.colors.blue24};
  }

  & .MuiDrawer-paperAnchorLeft {
    left: ${({ theme }) => theme.sizes.navbarWidth};
    z-index: ${({ theme }) => theme.constants.subnavZIndex};
  }
`;

export const DrawerHeader = styled.div`
  ${header1}
  padding: 21px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayddd};
`;

export const TabContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 21px;
  color: ${({ theme }) => theme.colors.gray333};
  text-align: left;

  .subtext {
    ${bodySmall}
    color: ${({ theme }) => theme.colors.gray666};
  }

  &:hover {
    font-weight: 700;
  }

  &.selected {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray333};
    background-color: ${({ theme }) => theme.colors.blue04};
  }
`;

export const TabIcon = styled.div`
  display: flex;
  align-items: center;

  & span {
    height: 1.25rem;
  }

  & svg {
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.gray333};
  }
`;
