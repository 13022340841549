import { bodySmall } from '@monorepo/shared/styles/text';
import startcase from 'lodash.startcase';
import { FormulaFieldResponse } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';

interface FormulaFieldTypePreviewAdditionalInfoProps {
  formulaField: FormulaFieldResponse;
}

const Row = styled.div`
  ${bodySmall}
  padding: 0 0 0.5rem;
  color: ${({ theme }) => theme.colors.gray666};

  &:first-child {
    color: ${({ theme }) => theme.colors.gray333};
  }

  &:last-child {
    padding: 0;
  }
`;

const FormattedText = styled.pre`
  ${bodySmall}
  margin: 0;
  font-family: inherit;
  white-space: pre-wrap;
`;

export function FormulaFieldTypePreviewAdditionalInfo({
  formulaField: { description, expression, outputType, units },
}: FormulaFieldTypePreviewAdditionalInfoProps) {
  return (
    <>
      <Row>{expression}</Row>
      <Row>
        Output: {startcase(outputType)} {!!units && <>({units})</>}
      </Row>
      {!!description && (
        <Row>
          <FormattedText>{description}</FormattedText>
        </Row>
      )}
    </>
  );
}
