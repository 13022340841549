import { BaseEditEntryModal } from '@monorepo/shared/componentsV2/logs/BaseEditEntryModal';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import { useLogEntry } from '@monorepo/shared/hooks/logs/useLogEntry';
import { useLogEntryCreate } from '@monorepo/shared/hooks/logs/useLogEntryCreate';
import { useLogEntryUpdate } from '@monorepo/shared/hooks/logs/useLogEntryUpdate';
import { useLogEntryFields } from '@monorepo/shared/hooks/logs/useLogFields';
import {
  DateLike,
  LogEntryResponse,
  SaveFieldValueRequest,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import React from 'react';
import { USAGE_TRACKING_PREFIX } from '../../../constants/usageTracking';
import { EditLogEntryBuiltInFields } from './EditLogEntryBuiltInFields';

type EditLogEntryModalProps = {
  logDate?: DateLike;
  logEntryId?: string;
  logId?: string;
  onClose: () => void;
  open: boolean;
  organizationId?: string;
  projectId?: string;
};

export function EditLogEntryModal({
  logDate,
  logEntryId,
  logId,
  onClose,
  open,
  organizationId,
  projectId,
}: EditLogEntryModalProps) {
  const { log, isLoading: isLoadingLog } = useLog({ organizationId, logId });
  const { logFields, isLoading: isLoadingLogFields } = useLogEntryFields({
    organizationId,
    logId,
  });

  const { logEntry: existingLogEntry, isLoading: isLoadingEntry } = useLogEntry(
    { organizationId, logId, logEntryId, projectId },
  );

  const [creator] = useLogEntryCreate({ config: { throwOnError: true } });
  const [updator] = useLogEntryUpdate({ config: { throwOnError: true } });

  if (!projectId || !organizationId || !logId) {
    throw new Error(
      'Unexpected state: Log entry modal is missing organization, project, or Log context',
    );
  }

  const isLoading = isLoadingEntry || isLoadingLogFields || isLoadingLog;

  const isAllDataLoaded =
    !!logFields && !!log && (!logEntryId || !!existingLogEntry);

  return (
    <BaseEditEntryModal<LogEntryResponse>
      canBypassSoftRequiredFieldValidation
      entryId={logEntryId}
      entryTypeDisplayName="Log entry"
      fields={logFields}
      fieldValues={existingLogEntry?.logFieldValues}
      getEntryName={() => 'Log entry'}
      hardRequiredFieldIds={['logDate']}
      isLoading={isLoading}
      modalId={`${USAGE_TRACKING_PREFIX}-edit-log-entry-modal`}
      instructions={log?.instructions}
      onClose={onClose}
      open={open}
      organizationId={organizationId}
      projectId={projectId}
      saver={(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formValues: Record<string, any>,
        fieldValues: SaveFieldValueRequest[],
      ) => {
        const logEntry = {
          // log date is a required field in the form, so it will never be null
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          logDate: UTCEquivalentOfLocal(formValues.logDate)!,
          logFieldValues: fieldValues,
        };
        return logEntryId
          ? updator({ organizationId, projectId, logId, logEntryId, logEntry })
          : creator({ organizationId, projectId, logId, logEntry });
      }}
    >
      {open && !isLoading && isAllDataLoaded && (
        <EditLogEntryBuiltInFields
          existingLogEntry={existingLogEntry}
          initialLogDate={logDate}
        />
      )}
    </BaseEditEntryModal>
  );
}
