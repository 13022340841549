import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { AddStepButton } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage/AddStepButton';
import { WorkflowStepOperationType } from 'mapistry-shared';
import React from 'react';

interface EmptyListProps {
  onAddNew: (stepType: WorkflowStepOperationType) => void;
  organizationId: string;
}

export function EmptyStepList({ onAddNew, organizationId }: EmptyListProps) {
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const secondaryText = canEditOrgSettings
    ? 'Add a step to transform your data structure and create relationships between data.'
    : 'Contact your organization administrator to configure a Workflow.';

  return (
    <EmptyState
      additionalContent={
        canEditOrgSettings ? <AddStepButton onAddNew={onAddNew} /> : null
      }
      primaryText="You don't have any steps yet."
      secondaryText={secondaryText}
    />
  );
}
