import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourcePropertyCache,
  ResourcePropertyCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  getCache,
  setCache,
} from '@monorepo/shared/hooks/resources/useResourceProperties';
import { FieldResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type UseResourcePropertyUpdateParams = {
  config?: MutationConfig<FieldResponse, Api.ErrorResponse>;
};

export const useResourcePropertyUpdate = (
  params?: UseResourcePropertyUpdateParams,
) =>
  useMutation(Api.updateResourceProperty, {
    ...params?.config,
    onSuccess: (updatedProperty, mutationFuncParams) => {
      const { organizationId, resourceTypeId } = mutationFuncParams;

      const listParams = { organizationId, resourceTypeId };
      const currentItems = getCache(listParams);
      // If there is cache, update it
      if (currentItems) {
        const newItems = currentItems.map((p) =>
          p.id === updatedProperty.id ? updatedProperty : p,
        );
        setCache(listParams, newItems);
      }

      // Invalidate any RQ cache that might use information about Resource Property
      invalidateResourcePropertyCache(ResourcePropertyCacheEvents.UPDATE, {
        organizationId,
        resourcePropertyId: updatedProperty.id,
        resourceTypeId,
      });

      return params?.config?.onSuccess?.(updatedProperty, mutationFuncParams);
    },
  });
