import { Header } from '@monorepo/shared/componentsV2/Header';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useState } from 'react';
import styled from 'styled-components';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledMain = styled(Main)`
  ${fillHeightAndScrollable}
  ${verticalLayout}
`;

const BackboneWrapper = styled.iframe`
  flex-grow: 1;
  margin-top: -1rem;
  margin-left: 0.15rem;
  border: none;
`;

export function RecordsPage() {
  const [frameIsLoading, setFrameLoading] = useState(true);
  const { project } = useCurrentProject();

  return (
    <Page>
      <Header title="Records" subtitle={project?.name} variant="noDropShadow" />
      <StyledMain>
        {frameIsLoading && <Loading />}
        <BackboneWrapper
          src={`/project-records/${project?.id}`}
          title="Records Section"
          onLoad={() => setFrameLoading(false)}
        />
      </StyledMain>
    </Page>
  );
}
