import { UnitString } from 'mapistry-shared';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmissionMicroIntervalStatisticsAction } from '../../actions/air';
import APP from '../../config';
import {
  getIsFetching,
  getMicroIntervalStatistics,
} from '../../selectors/genericLogs';
import {
  Frequency,
  MicroIntervalStatistics,
  ResourceKey,
} from '../../types/ts-types';
import { createKey } from '../../utils/createKey';
import { useStableReference } from '../useStableReference';

const selectResourceMicroIntervalStatistics = (
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  state,
  params: ResourceKeyWithParams,
  logProjectId: string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
) => {
  const allMicroIntervalStatistics = getMicroIntervalStatistics(
    state,
    logProjectId,
  );
  const key = createKey(params);
  if (allMicroIntervalStatistics) {
    return allMicroIntervalStatistics[key];
  }
};

export type ResourceKeyWithParams = ResourceKey & {
  frequency: Frequency;
  start: string;
  end: string;
  unit?: UnitString;
  limits?: string[];
};

type Input = ResourceKeyWithParams[];

export function useMicroIntervalStatistics(
  input: Input | undefined,
  logProjectId: string,
) {
  const dispatch = useDispatch();
  const airMicroIntervalStatistics = useSelector((state) => {
    if (!input) return undefined;
    const allDataLoaded = input.every((r) => {
      const { resourceType, resourceId, frequency, start, end, unit, limits } =
        r;
      return selectResourceMicroIntervalStatistics(
        state,
        {
          resourceType,
          resourceId,
          frequency,
          start,
          end,
          unit,
          limits,
        },
        logProjectId,
      );
    });

    if (!allDataLoaded) return undefined;
    return input.map((r) => {
      const { resourceType, resourceId, frequency, start, end, unit, limits } =
        r;
      return selectResourceMicroIntervalStatistics(
        state,
        {
          resourceType,
          resourceId,
          frequency,
          start,
          end,
          unit,
          limits,
        },
        logProjectId,
      );
    });
  }) as MicroIntervalStatistics[] | undefined;
  const stableAirMicroIntervalStatistics = useStableReference(
    airMicroIntervalStatistics,
  );
  const isLoading = useSelector((state) =>
    getIsFetching(state, 'airEmissionsMicroIntervalStatistics'),
  ) as boolean;
  useEffect(() => {
    if (input) {
      dispatch(
        fetchEmissionMicroIntervalStatisticsAction(
          APP.projectId,
          input,
          logProjectId,
        ),
      );
    }
  }, [input, dispatch, logProjectId]);
  return {
    airEmissionMicroIntervalStatistics: stableAirMicroIntervalStatistics,
    isLoading,
  };
}
