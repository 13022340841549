import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourceTypeCache,
  ResourceTypeCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import {
  getCache as getPropertiesCache,
  invalidateCache as invalidatePropertiesCache,
  setCache as setPropertiesCache,
} from '@monorepo/shared/hooks/resources/useResourceProperties';
import {
  getCache,
  invalidateCache,
  setCache,
} from '@monorepo/shared/hooks/resources/useResourceType';
import {
  getCache as getListCache,
  setCache as setListCache,
} from '@monorepo/shared/hooks/resources/useResourceTypes';
import { ResourceTypeListResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type useResourceTypeDeleteParams = {
  config?: MutationConfig<Api.DeleteResourceTypeResponse, Api.ErrorResponse>;
};

export const useResourceTypeDelete = (params?: useResourceTypeDeleteParams) =>
  useMutation(Api.deleteResourceType, {
    ...params?.config,
    onMutate: ({ organizationId, resourceTypeId }) => {
      // Optimistically remove that single Resource Type and its properties
      const keyParams = { organizationId, resourceTypeId };
      const removedResourceType = getCache(keyParams);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidateCache(keyParams);
      const removedProperties = getPropertiesCache(keyParams);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      invalidatePropertiesCache(keyParams);

      // Optimistically remove from the list of Resource Types if there's cache
      const listKeyParams = { organizationId };
      const items = getListCache(listKeyParams);

      let removedListItem: ResourceTypeListResponse | undefined;
      if (items) {
        removedListItem = items.find((item) => item.id === resourceTypeId);
        const newItems = items.filter((item) => item.id !== resourceTypeId);
        setListCache(listKeyParams, newItems);
      }

      return () => {
        // Put Resource Type Properties back
        if (removedProperties) {
          setPropertiesCache(keyParams, removedProperties);
        }
        // Put Resource Type back
        if (removedResourceType) {
          setCache(keyParams, removedResourceType);
        }
        // Put the Resource Type back in the list if cache is still there
        if (removedListItem) {
          const currentItems = getListCache(listKeyParams);
          if (currentItems) {
            setListCache(listKeyParams, [...currentItems, removedListItem]);
          }
        }
      };
    },
    onSuccess: async (_, { organizationId, resourceTypeId }) =>
      // Invalidate any RQ cache that might use information about Resource Types
      invalidateResourceTypeCache(ResourceTypeCacheEvents.DELETE, {
        organizationId,
        resourceTypeId,
      }),
    onError: async (error, mutationFuncParams, rollback) => {
      if (params?.config?.onError) {
        return params.config.onError(error, mutationFuncParams, rollback);
      }
      return rollback();
    },
  });
