import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { bodyRegular, header2 } from '@monorepo/shared/styles/text';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Dialog = styled(MuiDialog)`
  box-shadow: 0 4px 13px ${({ theme }) => theme.colors.blue20};
`;

const DialogTitle = styled(MuiDialogTitle)`
  display: inline-flex;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayeee};

  & .MuiTypography-root {
    ${header2}
    color: ${({ theme }) => theme.colors.gray333};
  }
`;

const DialogTitleIcon = styled.span`
  display: inline-block;
  width: 2rem;
  margin-right: 0.5rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.blue08};
  border-radius: 5px;

  & svg {
    height: 0.8rem;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: auto;
  padding: 0;
`;

const DialogContent = styled(MuiDialogContent)`
  ${bodyRegular}
  min-height: 10rem;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayeee};
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 1rem;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

type BaseModalProps = Omit<DialogProps, 'onClose'> & {
  buttons: React.ReactNode;
  description?: React.ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
  title: string;
  icon?: React.ReactElement;
};

export function BaseModal({
  children,
  buttons,
  open,
  title,
  description,
  icon,
  onClose,
  showCloseButton,
  ...props
}: BaseModalProps) {
  const [idRandomify] = useState(uuidv4);
  const ariaLabelId = `form-dialog-title-${idRandomify}`;

  return (
    <Dialog
      aria-labelledby={ariaLabelId}
      fullWidth
      open={open}
      onClose={onClose}
      {...props}
    >
      <DialogTitle id={ariaLabelId}>
        {icon && <DialogTitleIcon aria-hidden>{icon}</DialogTitleIcon>}
        {title}
      </DialogTitle>
      {showCloseButton && (
        <CloseButton
          aria-label="close"
          onClick={onClose}
          title="Close dialog box"
        >
          <CloseIcon />
        </CloseButton>
      )}
      <DialogContent>
        {description}
        {children}
      </DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
}
