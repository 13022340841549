import { isBefore, parseISO, startOfToday } from 'date-fns';
import _get from 'lodash.get';
import { localEquivalentOfUTC } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  fetchCurrentUserWidgetSettingAction,
  setCurrentUserWidgetSettingAction,
} from '../../../../actions/currentUser';
import { fetchTasksAction } from '../../../../actions/task';
import { fetchUsersAction } from '../../../../actions/user';
import APP from '../../../../config';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import UserSettings from '../../../../types/UserSettings';
import { ProjectTaskType } from '../../../propTypes';
import withProvider from '../../../withProvider';
import { TasksCard } from './TasksCard';

class TasksCardContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: null,
    };
  }

  componentDidMount() {
    const { fetchUsers, fetchWidgetSetting, projectId } = this.props;
    fetchUsers(projectId);
    fetchWidgetSetting(projectId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      calendarUpdating,
      calendarServerError,
      isCompleting,
      isDeleting,
      isUpdating,
      taskServerError,
      usersLoading,
      widgetSettings,
      widgetSettingsLoading,
    } = this.props;
    const { filters } = this.state;
    const { currentUser } = this.context;

    if (!filters && !widgetSettingsLoading && !usersLoading && currentUser) {
      this.setState({ filters: this.hydrateFilters(widgetSettings) });
    }

    if (filters !== null && prevState.filters !== filters) {
      this.reload();
      return;
    }

    const taskDeleted =
      !isDeleting && isDeleting !== prevProps.isDeleting && !taskServerError;
    const taskUpdated =
      !isUpdating && isUpdating !== prevProps.isUpdating && !taskServerError;
    const taskCompleted =
      !isCompleting &&
      isCompleting !== prevProps.isCompleting &&
      !taskServerError;
    const calendarUpdated =
      !calendarUpdating &&
      calendarUpdating !== prevProps.calendarUpdating &&
      !calendarServerError;
    if (taskUpdated || taskDeleted || taskCompleted || calendarUpdated) {
      this.reload();
    }
  }

  handleFilterSettingsSave = (newFilters) => {
    const { projectId, saveWidgetSettings } = this.props;
    this.setState({ filters: newFilters });
    saveWidgetSettings(projectId, this.dehydrateFilters(newFilters));
  };

  reload = () => {
    const { fetchTasks, projectId } = this.props;
    const { filters } = this.state;
    fetchTasks(projectId, this.dehydrateFilters(filters));
  };

  hydrateFilters(widgetSettings) {
    const { assignableUsers } = this.props;
    const { currentUser } = this.context;
    const hydratedFilters = { ...widgetSettings };
    // If a user has never set filters for Tasks widget,
    // it will be automatically filtered by current user.
    // This approach was chosen instead of DB migration because
    // it works for both existing users and new ones.
    if (widgetSettings === null && currentUser) {
      hydratedFilters.assignees = [
        {
          name: currentUser.name,
          userId: currentUser.id,
        },
      ];
    }
    if (widgetSettings?.assignees?.length) {
      hydratedFilters.assignees = assignableUsers.filter((user) =>
        widgetSettings.assignees.includes(user.userId),
      );
    }
    return hydratedFilters;
  }

  dehydrateFilters(filters) {
    const dehydratedFilters = { ...filters };
    if (dehydratedFilters.assignees) {
      dehydratedFilters.assignees = dehydratedFilters.assignees.map(
        (user) => user.userId,
      );
    }
    return dehydratedFilters;
  }

  isTaskOverdue(task) {
    // User can save incomplete task in Form inspection.
    // Show tasks without due date in Overdue section.
    if (!task.dueDate) return true;
    // BE server doesn't know local timezone, it sorts tasks into
    // currentTasks and futureTasks buckets based by UTC timestamp.
    // It means that currentTasks might include not only overdue and today's tasks,
    // but some future tasks as well when it's the end of the day, e.g. after 5PM PST.
    // That's why we are checking whether a task is overdue,
    // not whether it's due today.
    return isBefore(
      localEquivalentOfUTC(parseISO(task.dueDate)),
      startOfToday(),
    );
  }

  separateOverdueTasks(tasks) {
    const sortedTasks = {
      completedTasks: tasks.completedTasks,
      currentTasks: tasks.currentTasks?.filter(
        (task) => !this.isTaskOverdue(task),
      ),
      filteredTasks: tasks.filteredTasks,
      futureTasks: tasks.futureTasks,
      overdueTasks: tasks.currentTasks?.filter(this.isTaskOverdue).reverse(),
    };
    return sortedTasks;
  }

  isLoading() {
    const { isLoading, usersLoading, widgetSettingsLoading } = this.props;
    return isLoading || usersLoading || widgetSettingsLoading;
  }

  render() {
    const { tasks, projectId } = this.props;
    const { filters } = this.state;

    return (
      <TasksCard
        filters={filters || {}}
        isLoading={this.isLoading()}
        onFiltersChange={this.handleFilterSettingsSave}
        projectId={projectId}
        tasks={this.separateOverdueTasks(tasks)}
        reloadTaskList={this.reload}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { calendar, currentUser, task, user } = state;
  const { projectId } = APP;
  const creatingAssignees = _get(calendar.isFetching, 'createAssignees', false);
  const removingAssignees = _get(calendar.isFetching, 'removeAssignees', false);
  const updatingFrequency = _get(calendar.isFetching, 'removeAssignees', false);

  return {
    assignableUsers: user.forProject === projectId ? user.users : [],
    calendarUpdating:
      creatingAssignees || removingAssignees || updatingFrequency,
    calendarServerError: calendar.errorMessage,
    isCompleting: task.isFetching.completingTask,
    isDeleting: task.isFetching.deletingTask,
    isLoading: task.isFetching.projectTasks,
    isUpdating: task.isFetching.updatingTask,
    projectId,
    tasks: task.tasks,
    taskServerError: task.errorMessage,
    usersLoading: user.isFetching.users,
    widgetSettings: _get(
      currentUser.userSettings,
      `${projectId}.${UserSettings.WIDGET_FILTERS}.${UserSettings.TASKS_CARD}`,
      null,
    ),
    widgetSettingsLoading: _get(
      currentUser.isFetching,
      `userSettings-${UserSettings.WIDGET_FILTERS}-${UserSettings.TASKS_CARD}`,
      false,
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTasks: (projectId, filters) =>
    dispatch(fetchTasksAction(projectId, filters)),
  fetchUsers: (projectId) => dispatch(fetchUsersAction(projectId)),
  fetchWidgetSetting: (projectId) => {
    dispatch(
      fetchCurrentUserWidgetSettingAction(
        projectId,
        UserSettings.WIDGET_FILTERS,
        UserSettings.TASKS_CARD,
      ),
    );
  },
  saveWidgetSettings: (projectId, widgetSettings) => {
    dispatch(
      setCurrentUserWidgetSettingAction(projectId, {
        settingKey: UserSettings.WIDGET_FILTERS,
        settingValue: {
          type: UserSettings.TASKS_CARD,
          widgetSettings,
        },
      }),
    );
  },
});

TasksCardContainer.contextType = CurrentUserContext;

TasksCardContainer.propTypes = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  assignableUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  calendarUpdating: PropTypes.bool.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  calendarServerError: PropTypes.object,
  fetchTasks: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchWidgetSetting: PropTypes.func.isRequired,
  isCompleting: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  saveWidgetSettings: PropTypes.func.isRequired,
  tasks: PropTypes.shape({
    completedTasks: PropTypes.arrayOf(ProjectTaskType),
    currentTasks: PropTypes.arrayOf(ProjectTaskType),
    filteredTasks: PropTypes.arrayOf(ProjectTaskType),
    futureTasks: PropTypes.arrayOf(ProjectTaskType),
  }).isRequired,
  taskServerError: PropTypes.shape({ message: PropTypes.string }),
  usersLoading: PropTypes.bool.isRequired,
  widgetSettings: PropTypes.shape({
    assignees: PropTypes.arrayOf(PropTypes.string),
    interval: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  widgetSettingsLoading: PropTypes.bool.isRequired,
};

TasksCardContainer.defaultProps = {
  calendarServerError: null,
  taskServerError: null,
  widgetSettings: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(TasksCardContainer),
);
