import { Api } from '@monorepo/shared/apiClient';
import {
  LogCacheEvents,
  logCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import {
  LogFrequencyRequirementCacheEvents,
  logFrequencyRequirementCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFrequencyRequirementCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchLogsWithSiteConfigParams;

const KEY_START = 'logsWithSiteConfig';
export const createQueryKey = ({ organizationId, projectId }: KeyParams) =>
  [KEY_START, organizationId, projectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogsWithSiteConfig
>;

type UseLogsWithSiteConfigParams =
  Partial<Api.FetchLogsWithSiteConfigParams> & {
    config?: QueryConfig<
      Api.FetchLogsWithSiteConfigResponse,
      Api.ErrorResponse
    >;
  };

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  projectId: string,
) => Api.fetchLogsWithSiteConfig({ organizationId, projectId });

export const useLogsWithSiteConfig = ({
  config: inputConfig,
  organizationId,
  projectId,
}: UseLogsWithSiteConfigParams) => {
  const isEnabled = !!organizationId && !!projectId;
  const config = getQueryConfig<Api.FetchLogsWithSiteConfigResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({ organizationId, projectId })
    : undefined;
  const { data: logsWithSiteConfig, ...queryInfo } = useQuery(
    key,
    fetcher,
    config,
  );
  return {
    logsWithSiteConfig,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<
  typeof useLogsWithSiteConfig
>['logsWithSiteConfig'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<KeyParams, 'projectId'> & {
  projectId?: string;
};
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries([
    KEY_START,
    keyParams.organizationId,
    ...(keyParams.projectId ? [keyParams.projectId] : []),
  ]);
}

const cacheRegisterInvalidator = {
  hookName: 'useLogsWithSiteConfig',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logCacheRegister(
  [LogCacheEvents.CREATE, LogCacheEvents.UPDATE, LogCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

logFrequencyRequirementCacheRegister(
  [
    LogFrequencyRequirementCacheEvents.CREATE,
    LogFrequencyRequirementCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);
