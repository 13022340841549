import { A, ButtonLink } from '@monorepo/shared/componentsV2/Link';
import { contentPadding, page } from '@monorepo/shared/styles/layout';
import React from 'react';
import styled from 'styled-components';
import { header1 } from '../../styles/text';
import { DocumentTitle } from '../DocumentTitle';
import { Main } from '../Main';

const Page = styled(Main)`
  ${page}
  ${contentPadding}
  margin: auto;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  & > * {
    flex-basis: 50%; /* evenly sized columns */
    flex-grow: 1;
    padding: 2rem;
  }
`;

const Header = styled.h1`
  ${header1}
  font-size: 4rem;
`;

const Image = styled.img`
  max-width: 45vw;
  max-height: 100vh;
  padding: 5vw;
`;

const Bold = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2rem;
`;

const ButtonContainer = styled.div`
  padding-top: 1rem;
`;

export const Error404Page = () => (
  <Page>
    <DocumentTitle title="404" />
    <Columns>
      <Image alt="" src={`${process.env.ASSETS_ROOT}404_spill.svg`} />
      <div>
        <Header>404</Header>
        <Bold>Oh no! Looks like you&apos;ve gone down the wrong drain.</Bold>
        <p>
          If you think you&apos;ve reached this page in error, let us know. You
          can email us at
          <A href="mailto:help@mapistry.com"> help@mapistry.com</A>.
        </p>
        <ButtonContainer>
          <ButtonLink variant="contained" to="/vortex">
            Go back to home
          </ButtonLink>
        </ButtonContainer>
      </div>
    </Columns>
  </Page>
);
