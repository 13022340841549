import React from 'react';

interface ConditionalWrapperProps {
  children: React.ReactNode;
  condition: boolean;
  wrapper(children: React.ReactNode): JSX.Element;
}

export function ConditionalWrapper({
  children,
  condition,
  wrapper,
}: ConditionalWrapperProps) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition ? wrapper(children) : <>{children}</>;
}
