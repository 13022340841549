import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { ResourceResponse } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useResourceDelete } from '../../hooks/useResourceDelete';

type ResourceActionMenuProps = {
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  onEditResource: (string) => void;
  resource: ResourceResponse;
};

export function ViewResourcesActionMenu({
  onEditResource,
  resource,
}: ResourceActionMenuProps) {
  const { confirm } = useModal();
  const { showUserFriendlyErrorToast, success } = useToast();
  const { organizationId, resourceTypeId } = resource;

  const [deleter] = useResourceDelete({ config: { throwOnError: true } });

  // function passed to confirm to be executed when the confirm button is clicked
  const handleDeleteConfirm = useCallback(
    async (isConfirmed) => {
      if (isConfirmed) {
        await deleter({
          resourceId: resource.id,
          resourceTypeId,
          organizationId,
        });
      }
    },
    [deleter, organizationId, resource, resourceTypeId],
  );

  const handleClickDelete = useCallback(async () => {
    try {
      const isConfirmed = await confirm({
        title: 'Delete Resource',
        description: (
          <>
            Are you sure you want to delete the <b>{resource.name}</b> Resource?
          </>
        ),
        cancelButtonText: 'Keep Resource',
        confirmButtonText: 'Delete Resource',
        afterConfirmButtonText: 'Deleting...',
        danger: true,
        onConfirmAsync: handleDeleteConfirm,
      });
      if (isConfirmed) {
        success(`${resource.name} has been deleted.`);
      }
    } catch (err) {
      showUserFriendlyErrorToast(err, `Unable to delete ${resource.name}.`);
    }
  }, [
    confirm,
    resource,
    handleDeleteConfirm,
    success,
    showUserFriendlyErrorToast,
  ]);

  return (
    <ActionMenu
      altText={`Action menu for ${resource.name} Resource`}
      options={[
        {
          displayName: 'Edit',
          onClick: () => {
            onEditResource(resource.id);
          },
        },
        {
          displayName: 'Delete',
          onClick: handleClickDelete,
        },
      ]}
    />
  );
}
