import { Api } from '@monorepo/shared/apiClient';
import { useErrorHandler as useRebErrorHandler } from 'react-error-boundary';

type useErrorHandlerParams = Api.ErrorResponse | Error | null;

const getError = (error?: useErrorHandlerParams) => {
  if (!error) {
    return null;
  }

  return error instanceof Error
    ? error
    : new Error(
        `Something has gone wrong. Please try again later. - ${error.data}`,
      );
};

export function useErrorHandler(error?: useErrorHandlerParams) {
  const handleRebError = useRebErrorHandler(getError(error));
  return (err?: useErrorHandlerParams) => handleRebError(getError(err));
}
