import {
  BooleanValue,
  DatetimeValue,
  DateValue,
  FieldDataType,
  FormulaValue,
  LocationValue,
  NumericValue,
  ParameterValue,
  ResourceValue,
  SignatureValue,
  SingleSelectValue,
  SpecificFieldValue,
  SubstanceValue,
  TextValue,
  TimeValue,
  UserValue,
} from 'mapistry-shared';

interface FieldValueFactoryParams<
  T extends FieldDataType,
  V extends SpecificFieldValue,
> {
  fieldType: T;
  fieldValue: V;
}

export function fieldValueFactory<
  T extends FieldDataType.BOOLEAN,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): BooleanValue | null;
export function fieldValueFactory<
  T extends FieldDataType.DATE,
  V extends SpecificFieldValue,
>({ fieldType, fieldValue }: FieldValueFactoryParams<T, V>): DateValue | null;
export function fieldValueFactory<
  T extends FieldDataType.DATETIME,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): DatetimeValue | null;
export function fieldValueFactory<
  T extends FieldDataType.FORMULA,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): FormulaValue | null;
export function fieldValueFactory<
  T extends FieldDataType.LOCATION,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): LocationValue | null;
export function fieldValueFactory<
  T extends FieldDataType.NUMERIC,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): NumericValue | null;
export function fieldValueFactory<
  T extends FieldDataType.PARAMETER,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): ParameterValue | null;
export function fieldValueFactory<
  T extends FieldDataType.RESOURCE,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): ResourceValue | null;
export function fieldValueFactory<
  T extends FieldDataType.SIGNATURE,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): SignatureValue | null;
export function fieldValueFactory<
  T extends FieldDataType.SINGLE_SELECT,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): SingleSelectValue | null;
export function fieldValueFactory<
  T extends FieldDataType.SUBSTANCE,
  V extends SpecificFieldValue,
>({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<T, V>): SubstanceValue | null;
export function fieldValueFactory<
  T extends FieldDataType.TEXT,
  V extends SpecificFieldValue,
>({ fieldType, fieldValue }: FieldValueFactoryParams<T, V>): TextValue | null;
export function fieldValueFactory<
  T extends FieldDataType.TIME,
  V extends SpecificFieldValue,
>({ fieldType, fieldValue }: FieldValueFactoryParams<T, V>): TimeValue | null;
export function fieldValueFactory<
  T extends FieldDataType.USER,
  V extends SpecificFieldValue,
>({ fieldType, fieldValue }: FieldValueFactoryParams<T, V>): UserValue | null;
export function fieldValueFactory({
  fieldType,
  fieldValue,
}: FieldValueFactoryParams<FieldDataType, SpecificFieldValue>) {
  const defaultResult = null;
  if (fieldValue.value == null) {
    return null;
  }
  switch (fieldType) {
    case FieldDataType.BOOLEAN:
      return fieldValue as BooleanValue;
    case FieldDataType.DATE:
      return fieldValue as DateValue;
    case FieldDataType.DATETIME:
      return fieldValue as DatetimeValue;
    case FieldDataType.FORMULA:
      return fieldValue as FormulaValue;
    case FieldDataType.LOCATION:
      return fieldValue as LocationValue;
    case FieldDataType.NUMERIC:
      return fieldValue as NumericValue;
    case FieldDataType.PARAMETER:
      return fieldValue as ParameterValue;
    case FieldDataType.RESOURCE:
      return fieldValue as ResourceValue;
    case FieldDataType.SIGNATURE:
      return fieldValue as SignatureValue;
    case FieldDataType.SINGLE_SELECT:
      return fieldValue as SingleSelectValue;
    case FieldDataType.SUBSTANCE:
      return fieldValue as SubstanceValue;
    case FieldDataType.TEXT:
      return fieldValue as TextValue;
    case FieldDataType.TIME:
      return fieldValue as TimeValue;
    case FieldDataType.USER:
      return fieldValue as UserValue;
    default:
      return defaultResult;
  }
}
