import { formatNumber } from '@monorepo/shared/utils/formatNumber';
import { MathUnit, NullableMathUnitName } from 'mapistry-shared';

export function formatUnitAwareNumber(
  value: number,
  unit?: NullableMathUnitName,
): string {
  const formatted = formatNumber(value);
  if (!unit) {
    return formatted;
  }
  if (MathUnit.isCurrency(unit)) {
    const twoDecimalPlaces = formatNumber(value, { fixedFractionDigits: 2 });
    return `${MathUnit.getLabel(unit)}${twoDecimalPlaces}`;
  }
  return `${formatted} ${MathUnit.getLabel(unit)}`;
}
