import { socket } from '../webSocket';

/** this seems to log every incoming message _twice_, but it's still useful for debugging. */
export function useLogAllMessagesHandler({ enableDebuggingLogs = false }) {
  if (!enableDebuggingLogs) {
    return;
  }

  socket.onAny((...args) =>
    console.log('Received push notification:', ...args),
  );
}
