import { Api } from '@monorepo/shared/apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFrequencyRequirementCacheEvents,
  logFrequencyRequirementCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFrequencyRequirementCacheRegister';
import {
  ViewCacheEvents,
  viewCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import { QueryConfig, queryCache, useQuery } from 'react-query';

type KeyParams = Api.FetchLogSiteSummaryParams;
const KEY_START = 'logSiteSummary';
const createQueryKey = ({ logId, organizationId }: KeyParams) =>
  [KEY_START, organizationId, logId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogSiteSummary
>;

type UseLogSiteSummaryParams = Partial<Api.FetchLogSiteSummaryParams> & {
  config?: QueryConfig<Api.FetchLogSiteSummaryResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (_: string, organizationId: string, logId: string) =>
  Api.fetchLogSiteSummary({ logId, organizationId });

export const useLogSiteSummary = ({
  config: inputConfig = { useErrorBoundary: true },
  logId,
  organizationId,
}: UseLogSiteSummaryParams) => {
  const isEnabled = !!organizationId && !!logId;
  const config = {
    ...inputConfig,
    enabled:
      inputConfig?.enabled != null
        ? inputConfig.enabled && isEnabled
        : isEnabled,
  };
  const key = isEnabled ? createQueryKey({ logId, organizationId }) : undefined;
  const { data: summary, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    summary,
    ...queryInfo,
  };
};

// allows invalidating summary for all logs in an org
type InvalidateCacheParams = Omit<KeyParams, 'logId'> & {
  logId?: string;
};
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries([
    KEY_START,
    keyParams.organizationId,
    ...(keyParams.logId ? [keyParams.logId] : []),
  ]);
}

const cacheRegisterInvalidator = {
  hookName: 'useLogSiteSummary',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

// Currently we can't invalidate cache when project name changes
// because Site Details live in its own iFrame, so invalidating cache
// from there won't affect RQ cache in main vortex app.

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logFrequencyRequirementCacheRegister(
  [
    LogFrequencyRequirementCacheEvents.CREATE,
    LogFrequencyRequirementCacheEvents.UPDATE,
    LogFrequencyRequirementCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

viewCacheRegister(
  [ViewCacheEvents.CREATE, ViewCacheEvents.UPDATE, ViewCacheEvents.DELETE],
  cacheRegisterInvalidator,
);
