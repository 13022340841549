import { Loading } from '@monorepo/shared/components/Loading';
import { GenericError } from '@monorepo/shared/componentsV2/errorDisplays/GenericError';
import { fieldValueDisplayFactory } from '@monorepo/shared/componentsV2/fieldDataType/values/fieldValueDisplayFactory';
import {
  Pagination,
  PaginationOnPageChange,
} from '@monorepo/shared/componentsV2/Pagination';
import { useSkipWidgetLink } from '@monorepo/shared/componentsV2/SkipWidgetLink';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { helpDeskButtonDiameter } from '@monorepo/shared/constants/helpDesk';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useResources } from '@monorepo/shared/hooks/resources/useResources';
import { useResourceTypeWithProperties } from '@monorepo/shared/hooks/resources/useResourceTypeWithProperties';
import { useDebouncedValue } from '@monorepo/shared/hooks/useDebouncedValue';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { format } from 'date-fns';
import {
  FieldResponse,
  ResourceResponse,
  ResourceTypeRefResponse,
  SortDirection,
} from 'mapistry-shared';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { ViewResourcesActionMenu } from './ViewResourcesActionMenu';

const StyledContainer = styled.div`
  ${verticalLayout}
`;

const StyledTable = styled(Table)`
  ${fillHeightAndScrollable}
` as typeof Table;

const StyledPagination = styled(Pagination)`
  flex-shrink: 0;

  /* Since the pagination control is pinned to the bottom of this 100% height component, we
   know it will run into the pendo icon. So we give it extra padding. */
  margin-right: ${helpDeskButtonDiameter};
`;

function getTableColumns(
  resourceType: ResourceTypeRefResponse,
  resourceProperties: FieldResponse[],
  onEditResource: ViewResourcesTableProps['onEditResource'],
  permissions: { canEditOrgSettings: boolean },
  nameColumnContents?: ViewResourcesTableProps['nameColumnContents'],
): TableColumn<ResourceResponse>[] {
  const nameCol: TableColumn<ResourceResponse> = {
    header: 'Name',
    contents: nameColumnContents || 'name',
    id: 'name',
  };

  const siteAssocCol: TableColumn<ResourceResponse> = {
    header: 'Site Association',
    contents: (row) => row.project?.name || '',
    id: 'siteAssociation',
  };

  const createdAtCol: TableColumn<ResourceResponse> = {
    header: 'Created',
    contents: (row) => format(new Date(row.createdAt), 'MM/dd/yy HH:mm'),
    id: 'createdAt',
  };

  const actionMenuCol: TableColumn<ResourceResponse> = {
    header: 'Actions',
    contents: (row: ResourceResponse) => (
      <ViewResourcesActionMenu resource={row} onEditResource={onEditResource} />
    ),
    id: 'actionMenu',
    width: '10%',
    align: 'center',
  };

  const resourcePropertyCols = (resourceProperties || []).map((prop) => ({
    header: prop.name,
    contents: ({ propertyValues }: ResourceResponse) => {
      const fieldValue = propertyValues[prop.id]?.fieldValue;
      return fieldValue
        ? fieldValueDisplayFactory({
            fieldType: prop.type,
            fieldValue,
          })
        : '';
    },

    id: prop.id,
  }));

  return [
    nameCol,
    ...(resourceType?.isSiteSpecific ? [siteAssocCol] : []),
    ...resourcePropertyCols,
    createdAtCol,
    ...(permissions.canEditOrgSettings ? [actionMenuCol] : []),
  ];
}

type ViewResourcesTableProps = {
  filterBy?: string;
  onEditResource: (resourceId: string) => void;
  onPageChange: PaginationOnPageChange;
  // by default, the resource name column will simply print the name of the resource
  // this prop lets you override that behavior
  nameColumnContents?: TableColumn<ResourceResponse>['contents'];
  pageNumber: number;
};

export function ViewResourcesTable({
  filterBy = '',
  onEditResource,
  onPageChange,
  nameColumnContents,
  pageNumber,
}: ViewResourcesTableProps) {
  const { resourceTypeId, organizationId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  // Debounce updates to name filter, so we send server request only after
  // a user pauses typing for at least half a second
  const nameFilter = useDebouncedValue(filterBy);
  useEffect(() => {
    // Reset page number when filter changes
    const firstPage = 0;
    onPageChange(firstPage);

    // Not including `onPageChange` in useEffect's dependencies, because we need to invoke this effect only
    // when filter updates. Every time we paginate we navigate to new url, `navigate` object updates,
    // which updates `onPageChange` and would've invoked this hook too.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const {
    resources,
    pagination,
    isLoading: isLoadingResources,
    isFetching: isFetchingResources,
  } = useResources({
    nameFilter,
    organizationId,
    resourceTypeId,
    requestedPage: pageNumber,
    sortDirection: SortDirection.ASC,
  });
  const {
    resourceType,
    resourceProperties,
    isLoading: isLoadingResourceType,
  } = useResourceTypeWithProperties({
    organizationId,
    resourceTypeId,
  });

  const tableColumns = useMemo(
    () =>
      resourceType && resourceProperties
        ? getTableColumns(
            resourceType,
            resourceProperties,
            onEditResource,
            { canEditOrgSettings },
            nameColumnContents,
          )
        : [],
    [
      canEditOrgSettings,
      resourceType,
      resourceProperties,
      onEditResource,
      nameColumnContents,
    ],
  );

  const { SkipLink, SkipLinkTarget } = useSkipWidgetLink();

  if (isLoadingResources || isFetchingResources || isLoadingResourceType) {
    return <Loading />;
  }

  if (!resources || !resourceProperties || !resourceType) {
    return <GenericError />;
  }

  return (
    <StyledContainer>
      <SkipLink linkText="Skip to pagination" />
      <StyledTable
        ariaLabel="Resources"
        columns={tableColumns}
        rows={resources}
      />
      <SkipLinkTarget />
      <StyledPagination
        onPageChange={onPageChange}
        page={pagination?.currentPage || 0}
        rowsPerPage={pagination?.perPage}
        totalRowCount={pagination?.fullCount}
      />
    </StyledContainer>
  );
}
