import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { LogEntryResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '../../cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '../../cacheRegisters/data2/logFieldCacheRegister';

type KeyParams = Api.FetchLogEntryParams;

function getKeyPartsInOrder(
  keyParams: KeyParams,
): [string, string, string, string, string];
function getKeyPartsInOrder(
  keyParams: Partial<KeyParams>,
): [
  string,
  string | undefined,
  string | undefined,
  string | undefined,
  string | undefined,
];
function getKeyPartsInOrder(
  keyParams: KeyParams | Partial<KeyParams>,
):
  | [string, string, string, string, string]
  | [
      string,
      string | undefined,
      string | undefined,
      string | undefined,
      string | undefined,
    ] {
  const { organizationId, logId, logEntryId, projectId } = keyParams;
  return ['logEntry', organizationId, logId, projectId, logEntryId];
}

export const createKey = (params: KeyParams) =>
  [...getKeyPartsInOrder(params)] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchLogEntry>;

const fetcher: Fetcher = (_, organizationId, logId, projectId, logEntryId) =>
  Api.fetchLogEntry({ organizationId, logId, projectId, logEntryId });

type UseLogEntryParams = Partial<Api.FetchLogEntryParams> & {
  config?: QueryConfig<LogEntryResponse, Api.ErrorResponse>;
};

export function useLogEntry({
  config: inputConfig,
  organizationId,
  logId,
  logEntryId,
  projectId,
}: UseLogEntryParams) {
  const isEnabled = !!organizationId && !!logId && !!logEntryId && !!projectId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createKey({ organizationId, logId, logEntryId, projectId })
    : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    logEntry: data,
  };
}

type QueryCache = ReturnType<typeof useLogEntry>['logEntry'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

// allows invalidating:
// - a single entry
// - all entries at a site
// - all entries across sites for a particular log
// - all entries for every log across sites in an org (when a resource's values change)
type InvalidateCacheParams = Omit<
  KeyParams,
  'logEntryId' | 'logId' | 'projectId'
> & {
  logId?: string;
  projectId?: string;
  logEntryId?: string;
};
export async function invalidateCache(
  invalidateCacheParams: InvalidateCacheParams,
): Promise<void> {
  const keyParts = getKeyPartsInOrder(invalidateCacheParams);
  await queryCache.invalidateQueries(getKeyParamsForInvalidation(keyParts));
}

const cacheRegisterInvalidator = {
  hookName: 'useLogEntry',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logEntryCacheRegister([LogEntryCacheEvents.DELETE], cacheRegisterInvalidator);

// Log entry completion status might have changed
logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

// Formula field value could be affected if it uses a property value on a resource that has changed
resourceCacheRegister(
  [ResourceCacheEvents.UPDATE, ResourceCacheEvents.DELETE],
  cacheRegisterInvalidator,
);
