import React from 'react';
import { AlertModal } from '@monorepo/shared/componentsV2/modals/AlertModal';

type ModalProps = {
  open: boolean;
  onDone: () => void;
};

export const CannotEditFormTemplateModal = (props: ModalProps) => {
  const { open, onDone } = props;
  return (
    <AlertModal
      title="Cannot Configure Form"
      description="This form template is not editable because multiple companies use it. Please get in touch with your customer support manager to create a copy specific to your organization."
      open={open}
      onDone={onDone}
    />
  );
};
