import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Select } from '../Select';

type SelectFieldProps<ValueType> = Omit<
  React.ComponentProps<typeof Select>,
  'value' | 'onChange' | 'error' | 'errorText'
> & {
  initialValue?: ValueType;
  validate?: UseFieldConfig<ValueType>['validate'];
};

export const SelectField = <ValueType extends string = string>({
  name,
  validate,
  initialValue,
  ...rest
}: SelectFieldProps<ValueType>) => {
  const { input, meta } = useField<ValueType>(name, { validate, initialValue });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <Select
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      name={name}
      onChange={input.onChange}
      value={input.value}
    />
  );
};
