import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { FieldWrapper } from '../elements';
import GenericLogsResourceSelect from './GenericLogsResourceSelect';
import getFrequencyFromResource from '../../utils/genericLogs/getFrequencyFromResource';
import {
  ChartableGenericLogsResource,
  ResourceKey,
  Frequency,
} from '../../types/ts-types';

type AirEmissionResourceFieldProps = {
  allResources: ChartableGenericLogsResource[];
  isPrimaryField?: boolean;
  label: string;
  name: string;
  onForceFrequency: (frequency: Frequency) => void;
  onReinitializeField: (index: number) => void;
  primaryFieldResource: ResourceKey;
  resourceUpdater: (selectedResourceKey: ResourceKey) => ResourceKey;
  selectedResources: ResourceKey[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GenericLogsResourceField(props: AirEmissionResourceFieldProps) {
  const {
    name,
    label,
    allResources,
    selectedResources,
    isPrimaryField,
    primaryFieldResource,
    resourceUpdater,
    onReinitializeField,
    onForceFrequency,
  } = props;
  const filterByFrequency =
    !isPrimaryField && primaryFieldResource.resourceId
      ? getFrequencyFromResource(
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          allResources.find(
            (r) => r.resourceId === primaryFieldResource.resourceId,
          )!,
        )
      : undefined;
  const handleChange = useCallback(
    (selectedResourceKey, updater) => {
      // if frequencies between primary resource and any other resource don't match
      // then reset that resource
      if (isPrimaryField) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const primaryResource = allResources.find(
          (aer) => aer.resourceId === selectedResourceKey.resourceId,
        )!;
        const frequencyOfPrimaryResource =
          getFrequencyFromResource(primaryResource);
        selectedResources.forEach((resourceKey, index) => {
          // skip fields that don't have a selected value
          if (!resourceKey.resourceId) return;
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const thisResource = allResources.find(
            (aer) => aer.resourceId === resourceKey.resourceId,
          )!;
          const frequencyOfThisResource =
            getFrequencyFromResource(thisResource);
          if (frequencyOfThisResource !== frequencyOfPrimaryResource) {
            onReinitializeField(index);
          }
        });
      }
      // if selected resource is a rolling calculation then force frequency to calculation frequency
      if (selectedResourceKey.resourceType === 'rollingCalculation') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const selectedResource = allResources.find(
          (aer) => aer.resourceId === selectedResourceKey.resourceId,
        )!;
        onForceFrequency(getFrequencyFromResource(selectedResource));
      }
      const selectedResource = resourceUpdater(selectedResourceKey);
      updater(selectedResource);
    },
    [
      allResources,
      selectedResources,
      isPrimaryField,
      resourceUpdater,
      onReinitializeField,
      onForceFrequency,
    ],
  );
  return (
    <Field name={name}>
      {(field) => (
        <div className="field-wrapper">
          <FieldWrapper label={label}>
            <GenericLogsResourceSelect
              resources={allResources}
              filterByFrequency={filterByFrequency}
              error={Boolean(field.meta.touched && field.meta.error)}
              onChange={(selectedResourceKey) =>
                handleChange(selectedResourceKey, field.input.onChange)
              }
              value={field.input.value}
            />
          </FieldWrapper>
        </div>
      )}
    </Field>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsResourceField;
