import { isRequired as isRequiredValidator } from '@monorepo/shared/utils/validators';
import * as Sentry from '@sentry/browser';
import { FieldResponse } from 'mapistry-shared';

export const getValidateFunction = (isRequired: boolean) =>
  isRequired ? isRequiredValidator : undefined;

// initialValue prop on fields prefer undefined over null
export function nullToUndefined<T>(v: T): NonNullable<T> | undefined {
  if (v == null) {
    return undefined;
  }
  // TS doesn't figure this out, so cast
  return v as NonNullable<T>;
}

export function getUnexpectedTypeErrorAndLogToSentry(
  field: FieldResponse,
  value: unknown,
) {
  const err = new Error(
    `Unexpected value provided for ${field.name} (${value})`,
  );
  Sentry.captureException(err, { extra: { field, value } });
  return err;
}
