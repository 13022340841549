import { FieldDataTypeWizardModal } from '@monorepo/shared/componentsV2/fieldDataType/wizard/FieldDataTypeWizardModal';
import * as Sentry from '@sentry/browser';
import {
  CreateFieldRequest,
  FieldResponse,
  NonEmptyArray,
  ResourcePropertyType,
  UpdateFieldRequest,
} from 'mapistry-shared';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useResourcePropertyCreate } from '../../hooks/useResourcePropertyCreate';
import { useResourcePropertyUpdate } from '../../hooks/useResourcePropertyUpdate';

const availableFieldTypes: NonEmptyArray<ResourcePropertyType> = [
  ResourcePropertyType.TEXT,
  ResourcePropertyType.SINGLE_SELECT,
  ResourcePropertyType.NUMERIC,
  ResourcePropertyType.DATETIME,
  ResourcePropertyType.BOOLEAN,
  // todo@areResourceFieldsOnResourceTypesEnabled take the feature flag below out and here add ResourcePropertyType.RESOURCE
];

interface EditResourcePropertyModalProps {
  property?: FieldResponse;
  onClose: () => void;
  open: boolean;
}

const isUpdateRequest = (
  x: CreateFieldRequest | UpdateFieldRequest,
): x is UpdateFieldRequest => 'id' in x && x.id != null;

const isCreateRequest = (
  x: CreateFieldRequest | UpdateFieldRequest,
): x is CreateFieldRequest => !isUpdateRequest(x);

export function EditResourcePropertyModal({
  property,
  onClose,
  open,
}: EditResourcePropertyModalProps) {
  const { organizationId, resourceTypeId } = useParams();
  const { areResourceFieldsOnResourceTypesEnabled } = useFeatureFlags();

  const [creator] = useResourcePropertyCreate({
    config: { throwOnError: true },
  });
  const [updater] = useResourcePropertyUpdate({
    config: { throwOnError: true },
  });
  const onSubmit = useCallback(
    async (requestDto: CreateFieldRequest | UpdateFieldRequest) => {
      if (!organizationId || !resourceTypeId) {
        const errMsg = `Couldn't save Resource property: EditResourcePropertyModal doesn't have organization or a resourceTypeId`;
        Sentry.captureException(errMsg);
        throw new Error(errMsg);
      }

      if (isUpdateRequest(requestDto)) {
        if (!property) {
          const errMsg = `Couldn't update Resource property: EditResourcePropertyModal doesn't have "property" prop`;
          Sentry.captureException(errMsg, {
            extra: {
              valuesId: requestDto.id,
            },
          });
          throw new Error(errMsg);
        }
        await updater({
          organizationId,
          resourceTypeId,
          resourcePropertyId: property.id,
          values: requestDto,
        });
        return;
      }

      if (isCreateRequest(requestDto)) {
        await creator({
          organizationId,
          resourceTypeId,
          property: requestDto,
        });
      }
    },
    [creator, organizationId, property, resourceTypeId, updater],
  );

  // todo@areResourceFieldsOnResourceTypesEnabled take the feature flag out and just add the resource type above
  const availableFieldTypesBasedOnFeatureFlag = useMemo<
    NonEmptyArray<ResourcePropertyType>
  >(
    () =>
      areResourceFieldsOnResourceTypesEnabled
        ? [...availableFieldTypes, ResourcePropertyType.RESOURCE]
        : availableFieldTypes,
    [areResourceFieldsOnResourceTypesEnabled],
  );

  if (!open) {
    return null;
  }

  return (
    <FieldDataTypeWizardModal
      availableFieldTypes={availableFieldTypesBasedOnFeatureFlag}
      field={property}
      fieldTypeName="property"
      isRequiredHelpText="This property will be required for every Resource of this type."
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
