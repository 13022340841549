import { useAppContext } from '@monorepo/vortex/src/contexts/AuthContext';
import { AnalyticsRoles } from 'mapistry-shared';

export function useAnalyticsRole() {
  const { currentUser } = useAppContext();
  const { analytics_role: analyticsRole } = currentUser;
  return analyticsRole;
}

export function useHasAnalyticsAccess() {
  const analyticsRole = useAnalyticsRole();
  const hasAnalyticsAccess =
    analyticsRole && analyticsRole !== AnalyticsRoles.NONE;

  return hasAnalyticsAccess;
}
