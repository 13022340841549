import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import * as Sentry from '@sentry/browser';
import { LogRefResponse } from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useLogDelete } from '../../hooks/useLogDelete';
import { useLogUpdate } from '../../hooks/useLogUpdate';

const Bold = styled.span`
  font-weight: 700;
`;

interface ViewLogsTableActionsProps {
  log: LogRefResponse;
}

export function ViewLogsTableActions({ log }: ViewLogsTableActionsProps) {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { confirm } = useModal();
  const { showUserFriendlyErrorToast, success } = useToast();
  const [deleter] = useLogDelete({ config: { throwOnError: true } });
  const [updater] = useLogUpdate({ config: { throwOnError: true } });

  const handlePublish = useCallback(async () => {
    try {
      if (!log || !organizationId) {
        const errMsg = `Couldn't update Log: Logs page doesn't have Log (${log.id}) or organization (${organizationId})`;
        Sentry.captureException(errMsg);
        throw new Error(errMsg);
      }
      const updated = await updater({
        log: { ...log, isPublished: !log.isPublished },
        organizationId,
      });
      if (updated) {
        success(`${log.name} has been updated.`);
      }
    } catch (err) {
      showUserFriendlyErrorToast(err, `Unable to update ${log.name}.`);
    }
  }, [showUserFriendlyErrorToast, log, organizationId, success, updater]);

  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm({
      title: 'Delete Log',
      description: (
        <>
          Are you sure you want to delete the <Bold>{log.name}</Bold> log? No
          users at any site will be able to add data to this log.
        </>
      ),
      cancelButtonText: 'Keep Log',
      confirmButtonText: 'Delete Log',
      danger: true,
    });
    if (isConfirmed) {
      try {
        if (!log || !organizationId) {
          const errMsg = `Couldn't delete Log: Logs page doesn't have Log (${log.id}) or organization (${organizationId})`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }
        const deleted = await deleter({ logId: log.id, organizationId });
        if (deleted) {
          success(`${log.name} has been deleted.`);
        }
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to delete ${log.name}.`);
      }
    }
  }, [
    confirm,
    deleter,
    showUserFriendlyErrorToast,
    log,
    organizationId,
    success,
  ]);

  return (
    <ActionMenu
      altText={`Action menu for ${log.name} log`}
      options={[
        {
          displayName: log.isPublished ? 'Unpublish' : 'Publish',
          onClick: handlePublish,
        },
        {
          displayName: 'Edit',
          onClick: () => navigate(`${log.id}/edit`),
        },
        {
          displayName: 'Delete',
          onClick: handleDelete,
        },
      ]}
    />
  );
}
