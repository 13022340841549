import { Footer } from '@monorepo/shared/componentsV2/Footer';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import {
  Pagination,
  PaginationOnPageChange,
} from '@monorepo/shared/componentsV2/Pagination';
import {
  ColumnLayout,
  Table,
  TableColumn,
} from '@monorepo/shared/componentsV2/Table';
import { helpDeskButtonDiameter } from '@monorepo/shared/constants/helpDesk';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { ColumnType, QueryResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  DisplayQueryColumn,
  DisplayResultRow,
  getDisplayQueryColumns,
  mapResultRows,
  SpecialColumnType,
} from './resultsMapper';
import { ViewResultsTableCellFactory } from './ViewResultsTableCellFactory';

const Container = styled.div`
  ${verticalLayout}
`;

const StyledTable = styled(Table)`
  ${fillHeightAndScrollable}
` as typeof Table;

const StyledPagination = styled(Pagination)`
  flex-shrink: 0;

  /* Since the pagination control is pinned to the bottom of this 100% height component, we
   know it will run into the pendo icon. So we give it extra padding. */
  margin-right: ${helpDeskButtonDiameter};
`;

interface ViewResultsTableProps {
  className?: string;
  isLoading: boolean;
  onPageChange?: PaginationOnPageChange;
  queryResult?: QueryResponse;
  viewName?: string;
}

function getColumnLayout(queryColumn: DisplayQueryColumn): ColumnLayout {
  switch (queryColumn.columnType) {
    case ColumnType.NUMBER:
    case SpecialColumnType.LIMIT_COLUMN:
      return { align: 'right' };
    case ColumnType.BOOLEAN:
    case ColumnType.DATETIME:
    case ColumnType.DATE_RANGE:
    case ColumnType.TEXT:
    case ColumnType.TIME:
    case ColumnType.FOREIGN_ID:
    default:
      return {};
  }
}

export function ViewResultsTable({
  className,
  isLoading,
  onPageChange,
  queryResult,
  viewName,
}: ViewResultsTableProps) {
  const {
    columns: queryColumns = [],
    columnSets = [],
    limitColumnSets = [],
    results: {
      pagination = {
        fullCount: 0,
        currentPage: 0,
        perPage: 50,
        from: 0,
        to: 0,
      },
    } = {},
  } = queryResult || {};

  const rows: DisplayResultRow[] = useMemo(
    () => (queryResult ? mapResultRows(queryResult) : []),
    [queryResult],
  );

  const columns: TableColumn<DisplayResultRow>[] = useMemo(
    () =>
      getDisplayQueryColumns(queryColumns, limitColumnSets, columnSets).map(
        (queryColumn: DisplayQueryColumn) => {
          const { columnName, columnLabel } = queryColumn;
          return {
            id: columnName,
            header: columnLabel,
            ...getColumnLayout(queryColumn),
            // eslint-disable-next-line react/no-unstable-nested-components
            contents: (row: DisplayResultRow) => (
              <ViewResultsTableCellFactory
                key={row.id}
                column={queryColumn}
                columns={queryColumns}
                columnSets={columnSets}
                row={row}
              />
            ),
          };
        },
      ),
    [columnSets, limitColumnSets, queryColumns],
  );

  return isLoading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <Container className={className}>
      <StyledTable
        ariaLabel={`${viewName || 'Current View'} Results`}
        columns={columns}
        makeTableFocusable
        rows={rows}
      />
      {/* if onPageChange wasn't passed in we assume you don't want pagination */}
      {onPageChange ? (
        <StyledPagination
          onPageChange={onPageChange}
          page={pagination.currentPage || 0}
          rowsPerPage={pagination.perPage}
          totalRowCount={pagination.fullCount ?? 0}
        />
      ) : (
        <Footer />
      )}
    </Container>
  );
}
