import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import { useToast } from '@monorepo/shared/contexts';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { ResourceTypeListResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useResourceTypeDelete } from '../../hooks/useResourceTypeDelete';
import { ResourceTypeEditMode } from '../routerTypes';

const Bold = styled.span`
  font-weight: 700;
`;

interface ResourceTypeActionMenuProps {
  organizationId: string;
  resourceType: ResourceTypeListResponse;
}

export function ResourceTypeActionMenu({
  organizationId,
  resourceType,
}: ResourceTypeActionMenuProps) {
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const navigate = useNavigate();

  const [deleter] = useResourceTypeDelete({ config: { throwOnError: true } });
  const { confirm } = useModal();
  const { showUserFriendlyErrorToast, success: showSuccess } = useToast();
  const handleDelete = useCallback(async () => {
    const isConfirmed = await confirm({
      title: 'Delete Resource Type',
      description: (
        <>
          Are you sure you want to delete the <Bold>{resourceType.name}</Bold>{' '}
          Resource type?
        </>
      ),
      cancelButtonText: 'Keep Resource type',
      confirmButtonText: 'Delete Resource type',
      danger: true,
    });

    if (!isConfirmed) return;

    try {
      await deleter({
        organizationId,
        resourceTypeId: resourceType.id,
      });
      showSuccess(`${resourceType.name} has been deleted.`);
    } catch (error) {
      showUserFriendlyErrorToast(
        error,
        `Unable to delete ${resourceType.name}.`,
      );
    }
  }, [
    confirm,
    deleter,
    organizationId,
    resourceType,
    showUserFriendlyErrorToast,
    showSuccess,
  ]);

  const options = useMemo(() => {
    const commonOptions = [
      {
        displayName: 'Details',
        onClick: () => {
          navigate(resourceType.id);
        },
      },
    ];
    if (!canEditOrgSettings) return commonOptions;
    return [
      ...commonOptions,
      {
        displayName: 'Edit',
        onClick: () => {
          navigate(`${resourceType.id}/${ResourceTypeEditMode.EDIT}`);
        },
      },
      {
        displayName: 'Delete',
        onClick: handleDelete,
      },
    ];
  }, [canEditOrgSettings, handleDelete, navigate, resourceType]);

  return (
    <ActionMenu
      altText={`Action menu for ${resourceType.name} Resource type`}
      options={options}
    />
  );
}
