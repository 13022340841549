import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { IntervalFrequency } from 'mapistry-shared';
import { FieldWrapper, Select } from '../elements';
import getFrequencyFromResource from '../../utils/genericLogs/getFrequencyFromResource';
import {
  ChartableGenericLogsResource,
  ResourceKey,
} from '../../types/ts-types';

const FREQUENCY_OPTIONS = [
  { label: 'Hourly', value: IntervalFrequency.HOUR },
  { label: 'Daily', value: IntervalFrequency.DAY },
  { label: 'Weekly', value: IntervalFrequency.WEEK },
  { label: 'Monthly', value: IntervalFrequency.MONTH },
  { label: 'Quarterly', value: IntervalFrequency.QUARTER },
  { label: 'Twice per year', value: IntervalFrequency.BIANNUAL },
  { label: 'Yearly', value: IntervalFrequency.YEAR },
];

type AirEmissionResourceFrequencyFieldProps = {
  allResources: ChartableGenericLogsResource[];
  forcedFrequencyHelpText: string;
  label: string;
  name: string;
  selectedResources: ResourceKey[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GenericLogsResourceFrequencyField(
  props: AirEmissionResourceFrequencyFieldProps,
) {
  const {
    name,
    label,
    allResources,
    selectedResources,
    forcedFrequencyHelpText,
  } = props;
  const forcedFrequency = useMemo(() => {
    // if any of the selected resources are rolling calculations then the frequency
    // must equal the calculation frequency of that rolling calculation
    const selectedRollingCalculationResourceKey = selectedResources.find(
      (r) => r.resourceType === 'rollingCalculation',
    );
    const selectedRollingCalculationResource =
      selectedRollingCalculationResourceKey
        ? allResources.find(
            (r) =>
              r.resourceId === selectedRollingCalculationResourceKey.resourceId,
          )
        : undefined;
    return selectedRollingCalculationResource
      ? getFrequencyFromResource(selectedRollingCalculationResource)
      : undefined;
  }, [allResources, selectedResources]);
  return (
    <Field name={name}>
      {(field) => (
        <div className="field-wrapper">
          <FieldWrapper
            label={label}
            helpText={forcedFrequency ? forcedFrequencyHelpText : undefined}
          >
            <Select
              isDisabled={Boolean(forcedFrequency)}
              error={Boolean(field.meta.touched && field.meta.error)}
              isFixed
              isClearable={false}
              options={FREQUENCY_OPTIONS}
              onChange={(opt) => field.input.onChange(opt.value)}
              value={FREQUENCY_OPTIONS.find(
                (o) =>
                  o.value === forcedFrequency || o.value === field.input.value,
              )}
            />
          </FieldWrapper>
        </div>
      )}
    </Field>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsResourceFrequencyField;
