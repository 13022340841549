import { humanReadableNumber } from '@monorepo/old-web/js/utils';
import {
  AnimatedComponent,
  AnimationType,
} from '@monorepo/shared/components/AnimatedComponent';
import { Tooltip } from '@monorepo/shared/components/Tooltip';
import ExceedanceIcon from '@svg/exclamation.svg';
import { GenericLogLimitType, LogStatistic } from 'mapistry-shared';
import React, { useMemo } from 'react';

const CHART_SERIES_COLORS = {
  CLEAN_GREEN: '#31B14A',
  WARNING_YELLOW: '#F2C407',
  EXCEEDED_RED: '#FF4136',
};

type LimitMonitorChartRowProps = {
  statistics: LogStatistic;
};

export const LimitMonitorChartRow = (props: LimitMonitorChartRowProps) => {
  const { statistics } = props;
  const [limitStat] = statistics.stats.limits;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const limitType = limitStat.type;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const limitValue = limitStat.value;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const { limitFrequency } = limitStat;
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const limitUnit = limitStat.units;
  const statValue = useMemo(() => {
    switch (limitType) {
      case GenericLogLimitType.AVERAGE:
        return statistics.stats.averageValue;
      case GenericLogLimitType.GEOMETRIC_MEAN:
        return statistics.stats.geomeanValue;
      case GenericLogLimitType.MAX:
      case GenericLogLimitType.MIN:
      default:
        return statistics.stats.totalValue;
    }
  }, [
    limitType,
    statistics.stats.averageValue,
    statistics.stats.geomeanValue,
    statistics.stats.totalValue,
  ]);
  const percentOfLimit = (statValue / limitValue) * 100;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
  const color = useMemo(() => {
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (limitStat.status) {
      case 'clean':
        return CHART_SERIES_COLORS.CLEAN_GREEN;
      case 'limit_warning':
        return CHART_SERIES_COLORS.WARNING_YELLOW;
      case 'limit_exceeded':
        return CHART_SERIES_COLORS.EXCEEDED_RED;
    }
  }, [limitStat]);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
  const humanReadableLimitType = useMemo(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (limitType) {
      case GenericLogLimitType.MAX:
        return 'Max limit of';
      case GenericLogLimitType.MIN:
        return 'Min limit of';
      case GenericLogLimitType.AVERAGE:
        return 'Average limit of';
      case GenericLogLimitType.GEOMETRIC_MEAN:
        return 'Geometric mean limit of';
    }
  }, [limitType]);

  return (
    <div className="row-chart__wrapper">
      <div className="row-chart__header">
        <span>{humanReadableLimitType} </span>
        <span>
          {limitValue} {limitUnit}
          {' / '}
          {limitFrequency}
        </span>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        {limitStat.status === 'limit_exceeded' && (
          <span>
            <div className="svg-icon-outline exceedance__icon">
              <Tooltip
                placement="top"
                /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
                title={`The facility has exceeded the limit ${limitStat.label}.`}
              >
                <div>
                  <ExceedanceIcon className="m-icon exceedance__icon" />
                </div>
              </Tooltip>
            </div>
          </span>
        )}
      </div>
      <div className="row-chart__body">
        <div className="row-chart__background">
          <AnimatedComponent type={AnimationType.slideLeftRight}>
            <div
              className="row-chart__inner"
              style={{
                backgroundColor: color,
                width: `${Math.min(percentOfLimit, 100)}%`,
              }}
            />
          </AnimatedComponent>
        </div>
      </div>
      <div className="row-chart__footer">
        <span
          className={`${
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            limitStat.status === 'limit_exceeded'
              ? 'color-red'
              : 'color-default'
          }`}
        >
          {humanReadableNumber(statValue, 2)}/{limitValue} {limitUnit} logged
        </span>
      </div>
    </div>
  );
};
