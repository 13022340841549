import { bodySmall, header4 } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const SectionHeader = styled.div`
  ${header4}
  padding: .5rem 0;
`;

export const Subtext = styled.div`
  ${bodySmall}
  padding-bottom: 1rem;
  color: ${({ theme }) => theme.colors.gray666};
`;
