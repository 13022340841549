import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useWorkflow } from '@monorepo/shared/hooks/workflows/useWorkflow';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { DatasetInfo } from '../../shared/DatasetPicker/DatasetInfo';
import { InfoContainer } from '../../shared/DatasetPicker/styled';

export function SelectedDataset() {
  const { organizationId, workflowId } = useParams();
  const { workflow, isLoading } = useWorkflow({
    organizationId,
    workflowId,
  });

  if (isLoading) {
    return (
      <InfoContainer>
        <Loading size="medium" />;
      </InfoContainer>
    );
  }

  if (!workflow) {
    throw new Error(`A Workflow with id ${workflowId} does not exist.`);
  }

  const { inputDataset } = workflow;
  return <DatasetInfo dataset={inputDataset} isLocked />;
}
