import { Button } from '@monorepo/shared/componentsV2/Button';
import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { ButtonsGroup } from './ButtonsGroup';
import { TextBlock } from './TextBlock';

interface ButtonConfig {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactElement;
}

interface EmptyPageProps {
  actionButtonConfigs?: ButtonConfig | ButtonConfig[];
  additionalContent?: ReactNode;
  className?: string;
  primaryText: string;
  secondaryText?: string;
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  text-align: center;
`;

export function EmptyState({
  actionButtonConfigs = [],
  additionalContent,
  className,
  primaryText,
  secondaryText,
}: EmptyPageProps) {
  const buttonConfigs = useMemo(
    () =>
      Array.isArray(actionButtonConfigs)
        ? actionButtonConfigs
        : [actionButtonConfigs],
    [actionButtonConfigs],
  );
  return (
    <PageContainer className={className}>
      <TextBlock primaryText={primaryText} secondaryText={secondaryText} />
      <ButtonsGroup>
        {buttonConfigs.map((buttonConfig) => (
          <Button
            color="primary"
            onClick={buttonConfig.onClick}
            key={buttonConfig.label}
          >
            {!!buttonConfig.icon && buttonConfig.icon}
            {buttonConfig.label}
          </Button>
        ))}
      </ButtonsGroup>
      {additionalContent}
    </PageContainer>
  );
}
