import { Api } from '@monorepo/shared/apiClient';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchDatasetParams;

export const createKey = ({ organizationId, datasetId }: KeyParams) =>
  ['dataset', organizationId, datasetId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchDataset>;

const fetcher: Fetcher = (_, organizationId, datasetId) =>
  Api.fetchDataset({ organizationId, datasetId });

type UseDatasetsParams = Partial<Api.FetchDatasetParams> & {
  config?: QueryConfig<Api.FetchDatasetResponse, Api.ErrorResponse>;
};

export function useDataset({
  config: inputConfig,
  organizationId,
  datasetId,
}: UseDatasetsParams) {
  const isEnabled = !!organizationId && !!datasetId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId, datasetId }) : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    dataset: data,
  };
}

// todo@workflows
// dataset invalidation is a little tricky because a lot of it happens async
// we could start invalidating datasets based on `originId` when ResourceTypes or Logs change
