import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button } from '@monorepo/shared/componentsV2/Button';
import React from 'react';
import { useLogFieldCommonState } from '../../../hooks/useLogFieldCommonState';
import { EditLogFieldModal } from '../../EditLogFieldModal';
import { Description, SubTitle } from '../styled';
import {
  ReorderableLogFields,
  SortedLogFields,
  UpdateLogFieldOrder,
} from './ReorderableLogFields';

interface EditLogFieldListProps {
  logId?: string;
  organizationId?: string;
  sortedLogFields: SortedLogFields;
  updateLogFieldOrder: UpdateLogFieldOrder;
}

export function EditLogFieldList({
  logId,
  organizationId,
  sortedLogFields,
  updateLogFieldOrder,
}: EditLogFieldListProps) {
  const { closeModal, field, open, openModal, getMenu } =
    useLogFieldCommonState({
      fieldType: 'Log',
      logId,
      organizationId,
    });

  const listLabelId = 'log-fields';

  return (
    <>
      <EditLogFieldModal field={field} open={open} onClose={closeModal} />
      <SubTitle id={listLabelId}>Fields</SubTitle>
      <Description>
        <div>
          Log fields allow you to log data required to comply with permits. Tip:
          think of each entry field as a column in your data table.
        </div>
        <div>Drag and drop the fields to reorder them.</div>
      </Description>
      <ReorderableLogFields
        getMenu={getMenu}
        listLabelId={listLabelId}
        sortedLogFields={sortedLogFields}
        updateLogFieldOrder={updateLogFieldOrder}
      />
      <Button color="primary" onClick={() => openModal()}>
        <AddCircleOutlineIcon />
        Add a Log field
      </Button>
    </>
  );
}
