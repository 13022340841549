import { useLogAllMessagesHandler } from './useLogAllMessagesHandler';
import { useLogCalendarMessageHandlers } from './useLogCalendarMessageHandlers';
import { useTaskCalendarMessageHandlers } from './useTaskCalendarMessageHandlers';
import { useTestMessageHandlers } from './useTestMessageHandlers';

export function useRegisterPushMessageHandlers() {
  useLogAllMessagesHandler({ enableDebuggingLogs: false }); // set to true to log all incoming push notifications
  useTestMessageHandlers();
  useLogCalendarMessageHandlers();
  useTaskCalendarMessageHandlers();
}
