import { fieldTypeOptions } from '@monorepo/shared/componentsV2/fieldDataType/fieldTypeOptions';
import { FieldTypePreview } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreview';
import { FieldDataType } from 'mapistry-shared';
import React from 'react';

export function SitePropertyPreview() {
  const { icon, label: tooltip } =
    fieldTypeOptions[FieldDataType.SINGLE_SELECT];
  return (
    <FieldTypePreview
      fieldName="Site"
      fieldTypeName="property"
      icon={icon}
      iconTooltip={tooltip}
      isLocked
      isRequired
    />
  );
}
