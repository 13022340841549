import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { ComboboxField } from '@monorepo/shared/componentsV2/fields/ComboboxField';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import TrashIcon from '@svg/trash.svg';
import { MathUnit, NullableMathUnitName } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GroupByColumn } from './GroupByColumn';
import { LimitType } from './types';

const DeleteButton = styled(IconButton)<{ $withLabelAdjustments: boolean }>`
  align-self: ${({ $withLabelAdjustments }) =>
    $withLabelAdjustments ? 'flex-end' : 'flex-start'};
  min-width: 2rem;
  height: 30px;
  margin-bottom: ${({ $withLabelAdjustments }) =>
    $withLabelAdjustments ? '1.6rem' : '0'};
  font-size: 0.75rem;
`;

interface LimitConditionProps {
  hideLabels: boolean;
  limitType: LimitType;
  name: string;
  onDelete?(): void;
  units: NullableMathUnitName | undefined;
}

export function LimitCondition({
  hideLabels,
  limitType,
  name,
  onDelete,
  units,
}: LimitConditionProps) {
  const unitOptions = useMemo(() => {
    let relatedUnitOptions: SelectableOption[] = [];

    if (!units) {
      return relatedUnitOptions;
    }

    relatedUnitOptions = MathUnit.getRelatedSelectableUnits(units);
    return relatedUnitOptions;
  }, [units]);

  return (
    <>
      <GroupByColumn name={name} hideLabels={hideLabels} />
      {(limitType === LimitType.min || limitType === LimitType.range) && (
        <TextField
          fullWidth
          hiddenLabel={hideLabels}
          label="Minimum"
          name={`${name}.min`}
          placeholder="n"
          required
          type="number"
          validate={isRequired}
        />
      )}
      {(limitType === LimitType.max || limitType === LimitType.range) && (
        <TextField
          fullWidth
          hiddenLabel={hideLabels}
          label="Maximum"
          name={`${name}.max`}
          placeholder="n"
          required
          type="number"
          validate={isRequired}
        />
      )}
      {!!units && (
        <ComboboxField
          fullWidth
          hiddenLabel={hideLabels}
          label="Unit"
          name={`${name}.units`}
          options={unitOptions}
          placeholder="Unit"
          required
          validate={isRequired}
        />
      )}
      {onDelete && (
        <DeleteButton $withLabelAdjustments={!hideLabels} onClick={onDelete}>
          <VisuallyHidden>Delete condition</VisuallyHidden>
          <TrashIcon />
        </DeleteButton>
      )}
    </>
  );
}
