import MuiTablePagination from '@material-ui/core/TablePagination';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const ROWS_PER_PAGE = 50;

const StyledPagination = styled(MuiTablePagination).attrs({
  backIconButtonProps: {
    disableRipple: true,
  },
  nextIconButtonProps: {
    disableRipple: true,
  },
})`
  & .MuiTablePagination-actions {
    margin-left: 0.75rem;
  }

  & .MuiButtonBase-root {
    padding: 0.25rem;

    &:focus-visible {
      outline: 0.125rem solid ${({ theme }) => theme.colors.gray123};
    }
  }
` as typeof MuiTablePagination;

export type PaginationOnPageChange = (pageNumber: number) => void;

interface PaginationProps {
  className?: string;
  /**
   * Changing the name of this prop because `onChangePage` is marked as deprecated in the MUI docs,
   * but is the only option available for the version we have installed currently. The change I'm
   * making matches the accepted prop in the documentation
   *
   * https://v4.mui.com/api/table-pagination/#props
   */
  onPageChange: PaginationOnPageChange;
  page: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  totalRowCount?: number;
}

export function Pagination({
  className,
  onPageChange,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  totalRowCount,
}: PaginationProps) {
  const handlePageChange = useCallback(
    (_, newPage) => onPageChange(newPage),
    [onPageChange],
  );

  return (
    <StyledPagination
      component="div"
      count={totalRowCount == null ? -1 : totalRowCount}
      className={className}
      onChangePage={handlePageChange}
      page={page}
      rowsPerPage={rowsPerPage || ROWS_PER_PAGE}
      rowsPerPageOptions={rowsPerPageOptions || [ROWS_PER_PAGE]}
    />
  );
}
