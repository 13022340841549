import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { colors } from '@monorepo/shared/themes/colors';
import FailureIcon from '@svg/m-event-calendar-failure.svg';
import IncompleteIcon from '@svg/m-event-calendar-incomplete.svg';
import SuccessIcon from '@svg/m-event-calendar-success.svg';
import { CalendarEventStatus } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';

const EventStatusIcon = styled.div<{
  color: string;
}>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};

  & .m-icon {
    width: unset;
    margin: 0 3px 0 2px;
    font-size: 1rem;
  }
`;

interface EventStatusIndicatorProps {
  className?: string;
  status: CalendarEventStatus;
}

export const EventStatusIndicator = ({
  className,
  status,
}: EventStatusIndicatorProps) => {
  let Icon;
  let statusText;
  let color;

  switch (status) {
    case CalendarEventStatus.COMPLETE:
      Icon = SuccessIcon;
      statusText = 'complete';
      color = colors.darkGreen;
      break;
    case CalendarEventStatus.OVERDUE:
      Icon = FailureIcon;
      statusText = 'overdue';
      color = colors.darkRed;
      break;
    default:
      Icon = IncompleteIcon;
      statusText = 'incomplete';
      color = colors.lightBlue;
      break;
  }

  return (
    <EventStatusIcon className={className} color={color}>
      <VisuallyHidden>{statusText}:</VisuallyHidden>
      <Icon aria-hidden className="m-icon" />
    </EventStatusIcon>
  );
};
