import {
  FieldResponse,
  FrequencyRequirementFilterResponse,
} from 'mapistry-shared';
import React from 'react';
import { fieldValueDisplayFactory } from '../fieldDataType/values/fieldValueDisplayFactory';

type FrequencyRequirementFilterDisplayProps = {
  filter: FrequencyRequirementFilterResponse;
  logFields?: FieldResponse[];
  className?: string;
};

export function getFilterAsText(
  filter: FrequencyRequirementFilterResponse,
  logFields?: FieldResponse[],
) {
  const field = (logFields || []).find(
    (logField) => logField.id === filter.fieldId,
  );
  if (!field) {
    throw new Error('Log field selected for the filter does not exist');
  }
  return `${field.name} = ${fieldValueDisplayFactory({
    fieldType: field.type,
    fieldValue: filter.value,
  })}`;
}

export function FrequencyRequirementFilterDisplay({
  filter,
  logFields,
  className,
}: FrequencyRequirementFilterDisplayProps) {
  return <div className={className}>{getFilterAsText(filter, logFields)}</div>;
}
