export enum RouteEditMode {
  EDIT = 'edit',
  NEW = 'new',
}

export enum WizardStep {
  DETAILS = 'Basic Info',
  STEPS = 'Steps',
}

// the last bit of the URL each one of these steps is found on
export const WizardStepUrlPart: Record<WizardStep, string> = {
  [WizardStep.DETAILS]: 'basic-info',
  [WizardStep.STEPS]: 'steps',
};
