import { CheckboxField } from '@monorepo/shared/componentsV2/fields/CheckboxField';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { ModalFieldWrapper } from '../../styled';
import { AlertText } from '../styled';

// this is rendered within FinalForm so the inputs are managed by it
export function EditIsRequiredField() {
  return (
    <>
      <CheckboxField name="isRequired" label="Field is required" />
      <FormSpy subscription={{ values: true, initialValues: true }}>
        {({ values, initialValues }) => {
          const shouldShowAlert =
            values.isRequired === true && initialValues.isRequired === false;
          return (
            shouldShowAlert && (
              <ModalFieldWrapper>
                <AlertText>
                  If already completed submissions have left this field blank,
                  they will NOT be marked as incomplete. But, if a user visits
                  such a form submission and clicks “Save,” the previously
                  complete submission will now be incomplete.
                </AlertText>
              </ModalFieldWrapper>
            )
          );
        }}
      </FormSpy>
    </>
  );
}
