import React from 'react';
import styled from 'styled-components';
import { bodyRegular, bodySmall } from '../../styles/text';
import { VisuallyHidden } from '../VisuallyHidden';

const ListItem = styled.li`
  ${bodyRegular}
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0 1rem;
`;

const IconSection = styled.div`
  flex-shrink: 0;
  margin-right: 0.5rem;

  & svg {
    width: 0.875rem;
    height: 0.875rem;
    fill: ${({ theme }) => theme.colors.gray666};
  }
`;

const Section = styled.div`
  margin-right: 0.5rem;
  overflow: hidden;
  line-height: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Token = styled(Section)`
  ${bodySmall}
  flex-shrink: 0;

  /* italic text gets cut off with overflow: hidden; This padding prevents that */
  padding-right: 0.375rem;
  font-style: italic;
`;

const FormulaExpression = styled(Section)`
  flex-shrink: 2;
`;

export interface FormulaTokenListItemProps {
  formulaExpression?: string;
  icon?: React.ReactElement;
  iconAltText?: string;
  name: string;
  token: string;
}

export function FormulaTokenListItem({
  formulaExpression,
  icon,
  iconAltText,
  name,
  token,
}: FormulaTokenListItemProps) {
  return (
    <ListItem title={name}>
      {icon && (
        <IconSection aria-hidden>
          {icon}
          {iconAltText && <VisuallyHidden>iconAltText</VisuallyHidden>}
        </IconSection>
      )}
      <Section>{name}</Section>
      {formulaExpression && (
        <FormulaExpression>({formulaExpression})</FormulaExpression>
      )}
      <Token>{token}</Token>
    </ListItem>
  );
}
