import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogFieldCache,
  LogFieldCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  getCache as getLogFieldsCache,
  setCache as setLogFieldsCache,
} from '@monorepo/shared/hooks/logs/useLogFields';
import { MutationConfig, useMutation } from 'react-query';

type useLogFieldDeleteParams = {
  config?: MutationConfig<Api.DeleteLogFieldResponse, Api.ErrorResponse>;
};

export const useLogFieldDelete = (params?: useLogFieldDeleteParams) =>
  useMutation(Api.deleteLogField, {
    ...params?.config,
    onMutate: ({ logFieldId, logId, organizationId }) => {
      // Optimistically remove from the list of log fields if there's cache
      let removedLogField:
        | Exclude<ReturnType<typeof getLogFieldsCache>, undefined>[0]
        | undefined;
      const currentLogFields = getLogFieldsCache({ logId, organizationId });
      if (currentLogFields) {
        removedLogField = currentLogFields.find((f) => f.id === logFieldId);
        const newLogFields = currentLogFields.filter(
          (item) => item.id !== logFieldId,
        );

        setLogFieldsCache({ logId, organizationId }, newLogFields);
      }

      return () => {
        // Put the log field back in the list if cache is still there
        const logFields = getLogFieldsCache({ logId, organizationId });
        if (logFields && removedLogField) {
          setLogFieldsCache({ logId, organizationId }, [
            ...logFields,
            removedLogField,
          ]);
        }
      };
    },
    onError: (error, deleteLogFieldParams, rollback) => {
      if (params?.config?.onError) {
        return params.config.onError(error, deleteLogFieldParams, rollback);
      }
      return rollback();
    },
    onSuccess: (_, { logFieldId, logId, organizationId }) => {
      // Invalidate any RQ cache that might use information about Log Fields
      invalidateLogFieldCache(LogFieldCacheEvents.UPDATE, {
        logFieldId,
        logId,
        organizationId,
      });
    },
  });
