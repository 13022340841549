import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { Search } from '@monorepo/shared/componentsV2/Search';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  max-width: 10rem;
`;

const ButtonContainer = styled.div`
  min-width: 7rem;
`;

type ViewResourceTypesHeaderProps = {
  onSearchChange?: React.ComponentProps<typeof Search>['onChange'];
  onAddNew: React.ComponentProps<typeof Button>['onClick'];
  organizationId: string;
};

export function ViewResourceTypesHeader(props: ViewResourceTypesHeaderProps) {
  const { onSearchChange, onAddNew, organizationId } = props;
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  return (
    <Header
      variant="noDropShadow"
      rightActions={
        <ButtonsGroup>
          {onSearchChange && (
            <SearchContainer>
              <Search onChange={onSearchChange} />
            </SearchContainer>
          )}
          {canEditOrgSettings && (
            <ButtonContainer>
              <Button color="primary" onClick={onAddNew} variant="contained">
                Create new Resource type
              </Button>
            </ButtonContainer>
          )}
        </ButtonsGroup>
      }
      title="All Resource Types"
    />
  );
}
