import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogCache,
  LogCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import {
  createQueryKey as createLogKey,
  getCache as getLogCache,
  setCache as setLogCache,
} from '@monorepo/shared/hooks/logs/useLog';
import {
  getCache as getLogsCache,
  setCache as setLogsCache,
} from '@monorepo/shared/hooks/logs/useLogs';
import { LogRefResponse } from 'mapistry-shared';
import { MutationConfig, queryCache, useMutation } from 'react-query';

type useLogDeleteParams = {
  config?: MutationConfig<Api.DeleteLogResponse, Api.ErrorResponse>;
};

export const useLogDelete = (params?: useLogDeleteParams) =>
  useMutation(Api.deleteLog, {
    ...params?.config,
    onMutate: ({ logId, organizationId }) => {
      // Optimistically remove the single log
      const logKeyParams = { logId, organizationId };
      const logKey = createLogKey(logKeyParams);
      const removedLog = getLogCache(logKeyParams);

      queryCache.removeQueries(logKey, { exact: true });

      // Optimistically remove from the list of logs if there's cache
      const currentLogs = getLogsCache({ organizationId });
      let logFromList: LogRefResponse | undefined;
      if (currentLogs) {
        logFromList = currentLogs.find((item) => item.id === logId);
        const newLogs = currentLogs.filter((item) => item.id !== logId);
        setLogsCache({ organizationId }, newLogs);
      }

      return () => {
        if (removedLog) {
          // Put the single log back
          setLogCache(logKeyParams, removedLog);
        }

        if (logFromList) {
          // Put the log back in the list if cache is still there
          const logs = getLogsCache({ organizationId });
          if (logs) {
            setLogsCache({ organizationId }, [...logs, logFromList]);
          }
        }
      };
    },
    onSuccess: (_, { logId, organizationId }) => {
      // Invalidate any RQ cache that might use information about Logs
      invalidateLogCache(LogCacheEvents.DELETE, { logId, organizationId });
    },
    onError: (error, deleteLogParams, rollback) => {
      if (params?.config?.onError) {
        return params.config.onError(error, deleteLogParams, rollback);
      }
      return rollback();
    },
  });
