import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { ResourceTypeListResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '../../cacheRegisters/data2/resourceCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '../../cacheRegisters/data2/resourceTypeCacheRegister';

type KeyParams = Api.FetchResourceTypesParams;
export const createKey = ({ organizationId }: KeyParams) =>
  ['org-resource-types', organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchResourceTypes
>;

const fetcher: Fetcher = (_, organizationId) =>
  Api.fetchResourceTypes({ organizationId });

type UseResourceTypesParams = Partial<Api.FetchResourceTypesParams> & {
  config?: QueryConfig<ResourceTypeListResponse[], Api.ErrorResponse>;
};

export function useResourceTypes({
  config: inputConfig,
  organizationId,
}: UseResourceTypesParams) {
  const isEnabled = !!organizationId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId }) : undefined;
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    resourceTypes: data,
  };
}

type QueryCache = ReturnType<typeof useResourceTypes>['resourceTypes'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

const cacheRegisterInvalidator = {
  hookName: 'useResourceTypes',
  callback: (keyParams: KeyParams) => invalidateCache(keyParams),
};

resourceCacheRegister(
  [ResourceCacheEvents.CREATE, ResourceCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

resourceTypeCacheRegister(
  [ResourceTypeCacheEvents.CREATE],
  cacheRegisterInvalidator,
);
