import { useEffect } from 'react';

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
function useMountEffect(cb) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    cb();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default useMountEffect;
