import _debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

interface DebounceOptions {
  leading: boolean;
  trailing: boolean;
  wait: number;
}

export function useDebouncedValue<T = string>(
  value: T,
  initialOptions?: DebounceOptions,
) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [unchangeableOptions] = useState({
    leading: initialOptions?.leading ?? false,
    trailing: initialOptions?.trailing ?? true,
    wait: initialOptions?.wait ?? 500,
  });

  useEffect(() => {
    const { leading, trailing, wait } = unchangeableOptions;
    const debouncedSetter = _debounce(setDebouncedValue, wait, {
      leading,
      trailing,
    });
    debouncedSetter(value);
    return debouncedSetter.cancel;
  }, [unchangeableOptions, value]);

  return debouncedValue;
}
