import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum LogFrequencyRequirementCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  organizationId: string;
  logId: string;
  projectId: string;
  logFrequencyRequirementId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof LogFrequencyRequirementCacheEvents,
  CacheParams
>(LogFrequencyRequirementCacheEvents);

export const invalidateLogFrequencyRequirementCache = cacheRegister.invalidate;
export const logFrequencyRequirementCacheRegister = cacheRegister.register;
