import { SingleSelectFieldResponse } from 'mapistry-shared';
import React from 'react';
import {
  compose,
  hasAtLeastOneValue,
  hasUniqueValues,
} from '../../../utils/validators';
import { ListBuilderField } from '../../fields/ListBuilderField';
import { GenericDataType } from './GenericDataType';

type SingleSelectTypeProps = Omit<
  React.ComponentProps<typeof GenericDataType>,
  'children'
>;

export function SingleSelectType(props: SingleSelectTypeProps) {
  return (
    <GenericDataType {...props}>
      <ListBuilderField<NonNullable<SingleSelectFieldResponse['options']>>
        addItemButtonLabel="Add option"
        label="Options"
        name="options"
        validate={compose(hasAtLeastOneValue, hasUniqueValues)}
      />
    </GenericDataType>
  );
}
