import MuiAppBar from '@material-ui/core/AppBar';
import React from 'react';
import {
  AppBar,
  AppBarProps,
  Bar,
  CenterActions,
  LeftActions,
  RightActions,
  Spacer,
} from './styled';

interface ToolbarProps {
  centerActions?: React.ReactNode;
  className?: AppBarProps['className'];
  leftActions?: React.ReactNode;
  position?: React.ComponentProps<typeof MuiAppBar>['position'];
  rightActions?: React.ReactNode;
  size?: AppBarProps['$size'];
  variant?: AppBarProps['$variant'];
}

export function Toolbar({
  centerActions,
  className,
  leftActions,
  position = 'sticky',
  rightActions,
  size,
  variant,
}: ToolbarProps) {
  return (
    <AppBar
      className={className}
      position={position}
      $size={size}
      $variant={variant}
    >
      <Bar $size={size}>
        {leftActions && <LeftActions>{leftActions}</LeftActions>}
        {centerActions && <CenterActions>{centerActions}</CenterActions>}
        <Spacer />
        {rightActions && <RightActions>{rightActions}</RightActions>}
      </Bar>
    </AppBar>
  );
}
