import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UserType } from '../../propTypes';
import { fetchUsersAction } from '../../../actions/user';
import APP from '../../../config';
import withProvider from '../../withProvider';
import Select from '../Select';

class UserSelectSimple extends Component {
  componentDidMount() {
    const { fetchUsers, projectId } = this.props;
    fetchUsers(projectId);
  }

  handleChange = (selection) => {
    const { isMulti, onChange, users } = this.props;
    const listOfSelections = this.listOfSelections(selection);
    const selectedUserIds = listOfSelections.map((option) => option.value);
    const selectedUsers = users.filter((user) =>
      selectedUserIds.includes(user.userId),
    );
    onChange(isMulti ? selectedUsers : selectedUsers[0] || null);
  };

  listOfSelections(selection) {
    const { isMulti } = this.props;
    if (!selection) {
      return [];
    }
    return isMulti ? selection : [selection];
  }

  render() {
    const { isMulti, selected, users } = this.props;

    const userOptions = users.map((user) => ({
      value: user.userId,
      label: user.name,
    }));
    const selectedUsers = this.listOfSelections(selected);
    const selectedOptions = selectedUsers.map((user) =>
      userOptions.find((o) => o.value === user.userId),
    );

    return (
      <Select
        options={userOptions}
        value={selectedOptions}
        onChange={this.handleChange}
        isMulti={isMulti}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  const { projectId } = APP;

  return {
    projectId,
    users: user.forProject === projectId ? user.users : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (projectId) => dispatch(fetchUsersAction(projectId)),
});

UserSelectSimple.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([UserType, PropTypes.arrayOf(UserType)])
    .isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UserSelectSimple.defaultProps = {
  isMulti: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(UserSelectSimple),
);
