import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogFrequencyRequirementCache,
  LogFrequencyRequirementCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logFrequencyRequirementCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type UseFrequencyRequirementDeleteParams = {
  config?: MutationConfig<
    Api.DeleteFrequencyRequirementResponse,
    Api.ErrorResponse
  >;
};

export const useFrequencyRequirementDelete = (
  params?: UseFrequencyRequirementDeleteParams,
) =>
  useMutation(Api.deleteFrequencyRequirement, {
    ...params?.config,
    onSuccess: (
      _,
      { logId, organizationId, projectId, frequencyRequirementId },
    ) => {
      const keyParams = {
        logId,
        organizationId,
        projectId,
        logFrequencyRequirementId: frequencyRequirementId,
      };
      invalidateLogFrequencyRequirementCache(
        LogFrequencyRequirementCacheEvents.DELETE,
        keyParams,
      );
    },
  });
