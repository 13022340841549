import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type LogsAndViewsPageContext = {
  isHeaderVisible: boolean;
  hideHeader(): void;
  showHeader(): void;

  isSearchVisible: boolean;
  setIsSearchVisible: (isSearchVisible: boolean) => void;

  searchFilter: string;
  setSearchFilter: (filterText: string) => void;
};

const LogsAndViewsPageContext = createContext<
  LogsAndViewsPageContext | undefined
>(undefined);

export const LogsAndViewsPageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const showHeader = useCallback(() => {
    setIsHeaderVisible(true);
  }, []);

  const hideHeader = useCallback(() => {
    setIsHeaderVisible(false);
  }, []);

  const contextValue = useMemo<LogsAndViewsPageContext>(
    () => ({
      isHeaderVisible,
      showHeader,
      hideHeader,
      isSearchVisible,
      setIsSearchVisible,
      searchFilter,
      setSearchFilter,
    }),
    [hideHeader, isHeaderVisible, isSearchVisible, searchFilter, showHeader],
  );

  return (
    <LogsAndViewsPageContext.Provider value={contextValue}>
      {children}
    </LogsAndViewsPageContext.Provider>
  );
};

export function useLogsAndViewsPageContext(): LogsAndViewsPageContext {
  const contextValue = useContext<LogsAndViewsPageContext | undefined>(
    LogsAndViewsPageContext,
  );

  if (!contextValue)
    throw new Error(
      'must use useLogsAndViewsPageContext within a export const LogsAndViewsPageContextProvider',
    );
  return contextValue;
}
