import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogFrequencyRequirementCache,
  LogFrequencyRequirementCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logFrequencyRequirementCacheRegister';
import { FrequencyRequirementResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type UseFrequencyRequirementUpdateParams = {
  config?: MutationConfig<FrequencyRequirementResponse, Api.ErrorResponse>;
};

export const useFrequencyRequirementUpdate = (
  params?: UseFrequencyRequirementUpdateParams,
) =>
  useMutation(Api.updateFrequencyRequirement, {
    ...params?.config,
    onSuccess: async (updatedFrequencyRequirement, mutationFuncParams) => {
      const { organizationId, logId, projectId } = mutationFuncParams;
      invalidateLogFrequencyRequirementCache(
        LogFrequencyRequirementCacheEvents.UPDATE,
        {
          organizationId,
          logId,
          projectId,
          logFrequencyRequirementId: updatedFrequencyRequirement.id,
        },
      );
      return params?.config?.onSuccess?.(
        updatedFrequencyRequirement,
        mutationFuncParams,
      );
    },
  });
