import {
  fetchSelectOptionsByResourceFieldType,
  SelectOptionsByFieldType,
} from '@monorepo/shared/apiClient/logs/fieldOptionsForUpload';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourceListCacheEvents,
  resourceListCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceListCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { ResourceFieldResponse, ResourcePropertyType } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '../../cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '../../cacheRegisters/data2/resourceTypeCacheRegister';
import { useResourceTypeWithProperties } from './useResourceTypeWithProperties';

type KeyParams = {
  organizationId: string;
  resourceTypeId: string;
};

export const createKey = (keyParams: KeyParams): [string, string, string] => {
  const { organizationId, resourceTypeId } = keyParams;
  return ['resourceFieldOptionsForUpload', organizationId, resourceTypeId];
};

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof fetchSelectOptionsByResourceFieldType
>;

type UseResourceFieldOptionsForUploadParams = {
  resourceTypeId: string;
  organizationId: string;
  config?: QueryConfig<SelectOptionsByFieldType, Api.ErrorResponse>;
};

export const useResourceFieldOptionsForUpload = ({
  config: inputConfig = { useErrorBoundary: false },
  resourceTypeId,
  organizationId,
}: UseResourceFieldOptionsForUploadParams) => {
  const isEnabled = !!organizationId;
  const config = getQueryConfig<SelectOptionsByFieldType>(
    inputConfig,
    isEnabled,
  );

  const key = isEnabled
    ? createKey({
        organizationId,
        resourceTypeId,
      })
    : undefined;

  const { resourceProperties } = useResourceTypeWithProperties({
    organizationId,
    resourceTypeId,
  });

  const referencedResourceTypeIds: string[] = (resourceProperties || [])
    .filter((property) => property.type === ResourcePropertyType.RESOURCE)
    .map((property) => (property as ResourceFieldResponse).resourceTypeId);

  const fetcher: Fetcher = async () =>
    fetchSelectOptionsByResourceFieldType({
      organizationId,
      referencedResourceTypeIds,
    });

  const { data: resourceFieldOptionsForUpload, ...queryInfo } = useQuery(
    key,
    fetcher,
    config,
  );
  return {
    resourceFieldOptionsForUpload,
    ...queryInfo,
  };
};

type InvalidateCacheParams = KeyParams;
async function invalidateCache(
  invalidateCacheParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(createKey(invalidateCacheParams));
}

const cacheRegisterInvalidator = {
  hookName: 'useResourceFieldOptionsForUpload',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.UPDATE,
    ResourceTypeCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourcePropertyCacheRegister(
  [
    ResourcePropertyCacheEvents.CREATE,
    ResourcePropertyCacheEvents.UPDATE,
    ResourcePropertyCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.UPDATE,
    ResourceCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourceListCacheRegister(
  [ResourceListCacheEvents.CREATE],
  cacheRegisterInvalidator,
);
