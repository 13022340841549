import { NullableDate } from 'mapistry-shared';
import React, { createContext, useMemo, useState } from 'react';

interface DateRangeFilter {
  from: NullableDate;
  to: NullableDate;
}

type DateRangeFilterContext = {
  filter?: DateRangeFilter;
  setFilter: React.Dispatch<React.SetStateAction<DateRangeFilter | undefined>>;
};

export const DateRangeFilterContext = createContext<
  DateRangeFilterContext | undefined
>(undefined);

type DateRangeFilterProviderProps = {
  children: React.ReactNode;
};

export const DateRangeFilterProvider = ({
  children,
}: DateRangeFilterProviderProps) => {
  const [filter, setFilter] = useState<DateRangeFilter | undefined>();
  const contextValue = useMemo(
    () => ({ filter, setFilter }),
    [filter, setFilter],
  );

  return (
    <DateRangeFilterContext.Provider value={contextValue}>
      {children}
    </DateRangeFilterContext.Provider>
  );
};
