import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourceTypeCache,
  ResourceTypeCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import { setCache as setResourceTypeCache } from '@monorepo/shared/hooks/resources/useResourceType';
import {
  getCache as getListCache,
  invalidateCache as invalidateListCache,
  setCache as setListCache,
} from '@monorepo/shared/hooks/resources/useResourceTypes';
import { ResourceTypeRefResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type UseResourceTypeUpdateParams = {
  config?: MutationConfig<ResourceTypeRefResponse, Api.ErrorResponse>;
};

export const useResourceTypeUpdate = (params?: UseResourceTypeUpdateParams) =>
  useMutation(Api.updateResourceType, {
    ...params?.config,
    onSuccess: (updatedResourceType, mutationFuncParams) => {
      const { organizationId } = mutationFuncParams;

      // Set cache for that single Resource Type
      const keyParams = {
        organizationId,
        resourceTypeId: updatedResourceType.id,
      };
      setResourceTypeCache(keyParams, updatedResourceType);

      // Update list of resource types
      const listKeyParams = { organizationId };
      const currentItems = getListCache(listKeyParams);
      if (currentItems) {
        let found = false;
        const newItems = currentItems.map((item) => {
          // Merging existing list item with updated resource type values
          if (item.id === updatedResourceType.id) {
            found = true;
            return { ...item, ...updatedResourceType };
          }
          return item;
        });
        if (found) {
          setListCache(listKeyParams, newItems);
        } else {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          invalidateListCache(listKeyParams);
        }
      }

      // Invalidate any RQ cache that might use information about Resource Types
      invalidateResourceTypeCache(ResourceTypeCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(
        updatedResourceType,
        mutationFuncParams,
      );
    },
  });
