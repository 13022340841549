export function lookFor<T = Record<string, unknown>>(
  searchTerm: string | null | undefined,
  item: T,
  keysToSearch: Array<keyof T>,
): boolean {
  if (!searchTerm) {
    return true;
  }

  const parsedSearchTerm = searchTerm.trim().toLowerCase();
  if (!parsedSearchTerm) {
    return true;
  }
  for (let keyIdx = 0; keyIdx < keysToSearch.length; keyIdx++) {
    const key = keysToSearch[keyIdx] as keyof T;
    const value = item[key];
    if (typeof value !== 'string') {
      break;
    }
    const parsedValue = value.toLowerCase();
    if (parsedValue.includes(parsedSearchTerm)) {
      // We found a match, hooray! Include this item in search result.
      return true;
    }
  }
  // If we are here, we didn't find a match in any keys, filter this item out.
  return false;
}
