import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourcePropertyCache,
  ResourcePropertyCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  getCache,
  setCache,
} from '@monorepo/shared/hooks/resources/useResourceProperties';
import { FieldResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type useResourcePropertyCreateParams = {
  config?: MutationConfig<FieldResponse, Api.ErrorResponse>;
};

export const useResourcePropertyCreate = (
  params?: useResourcePropertyCreateParams,
) =>
  useMutation(Api.createResourceProperty, {
    ...params?.config,
    onSuccess: (createdProperty, mutationFuncParams) => {
      const { organizationId, resourceTypeId } = mutationFuncParams;

      const listParams = { organizationId, resourceTypeId };
      const currentItems = getCache(listParams);
      // If there is cache, update it
      if (currentItems) {
        const newItems = [...currentItems, createdProperty];
        setCache(listParams, newItems);
      }

      // Invalidate any RQ cache that might use information about Resource Property
      invalidateResourcePropertyCache(ResourcePropertyCacheEvents.CREATE, {
        organizationId,
        resourcePropertyId: createdProperty.id,
        resourceTypeId,
      });

      return params?.config?.onSuccess?.(createdProperty, mutationFuncParams);
    },
  });
