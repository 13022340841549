import _capitalize from 'lodash.capitalize';
import { FieldResponse } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import { isRequired } from '../../../utils/validators';
import { CheckboxField } from '../../fields/CheckboxField';
import { TextField } from '../../fields/TextField';
import { Tooltip } from '../../Tooltip';
import { DataTypeBase } from './DataTypeBase';

const FieldLabel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  margin: 0 0 2rem;
`;

const FieldLabelInput = styled(TextField)`
  flex-direction: column;
  width: 35rem;
  margin-bottom: 0;
` as typeof TextField;

interface GenericDataTypeProps {
  children?: React.ReactNode;
  fieldTypeName?: string;
  isRequiredHelpText?: string;
}

export function GenericDataType({
  children,
  fieldTypeName = 'field',
  isRequiredHelpText,
}: GenericDataTypeProps) {
  return (
    <DataTypeBase fieldTypeName={fieldTypeName}>
      <FieldLabel>
        <FieldLabelInput<FieldResponse['name']>
          label={`${_capitalize(fieldTypeName)} Label`}
          name="name"
          validate={isRequired}
          required
          dontSaveSpaceForErrorText
        />
        <CheckboxField<FieldResponse['isRequired']>
          label={
            <>
              Required field
              {isRequiredHelpText && <Tooltip title={isRequiredHelpText} />}
            </>
          }
          name="isRequired"
        />
      </FieldLabel>
      {!!children && children}
    </DataTypeBase>
  );
}
