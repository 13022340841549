import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import React from 'react';
import { Route, useParams } from 'react-router-dom-v5-compat';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '../../../shared/hooks/permissions/useHasPermissions';
import { EditFormTemplatePage } from './EditFormTemplatePage/EditFormTemplatePage';

export function FormTemplates() {
  const { organizationId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  return (
    <RoutesWith404>
      <Route path=":formTemplateSlug">
        {canEditOrgSettings && (
          <Route path="edit" element={<EditFormTemplatePage />} />
        )}
      </Route>
    </RoutesWith404>
  );
}
