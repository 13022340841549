import { Api } from '@monorepo/shared/apiClient';
import {
  LogCacheEvents,
  logCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

const KEY_START = 'org-dataset-sample-formula-terms';
type KeyParams = Api.FetchDatasetSampleFormulaTermsParams;

export const createKey = ({ organizationId, datasetId }: KeyParams) =>
  [KEY_START, organizationId, datasetId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchDatasetSampleFormulaTerms
>;

const fetcher: Fetcher = (_, organizationId, datasetId) =>
  Api.fetchDatasetSampleFormulaTerms({ organizationId, datasetId });

type UseDatasetSampleFormulaTermsParams =
  Partial<Api.FetchDatasetSampleFormulaTermsParams> & {
    config?: QueryConfig<
      Api.FetchDatasetSampleFormulaTermsResponse,
      Api.ErrorResponse
    >;
  };

export function useDatasetSampleFormulaTerms({
  config: inputConfig,
  datasetId,
  organizationId,
}: UseDatasetSampleFormulaTermsParams) {
  const isEnabled = !!datasetId && !!organizationId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId, datasetId }) : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    datasetId: data?.datasetId,
    datasetSampleFormulaTerms: data?.sampleFormulaTerms,
  };
}

// allows invalidating sample formula terms for all datasets in an org
type InvalidateCacheParams = Omit<KeyParams, 'datasetId'> &
  Partial<Pick<KeyParams, 'datasetId'>>;
export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.datasetId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useDatasetSampleFormulaTerms',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.UPDATE,
    ResourceTypeCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logCacheRegister(
  [LogCacheEvents.CREATE, LogCacheEvents.UPDATE, LogCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

workflowCacheRegister(
  [WorkflowCacheEvents.CREATE, WorkflowCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);
