import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../../contexts/EditViewInfoModalContext';
import { useView } from '../../../hooks/useView';
import { useViewQueryResults } from '../../../hooks/useViewQueryResults';
import { ViewResultsTable } from '../ViewResultsTable';
import { ViewDetailErrorBoundary } from './ViewDetailErrorBoundary';
import { ViewDetailToolbar } from './ViewDetailToolbar';

const Container = styled(Main)`
  ${verticalLayout}
`;

const StyledViewResultsTable = styled(ViewResultsTable)`
  ${fillHeightAndScrollable}
`;

function ViewDetailTabContents() {
  const { organizationId, pageNumber, projectId, viewId } = useParams();
  const navigate = useNavigate();
  const { view, isLoading } = useView({ organizationId, projectId, viewId });
  const {
    queryResult,
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
  } = useViewQueryResults({
    organizationId,
    projectId,
    requestedPage: Number(pageNumber),
    viewId,
  });

  const handlePageChange = useCallback(
    (newPage: number) => {
      navigate(`../../${newPage}`); // todo@workflows: make absolute when LogViews page gets removed
    },
    [navigate],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditViewInfoModalProvider>
      <Container>
        <ViewDetailToolbar />
        <StyledViewResultsTable
          isLoading={queryIsLoading || queryIsFetching}
          onPageChange={handlePageChange}
          queryResult={queryResult}
          viewName={view?.name}
        />
      </Container>
    </EditViewInfoModalProvider>
  );
}

export function ViewDetailTab() {
  return (
    <ViewDetailErrorBoundary>
      <ViewDetailTabContents />
    </ViewDetailErrorBoundary>
  );
}
