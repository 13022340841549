import { useToast } from '@monorepo/shared/contexts';
import DocumentIcon from '@svg/document.svg';
import DownloadIcon from '@svg/download.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  FormSubmissionService,
  REQUEST_PDF_SUCCEEDED_MESSAGE,
} from '../../../../services/FormSubmissionService';
import { ButtonLink, Card } from '../../../elements';

export const Covid19ResponseCard = (props) => {
  const { goToFormPath, formSubmissionExists, submissionId } = props;
  const [hasAlreadyRequestedDownload, setHasAlreadyRequestedDownload] =
    useState(false);
  const { success } = useToast();
  const menuOptions = formSubmissionExists
    ? [
        {
          label: 'Email Plan',
          icon: <DownloadIcon className="m-icon" />,
          onClick: async () => {
            await new FormSubmissionService().requestPdf(submissionId);
            success(REQUEST_PDF_SUCCEEDED_MESSAGE, {
              autoHideDuration: 10 * 1000,
            });
            setHasAlreadyRequestedDownload(true);
          },
          disabled: hasAlreadyRequestedDownload,
        },
      ]
    : [];
  return (
    <Card menuOptions={menuOptions} title="COVID-19 Response Plan Builder">
      <div className="card-icon__wrapper">
        <DocumentIcon className="m-icon card-icon__icon" />
        <ButtonLink href={goToFormPath} color="secondary">
          {formSubmissionExists
            ? 'View/Edit Response Plan'
            : 'Create Response Plan'}
        </ButtonLink>
      </div>
    </Card>
  );
};

Covid19ResponseCard.propTypes = {
  goToFormPath: PropTypes.string.isRequired,
  formSubmissionExists: PropTypes.bool.isRequired,
  submissionId: PropTypes.number.isRequired,
};
