import { useQuerySteps } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import {
  AggregationInterval,
  AggregationStep,
  QueryOperationType,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const ALL_TIME_VALUE = '';

// largest to smallest
const orderedIntervalOptions = [
  { label: 'All time', value: ALL_TIME_VALUE },
  { label: 'Year', value: AggregationInterval.YEAR },
  { label: 'Semi-Annual', value: AggregationInterval.SEMI_ANNUAL },
  { label: 'Quarter', value: AggregationInterval.QUARTER },
  { label: 'Month', value: AggregationInterval.MONTH },
  { label: 'Week', value: AggregationInterval.WEEK },
  { label: 'Day', value: AggregationInterval.DAY },
];

const StyledSelectField = styled(SelectField)`
  width: 15rem;
`;

export function AggregationIntervalSelect() {
  const { validQuerySteps } = useQuerySteps();
  const { index, dateColumn, isLastStep } = useSingleQueryStep();

  // intervals can only stay the same or increase in size as aggregation steps are added (e.g. if a previous step specifies "quarter", then
  //  a following aggregation step must allow only "quarter", "year", and "all time")
  const availableIntervalOptions = useMemo(() => {
    // if there's no date column on which to do a interval aggregation, the only option is "All time"
    if (!dateColumn) return orderedIntervalOptions.slice(0, 1);

    const previousAggregationSteps = validQuerySteps
      .slice(0, index)
      .filter(
        (step) => step.operationType === QueryOperationType.AGGREGATION,
      ) as AggregationStep[];

    // if this is the first aggregation step in the query, all options are allowed
    if (previousAggregationSteps.length === 0) return orderedIntervalOptions;

    // look at the last one to get the largest
    const largestPreviousInterval =
      previousAggregationSteps[previousAggregationSteps.length - 1]?.operation
        .groupByInterval?.interval || ALL_TIME_VALUE; // when no interval is specified for the step, that indicates all time is the selection
    return orderedIntervalOptions.slice(
      0,
      orderedIntervalOptions.findIndex(
        ({ value }) => value === largestPreviousInterval,
      ) + 1, // include the largest previous interval
    );
  }, [validQuerySteps, index, dateColumn]);

  return (
    <StyledSelectField
      disabled={!isLastStep}
      fullWidth={false}
      hiddenLabel
      label="Aggregation Period"
      name="interval"
      options={availableIntervalOptions}
    />
  );
}
