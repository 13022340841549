import { useContext } from 'react';
import WidgetDetailsContext, {
  WidgetDetailsContextValue,
} from '../../components/widgets/WidgetDetailsContext';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useWidgetDetails<S>() {
  return useContext<WidgetDetailsContextValue<S>>(WidgetDetailsContext);
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default useWidgetDetails;
