import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import { FrequencyRequirementResponse } from 'mapistry-shared';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { EditFrequencyRequirementModal } from '../EditFrequencyRequirementModal/EditFrequencyRequirementModal';
import { LogFrequencyRequirementsTable } from './LogFrequencyRequirementsTable';

export function LogFrequencyRequirementsTab() {
  const { logId, organizationId, projectId } = useParams();

  const [editModalOpen, openEditModal, closeEditModal] = useOpenClose();
  const [frequencyRequirementForEdit, setFrequencyRequirementForEdit] =
    useState<FrequencyRequirementResponse | undefined>(undefined);

  const openEntryModal = useCallback(
    (frequencyRequirement?: FrequencyRequirementResponse) => {
      setFrequencyRequirementForEdit(frequencyRequirement);
      openEditModal();
    },
    [setFrequencyRequirementForEdit, openEditModal],
  );

  return (
    <ErrorBoundary>
      <LogFrequencyRequirementsTable onEditEntry={openEntryModal} />
      {editModalOpen && (
        <EditFrequencyRequirementModal
          logId={logId}
          organizationId={organizationId}
          projectId={projectId}
          open={editModalOpen}
          onClose={closeEditModal}
          frequencyRequirementForEdit={frequencyRequirementForEdit}
        />
      )}
    </ErrorBoundary>
  );
}
