import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateTasksCache,
  TasksCacheEvents,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type Input = {
  config?: MutationConfig<Api.Task, Api.ErrorResponse>;
};

export function useSaveTask(input?: Input) {
  const mutationInfo = useMutation(Api.saveTask, {
    ...input?.config,
    onSuccess: (task, variables) => {
      invalidateTasksCache(TasksCacheEvents.UPSERT, {
        projectId: task.projectId,
      });

      return input?.config?.onSuccess?.(task, variables);
    },
  });
  return mutationInfo;
}
