import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum ResourceListCacheEvents {
  CREATE = 'CREATE',
}

interface CacheParams {
  organizationId: string;
  resourceTypeId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof ResourceListCacheEvents,
  CacheParams
>(ResourceListCacheEvents);

export const invalidateResourceListCache = cacheRegister.invalidate;

/**
 *
 * Not for individual resources, but only for the list of all resources
 */
export const resourceListCacheRegister = cacheRegister.register;
