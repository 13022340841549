import { Loading } from '@monorepo/shared/components';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../../contexts/EditViewInfoModalContext';
import { useLogsAndViewsPageContext } from '../../../contexts/LogsAndViewsPageContext';
import { useProjectViews } from '../../../hooks/useProjectViews';
import { ViewsToolbar } from '../ViewsToolbar';
import { CreateNewViewModal } from './CreateNewViewModal';
import { ProjectViewsTable } from './ProjectViewsTable';

const StyledLoading = styled(Loading)`
  margin-top: 5rem;
`;

const StyledMainContainer = styled(Main)`
  ${fillHeightAndScrollable}
  ${verticalLayout}
`;

const StyledProjectViewsTable = styled(ProjectViewsTable)`
  ${fillHeightAndScrollable}
`;

export function ProjectViewsPageContent() {
  const { organizationId, projectId } = useParams();
  const [
    createNewViewModalIsOpen,
    openCreateNewViewModal,
    closeCreateNewViewModal,
  ] = useOpenClose();
  const { setIsSearchVisible, searchFilter } = useLogsAndViewsPageContext();

  const { isLoading, projectViews } = useProjectViews({
    organizationId,
    projectId,
  });

  const hasViewsOnPage = !!(projectViews && projectViews.length > 0);
  setIsSearchVisible(hasViewsOnPage);

  if (isLoading) {
    return <StyledLoading />;
  }

  return (
    <ErrorBoundary>
      {createNewViewModalIsOpen && (
        <CreateNewViewModal
          closeModal={closeCreateNewViewModal}
          isOpen={createNewViewModalIsOpen}
        />
      )}
      <EditViewInfoModalProvider>
        <StyledMainContainer>
          <ViewsToolbar onCreateNewView={openCreateNewViewModal} />
          <StyledProjectViewsTable
            filter={searchFilter}
            projectViews={projectViews}
          />
        </StyledMainContainer>
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}
