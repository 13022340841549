import React from 'react';
import { NoWrap } from './styled';

interface Props {
  value: string;
}
export const TimeTableCell = (props: Props) => {
  const { value } = props;
  return <NoWrap>{value}</NoWrap>;
};
