import * as Sentry from '@sentry/browser';
import {
  FieldDataType,
  FieldResponse,
  FormulaFieldResponse,
  NumericFieldResponse,
  SaveFieldValueRequest,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import { RecordValue } from '../../types/flatfileTypes';
import {
  parseDateString,
  parseDateTimeString,
} from '../validationAndParsing/dateParsingHelpers';
import {
  isEmpty,
  isValidDate,
  isValidNumber,
  numericValueOrUndefinedIfEmpty,
} from '../validationAndParsing/valueValidationHelpers';
import { composeKeyForUnitField } from './getColumnsForFields';

/* ----- Preparing uploaded record to send to the server ----- */

const userFriendlyErrorMsg = `Couldn't prepare data to send to the server.`;

function getEmptyFieldValue(fieldId: string): SaveFieldValueRequest {
  return {
    fieldId,
    fieldValue: { value: null },
  };
}

function logError(
  logMsg: string,
  record: Record<string, RecordValue>,
  field?: FieldResponse,
): void {
  Sentry.captureException(logMsg, {
    extra: {
      field,
      record,
    },
  });
}

function parseToBooleanFieldValue(
  field: FieldResponse,
  record: Record<string, RecordValue>,
): SaveFieldValueRequest {
  const fieldId = field.id;
  const recordValue = record[fieldId];
  if (isEmpty(recordValue)) {
    return getEmptyFieldValue(fieldId);
  }

  if (!(recordValue === true || recordValue === false)) {
    logError(
      'Flatfile returned non-boolean value for boolean field',
      record,
      field,
    );
    throw new Error(userFriendlyErrorMsg);
  }

  return {
    fieldId,
    fieldValue: { value: recordValue },
  };
}

function parseToDateFieldValue(
  field: FieldResponse,
  record: Record<string, RecordValue>,
): SaveFieldValueRequest {
  const fieldId = field.id;
  const recordValue = record[fieldId];
  if (isEmpty(recordValue)) {
    return getEmptyFieldValue(fieldId);
  }

  if (!isValidDate(recordValue)) {
    logError(
      `Flatfile returned non-date value for ${field.type} field`,
      record,
      field,
    );
    throw new Error(userFriendlyErrorMsg);
  }

  const parsedString =
    field.type === FieldDataType.DATE
      ? parseDateString(recordValue)
      : parseDateTimeString(recordValue);
  return {
    fieldId,
    fieldValue: {
      value: UTCEquivalentOfLocal(parsedString),
    },
  };
}

function parseToNumericFieldValue(
  field: NumericFieldResponse,
  record: Record<string, RecordValue>,
): SaveFieldValueRequest {
  const fieldId = field.id;
  const recordValue = record[fieldId];
  if (!isEmpty(recordValue) && !isValidNumber(recordValue)) {
    logError(
      'Flatfile returned non-numeric value for numeric field',
      record,
      field,
    );
    throw new Error(userFriendlyErrorMsg);
  }
  const numberOrUndefined = numericValueOrUndefinedIfEmpty(recordValue);
  const nullableNumber =
    numberOrUndefined !== undefined ? numberOrUndefined : null;

  let givenUnits: string | null = null;
  if (field.units && nullableNumber !== null) {
    const unitRecordValue = record[composeKeyForUnitField(field.id)];
    if (typeof unitRecordValue !== 'string') {
      logError('Flatfile returned non-string value for units', record, field);
      throw new Error(userFriendlyErrorMsg);
    }
    givenUnits = unitRecordValue;
  }

  return {
    fieldId,
    fieldValue: {
      value: nullableNumber,
      givenUnits,
    },
  };
}

function parseToTextFieldValue(
  field: FieldResponse,
  record: Record<string, RecordValue>,
): SaveFieldValueRequest {
  const fieldId = field.id;
  const recordValue = record[fieldId];
  if (isEmpty(recordValue)) {
    return getEmptyFieldValue(fieldId);
  }

  if (typeof recordValue !== 'string') {
    logError(
      'Flatfile returned non-string value for text field',
      record,
      field,
    );
    throw new Error(userFriendlyErrorMsg);
  }

  return {
    fieldId: field.id,
    fieldValue: { value: recordValue },
  };
}

type NotFormulaField = Exclude<FieldResponse, FormulaFieldResponse>;

function isNotFormula(field: FieldResponse): field is NotFormulaField {
  return field.type !== FieldDataType.FORMULA;
}

export function parseRecordToFieldValueRequest(
  fields: FieldResponse[],
  record: Record<string, RecordValue>,
): SaveFieldValueRequest[] {
  const withoutFormulas = fields.filter<NotFormulaField>(isNotFormula);
  return withoutFormulas.map((field) => {
    const { type } = field;

    switch (type) {
      case FieldDataType.BOOLEAN:
        return parseToBooleanFieldValue(field, record);
      case FieldDataType.DATE:
      case FieldDataType.DATETIME:
        return parseToDateFieldValue(field, record);
      case FieldDataType.NUMERIC:
        return parseToNumericFieldValue(field as NumericFieldResponse, record);
      case FieldDataType.RESOURCE:
      case FieldDataType.SINGLE_SELECT:
      case FieldDataType.TEXT:
      case FieldDataType.TIME:
      case FieldDataType.USER:
        return parseToTextFieldValue(field, record);
      default:
        // eslint-disable-next-line no-case-declarations
        const msg = `Couldn't prepare data to send to the server: not allowed field type "${type}"`;
        logError(msg, record);
        throw new Error(msg);
    }
  });
}
