import { Loading } from '@monorepo/shared/components/Loading';
import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useResourceTypes } from '@monorepo/shared/hooks/resources/useResourceTypes';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import { format } from 'date-fns';
import { ResourceTypeListResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { ResourceTypeActionMenu } from './ResourceTypeActionMenu';

const StyledTable = styled(Table)`
  padding-bottom: 4rem;
` as typeof Table;

type ViewResourceTypesTableProps = {
  className?: string;
  filterBy: string;
  onAddNew: React.MouseEventHandler;
};

export function ViewResourceTypesTable({
  filterBy,
  onAddNew,
  className,
}: ViewResourceTypesTableProps) {
  const { organizationId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const { resourceTypes, isLoading } = useResourceTypes({
    organizationId,
  });
  const sortedResourceTypes = useMemo(
    () =>
      resourceTypes
        ? resourceTypes.sort((r1, r2) =>
            r1.name.toLowerCase() > r2.name.toLowerCase() ? 1 : -1,
          )
        : [],
    [resourceTypes],
  );

  const columns: TableColumn<ResourceTypeListResponse>[] = useMemo(
    () => [
      {
        id: 'name',
        header: 'Resource Type',
        /* TODO: Fix this the next time the file is edited */
        /* eslint-disable-next-line react/no-unstable-nested-components */
        contents: (row) => (
          <Link to={`${row.id}/resources`} color="grey">
            {row.name}
          </Link>
        ),
        width: '40%',
      },
      {
        id: 'numResources',
        header: 'Items',
        contents: 'numberOfResources',
        align: 'center',
      },
      {
        id: 'createdAt',
        header: 'Created',
        contents: (row) => format(new Date(row.createdAt), 'MM/dd/yy HH:mm'),
      },
      {
        id: 'actions',
        header: 'Actions',
        /* TODO: Fix this the next time the file is edited */
        /* eslint-disable-next-line react/no-unstable-nested-components */
        contents: (row) =>
          organizationId ? (
            <ResourceTypeActionMenu
              organizationId={organizationId}
              resourceType={row}
            />
          ) : (
            ''
          ),
        align: 'center',
        width: '10%',
      },
    ],
    [organizationId],
  );

  const filterRowsBy = useCallback(
    (row) => lookFor(filterBy, row, ['name']),
    [filterBy],
  );

  if (isLoading) {
    return <Loading />;
  }

  const hasResourceTypes = !!sortedResourceTypes?.length;
  const aboutResourceType =
    'Resources help you organize items with static properties that can be used in various ways across Logs and sites.';

  return (
    <Main className={className}>
      {hasResourceTypes ? (
        <StyledTable
          ariaLabel="Resource Types"
          columns={columns}
          rowFilter={filterRowsBy}
          rows={sortedResourceTypes}
        />
      ) : (
        <EmptyState
          actionButtonConfigs={
            canEditOrgSettings
              ? [{ label: 'Create new Resource type', onClick: onAddNew }]
              : []
          }
          primaryText="There are no Resource types configured for this organization."
          secondaryText={
            canEditOrgSettings
              ? aboutResourceType
              : `${aboutResourceType} Contact your organization administrator to configure a Resource type.`
          }
        />
      )}
    </Main>
  );
}
