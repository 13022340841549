import { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { LinkEnd } from './types';

type ProjectSpecificLink<PathEnd extends LinkEnd> =
  `/vortex/organizations/${string}/sites/${string}${PathEnd}`;

type GetLinkOnSameProject = <PathEnd extends LinkEnd>(
  pathEnd: PathEnd,
) => ProjectSpecificLink<PathEnd>;

interface ProjectSpecificLinkProps<PathEnd> {
  projectId: string;
  organizationId: string;
  pathEnd: PathEnd;
}

function getProjectSpecificLink<PathEnd extends LinkEnd>(
  props: ProjectSpecificLinkProps<PathEnd>,
): ProjectSpecificLink<PathEnd> {
  const { projectId, organizationId, pathEnd } = props;
  return `/vortex/organizations/${organizationId}/sites/${projectId}${pathEnd}`;
}

function useProjectAndOrgFromPath() {
  const { projectId, organizationId } = useParams();

  if (!organizationId || !projectId) {
    throw new Error(
      "useProjectAndOrgFromPath page was used from a url that doesn't have organizationId or projectId",
    );
  }

  return { projectId, organizationId };
}

/** To help easily navigate between different sub pages on the same project, while avoiding relative links. */
export const useLinkOnSameProject: GetLinkOnSameProject = (pathEnd) => {
  const { projectId, organizationId } = useProjectAndOrgFromPath();
  return getProjectSpecificLink({ projectId, organizationId, pathEnd });
};

/** To help create dynamic links to navigate between different sub pages on the same project, while avoiding relative links. */
export function useDynamicLinkOnSameProject() {
  const { projectId, organizationId } = useProjectAndOrgFromPath();

  const getLinkOnSameProject = useCallback<GetLinkOnSameProject>(
    (pathEnd) => getProjectSpecificLink({ organizationId, projectId, pathEnd }),
    [organizationId, projectId],
  );

  return { getLinkOnSameProject };
}
