import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Input } from '../Input';

type TextFieldProps<ValueType> = Omit<
  React.ComponentProps<typeof Input>,
  'value' | 'onChange' | 'error' | 'errorText' | 'inputComponent'
> & {
  initialValue?: ValueType;
  validate?: UseFieldConfig<ValueType>['validate'];
};

export const TextField = <ValueType extends string = string>({
  initialValue,
  name,
  validate,
  ...rest
}: TextFieldProps<ValueType>) => {
  const { input, meta } = useField<ValueType>(name, { validate, initialValue });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <Input
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      name={name}
      onChange={input.onChange}
      value={input.value}
    />
  );
};
