import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { ListBuilder } from '../ListBuilder';

type ListBuilderFieldProps<ValueType> = Omit<
  React.ComponentProps<typeof ListBuilder>,
  'onChange' | 'value'
> & {
  isDisabled?: boolean;
  isRequired?: boolean;
  validate?: UseFieldConfig<ValueType>['validate'];
};

export const ListBuilderField = <ValueType extends string[]>({
  isDisabled = false,
  isRequired = false,
  name,
  validate,
  ...rest
}: ListBuilderFieldProps<ValueType>) => {
  const { input, meta } = useField<ValueType>(name, { validate });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <ListBuilder
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      onChange={input.onChange}
      value={input.value}
    />
  );
};
