import { Button } from '@monorepo/shared/componentsV2/Button';
import { Input } from '@monorepo/shared/componentsV2/Input';
import { Main } from '@monorepo/shared/componentsV2/Main';
import {
  contentPadding,
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const StyledMain = styled(Main)`
  ${verticalLayout}
  ${page}
`;

export const BackButton = styled(Button)`
  min-width: 2rem;

  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const ButtonContainer = styled.div`
  min-width: 4.25rem;
`;

export const Container = styled.div`
  ${contentPadding}
  ${fillHeightAndScrollable}
`;

export const ComplianceContainer = styled.div`
  & label.MuiFormLabel-root {
    ${header4}
  }
`;

export const ComplianceInput = styled(Input)`
  min-width: 18rem;
`;

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 38rem;
  margin: 0 0 1rem;
`;

export const FieldsSectionLabel = styled.div`
  ${header4}
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.gray333};
`;

export const FieldContainer = styled.ul`
  padding: 0;
  margin: 0;
`;

export const InstructionsContainer = styled.div`
  padding: 0;
  margin: 0 0 0.5rem;
  color: ${({ theme }) => theme.colors.gray333};
  white-space: pre-wrap;
`;
