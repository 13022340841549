import { ComboboxField } from '@monorepo/shared/componentsV2/fields/ComboboxField';
import { DatePickerField } from '@monorepo/shared/componentsV2/fields/DatePickerField';
import { DatetimePickerField } from '@monorepo/shared/componentsV2/fields/DatetimePickerField';
import { RadioGroupField } from '@monorepo/shared/componentsV2/fields/RadioGroupField';
import { TimePickerField } from '@monorepo/shared/componentsV2/fields/TimePickerField';
import {
  StyledInput,
  StyledSelect,
} from '@monorepo/shared/componentsV2/styled/form';
import { isDate } from 'date-fns';
import { NullableDate, NullableString } from 'mapistry-shared';

// the type of the potential values we'll get from the form are derived from the types
//  of the components that could make up the form
type FormValue =
  | React.ComponentProps<typeof StyledInput>['initialValue']
  | React.ComponentProps<typeof RadioGroupField>['initialValue']
  | React.ComponentProps<typeof StyledSelect>['initialValue']
  | React.ComponentProps<typeof DatetimePickerField>['initialValue']
  | React.ComponentProps<typeof DatePickerField>['initialValue']
  | React.ComponentProps<typeof TimePickerField>['initialValue']
  | React.ComponentProps<typeof ComboboxField>['initialValue'];

export type FormValues = Record<string, FormValue>;

export function isNullableString(x: unknown): x is NullableString {
  return typeof x === 'string' || x === null;
}

export function isNullableDate(x: unknown): x is NullableDate {
  return isDate(x) || x === null;
}
