import { Button } from '@monorepo/shared/componentsV2/Button';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { ModalFormInstructions } from '@monorepo/shared/componentsV2/modals/contents/ModalFormInstructions';
import {
  StyledFormGroup,
  StyledInput,
  StyledTextArea,
} from '@monorepo/shared/componentsV2/styled/form';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { isRequired } from '@monorepo/shared/utils/validators';
import * as Sentry from '@sentry/browser';
import {
  CreateEdpViewRequest,
  EdpViewResponse,
  UpdateEdpViewRequest,
  ViewType,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useViewCreate } from '../../hooks/useViewCreate';
import { useViewUpdate } from '../../hooks/useViewUpdate';
import { EditEdpView } from '../../types';
import { filterIncompleteQuerySteps } from '../../validate';

export function getError(values: EditEdpView) {
  // any error thrown in this function is unexpected - the UI should've protected against these
  const errMsg = `Tried to save view without all required view request properties`;
  Sentry.captureException(errMsg, { extra: { values } });
  return new Error(errMsg);
}

function mapFormValuesToRequestDto(
  values: EditEdpView,
  originId: string,
  viewType: ViewType,
): CreateEdpViewRequest | UpdateEdpViewRequest {
  // this should always be defined by the time the form is getting submitted
  if (!values.name) throw getError(values);
  const querySteps = filterIncompleteQuerySteps(values.querySteps || []);
  return {
    name: values.name,
    description: values.description || null,
    querySteps,
    originId,
    type: viewType,
  };
}

interface ViewInfoModalProps {
  isLoading: boolean;
  onClose: () => void;
  view?: EditEdpView;
  originId: string;
  viewType: ViewType;
}

export function EditViewInfoModal({
  isLoading,
  onClose,
  view,
  originId,
  viewType,
}: ViewInfoModalProps) {
  const { organizationId, projectId } = useParams();
  const navigate = useNavigate();
  const [creator] = useViewCreate({ config: { throwOnError: true } });
  const [updater] = useViewUpdate({ config: { throwOnError: true } });

  const viewExists = !!view?.id;

  const isUpdateRequest = useCallback(
    (
      x: CreateEdpViewRequest | UpdateEdpViewRequest,
    ): x is UpdateEdpViewRequest => !!view && 'id' in view && view.id != null,
    [view],
  );
  const isCreateRequest = useCallback(
    (
      x: CreateEdpViewRequest | UpdateEdpViewRequest,
    ): x is CreateEdpViewRequest => !isUpdateRequest(x),
    [isUpdateRequest],
  );

  const { showUserFriendlyErrorToast, success: showSuccess } = useToast();
  const onSubmit = useCallback(
    async (values: EditEdpView) => {
      try {
        if (!organizationId || !projectId) {
          throw new Error(
            `Couldn't create View: This page is missing an organization id or a site id.`,
          );
        }
        if (view?.originId && view.originId !== originId) {
          throw new Error(
            "Passed in originId does not match existing view's originId.",
          );
        }

        const requestDto = mapFormValuesToRequestDto(
          values,
          originId,
          viewType,
        );
        let createdView: EdpViewResponse | undefined;

        if (isUpdateRequest(requestDto)) {
          if (!view?.id) {
            throw new Error(`Couldn't update View: No id found`);
          }
          await updater({
            organizationId,
            projectId,
            view: requestDto,
            viewId: view.id,
          });
        }

        if (isCreateRequest(requestDto)) {
          createdView = await creator({
            organizationId,
            projectId,
            view: requestDto,
          });
        }
        showSuccess(
          `${values.name} has been ${viewExists ? 'edited.' : 'created.'}`,
        );
        onClose();

        // We only create a view from the All Views table. Navigate automatically to editing the view
        // NOTE: This means that this modal is currently tightly coupled to where it is rendered. We'd
        // like to add a utility to navigate to specific sub-routes more easily in the near future
        if (createdView) {
          navigate(`../${createdView.id}/edit`);
        }
      } catch (error) {
        showUserFriendlyErrorToast(error, `Unable to save ${values.name}.`);
      }
    },
    [
      creator,
      isCreateRequest,
      isUpdateRequest,
      navigate,
      onClose,
      organizationId,
      projectId,
      showUserFriendlyErrorToast,
      showSuccess,
      updater,
      view?.id,
      view?.originId,
      originId,
      viewExists,
      viewType,
    ],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Form<EditEdpView>
      initialValues={view}
      onSubmit={onSubmit}
      subscription={{
        invalid: true,
        submitting: true,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <BaseModal
            buttons={
              <>
                <Button color="primary" onClick={onClose} type="button">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={submitting}
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </>
            }
            onClose={onClose}
            open
            showCloseButton
            title={`${viewExists ? 'Edit' : 'Save your'} View`}
          >
            <ModalFormInstructions>
              Give your View a name and description.
            </ModalFormInstructions>
            <StyledFormGroup row>
              <StyledInput<NonNullable<EditEdpView['name']>>
                label="View Name"
                name="name"
                placeholder="Name"
                required
                validate={isRequired}
              />
            </StyledFormGroup>
            <StyledFormGroup row>
              <StyledTextArea<NonNullable<EditEdpView['description']>>
                label="Description"
                multiline
                name="description"
              />
            </StyledFormGroup>
          </BaseModal>
        </form>
      )}
    </Form>
  );
}
