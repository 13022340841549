import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum LogFieldCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  logFieldId: string;
  logId: string;
  organizationId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof LogFieldCacheEvents,
  CacheParams
>(LogFieldCacheEvents);

export const invalidateLogFieldCache = cacheRegister.invalidate;
export const logFieldCacheRegister = cacheRegister.register;
