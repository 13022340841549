import { MathUnit } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import { ComboboxField } from '../../fields/ComboboxField';
import { GenericDataType } from './GenericDataType';

const Units = styled(ComboboxField)`
  max-width: 17.5rem;
` as typeof ComboboxField;

type NumericTypeProps = Omit<
  React.ComponentProps<typeof GenericDataType>,
  'children'
>;

const sortedUnitsList = MathUnit.selectableList.sort((a, b) =>
  a.label.localeCompare(b.label),
);

export function NumericType(props: NumericTypeProps) {
  return (
    <GenericDataType {...props}>
      <Units label="Units" name="units" options={sortedUnitsList} />
    </GenericDataType>
  );
}
