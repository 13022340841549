import { BaseEditEntryModal } from '@monorepo/shared/componentsV2/logs/BaseEditEntryModal';
import { useResource } from '@monorepo/shared/hooks/resources/useResource';
import { useResourceTypeWithProperties } from '@monorepo/shared/hooks/resources/useResourceTypeWithProperties';
import { ResourceResponse, SaveFieldValueRequest } from 'mapistry-shared';
import React from 'react';
import { useResourceCreate } from '../../hooks/useResourceCreate';
import { useResourceUpdate } from '../../hooks/useResourceUpdate';
import { EditResourceBuiltInFields } from './EditResourceBuiltInFields';

type EditResourceModalProps = {
  organizationId?: string;
  resourceTypeId?: string;
  resourceId?: string;
  open: boolean;
  onClose: () => void;
};

export function EditResourceModal({
  organizationId,
  resourceTypeId,
  resourceId,
  open,
  onClose,
}: EditResourceModalProps) {
  const {
    resourceType,
    resourceProperties,
    isLoading: isLoadingResourceType,
  } = useResourceTypeWithProperties({
    organizationId,
    resourceTypeId,
  });

  const { resource: existingResource, isLoading: isLoadingResource } =
    useResource({
      organizationId,
      resourceTypeId,
      resourceId,
    });

  const [creator] = useResourceCreate({ config: { throwOnError: true } });
  const [updator] = useResourceUpdate({ config: { throwOnError: true } });

  const isLoading = isLoadingResource || isLoadingResourceType;

  const isAllDataLoaded =
    !!resourceProperties &&
    !!resourceType &&
    (!resourceId || !!existingResource);

  if (!resourceTypeId || !organizationId) {
    throw new Error(
      'Unexpected state: Resource modal is missing organization or Resource type context',
    );
  }

  return (
    <BaseEditEntryModal<ResourceResponse>
      entryId={resourceId}
      entryTypeDisplayName="Resource"
      fields={resourceProperties}
      fieldValues={existingResource?.propertyValues}
      getEntryName={(resource: ResourceResponse) => resource.name}
      isLoading={isLoading}
      onClose={onClose}
      open={open}
      organizationId={organizationId}
      projectId={undefined} // this modal isn't scoped to a single project
      saver={(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formValues: Record<string, any>,
        fieldValues: SaveFieldValueRequest[],
      ) => {
        const resource = {
          name: formValues.name,
          propertyValues: fieldValues,
          projectId: formValues.projectId,
        };
        return resourceId
          ? updator({
              organizationId,
              resourceTypeId,
              resourceId,
              resource,
            })
          : creator({ organizationId, resourceTypeId, resource });
      }}
    >
      {open && !isLoading && isAllDataLoaded && (
        <EditResourceBuiltInFields
          {...{ organizationId, existingResource, resourceType }}
        />
      )}
    </BaseEditEntryModal>
  );
}
