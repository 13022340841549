import { WysiwygEditor } from '@monorepo/shared/componentsV2/WysiwgEditor';
import { isRequired } from '@monorepo/shared/utils/validators';
import React, { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import styled from 'styled-components';
import { ModalFieldWrapper, ModalTextField } from '../styled';

export const ModalQuillWrapper = styled.div`
  max-width: 50rem;
  margin: 0 0 0.1rem;

  & .quill {
    display: block;
    width: 100%;

    & .ql-editor {
      min-height: 10rem;
    }
  }
`;

// this is rendered within FinalForm so the inputs are managed by it
export function EditExplanationAreaFieldForm() {
  const { input: helpText } = useField('helpText');
  const [value, setValue] = useState(helpText.value);
  const form = useForm();

  const changeValue = useCallback(
    (newValue) => {
      const fieldState = form.getFieldState('helpText');
      if (fieldState) fieldState.change(newValue);
    },
    [form],
  );

  useEffect(() => {
    changeValue(value);
  }, [value, changeValue]);

  return (
    <>
      <ModalFieldWrapper>
        <ModalTextField
          label="Field Name"
          name="name"
          validate={isRequired}
          required
          tooltipText="The name of an explanation area field will not be shown to users when completing a form submission. You will only see this name when editing the form template."
          dontSaveSpaceForErrorText
        />
      </ModalFieldWrapper>
      <ModalQuillWrapper>
        <WysiwygEditor
          value={value}
          setValue={setValue}
          label="Explanation Area"
          toolTipText="Saving this field may remove more complex formatting in order to make the Explanation compatible with mobile display.  If you need special web-only formatting, please contact Customer Service."
        />
        <input type="hidden" name="helpText" />
      </ModalQuillWrapper>
    </>
  );
}
