import { defaultErrorMsg } from '@monorepo/shared/apiClient/ServerErrorHandler';
import { useToast } from '@monorepo/shared/contexts';
import { useResourceProperties } from '@monorepo/shared/hooks/resources/useResourceProperties';
import { useResourceType } from '@monorepo/shared/hooks/resources/useResourceType';
import { useEffect } from 'react';

export function useResourceTypeAssetsOrShowError(
  organizationId?: string,
  resourceTypeId?: string,
) {
  const {
    resourceType,
    isLoading: isResourceTypeLoading,
    error: resourceTypeLoadingError,
  } = useResourceType({
    organizationId,
    resourceTypeId,
    // this hook handles errors on its own
    config: { useErrorBoundary: false },
  });
  const {
    resourceProperties,
    isLoading: isPropertiesLoading,
    error: propertiesLoadingError,
  } = useResourceProperties({
    organizationId,
    resourceTypeId,
    config: { useErrorBoundary: false },
  });
  const isLoading = isResourceTypeLoading || isPropertiesLoading;
  const isError = !!resourceTypeLoadingError || !!propertiesLoadingError;

  const { showUserFriendlyErrorToast } = useToast();
  useEffect(() => {
    if (isError) {
      showUserFriendlyErrorToast(
        propertiesLoadingError ||
          resourceTypeLoadingError || { data: defaultErrorMsg },
      );
    }
  }, [
    isError,
    propertiesLoadingError,
    resourceTypeLoadingError,
    showUserFriendlyErrorToast,
  ]);

  return {
    isError,
    isLoading,
    resourceProperties,
    resourceType,
  };
}
