import { Skeleton } from '@material-ui/lab';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { focusOutline } from '@monorepo/shared/styles/focus';
import styled from 'styled-components';

export const FullCalendarCustomStyles = styled.div`
  margin-bottom: 0.5rem;

  & .fc .fc-daygrid-day-number,
  & .fc-col-header-cell-cushion {
    /*  while the date numbers are not clickable, do not make them appear like they are */
    pointer-events: none;
  }

  & .fc-popover {
    /* don't cover our own modals with the more events popover */
    z-index: 2;
  }

  & .fc-more-link:focus-visible {
    ${focusOutline()}
  }

  & .more-link-is-loading {
    display: none;
  }

  & .loading-indicator {
    /*  avoid hover on loading skeletons */
    pointer-events: none;
  }
`;

export const CalendarHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarNavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: start;
  width: 100%;
`;

export const CalendarNavigation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
`;

export const TodayButton = styled(Button)`
  flex-grow: 1;
  max-width: 4rem;
  padding: 0;
`;

export const PreviousButton = styled(IconButton)`
  flex-grow: 1;
  max-width: 3.5rem;
  padding: 0;
  margin-left: 1.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:focus-visible {
    z-index: 1;
  }
`;

export const NextButton = styled(IconButton)`
  flex-grow: 1;
  max-width: 3.5rem;
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:focus-visible {
    ${focusOutline()}
  }

  &.Mui-disabled {
    /* allow hovering over the button when it's disabled so the tooltip can be accessed */
    pointer-events: auto;

    &:hover {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const StyledCalendarEvent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin-left: 5%;
`;

export const CalendarEventTitle = styled.div`
  line-height: 1rem;
`;

export const EmptyViewNotification = styled.div`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.gray666};
`;
