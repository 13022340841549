import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { SelectResourceType } from '../SelectResourceType';

type SelectResourceTypeFieldProps<ValueType> = React.ComponentProps<
  typeof SelectResourceType
> & {
  name: string;
  validate?: UseFieldConfig<ValueType>['validate'];
};

export const SelectResourceTypeField = <ValueType extends string = string>({
  name,
  validate,
  ...rest
}: SelectResourceTypeFieldProps<ValueType>) => {
  const { input, meta } = useField<ValueType>(name, { validate });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <SelectResourceType
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      name={name}
      onChange={input.onChange}
      value={input.value}
    />
  );
};
