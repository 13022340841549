import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import format from 'date-fns/format';

const LogChoiceMenu = (props) => {
  const { logs, menuAnchor, onClose, onEventClick } = props;

  return (
    <Menu
      anchorEl={menuAnchor}
      className="mapistry-menu"
      onClose={onClose}
      open={!!menuAnchor}
    >
      {logs.map((log) => (
        <MenuItem
          key={log.datetime.toISOString()}
          onClick={() => onEventClick(log)}
        >
          <span className="event-choice-menu-item-text">
            {log.isDefault
              ? 'Add a log'
              : `${format(log.datetime, 'MMM d, yyyy | HH:mm')}`}
          </span>
        </MenuItem>
      ))}
    </Menu>
  );
};

LogChoiceMenu.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({})),
  menuAnchor: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
  onEventClick: PropTypes.func.isRequired,
};

LogChoiceMenu.defaultProps = {
  logs: [],
  menuAnchor: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LogChoiceMenu;
