import {
  GroupByFilter,
  LimitDefinition,
  NullableMathUnitName,
} from 'mapistry-shared';

export enum LimitType {
  min = 'min',
  max = 'max',
  range = 'range',
}

export type FormValueLimitCondition = {
  groupByFilters?: GroupByFilter[];
  max?: string;
  min?: string;
  units?: NullableMathUnitName;
};

export type FormValues = Omit<LimitDefinition, 'limitConditions'> & {
  limitConditions: FormValueLimitCondition[];
  limitType: LimitType;
};
