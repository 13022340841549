import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import { EditWorkflowPage } from '@monorepo/workflowDataSetup/src/components/EditWorkflowPage/EditWorkflowPage';
import { EditWorkflowStepsPage } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage';
import { useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { RouteEditMode, WizardStep, WizardStepUrlPart } from './types';
import { WorkflowListPage } from './WorkflowListPage';

export function WorkflowDataSetup() {
  const { areWorkflowsEnabled, isData2Enabled } = useFeatureFlags();

  return isData2Enabled && areWorkflowsEnabled ? (
    <RoutesWith404>
      <Route
        index
        element={
          <ErrorBoundary>
            <WorkflowListPage />
          </ErrorBoundary>
        }
      />
      <Route path={RouteEditMode.NEW}>
        <Route
          index
          element={
            <ErrorBoundary>
              <EditWorkflowPage />
            </ErrorBoundary>
          }
        />
      </Route>
      <Route path=":workflowId">
        <Route path=":mode">
          <Route
            index
            element={
              <Navigate replace to={WizardStepUrlPart[WizardStep.DETAILS]} />
            }
          />
          <Route path={WizardStepUrlPart[WizardStep.DETAILS]}>
            <Route
              index
              element={
                <ErrorBoundary>
                  <EditWorkflowPage />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            path={WizardStepUrlPart[WizardStep.STEPS]}
            element={
              <ErrorBoundary>
                <EditWorkflowStepsPage />
              </ErrorBoundary>
            }
          />
        </Route>
      </Route>
    </RoutesWith404>
  ) : null;
}
