import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateWorkflowCache,
  WorkflowCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type UseWorkflowStepCreateParams = {
  config?: MutationConfig<Api.CreateWorkflowStepResponse, Api.ErrorResponse>;
};

export const useWorkflowStepCreate = (params?: UseWorkflowStepCreateParams) =>
  useMutation(Api.createWorkflowStep, {
    ...params?.config,
    onSuccess: (createdWorkflowStep, mutationFuncParams) => {
      const { organizationId, workflowId } = mutationFuncParams;
      const keyParams = { organizationId, workflowId };

      invalidateWorkflowCache(WorkflowCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(
        createdWorkflowStep,
        mutationFuncParams,
      );
    },
  });
