import { Api } from '@monorepo/shared/apiClient';
import {
  CalculatedValue,
  CalculatedValueResourceType,
  GenericLogLoggedItemType,
  GenericLogType,
  LimitItemResourceType,
  RollingCalculation,
} from 'mapistry-shared';
import { useMemo } from 'react';
import { useCalculatedValues } from './useCalculatedValues';
import { useFactors } from './useFactors';
import { useLoggedItems } from './useLoggedItems';
import { useRollingCalculations } from './useRollingCalculations';

interface UseResourcesProps
  extends Api.FetchCalculatedValuesProps,
    Api.FetchFactorsProps,
    Api.FetchLoggedItemsProps,
    Api.FetchRollingCalculationsProps {
  resourceTypes: (CalculatedValueResourceType | LimitItemResourceType)[];
}

export type CalculatedValueResource = CalculatedValue & {
  logSettingType: GenericLogType.CALCULATED_VALUE;
};
export type FactorResource = Api.Factor & {
  logSettingType: GenericLogType.EMISSION_FACTOR;
};
export type LoggedItemResource = Api.LoggedItem & {
  logSettingType: GenericLogType.LOGGED_ITEM;
};
export type RollingCalculationResource = RollingCalculation & {
  logSettingType: GenericLogType.ROLLING_CALCULATION;
};

export type Resource =
  | CalculatedValueResource
  | FactorResource
  | LoggedItemResource
  | RollingCalculationResource;

interface ResourceIdMap {
  [resourceId: string]: Resource;
}

export const useResources = ({
  resourceTypes,
  ...params
}: UseResourcesProps) => {
  const {
    calculatedValues: rawCalculatedValues,
    isLoading: calculatedValuesAreLoading,
  } = useCalculatedValues({
    ...params,
    config: {
      enabled: resourceTypes.includes(GenericLogType.CALCULATED_VALUE),
    },
  });
  const calculatedValues = rawCalculatedValues?.map<CalculatedValueResource>(
    (item) => ({
      ...item,
      logSettingType: GenericLogType.CALCULATED_VALUE,
    }),
  );
  const { factors: rawFactors, isLoading: factorsAreLoading } = useFactors({
    ...params,
    config: {
      enabled: resourceTypes.includes(GenericLogType.EMISSION_FACTOR),
    },
  });
  const factors = rawFactors?.map<FactorResource>((item) => ({
    ...item,
    logSettingType: GenericLogType.EMISSION_FACTOR,
  }));
  // Filter out non-numeric logged items
  const { loggedItems: rawLoggedItems, isLoading: loggedItemsAreLoading } =
    useLoggedItems({
      ...params,
      config: {
        enabled: resourceTypes.includes(GenericLogType.LOGGED_ITEM),
      },
    });
  const loggedItems = rawLoggedItems
    ?.filter((item) => item.itemType === GenericLogLoggedItemType.NUMBER)
    ?.map<LoggedItemResource>((item) => ({
      ...item,
      logSettingType: GenericLogType.LOGGED_ITEM,
    }));
  const {
    rollingCalculations: rawRollingCalculations,
    isLoading: rollingCalculationsAreLoading,
  } = useRollingCalculations({
    ...params,
    config: {
      enabled: resourceTypes.includes(GenericLogType.ROLLING_CALCULATION),
    },
  });
  const rollingCalculations =
    rawRollingCalculations?.map<RollingCalculationResource>((item) => ({
      ...item,
      logSettingType: GenericLogType.ROLLING_CALCULATION,
    }));

  const resources = useMemo(
    () => [
      ...(calculatedValues ?? []),
      ...(factors ?? []),
      ...(loggedItems ?? []),
      ...(rollingCalculations ?? []),
    ],
    [calculatedValues, factors, loggedItems, rollingCalculations],
  );

  const resourceIdMap = useMemo<ResourceIdMap>(
    () =>
      resources.reduce((acc, resource) => {
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        acc[resource.id] = resource;
        return acc;
      }, {}),
    [resources],
  );

  return {
    isLoading:
      calculatedValuesAreLoading ||
      factorsAreLoading ||
      loggedItemsAreLoading ||
      rollingCalculationsAreLoading,
    resources,
    resourceIdMap,
  };
};
