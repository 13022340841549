import ArrowBack from '@material-ui/icons/ArrowBack';
import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { GenericError } from '@monorepo/shared/componentsV2/errorDisplays/GenericError';
import { fieldValueDisplayFactory } from '@monorepo/shared/componentsV2/fieldDataType/values/fieldValueDisplayFactory';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useResource } from '@monorepo/shared/hooks/resources/useResource';
import { useResourceTypeWithProperties } from '@monorepo/shared/hooks/resources/useResourceTypeWithProperties';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { bodySmall, header4 } from '@monorepo/shared/styles/text';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditResourceModal } from '../EditResourceModal';

const Page = styled(Main)`
  ${verticalLayout}
  ${page}
`;

const TitleContainer = styled.div`
  padding: 1.5rem 1rem 1rem;
`;

const H2 = styled.h2`
  ${header4}
  padding-bottom: 0.5rem;
  margin: 0;
`;

const Subtext = styled.div`
  ${bodySmall}
  color: ${({ theme }) => theme.colors.gray666};
`;

const Content = styled.div`
  ${fillHeightAndScrollable};
`;

const StyledTable = styled(Table)`
  padding-bottom: 4rem;
` as typeof Table;

const NameText = styled.div`
  color: ${({ theme }) => theme.colors.gray333};
`;

type ResourcePropertyRow = {
  id: string;
  name: string;
  displayedValue: React.ReactNode;
};

const columns: TableColumn<ResourcePropertyRow>[] = [
  {
    id: 'name',
    header: 'Property',
    contents: (row) => <NameText>{row.name}</NameText>,
  },
  {
    id: 'value',
    header: 'Value',
    contents: 'displayedValue',
  },
];

function ResourceDetailsContent() {
  const { resourceTypeId, organizationId, resourceId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const navigate = useNavigate();
  const [editModalOpen, openEditModal, closeEditModal] = useOpenClose();

  const { resource, isLoading: isLoadingResource } = useResource({
    organizationId,
    resourceTypeId,
    resourceId,
  });

  const {
    resourceType,
    resourceProperties,
    isLoading: isLoadingResourceType,
  } = useResourceTypeWithProperties({
    organizationId,
    resourceTypeId,
  });

  if (isLoadingResource || isLoadingResourceType) {
    return <Loading />;
  }

  if (!resource || !resourceType || !resourceProperties) {
    return <GenericError />;
  }

  const rows = [
    ...(resourceType.isSiteSpecific
      ? [
          {
            id: 'associated-site',
            name: 'Site Association',
            displayedValue: resource.project?.name || '',
          },
        ]
      : []),
    ...resourceProperties.map((prop) => {
      const fieldValue = resource.propertyValues[prop.id]?.fieldValue;
      return {
        id: prop.id,
        name: prop.name,
        displayedValue: fieldValue
          ? fieldValueDisplayFactory({
              fieldType: prop.type,
              fieldValue,
            })
          : '',
      };
    }),
  ];

  const addResourcesSubtext = canEditOrgSettings
    ? 'You may adjust the values associated with these properties by clicking to edit Resource values.'
    : 'Ask your organization administrator to adjust the values.';

  return (
    <>
      <Page>
        <Header
          variant="bottomBorder"
          title={resource.name || ''}
          leftActions={
            <IconButton onClick={() => navigate('../')}>
              <ArrowBack />
              <VisuallyHidden>Back</VisuallyHidden>
            </IconButton>
          }
          rightActions={
            canEditOrgSettings ? (
              <Button
                variant="contained"
                color="primary"
                onClick={openEditModal}
              >
                Edit Resource values
              </Button>
            ) : null
          }
        />
        <Content>
          <TitleContainer>
            <H2>Resource Properties</H2>
            <Subtext>
              These properties are defined on the Resource type and cannot be
              changed at the Resource level. <br />
              {addResourcesSubtext}
            </Subtext>
          </TitleContainer>
          <StyledTable
            ariaLabel={`${resource.name} property values`}
            columns={columns}
            rows={rows}
          />
        </Content>
      </Page>
      {/* this makes sure the modal is created fresh each time its opened & skips performing unnecessary logic */}
      {editModalOpen && (
        <EditResourceModal
          organizationId={organizationId}
          resourceTypeId={resourceTypeId}
          resourceId={resourceId}
          open={editModalOpen}
          onClose={closeEditModal}
        />
      )}
    </>
  );
}

export const ResourceDetails = () => (
  <ErrorBoundary>
    <ResourceDetailsContent />
  </ErrorBoundary>
);
