import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import { ViewType } from 'mapistry-shared';
import React, { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { EditViewInfoModal } from '../components/Views/EditViewInfoModal';
import { useView } from '../hooks/useView';
import { EditEdpView } from '../types';

type OpenEditViewInfoModalArgs = {
  existingViewId: string | undefined;
  viewOriginId: string;
  viewType: ViewType;
  editedSteps?: EditEdpView['querySteps'];
};

type EditViewInfoModalContext = {
  open: boolean;
  openModal: (args: OpenEditViewInfoModalArgs) => void;
  closeModal: () => void;
};

export const EditViewInfoModalContext = createContext<
  EditViewInfoModalContext | undefined
>(undefined);

type EditViewInfoModalProviderProps = {
  children: React.ReactNode;
};

export const EditViewInfoModalProvider = ({
  children,
}: EditViewInfoModalProviderProps) => {
  const { organizationId, projectId } = useParams();
  if (!organizationId || !projectId) {
    throw new Error(
      "EditViewInfoModalContent component was rendered in a page with url that doesn't have projectId or organizationId",
    );
  }

  const [isOpen, open, close] = useOpenClose();

  // these pieces of state get all either set or unset together. (Could do a useReducer but...time.)
  const [existingViewId, setExistingViewId] = useState<string>();
  const [originId, setOriginId] = useState<string>();
  const [viewType, setViewType] = useState<ViewType>();
  const [editedViewSteps, setEditedViewSteps] =
    useState<EditEdpView['querySteps']>();

  const { view, isLoading } = useView({
    organizationId,
    projectId,
    viewId: existingViewId,
  });

  const contextValue = useMemo(
    () => ({
      closeModal: () => {
        close();
        setExistingViewId(undefined);
        setOriginId(undefined);
        setViewType(undefined);
        setEditedViewSteps(undefined);
      },
      open: isOpen,
      openModal: ({
        existingViewId: viewId,
        viewOriginId,
        viewType: type,
        editedSteps,
      }: OpenEditViewInfoModalArgs) => {
        setExistingViewId(viewId);
        setOriginId(viewOriginId);
        setViewType(type);
        setEditedViewSteps(editedSteps);
        open();
      },
    }),
    [isOpen, close, open],
  );

  const viewToPass: EditEdpView | undefined = editedViewSteps
    ? { ...view, querySteps: editedViewSteps }
    : view;

  return (
    <EditViewInfoModalContext.Provider value={contextValue}>
      {isOpen && originId && viewType && (
        <EditViewInfoModal
          isLoading={isLoading}
          onClose={contextValue.closeModal}
          originId={originId}
          view={viewToPass}
          viewType={viewType}
        />
      )}
      {children}
    </EditViewInfoModalContext.Provider>
  );
};
