import { Description } from '@monorepo/shared/componentsV2/Description';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Search } from '@monorepo/shared/componentsV2/Search';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import {
  Content,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { LogSiteRequirementsSummaryTable } from '../LogSiteRequirementsSummaryTable';
import { EditLogHeader, WizardStep } from './EditLogHeader';

const AboveTableContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchContainer = styled.div`
  max-width: 20rem;
`;

export function EditLogSites() {
  useDisableSubnav();
  const { logId, organizationId } = useParams();

  const [filter, setFilter] = useState('');
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value),
    [setFilter],
  );

  return (
    <ErrorBoundary>
      <Page>
        <EditLogHeader wizardStep={WizardStep.SITES} />

        <Content>
          <AboveTableContent>
            <div>
              <Title>Site Requirements Summary</Title>
              <Description>
                View the summary of each site’s frequency requirements, data
                views, and limits for this Log. Use the “Configure” button to
                set up this Log’s frequency requirements, data views, and limits
                at each relevant site.
              </Description>
            </div>
            <SearchContainer>
              <Search onChange={handleSearch} placeholder="Search for a site" />
            </SearchContainer>
          </AboveTableContent>

          <ErrorBoundary>
            <LogSiteRequirementsSummaryTable
              filter={filter}
              logId={logId}
              organizationId={organizationId}
            />
          </ErrorBoundary>
        </Content>
      </Page>
    </ErrorBoundary>
  );
}
