import FormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiSwitch from '@material-ui/core/Switch';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { focusOutline } from '../styles/focus';
import { bodyMedium, bodyRegular } from '../styles/text';
import { HelperText } from './styled/form';

const FormControlLabel = styled(MuiFormControlLabel)`
  & .MuiFormControlLabel-label {
    ${bodyRegular}
  }
`;

const StyledSwitch = styled(MuiSwitch).attrs({ disableRipple: true })`
  & .Mui-focusVisible .MuiSwitch-thumb {
    ${focusOutline({ $outlineOffset: '2px ' })}
  }

  & .Mui-checked {
    color: ${({ theme }) => theme.colors.lightBlue};

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightBlue08};
    }

    & + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
` as typeof MuiSwitch;

const FormLabel = styled(MuiFormLabel)`
  ${bodyMedium}
  color: ${({ theme }) => theme.colors.gray333};
  transform: none;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray333};
  }
` as typeof MuiFormLabel;

type SwitchProps = React.ComponentProps<typeof MuiSwitch> & {
  checkedLabel?: string;
  helperText?: string;
  label?: string;
  uncheckedLabel?: string;
};

export function Switch({
  checked,
  checkedLabel = 'Yes',
  helperText,
  label,
  uncheckedLabel = 'No',
  ...props
}: SwitchProps) {
  const switchValueText = useMemo(
    () => (checked ? checkedLabel : uncheckedLabel),
    [checked, checkedLabel, uncheckedLabel],
  );
  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormControlLabel
        label={switchValueText}
        control={<StyledSwitch checked={checked} {...props} />}
      />
      <HelperText>{helperText || ' '}</HelperText>
    </FormControl>
  );
}
