import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import React from 'react';
import styled from 'styled-components';
import { ActionItemsWidgetWrapper } from './ActionItemsWidgetWrapper';
import { ComplianceCalendar } from './ComplianceCalendar';
import { ComplianceCalendarErrorFallback } from './ComplianceCalendarErrorFallback';

const widthToShowCalendarAndActionItemsNextToEachOther = '1500px';
const mediumCardWidth = '420px'; // matching the mapistry-card--width-medium 420px that is applied to the action items card

const ComplianceCalendarAndTasksWidgetLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  column-gap: 1rem;
  padding: 15px;
  margin: 16px;

  @media (min-width: ${widthToShowCalendarAndActionItemsNextToEachOther}) {
    /* action widgets always bigger than 420px, but less than the smaller of 30rem or 25%   */
    grid-template-columns: 3fr minmax(${mediumCardWidth}, min(25%, 30rem));
  }
`;

const ActionItemsContainer = styled.div`
  max-width: 600px;

  @media (min-width: ${widthToShowCalendarAndActionItemsNextToEachOther}) {
    /* aligning the top of the action items card with the first calendar row */
    margin-top: 80px;
  }
`;

export function ComplianceCalendarSection() {
  return (
    <ComplianceCalendarAndTasksWidgetLayout>
      <ErrorBoundary Fallback={ComplianceCalendarErrorFallback}>
        <ComplianceCalendar />
      </ErrorBoundary>
      <ActionItemsContainer>
        <ActionItemsWidgetWrapper />
      </ActionItemsContainer>
    </ComplianceCalendarAndTasksWidgetLayout>
  );
}
