import React from 'react';
import { useChartableResources } from '../../../hooks/genericLogs/useChartableResources';
import { useLimitItems } from '../../../hooks/genericLogs/useLimitItems';
import DuplicableWidget from '../../widgets/DuplicableWidget';
import ChartCard from './ChartCard';

type ChartWidgetProps = {
  filterQuery: string;
  logProjectId: string;
  projectId: string;
  widgetDefaultName: string;
};

const AIR_EMISSION_CHART_WIDGET_SETTINGS_VERSION = '4';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ChartWidget(props: ChartWidgetProps) {
  const { filterQuery, logProjectId, projectId, widgetDefaultName } = props;
  const { airEmissionResources, isLoading: airEmissionResourcesAreLoading } =
    useChartableResources(projectId, logProjectId);
  const { airEmissionLimitItems, isLoading: airEmissionLimitItemsAreLoading } =
    useLimitItems(logProjectId);
  return (
    <DuplicableWidget
      filterQuery={filterQuery}
      widgetDefaultName={widgetDefaultName}
      widgetType={`chart-${logProjectId}`}
      widgetSettingsVersion={AIR_EMISSION_CHART_WIDGET_SETTINGS_VERSION}
    >
      <ChartCard
        airEmissionResources={airEmissionResources}
        airEmissionResourcesAreLoading={airEmissionResourcesAreLoading}
        airEmissionLimitItems={airEmissionLimitItems}
        airEmissionLimitItemsAreLoading={airEmissionLimitItemsAreLoading}
        logProjectId={logProjectId}
        projectId={projectId}
        widgetDefaultName={widgetDefaultName}
      />
    </DuplicableWidget>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ChartWidget;
