import { A, ButtonLink, Link } from '@monorepo/shared/componentsV2/Link';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import {
  useCurrentUserPermissions,
  useHasProjectViewPermissions,
} from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useProjectsForUser } from '@monorepo/shared/hooks/useProjectsForUser';
import { contentPadding, page } from '@monorepo/shared/styles/layout';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { NoSitesAssignedToUser } from './NoSitesAssignedToUser';

const Page = styled.div`
  ${page}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled(Main)`
  ${contentPadding}
`;

const WarnAboutInvalidProjectId = () => (
  <Page>
    <Content>
      <h1>You do not have permissions to view this site.</h1>
      <p>
        Double check that your url is valid. If you think this is just a typo in
        the url,
        <Link to="/vortex"> you can try to go back to home.</Link>
      </p>
      <p>
        Otherwise, this likely means you have the role of &quot;Site
        Maintenance&quot; for this site.
      </p>
      <p>
        This means you will receive emails about assigned tasks, but do not have
        access to the Mapistry dashboards.
      </p>
      <p>
        To mark tasks as complete or change your level of user permissions on
        Mapistry, contact your Mapistry admin.
      </p>
      <p>
        And as always, you can email
        <A href="mailto:help@mapistry.com"> help@mapistry.com </A> if you need
        any further assistance.
      </p>
    </Content>
  </Page>
);

const WarnAboutOrgAndProjectNotMatching = () => (
  <Page>
    <Content>
      <h1>Hmmm, something about your URL is invalid.</h1>
      <p>That site does not exist on that organization.</p>
      <p>Double check your URL.</p>
      <ButtonLink variant="contained" to="/vortex">
        Go back to home
      </ButtonLink>
    </Content>
  </Page>
);

const NoSitesAssignedView = () => (
  <Page>
    <Content>
      <NoSitesAssignedToUser />
    </Content>
  </Page>
);

type ConfirmProjectAndOrgAccessProps = {
  children: React.ReactElement;
};

export const ConfirmProjectAndOrgAccess = ({
  children,
}: ConfirmProjectAndOrgAccessProps) => {
  const { projectId, organizationId } = useParams();

  const { isLoading: permissionsAreLoading } = useCurrentUserPermissions();
  const hasProjectViewPermission = useHasProjectViewPermissions(projectId);
  const { projects, isLoading: projectsAreLoading } = useProjectsForUser();

  if (!projectId) {
    return children;
  }

  if (permissionsAreLoading || projectsAreLoading) {
    return <Loading />;
  }

  if (projects?.length === 0) {
    return <NoSitesAssignedView />;
  }

  if (!hasProjectViewPermission) {
    return <WarnAboutInvalidProjectId />;
  }

  const project = projects?.find((p) => p.id === projectId);

  if (organizationId && project?.organization_id !== organizationId) {
    return <WarnAboutOrgAndProjectNotMatching />;
  }

  return children;
};
