import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { EditLogEntryModal } from '@monorepo/shared/componentsV2/logs/EditLogEntryModal';
import { LogEntriesUploadModal } from '@monorepo/shared/componentsV2/logs/LogEntriesUploadModal';
import { DateRangeFilterProvider } from '@monorepo/shared/contexts/DateRangeFilterContext';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { LogEntryTable } from './LogEntryTable';

export function LogEntriesTab() {
  const { project } = useCurrentProject();
  const { logId, organizationId } = useParams();

  const [editModalOpen, openEditModal, closeEditModal] = useOpenClose();
  const [uploadModalOpen, openUploadModal, closeUploadModal] = useOpenClose();

  const [entryIdForEdit, setEntryIdForEdit] = useState<string | undefined>(
    undefined,
  );

  const openEntryModal = useCallback(
    (entryId?: string) => {
      setEntryIdForEdit(entryId);
      openEditModal();
    },
    [setEntryIdForEdit, openEditModal],
  );

  return (
    <ErrorBoundary>
      <DateRangeFilterProvider>
        <LogEntryTable
          onEditEntry={openEntryModal}
          onUploadEntries={openUploadModal}
        />
      </DateRangeFilterProvider>
      {editModalOpen && (
        <EditLogEntryModal
          organizationId={organizationId}
          logId={logId}
          logEntryId={entryIdForEdit}
          open={editModalOpen}
          onClose={closeEditModal}
          projectId={project?.id}
        />
      )}
      {project && logId && organizationId && (
        <ErrorBoundary onError={closeUploadModal}>
          <LogEntriesUploadModal
            logId={logId}
            isOpen={uploadModalOpen}
            onClose={closeUploadModal}
            organizationId={organizationId}
            projectId={project.id}
          />
        </ErrorBoundary>
      )}
    </ErrorBoundary>
  );
}
