import { lookFor } from '@monorepo/shared/utils/lookFor';
import { isRequired } from '@monorepo/shared/utils/validators';
import { DatasetRefResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useField } from 'react-final-form';
import { Combobox } from '../../../../../shared/componentsV2/Combobox';
import { SelectableOption } from '../../../../../shared/types/SelectableOption';
import { StyledCombobox, StyledDatasetInfoAsPickerOption } from './styled';

type DatasetPickerProps = Omit<
  React.ComponentProps<typeof Combobox>,
  'label' | 'options' | 'value' | 'onChange'
> & {
  datasets: DatasetRefResponse[];
  fieldName: string;
  label: string;
};

export function DatasetPicker({
  datasets,
  fieldName,
  label,
  ...props
}: DatasetPickerProps) {
  const { input, meta } = useField<string>(fieldName, { validate: isRequired });
  const hasError = (!!meta.error && (meta.touched || meta.modified)) || false;

  const datasetDict = useMemo<Record<string, DatasetRefResponse>>(
    () =>
      datasets.reduce(
        (acc, dataset) => ({ ...acc, [dataset.id]: dataset }),
        {},
      ),
    [datasets],
  );

  const sortedDatasetOptions = useMemo<SelectableOption[]>(
    () =>
      datasets
        .map((d) => ({ label: d.name, value: d.id }))
        .sort((set1, set2) => set1.label.localeCompare(set2.label)),
    [datasets],
  );

  const filterOptions = useCallback(
    (allOptions, state): SelectableOption[] =>
      allOptions.filter((opt: SelectableOption) =>
        lookFor(state.inputValue, opt, ['label']),
      ),
    [],
  );

  return (
    // todo@workflows: disable this when editing a step
    <StyledCombobox
      label={label}
      noOptionsText="There are no data sources that match the given filter. Try a different filter."
      disableClearable
      placeholder="Search for a Log, Resource type, or Workflow"
      value={input.value}
      options={sortedDatasetOptions}
      filterOptions={filterOptions}
      onChange={({ target: { value } }) => input.onChange(value)}
      renderOption={(option) => {
        const dataset = datasetDict[(option as SelectableOption).value];
        if (!dataset) {
          return ''; // should not happen
        }
        return <StyledDatasetInfoAsPickerOption dataset={dataset} />;
      }}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      {...props}
    />
  );
}
