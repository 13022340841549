import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import { cardBorder } from '@monorepo/shared/styles/card';
import { bodyMedium, bodySmall } from '@monorepo/shared/styles/text';
import { WorkflowRefResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { RouteEditMode } from '../types';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 51rem;
  padding: 1.2rem 1rem 1rem;
  ${cardBorder}
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 0.4rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

const Name = styled.div`
  ${bodyMedium};
  padding-right: 2.5rem;
  color: ${({ theme }) => theme.colors.gray333};
`;

const Description = styled.div`
  ${bodySmall};
  margin-top: 0.75rem;
  color: ${({ theme }) => theme.colors.gray666};
  white-space: pre-wrap;
`;

interface WorkflowCardProps {
  workflow: WorkflowRefResponse;
}

export function WorkflowCard({ workflow }: WorkflowCardProps) {
  const navigate = useNavigate();
  const actionMenuOptions = useMemo(
    () => [
      {
        displayName: 'Edit',
        onClick: () => {
          navigate(`${workflow.id}/${RouteEditMode.EDIT}`);
        },
      },
    ],
    [navigate, workflow.id],
  );

  return (
    <Container>
      <Name>{workflow.name}</Name>
      {workflow.description && (
        <Description>{workflow.description}</Description>
      )}
      <MenuContainer>
        <ActionMenu
          altText={`Action menu for ${workflow.name} Workflow`}
          options={actionMenuOptions}
        />
      </MenuContainer>
    </Container>
  );
}
