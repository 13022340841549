import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum WorkflowCacheEvents {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  organizationId: string;
  workflowId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof WorkflowCacheEvents,
  CacheParams
>(WorkflowCacheEvents);

export const invalidateWorkflowCache = cacheRegister.invalidate;
export const workflowCacheRegister = cacheRegister.register;
