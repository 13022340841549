import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Combobox } from '../Combobox';

type ComboboxFieldProps = Omit<
  React.ComponentProps<typeof Combobox>,
  'value' | 'onChange' | 'error' | 'errorText'
> & {
  name: string;
  initialValue?: string;
  validate?: UseFieldConfig<string>['validate'];
};

export function ComboboxField({
  initialValue,
  name,
  validate,
  ...rest
}: ComboboxFieldProps) {
  const { input, meta } = useField<string>(name, { validate, initialValue });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <Combobox
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      name={name}
      onChange={input.onChange}
      value={input.value}
    />
  );
}
