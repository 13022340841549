import _throttle from 'lodash.throttle';
import { useMemo, useRef, useState } from 'react';

interface ThrottleOptions {
  wait: number;
}

const defaultWait = 500;
const defaultOptions = {
  leading: true,
  trailing: true,
};

/**
 * Returns a function that gets called maximally twice per interval (once at the start and once at the end).
 * Gets called with the last arguments it was called with before executing.
 * E.g. called with 'a', 'b', 'c' with in the same interval, it will execute
 * once with 'a' and once with 'c' ('a' at the first call and 'c' at the end of the interval),
 * the 'b' argument never gets used.
 * This is useful e.g. if you only want to update something to the last value that got sent.
 */
export function useThrottled<T extends (...args: any) => any>(
  functionToThrottle: T,
  options: ThrottleOptions = { wait: defaultWait },
) {
  const { current: unchangeableOptions } = useRef(options);

  const throttledFunction = useMemo(() => {
    const { wait = defaultWait } = unchangeableOptions;
    const throttled = _throttle(functionToThrottle, wait, defaultOptions);
    return throttled;
  }, [functionToThrottle, unchangeableOptions]);

  return throttledFunction;
}

/**
 * Returns a function that gets called maximally twice per interval (once at the start and
 * once at the end) _for each unique set of arguments_ the function gets called with.
 * E.g. called with 'a', 'b', 'a', 'a', 'a' with in the same interval, it will execute
 * once with 'b' and twice with 'a' (once at the first call, and once at the end of the interval).
 * This is useful e.g. if you want to limit how often a function gets called per time-frame, but make sure it gets called
 * at least once for each unique set of arguments it got called with.
 */
export function useThrottledBasedOnArgs<T extends (...args: any) => any>(
  functionToThrottle: T,
  options: ThrottleOptions,
) {
  const { current: unchangeableOptions } = useRef(options);
  const [throttledBasedOnArgsDict, setThrottledBasedOnArgsDict] = useState<
    Record<string, T>
  >({});

  const throttledBasedOnArgs = (...args: any) => {
    const argsHash = JSON.stringify(args);

    const alreadyHashed = throttledBasedOnArgsDict[argsHash];
    if (alreadyHashed) {
      alreadyHashed(...args);
      return;
    }

    const { wait = defaultWait } = unchangeableOptions;
    const throttled = _throttle(functionToThrottle, wait, defaultOptions);

    throttled(...args);

    setThrottledBasedOnArgsDict({
      ...throttledBasedOnArgsDict,
      [argsHash]: throttled,
    });
  };

  return throttledBasedOnArgs;
}
