import { Badge } from '@monorepo/shared/componentsV2/Badge';
import { bodyRegular } from '@monorepo/shared/styles/text';
import DataSetupIcon from '@svg/data-setup.svg';
import React from 'react';
import styled from 'styled-components';

const Chip = styled(Badge)`
  height: auto;
  padding: 0.25rem;
  color: ${({ theme }) => theme.colors.grayeee};
  background-color: ${({ theme }) => theme.colors.blue64};
`;

const LabelWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  ${bodyRegular};
  max-width: 100%;

  & span {
    padding: 0;
  }

  & svg {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    fill: ${({ theme }) => theme.colors.grayeee};
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-shrink: 0;
`;

const Label = styled.div`
  max-width: 100%;
  text-align: start;
  white-space: normal;
`;

interface DatasetChipProps {
  className?: string;
  name: string;
}

export function DatasetChip({ className, name }: DatasetChipProps) {
  const label = (
    <LabelWithIcon>
      <IconContainer aria-hidden>
        <DataSetupIcon />
      </IconContainer>
      <Label>{name}</Label>
    </LabelWithIcon>
  );

  return <Chip className={className} label={label} />;
}
