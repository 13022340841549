import { fetchResourceRefs } from '@monorepo/shared/apiClient/logs/resources';
import { fetchAllUsersOnProject } from '@monorepo/shared/apiClient/users';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import {
  FieldDataType,
  FieldResponse,
  LogInfoResponse,
  ResourceTypeResponse,
} from 'mapistry-shared';

interface ResourceOptionsByResourceType {
  [resourceTypeId: string]: SelectableOption[];
}

export interface SelectOptionsByFieldType {
  users?: SelectableOption[];
  resources?: ResourceOptionsByResourceType;
}

export interface FetchSelectOptionsByLogFieldTypeParams {
  logInfo: LogInfoResponse;
  projectId: string;
}

export interface FetchSelectOptionsByResourceFieldTypeParams {
  organizationId: string;
  referencedResourceTypeIds: string[];
}

type OptionFetcher = () => Promise<SelectOptionsByFieldType>;

async function fetchUserOptions(
  fields: FieldResponse[],
  projectId: string,
): Promise<SelectOptionsByFieldType> {
  const hasUserField = fields.some((f) => f.type === FieldDataType.USER);
  if (!hasUserField) return {};

  const users = await fetchAllUsersOnProject({ projectId });
  const options = users.map((u) => ({
    label: u.userName,
    value: u.userId,
  }));
  return {
    users: options,
  };
}

async function fetchResourceOptionsByResourceType(
  resourceType: Pick<ResourceTypeResponse, 'id' | 'organizationId'>,
  projectId?: string,
): Promise<ResourceOptionsByResourceType> {
  const resourceRefs = await fetchResourceRefs({
    organizationId: resourceType.organizationId,
    resourceTypeId: resourceType.id,
    projectId,
  });
  const options = resourceRefs
    .sort((r1, r2) => r1.name.localeCompare(r2.name))
    .map((r) => ({
      label: r.name,
      value: r.id,
    }));
  return {
    [resourceType.id]: options,
  };
}

async function fetchResourceOptions(
  resourceTypes: Pick<ResourceTypeResponse, 'id' | 'organizationId'>[],
  projectId?: string,
): Promise<SelectOptionsByFieldType> {
  if (!resourceTypes.length) return {};

  const partials = await Promise.all(
    resourceTypes.map((rt) =>
      fetchResourceOptionsByResourceType(rt, projectId),
    ),
  );
  const resourcesByResourceType = partials.reduce(
    (acc, resourcesBySingleResourceType) => ({
      ...acc,
      ...resourcesBySingleResourceType,
    }),
    {} as ResourceOptionsByResourceType,
  );
  return {
    resources: resourcesByResourceType,
  };
}

/** for log entry bulk upload */
export async function fetchSelectOptionsByLogFieldType({
  logInfo,
  projectId,
}: FetchSelectOptionsByLogFieldTypeParams): Promise<SelectOptionsByFieldType> {
  const {
    log: { fields },
    resourceTypes,
  } = logInfo;

  const fetchers: OptionFetcher[] = [
    () => fetchUserOptions(fields, projectId),
    () => fetchResourceOptions(Object.values(resourceTypes), projectId),
  ];
  const partials = await Promise.all(fetchers.map((fetch) => fetch()));

  return partials.reduce(
    (acc, singleFieldTypeOptions) => ({
      ...acc,
      ...singleFieldTypeOptions,
    }),
    {} as SelectOptionsByFieldType,
  );
}

/** for resources bulk upload (it's currently only resource fields that even have select options) */
export async function fetchSelectOptionsByResourceFieldType({
  organizationId,
  referencedResourceTypeIds,
}: FetchSelectOptionsByResourceFieldTypeParams): Promise<SelectOptionsByFieldType> {
  const referencedResourceTypesInfo = referencedResourceTypeIds.map((id) => ({
    id,
    organizationId,
  }));
  const resourceOptions = await fetchResourceOptions(
    referencedResourceTypesInfo,
  );
  return resourceOptions;
}
