import {
  AnimatedComponent,
  AnimationType,
} from '@monorepo/shared/components/AnimatedComponent';
import React from 'react';
import { AccordionContainer } from '../../../elements';
import { ProjectTaskType } from '../../../propTypes';
import ProjectTask from '../../../task/ProjectTask';

interface TaskCardSectionProps {
  emptyMessage?: string;
  disableAccordion?: boolean;
  projectId: string;
  startExpanded?: boolean;
  tasks: typeof ProjectTaskType[];
  title?: string | null;
}

export const TasksCardSection = ({
  emptyMessage,
  disableAccordion = false,
  startExpanded = false,
  tasks,
  title = null,
  projectId,
}: TaskCardSectionProps) => {
  const sectionBody = (
    <div className="tasks-card-section__content">
      <AnimatedComponent type={AnimationType.fadeInOut}>
        {tasks.map((task) => (
          /* @ts-expect-error - ProjectTask is not TypeScript */
          <React.Fragment key={task.id}>
            {/* @ts-expect-error - ProjectTask is not TypeScript */}
            <ProjectTask task={task} projectId={projectId} />
          </React.Fragment>
        ))}
      </AnimatedComponent>
      {!tasks.length && emptyMessage}
    </div>
  );
  return disableAccordion ? (
    <>
      {title && <div className="tasks-card-section__title">{title}</div>}
      {sectionBody}
    </>
  ) : (
    <AccordionContainer
      className="group__container"
      isExpanded={startExpanded}
      title={
        title ? <div className="tasks-card-section__title">{title}</div> : null
      }
    >
      {sectionBody}
    </AccordionContainer>
  );
};
