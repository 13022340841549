import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Description } from '@monorepo/shared/componentsV2/Description';
import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { List } from '@monorepo/shared/componentsV2/List';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { StartDate } from '@monorepo/shared/componentsV2/styled/header';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import {
  contentPadding,
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import {
  BackButton,
  ButtonContainer,
} from '../../../../logDataSetup/src/components/LogDetail/styled';
import { useResourceTypeAssetsOrShowError } from '../../hooks/useResourceTypeAssetsOrShowError';
import { NamePropertyPreview } from '../BuiltInPropertyPreviews/NamePropertyPreview';
import { SitePropertyPreview } from '../BuiltInPropertyPreviews/SitePropertyPreview';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledMain = styled(Main)`
  ${contentPadding}
  ${fillHeightAndScrollable}
`;

const Content = styled.div`
  max-width: 615px;
`;

const SubTitle = styled.h2`
  ${header4}
`;

const DescriptionStyled = styled(Description)`
  margin-bottom: 2.3rem;
`;

export function ResourceTypeDetailsPage() {
  const { organizationId, resourceTypeId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const { isError, isLoading, resourceProperties, resourceType } =
    useResourceTypeAssetsOrShowError(organizationId, resourceTypeId);

  const navigate = useNavigate();
  const handleBack = useCallback(() => navigate('../..'), [navigate]);
  const handleEdit = useCallback(() => navigate('edit'), [navigate]);

  const backAction = (
    <BackButton onClick={handleBack}>
      <VisuallyHidden>Back</VisuallyHidden>
      <ArrowBackIcon />
    </BackButton>
  );

  if (isLoading) {
    return (
      <Page>
        <Header leftActions={backAction} title="Settings" />
        <Loading />
      </Page>
    );
  }

  if (isError) {
    return (
      <Page>
        <Header leftActions={backAction} title="Settings" />
      </Page>
    );
  }

  // Should never happen, a contract with the BE is that it either returns a value or 404
  if (!resourceType || !resourceProperties) {
    return null;
  }

  return (
    <Page>
      <Header
        centerActions={
          <StartDate>
            {`Created ${format(new Date(resourceType.createdAt), 'MM/dd/yy')}`}
          </StartDate>
        }
        leftActions={backAction}
        rightActions={
          canEditOrgSettings ? (
            <ButtonContainer>
              <Button color="primary" onClick={handleEdit} variant="contained">
                Edit Resource type
              </Button>
            </ButtonContainer>
          ) : null
        }
        title={`${resourceType.name} Settings`}
      />
      <StyledMain>
        <Content>
          <SubTitle>Description</SubTitle>
          <DescriptionStyled>
            {resourceType.description || 'No description'}
          </DescriptionStyled>
          <SubTitle>Properties</SubTitle>
          <List>
            <NamePropertyPreview key="name" />
            {resourceType.isSiteSpecific && <SitePropertyPreview key="site" />}
            {resourceProperties.map((property) => (
              <FieldTypePreviewFactory
                key={property.id}
                fieldTypeName="property"
                field={property}
              />
            ))}
          </List>
          {!resourceProperties.length && !resourceType.isSiteSpecific && (
            <Description>No properties</Description>
          )}
        </Content>
      </StyledMain>
    </Page>
  );
}
