import { Property, SheetConfig } from '@flatfile/api/api';
import { Api } from '@monorepo/shared/apiClient';
import { SelectOptionsByFieldType } from '@monorepo/shared/apiClient/logs/fieldOptionsForUpload';
import { FetchAllProjectsOnOrganizationResponse } from '@monorepo/shared/apiClient/projects';
import {
  invalidateResourceListCache,
  ResourceListCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourceListCacheRegister';
import {
  getSingleSelectColumnSetting,
  getTextColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { getColumnsForFields } from '@monorepo/shared/components/InteractiveFileUpload/helpers/edpFields/getColumnsForFields';
import { parseRecordToFieldValueRequest } from '@monorepo/shared/components/InteractiveFileUpload/helpers/edpFields/parseRecordToFieldValueRequest';
import { validateFields } from '@monorepo/shared/components/InteractiveFileUpload/helpers/edpFields/validateFields';
import { uniqueConstraintSetting } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileSettingsHelpers';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useResourceFieldOptionsForUpload } from '@monorepo/shared/hooks/resources/useResourceFieldOptionsForUpload';
import { useResourceTypeWithProperties } from '@monorepo/shared/hooks/resources/useResourceTypeWithProperties';
import { useOrganizationProjects } from '@monorepo/shared/hooks/useOrgProjects';
import {
  FieldResponse,
  ResourceTypeRefResponse,
  SaveResourceRequest,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';

enum uploadFieldKeys {
  name = 'name', // the backend shows errors on this column, do not change the name without changing the backend as well
  projectId = 'projectId',
}

type ResourceUploadRecord = {
  [uploadFieldKeys.name]: string;
  [uploadFieldKeys.projectId]: string;
  [fieldId: string]: string | number | boolean | null | undefined;
};

type ResourcesUploadModalProps = {
  resourceTypeId: string;
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
};

const getColumns = (
  resourceType: ResourceTypeRefResponse,
  resourceProperties: FieldResponse[],
  resourceFieldOptionsForUpload: SelectOptionsByFieldType,
  projects?: FetchAllProjectsOnOrganizationResponse,
): SheetConfig['fields'] => {
  const projectOptions =
    projects?.map((p) => ({ value: p.id, label: p.name })) || [];
  const projectColumn: Property.Enum = getSingleSelectColumnSetting(
    {
      key: uploadFieldKeys.projectId,
      label: 'Site Association',
      isRequired: true,
    },
    projectOptions,
  );

  return [
    getTextColumnSetting({
      label: 'Resource Name',
      key: uploadFieldKeys.name,
      isRequired: true,
    }),
    ...(resourceType.isSiteSpecific ? [projectColumn] : []),
    ...getColumnsForFields(resourceProperties, resourceFieldOptionsForUpload),
  ];
};

function parseResultsToSaveResourceRequest(
  results: ResourceUploadRecord[],
  resourceProperties: FieldResponse[] | undefined,
): SaveResourceRequest[] {
  return results.map((result) => {
    const resource: SaveResourceRequest = {
      name: result[uploadFieldKeys.name],
      projectId: result[uploadFieldKeys.projectId] || undefined,
      propertyValues: parseRecordToFieldValueRequest(
        resourceProperties || [],
        result,
      ),
    };

    return resource;
  });
}

export function ResourcesUploadModal({
  resourceTypeId,
  isOpen,
  onClose,
  organizationId,
}: ResourcesUploadModalProps) {
  const {
    resourceType,
    resourceProperties,
    isStale: isResourceTypeStale,
    isFetching: isFetchingResourceType,
    error: resourceTypeLoadingError,
  } = useResourceTypeWithProperties({
    organizationId,
    resourceTypeId,
  });

  const {
    projects,
    isLoading: areProjectOptionsLoading,
    error: projectOptionsLoadingError,
  } = useOrganizationProjects({
    config: { enabled: resourceType && resourceType.isSiteSpecific },
    organizationId,
  });

  const {
    error: optionsLoadError,
    isLoading: isSelectOptionsLoading,
    resourceFieldOptionsForUpload,
  } = useResourceFieldOptionsForUpload({
    config: { enabled: isOpen, useErrorBoundary: false },
    resourceTypeId,
    organizationId,
  });

  const isLoading =
    isResourceTypeStale ||
    isFetchingResourceType ||
    areProjectOptionsLoading ||
    isSelectOptionsLoading;
  const loadingError =
    resourceTypeLoadingError || projectOptionsLoadingError || optionsLoadError;

  const workbook = useMemo<WorkbookConfig>(() => {
    const fields: SheetConfig['fields'] =
      isOpen &&
      resourceType &&
      resourceProperties &&
      resourceFieldOptionsForUpload
        ? getColumns(
            resourceType,
            resourceProperties,
            resourceFieldOptionsForUpload,
            projects,
          )
        : [];

    // declaring as a const before returning for better type checking
    const workbookConfig: WorkbookConfig = {
      name: 'Upload Resources',
      sheets: [
        {
          name: 'Resources',
          slug: 'upload-resources',
          fields,
          constraints: [
            uniqueConstraintSetting(
              'Resource across all fields',
              fields.map((field) => field.key),
            ),
          ],
        },
      ],
    };

    return workbookConfig;
  }, [
    isOpen,
    projects,
    resourceFieldOptionsForUpload,
    resourceProperties,
    resourceType,
  ]);

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(
    async (record) => validateFields(resourceProperties || [], record),
    [resourceProperties],
  );

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: ResourceUploadRecord[]) => {
      const resources = parseResultsToSaveResourceRequest(
        results,
        resourceProperties,
      );

      await Api.uploadResources({
        organizationId,
        resources,
        resourceTypeId,
      });

      invalidateResourceListCache(ResourceListCacheEvents.CREATE, {
        organizationId,
        resourceTypeId,
      });

      const noun = resources.length === 1 ? 'Resource' : 'Resources';
      return `Successfully uploaded ${resources.length} ${noun}!`;
    },
    [organizationId, resourceProperties, resourceTypeId],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ organizationId, resourceTypeId }),
    [organizationId, resourceTypeId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isLoading}
      loadingError={loadingError}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      recordHookCallback={recordHookCallback}
      showTableForManualInput
      workbook={workbook}
    />
  );
}
