import { Button } from '@monorepo/shared/componentsV2/Button';
import AddIcon from '@svg/add.svg';
import { DatasetResponse, JoinStepConditionRequest } from 'mapistry-shared';
import React, { useCallback, useRef } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { StepCondition } from './StepCondition';
import {
  StyledConditionsContainer,
  StyledConditionsDescription,
} from './styled';

interface StepConditionsProps {
  disableAll?: boolean; // todo@workflows: remove once we allow editing steps
  joinToDataset?: DatasetResponse;
  stepInputDataset: DatasetResponse;
}

export function StepConditions({
  disableAll,
  joinToDataset,
  stepInputDataset,
}: StepConditionsProps) {
  const addConditionButtonRef = useRef<HTMLButtonElement>(null);

  /**
   * move focus to the add button if it would get lost otherwise
   * (e.g. if we're deleting the last condition in the list, or there are only 2 left and we delete one)
   */
  const preventLosingFocus = useCallback((conditions, idx) => {
    if (conditions.length === 2 || idx + 1 === conditions.length) {
      addConditionButtonRef.current?.focus();
    }
  }, []);

  return (
    <StyledConditionsContainer>
      <h3>Conditions</h3>
      <StyledConditionsDescription>
        Choose the columns you’d like to join from each of your data sources. If
        joining on a date column, please choose a time interval.
      </StyledConditionsDescription>
      <FieldArray<Partial<JoinStepConditionRequest>> name="conditions">
        {({ fields: conditions }) => (
          <div>
            {conditions.map((conditionName, idx) => (
              <StepCondition
                canDelete={conditions.value.length > 1}
                disabled={disableAll}
                formFieldName={conditionName}
                key={conditionName}
                leftDataset={stepInputDataset}
                rightDataset={joinToDataset}
                shouldLabelShow={idx === 0}
                onDelete={() => {
                  preventLosingFocus(conditions, idx);
                  conditions.remove(idx);
                }}
              />
            ))}

            <Button
              ref={addConditionButtonRef}
              color="primary"
              disabled={disableAll}
              onClick={() => {
                conditions.push({
                  leftPropertyId: undefined,
                  rightPropertyId: undefined,
                });
              }}
            >
              <AddIcon />
              Add a condition
            </Button>
          </div>
        )}
      </FieldArray>
    </StyledConditionsContainer>
  );
}
