import { Api } from '@monorepo/shared/apiClient';
import { Description } from '@monorepo/shared/componentsV2/Description';
import { Title } from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { AddStepButton } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage/AddStepButton';
import {
  WorkflowStepOperationType,
  WorkflowStepResponse,
} from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import { WorkflowStepCard } from './WorkflowStepCard';

const ListContainer = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const StyledWorkflowStepCard = styled(WorkflowStepCard)`
  margin-bottom: 0.625rem;
`;

interface StepListProps {
  addButtonRef?: React.Ref<HTMLButtonElement>;
  onDeleteSuccess: () => void;
  openModalForNewStep: (stepType: WorkflowStepOperationType) => void;
  openModalForExistingStep: (step: WorkflowStepResponse) => void;
  workflow: Api.FetchWorkflowResponse;
}

export function StepList({
  addButtonRef,
  onDeleteSuccess,
  openModalForExistingStep,
  openModalForNewStep,
  workflow,
}: StepListProps) {
  const { organizationId, steps } = workflow;
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  return (
    <>
      <Title>Steps</Title>
      <Description>
        Add a step to transform your data structure and create relationships
        between data.
      </Description>

      <ListContainer>
        {steps.map((step) => (
          <StyledWorkflowStepCard
            key={step.id}
            openModalForExistingStep={openModalForExistingStep}
            organizationId={organizationId}
            showMenu
            step={step}
            onDeleteSuccess={onDeleteSuccess}
          />
        ))}
      </ListContainer>

      {canEditOrgSettings && (
        <AddStepButton
          onAddNew={openModalForNewStep}
          buttonRef={addButtonRef}
        />
      )}
    </>
  );
}
