import { createFeatureFlagsKey, useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import { TypedQueryFunction, useQuery } from 'react-query';

function UseQuery(props: {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children(childProps: { isLoading: boolean; data: any }): JSX.Element | null;
  queryKey: string | readonly string[];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: TypedQueryFunction<any, any>;
}) {
  return props.children(useQuery(props.queryKey, props.fn));
}

const featureFlagsKey = createFeatureFlagsKey();

// allows to not have to refetch feature flags when going between components
// wrap around the exporting component
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
export const withFeatureFlags = (Component) => (props) =>
  (
    <UseQuery queryKey={featureFlagsKey} fn={() => useFeatureFlags()}>
      {(query) =>
        // only load once feature flags are fetched
        query.isLoading ? null : (
          <Component {...props} featureFlags={query.data} />
        )
      }
    </UseQuery>
  );
