import { SkipToMainLink } from '@monorepo/shared/componentsV2/Main';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { NavBar } from './NavBar';
import { Providers } from './Providers';
import { Router } from './Router';

const Root = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

/**
 * Truly global styles can go here. These should be decorative in nature
 * (i.e. not spacing, sizing, etc). Before adding a style here consider
 * if a re-usable styled component would work instead
 */
const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
  }
`;

export function App() {
  return (
    <Providers>
      <GlobalStyle />
      <Root>
        <SkipToMainLink />
        <NavBar />
        <Router />
      </Root>
    </Providers>
  );
}
