import { Api } from '@monorepo/shared/apiClient';
import { LogStatistic } from 'mapistry-shared';
import { QueryConfig, useQuery } from 'react-query';

export const createQueryKey = ({
  logProjectId,
  projectId,
  resources,
}: Api.FetchStatisticsProps) =>
  ['statistics', projectId, logProjectId, resources] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchStatistics
>;

type UseStatisticsProps = Api.FetchStatisticsProps & {
  config?: QueryConfig<LogStatistic[], Api.ErrorResponse>;
};

const fetcher: Fetcher = (_, projectId, logProjectId, resources) =>
  Api.fetchStatistics({ logProjectId, projectId, resources });

export const useStatistics = ({
  config: inputConfig,
  logProjectId,
  projectId,
  resources,
}: UseStatisticsProps) => {
  const config = {
    enabled: logProjectId && projectId && resources?.length,
    ...inputConfig,
  };
  const key = createQueryKey({ logProjectId, projectId, resources });
  const { data: statistics, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    statistics,
    ...queryInfo,
  };
};
