import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import {
  NullableDate,
  PhotosAndAttachmentsToIncludeInPdf,
  TasksToIncludeInPdf,
} from 'mapistry-shared';
import React from 'react';
import { DownloadState } from '../../../../../types/DownloadState';
import {
  Button,
  DatePicker,
  FieldWrapper,
  FormErrors,
  LoadingIndicator,
  Modal,
} from '../../../../elements';
import { PdfOptionsSection } from './PdfOptionsSection';

type DownloadInspectionsModalProps = {
  downloadState: DownloadState;
  emailLimit: number;
  errors?: string[];
  isFetchingCount: boolean;
  numberOfInspections: number;
  onClose: () => void;
  onDownload: () => void;
  onEndDateChange: (date: Date) => void;
  onStartDateChange: (date: Date) => void;
  onWithTaskOptionChange: (value: TasksToIncludeInPdf) => void;
  onIncludeTaskAttachmentsChange: (value: boolean) => void;
  onIncludeRegularFormContentChange: (value: boolean) => void;
  onPhotoOptionChange: (value: PhotosAndAttachmentsToIncludeInPdf) => void;
  endDate?: NullableDate;
  startDate?: NullableDate;
  includeTasksOption: TasksToIncludeInPdf;
  includePhotosAndAttachmentsOption: PhotosAndAttachmentsToIncludeInPdf;
  includeRegularFormContent: boolean;
  includeTasksAttachments?: boolean;
  open?: boolean;
  title: string;
};

export const DownloadInspectionsModal = ({
  downloadState,
  emailLimit,
  errors,
  isFetchingCount,
  numberOfInspections,
  onClose,
  onDownload,
  onEndDateChange,
  onIncludeTaskAttachmentsChange,
  onIncludeRegularFormContentChange,
  onPhotoOptionChange,
  onStartDateChange,
  onWithTaskOptionChange,
  open = false,
  includeTasksAttachments = true,
  includeRegularFormContent = true,
  includePhotosAndAttachmentsOption,
  endDate = null,
  startDate = null,
  includeTasksOption = TasksToIncludeInPdf.ALL,
  title,
}: DownloadInspectionsModalProps) => {
  const downloadText = () => {
    switch (downloadState) {
      case DownloadState.DOWNLOADING:
        return `Emailing File(s)`;
      case DownloadState.DOWNLOADED:
        return `File(s) Processing`;
      default:
        return `Email File(s)`;
    }
  };

  const footer = () => {
    const downloadButtonClass =
      downloadState === DownloadState.DOWNLOADED ? ' downloaded' : '';

    const allContentIsExcluded =
      includePhotosAndAttachmentsOption ===
        PhotosAndAttachmentsToIncludeInPdf.NONE &&
      includeRegularFormContent === false &&
      includeTasksOption === TasksToIncludeInPdf.NONE;

    return (
      <>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          className={`download-button${downloadButtonClass}`}
          color="primary"
          disabled={
            numberOfInspections === 0 ||
            numberOfInspections > emailLimit ||
            downloadState === DownloadState.DOWNLOADING ||
            downloadState === DownloadState.DOWNLOADED ||
            allContentIsExcluded
          }
          onClick={onDownload}
        >
          {downloadText()}
          {downloadState === DownloadState.DOWNLOADING && (
            <LoadingIndicator isAbsolute={false} size={15} />
          )}
        </Button>
      </>
    );
  };

  const inspections = () =>
    isFetchingCount ? (
      <div className="download-inspections__number-of-inspections">
        <LoadingIndicator size={30} isAbsolute={false} />
        <span className="number-of-inspections">Fetching inspections...</span>
      </div>
    ) : (
      <div className="download-inspections__number-of-inspections">
        <SvgIcon
          className="mapistry-green"
          identifier="m-event-calendar-success"
        />
        <span className="number-of-inspections">
          {`${numberOfInspections} inspection${
            numberOfInspections === 1 ? '' : 's'
          } selected`}
        </span>
      </div>
    );

  const explanationText = () => {
    if (numberOfInspections > emailLimit) {
      return (
        <div className="download-inspections__email-explanation">
          <p>There are too many inspections.</p>
          <p>{`Please select a date range that results in ${emailLimit} or fewer inspections.`}</p>
        </div>
      );
    }

    if (numberOfInspections > 0) {
      return (
        <div className="download-inspections__email-explanation">
          <p>
            {downloadState === DownloadState.DOWNLOADED &&
              `This could take some time.
              You can close this window and we will send you an email with
              the files when they're ready.`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      className="download-inspections__modal"
      scrollPaperClassName=""
      header={<h4>{title}</h4>}
      footer={footer()}
      fullWidth={false}
      maxWidth="xs"
      onClose={onClose}
      open={open}
    >
      <div className="download-inspections__content">
        {errors && <FormErrors errors={errors} />}
        <FieldWrapper label="Select a date range of inspections">
          <DatePicker
            disabled={downloadState === DownloadState.DOWNLOADING}
            onChange={onStartDateChange}
            value={startDate}
          />
          <DatePicker
            disabled={downloadState === DownloadState.DOWNLOADING}
            minDate={startDate}
            onChange={onEndDateChange}
            value={endDate}
          />
        </FieldWrapper>
        {inspections()}
        {explanationText()}
        <div className="download-inspections__pdf-options-section">
          <PdfOptionsSection
            includePhotosAndAttachmentsOption={
              includePhotosAndAttachmentsOption
            }
            includeRegularFormContent={includeRegularFormContent}
            includeTasksAttachments={includeTasksAttachments}
            includeTasksOption={includeTasksOption}
            onIncludeRegularFormContentChange={
              onIncludeRegularFormContentChange
            }
            onIncludeTaskAttachmentsChange={onIncludeTaskAttachmentsChange}
            onPhotoOptionChange={onPhotoOptionChange}
            onWithTaskOptionChange={onWithTaskOptionChange}
          />
        </div>
      </div>
    </Modal>
  );
};
