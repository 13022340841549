import ProjectService from '@monorepo/old-web/js/services/ProjectService';
import { useQuery } from 'react-query';

const createKey = ({ projectId }: { projectId: string }) =>
  ['project-calendars', projectId] as const;

const fetcher = (_: string, projectId: string) => {
  const projectService = new ProjectService();
  return projectService.getCalendars(projectId);
};

export function useProjectCalendars({ projectId }: { projectId?: string }) {
  const isEnabled = !!projectId;
  const config = { enabled: !!projectId };
  const key = isEnabled ? createKey({ projectId }) : undefined;
  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    projectCalendars: data,
  };
}
