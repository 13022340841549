import { InspectionWidgets } from '@monorepo/inspections/src/components/InspectionsWidgets';
import APP from '@monorepo/old-web/js/config';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InspectionsPageHeader } from './InspectionsPageHeader';

const StyledMain = styled(Main)`
  ${verticalLayout}
  ${page}
`;

const ScrollableContainer = styled.div`
  ${fillHeightAndScrollable}
`;

export function InspectionsPage() {
  const { project } = useCurrentProject();

  const [filter, setFilter] = useState('');

  APP.projectId = project?.id; // Legacy code grabs projectId from the App object

  // clear filter when switching projects
  useEffect(() => {
    setFilter('');
  }, [project?.id]);

  return (
    <StyledMain>
      <InspectionsPageHeader onSearch={setFilter} filter={filter} />
      <ErrorBoundary>
        <ScrollableContainer>
          {project ? <InspectionWidgets filter={filter} /> : <Loading />}
        </ScrollableContainer>
      </ErrorBoundary>
    </StyledMain>
  );
}
