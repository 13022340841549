import React from 'react';
import { ModalFieldWrapper } from '../styled';
import { EditGenericFieldForm } from './GenericFieldForm/EditGenericFieldForm';
import { ChooseDefaultOptionField } from './shared/ChooseDefaultOptionField';
import { StyledListBuilderField } from './styled';

// this is rendered within FinalForm so the inputs are managed by it
export function EditBooleanFieldForm() {
  const defaultValueOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];
  return (
    <>
      <EditGenericFieldForm />
      <ModalFieldWrapper>
        <StyledListBuilderField
          isDisabled
          isRequired
          label="Options"
          name="options"
        />
      </ModalFieldWrapper>
      <ChooseDefaultOptionField defaultValueOptions={defaultValueOptions} />
    </>
  );
}
