import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Menu } from '@monorepo/shared/componentsV2/Menu';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  & .menu-button__arrow-drop-down {
    margin-right: 0;
    margin-left: 0.5rem;
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-shrink: 0;
`;

type MenuButtonProps = Omit<
  React.ComponentProps<typeof Menu>,
  'buttonElement'
> &
  Partial<Pick<React.ComponentProps<typeof Button>, 'variant'>> & {
    className?: string;
    icon?: ReactNode;
    buttonRef?: React.Ref<HTMLButtonElement>;
    label: React.ReactNode;
  };

export function MenuButton({
  className,
  icon,
  label,
  options,
  variant,
  buttonRef,
  ...rest
}: MenuButtonProps) {
  return (
    <Menu
      buttonElement={
        <StyledButton
          className={className}
          color="primary"
          variant={variant || 'contained'}
          ref={buttonRef}
        >
          {icon && <IconContainer aria-hidden>{icon}</IconContainer>}
          {label}
          <ArrowDropDown aria-hidden className="menu-button__arrow-drop-down" />
        </StyledButton>
      }
      options={options}
      {...rest}
    />
  );
}
