import {
  StyledFormGroup,
  StyledInput,
  StyledSelect,
} from '@monorepo/shared/componentsV2/styled/form';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import {
  Content,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { isRequired } from '@monorepo/shared/utils/validators';
import {
  Category,
  getCategoryDisplayString,
  UpdateLogRequest,
} from 'mapistry-shared';
import React from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom-v5-compat';
import { EditLogHeader, WizardStep } from './EditLogHeader';
import { Mode } from './types';
import { useSubmitLogDetails } from './useSubmitLogDetails';

const options = Object.values(Category).map((category) => ({
  label: getCategoryDisplayString(category),
  value: category,
}));

export function EditLogPage() {
  useDisableSubnav();

  const { logId, mode = Mode.new, organizationId } = useParams();
  const { log } = useLog({ logId, organizationId });

  const isEditMode = mode === Mode.edit;
  const logExists = !!logId;

  const onSubmit = useSubmitLogDetails();

  return (
    <Page>
      <Form
        initialValues={log}
        onSubmit={onSubmit}
        subscription={{ pristine: true, submitting: true }}
      >
        {({ handleSubmit, pristine, submitting }) => (
          <>
            <EditLogHeader
              canSubmit={!pristine || (logExists && !isEditMode)}
              isSubmitting={submitting}
              onSubmit={handleSubmit}
              wizardStep={WizardStep.DETAILS}
            />
            <Content>
              <Title>Basic Info</Title>
              <StyledFormGroup row>
                <StyledInput<UpdateLogRequest['name']>
                  label="Log Name"
                  name="name"
                  placeholder="Name"
                  validate={isRequired}
                />
              </StyledFormGroup>
              <StyledFormGroup row>
                <StyledSelect<UpdateLogRequest['category']>
                  label="Compliance Category"
                  name="category"
                  options={options}
                  validate={isRequired}
                />
              </StyledFormGroup>
            </Content>
          </>
        )}
      </Form>
    </Page>
  );
}
