import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { GenericError } from '@monorepo/shared/componentsV2/errorDisplays/GenericError';
import { getFieldValueFromForm } from '@monorepo/shared/componentsV2/fieldDataType/values/EditFieldValueFactory';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { useToast } from '@monorepo/shared/contexts';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import * as Sentry from '@sentry/browser';
import startCase from 'lodash.startcase';
import {
  FieldResponse,
  FieldValueResponse,
  NullableString,
  SaveFieldValueRequest,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { IS_REQUIRED_ERROR } from '../../utils/validators';
import { EditEntryForm } from './EditEntryForm';

const SaveButton = styled(Button)`
  width: 5rem;
`;

type BaseEditEntryModalProps<EntryResponseType> = {
  children: React.ReactNode;
  entryId?: string;
  entryTypeDisplayName: string;
  fields?: FieldResponse[];
  fieldValues?: Record<FieldResponse['id'], FieldValueResponse>;
  getEntryName: (response: EntryResponseType) => string;
  hardRequiredFieldIds?: string[];
  canBypassSoftRequiredFieldValidation?: boolean;
  instructions?: NullableString;
  modalId?: string;
  isLoading: boolean;
  onClose: () => void;
  open: boolean;
  organizationId: string;
  projectId: string | undefined;
  saver: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues: Record<string, any>,
    fieldValues: SaveFieldValueRequest[],
  ) => Promise<EntryResponseType | undefined>;
};

export function BaseEditEntryModal<EntryResponseType>({
  children,
  entryId,
  entryTypeDisplayName,
  fields,
  fieldValues,
  getEntryName,
  hardRequiredFieldIds = [],
  canBypassSoftRequiredFieldValidation,
  instructions,
  modalId,
  isLoading,
  onClose,
  open,
  organizationId,
  projectId,
  saver,
}: BaseEditEntryModalProps<EntryResponseType>) {
  const title = useMemo(
    () =>
      entryId
        ? `Edit ${startCase(entryTypeDisplayName)}`
        : `Add New ${startCase(entryTypeDisplayName)}`,
    [entryId, entryTypeDisplayName],
  );

  const { success, showUserFriendlyErrorToast } = useToast();
  const { confirm } = useModal();

  const persistValidatedForm = useCallback(
    async (values) => {
      try {
        const fieldValuesToSave = (fields || []).map((field) =>
          getFieldValueFromForm(field, values),
        );
        const saved = await saver(values, fieldValuesToSave);

        if (saved) {
          success(
            `${getEntryName(saved)} has been ${
              entryId ? 'edited' : 'created'
            }.`,
          );
          onClose();
        } else {
          Sentry.captureException(
            `Did not receive ${entryTypeDisplayName} back from create or edit request`,
            { extra: { values, entryId } },
          );
          throw new Error();
        }
      } catch (err) {
        showUserFriendlyErrorToast(
          err,
          `Unable to save ${entryTypeDisplayName}.`,
          { dontAutoHide: true },
        );
      }
    },
    [
      entryId,
      entryTypeDisplayName,
      showUserFriendlyErrorToast,
      fields,
      getEntryName,
      onClose,
      saver,
      success,
    ],
  );

  const askUserToConfirmBypassingValidation = useCallback(
    async (formValues) =>
      confirm({
        title: 'Incomplete Log Entry',
        description: (
          <>
            You have not completed all required fields for this entry. To comply
            with the requirements of this Log, you&apos;ll need to add a value
            to all required fields marked with *.
            <br />
            <br />
            Do you want to submit an incomplete Log entry?
          </>
        ),
        cancelButtonText: 'Return to entry form',
        confirmButtonText: 'Submit incomplete',
        afterConfirmButtonText: 'Submitting...',
        danger: true,
        onConfirmAsync: async () => persistValidatedForm(formValues),
      }),
    [confirm, persistValidatedForm],
  );

  const canBypassErrors = useCallback(
    (errors: Record<string, string>) =>
      canBypassSoftRequiredFieldValidation &&
      Object.values(errors).every((e) => e === IS_REQUIRED_ERROR) &&
      !hardRequiredFieldIds.some((f) => errors[f]),
    [canBypassSoftRequiredFieldValidation, hardRequiredFieldIds],
  );

  const handleSave = useCallback(
    async ({ invalid, errors, values, handleSubmit }) => {
      handleSubmit(); // validates and submits if valid
      if (invalid && canBypassErrors(errors)) {
        await askUserToConfirmBypassingValidation(values);
      }
    },
    [askUserToConfirmBypassingValidation, canBypassErrors],
  );

  // children prop should only be truthy when it is able to properly render
  const canRenderForm = !!children && !!fields;

  const modalContents = useMemo(() => {
    if (!open) return null;
    if (isLoading) return <Loading />;
    if (!canRenderForm) return <GenericError />;
    return (
      <EditEntryForm
        entryTypeDisplayName={entryTypeDisplayName}
        fieldValues={fieldValues}
        fields={fields}
        instructions={instructions}
        organizationId={organizationId}
        projectId={projectId}
      >
        {children}
      </EditEntryForm>
    );
  }, [
    canRenderForm,
    children,
    entryTypeDisplayName,
    fieldValues,
    fields,
    instructions,
    isLoading,
    open,
    organizationId,
    projectId,
  ]);

  return (
    <Form
      onSubmit={persistValidatedForm}
      subscription={{
        errors: true,
        invalid: true,
        pristine: true,
        submitting: true,
        values: true,
      }}
      // initial values are set on the specific fields; it's more straightforward since the shape of this
      //  form is determined by fetched data (the log or resource type config)
    >
      {({ errors, handleSubmit, invalid, pristine, submitting, values }) => (
        <BaseModal
          buttons={[
            <Button
              color="primary"
              disabled={submitting}
              key="cancel"
              onClick={onClose}
              variant="text"
            >
              Cancel
            </Button>,
            <SaveButton
              color="primary"
              disabled={submitting || pristine || !canRenderForm || isLoading}
              key="save"
              onClick={() =>
                handleSave({ invalid, errors, values, handleSubmit })
              }
              variant="contained"
            >
              {submitting ? 'Saving...' : 'Save'}
            </SaveButton>,
          ]}
          id={modalId}
          onClose={submitting ? undefined : onClose}
          open={open}
          showCloseButton
          title={title}
        >
          {modalContents}
        </BaseModal>
      )}
    </Form>
  );
}
