import { CalendarApi } from '@fullcalendar/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { Tooltip } from '@monorepo/shared/componentsV2/Tooltip';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { addYears, subDays } from 'date-fns';
import React from 'react';

import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  CalendarHeaderContainer,
  CalendarNavigation,
  CalendarNavigationButtons,
  EmptyViewNotification,
  NextButton,
  PreviousButton,
  TodayButton,
} from './styled';

type ComplianceCalendarHeaderProps = {
  calendarApi?: CalendarApi;
  noVisibleEvents: boolean;
};

const NextButtonWithEndOfRangeText = ({
  canGoToNextMonth,
  goToNextMonth,
}: {
  canGoToNextMonth: boolean;
  goToNextMonth: () => void;
}) => (
  <Tooltip
    title={
      !canGoToNextMonth
        ? 'You can view calendar events up to one year into the future.'
        : ''
    }
  >
    <NextButton
      variant="contained"
      disabled={!canGoToNextMonth}
      onClick={goToNextMonth}
      focusableWhenDisabled
    >
      <VisuallyHidden>Next month</VisuallyHidden>
      <KeyboardArrowRightIcon aria-hidden />
    </NextButton>
  </Tooltip>
);

// for now, we only cache events up to one year into the future
export function getCalendarRange() {
  const yesterdayOneYearIntoTheFuture = subDays(addYears(new Date(), 1), 1);
  return { end: yesterdayOneYearIntoTheFuture };
}

export const ComplianceCalendarHeader = ({
  calendarApi,
  noVisibleEvents,
}: ComplianceCalendarHeaderProps) => {
  const { project } = useCurrentProject();
  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(
    project?.id,
  );

  const goToToday = () => {
    calendarApi?.today();
  };

  const goToPreviousMonth = () => {
    calendarApi?.prev();
  };

  const goToNextMonth = () => {
    calendarApi?.next();
  };

  const [visibleStart, visibleEnd] = [
    calendarApi?.view.currentStart,
    calendarApi?.view.currentEnd,
  ];

  const todayIsVisible =
    visibleStart &&
    visibleEnd &&
    visibleStart < new Date() &&
    new Date() < visibleEnd;

  const { end: endOfValidRange } = getCalendarRange();

  const canGoToNextMonth = visibleEnd && endOfValidRange > visibleEnd;

  const getNoVisibleEventsText = () => {
    if (!noVisibleEvents) return null;

    const manageTasksText = (
      <span>
        To manage action items in the calendar view,{' '}
        <Link to="?taskId=new">Add a Task</Link>.
      </span>
    );

    return (
      <EmptyViewNotification>
        <span>No tasks in the selected time frame.</span>{' '}
        {hasProjectUpdatePermission ? manageTasksText : null}
      </EmptyViewNotification>
    );
  };

  return (
    <CalendarHeaderContainer>
      <CalendarNavigation>
        <CalendarNavigationButtons>
          <TodayButton
            variant="contained"
            disabled={todayIsVisible}
            onClick={goToToday}
          >
            today
          </TodayButton>
          <PreviousButton variant="contained" onClick={goToPreviousMonth}>
            <VisuallyHidden>Previous month</VisuallyHidden>
            <KeyboardArrowLeftIcon aria-hidden />
          </PreviousButton>
          <NextButtonWithEndOfRangeText
            canGoToNextMonth={!!canGoToNextMonth}
            goToNextMonth={goToNextMonth}
          />
        </CalendarNavigationButtons>
        <h2>{calendarApi?.view.title}</h2>
      </CalendarNavigation>
      {getNoVisibleEventsText()}
    </CalendarHeaderContainer>
  );
};
