import LinkIcon from '@material-ui/icons/LaunchRounded';
import { Loading } from '@monorepo/shared/components/Loading';
import { IconButtonLink } from '@monorepo/shared/componentsV2/Button/IconButton';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { contentPaddingValue } from '@monorepo/shared/styles/layout';
import { linkTarget } from '@monorepo/shared/utils/linkTarget';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import { format } from 'date-fns';
import {
  localEquivalentOfUTC,
  LogSiteRequirementsSummary,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useHref } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useLogSiteSummary } from '../../hooks/useLogSiteSummary';

type LogSiteRequirementsSummaryRow = LogSiteRequirementsSummary & {
  id: string;
};

const TableContainer = styled.div`
  width: calc(100% + 2 * ${contentPaddingValue});
  margin: 0.5rem -${contentPaddingValue};
`;

const StyledTable = styled(Table)`
  padding-bottom: 4rem;
` as typeof Table;

function getColumns(logId: string, currentPath: string) {
  const orgPath = currentPath.split('/data-setup/')[0];

  const columns: TableColumn<LogSiteRequirementsSummaryRow>[] = [
    {
      header: 'Site',
      contents: 'projectName',
      id: 'projectName',
    },
    {
      header: 'Frequency Count',
      contents: 'frequencyRequirementCount',
      id: 'frequencyRequirementCount',
    },
    {
      header: 'Earliest Frequency Start Date',
      contents: (row) =>
        row.earliestFrequencyStartDate
          ? format(
              localEquivalentOfUTC(row.earliestFrequencyStartDate),
              'MM/dd/yy',
            )
          : '',
      id: 'earliestFrequencyStartDate',
    },
    {
      header: 'View Count',
      contents: 'viewCount',
      id: 'viewCount',
    },
    {
      header: 'Limit Count',
      contents: 'limitCount',
      id: 'limitCount',
    },
    {
      header: 'Last Entry Date',
      contents: (row) =>
        row.lastEntryDate
          ? format(localEquivalentOfUTC(row.lastEntryDate), 'Pp')
          : '',
      id: 'lastEntryDate',
    },
    {
      align: 'center',
      header: 'Site Configuration',
      contents: (row) => (
        <IconButtonLink
          color="primary"
          target={linkTarget.NEW_BROWSER_TAB}
          to={`${orgPath}/sites/${row.projectId}/logs/${logId}/frequencySettings`}
        >
          <VisuallyHidden>
            Go to {row.projectName} configuration page
          </VisuallyHidden>
          <LinkIcon />
        </IconButtonLink>
      ),
      id: 'siteLink',
    },
  ];
  return columns;
}

interface LogSiteRequirementsSummaryTableProps {
  filter: string;
  logId?: string;
  organizationId?: string;
}

export function LogSiteRequirementsSummaryTable({
  filter,
  logId,
  organizationId,
}: LogSiteRequirementsSummaryTableProps) {
  const { summary, isLoading } = useLogSiteSummary({ logId, organizationId });
  const rows: LogSiteRequirementsSummaryRow[] = useMemo(
    () => (summary || []).map((s) => ({ ...s, id: s.projectId })),
    [summary],
  );

  const currentPath = useHref('.');
  const tableColumns = useMemo(
    () => (logId ? getColumns(logId, currentPath) : []),
    [currentPath, logId],
  );

  const filterSummaryBy = useCallback(
    (row: LogSiteRequirementsSummary) => lookFor(filter, row, ['projectName']),
    [filter],
  );

  return (
    <TableContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <StyledTable<LogSiteRequirementsSummaryRow>
          ariaLabel="site requirements summary"
          columns={tableColumns}
          rows={rows}
          rowFilter={filterSummaryBy}
        />
      )}
    </TableContainer>
  );
}
