import { ToggleButtons } from '@monorepo/shared/components/ToggleButtons';
import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import { DefaultCustomFrequency } from '@monorepo/shared/contexts/FrequencyContext/types';
import { CustomFrequencyErrors } from '@monorepo/shared/utils/CustomFrequencyValidator';
import { customFrequencyIntervalOptions } from '@monorepo/shared/utils/frequencyUtils';
import { Days, IntervalFrequencyEnum, Months } from 'mapistry-shared';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import { Select } from '../Select';
import { ErrorText } from '../styled/form';

const RepeatToggleButtons = styled(ToggleButtons)`
  padding-top: 1rem;

  & button.mapistry-toggle-button {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grayddd};
    }

    &.selected {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.lightBlue};

      &:hover {
        background-color: ${({ theme }) => theme.colors.lightBlue};
      }
    }

    &:focus-visible {
      outline: solid 1px black;
    }
  }
`;

const FormContainer = styled.div`
  padding-bottom: 1rem;
`;

const RepeatContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    padding-right: 0.5rem;
  }
`;

const RepeatInput = styled(Input)`
  max-width: 4rem;
`;

const EveryIntervalSelect = styled(Select)`
  max-width: 12rem;
`;

type CustomFrequencyFormProps = {
  errors?: CustomFrequencyErrors;
};

export function CustomFrequencyForm({ errors }: CustomFrequencyFormProps) {
  // does not yet support "integrated" start date, such that start date lines up with the custom frequency selected (e.g. a custom frequency of every n months has to start on the first of a month)

  const {
    customFrequency = DefaultCustomFrequency,
    onFrequencySelect,
    repeatOptions,
    selectedFrequency,
  } = useContext(FrequencyContext);

  const { every, numberOfEvents } = customFrequency;

  return (
    <FormContainer>
      <RepeatContainer>
        <div>Repeat</div>
        <RepeatInput
          type="number"
          label="Repeat"
          hiddenLabel
          name="repeat"
          inputProps={{ min: 1 }}
          value={numberOfEvents.toString()}
          onChange={({ target: { value } }: { target: { value: string } }) =>
            onFrequencySelect(selectedFrequency, {
              ...customFrequency,
              numberOfEvents: parseInt(value, 10),
            })
          }
          required
          error={errors?.numberOfEventsError}
          dontSaveSpaceForErrorText
        />
        <div>time(s) every</div>
        <RepeatInput
          type="number"
          label="Every"
          hiddenLabel
          name="every"
          inputProps={{ min: 1 }}
          value={every.toString()}
          onChange={({ target: { value } }: { target: { value: string } }) =>
            onFrequencySelect(selectedFrequency, {
              ...customFrequency,
              every: parseInt(value, 10),
            })
          }
          required
          error={errors?.everyError}
          dontSaveSpaceForErrorText
        />
        <EveryIntervalSelect
          required
          name="baseInterval"
          label="Repeat Interval"
          hiddenLabel
          options={customFrequencyIntervalOptions}
          value={selectedFrequency || ''}
          onChange={({ target: { value } }) =>
            onFrequencySelect(value as IntervalFrequencyEnum, customFrequency)
          }
          dontSaveSpaceForErrorText
        />
      </RepeatContainer>
      {!!errors?.messages && (
        <ErrorText>
          {errors.messages.map((message) => (
            <div key={message}>{message}</div>
          ))}
        </ErrorText>
      )}
      {repeatOptions.length > 0 && (
        <RepeatToggleButtons
          onChange={(value) =>
            onFrequencySelect(selectedFrequency, {
              ...customFrequency,
              repeatOn: value as Days[] | Months[],
            })
          }
          options={repeatOptions}
          size={every === 1 ? 'small' : 'medium'}
          value={customFrequency?.repeatOn ?? []}
        />
      )}
    </FormContainer>
  );
}
