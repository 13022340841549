import { useContext } from 'react';
import { DateRangeFilterContext } from '../contexts/DateRangeFilterContext';

export const useDateRangeFilter = () => {
  const context = useContext(DateRangeFilterContext);

  if (context === undefined) {
    throw new Error(
      'useDateRangeFilter must be used within a DateRangeFilterProvider',
    );
  }
  return context;
};
