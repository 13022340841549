import CalendarIcon from '@svg/calendar.svg';
import CircleCheckIcon from '@svg/circle-check.svg';
import ClockIcon from '@svg/clock.svg';
import HashIcon from '@svg/hash.svg';
import PersonIcon from '@svg/person.svg';
import ResourceIcon from '@svg/resource.svg';
import SignatureIcon from '@svg/signature.svg';
import TextIcon from '@svg/text.svg';
import ToggleIcon from '@svg/toggle.svg';
import { FieldDataType, InputFieldDataType } from 'mapistry-shared';
import React from 'react';

export const fieldTypeOptions: Record<
  InputFieldDataType,
  { icon: React.ReactElement; label: string; value: FieldDataType }
> = {
  [FieldDataType.BOOLEAN]: {
    icon: <ToggleIcon />,
    label: 'Boolean',
    value: FieldDataType.BOOLEAN,
  },
  [FieldDataType.DATE]: {
    icon: <CalendarIcon />,
    label: 'Date',
    value: FieldDataType.DATE,
  },
  [FieldDataType.DATETIME]: {
    icon: <CalendarIcon />,
    label: 'Date & Time',
    value: FieldDataType.DATETIME,
  },
  [FieldDataType.LOCATION]: {
    icon: <TextIcon />, // TODO needs a proper icon
    label: 'Location',
    value: FieldDataType.LOCATION,
  },
  [FieldDataType.NUMERIC]: {
    icon: <HashIcon />,
    label: 'Number',
    value: FieldDataType.NUMERIC,
  },
  [FieldDataType.RESOURCE]: {
    icon: <ResourceIcon />,
    label: 'Resource',
    value: FieldDataType.RESOURCE,
  },
  [FieldDataType.SIGNATURE]: {
    icon: <SignatureIcon />,
    label: 'Signature',
    value: FieldDataType.SIGNATURE,
  },
  [FieldDataType.SINGLE_SELECT]: {
    icon: <CircleCheckIcon />,
    label: 'Single Select',
    value: FieldDataType.SINGLE_SELECT,
  },
  [FieldDataType.TEXT]: {
    icon: <TextIcon />,
    label: 'Text',
    value: FieldDataType.TEXT,
  },
  [FieldDataType.TIME]: {
    icon: <ClockIcon />,
    label: 'Time',
    value: FieldDataType.TIME,
  },
  [FieldDataType.USER]: {
    icon: <PersonIcon />,
    label: 'User',
    value: FieldDataType.USER,
  },
  [FieldDataType.PARAMETER]: {
    icon: <TextIcon />, // TODO needs a proper icon
    label: 'Parameter',
    value: FieldDataType.PARAMETER,
  },
  [FieldDataType.SUBSTANCE]: {
    icon: <TextIcon />, // TODO needs a proper icon
    label: 'Substance',
    value: FieldDataType.SUBSTANCE,
  },
};
