import { SwitchField } from '@monorepo/shared/componentsV2/fields/SwitchField';
import {
  StyledFormGroup,
  StyledInput,
  StyledTextArea,
} from '@monorepo/shared/componentsV2/styled/form';
import { isRequired } from '@monorepo/shared/utils/validators';
import _pick from 'lodash.pick';
import { ResourceTypeRefResponse } from 'mapistry-shared';
import React from 'react';

export type FormValues = Pick<
  ResourceTypeRefResponse,
  'name' | 'description' | 'isSiteSpecific'
>;

export function pickFormValues(
  resourceType: ResourceTypeRefResponse,
): FormValues {
  const keys: (keyof FormValues)[] = ['name', 'description', 'isSiteSpecific'];
  return _pick(resourceType, keys);
}

interface EditResourceTypeFormProps {
  isSiteToggleDisabled: boolean;
}

export function EditResourceTypeForm({
  isSiteToggleDisabled,
}: EditResourceTypeFormProps) {
  return (
    <>
      <StyledFormGroup row>
        <StyledInput<FormValues['name']>
          label="Resource Type Name"
          name="name"
          placeholder="Name"
          validate={isRequired}
          required
        />
      </StyledFormGroup>
      <StyledFormGroup row>
        <StyledTextArea<NonNullable<FormValues['description']>>
          helperText="Information to help identify this Resource type, the purpose or use cases."
          label="Description"
          multiline
          name="description"
          placeholder="Description"
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <SwitchField<FormValues['isSiteSpecific']>
          checkedLabel="On"
          disabled={isSiteToggleDisabled}
          label="Site Association"
          name="isSiteSpecific"
          uncheckedLabel="Off"
          helperText="Turn this setting on to associate each of your Resources with a single site.
            This setting cannot be changed once Resources are added, or in certain circumstances once this Resource type
            links to or gets linked to from other Resource types via Resource properties."
        />
      </StyledFormGroup>
    </>
  );
}
