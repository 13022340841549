import { Button } from '@monorepo/shared/componentsV2/Button';
import React from 'react';
import { BaseModal } from './BaseModal';

type AlertModalProps = Omit<
  React.ComponentProps<typeof BaseModal>,
  'buttons' | 'children' | 'onClose'
> & {
  buttonText?: string;
  onDone: () => void;
};

export function AlertModal({
  buttonText = 'OK',
  description,
  onDone,
  open,
  title,
  showCloseButton,
}: AlertModalProps) {
  return (
    <BaseModal
      open={open}
      title={title}
      description={description}
      buttons={
        <Button onClick={onDone} color="primary">
          {buttonText}
        </Button>
      }
      onClose={onDone}
      showCloseButton={showCloseButton}
    />
  );
}
