import { ListBuilderField } from '@monorepo/shared/componentsV2/fields/ListBuilderField';
import { bodyRegular } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const AlertText = styled.div`
  ${bodyRegular}
  margin-bottom: 1rem;
`;

export const StyledListBuilderField = styled(ListBuilderField)`
  & div.MuiInputBase-root {
    width: 100%;
  }
`;
