import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourcePropertyCache,
  ResourcePropertyCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  getCache,
  setCache,
} from '@monorepo/shared/hooks/resources/useResourceProperties';
import { FieldResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type UseResourcePropertyDeleteParams = {
  config?: MutationConfig<
    Api.DeleteResourcePropertyResponse,
    Api.ErrorResponse
  >;
};

export const useResourcePropertyDelete = (
  params?: UseResourcePropertyDeleteParams,
) =>
  useMutation(Api.deleteResourceProperty, {
    ...params?.config,
    onMutate: (mutationFuncParams) => {
      const { organizationId, resourceTypeId, resourcePropertyId } =
        mutationFuncParams;

      // Optimistically remove from the list if there's cache
      const listParams = { organizationId, resourceTypeId };
      const currentItems = getCache(listParams);

      let removedProperty: FieldResponse | undefined;
      // If there is cache, update it
      if (currentItems) {
        removedProperty = currentItems.find((p) => p.id === resourcePropertyId);
        const updatedItems = currentItems.filter(
          (p) => p.id !== resourcePropertyId,
        );
        setCache(listParams, updatedItems);
      }

      return () => {
        // Put the resource property back in the list if cache is still there
        const items = getCache(listParams);
        if (items && removedProperty) {
          setCache(listParams, [...items, removedProperty]);
        }
      };
    },
    onSuccess: (_, { organizationId, resourceTypeId, resourcePropertyId }) =>
      // Invalidate any RQ cache that might use information about Resource Property
      invalidateResourcePropertyCache(ResourcePropertyCacheEvents.DELETE, {
        organizationId,
        resourcePropertyId,
        resourceTypeId,
      }),
    onError: (error, mutationFuncParams, rollback) => {
      if (params?.config?.onError) {
        return params.config.onError(error, mutationFuncParams, rollback);
      }
      return rollback();
    },
  });
