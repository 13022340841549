import { formatISO } from 'date-fns';
import _get from 'lodash.get';
import {
  PhotosAndAttachmentsToIncludeInPdf,
  TasksToIncludeInPdf,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  emailCalendarAttachmentsAction,
  fetchCalendarAttachmentCountAction,
  resetCalendarErrorStateAction,
} from '../../../../../actions/calendar';
import APP from '../../../../../config';
import { DownloadState } from '../../../../../types/DownloadState';
import withProvider from '../../../../withProvider';
import { DownloadInspectionsModal } from './DownloadInspectionsModal';
import { isValidDate } from '../../../../../utils/index.js';

const EMAIL_LIMIT = 100;

class DownloadInspectionsModalContainer extends Component {
  constructor(props) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleWithTaskOptionChange =
      this.handleWithTaskOptionChange.bind(this);
    this.handleIncludeTaskAttachmentsChange =
      this.handleIncludeTaskAttachmentsChange.bind(this);
    this.handleIncludeRegularFormContentChange =
      this.handleIncludeRegularFormContentChange.bind(this);
    this.handlePhotoOptionChange = this.handlePhotoOptionChange.bind(this);
    const defaultStartDate = new Date();
    defaultStartDate.setDate(1);
    const defaultEndDate = new Date();
    defaultEndDate.setMonth(defaultEndDate.getMonth() + 1, 0);
    this.state = {
      downloadState: props.isDownloading
        ? DownloadState.DOWNLOADING
        : DownloadState.DEFAULT,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      includeTasksOption: TasksToIncludeInPdf.ALL,
      includeTaskAttachments: true,
      includeRegularFormContent: true,
      includePhotosAndAttachmentsOption: PhotosAndAttachmentsToIncludeInPdf.ALL,
    };
  }

  componentDidMount() {
    this.fetchInspectionCount();
  }

  componentDidUpdate(prevProps) {
    const { isDownloading, serverErrorMessage } = this.props;
    if (prevProps.isDownloading && !isDownloading) {
      this.setState({
        downloadState: serverErrorMessage
          ? DownloadState.DEFAULT
          : DownloadState.DOWNLOADED,
      });
    }
  }

  handleClose() {
    const { onClose, resetErrorState } = this.props;
    resetErrorState();
    onClose();
  }

  handleEndDateChange(endDate) {
    const { resetErrorState } = this.props;
    resetErrorState();
    this.setState(
      { endDate, downloadState: DownloadState.DEFAULT },
      this.fetchInspectionCount,
    );
  }

  handleStartDateChange(startDate) {
    const { resetErrorState } = this.props;
    resetErrorState();
    this.setState(
      { startDate, downloadState: DownloadState.DEFAULT },
      this.fetchInspectionCount,
    );
  }

  handleWithTaskOptionChange(includeTasksOption) {
    const { resetErrorState } = this.props;
    resetErrorState();
    this.setState({
      includeTasksOption,
      downloadState: DownloadState.DEFAULT,
    });
  }

  handleIncludeTaskAttachmentsChange(includeTaskAttachments) {
    const { resetErrorState } = this.props;
    resetErrorState();
    this.setState({
      includeTaskAttachments,
      downloadState: DownloadState.DEFAULT,
    });
  }

  handleIncludeRegularFormContentChange(includeRegularFormContent) {
    const { resetErrorState } = this.props;
    resetErrorState();
    this.setState({
      includeRegularFormContent,
      downloadState: DownloadState.DEFAULT,
    });
  }

  handlePhotoOptionChange(includePhotosAndAttachmentsOption) {
    const { resetErrorState } = this.props;
    resetErrorState();
    this.setState({
      includePhotosAndAttachmentsOption,
      downloadState: DownloadState.DEFAULT,
    });
  }

  handleDownload() {
    const { calendarId, emailInspections, projectId, resetErrorState } =
      this.props;
    const { startDate, endDate } = this.state;
    const formattedStartDate = formatISO(startDate, { representation: 'date' });
    const formattedEndDate = formatISO(endDate, { representation: 'date' });
    const pdfOptions = this.getPdfOptions();

    resetErrorState();
    this.setState({ downloadState: DownloadState.DOWNLOADING }, () =>
      emailInspections(
        projectId,
        calendarId,
        formattedStartDate,
        formattedEndDate,
        pdfOptions,
      ),
    );
  }

  getPdfOptions() {
    const {
      includeTaskAttachments,
      includeRegularFormContent,
      includeTasksOption,
      includePhotosAndAttachmentsOption,
    } = this.state;
    const pdfOptions = {
      includeTasksOption,
      includeTaskAttachments,
      includeRegularFormContent,
      includePhotosAndAttachmentsOption,
    };

    return pdfOptions;
  }

  fetchInspectionCount() {
    const { calendarId, fetchAttachmentCount, projectId } = this.props;
    const { startDate, endDate } = this.state;
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      return;
    }
    const formattedStartDate = formatISO(startDate, { representation: 'date' });
    const formattedEndDate = formatISO(endDate, { representation: 'date' });

    fetchAttachmentCount(
      projectId,
      calendarId,
      formattedStartDate,
      formattedEndDate,
    );
  }

  render() {
    const {
      attachmentCount,
      isFetchingCount,
      open,
      serverErrorMessage,
      title,
    } = this.props;
    const {
      downloadState,
      endDate,
      startDate,
      includeTasksOption,
      includeTaskAttachments,
      includeRegularFormContent,
      includePhotosAndAttachmentsOption,
    } = this.state;

    return (
      <DownloadInspectionsModal
        downloadState={downloadState}
        emailLimit={EMAIL_LIMIT}
        errors={serverErrorMessage}
        isFetchingCount={isFetchingCount}
        numberOfInspections={attachmentCount}
        onClose={this.handleClose}
        onDownload={this.handleDownload}
        onEndDateChange={this.handleEndDateChange}
        onStartDateChange={this.handleStartDateChange}
        onWithTaskOptionChange={this.handleWithTaskOptionChange}
        onIncludeTaskAttachmentsChange={this.handleIncludeTaskAttachmentsChange}
        onIncludeRegularFormContentChange={
          this.handleIncludeRegularFormContentChange
        }
        onPhotoOptionChange={this.handlePhotoOptionChange}
        open={open}
        endDate={endDate}
        startDate={startDate}
        includeTasksOption={includeTasksOption}
        includeTasksAttachments={includeTaskAttachments}
        includeRegularFormContent={includeRegularFormContent}
        includePhotosAndAttachmentsOption={includePhotosAndAttachmentsOption}
        title={title}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { calendar } = state;
  return {
    attachmentCount: calendar.attachmentCounts[ownProps.calendarId] || 0,
    isDownloading: calendar.isFetching.downloadCalendarAttachments,
    isFetchingCount: calendar.isFetching.calendarAttachmentCount,
    projectId: APP.projectId,
    serverErrorMessage: _get(calendar.errorMessage, 'message', null) && [
      'There was a problem with the download.',
    ],
  };
};

const mapDispatchToProps = (dispatch) => ({
  emailInspections: (projectId, calendarId, startDate, endDate, pdfOptions) =>
    dispatch(
      emailCalendarAttachmentsAction({
        projectId,
        calendarId,
        startDate,
        endDate,
        pdfOptions,
      }),
    ),

  fetchAttachmentCount: (projectId, calendarId, startDate, endDate) =>
    dispatch(
      fetchCalendarAttachmentCountAction({
        projectId,
        calendarId,
        startDate,
        endDate,
      }),
    ),
  resetErrorState: () => dispatch(resetCalendarErrorStateAction()),
});

DownloadInspectionsModalContainer.propTypes = {
  attachmentCount: PropTypes.number.isRequired,
  calendarId: PropTypes.string.isRequired,
  emailInspections: PropTypes.func.isRequired,
  fetchAttachmentCount: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool,
  isFetchingCount: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  resetErrorState: PropTypes.func.isRequired,
  serverErrorMessage: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

DownloadInspectionsModalContainer.defaultProps = {
  isDownloading: false,
  isFetchingCount: false,
  open: false,
  serverErrorMessage: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DownloadInspectionsModalContainer),
);
