import MuiDrawer from '@material-ui/core/Drawer';
import { A } from '@monorepo/shared/componentsV2/Link';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { focusOutline } from '@monorepo/shared/styles/focus';
import Logo from '@svg/Logo.svg';
import React from 'react';
import styled from 'styled-components';

const Drawer = styled(MuiDrawer)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.sizes.navbarWidth};

  & .MuiDrawer-paper {
    width: ${({ theme }) => theme.sizes.navbarWidth};
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 59px;
  margin-bottom: 15px;

  & a {
    padding: 12.5px;
  }

  & svg {
    width: 40px;
    max-height: 30px;
  }
`;

const StyledA = styled(A)`
  &:focus-visible {
    ${focusOutline({ $outlineOffset: '-2px' })}
  }
`;

type BaseNavBarProps = { children?: React.ReactNode };

export function BaseNavBar({ children }: BaseNavBarProps) {
  return (
    <Drawer variant="permanent" anchor="left">
      <LogoContainer>
        <StyledA href="/">
          <Logo aria-hidden />
          <VisuallyHidden>Home</VisuallyHidden>
        </StyledA>
      </LogoContainer>
      {children}
    </Drawer>
  );
}
