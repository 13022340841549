import { compose, isDateOrNullish } from '@monorepo/shared/utils/validators';
import React, { useMemo } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { DatetimePicker } from '../dateAndTimePickers';

type DatetimePickerFieldProps = Omit<
  React.ComponentProps<typeof DatetimePicker>,
  'value' | 'onChange' | 'error' | 'errorText'
> & {
  initialValue?: Date;
  name: string;
  validate?: UseFieldConfig<Date>['validate'];
};

export function DatetimePickerField({
  name,
  validate,
  initialValue,
  ...rest
}: DatetimePickerFieldProps) {
  const composedValidate = useMemo(
    () => (validate ? compose(validate, isDateOrNullish) : isDateOrNullish),
    [validate],
  );

  const { input, meta } = useField<Date>(name, {
    validate: composedValidate,
    initialValue,
  });

  const hasError = (meta.touched || meta.modified) && !!meta.error;
  return (
    <DatetimePicker
      {...rest}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
      value={input.value}
      onChange={input.onChange}
      name={name}
    />
  );
}
