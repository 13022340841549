import {
  BooleanValue,
  DatetimeValue,
  DateValue,
  FieldDataType,
  FormulaValue,
  LocationValue,
  NumericValue,
  ParameterValue,
  ResourceValue,
  SaveFieldValue,
  SignatureValue,
  SingleSelectValue,
  SpecificFieldValue,
  SubstanceValue,
  TextValue,
  TimeValue,
  UserValue,
} from 'mapistry-shared';

interface ConvertSpecificFieldValueToSaveValueParams {
  fieldType: FieldDataType;
  fieldValue: SpecificFieldValue;
}

export const convertSpecificFieldValueToSaveFieldValue = ({
  fieldType,
  fieldValue,
}: ConvertSpecificFieldValueToSaveValueParams): SaveFieldValue => {
  switch (fieldType) {
    case FieldDataType.BOOLEAN:
    case FieldDataType.DATE:
    case FieldDataType.DATETIME:
    case FieldDataType.FORMULA:
    case FieldDataType.NUMERIC:
    case FieldDataType.SINGLE_SELECT:
    case FieldDataType.TEXT:
    case FieldDataType.TIME:
      return fieldValue as
        | BooleanValue
        | DateValue
        | DatetimeValue
        | FormulaValue
        | NumericValue
        | SingleSelectValue
        | TextValue
        | TimeValue;
    case FieldDataType.LOCATION:
    case FieldDataType.PARAMETER:
    case FieldDataType.RESOURCE:
    case FieldDataType.SIGNATURE:
    case FieldDataType.SUBSTANCE:
    case FieldDataType.USER:
      return {
        value:
          (
            fieldValue as
              | LocationValue
              | ParameterValue
              | ResourceValue
              | SignatureValue
              | SubstanceValue
              | UserValue
          ).value?.id || null,
      };
    default: {
      const exhaustiveCheck: never = fieldType;
      throw new Error(`Unhandled field data type - ${exhaustiveCheck}`);
    }
  }
};
