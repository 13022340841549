import { Button } from '@monorepo/shared/componentsV2/Button';
import { A } from '@monorepo/shared/componentsV2/Link';
import { contentPadding, page } from '@monorepo/shared/styles/layout';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { DocumentTitle } from '../DocumentTitle';
import { Main } from '../Main';
import { BaseNavBar } from '../NavigationTabs/BaseNavBar';
import { UserFriendlyErrorMessage } from './UserFriendlyErrorMessage';

const Page = styled(Main)`
  ${page}
  ${contentPadding}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const Content = styled.div`
  max-width: 70vw;
`;

const Header = styled.h1`
  font-size: 4rem;
  font-weight: 600;
  line-height: 3.5rem;
`;

const Bold = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.5rem;
`;

const Section = styled.div`
  padding-top: 1rem;
`;

const ContactLinks = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  & > * {
    margin-right: 1rem;
  }
`;

export const FullPageFatalError = ({ error }: FallbackProps) => (
  <Page>
    <BaseNavBar />
    <Content>
      <DocumentTitle title="Error" />
      <Header>Oops, something went wrong.</Header>
      <Section>
        <Bold>
          If this is the first time encountering the problem, please refresh the
          page and try again. Otherwise, please contact the support team for
          assistance.
        </Bold>
      </Section>

      <Section>
        <UserFriendlyErrorMessage error={error} />
      </Section>

      <ContactLinks>
        <span>
          <A href="tel:1-800-553-7420">(800) 553-7420 ext 1</A>
        </span>
        <span>|</span>
        <span>
          <A href="mailto:help@mapistry.com">help@mapistry.com</A>
        </span>
      </ContactLinks>

      {/* If something bubbled all the way up here, trigger a full page refresh instead of just resetting the error boundary */}
      <Section>
        <Button
          variant="contained"
          size="large"
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </Button>
      </Section>
    </Content>
  </Page>
);
