import {
  DisplayQueryColumn,
  DisplayResultRow,
  LimitColumnValue,
  SpecialColumnType,
} from '@monorepo/logs/src/components/Views/ViewResultsTable/resultsMapper';
import {
  ColumnSet,
  ColumnType,
  DateLike,
  DateRange,
  QueryColumn,
} from 'mapistry-shared';
import React from 'react';
import { BooleanTableCell } from './BooleanTableCell';
import { DateRangeTableCell } from './DateRangeTableCell';
import { DatetimeTableCell } from './DatetimeTableCell';
import { LimitTableCell } from './LimitTableCell';
import { NumberTableCell } from './NumberTableCell';
import { TextTableCell } from './TextTableCell';
import { TimeTableCell } from './TimeTableCell';

interface ViewResultsTableCellFactoryProps {
  column: DisplayQueryColumn;
  columns: QueryColumn[];
  columnSets: ColumnSet[];
  row: DisplayResultRow;
}

export const ViewResultsTableCellFactory = ({
  column,
  columns,
  columnSets,
  row,
}: ViewResultsTableCellFactoryProps) => {
  const { columnName, columnType, units } = column;
  const value = row[columnName];
  if (value === null || value === undefined) return null;
  switch (columnType) {
    case ColumnType.BOOLEAN:
      return <BooleanTableCell value={value as boolean} />;
    case ColumnType.DATETIME:
      return <DatetimeTableCell value={value as DateLike} />;
    case ColumnType.DATE_RANGE:
      return <DateRangeTableCell value={value as DateRange} />;
    case ColumnType.NUMBER:
      return <NumberTableCell value={value as number} units={units} />;
    case ColumnType.TEXT:
      return <TextTableCell value={value as string} />;
    case ColumnType.TIME:
      return <TimeTableCell value={value as string} />;
    case SpecialColumnType.LIMIT_COLUMN:
      return <LimitTableCell value={value as LimitColumnValue} />;
    case ColumnType.FOREIGN_ID: {
      const set = columnSets.find(
        (cs) => cs.parentColumn === column.columnName,
      );
      const displayColumn = columns.find(
        (col) => col.columnName === set?.displayColumn,
      );
      if (!set || !displayColumn) {
        // this foreign ID shouldn't be shown... log an error (?) but don't make the whole table blow up
        // or the column couldn't be found??
        return null;
      }
      return (
        <ViewResultsTableCellFactory
          column={displayColumn}
          columns={columns}
          columnSets={columnSets}
          row={row}
        />
      );
    }
    default: {
      const exhaustiveCheck: never = columnType;
      return <>Invalid column type - {exhaustiveCheck}</>;
    }
  }
};
