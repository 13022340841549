import { Loading } from '@monorepo/shared/components/Loading';
import { useLogFields } from '@monorepo/shared/hooks/logs/useLogFields';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';

interface FieldCountProps {
  logId: string;
}

export function FieldCount({ logId }: FieldCountProps) {
  const { organizationId } = useParams();
  const { isLoading, logFields } = useLogFields({ logId, organizationId });

  if (isLoading) {
    return <Loading size="small" />;
  }

  /** Add 1 to the count to take the mandatory Log Date field into account */
  return <span>{(logFields?.length || 0) + 1}</span>;
}
