import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import APP from '../../../../config';

import { FORM_SLUGS } from '../../../../types/ApplicationShared';
import withProvider from '../../../withProvider';

import { fetchFormSubmissionsBySlugAction } from '../../../../actions/formSubmissions';

import { Covid19ResponseCard } from './Covid19ResponseCard';

class Covid19ResponseCardContainer extends Component {
  componentDidMount() {
    const { fetchFormSubmissionsBySlug } = this.props;
    fetchFormSubmissionsBySlug(
      APP.projectId,
      FORM_SLUGS.COVID_19_RESPONSE_PLAN,
      FORM_SLUGS.COVID_19_RESPONSE_PLAN,
    ).catch(() => {
      // We legitimately may not have Covid plan set up yet, and server responds with 404.
      // Nothing to do here. This component doesn't have an error state.
      // Our Redux API middleware is setup the way that dispatch will return
      // resolved or rejected Promise. Poor design but here we are.
      // So, it's component responsibility to handle rejections,
      // otherwise unhandled rejection will end up in our error logs.
    });
  }

  render() {
    const { submissionId } = this.props;

    const formSlug = FORM_SLUGS.COVID_19_RESPONSE_PLAN;
    return (
      <Covid19ResponseCard
        submissionId={submissionId}
        goToFormPath={`/projects/${APP.projectId}/form-submissions/${formSlug}/${formSlug}`}
        formSubmissionExists={!!submissionId}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { formSubmissions } = state;

  return {
    submissionId: _get(
      formSubmissions.formSubmissions,
      `${FORM_SLUGS.COVID_19_RESPONSE_PLAN}.id`,
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFormSubmissionsBySlug: (projectId, submissionSlug, templateSlug) =>
    dispatch(
      fetchFormSubmissionsBySlugAction(projectId, submissionSlug, templateSlug),
    ),
});

Covid19ResponseCardContainer.propTypes = {
  fetchFormSubmissionsBySlug: PropTypes.func.isRequired,
  submissionId: PropTypes.number,
};

Covid19ResponseCardContainer.defaultProps = {
  submissionId: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(Covid19ResponseCardContainer),
);
