import MuiList from '@material-ui/core/List';
import MuiListSubheader from '@material-ui/core/ListSubheader';
import React from 'react';
import styled from 'styled-components';

export interface ListItemWithHeaders {
  header: string;
  items: React.ReactElement[];
}

const StyledList = styled(MuiList)`
  padding: 0;
  margin: 0;
`;

type ListWithHeadersProps = Omit<
  React.ComponentProps<typeof MuiList>,
  'children'
> & {
  listItems: ListItemWithHeaders[];
};
type ListWithoutHeadersProps = React.ComponentProps<typeof MuiList>;

const hasHeaders = (
  x: ListWithHeadersProps | ListWithoutHeadersProps,
): x is ListWithHeadersProps => 'listItems' in x;

export function List(props: ListWithHeadersProps): JSX.Element;
export function List(props: ListWithoutHeadersProps): JSX.Element;
export function List(props: ListWithHeadersProps | ListWithoutHeadersProps) {
  if (hasHeaders(props)) {
    const { listItems, ...rest } = props;
    return (
      <StyledList {...rest}>
        {listItems.map((listItem) => (
          <React.Fragment key={listItem.header}>
            <MuiListSubheader title={listItem.header}>
              {listItem.header}
            </MuiListSubheader>
            {listItem.items.map((item) => (
              /*
               * key should be set in each item by the caller since we have no way of
               * figuring out a unique key here. If the caller does not set the key
               * an undefined key should display errors in the console which should
               * hopefully bring the developer to this message and the solution
               */
              <React.Fragment key={item.key || undefined}>
                {item}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </StyledList>
    );
  }
  return <StyledList {...props} />;
}
