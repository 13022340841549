import {
  CreateFieldRequest,
  FieldDataType,
  FieldResponse,
  NumericFieldResponse,
  ResourceFieldResponse,
  SingleSelectFieldResponse,
  UpdateFieldRequest,
} from 'mapistry-shared';
import React, { useCallback, useMemo, useState } from 'react';
import {
  BaseFieldWizardModal,
  getError,
  mapFormValuesToBaseRequestDto,
} from './BaseFieldWizardModal';
import { DataTypeFactory } from './DataTypeFactory';
import { FieldDataTypeSelector } from './FieldDataTypeSelector';

function mapFormValuesToRequestDto(
  values: Partial<FieldResponse>,
): CreateFieldRequest | UpdateFieldRequest {
  const baseRequestDto = mapFormValuesToBaseRequestDto(values);

  switch (values.type) {
    case FieldDataType.NUMERIC: {
      const { units } = values as Partial<NumericFieldResponse>;
      return { ...baseRequestDto, units: units || null };
    }
    case FieldDataType.SINGLE_SELECT: {
      const { options } = values as Partial<SingleSelectFieldResponse>;
      if (!options) throw getError(values);
      return { ...baseRequestDto, options };
    }
    case FieldDataType.RESOURCE: {
      const { resourceTypeId } = values as Partial<ResourceFieldResponse>;
      if (!resourceTypeId) throw getError(values);
      return { ...baseRequestDto, resourceTypeId };
    }
    default:
      return baseRequestDto;
  }
}

interface FieldDataTypeWizardModalProps {
  availableFieldTypes: React.ComponentProps<
    typeof FieldDataTypeSelector
  >['availableFieldTypes'];
  field?: FieldResponse;
  fieldTypeName?: string;
  isRequiredHelpText?: string;
  onClose: () => void;
  onSubmit: (values: CreateFieldRequest | UpdateFieldRequest) => Promise<void>;
}

export function FieldDataTypeWizardModal({
  availableFieldTypes,
  field: inboundField,
  fieldTypeName = 'field',
  isRequiredHelpText,
  onClose,
  onSubmit: onSubmitProp,
}: FieldDataTypeWizardModalProps) {
  const isEditing = !!inboundField;

  const [field, setField] = useState<Partial<FieldResponse>>(
    inboundField || {},
  );

  const wizardPages = useMemo(
    () => [
      <FieldDataTypeSelector
        availableFieldTypes={availableFieldTypes}
        fieldTypeName={fieldTypeName}
      />,
      <DataTypeFactory
        dataType={field.type}
        fieldTypeName={fieldTypeName}
        isRequiredHelpText={isRequiredHelpText}
        isEditing={isEditing}
      />,
    ],
    [
      availableFieldTypes,
      field.type,
      fieldTypeName,
      isEditing,
      isRequiredHelpText,
    ],
  );

  const isUpdateRequest = (
    x: CreateFieldRequest | UpdateFieldRequest | Partial<FieldResponse>,
  ): x is UpdateFieldRequest => 'id' in x && x.id != null;
  const isCreateRequest = useCallback(
    (
      x: CreateFieldRequest | UpdateFieldRequest | Partial<FieldResponse>,
    ): x is CreateFieldRequest =>
      !isUpdateRequest(x) && x.name != null && x.type != null,
    [],
  );

  const onSubmit = useCallback(
    async (formValues: Partial<FieldResponse>, pageNumber) => {
      const isLastPage = pageNumber === wizardPages.length - 1;
      const values = isLastPage
        ? mapFormValuesToRequestDto(formValues)
        : formValues;
      if (pageNumber === 0 && !isUpdateRequest(values)) {
        values.type === field.type
          ? setField({ ...field, ...values })
          : setField({ type: values.type });
        return;
      }

      if (isUpdateRequest(values) || isCreateRequest(values)) {
        await onSubmitProp(values);
      }
    },
    [field, isCreateRequest, onSubmitProp, wizardPages.length],
  );

  return (
    <BaseFieldWizardModal
      field={field}
      fieldTypeName={fieldTypeName}
      onClose={onClose}
      onSubmit={onSubmit}
      showBackButton={!isEditing}
      startPage={isEditing ? 1 : 0}
      wizardPages={wizardPages}
    />
  );
}
