import React, { useCallback } from 'react';
import ConfirmDialog from '../elements/ConfirmDialog';

interface DeleteTaskDialogProps {
  onCancelled: () => void;
  onConfirmed: (allFutureTasks: boolean) => void;
  open: boolean;
  isRecurring: boolean;
  description?: string;
}

export function DeleteTaskDialog(props: DeleteTaskDialogProps) {
  const { onCancelled, onConfirmed, open, isRecurring, description } = props;
  const THIS_TASK = 'ONLY_THIS_TASK';
  const THIS_AND_ALL_FUTURE_TASKS = 'THIS_AND_ALL_FUTURE_TASKS';

  const handleConfirmation = useCallback(
    (_, el) => {
      onConfirmed(el.id === THIS_AND_ALL_FUTURE_TASKS);
    },
    [onConfirmed],
  );

  return isRecurring ? (
    <ConfirmDialog
      open={open}
      title="Delete Task?"
      description={description}
      hideCancelButton
      showCloseButton
      confirmButtons={[
        {
          id: THIS_TASK,
          text: 'Delete this task only',
        },
        {
          id: THIS_AND_ALL_FUTURE_TASKS,
          text: 'Delete this and all future tasks',
        },
      ]}
      danger
      onConfirmed={handleConfirmation}
      onCancelled={onCancelled}
    />
  ) : (
    <ConfirmDialog
      open={open}
      title="Are you sure you want to delete this task?"
      description={description}
      confirmButtonText="Delete Task"
      danger
      onConfirmed={() => onConfirmed(false)}
      onCancelled={onCancelled}
    />
  );
}
