import {
  ChartableGenericLogsResource,
  Frequency,
  isRollingCalculationResource,
} from '../../types/ts-types';

function getFrequencyFromResource(
  resource: ChartableGenericLogsResource,
): Frequency {
  if (isRollingCalculationResource(resource)) {
    return resource.entity.calculationFrequency;
  }
  return resource.entity.frequency;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default getFrequencyFromResource;
