import { CalendarEventStatus } from 'mapistry-shared';
import React from 'react';
import { EventStatusIndicator } from '../EventStatusIndicator';
import { CalendarEventTitle, StyledCalendarEvent } from './styled';

export function CalendarEvent({
  status,
  title,
}: {
  status: CalendarEventStatus;
  title: string;
}) {
  return (
    <StyledCalendarEvent>
      <EventStatusIndicator status={status} />
      <CalendarEventTitle>{title}</CalendarEventTitle>
    </StyledCalendarEvent>
  );
}
