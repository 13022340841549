import { apiUrl } from '@monorepo/shared/apiClient/httpClient';
import { useThrottledBasedOnArgs } from '@monorepo/shared/hooks/useThrottled';
import {
  ClientToServerMessages,
  PushMessageTopic,
  ServerToClientMessages,
} from 'mapistry-shared';
import { useEffect } from 'react';
import { Socket, io } from 'socket.io-client';

type SocketConnection = Socket<ServerToClientMessages, ClientToServerMessages>;

export const socket: SocketConnection = io(apiUrl as string, {
  autoConnect: false,
  withCredentials: true,

  /** If we didn't account for some reconnection bug, stop trying to reconnect after about 30 minutes */
  reconnectionAttempts: 200,
  reconnectionDelayMax: 10 * 1000,

  // To avoid needing sticky session and needing to do special things for throng.
  // If we run into issues with slow or prevented connections and need to use the long polling, rethink this.
  // - https://socket.io/docs/v4/using-multiple-nodes/#why-is-sticky-session-required
  // - https://github.com/socketio/socket.io-sticky
  // - https://socket.io/docs/v4/how-it-works/#upgrade-mechanism
  transports: ['websocket'],
});

export function useThrottledOnArgsMessageHandler<T extends PushMessageTopic>(
  pushMessageTopic: T,
  pushMessageHandler: ServerToClientMessages[T],
  throttlingOptions = { wait: 2000 },
) {
  const throttledMessageHandler = useThrottledBasedOnArgs(
    pushMessageHandler,
    throttlingOptions,
  );

  useEffect(() => {
    // @ts-expect-error I don't know - socket.io complains about not matching the FallbackToUntypedListener
    socket.on(pushMessageTopic, throttledMessageHandler);

    return () => {
      // @ts-expect-error I don't know - socket.io complains about not matching the FallbackToUntypedListener
      socket.off(pushMessageTopic, throttledMessageHandler);
    };
  }, [throttledMessageHandler, pushMessageTopic]);
}
