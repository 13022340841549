import { Loading } from '@monorepo/shared/components/Loading';
import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useResourceCount } from '@monorepo/shared/hooks/resources/useResourceCount';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { ResourcesUploadModal } from '../AddResourcesPage/ResourcesUploadModal';
import { EditResourceModal } from '../EditResourceModal';
import { ViewResourcesTable } from '../ViewResourcesTable/ViewResourcesTable';
import { ViewResourcesHeader } from './ViewResourcesHeader';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledMain = styled(Main)`
  ${fillHeightAndScrollable}
`;

function ViewResourcesContent() {
  const { organizationId, pageNumber, resourceTypeId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const navigate = useNavigate();

  const [filterBy, setFilterBy] = useState('');

  const [isEditModalOpen, openEditModal, closeEditModal] = useOpenClose();
  const [isBulkUploadModalOpen, openBulkUploadModal, closeBulkUploadModal] =
    useOpenClose();

  const [resourceIdForEdit, setResourceIdForEdit] = useState<
    string | undefined
  >(undefined);

  const { hasResources, isLoading: isResourceCountLoading } = useResourceCount({
    organizationId,
    resourceTypeId,
  });

  const handleSearchChange = useCallback(
    (event) => setFilterBy(event.target.value),
    [setFilterBy],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      navigate(`../../${newPage}`);
    },
    [navigate],
  );

  const handleSettings = useCallback(() => {
    navigate(`../../../..`);
  }, [navigate]);

  const openResourceModal = useCallback(
    (resourceId?: string) => {
      setResourceIdForEdit(resourceId);
      openEditModal();
    },
    [setResourceIdForEdit, openEditModal],
  );

  const handleAddNew = useCallback(() => {
    openResourceModal(); // pass no resource id
  }, [openResourceModal]);

  const nameColumnContents = useCallback(
    (resource) => (
      <Link to={`../../../${resource.id}`} color="grey">
        {resource.name}
      </Link>
    ),
    [],
  );

  const aboutResource =
    'Resources help you organize items with static properties that can be used in various ways across Logs and sites.';

  if (isResourceCountLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <ViewResourcesHeader
        onSearchChange={hasResources ? handleSearchChange : undefined}
        onSettingsClick={handleSettings}
        onAddNew={handleAddNew}
        onAddNewFromFile={openBulkUploadModal}
      />
      <StyledMain>
        {hasResources ? (
          <ViewResourcesTable
            filterBy={filterBy}
            onEditResource={openResourceModal}
            onPageChange={handlePageChange}
            nameColumnContents={nameColumnContents}
            pageNumber={Number(pageNumber)}
          />
        ) : (
          <EmptyState
            primaryText="There are no Resources to show."
            secondaryText={
              canEditOrgSettings
                ? aboutResource
                : `${aboutResource} Contact your organization administrator to add Resources.`
            }
          />
        )}
      </StyledMain>
      {isEditModalOpen && (
        <EditResourceModal
          organizationId={organizationId}
          resourceTypeId={resourceTypeId}
          resourceId={resourceIdForEdit}
          open={isEditModalOpen}
          onClose={closeEditModal}
        />
      )}
      {isBulkUploadModalOpen && organizationId && resourceTypeId && (
        <ErrorBoundary onError={closeBulkUploadModal}>
          <ResourcesUploadModal
            isOpen={isBulkUploadModalOpen}
            resourceTypeId={resourceTypeId}
            onClose={closeBulkUploadModal}
            organizationId={organizationId}
          />
        </ErrorBoundary>
      )}
    </Page>
  );
}

export const ViewResources = () => (
  <ErrorBoundary>
    <ViewResourcesContent />
  </ErrorBoundary>
);
