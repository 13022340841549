import { Api } from '@monorepo/shared/apiClient';
import { MutationConfig, useMutation } from 'react-query';
import { setCache } from './useQueryResults';

type UseQueryValidateParams = {
  config?: MutationConfig<Api.ValidateQueryResponse, Api.ErrorResponse>;
};

export const useQueryValidate = (params?: UseQueryValidateParams) =>
  useMutation(Api.validateQuery, {
    ...params?.config,
    onSuccess: (queryResult, validateQueryParams) => {
      setCache(validateQueryParams, queryResult);
      return params?.config?.onSuccess?.(queryResult, validateQueryParams);
    },
  });
