import { formatUnitAwareNumber } from '@monorepo/shared/utils/formatUnitAwareNumber';
import { NullableMathUnitName } from 'mapistry-shared';
import React from 'react';
import { NoWrap } from './styled';

interface Props {
  units?: NullableMathUnitName;
  value: number;
}

export const NumberTableCell = (props: Props) => {
  const { value, units } = props;
  const formatted = formatUnitAwareNumber(value, units);
  return <NoWrap>{formatted}</NoWrap>;
};
