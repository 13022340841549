import { Accordion } from '@monorepo/shared/componentsV2/Accordion';
import { Badge } from '@monorepo/shared/componentsV2/Badge';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { bodyRegular, buttonText } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';

const SummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const LabelWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray333};
  ${buttonText};

  & svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    fill: ${({ theme }) => theme.colors.gray333};
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Chip = styled(Badge)`
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.grayeee};
  background-color: ${({ theme }) => theme.colors.blue64};
  ${bodyRegular};
`;

const ActionButton = styled(IconButton)`
  height: 2rem;
  margin-left: 0.25rem;
  font-size: 1rem;
`;

const MAX_INFO_CHIPS = 3;

type QueryStepActionButton = {
  altText: string;
  icon: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type QueryStepAccordionProps = Pick<
  React.ComponentProps<typeof Accordion>,
  'defaultExpanded' | 'detailsContent'
> & {
  actions?: QueryStepActionButton[];
  icon: React.ReactNode;
  infoChips?: string[];
  stepLabel: string;
};

export function QueryStepAccordion({
  actions = [],
  defaultExpanded,
  detailsContent,
  icon,
  infoChips = [],
  stepLabel,
}: QueryStepAccordionProps) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      detailsContent={detailsContent}
      summaryContent={
        <SummaryContent>
          <LabelWithIcon>
            {icon}
            {stepLabel}
          </LabelWithIcon>
          <RightSide>
            {infoChips.slice(0, MAX_INFO_CHIPS).map((chipLabel) => (
              <Chip label={chipLabel} key={chipLabel} />
            ))}
            {infoChips.length > MAX_INFO_CHIPS && (
              <Chip
                label={`${infoChips.length - MAX_INFO_CHIPS} more`}
                key="extra chips"
              />
            )}
            {actions.map((action, idx) => (
              <ActionButton
                aria-label={action.altText}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  action.onClick(event);
                }}
                onFocus={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                }}
              >
                {action.icon}
              </ActionButton>
            ))}
          </RightSide>
        </SummaryContent>
      }
    />
  );
}
