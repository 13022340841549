import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourceListCacheEvents,
  resourceListCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceListCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { QueryConfig, queryCache, useQuery } from 'react-query';
import { Api } from '../../apiClient';

type KeyParams = Api.FetchResourceRefsParams;

const KEY_START = 'resource-refs';

export const createKey = ({
  organizationId,
  resourceTypeId,
  projectId,
}: KeyParams) =>
  [
    KEY_START,
    organizationId,
    resourceTypeId,
    // only add this item to the key if projectId exists, so we have a hierarchy (invalidating all resource refs
    //  in a type will invalidate the specific projects too)
    ...(projectId ? [projectId] : []),
  ] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchResourceRefs
>;

const fetcher: Fetcher = (_, organizationId, resourceTypeId, projectId) =>
  Api.fetchResourceRefs({ organizationId, resourceTypeId, projectId });

type UseResourceRefsParams = Partial<Api.FetchResourceRefsParams> & {
  config?: QueryConfig<Api.FetchResourceRefsResponse, Api.ErrorResponse>;
};

export function useResourceRefs({
  config: inputConfig,
  organizationId,
  resourceTypeId,
  projectId,
}: UseResourceRefsParams) {
  const isEnabled = !!organizationId && !!resourceTypeId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createKey({ organizationId, resourceTypeId, projectId })
    : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    resourceRefs: data,
  };
}

type QueryCache = ReturnType<typeof useResourceRefs>['resourceRefs'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.UPDATE,
    ResourceCacheEvents.DELETE,
  ],
  {
    hookName: 'useResourceRefs',
    callback: (keyParams: KeyParams) => invalidateCache(keyParams),
  },
);

resourceListCacheRegister([ResourceListCacheEvents.CREATE], {
  hookName: 'useResourceRefs',
  callback: (keyParams: KeyParams) => invalidateCache(keyParams),
});
