import { useDispatch, useSelector } from 'react-redux';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';
import { fetchEmissionLimitItemsAction } from '../../actions/air';
import APP from '../../config';
import { getIsFetching, getLimitItems } from '../../selectors/genericLogs';
import { GenericLogsLimitItem } from '../../types/ts-types';
import useMountEffect from '../useMountEffect';
import { useStableReference } from '../useStableReference';

const deepEqual = _.isEqual;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useLimitItems(logProjectId: string) {
  const dispatch = useDispatch();
  const airEmissionLimitItems = useSelector((state) =>
    getLimitItems(state, logProjectId),
  ) as GenericLogsLimitItem[] | undefined;
  const isLoading = useSelector((state) =>
    getIsFetching(state, 'airEmissionsMaterialLimits'),
  ) as boolean;
  useMountEffect(() => {
    dispatch(fetchEmissionLimitItemsAction(APP.projectId, logProjectId));
  });
  const stableAirEmissionLimitItems = useStableReference(
    airEmissionLimitItems,
    deepEqual,
  );
  return { airEmissionLimitItems: stableAirEmissionLimitItems, isLoading };
}

export { useLimitItems };
