import SearchIcon from '@material-ui/icons/Search';
import { withParamsAndNavigate } from '@monorepo/old-web/js/components/routing/withReactRouter';
import { Tooltip } from '@monorepo/shared/components/Tooltip';
import { withPermissions } from '@monorepo/shared/hooks/permissions/withPermissions';
import CheckIcon from '@svg/check.svg';
import CogIcon from '@svg/cog.svg';
import EditIcon from '@svg/edit.svg';
import CalendarEventSuccessIcon from '@svg/m-event-calendar-success.svg';
import RecurringTaskIcon from '@svg/recurring.svg';
import TrashIcon from '@svg/trash.svg';
import cn from 'classnames';
import { CalendarEventStatus } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { deleteTaskAction, markTaskCompleteAction } from '../../actions/task';
import apiCaller from '../../apiCaller';
import { UTCEquivalentOfLocal } from '../../utils';
import DueDate from '../elements/DueDate';
import Menu from '../elements/Menu';
import { MoreHoriz } from '../elements/MuiIcon';
import { ProjectTaskType } from '../propTypes';
import TasksValidator from '../views/formSubmissionEditor/validations/TasksValidator';
import withProvider from '../withProvider';
import { DeleteTaskDialog } from './DeleteTaskDialog';
import InspectionTaskEditModal from './InspectionTaskEditModal/index';
import { NO_COMPLETE_PERMISSION_MSG } from './shared_messaging.ts';

class ProjectTask extends Component {
  constructor() {
    super();
    this.state = {
      confirmDelete: false,
      hideDeleted: false,
      inspectionModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isDeleting } = this.props;
    const { hideDeleted } = this.state;
    // Server request failed
    if (hideDeleted && prevProps.isDeleting && !isDeleting) {
      this.setState({ hideDeleted: false });
    }
  }

  handleTaskClick = () => {
    const { task } = this.props;
    if (!task.isInspection) {
      this.handleOpenTaskModal();
    } else {
      this.handleInspectionClick();
    }
  };

  handleOpenTaskModal = () => {
    const { task } = this.props;

    if (task.isTemplate) {
      this.openProjectTaskModal(
        task.parentTemplateId || task.templateId,
        task.parentDueDate || task.dueDate,
      );
    } else {
      this.openProjectTaskModal(task.parentId ?? task.id);
    }
  };

  // eslint-disable-next-line react/sort-comp
  openProjectTaskModal(taskId, dueDate) {
    const { navigate } = this.props;

    let queryParams = `?taskId=${taskId}`;

    if (dueDate) {
      queryParams += `&dueDate=${dueDate}`;
    }

    navigate(queryParams);
  }

  handleInspectionClick = () => {
    const { task } = this.props;
    if (!this.canStartInspection()) {
      return;
    }
    const {
      calendarName,
      dueDate,
      eventNumber,
      formSubmissionSlug,
      formTemplateSlug,
      projectId,
    } = task;

    const submissionUrl = apiCaller.getFormSubmissionUrl(
      projectId,
      formTemplateSlug,
      formSubmissionSlug,
      calendarName,
      new Date(dueDate),
      eventNumber,
    );

    window.location.href = submissionUrl;
  };

  handleDeleteTask = (allFuture) => {
    const { deleteTask, task } = this.props;
    this.setState({
      confirmDelete: false,
      hideDeleted: true,
    });

    if (task.isRecurring) {
      const id = task.isTemplate ? task.templateId : task.id;
      deleteTask(task.projectId, id, task.dueDate, allFuture);
      return;
    }

    deleteTask(task.projectId, task.id);
  };

  markComplete = () => {
    const { markComplete, task } = this.props;
    const markedAsCompletedDate = UTCEquivalentOfLocal(new Date());

    if (task.isRecurring) {
      const id = task.isTemplate ? task.templateId : task.id;
      markComplete(task.projectId, id, markedAsCompletedDate, task.dueDate);
      return;
    }

    markComplete(task.projectId, task.id, markedAsCompletedDate);
  };

  canStartInspection() {
    const { task } = this.props;
    const today = new Date();
    const startDate = new Date(task.startDate);
    return task.isInspection && task.startDate && startDate <= today;
  }

  menuOptions() {
    const { task, hasProjectUpdatePermission } = this.props;
    const isCompleted = task.status === CalendarEventStatus.COMPLETE;
    const menuOptions = [];

    if (task.isInspection) {
      if (this.canStartInspection()) {
        menuOptions.push({
          icon: <EditIcon className="m-icon" />,
          label: 'Edit Inspection',
          onClick: this.handleInspectionClick,
        });
      }
      if (!isCompleted) {
        menuOptions.push({
          icon: <CogIcon className="m-icon" />,
          label: 'Inspection Settings',
          onClick: () => this.setState({ inspectionModalOpen: true }),
        });
      }
    }
    if (!task.isInspection) {
      const validator = new TasksValidator();
      const validationError = validator.validateTask(null, task);

      const editTaskIconAndLabel =
        task.permissions.isAllowedToEdit || task.permissions.isAllowedToComplete
          ? {
              icon: <EditIcon className="m-icon" aria-hidden />,
              label: 'Edit Task',
            }
          : {
              icon: <SearchIcon className="m-icon" aria-hidden />,
              label: 'View Task',
            };

      menuOptions.push({
        ...editTaskIconAndLabel,
        onClick: this.handleOpenTaskModal,
      });

      if (!isCompleted && !validationError && hasProjectUpdatePermission) {
        menuOptions.push({
          icon: <CheckIcon className="m-icon" />,
          label: 'Mark Complete',
          onClick: this.markComplete,
          disabledWithExplanation: task.permissions.isAllowedToComplete
            ? ''
            : NO_COMPLETE_PERMISSION_MSG,
        });
      }
    }

    if (!isCompleted && hasProjectUpdatePermission) {
      menuOptions.push({
        danger: true,
        icon: <TrashIcon className="m-icon" />,
        label: 'Delete Task',
        onClick: () => this.setState({ confirmDelete: true }),
        disabledWithExplanation: task.permissions.isAllowedToDelete
          ? ''
          : 'Only admins and the author of the task are allowed to delete that task.',
      });
    }
    return menuOptions;
  }

  render() {
    const { task } = this.props;
    const { confirmDelete, hideDeleted, inspectionModalOpen } = this.state;
    if (hideDeleted) {
      return null;
    }

    const isCompleted =
      task.isMarkedCompleted || task.status === CalendarEventStatus.COMPLETE;
    const canStartInspection = this.canStartInspection();

    const icon = isCompleted ? (
      <div className="project-task__icon-svg-container">
        <CalendarEventSuccessIcon className="m-icon project-task__icon--complete" />
      </div>
    ) : (
      <div className="project-task__icon--due" />
    );

    let dueDateEl = null;
    if (isCompleted) {
      dueDateEl = 'Complete';
    }
    if (!isCompleted && task.dueDate) {
      dueDateEl = <DueDate dueDateInUTC={task.dueDate} status={task.status} />;
    }

    const taskInfoClassName =
      !task.isInspection || canStartInspection
        ? 'project-task__info project-task__info--clickable'
        : 'project-task__info';

    // this isn't working for recurring tasks
    const deleteConfirmDescription = task.subTaskCount
      ? `This task has ${task.subTaskCount} subtask${
          task.subTaskCount > 1 ? 's' : ''
        } which will be deleted.`
      : '';

    return (
      <>
        <div
          className={cn({
            'project-task': true,
            'project-task--not-started':
              task.isInspection && !canStartInspection,
          })}
        >
          <div className="project-task__icon">{icon}</div>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className={taskInfoClassName} onClick={this.handleTaskClick}>
            <div className="project-task__title">{task.title}</div>
            {task.parentTitle && (
              <div className="project-task__parent">{`< ${task.parentTitle}`}</div>
            )}
            {task.assignees && !!task.assignees.length && (
              <div className="project-task__assignee">
                Assigned to
                <span> </span>
                <span className="project-task__assignee-name">
                  {task.assignees.map((a) => a.name).join(', ')}
                </span>
              </div>
            )}
          </div>
          <div
            className={`project-task__date ${
              isCompleted && 'project-task__date--complete'
            }`}
          >
            {dueDateEl}
          </div>
          <div className="project-task__options">
            <Menu icon={<MoreHoriz />} options={this.menuOptions()} />
            {task.isRecurring && (
              <Tooltip title="This is a recurring task">
                <div>
                  <RecurringTaskIcon className="m-icon project-task__recurring-icon" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {inspectionModalOpen && (
          <InspectionTaskEditModal
            isOpen={inspectionModalOpen}
            onClose={() => this.setState({ inspectionModalOpen: false })}
            task={task}
          />
        )}
        {confirmDelete && (
          <DeleteTaskDialog
            description={deleteConfirmDescription}
            open={confirmDelete}
            onConfirmed={this.handleDeleteTask}
            onCancelled={() => this.setState({ confirmDelete: false })}
            isRecurring={task.isRecurring}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { task } = state;
  return {
    isDeleting: task.isFetching.deletingTask,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteTask: (projectId, taskId, dueDate, allFuture) =>
    dispatch(deleteTaskAction(projectId, taskId, dueDate, allFuture)),
  markComplete: (projectId, taskId, markedAsCompletedDate, dueDate) =>
    dispatch(
      markTaskCompleteAction(projectId, taskId, markedAsCompletedDate, dueDate),
    ),
});

ProjectTask.propTypes = {
  deleteTask: PropTypes.func.isRequired,
  hasProjectUpdatePermission: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  markComplete: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  task: ProjectTaskType.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withPermissions(
  withParamsAndNavigate(
    withProvider(connect(mapStateToProps, mapDispatchToProps)(ProjectTask)),
  ),
);
