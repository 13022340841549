import { EditFieldValueFactory } from '@monorepo/shared/componentsV2/fieldDataType/values/EditFieldValueFactory';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { Input } from '@monorepo/shared/componentsV2/Input';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { bodyMedium } from '@monorepo/shared/styles/text';
import { isRequired } from '@monorepo/shared/utils/validators';
import TrashIcon from '@svg/trash.svg';
import { FieldResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';
import { FormValues } from './types';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const FormFieldContainer = styled.div`
  flex-grow: 0;
  width: 45%;
`;

const EqualsText = styled.div`
  ${bodyMedium}
  margin: 0 1rem;
`;

const FieldValueField = styled(EditFieldValueFactory)`
  width: 100%;
`;

const FieldValuePlaceholder = styled(Input)`
  width: 100%;
`;

const DeleteButton = styled(IconButton)`
  min-width: 2rem;
  margin-bottom: 0.2rem; /* lines this up vertically with the input elements to its left */
  font-size: 0.75rem;
`;

export function FrequencyRequirementFilter({
  formFieldName,
  formFieldIndex,
  logFields,
  organizationId,
  onDelete,
  projectId,
}: {
  formFieldName: string;
  formFieldIndex: number;
  logFields: FieldResponse[];
  organizationId: string;
  onDelete: () => void;
  projectId: string;
}) {
  const { values: currentFormValues } = useFormState<FormValues>();

  const selectedLogFieldId = (currentFormValues?.filters || [])[formFieldIndex]
    ?.fieldId;

  const selectedLogField = useMemo(
    () => logFields.find((field) => field.id === selectedLogFieldId),
    [logFields, selectedLogFieldId],
  );

  const logFieldOptions = useMemo(
    () =>
      (selectedLogField
        ? [selectedLogField]
        : logFields.filter(
            (lf) =>
              !(currentFormValues?.filters || []).some(
                (f) => f?.fieldId === lf.id,
              ),
          )
      ).map(({ id, name: fieldName }) => ({
        value: id,
        label: fieldName,
      })),
    [selectedLogField, logFields, currentFormValues?.filters],
  );

  return (
    <Container>
      <FormFieldContainer>
        <SelectField
          disabled={!!selectedLogField}
          name={`${formFieldName}.fieldId`}
          label="Field"
          options={logFieldOptions}
          required
          validate={isRequired}
        />
      </FormFieldContainer>
      <EqualsText>=</EqualsText>
      <FormFieldContainer>
        {selectedLogField ? (
          <FieldValueField
            field={selectedLogField}
            isRequiredOverride
            labelOverride="Value"
            nameOverride={`${formFieldName}.value.value`}
            organizationId={organizationId}
            projectId={projectId}
          />
        ) : (
          <FieldValuePlaceholder
            disabled
            label="Value *"
            name={`disabled-value-${formFieldIndex}`}
            value="Choose a field"
          />
        )}
      </FormFieldContainer>
      <DeleteButton onClick={onDelete}>
        <VisuallyHidden>Delete condition</VisuallyHidden>
        <TrashIcon />
      </DeleteButton>
    </Container>
  );
}
