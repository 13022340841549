import { Api } from '@monorepo/shared/apiClient';
import {
  TasksCacheEvents,
  tasksCacheRegister,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import { QueryConfig, queryCache, useQuery } from 'react-query';
import { KEY_START, createTaskKey } from './createTaskKey';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function fetcher(_: any, projectId: string, taskId: string, dueDate?: string) {
  return Api.fetchTask({ projectId, taskId, dueDate });
}

type Input = {
  variables: Api.FetchTaskVariables;
  config?: QueryConfig<Api.Task, Api.ErrorResponse>;
};

export function useTask(input?: Input) {
  const queryInfo = useQuery(
    input ? createTaskKey(input.variables) : undefined,
    fetcher,
    input ? input.config : { enabled: false },
  );
  return {
    task: queryInfo.data,
    error: queryInfo.error,
    status: queryInfo.status,
  };
}

type InvalidateCacheParams = { projectId?: string };

// currently not invalidating single tasks, to be on the safe side and not have to test
// how single tasks and subtasks and recurring tasks interact and if invalidating a single task
// is always enough. That would require more testing.
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  const key = [
    KEY_START,
    ...(keyParams.projectId ? [keyParams.projectId] : []),
  ];
  await queryCache.invalidateQueries(key);
}

tasksCacheRegister([TasksCacheEvents.UPSERT, TasksCacheEvents.DELETE], {
  hookName: 'useTask',
  callback: (invalidateKeyParams: InvalidateCacheParams) =>
    invalidateCache(invalidateKeyParams),
});
