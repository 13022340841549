import { Api } from '@monorepo/shared/apiClient';
import { setCache } from '@monorepo/shared/hooks/resources/useResourceProperties';
import { MutationConfig, useMutation } from 'react-query';

type UseResourcePropertiesOrderUpdateParams = {
  config?: MutationConfig<
    Api.UpdateResourcePropertiesOrderResponse,
    Api.ErrorResponse
  >;
};

export const useResourcePropertiesOrderUpdate = (
  params?: UseResourcePropertiesOrderUpdateParams,
) =>
  useMutation(Api.updateResourcePropertiesOrder, {
    ...params?.config,
    onSuccess: (reorderedResourceProperties, mutationFuncParams) => {
      const { resourceTypeId, organizationId } = mutationFuncParams;

      // success response includes the reordered resource properties
      setCache({ resourceTypeId, organizationId }, reorderedResourceProperties);
    },
  });
