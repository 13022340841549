import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import { SaveState } from '@monorepo/shared/types/SaveState';
import Trash from '@svg/trash.svg';
import {
  CustomFrequencyMessage,
  IntervalFrequencyFactory,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  Button,
  FieldWrapper,
  FormErrors,
  HelpIcon,
  IconButton,
  Modal,
  SaveButton,
} from '../../../../elements';
import FrequencyForm from '../../../../frequencyForm';

const InspectionFrequencyModal = (props) => {
  const {
    errors,
    frequencies,
    frequencyOptions,
    onClose,
    onDeleteFrequency,
    onSave,
    open,
    saveState,
    title,
  } = props;

  const { customPopperOpen } = useContext(FrequencyContext);

  const formatDate = (date) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  // need tooltip on disabled icon button
  const deleteButton = (frequencyModel) => {
    const { id, numberOfInspections } = frequencyModel;
    const disabled = numberOfInspections > 0 || customPopperOpen;
    return (
      id && (
        <span className="flex center">
          <Button
            className="delete__button"
            color="danger"
            disabled={disabled}
            onClick={() => onDeleteFrequency(id)}
          >
            Delete frequency
          </Button>
          <IconButton
            className="delete__icon"
            disabled={disabled}
            onClick={() => onDeleteFrequency(id)}
            tooltipText={
              disabled && !customPopperOpen
                ? 'History cannot be deleted because there are inspections associated with this frequency'
                : 'Delete frequency'
            }
          >
            <Trash className="m-icon grow-large" />
          </IconButton>
        </span>
      )
    );
  };

  const frequenciesList = () =>
    frequencies.map((frequencyModel) => {
      const {
        id,
        frequency,
        numberOfInspections,
        startDate,
        customFrequency: frequencyModelCustomFrequency,
      } = frequencyModel;
      const date = localEquivalentOfUTC(startDate);
      const intervalFrequency = IntervalFrequencyFactory.For(
        frequency,
        frequencyModelCustomFrequency,
      );
      const helpText = frequencyModelCustomFrequency
        ? new CustomFrequencyMessage(
            frequency,
            frequencyModelCustomFrequency,
          ).getMessage()
        : null;

      return (
        <div key={id} className="frequency-settings__table__row">
          <FieldWrapper
            className="frequency-settings__table__col-1"
            label="Start Date"
          >
            <span>{formatDate(date)}</span>
          </FieldWrapper>
          <FieldWrapper
            className="frequency-settings__table__col-2"
            label="Frequency"
          >
            <span>
              {intervalFrequency.toString()}
              {frequencyModelCustomFrequency && (
                <span className="frequency-settings__help-icon">
                  <HelpIcon text={helpText} />
                </span>
              )}
            </span>
          </FieldWrapper>
          <FieldWrapper
            className="frequency-settings__table__col-3"
            label="Number of Inspections"
          >
            <span>{`${numberOfInspections} inspections`}</span>
          </FieldWrapper>
          {deleteButton(frequencyModel)}
        </div>
      );
    });

  const buttonSaveState = customPopperOpen ? SaveState.CLEAN : saveState;
  const footer = (
    <>
      <Button color="secondary" onClick={onClose} disabled={customPopperOpen}>
        {buttonSaveState === SaveState.DIRTY ? 'Cancel' : 'Close'}
      </Button>
      <SaveButton onSave={onSave} saveState={buttonSaveState} />
    </>
  );

  return (
    <Modal
      header={<h4>{title}</h4>}
      footer={footer}
      fullWidth={false}
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <div className="frequency-settings__content">
        {errors && <FormErrors errors={errors} />}
        <FrequencyForm
          customFrequencyMessageStart="This inspection will be required"
          frequencyOptions={frequencyOptions}
          frequencyLabel="How often do you want to do your inspection?"
          startDateLabel="Frequency effective date"
        />
        <div className="frequency-settings__history">
          <h4>Frequency History</h4>
          <div className="frequency-settings__table">
            <div className="frequency-settings__table__header">
              <span className="frequency-settings__table__col-1">
                Start Date
              </span>
              <span className="frequency-settings__table__col-2">
                Frequency
              </span>
              <span className="frequency-settings__table__col-3">
                Number of Inspections
              </span>
            </div>
            <div className="frequency-settings__table__content">
              {frequenciesList()}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InspectionFrequencyModal.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  frequencies: PropTypes.arrayOf(
    PropTypes.shape({
      frequency: PropTypes.string,
      numberOfInspections: PropTypes.number,
      startDate: PropTypes.string,
    }),
  ),
  frequencyOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
  onClose: PropTypes.func.isRequired,
  onDeleteFrequency: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool,
  saveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  title: PropTypes.string.isRequired,
};

InspectionFrequencyModal.defaultProps = {
  errors: null,
  frequencies: [],
  frequencyOptions: [],
  open: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default InspectionFrequencyModal;
