import {
  BaseFieldWizardModal,
  getError,
  mapFormValuesToBaseRequestDto,
} from '@monorepo/shared/componentsV2/fieldDataType/wizard/BaseFieldWizardModal';
import * as Sentry from '@sentry/browser';
import {
  CreateFieldRequest,
  CreateFormulaFieldRequest,
  FieldDataType,
  FieldResponse,
  Formula,
  FormulaFieldResponse,
  UpdateFieldRequest,
  UpdateFormulaFieldRequest,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useLogFieldCreate } from '../../hooks/useLogFieldCreate';
import { useLogFieldUpdate } from '../../hooks/useLogFieldUpdate';
import { EditLogFormulaFieldWizardPage } from './EditLogFormulaFieldWizardPage';

function mapFormValuesToRequestDto(
  values: Partial<FieldResponse>,
): CreateFieldRequest | UpdateFieldRequest {
  const baseRequestDto = mapFormValuesToBaseRequestDto(values);

  if (values.type === FieldDataType.FORMULA) {
    const { expression } = values as Partial<FormulaFieldResponse>;
    if (!expression) throw getError(values);
    return { ...baseRequestDto, expression: Formula.format(expression) };
  }
  return baseRequestDto;
}

interface EditLogFormulaFieldModalProps {
  field: FieldResponse | undefined;
  onClose: () => void;
  open: boolean;
}

const isUpdateRequest = (
  x: Partial<FieldResponse>,
): x is UpdateFormulaFieldRequest =>
  'expression' in x && 'id' in x && x.id != null;

const isCreateRequest = (
  x: Partial<FieldResponse>,
): x is CreateFormulaFieldRequest => 'expression' in x && !isUpdateRequest(x);

export function EditLogFormulaFieldModal({
  field,
  onClose,
  open,
}: EditLogFormulaFieldModalProps) {
  const { logId, organizationId } = useParams();
  const [creator] = useLogFieldCreate({ config: { throwOnError: true } });
  const [updater] = useLogFieldUpdate({ config: { throwOnError: true } });

  const onSubmit = useCallback(
    async (formValues: Partial<FieldResponse>) => {
      if (!organizationId || !logId) {
        const errMsg = `Couldn't create Log formula field: This page doesn't have organization id or a Log id`;
        Sentry.captureException(errMsg);
        throw new Error(errMsg);
      }

      const requestDto = mapFormValuesToRequestDto(formValues);

      if (!(isUpdateRequest(requestDto) || isCreateRequest(requestDto))) {
        const errMsg = `Couldn't create Log formula field: This is not a valid formula field`;
        Sentry.captureException(errMsg);
        throw new Error(errMsg);
      }

      if (isUpdateRequest(requestDto)) {
        await updater({
          logField: requestDto,
          logId,
          organizationId,
        });
      }

      if (isCreateRequest(requestDto)) {
        await creator({
          logField: requestDto,
          logId,
          organizationId,
        });
      }
    },
    [creator, logId, organizationId, updater],
  );

  if (!open) {
    return null;
  }

  return (
    <BaseFieldWizardModal
      field={field || { type: FieldDataType.FORMULA }}
      fieldTypeName="formula"
      onClose={onClose}
      onSubmit={onSubmit}
      wizardPages={[
        <EditLogFormulaFieldWizardPage fieldColumnName={field?.columnName} />,
      ]}
    />
  );
}
