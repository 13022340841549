import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { format } from 'date-fns';
import { localEquivalentOfUTC, LogEntryResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';

import { useLogEntryDelete } from '../../hooks/useLogEntryDelete';

interface LogEntryTableActionsProps {
  logEntry: LogEntryResponse;
  onEditEntry: (logEntryId: string) => void;
}

export function LogEntryTableActions({
  logEntry,
  onEditEntry,
}: LogEntryTableActionsProps) {
  const { logId, organizationId, projectId } = useParams();
  const { confirm } = useModal();
  const { showUserFriendlyErrorToast, success } = useToast();
  const [deleter] = useLogEntryDelete({ config: { throwOnError: true } });

  const logDate = useMemo(
    () => format(localEquivalentOfUTC(new Date(logEntry.logDate)), 'Pp'),
    [logEntry.logDate],
  );

  // function passed to confirm to be executed when the confirm button is clicked
  const handleDeleteConfirm = useCallback(
    async (isConfirmed) => {
      if (isConfirmed && logId && organizationId && projectId) {
        await deleter({
          logEntryId: logEntry.id,
          logId,
          organizationId,
          projectId,
        });
      }
    },
    [deleter, logEntry.id, logId, organizationId, projectId],
  );

  const handleDelete = useCallback(async () => {
    try {
      const isConfirmed = await confirm({
        title: 'Delete Log entry',
        description: (
          <>
            Are you sure you want to delete the entry from <b>{logDate}</b>?
          </>
        ),
        cancelButtonText: 'Keep Log entry',
        confirmButtonText: 'Delete Log entry',
        afterConfirmButtonText: 'Deleting...',
        danger: true,
        onConfirmAsync: handleDeleteConfirm,
      });
      if (isConfirmed) {
        success(`Entry from ${logDate} has been deleted.`);
      }
    } catch (err) {
      showUserFriendlyErrorToast(
        err,
        `Unable to delete entry from ${logDate}.`,
      );
    }
  }, [
    confirm,
    logDate,
    handleDeleteConfirm,
    success,
    showUserFriendlyErrorToast,
  ]);

  return (
    <ActionMenu
      altText={`Action menu for Log entry on ${logDate}`}
      options={[
        {
          displayName: 'Edit',
          onClick: () => onEditEntry(logEntry.id),
        },
        {
          displayName: 'Delete',
          onClick: handleDelete,
        },
      ]}
    />
  );
}
