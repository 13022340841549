import ErrorIcon from '@material-ui/icons/Error';
import { fieldValueDisplayFactory } from '@monorepo/shared/componentsV2/fieldDataType/values/fieldValueDisplayFactory';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { Pagination } from '@monorepo/shared/componentsV2/Pagination';
import { useSkipWidgetLink } from '@monorepo/shared/componentsV2/SkipWidgetLink';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { Tooltip } from '@monorepo/shared/componentsV2/Tooltip';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { helpDeskButtonDiameter } from '@monorepo/shared/constants/helpDesk';
import { useLogEntries } from '@monorepo/shared/hooks/logs/useLogEntries';
import { useLogEntryFields } from '@monorepo/shared/hooks/logs/useLogFields';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useDateRangeFilter } from '@monorepo/shared/hooks/useDateRangeFilter';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { format } from 'date-fns';
import {
  FieldDataType,
  FieldValueResponse,
  FormulaOutputType,
  localEquivalentOfUTC,
  LogEntryResponse,
  SortDirection,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { LogEntryTableActions } from './LogEntryTableActions';
import { LogEntryToolbar } from './LogEntryToolbar';

const getValueForField = (logFieldValue?: FieldValueResponse) =>
  logFieldValue
    ? fieldValueDisplayFactory({
        fieldType: logFieldValue.fieldType,
        fieldValue: logFieldValue.fieldValue,
      })
    : '';

const IncompleteEntryIndicator = styled(Tooltip)`
  & svg {
    width: 0.875rem;
    height: 0.875rem;
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

// this column's definition is independent of any props, so don't recreate the object on each render
const incompleteEntryColumn: TableColumn<LogEntryResponse> = {
  id: 'is-complete',
  header: <VisuallyHidden>Entry completion status</VisuallyHidden>,
  contents: ({ isComplete }) =>
    isComplete ? (
      <VisuallyHidden>The Log entry is complete</VisuallyHidden>
    ) : (
      <IncompleteEntryIndicator
        placement="bottom-start"
        title="This entry submission is missing required fields. Edit this row and complete all required fields to meet the requirement for this entry."
      >
        <div>
          <ErrorIcon />
          <VisuallyHidden>The Log entry is incomplete</VisuallyHidden>
        </div>
      </IncompleteEntryIndicator>
    ),
  width: '2%',
  padding: 'none',
};

const StyledMain = styled(Main)`
  ${verticalLayout}
`;

const StyledTable = styled(Table)`
  ${fillHeightAndScrollable}
` as typeof Table;

const StyledPagination = styled(Pagination)`
  flex-shrink: 0;

  /* Since the pagination control is pinned to the bottom of this 100% height component, we
   know it will run into the pendo icon. So we give it extra padding. */
  margin-right: ${helpDeskButtonDiameter};
`;

type LogEntryTableProps = {
  onEditEntry: (logEntryId?: string) => void;
  onUploadEntries: () => void;
};

export function LogEntryTable({
  onEditEntry,
  onUploadEntries,
}: LogEntryTableProps) {
  const { logId, organizationId, pageNumber, projectId } = useParams();
  if (!logId || !organizationId || !projectId) {
    throw new Error(
      "LogEntryTable component was rendered in a page with url that doesn't have logId, or organizationId, or projectId",
    );
  }
  const canSubmitDataForProject = useHasProjectUpdatePermissions(projectId);
  const navigate = useNavigate();
  const { filter } = useDateRangeFilter();
  const { isLoading: logEntryFieldsLoading, logFields: logEntryFields = [] } =
    useLogEntryFields({
      logId,
      organizationId,
    });
  const {
    isLoading: logEntriesLoading,
    isFetching: logEntriesFetching,
    logEntries,
    pagination,
  } = useLogEntries({
    after: filter?.from ? UTCEquivalentOfLocal(filter.from) : undefined,
    before: filter?.to ? UTCEquivalentOfLocal(filter.to) : undefined,
    logId,
    organizationId,
    projectId,
    requestedPage: Number(pageNumber) || 0,
    sortDirection: SortDirection.DESC,
  });

  const handlePageChange = useCallback(
    (newPage: number) => {
      navigate(`../../${newPage}`);
    },
    [navigate],
  );
  // Reset table to show first page when filters change
  useEffect(
    () => handlePageChange(0),
    // Not including `handlePageChange` in useEffect's dependencies, because we need to invoke this effect only
    // when filter updates. Every time we paginate we navigate to new url, `navigate` object updates,
    // which updates `handlePageChange` and would've invoked this hook too.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );

  const hasIncompleteEntries = (logEntries || []).some(
    ({ isComplete }) => !isComplete,
  );

  const createActionsColumnContent = useCallback(
    (logEntry: LogEntryResponse) => (
      <LogEntryTableActions logEntry={logEntry} onEditEntry={onEditEntry} />
    ),
    [onEditEntry],
  );

  const columns: TableColumn<LogEntryResponse>[] = useMemo(
    () => [
      ...(hasIncompleteEntries ? [incompleteEntryColumn] : []),
      {
        id: 'log-date',
        header: 'Log Date',
        contents: ({ logDate }) =>
          format(localEquivalentOfUTC(new Date(logDate)), 'Pp'),
      },
      ...logEntryFields.map<TableColumn<LogEntryResponse>>((field) => ({
        id: field.columnName,
        header: field.name,
        contents: ({ logFieldValues }) =>
          getValueForField(logFieldValues[field.id]),
        align:
          field.type === FieldDataType.NUMERIC ||
          (field.type === FieldDataType.FORMULA &&
            'outputType' in field &&
            field.outputType === FormulaOutputType.NUMBER)
            ? 'right'
            : undefined,
      })),
      ...(canSubmitDataForProject
        ? [
            {
              id: 'actions',
              header: 'Actions',
              contents: createActionsColumnContent,
              align: 'center' as const,
              width: '10%',
            },
          ]
        : []),
    ],
    [
      canSubmitDataForProject,
      createActionsColumnContent,
      hasIncompleteEntries,
      logEntryFields,
    ],
  );

  const { SkipLink, SkipLinkTarget } = useSkipWidgetLink();

  if (logEntriesLoading || logEntriesFetching || logEntryFieldsLoading) {
    return <Loading />;
  }

  return (
    <StyledMain>
      <LogEntryToolbar
        logId={logId}
        organizationId={organizationId}
        projectId={projectId}
        onAddNew={() => onEditEntry()}
        onUpload={onUploadEntries}
      />
      <SkipLink linkText="Skip to pagination" />
      <StyledTable
        ariaLabel="All Log entries"
        columns={columns}
        rows={logEntries || []}
      />
      <SkipLinkTarget />
      <StyledPagination
        onPageChange={handlePageChange}
        page={pagination?.currentPage || 0}
        rowsPerPage={pagination?.perPage}
        totalRowCount={pagination?.fullCount}
      />
    </StyledMain>
  );
}
