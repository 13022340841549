import { FrequencyProvider } from '@monorepo/shared/contexts/FrequencyContext';
import {
  CUSTOM_FREQUENCY,
  FrequencyOption,
} from '@monorepo/shared/contexts/FrequencyContext/types';
import { CustomFrequencyErrors } from '@monorepo/shared/utils/CustomFrequencyValidator';
import {
  CustomFrequency,
  FrequencyResponse,
  IntervalFrequencyEnum,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { DatePicker } from '../dateAndTimePickers';
import { CustomFrequencyForm } from './CustomFrequencyForm';
import { IntervalFrequencySelect } from './IntervalFrequencySelect';

export type FrequencyPickerErrors = {
  customFrequency?: CustomFrequencyErrors;
  intervalFrequency?: string;
  startDate?: string;
};

type FrequencyPickerProps = {
  errors?: FrequencyPickerErrors;
  nonCustomFrequencyOptions: IntervalFrequencyEnum[];
  onChange: (arg0: { target: { value: Partial<FrequencyResponse> } }) => void;
  startDateIsRequired?: boolean;
  startDateTooltipText?: string;
  value?: Partial<FrequencyResponse>;
};

/**
 * This component does NOT contain validation.
 * Any validation that should be performed on this picker should be done by its parent, and errors should
 *  be passed via the `errors` object. For example validation, see FrequencyPickerField.tsx (this is probably the
 *  component you want to be using, unless you require using this picker outside of the context of final form).
 * Setting `startDateIsRequired` doesn't add validation to that start date component - it adds the "*" to its
 *  label, and the `required` attribute to the HTML input for the date picker.
 */
export function FrequencyPicker({
  errors,
  nonCustomFrequencyOptions,
  onChange,
  startDateIsRequired,
  startDateTooltipText,
  value,
}: FrequencyPickerProps) {
  const handleFrequencyChanged = useCallback(
    (
      newIntervalFrequency?: FrequencyOption | null,
      newCustomFrequency?: CustomFrequency | null,
    ) => {
      onChange({
        target: {
          value: {
            ...value,
            intervalFrequency:
              newIntervalFrequency === CUSTOM_FREQUENCY
                ? undefined
                : newIntervalFrequency || undefined,
            customFrequency: newCustomFrequency || undefined,
          },
        },
      });
    },
    [onChange, value],
  );

  return (
    <FrequencyProvider
      alwaysUseDatePicker
      customFrequency={value?.customFrequency}
      onFrequencyChange={handleFrequencyChanged}
      selectedFrequency={value?.intervalFrequency}
    >
      <IntervalFrequencySelect
        error={!!errors?.intervalFrequency}
        errorText={errors?.intervalFrequency}
        nonCustomFrequencyOptions={nonCustomFrequencyOptions}
      />
      {value?.customFrequency && (
        <CustomFrequencyForm errors={errors?.customFrequency} />
      )}
      <DatePicker
        error={!!errors?.startDate}
        errorText={errors?.startDate}
        label="Start Date"
        name="startDate"
        tooltipText={startDateTooltipText}
        onChange={({ target: { value: newStartDate } }) =>
          onChange({
            target: {
              value: {
                ...value,
                startDate: newStartDate || undefined,
              },
            },
          })
        }
        required={startDateIsRequired}
        value={value?.startDate}
      />
    </FrequencyProvider>
  );
}
