import { Link } from '@monorepo/shared/componentsV2/Link';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { useDynamicLinkOnSameLog } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameLog';
import { useHasProjectSettingsPermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { EdpViewRefResponse, ViewType } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useLogViews } from '../../../hooks/useLogViews';
import { ViewsTableActions } from '../ViewsTableActions';

const StyledTable = styled(Table)`
  padding-bottom: 4rem;
` as typeof Table;

export function LogViewsTable({ className }: { className?: string }) {
  const { logId, organizationId, projectId } = useParams();
  if (!logId || !organizationId || !projectId) {
    throw new Error(
      "LogViewsTable component was rendered in a page with url that doesn't have logId, projectId or organizationId",
    );
  }
  const canEditViews = useHasProjectSettingsPermissions(projectId);

  const { isLoading, views } = useLogViews({
    logId,
    organizationId,
    projectId,
  });

  const { getLinkOnSameLog } = useDynamicLinkOnSameLog();
  const createViewNameColumnContent = useCallback(
    ({ id, name }) => (
      <Link to={getLinkOnSameLog(`/views/${id}`)} color="grey">
        {name}
      </Link>
    ),
    [getLinkOnSameLog],
  );
  const createActionsColumnContent = useCallback(
    (view: EdpViewRefResponse) => (
      <ViewsTableActions
        viewId={view.id}
        viewName={view.name}
        viewOriginId={view.originId}
        viewType={ViewType.LOG}
      />
    ),
    [],
  );

  const columns: TableColumn<EdpViewRefResponse>[] = useMemo(
    () => [
      {
        id: 'view-name',
        header: 'View Name',
        contents: createViewNameColumnContent,
      },
      {
        id: 'description',
        header: 'Description',
        contents: 'description',
        width: '45%',
      },
      {
        id: 'limits',
        header: 'Limits',
        width: '10%',
        contents: 'limitQueryStepCount',
      },
      ...(canEditViews
        ? [
            {
              id: 'actions',
              header: 'Actions',
              contents: createActionsColumnContent,
              align: 'center' as const,
              width: '10%',
            },
          ]
        : []),
    ],
    [canEditViews, createActionsColumnContent, createViewNameColumnContent],
  );

  return isLoading ? (
    <Loading />
  ) : (
    <StyledTable
      ariaLabel="All views"
      className={className}
      columns={columns}
      rows={views || []}
    />
  );
}
