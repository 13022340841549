import React, { createContext, useState } from 'react';
import { ProjectSnakeCase } from '../apiClient/types';

type CurrentProjectContextType = {
  project?: ProjectSnakeCase;
  setCurrentProject: React.Dispatch<
    React.SetStateAction<ProjectSnakeCase | undefined>
  >;
};

export const CurrentProjectContext = createContext<
  CurrentProjectContextType | undefined
>(undefined);

interface CurrentProjectProviderProps {
  children: React.ReactNode;
}

export const CurrentProjectProvider = ({
  children,
}: CurrentProjectProviderProps) => {
  const [project, setCurrentProject] = useState<ProjectSnakeCase | undefined>();

  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CurrentProjectContext.Provider value={{ project, setCurrentProject }}>
      {children}
    </CurrentProjectContext.Provider>
  );
};
