import React from 'react';
import { NavLink, Link as ReactLink } from 'react-router-dom-v5-compat';
import styled, { css } from 'styled-components';
import { focusOutline } from '../styles/focus';
import { Button } from './Button';

const greyStyling = css<{ $outlineOffset?: string }>`
  color: ${({ theme }) => theme.colors.gray666};
  text-decoration: none;

  &:focus-visible {
    ${({ $outlineOffset = '2px' }) => focusOutline({ $outlineOffset })};
  }
`;

const primaryStyling = css<{ $outlineOffset?: string }>`
  ${greyStyling}
  color: ${({ theme }) => theme.colors.lightBlue};

  &:hover {
    color: ${({ theme }) => theme.colors.darkerBlue};
  }

  &:focus,
  &:focus-visible {
    color: ${({ theme }) => theme.colors.lightBlue};
  }
`;

type ButtonLinkProps = React.ComponentProps<typeof Button> & {
  to: string;
};

export const ButtonLink = React.forwardRef(
  (
    { children, ...props }: ButtonLinkProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <Button role="link" component={ReactLink} {...props} ref={ref}>
      {children}
    </Button>
  ),
);

const DefaultLink = styled(NavLink)`
  ${primaryStyling}
` as typeof NavLink;

const GreyLink = styled(NavLink)`
  ${greyStyling}
` as typeof NavLink;

const DefaultA = styled.a`
  ${primaryStyling}
`;

const GreyA = styled.a`
  ${greyStyling}
`;

interface LinkOptions {
  color?: 'grey';
  $outlineOffset?: string;
}

export const Link = React.forwardRef(
  (
    props: React.ComponentProps<typeof DefaultLink> & LinkOptions,
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    const { color } = props;

    switch (color) {
      case 'grey':
        return <GreyLink {...props} ref={ref} />;
      default:
        return <DefaultLink {...props} ref={ref} />;
    }
  },
);

export const A = React.forwardRef(
  (
    props: React.ComponentProps<typeof DefaultA> & LinkOptions,
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    const { color } = props;
    switch (color) {
      case 'grey':
        return <GreyA {...props} ref={ref} />;
      default:
        return <DefaultA {...props} ref={ref} />;
    }
  },
);
