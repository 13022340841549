import {
  FieldResponse,
  FieldValueResponse,
  NullableString,
} from 'mapistry-shared';
import React from 'react';
import { EditFieldValueFactory } from '../fieldDataType/values/EditFieldValueFactory';
import { ModalFormInstructions } from '../modals/contents/ModalFormInstructions';
import { StyledFormGroup } from '../styled/form';

type EditEntryFormProps = {
  children: React.ReactNode;
  entryTypeDisplayName: string;
  fields: FieldResponse[];
  fieldValues?: Record<FieldResponse['id'], FieldValueResponse>;
  instructions?: NullableString;
  organizationId: string;
  projectId: string | undefined;
};

function FormInstructions({
  entryTypeDisplayName,
  instructions,
}: {
  entryTypeDisplayName: string;
  instructions?: NullableString;
}) {
  // not using a default argument to show the default for `null`, too
  const defaultInstructions = `Enter the required values for your ${entryTypeDisplayName}.`;
  const instructionsToShow = instructions || defaultInstructions;

  return <ModalFormInstructions>{instructionsToShow}</ModalFormInstructions>;
}

export function EditEntryForm({
  children,
  entryTypeDisplayName,
  fields,
  fieldValues,
  instructions,
  organizationId,
  projectId,
}: EditEntryFormProps) {
  return (
    <>
      <FormInstructions
        entryTypeDisplayName={entryTypeDisplayName}
        instructions={instructions}
      />
      {children}
      {fields.map((field) => (
        <StyledFormGroup row key={field.id}>
          <EditFieldValueFactory
            field={field}
            fieldValue={
              fieldValues ? fieldValues[field.id]?.fieldValue : undefined
            }
            organizationId={organizationId}
            projectId={projectId}
          />
        </StyledFormGroup>
      ))}
    </>
  );
}
