import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { Search } from '@monorepo/shared/componentsV2/Search';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  max-width: 10rem;
`;

const ButtonContainer = styled.div`
  min-width: 7rem;
`;

interface WorkflowListHeaderProps {
  onAddNew: () => void;
  onSearchChange?: React.ComponentProps<typeof Search>['onChange'];
  organizationId: string;
}

export function WorkflowListHeader({
  onAddNew,
  onSearchChange,
  organizationId,
}: WorkflowListHeaderProps) {
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  return (
    <Header
      rightActions={
        <ButtonsGroup>
          {onSearchChange && (
            <SearchContainer>
              <Search onChange={onSearchChange} />
            </SearchContainer>
          )}
          {canEditOrgSettings && (
            <ButtonContainer>
              <Button color="primary" onClick={onAddNew} variant="contained">
                Create new Workflow
              </Button>
            </ButtonContainer>
          )}
        </ButtonsGroup>
      }
      title="Workflows"
      variant="noDropShadow"
    />
  );
}
