import React, { useEffect } from 'react';
import { useRegisterPushMessageHandlers } from './pushMessageHandlers/useRegisterPushMessageHandlers';
import { socket } from './webSocket';

interface WebSocketProviderProps {
  children: React.ReactElement;
}

function setupConnectionDebugLogging({ enableDebuggingLogs = false }) {
  if (!enableDebuggingLogs) {
    return;
  }

  socket.on('connect', () => console.log('socket connected'));

  socket.on('connect_error', (error) => {
    if (socket.active) {
      // temporary failure, the socket will automatically try to reconnect
      console.log(
        'socket - temporary connection failure, will try reconnecting',
        error,
      );
    } else {
      // the connection was denied by the server (`socket.connect()` must be manually called in order to reconnect).
      // mostly happens if the user is somehow not logged in when trying to establish connection
      console.log('socket - connection denied', error);
    }
  });

  socket.on('disconnect', (reason, description) => {
    console.log('socket disconnected', reason, description);
  });
}

export function useConnectWebSocket() {
  useEffect(() => {
    socket.connect();
    setupConnectionDebugLogging({ enableDebuggingLogs: false });

    return () => {
      socket.disconnect();
    };
  }, []);
}

export const WebSocketSetup = ({
  children,
}: WebSocketProviderProps): React.ReactElement => {
  // Overall, it's not advisable to add event handlers in the child components that use them,
  // because then the handling of such events is dependent on whether or not that component
  // is currently mounted. Instead, add all event listeners as early as possible.
  useRegisterPushMessageHandlers();
  useConnectWebSocket();

  return children;
};
