import { Api } from '@monorepo/shared/apiClient';
import { FetchAnalyticsEmbedUrlResponse } from '@monorepo/shared/apiClient/analytics';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

const QUERY_KEY = 'analyticsEmbed';
/**
 * We don't include the dashboardId in the key to prevent additional, unnecessary reloads
 * when we perform URL syncing. We only need to fetch the embed URL a single time when
 * a user goes to analytics because they will do all their navigation from within the embed.
 */
export const createQueryKey = () => [QUERY_KEY] as const;

type UseSimgaEmbedUrlProps = {
  config?: QueryConfig<FetchAnalyticsEmbedUrlResponse, Api.ErrorResponse>;
};

export const useSigmaEmbedUrl = (
  dashboardId: string | undefined,
  { config: inputConfig }: UseSimgaEmbedUrlProps = {},
) => {
  const config = getQueryConfig<FetchAnalyticsEmbedUrlResponse>(
    inputConfig,
    true,
  );
  const key = createQueryKey();
  const { data: embedResponse, ...queryInfo } = useQuery(
    key,
    () => Api.fetchSigmaEmbedUrl(dashboardId),
    config,
  );

  return { embedResponse, ...queryInfo };
};

export function removeFromCache(): void {
  // we use removeQueries so the invalidation is synchronus - as soon as it's called, the value isn't available anymore
  // synchronous invalidation is necessary for an unmount-immediate-remount scenario that occurs when the analytics nav
  //  bar button is clicked when analytics is already open
  queryCache.removeQueries(QUERY_KEY);
}
