import { header1, label } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';
import { DocumentTitle } from './DocumentTitle';
import { Toolbar } from './Toolbar';

export const Title = styled.h1`
  ${header1}
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
`;

export const Subtitle = styled.div`
  ${label}
  display: flex;
  color: ${({ theme }) => theme.colors.gray666};
  text-transform: uppercase;
`;

type HeaderProps = React.ComponentProps<typeof Toolbar> & {
  title: React.ReactNode;
  subtitle?: string;
};

export function Header({
  centerActions,
  leftActions,
  rightActions,
  title,
  subtitle,
  variant,
  className,
}: HeaderProps) {
  const leftActionTitleAndOrSubtitle = subtitle ? (
    <div>
      {leftActions}
      <Subtitle>{subtitle}</Subtitle>
      <Title>{title}</Title>
    </div>
  ) : (
    <>
      {leftActions}
      <Title>{title}</Title>
    </>
  );

  return (
    <>
      {/* 
        For now, the Document Title only works for Header components where the title is a pure string - e.g. it does not work
        in cases where title is a complex component, like breadcrumbs. For pages with breadcrumbs in the header, the title
        will fall back to the default title. This is something to fix in the future.
        For those pages, the h1 is currently also messed up and reads joined together jumbled breadcrumb text. 
      */}
      {typeof title === 'string' && <DocumentTitle title={title} />}
      <Toolbar
        className={className}
        centerActions={centerActions}
        leftActions={leftActionTitleAndOrSubtitle}
        rightActions={rightActions}
        variant={variant}
      />
    </>
  );
}
