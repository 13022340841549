import { AlertModal } from '@monorepo/shared/componentsV2/modals/AlertModal';
import { ConfirmModal } from '@monorepo/shared/componentsV2/modals/ConfirmModal';
import { PromptModal } from '@monorepo/shared/componentsV2/modals/PromptModal';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ModalContext } from './ModalContext';

type ModalType = 'alert' | 'confirm' | 'prompt';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModalOpts = Record<string, any>;

type ModalState = {
  showModal: boolean;
  modalType?: ModalType;
  modalOpts?: ModalOpts;
  prevModalOpts?: ModalOpts;
};

type ModalProviderProps = {
  children: NonNullable<React.ReactNode>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function ModalProvider({ children }: ModalProviderProps) {
  const [modalState, setModalState] = useState<ModalState>({
    showModal: false,
    modalType: undefined,
    modalOpts: undefined,
    prevModalOpts: undefined,
  });
  const { showModal, modalType, modalOpts, prevModalOpts } = modalState;

  const setModalOpen = useCallback((type, opts) => {
    setModalState((prevModalState) => ({
      ...prevModalState,
      showModal: true,
      modalType: type,
      modalOpts: opts,
    }));
  }, []);

  const setModalClosedAndReset = useCallback(() => {
    setModalState((prevModalState) => ({
      ...prevModalState,
      showModal: false,
      modalType: undefined,
      modalOpts: undefined,
      prevModalOpts: prevModalState.modalOpts,
    }));
  }, []);

  const promiseCallbacksRef = useRef<{
    resolve: (value?: unknown) => void;
    reject: (reason?: unknown) => void;
  }>({
    resolve: noop,
    reject: noop,
  });

  const openModal = useCallback(
    (type: ModalType, opts: ModalOpts) => {
      setModalOpen(type, opts);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const p = new Promise<any>((resolve, reject) => {
        promiseCallbacksRef.current = { resolve, reject };
      }).finally(() => {
        promiseCallbacksRef.current = {
          resolve: noop,
          reject: noop,
        };
        setModalClosedAndReset();
      });
      return p;
    },
    [setModalOpen, setModalClosedAndReset],
  );

  const alert = useCallback((opts) => openModal('alert', opts), [openModal]);

  const confirm = useCallback(
    (opts) => openModal('confirm', opts),
    [openModal],
  );

  const prompt = useCallback((opts) => openModal('prompt', opts), [openModal]);

  const contextValue = useMemo(
    () => ({
      alert,
      confirm,
      prompt,
    }),
    [alert, confirm, prompt],
  );

  const { resolve, reject } = promiseCallbacksRef.current;

  const modalElements = useMemo(() => {
    const getOptValue = (key: string) =>
      modalOpts ? modalOpts[key] : prevModalOpts?.[key];

    const baseModalOptProps = {
      description: getOptValue('description'),
      title: getOptValue('title'),
      showCloseButton: getOptValue('showCloseButton'),
    };

    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    const onConfirmAsync = async (value) => {
      // by default, this will simply resolve the modal (closes it)
      // but if an onConfirmAsync function is passed to it, we await it and then resolve the modal
      try {
        if (modalOpts?.onConfirmAsync) {
          await modalOpts.onConfirmAsync(value);
        }
        resolve(value);
      } catch (err) {
        reject(err);
      }
    };

    switch (modalType) {
      case 'alert':
        return (
          <AlertModal
            {...baseModalOptProps}
            open={showModal}
            buttonText={getOptValue('buttonText')}
            onDone={() => resolve()}
          />
        );

      case 'confirm':
        return (
          <ConfirmModal
            {...baseModalOptProps}
            open={showModal}
            cancelButtonText={getOptValue('cancelButtonText')}
            confirmButtonText={getOptValue('confirmButtonText')}
            afterConfirmButtonText={modalOpts?.confirmButtonHandlingConfirmText}
            confirmChoices={getOptValue('confirmChoices')}
            danger={getOptValue('danger')}
            onConfirmAsync={onConfirmAsync}
            onCancel={() => resolve(false)}
          />
        );

      case 'prompt':
        return (
          <PromptModal
            {...baseModalOptProps}
            open={showModal}
            cancelButtonText={getOptValue('cancelButtonText')}
            confirmButtonText={getOptValue('confirmButtonText')}
            initialInputValue={getOptValue('initialInputValue')}
            danger={getOptValue('danger')}
            multiline={getOptValue('multiline')}
            onConfirm={(inputValue) => resolve(inputValue)}
            onCancel={() => resolve()}
          />
        );
      default:
        return null;
    }
  }, [modalType, modalOpts, prevModalOpts, resolve, reject, showModal]);

  return (
    <ModalContext.Provider value={contextValue}>
      {modalElements}
      {children}
    </ModalContext.Provider>
  );
}
