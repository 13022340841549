import _get from 'lodash.get';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';
import { fetchCurrentUserWidgetSettingAction } from '../../actions/currentUser';
import APP from '../../config';
import UserSettings from '../../types/UserSettings';
import { useStableReference } from '../useStableReference';

const deepEqual = _.isEqual;

type WidgetDuplicates = {
  [widgetId: string]: {
    widgetSettingsVersion: string;
    widgetName: string;
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useWidgetDuplicates(
  widgetType: string,
  widgetSettingsVersion: string,
) {
  const dispatch = useDispatch();
  const allDuplicates = useSelector((state) =>
    _get(
      state,
      `currentUser.userSettings.${APP.projectId}.${UserSettings.DUPLICATED_WIDGETS}.${widgetType}`,
    ),
  ) as WidgetDuplicates | undefined;
  const widgetSettingsForCurrentVersion = useMemo<typeof allDuplicates>(() => {
    if (!allDuplicates) return undefined;
    const filteredEntries = Object.entries(allDuplicates).filter(
      ([, settings]) =>
        settings.widgetSettingsVersion === widgetSettingsVersion,
    );
    if (!filteredEntries.length) return undefined;
    return Object.fromEntries(filteredEntries);
  }, [allDuplicates, widgetSettingsVersion]);
  useEffect(() => {
    dispatch(
      fetchCurrentUserWidgetSettingAction(
        APP.projectId,
        UserSettings.DUPLICATED_WIDGETS,
        widgetType,
      ),
    );
  }, [widgetType, dispatch]);
  const stableWidgetSettingsForCurrentVersion = useStableReference(
    widgetSettingsForCurrentVersion,
    deepEqual,
  );
  return stableWidgetSettingsForCurrentVersion;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default useWidgetDuplicates;
