import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { FullPageFatalError } from '@monorepo/shared/componentsV2/errorDisplays/FullPageFatalError';
import React from 'react';

type TopLevelErrorBoundaryProps = {
  children: React.ReactNode;
};

export const TopLevelErrorBoundary = ({
  children,
}: TopLevelErrorBoundaryProps) => (
  <ErrorBoundary Fallback={FullPageFatalError}>{children}</ErrorBoundary>
);
