import { Api } from '@monorepo/shared/apiClient';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchFormSubmissionParams;

const KEY_START = 'formSubmission';
export const createQueryKey = ({
  calendarId,
  projectId,
  submissionSlug,
  templateSlug,
}: KeyParams) =>
  [KEY_START, projectId, templateSlug, submissionSlug, calendarId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchFormSubmission
>;

type UseFormSubmissionParams = Partial<Api.FetchFormSubmissionParams> & {
  config?: QueryConfig<Api.FetchFormSubmissionResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  projectId: string,
  templateSlug: string,
  submissionSlug: string,
  calendarId: string,
) =>
  Api.fetchFormSubmission({
    calendarId,
    projectId,
    submissionSlug,
    templateSlug,
  });

export const useFormSubmission = ({
  config: inputConfig,
  calendarId,
  projectId,
  submissionSlug,
  templateSlug,
}: UseFormSubmissionParams) => {
  const isEnabled =
    !!projectId && !!templateSlug && !!submissionSlug && !!calendarId;
  const config = getQueryConfig<Api.FetchFormSubmissionResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({ calendarId, projectId, submissionSlug, templateSlug })
    : undefined;
  const { data: formSubmission, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    formSubmission,
    ...queryInfo,
  };
};

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createQueryKey(keyParams));
}
