import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { FormulaFormValues } from '@monorepo/shared/componentsV2/formula';
import { EditFormulaStepForm } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage/EditFormulaStepModal/EditFormulaStepForm';
import FormulaIcon from '@svg/formula.svg';
import {
  CreateWorkflowFormulaStepRequest,
  WorkflowStepOperationType,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { DeepPartial } from 'utility-types';
import {
  BaseEditStepModal,
  CommonEditStepModalProps,
} from '../BaseEditStepModal';

export function EditFormulaStepModal({
  isNewStep,
  onSubmitStep,
  stepId,
  workflow,
  ...props
}: CommonEditStepModalProps<CreateWorkflowFormulaStepRequest>) {
  const submitStep = useCallback(
    async (data: FormulaFormValues) => {
      const { description, expression, name } = data;
      const step: CreateWorkflowFormulaStepRequest = {
        operationConfig: {
          description,
          friendlyExpression: expression,
          name,
        },
        type: WorkflowStepOperationType.FORMULA,
      };
      await onSubmitStep(step);
    },
    [onSubmitStep],
  );

  const initialValues = useMemo<Partial<FormulaFormValues>>(() => {
    if (isNewStep) {
      return {};
    }
    const step = workflow.steps.find((s) => s.id === stepId);
    if (!step || step.type !== WorkflowStepOperationType.FORMULA) {
      throw new Error(
        `EditFormulaStepModal was opened to edit not formula step. Type: ${step?.type}, step id: ${stepId}.`,
      );
    }
    // Defining this variable to get misspelling check. TS allows specify only known properties.
    const existingValues: Partial<FormulaFormValues> = {
      description: step.operationConfig.description,
      expression: step.operationConfig.friendlyExpression,
      name: step.operationConfig.name,
    };
    return existingValues;
  }, [isNewStep, stepId, workflow.steps]);

  return (
    <Form<FormulaFormValues, DeepPartial<FormulaFormValues>>
      onSubmit={submitStep}
      initialValues={initialValues}
      subscription={{ invalid: true, pristine: true, submitting: true }}
    >
      {(formProps) => (
        <BaseEditStepModal
          {...props}
          {...formProps}
          title="Formula"
          icon={<FormulaIcon />}
          isNewStep={isNewStep}
        >
          <ErrorBoundary>
            <EditFormulaStepForm
              disableAll={!isNewStep}
              workflow={workflow}
              stepId={stepId}
            />
          </ErrorBoundary>
        </BaseEditStepModal>
      )}
    </Form>
  );
}
