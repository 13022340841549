import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { Loading } from '../components/Loading';
import { useResourceTypes } from '../hooks/resources/useResourceTypes';
import { Select } from './Select';

type Filters = {
  excludedResourceTypeId?: string;
  excludeSiteSpecific?: boolean;
};

type SelectResourceTypeProps = Omit<
  React.ComponentProps<typeof Select>,
  'options'
> & {
  filters: Filters;
};

export function SelectResourceType({
  filters: { excludedResourceTypeId, excludeSiteSpecific } = {},
  ...props
}: SelectResourceTypeProps) {
  const { organizationId } = useParams();
  const { resourceTypes, isLoading } = useResourceTypes({
    organizationId,
    // currently nothing is done when this errors
    config: { useErrorBoundary: false },
  });

  const options = useMemo(
    () =>
      (resourceTypes || [])
        .filter(
          (rt) => !excludedResourceTypeId || rt.id !== excludedResourceTypeId,
        )
        .filter((rt) => !excludeSiteSpecific || !rt.isSiteSpecific)
        .map((rt) => ({ label: rt.name, value: rt.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [excludeSiteSpecific, excludedResourceTypeId, resourceTypes],
  );

  if (isLoading) {
    return <Loading />;
  }

  return <Select {...props} options={options} />;
}
