import { Button } from '@monorepo/shared/componentsV2/Button';
import React, { useMemo, useState } from 'react';
import { BaseModal } from './BaseModal';

interface ConfirmModalChoice {
  label: string;
  // this label is used for the button when (1) this choice is confirmed by the user AND (2) onConfirmAsync is in the process of executing
  afterConfirmLabel?: string;
  value: string;
  buttonProps: React.ComponentProps<typeof Button>;
}

type BaseConfirmModalProps = Omit<
  React.ComponentProps<typeof BaseModal>,
  'buttons' | 'children' | 'onClose'
> & {
  cancelButtonText?: string;
  onCancel: () => void;
};

function isMultiChoice(
  props: ConfirmModalProps,
): props is MultiChoiceConfirmModalProps {
  return 'confirmChoices' in props && props.confirmChoices != null;
}

export interface SingleChoiceConfirmModalProps extends BaseConfirmModalProps {
  confirmButtonText?: string;
  // this label is used for the confirm button when (1) the user has confirmed AND (2) onConfirmAsync is in the process of executing
  afterConfirmButtonText?: string;
  danger?: boolean;
  onConfirmAsync: (value: boolean) => Promise<void>;
}

export interface MultiChoiceConfirmModalProps extends BaseConfirmModalProps {
  confirmChoices: ConfirmModalChoice[];
  onConfirmAsync: (choice: string) => Promise<void>;
}

type ConfirmModalProps =
  | SingleChoiceConfirmModalProps
  | MultiChoiceConfirmModalProps;

export function ConfirmModal(props: ConfirmModalProps) {
  const { open, title, description, onCancel, showCloseButton } = props;

  const [confirmValue, setConfirmValue] = useState<
    string | boolean | undefined
  >(undefined);

  const isHandlingConfirm = !!confirmValue;

  const confirmButtons = useMemo(() => {
    if (isMultiChoice(props)) {
      const { onConfirmAsync, confirmChoices } = props;
      return confirmChoices.reverse().map((opt) => {
        const { label, value, buttonProps } = opt;
        const afterConfirmLabel = opt.afterConfirmLabel || label;
        return (
          <Button
            key={value}
            {...buttonProps}
            onClick={async () => {
              // disable buttons from being clicked & update the confirm button text to the `afterConfirmLabel` value
              setConfirmValue(value);
              await onConfirmAsync(value);
            }}
            disabled={isHandlingConfirm}
          >
            {isHandlingConfirm && confirmValue === value
              ? afterConfirmLabel
              : label}
          </Button>
        );
      });
    }

    const { confirmButtonText = 'OK', onConfirmAsync, danger } = props;
    const { afterConfirmButtonText = confirmButtonText } = props;
    return (
      <Button
        onClick={async () => {
          // disable buttons from being clicked & update the confirm button text to the `afterConfirmButtonText` value
          setConfirmValue(true);
          await onConfirmAsync(true);
        }}
        color={danger ? 'secondary' : 'primary'}
        variant="contained"
        disabled={isHandlingConfirm}
      >
        {isHandlingConfirm ? afterConfirmButtonText : confirmButtonText}
      </Button>
    );
  }, [props, isHandlingConfirm, confirmValue]);

  const { cancelButtonText = 'Cancel' } = props;

  return (
    <BaseModal
      open={open}
      title={title}
      description={description}
      buttons={
        <>
          <Button onClick={onCancel} disabled={isHandlingConfirm}>
            {cancelButtonText}
          </Button>
          {confirmButtons}
        </>
      }
      // don't allow closing if onConfirmAsync is in progress
      onClose={isHandlingConfirm ? undefined : onCancel}
      showCloseButton={showCloseButton}
    />
  );
}
