import { Button } from '@monorepo/shared/componentsV2/Button';
import { convertSpecificFieldValueToSaveFieldValue } from '@monorepo/shared/componentsV2/fieldDataType/values/convertSpecificFieldValueToSaveFieldValue';
import { FrequencyPickerField } from '@monorepo/shared/componentsV2/fields/FrequencyPickerField';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { ModalFormInstructions } from '@monorepo/shared/componentsV2/modals/contents/ModalFormInstructions';
import { useToast } from '@monorepo/shared/contexts';
import { useLogEntryFields } from '@monorepo/shared/hooks/logs/useLogFields';
import * as Sentry from '@sentry/browser';
import arrayMutators from 'final-form-arrays';
import {
  FieldDataType,
  FieldResponse,
  FrequencyRequirementResponse,
  IntervalFrequencyEnum,
  localEquivalentOfUTC,
  SaveFrequencyRequirementRequest,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useFrequencyRequirementCreate } from '../../hooks/useFrequencyRequirementCreate';
import { useFrequencyRequirementUpdate } from '../../hooks/useFrequencyRequirementUpdate';
import { FrequencyRequirementFilters } from './FrequencyRequirementFilters';
import { FormValues } from './types';

const NON_CUSTOM_FREQUENCIES = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.QUARTER,
  IntervalFrequencyEnum.YEAR,
  IntervalFrequencyEnum.AS_NEEDED,
];

const getSaveDtoFromFormValues = (
  values: FormValues,
): SaveFrequencyRequirementRequest => {
  if (!values.frequency?.intervalFrequency || !values.frequency?.startDate) {
    Sentry.captureException(
      'One or more values required by the edit frequency requirement modal not available at save time',
      { extra: { formValues: values } },
    );
    throw new Error('Unexpected error');
  }
  const transformedValues = {
    ...values,
    frequency: {
      ...values.frequency,
      startDate: UTCEquivalentOfLocal(values.frequency.startDate),
    },
  };

  return transformedValues;
};

type EditFrequencyRequirementModalProps = {
  logId?: string;
  organizationId?: string;
  projectId?: string;
  open: boolean;
  onClose: () => void;
  frequencyRequirementForEdit?: FrequencyRequirementResponse;
};

export function EditFrequencyRequirementModal({
  logId,
  organizationId,
  projectId,
  open,
  onClose,
  frequencyRequirementForEdit,
}: EditFrequencyRequirementModalProps) {
  if (!organizationId || !projectId || !logId) {
    throw new Error(
      'Frequency requirements modal opened without the context of an organization, project, or log',
    );
  }

  const { success, showUserFriendlyErrorToast } = useToast();

  const [creator] = useFrequencyRequirementCreate({
    config: { throwOnError: true },
  });
  const [updator] = useFrequencyRequirementUpdate({
    config: { throwOnError: true },
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const frequencyRequirement = getSaveDtoFromFormValues(values);
      if (!frequencyRequirementForEdit) {
        await creator({
          organizationId,
          logId,
          projectId,
          frequencyRequirement,
        });
      } else {
        await updator({
          organizationId,
          logId,
          projectId,
          frequencyRequirementId: frequencyRequirementForEdit.id,
          frequencyRequirement,
        });
      }
      success(
        `Frequency requirement has been ${
          frequencyRequirementForEdit ? 'edited' : 'created'
        }. The compliance calendar may take a few minutes to reflect these changes.`,
      );
      onClose();
    } catch (err) {
      showUserFriendlyErrorToast(err, 'Unable to save Frequency Requirement.', {
        dontAutoHide: true,
      });
    }
  };

  const title = `${
    frequencyRequirementForEdit ? 'Edit' : 'Create'
  } Frequency Requirement`;

  const { logFields } = useLogEntryFields({ organizationId, logId });
  const filteredLogFields = useMemo(
    () =>
      logFields?.filter(({ type }) =>
        // only allowed to setup filters on resource and single select log fields
        [FieldDataType.RESOURCE, FieldDataType.SINGLE_SELECT].includes(type),
      ),
    [logFields],
  );

  const initialValues = useMemo(
    () =>
      frequencyRequirementForEdit
        ? {
            frequency: {
              ...frequencyRequirementForEdit.frequency,
              startDate: localEquivalentOfUTC(
                new Date(frequencyRequirementForEdit.frequency.startDate),
              ),
            },
            filters: frequencyRequirementForEdit.filters.map(
              ({ id, fieldId, value: fieldValue }) => {
                const field: FieldResponse | undefined = (logFields || []).find(
                  (lf) => lf.id === fieldId,
                );
                return {
                  id,
                  fieldId,
                  value: field
                    ? convertSpecificFieldValueToSaveFieldValue({
                        fieldValue,
                        fieldType: field.type,
                      })
                    : { value: null },
                };
              },
            ),
          }
        : {},
    [frequencyRequirementForEdit, logFields],
  );

  return (
    <Form<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ ...arrayMutators }}
    >
      {({ handleSubmit, pristine, submitting }) => (
        <BaseModal
          buttons={[
            <Button
              variant="text"
              color="primary"
              onClick={onClose}
              disabled={submitting}
              key="cancel"
            >
              Cancel
            </Button>,
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={submitting || pristine}
              key="save"
            >
              {submitting ? 'Saving...' : 'Save'}
            </Button>,
          ]}
          onClose={submitting ? undefined : onClose}
          open={open}
          showCloseButton
          title={title}
        >
          <ModalFormInstructions>
            Set how often data should be submitted for this Log, and the date
            the frequency requirement begins. When checking for missing Log
            entries, Mapistry will check that the frequency requirement
            conditions are met at least once, but users can add additional
            entries as needed.
          </ModalFormInstructions>
          <FrequencyPickerField
            name="frequency"
            nonCustomFrequencyOptions={NON_CUSTOM_FREQUENCIES}
            startDateIsRequired
            startDateTooltipText="The start date tells Mapistry when to begin checking for missing Log entries for this frequency requirement. Users can still enter data before the start date."
          />
          <FrequencyRequirementFilters
            logFields={filteredLogFields || []}
            organizationId={organizationId}
            projectId={projectId}
          />
        </BaseModal>
      )}
    </Form>
  );
}
