import FormHelperText from '@material-ui/core/FormHelperText';
import { Combobox } from '@monorepo/shared/componentsV2/Combobox';
import { bodySmall } from '@monorepo/shared/styles/text';
import styled from 'styled-components';
import { DatasetInfo } from './DatasetInfo';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35rem;
  margin-top: 1.5rem;
  ${bodySmall}
`;

export const StyledHelperText = styled(FormHelperText)`
  width: 35rem;
`;

export const StyledCombobox = styled(Combobox)`
  width: 35rem;
`;

export const StyledDatasetInfoAsPickerOption = styled(DatasetInfo)`
  padding: 1.2rem 1rem 1rem 0;

  /* let the dropdown list style background color and border radius */
  background-color: initial;
  border: initial;

  /*
    color every other option grey.
    beware, :has selector doesn't work on old browsers so this might not work on all clients
    (they'll lose the every-other coloring, but that's a minor degradation)
  */
  li.MuiAutocomplete-option:nth-child(even):has(&) {
    background-color: ${({ theme }) => theme.colors.grayeee};
  }

  /*
    since we're setting background color, keep hover and focus styles visible
  */
  li.MuiAutocomplete-option:has(&):hover,
  li.MuiAutocomplete-option:has(&)[data-focus='true'] {
    background-color: ${({ theme }) => theme.colors.graybbb};
  }
`;
