import { useSearchParamState } from '@monorepo/shared/hooks/navigation/useSearchParamState';
import { ViewType } from 'mapistry-shared';
import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useLogsAndViewsPageContext } from '../../../contexts/LogsAndViewsPageContext';
import { EditViewPage } from './EditViewPage';

export function EditViewPageFromProjectViews() {
  const { organizationId, projectId, viewId } = useParams();
  const [originId] = useSearchParamState('originId') as [string | undefined];

  if (!organizationId || !projectId) {
    throw new Error(
      "EditViewPageFromProjectViews was rendered with a url that doesn't have projectId or organizationId",
    );
  }

  const { hideHeader, showHeader } = useLogsAndViewsPageContext();
  useLayoutEffect(() => {
    hideHeader();
    return showHeader;
  }, [hideHeader, showHeader]);

  const props = {
    organizationId,
    projectId,
    typeForNewViews: ViewType.DATASET,
  };

  // the double return is just for typescript, to have a place for the assertion that originId exists when it has to
  if (viewId) {
    return <EditViewPage {...props} originId={originId} viewId={viewId} />;
  }

  if (!originId) {
    throw new Error('To create new views, originId needs to be defined.');
  }
  return <EditViewPage {...props} originId={originId} viewId={undefined} />;
}
