import React from 'react';
import styled from 'styled-components';
import { header3 } from '../../../styles/text';
import { Main } from '../../Main';

const Page = styled(Main)`
  display: flex;
  flex-direction: column;
`;

const ModalDescription = styled.h2`
  ${header3}
  margin: 0 0 1.125rem;
`;

interface DataTypeBaseProps {
  children?: React.ReactNode;
  fieldTypeName?: string;
}

export function DataTypeBase({
  children,
  fieldTypeName = 'field',
}: DataTypeBaseProps) {
  return (
    <Page>
      <ModalDescription>
        {`Add details and confirm settings for your ${fieldTypeName}.`}
      </ModalDescription>
      {!!children && children}
    </Page>
  );
}
