import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { header1 } from '@monorepo/shared/styles/text';
import { QueryStep, ViewQueryStep, ViewType } from 'mapistry-shared';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  useLastQueryStepIsInvalid,
  useQueryStepsClickHandler,
} from '../../../contexts/QueryStepsContext';
import { useEditViewInfoModal } from '../../../hooks/useEditViewInfoModal';
import { EditEdpView } from '../../../types';

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${header1}
  height: 100%;
  margin: 0;
  font-weight: 400;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  & svg {
    width: 1rem;
  }
`;

interface EditViewHeaderProps {
  originId: string;
  typeForNewViews: ViewType;
  view: EditEdpView;
}
export function EditViewHeader({
  view,
  originId,
  typeForNewViews,
}: EditViewHeaderProps) {
  const isEditMode = !!view?.id;
  const { openModal } = useEditViewInfoModal();
  const { clickHandler } = useQueryStepsClickHandler();
  const { lastQueryStepIsInvalid } = useLastQueryStepIsInvalid();

  const onSave = useCallback(
    (validatedSteps: (QueryStep | ViewQueryStep)[]) => {
      const viewType = view?.type || typeForNewViews;

      // we use the passed query steps instead of what exists in the view prop because these steps may have been updated &
      //  validated as part of this callback chain (so no render in between for the view prop to be updated)
      openModal({
        existingViewId: view.id,
        viewOriginId: originId,
        viewType,
        editedSteps: validatedSteps,
      });
    },
    [openModal, view.id, view?.type, originId, typeForNewViews],
  );

  return (
    <Toolbar
      leftActions={
        <LeftSide>
          <StyledLink color="grey" to="..">
            <ArrowBackIcon />
            {isEditMode ? `Back to ${view?.name}` : 'Back to All Views'}
          </StyledLink>
          <Title>
            {isEditMode ? 'Edit View Configuration' : 'Create a new View'}
          </Title>
        </LeftSide>
      }
      rightActions={
        <Button
          color="primary"
          disabled={lastQueryStepIsInvalid}
          onClick={clickHandler(onSave)}
          variant="contained"
        >
          Save View
        </Button>
      }
      variant="noDropShadow"
    />
  );
}
