import {
  FormulaOutputType,
  FormulaValue,
  isUnitAwareNumber,
} from 'mapistry-shared';

export const formulaDisplayFactory = ({
  outputType,
  value,
}: FormulaValue): string => {
  const defaultResult = '';
  if (value == null) {
    return defaultResult;
  }
  switch (outputType) {
    case FormulaOutputType.BOOLEAN:
      return value ? 'Yes' : 'No';
    case FormulaOutputType.NUMBER:
      return isUnitAwareNumber(value)
        ? `${value.value} ${value.unit}`
        : `${value}`;
    case FormulaOutputType.TEXT:
      return (value as string) || defaultResult;
    default:
      return defaultResult;
  }
};
