import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogFieldCache,
  LogFieldCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import { getCache, setCache } from '@monorepo/shared/hooks/logs/useLogFields';
import { MutationConfig, useMutation } from 'react-query';

type useLogFieldsCreateParams = {
  config?: MutationConfig<Api.CreateLogFieldResponse, Api.ErrorResponse>;
};

export const useLogFieldCreate = (params?: useLogFieldsCreateParams) =>
  useMutation(Api.createLogField, {
    ...params?.config,
    onSuccess: (createdLogField, createdLogFieldParams) => {
      const { logId, organizationId } = createdLogFieldParams;
      const currentItems = getCache({ logId, organizationId });

      // If there is cache, update it
      if (currentItems) {
        const newItems = [...currentItems, createdLogField];
        setCache({ logId, organizationId }, newItems);
      }

      // Invalidate any RQ cache that might use information about Log Fields
      invalidateLogFieldCache(LogFieldCacheEvents.CREATE, {
        logFieldId: createdLogField.id,
        logId,
        organizationId,
      });

      return params?.config?.onSuccess?.(
        createdLogField,
        createdLogFieldParams,
      );
    },
  });
