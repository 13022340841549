import { Api } from 'mapistry-shared/api';
import React, { StrictMode } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { App } from './components/App';

if (process.env.NODE_ENV !== 'development') {
  import('../../../server/dataDogRum');
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
Api.setApiUrl(process.env.API_URL!);

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
