import { Api } from '@monorepo/shared/apiClient';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { useErrorHandler } from '../useErrorHandler';

type KeyParams = Api.FetchLogsParams;

export const createQueryKey = ({ organizationId }: KeyParams) =>
  ['logs', organizationId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createQueryKey, typeof Api.fetchLogs>;

type UseLogsParams = Partial<Api.FetchLogsParams> & {
  config?: QueryConfig<Api.FetchLogsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (_: string, organizationId: string) =>
  Api.fetchLogs({ organizationId });

export const useLogs = ({
  config: inputConfig,
  organizationId,
}: UseLogsParams) => {
  const isEnabled = !!organizationId;
  const config = {
    ...inputConfig,
    enabled:
      inputConfig?.enabled != null
        ? inputConfig.enabled && isEnabled
        : isEnabled,
  };
  const key = isEnabled ? createQueryKey({ organizationId }) : undefined;
  const { error, data: logs, ...queryInfo } = useQuery(key, fetcher, config);
  useErrorHandler(error);
  return {
    logs,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useLogs>['logs'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}
