import { useDispatch, useSelector } from 'react-redux';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';
import { fetchEmissionCalculatedValuesAction } from '../../actions/air';
import APP from '../../config';
import {
  getCalculatedValues,
  getIsFetching,
} from '../../selectors/genericLogs';
import { GenericLogsCalculatedValue } from '../../types/ts-types';
import useMountEffect from '../useMountEffect';
import { useStableReference } from '../useStableReference';

const deepEqual = _.isEqual;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useCalculatedValues(logProjectId: string) {
  const dispatch = useDispatch();
  const airEmissionCalculatedValues = useSelector((state) =>
    getCalculatedValues(state, logProjectId),
  ) as GenericLogsCalculatedValue[] | undefined;
  const isLoading = useSelector((state) =>
    getIsFetching(state, 'airEmissionsCalculatedValues'),
  ) as boolean;
  useMountEffect(() => {
    dispatch(fetchEmissionCalculatedValuesAction(APP.projectId, logProjectId));
  });
  const stableAirEmissionCalculatedValues = useStableReference(
    airEmissionCalculatedValues,
    deepEqual,
  );
  return {
    airEmissionCalculatedValues: stableAirEmissionCalculatedValues,
    isLoading,
  };
}

export { useCalculatedValues };
