import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import styled from 'styled-components';
import { SelectField } from '../fields/SelectField';
import { TextField } from '../fields/TextField';

export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0.2rem;

  & > :not(:first-child) {
    /* when there are multiple items in the same group & it's a row FormGroup, put some margin between them */
    ${({ row }) => (row ? 'margin-left: 0.5rem;' : '')}
  }
`;

export const StyledInput = styled(TextField)`
  width: 18rem;
` as typeof TextField;

export const StyledTextArea = styled(TextField)`
  min-width: 35rem;
` as typeof TextField;

export const StyledSelect = styled(SelectField)`
  width: 18rem;
` as typeof SelectField;

export const HelperText = styled(FormHelperText)`
  max-width: 26rem;
`;

export const ErrorText = styled(HelperText)`
  color: ${({ theme }) => theme.colors.darkRed};
`;
