import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { isRequired } from '@monorepo/shared/utils/validators';
import { Operator, SingleFilter } from 'mapistry-shared';
import React from 'react';
import { useField } from 'react-final-form';

export function ValueField({
  fieldType = 'text',
  filterConditionName,
}: {
  fieldType?: 'number' | 'text';
  filterConditionName: string;
}) {
  const {
    input: { value: currentFilterOperator },
  } = useField<SingleFilter['operator']>(`${filterConditionName}.operator`);
  const shouldDisplay =
    currentFilterOperator &&
    currentFilterOperator !== Operator.EMPTY &&
    currentFilterOperator !== Operator.NOT_EMPTY;

  return shouldDisplay ? (
    <TextField
      fullWidth
      hiddenLabel
      label="Value"
      name={`${filterConditionName}.value`}
      placeholder="Value"
      required
      type={fieldType}
      validate={isRequired}
    />
  ) : (
    <>
      {/* put an empty div here to keep the number of columns in the grid consistent */}
      <div />
    </>
  );
}
