import { FormulaForm } from '@monorepo/shared/componentsV2/formula/FormulaForm';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useLogInfo } from '@monorepo/shared/hooks/logs/useLogInfo';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { LogFormulaTokenList } from './LogFormulaTokenList';

const Page = styled(Main)`
  display: flex;
  flex-direction: column;
`;

interface EditLogFormulaFieldWizardPageProps {
  fieldColumnName: string | undefined;
}

export function EditLogFormulaFieldWizardPage(
  props: EditLogFormulaFieldWizardPageProps,
) {
  const { fieldColumnName } = props;
  const { logId, organizationId } = useParams();
  const { logInfo, isLoading } = useLogInfo({ logId, organizationId });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <FormulaForm
        sampleFormulaTerms={logInfo?.sampleFormulaTerms}
        tokenList={<LogFormulaTokenList currentColumnName={fieldColumnName} />}
      />
    </Page>
  );
}
