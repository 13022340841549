import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { NavigationTabs } from '@monorepo/shared/componentsV2/NavigationTabs';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useSubnav } from '@monorepo/shared/hooks/subnav/useSubnav';
import * as theme from '@monorepo/shared/themes/default';
import classnames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { Drawer, DrawerHeader, TabContents, TabIcon } from './styled';

type Option = {
  href: string;
  isLegacy?: boolean;
  label: string;
  subtext?: string;
};

interface SubnavBaseProps {
  isLoading?: boolean;
  options: Option[];
}

type SubnavWithLabelProps = SubnavBaseProps & {
  label: string;
};

type SubnavWithHeaderProps = SubnavBaseProps & {
  navAriaLabel: string;
  header: ReactElement;
};

function hasComplexHeader(
  props: SubnavWithLabelProps | SubnavWithHeaderProps,
): props is SubnavWithHeaderProps {
  return 'header' in props;
}

function getLabel(label: string, subtext?: string) {
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  return ({ isActive }) => (
    <TabContents className={classnames({ selected: isActive })}>
      {subtext ? (
        <div>
          <div>{label}</div>
          <div className="subtext">{subtext}</div>
        </div>
      ) : (
        label
      )}
      {isActive && (
        <TabIcon>
          <VisuallyHidden>Selected</VisuallyHidden>
          <KeyboardArrowRightIcon aria-hidden />
        </TabIcon>
      )}
    </TabContents>
  );
}

/**
 *
 * When collapsing, use along with `PageWithCollapsibleSubnav`
 */
export function Subnav(props: SubnavWithHeaderProps): ReactElement;
export function Subnav(props: SubnavWithLabelProps): ReactElement;
export function Subnav(
  props: SubnavWithHeaderProps | SubnavWithLabelProps,
): ReactElement | null {
  const { isLoading, options } = props;
  const { isSubnavOpen, isSubnavDisabled } = useSubnav();

  const mappedOptions = useMemo(
    () =>
      options.map((o) => ({
        ...o,
        label: getLabel(o.label, o.subtext),
        skeletonWidth: `${o.label.length || 0}rem`,
      })),
    [options],
  );

  let headerContent: string | ReactElement;
  let navAriaLabel: string;

  if (hasComplexHeader(props)) {
    const { header, navAriaLabel: navAriaLabelFromProps } = props;
    headerContent = header;
    navAriaLabel = navAriaLabelFromProps;
  } else {
    const { label } = props;
    headerContent = label;
    navAriaLabel = label;
  }

  if (isSubnavDisabled) {
    return null;
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isSubnavOpen}
      transitionDuration={theme.constants.subnavTransitionDuration}
    >
      <DrawerHeader>{headerContent}</DrawerHeader>
      <NavigationTabs
        isLoading={isLoading}
        label={navAriaLabel}
        options={mappedOptions}
        orientation="vertical"
      />
    </Drawer>
  );
}
