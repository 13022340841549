import { Api } from '@monorepo/shared/apiClient';
import { setCache } from '@monorepo/shared/hooks/logs/useLogFields';
import { MutationConfig, useMutation } from 'react-query';

type UseLogFieldsOrderUpdateParams = {
  config?: MutationConfig<Api.UpdateLogFieldsOrderResponse, Api.ErrorResponse>;
};

export const useLogFieldsOrderUpdate = (
  params?: UseLogFieldsOrderUpdateParams,
) =>
  useMutation(Api.updateLogFieldOrder, {
    ...params?.config,
    onSuccess: (reorderedLogFields, mutationFuncParams) => {
      const { logId, organizationId } = mutationFuncParams;

      // success response includes the reordered log fields
      setCache({ logId, organizationId }, reorderedLogFields);
    },
  });
