import { Loading } from '@monorepo/shared/components/Loading';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useLogEntryCount } from '../../hooks/useLogEntryCount';

interface EntryCountProps {
  logId: string;
}

export function EntryCount({ logId }: EntryCountProps) {
  const { organizationId, projectId } = useParams();
  const { count, isLoading } = useLogEntryCount({
    logId,
    organizationId,
    projectId,
  });

  if (isLoading) {
    return <Loading size="small" />;
  }

  return <span>{count || 0}</span>;
}
