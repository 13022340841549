import { Button } from '@monorepo/shared/componentsV2/Button';
import { contentPadding, page } from '@monorepo/shared/styles/layout';
import { header1 } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  ${header1}
`;

const Page = styled.div`
  ${page}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  ${contentPadding}
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex: 1; /*  evenly sized columns */
    padding: 2rem;
  }
`;

const Links = styled.div`
  margin-top: 2rem;

  a {
    margin-right: 2rem;
  }
`;

export const EmptyLogsSalesPage = () => (
  <Page>
    <Content>
      <Columns>
        <div>
          <Header>Collect and analyze your data with Logs</Header>
          <div>
            Your organization isn’t using Mapistry Logs yet. Contact your
            Customer Success Manager to unlock this feature and make collecting,
            visualizing, and sharing your data a breeze.
          </div>
          <Links>
            <Button href="mailto:help@mapistry.com" variant="contained">
              Access Logs
            </Button>
            <Button
              href="https://www.mapistry.com/platform/data-capture-analysis/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </Button>
          </Links>
        </div>
        <div>
          <img alt="" src={`${process.env.ASSETS_ROOT}LogsSalesGraphic.png`} />
        </div>
      </Columns>
    </Content>
  </Page>
);
