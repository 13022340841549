import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import React from 'react';
import { Route } from 'react-router-dom-v5-compat';
import { RecordsPage } from './RecordsPage';

export function Records() {
  return (
    <RoutesWith404>
      <Route index element={<RecordsPage />} />
    </RoutesWith404>
  );
}
