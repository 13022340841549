import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import { useFeatureFlags } from 'mapistry-shared/api';
import React from 'react';
import { Route } from 'react-router-dom-v5-compat';
import {
  EditLogFields,
  EditLogFormulas,
  EditLogPage,
  EditLogSites,
  Mode,
} from './EditLogPage';
import { LogDetail } from './LogDetail';
import { ViewLogs } from './ViewLogs';

export function LogDataSetup() {
  const { isData2Enabled } = useFeatureFlags();

  return isData2Enabled ? (
    <RoutesWith404>
      <Route
        index
        element={
          <ErrorBoundary>
            <ViewLogs />
          </ErrorBoundary>
        }
      />
      <Route path={Mode.new}>
        <Route
          index
          element={
            <ErrorBoundary>
              <EditLogPage />
            </ErrorBoundary>
          }
        />
      </Route>
      <Route path=":logId">
        <Route
          index
          element={
            <ErrorBoundary>
              <LogDetail />
            </ErrorBoundary>
          }
        />
        <Route path=":mode">
          <Route
            index
            element={
              <ErrorBoundary>
                <EditLogPage />
              </ErrorBoundary>
            }
          />
          <Route
            path="fields"
            element={
              <ErrorBoundary>
                <EditLogFields />
              </ErrorBoundary>
            }
          />
          <Route
            path="formulas"
            element={
              <ErrorBoundary>
                <EditLogFormulas />
              </ErrorBoundary>
            }
          />
          <Route path="sites" element={<EditLogSites />} />
        </Route>
      </Route>
    </RoutesWith404>
  ) : null;
}
