import { useLogEvents } from '@monorepo/tasks/src/hooks/useLogEvents';
import { endOfDay, startOfDay } from 'date-fns';
import {
  CalendarEventStatus,
  DateLike,
  EdpLogCalendarEvent,
  FrequencyRequirementResponse,
  IntervalFrequencyEnum,
  localEquivalentOfUTC,
  NonEmptyArray,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import { useMemo } from 'react';

interface GroupedByFrequencyRequirementLogEvent {
  frequencyRequirement: FrequencyRequirementResponse;
  status: CalendarEventStatus;
  completedEventCount: number;
  requiredEventCount: number;
}

type FrequencyRequirementId = string;

type PeriodicEvent = Omit<EdpLogCalendarEvent, 'frequencyRequirement'> & {
  frequencyRequirement: FrequencyRequirementResponse;
};

interface UseGroupedByFrequencyRequirementLogEventsProps {
  dueDate?: DateLike | null;
  logId: string;
  projectId?: string;
}

interface UseGroupedByFrequencyRequirementLogEventsResponse {
  isLoading: boolean;
  groupedEvents: GroupedByFrequencyRequirementLogEvent[];
  asNeededEvents: EdpLogCalendarEvent[];
}

function isPeriodicEvent(event: EdpLogCalendarEvent): event is PeriodicEvent {
  return (
    !!event.frequencyRequirement &&
    event.frequencyRequirement.frequency.intervalFrequency !==
      IntervalFrequencyEnum.AS_NEEDED
  );
}

function getDayStart(date?: DateLike | null) {
  if (!date) return undefined;
  return UTCEquivalentOfLocal(
    startOfDay(localEquivalentOfUTC(new Date(date))),
  ).toISOString();
}

function getDayEnd(date?: DateLike | null) {
  if (!date) return undefined;
  return UTCEquivalentOfLocal(
    endOfDay(localEquivalentOfUTC(new Date(date))),
  ).toISOString();
}

function getStatusForGroup(group: PeriodicEvent[]): CalendarEventStatus {
  const someOverdue = group.some(
    (g) => g.status === CalendarEventStatus.OVERDUE,
  );
  if (someOverdue) {
    return CalendarEventStatus.OVERDUE;
  }
  const allRequiredCompleted = group.every(
    (g) =>
      g.status === CalendarEventStatus.COMPLETE ||
      g.status === CalendarEventStatus.NOT_NEEDED,
  );
  if (allRequiredCompleted) {
    return CalendarEventStatus.COMPLETE;
  }
  return CalendarEventStatus.INCOMPLETE;
}

export function useGroupedByFrequencyRequirementLogEvents({
  dueDate,
  logId,
  projectId,
}: UseGroupedByFrequencyRequirementLogEventsProps): UseGroupedByFrequencyRequirementLogEventsResponse {
  const { isLoading, logEvents } = useLogEvents({
    logId,
    projectId,
    startDate: getDayStart(dueDate),
    endDate: getDayEnd(dueDate),
  });

  const groupedEvents = useMemo(() => {
    if (!logEvents) return [] as GroupedByFrequencyRequirementLogEvent[];
    const periodicEvents: PeriodicEvent[] = logEvents.filter(isPeriodicEvent);
    const groupedByFrequencyRequirementId = periodicEvents.reduce<
      Record<FrequencyRequirementId, NonEmptyArray<PeriodicEvent>>
    >((grouped, event) => {
      const freqReqId = event.frequencyRequirement.id;
      return {
        ...grouped,
        [freqReqId]: [...(grouped[freqReqId] || []), event],
      };
    }, {});
    return Object.values(groupedByFrequencyRequirementId).map((group) => ({
      frequencyRequirement: group[0].frequencyRequirement,
      status: getStatusForGroup(group),
      completedEventCount: group.filter(
        (e) => e.status === CalendarEventStatus.COMPLETE,
      ).length,
      requiredEventCount:
        group[0].frequencyRequirement.frequency.customFrequency
          ?.numberOfEvents || 1,
    }));
  }, [logEvents]);

  const asNeededEvents = useMemo(() => {
    if (!logEvents) return [] as EdpLogCalendarEvent[];
    return logEvents.filter(
      (e) =>
        !e.frequencyRequirement ||
        e.frequencyRequirement.frequency.intervalFrequency ===
          IntervalFrequencyEnum.AS_NEEDED,
    );
  }, [logEvents]);

  return {
    isLoading,
    groupedEvents,
    asNeededEvents,
  };
}
