import { FrequencyContext } from '@monorepo/shared/contexts/FrequencyContext';
import {
  CUSTOM_FREQUENCY,
  FrequencyOption,
} from '@monorepo/shared/contexts/FrequencyContext/types';
import {
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Select } from '../Select';

const StyledSelect = styled(Select)`
  max-width: 16rem;
`;

type IntervalFrequencySelectProps = {
  error?: boolean;
  errorText?: string;
  nonCustomFrequencyOptions: IntervalFrequencyEnum[];
};

export function IntervalFrequencySelect({
  error,
  errorText,
  nonCustomFrequencyOptions,
}: IntervalFrequencySelectProps) {
  const { onFrequencySelect, customFrequency, selectedFrequency } =
    useContext(FrequencyContext);

  const frequencyOptions = useMemo(
    () =>
      [...nonCustomFrequencyOptions, CUSTOM_FREQUENCY].map((freq) => ({
        label:
          freq === CUSTOM_FREQUENCY
            ? 'Custom'
            : IntervalFrequencyFactory.For(freq).toString(),
        value: freq,
      })),
    [nonCustomFrequencyOptions],
  );

  return (
    <StyledSelect
      error={error}
      errorText={errorText}
      label="Frequency"
      name="frequency"
      onChange={({ target: { value } }) => {
        onFrequencySelect(value as FrequencyOption, undefined);
      }}
      options={frequencyOptions}
      required
      value={customFrequency ? CUSTOM_FREQUENCY : selectedFrequency || ''}
    />
  );
}
