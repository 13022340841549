import { EventInput } from '@fullcalendar/core';
import { Api } from '@monorepo/shared/apiClient';
import { format } from 'date-fns';
import { localEquivalentOfUTC } from 'mapistry-shared';

type ComplianceCalendarBackendEvent = Awaited<
  ReturnType<typeof Api.fetchComplianceCalendarEvents>
>[number] & { isLoadingIndicator?: never };

type LoadingIndicatorEvent = {
  isLoadingIndicator: true;
  dueDate: ComplianceCalendarBackendEvent['dueDate'];
};

export function convertEventToFullCalendarFormat(
  event: ComplianceCalendarBackendEvent | LoadingIndicatorEvent,
): EventInput {
  if (event.isLoadingIndicator) {
    return {
      start: event.dueDate,
      interactive: false,
      isLoadingIndicator: true,
      className: 'loading-indicator',
    };
  }

  return {
    id: event.id,
    start: event.dueDate,
    status: event.status,
    title: event.title,
    url: event.url,
  };
}

const getDaysBetween = (start: Date, end: Date) => {
  const days = [];
  for (
    let day = new Date(start);
    day < end;
    day.setUTCDate(day.getUTCDate() + 1)
  ) {
    days.push(new Date(day));
  }
  return days;
};

export const exclusiveToInclusiveRange = (start?: Date, end?: Date) => {
  if (!start || !end) {
    return { startDateString: '', endDateString: '' };
  }

  const startDateString = format(localEquivalentOfUTC(start), 'yyyy-MM-dd');
  const inclusiveEnd = new Date(end);
  inclusiveEnd.setUTCDate(inclusiveEnd.getUTCDate() - 1);

  const endDateString = format(
    localEquivalentOfUTC(inclusiveEnd),
    'yyyy-MM-dd',
  );
  return { startDateString, endDateString };
};

const getLoadingIndicatorEvent = (date: Date): LoadingIndicatorEvent => ({
  dueDate: date.toISOString(),
  isLoadingIndicator: true,
});

export const createLoadingEvents = (start?: Date, end?: Date) => {
  if (!start || !end) {
    return [];
  }
  const loadingEvents: LoadingIndicatorEvent[] = [];
  const daysInBetween = getDaysBetween(start, end);

  daysInBetween.forEach((day) => {
    const loadingEvent = getLoadingIndicatorEvent(day);
    // show three bars in every day
    loadingEvents.push(loadingEvent, loadingEvent, loadingEvent);
  });

  return loadingEvents;
};
