import {
  ChartCardSettingsContent,
  ChartCardSettingsActions,
  FieldWrapper,
  Form,
  ToggleSwitch,
} from '@monorepo/old-web/js/components/elements';
import GenericLogsWidgetNameField from '@monorepo/old-web/js/components/genericLogs/GenericLogsWidgetNameField';
import { Button } from '@monorepo/shared/components';
import { GenericLogType, LimitItemResourceType } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Resource } from '../../../hooks/useResources';

type ResourceKey = { resourceId: string; resourceType: LimitItemResourceType };

type FormValues = {
  resources: ResourceKey[];
  widgetName: string;
};

type ResourceOption = {
  label: string;
  value: ResourceKey;
};

type LimitMonitoringSettingsFormProps = {
  resources: Resource[];
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
};

export const LimitMonitoringSettingsForm = (
  props: LimitMonitoringSettingsFormProps,
) => {
  const { initialValues, onSubmit, resources } = props;
  const initialValuesWithDefaults = useMemo<FormValues>(() => {
    if (initialValues) return initialValues;
    return { resources: [], widgetName: '' };
  }, [initialValues]);
  const resourceOptions = useMemo<ResourceOption[]>(
    () =>
      resources.reduce<ResourceOption[]>((acc, resource) => {
        if (resource.logSettingType !== GenericLogType.EMISSION_FACTOR) {
          return [
            ...acc,
            {
              label: resource.name,
              value: {
                resourceType: resource.logSettingType,
                resourceId: resource.id,
              },
            },
          ];
        }
        return acc;
      }, []),
    [resources],
  );

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValuesWithDefaults}
      mutators={{ ...arrayMutators }}
    >
      {(form) => (
        <Form
          className="generic-logs-chart-settings-form"
          onSubmit={form.handleSubmit}
        >
          <ChartCardSettingsContent>
            <GenericLogsWidgetNameField label="Widget Name" name="widgetName" />
            <FieldArray name="resources">
              {({ fields }) => (
                <>
                  {resourceOptions.map((opt) => (
                    <div key={opt.value.resourceId}>
                      <FieldWrapper label={opt.label}>
                        <ToggleSwitch
                          isChecked={Boolean(
                            fields.value.find(
                              (r) => r.resourceId === opt.value.resourceId,
                            ),
                          )}
                          onChange={() => {
                            const index = fields.value.findIndex(
                              (r) => r.resourceId === opt.value.resourceId,
                            );
                            if (index !== -1) {
                              fields.remove(index);
                            } else {
                              fields.push(opt.value);
                            }
                          }}
                        />
                      </FieldWrapper>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </ChartCardSettingsContent>
          <ChartCardSettingsActions>
            <Button
              type="submit"
              disabled={form.pristine || !form.values.resources.length}
            >
              Apply
            </Button>
          </ChartCardSettingsActions>
        </Form>
      )}
    </FinalForm>
  );
};
