import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { Button } from '@monorepo/shared/componentsV2/Button';
import AddIcon from '@svg/add.svg';
import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { DeepPartial } from 'utility-types';
import { SectionHeader, Subtext } from '../styled';
import {
  AggregationGroupBy,
  getAllowedGroupByColumns,
} from './AggregationGroupBy';
import { FormValues } from './types';

export function AggregationGroupBySection() {
  const { availableColumns, columnSets, isLastStep } = useSingleQueryStep();
  const { values: currentFormValues } = useFormState<DeepPartial<FormValues>>();

  // are there any remaining options for columns that can be grouped by, or do we have at least one column we're already grouping by?
  const stepHasColumnsForGrouping = useMemo(
    () =>
      getAllowedGroupByColumns(availableColumns, columnSets, currentFormValues)
        .length > 0 || currentFormValues?.groupBy?.length,
    [availableColumns, columnSets, currentFormValues],
  );
  return (
    <>
      <SectionHeader>Group By</SectionHeader>
      <Subtext>
        Split out your aggregated data by one or more fields of your choosing.
      </Subtext>
      {stepHasColumnsForGrouping ? (
        <FieldArray name="groupBy">
          {({ fields: formFields }) => (
            <>
              {formFields.map((formFieldName, formFieldIndex) => (
                <AggregationGroupBy
                  formFieldName={formFieldName}
                  formFieldIndex={formFieldIndex}
                  key={formFieldName}
                  onDelete={
                    isLastStep
                      ? () => formFields.remove(formFieldIndex)
                      : undefined
                  }
                />
              ))}
              {isLastStep && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => formFields.push(undefined)}
                >
                  <AddIcon />
                  Add group by
                </Button>
              )}
            </>
          )}
        </FieldArray>
      ) : (
        <span>There are no columns available to group by.</span>
      )}
    </>
  );
}
