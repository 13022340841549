import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useLogFormulaFields } from '@monorepo/shared/hooks/logs/useLogFields';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import {
  Content,
  Page,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import AddIcon from '@svg/add.svg';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useLogFieldCommonState } from '../../hooks/useLogFieldCommonState';
import { EditLogFormulaFieldModal } from '../EditLogFormulaFieldModal';
import { EditLogHeader, WizardStep } from './EditLogHeader';
import { Description, FieldList } from './styled';

export function EditLogFormulas() {
  useDisableSubnav();

  const { logId, organizationId } = useParams();
  const { logFields, isLoading } = useLogFormulaFields({
    logId,
    organizationId,
  });
  const { closeModal, field, getMenu, open, openModal } =
    useLogFieldCommonState({
      fieldType: 'formula',
      logId,
      organizationId,
    });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <EditLogFormulaFieldModal
        field={field}
        open={open}
        onClose={closeModal}
      />
      <EditLogHeader wizardStep={WizardStep.FORMULAS} />
      <Content>
        {logFields?.length ? (
          <>
            <Title id="formula-fields">Formula Fields</Title>
            <Description>
              Formula fields allow you to perform basic operations between two
              or more Log fields (columns).
            </Description>
            <FieldList aria-labelledby="formula-fields">
              {logFields?.map((formulaField) => (
                <FieldTypePreviewFactory
                  actionMenuOptions={getMenu(formulaField)}
                  field={formulaField}
                  key={formulaField.name}
                />
              ))}
            </FieldList>
            <Button color="primary" onClick={() => openModal()}>
              <AddCircleOutlineIcon />
              Add a formula
            </Button>
          </>
        ) : (
          <EmptyState
            actionButtonConfigs={[
              {
                icon: <AddIcon />,
                label: 'Add a formula',
                onClick: () => openModal(),
              },
            ]}
            primaryText="You do not have any formula fields yet!"
            secondaryText="Formula fields allow you to perform basic operations between two or more Log fields (columns)."
          />
        )}
      </Content>
    </Page>
  );
}
