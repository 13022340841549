/* eslint-disable react/jsx-no-bind */
import AddIcon from '@svg/add.svg';
import CogIcon from '@svg/cog.svg';
import DocumentsIcon from '@svg/documents.svg';
import FilterIcon from '@svg/filter.svg';
import UploadIcon from '@svg/upload.svg';
import _get from 'lodash.get';
import { CalendarEventStatus } from 'mapistry-shared';
import React, { useState } from 'react';
import { localEquivalentOfUTC } from '../../../utils';
import { Button, Card, IconButton } from '../../elements';

import CalendarCard from '../../views/projectDashboard/CalendarCard';
import NeverEventCalendar from '../../views/projectDashboard/CalendarCard/NeverEventCalendar';
import EditLogModal from '../EditLogModal';
import { LoggedItemValuesUploadModal } from '../EditLogModal/LoggedItemValuesUploadModal';

import FilterSettings from './FilterSettings';
import LogChoiceMenu from './LogChoiceMenu';

interface LoggedItem {
  frequency: string;
  customFrequency: string;
  id: string;
  name: string;
}

interface MenuItem {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface GenericLogTrackingCalendarProps {
  additionalMenuOptions?: MenuItem[];
  calendarName: string;
  currentItems: LoggedItem[];
  fetchEvents: () => void;
  isLoading: boolean;
  loggedItems: LoggedItem[];
  logProjectId: string;
  navigateToList: () => void;
  navigateToSettings: () => void;
  onChangeFilter: (filter: LoggedItem[]) => void;
  onDateChange: () => void;
  onSaveLog: (log: LoggedItem) => void;
  projectId: string;
  title: string;
  widgetId: string;
}

export function GenericLogTrackingCalendar({
  additionalMenuOptions = [],
  calendarName,
  currentItems,
  fetchEvents,
  isLoading,
  loggedItems,
  logProjectId,
  navigateToList,
  navigateToSettings,
  onChangeFilter,
  onDateChange,
  onSaveLog,
  projectId,
  title,
  widgetId,
}: GenericLogTrackingCalendarProps) {
  const [logToEdit, setLogToEdit] = useState<LoggedItem>();
  const [logsToChooseFrom, setLogsToChooseFrom] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showEditLogModal, setShowEditLogModal] = useState(false);
  const [showFilterSettings, setShowFilterSettings] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);

  function handleEditLogModalClose() {
    setLogToEdit(undefined);
    setShowEditLogModal(false);
  }

  function handleFilterSettingsSave(newFilter: LoggedItem[]) {
    setShowFilterSettings(false);
    onChangeFilter(newFilter);
  }

  function handleLogSave(log: LoggedItem) {
    setLogToEdit(undefined);
    setShowEditLogModal(false);
    onSaveLog(log);
  }

  function handleLogClick(log: LoggedItem) {
    setLogsToChooseFrom([]);
    setLogToEdit(log);
    setMenuAnchor(null);
    setShowEditLogModal(true);
  }

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  function handleEventClick(calendarEvents, clickEvent) {
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    const logs = calendarEvents.reduce((acc, event) => {
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      event.attachments.forEach((log) => {
        const { isDefault } = log;
        const datetime = localEquivalentOfUTC(log.datetime);

        if (isDefault) {
          const now = new Date();
          datetime?.setHours(now.getHours(), now.getMinutes(), 0, 0);
        }

        acc.push({ datetime, isDefault });
      });
      return acc;
    }, []);
    if (logs.length === 1) {
      setLogToEdit(logs[0]);
      setShowEditLogModal(true);
    } else {
      setLogsToChooseFrom(logs);
      setMenuAnchor(clickEvent.currentTarget);
    }
  }

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  function helpTextForEvent(event, tooMuchData: boolean) {
    if (tooMuchData) {
      return 'More Details';
    }
    switch (event.status) {
      case CalendarEventStatus.COMPLETE:
        return 'All items logged';
      case CalendarEventStatus.OVERDUE:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        return event.attachments.every((a) => !a.isComplete)
          ? 'All items not logged'
          : 'Some items not logged';
      case CalendarEventStatus.UPCOMING:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        return event.attachments.every((a) => !a.isComplete)
          ? 'All items still required'
          : 'Some items still required';
      default:
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        return event.attachments.every((a) => !a.isComplete)
          ? 'Future logs'
          : 'Some items still required';
    }
  }

  function menuOptions() {
    const options = [
      {
        label: 'Add a log',
        icon: <AddIcon className="m-icon" />,
        onClick: () => setShowEditLogModal(true),
      },
      {
        label: 'Log settings',
        icon: <CogIcon className="m-icon" />,
        onClick: navigateToSettings,
      },
      {
        label: 'View log values',
        icon: <DocumentsIcon className="m-icon" />,
        onClick: navigateToList,
      },
      {
        label: 'Upload logs',
        icon: <UploadIcon className="m-icon" />,
        onClick: () => setShowBulkUploadModal(true),
      },
    ];

    return [...options, ...additionalMenuOptions];
  }

  function tooltipText(status: string, count: number) {
    if (status === CalendarEventStatus.COMPLETE) {
      return 'Filtered items have been logged';
    }
    if (status === CalendarEventStatus.NOT_NEEDED) {
      return 'Not Required';
    }
    if (status === CalendarEventStatus.AS_NEEDED) {
      return 'Add a log';
    }
    return count === 1
      ? '1 filtered item still needs to be logged'
      : `${count} filtered items still need to be logged`;
  }

  // This doesn't work for twice-weekly
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  function tooltipTitle(events, tooMuchData) {
    if (tooMuchData) {
      return 'More Details';
    }
    let loggedItemIds = [] as string[];
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    const statusCount = events.reduce((acc, event) => {
      if (event.status === CalendarEventStatus.COMPLETE) {
        acc[CalendarEventStatus.COMPLETE] = 1;
      } else if (event.status === CalendarEventStatus.NOT_NEEDED) {
        acc[CalendarEventStatus.NOT_NEEDED] = 1;
      } else if (event.status === CalendarEventStatus.AS_NEEDED) {
        acc[CalendarEventStatus.AS_NEEDED] = 1;
      } else {
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        event.attachments.forEach((attachment) => {
          loggedItemIds = [...loggedItemIds, ...attachment.loggedItemIds];
        });
        acc[CalendarEventStatus.INCOMPLETE] = 1;
      }
      return acc;
    }, {});

    if (statusCount[CalendarEventStatus.INCOMPLETE]) {
      const itemsNotLogged = currentItems.filter(
        (i) => !loggedItemIds.includes(i.id),
      );
      statusCount[CalendarEventStatus.INCOMPLETE] = itemsNotLogged.length;
    }

    if (
      statusCount[CalendarEventStatus.AS_NEEDED] &&
      Object.keys(statusCount).length > 1
    ) {
      delete statusCount[CalendarEventStatus.AS_NEEDED];
    }

    return Object.keys(statusCount).map((status) => (
      <div key={status}>{tooltipText(status, statusCount[status])}</div>
    ));
  }

  function filterView() {
    return (
      <div className="card__filter-icon">
        <IconButton onClick={() => setShowFilterSettings(true)}>
          <FilterIcon className="m-icon" />
        </IconButton>
      </div>
    );
  }

  function noFilterItemsView() {
    return (
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      <Card actions={filterView()} menuOptions={menuOptions()} title={title}>
        <div className="emission-tracking-calendar__empty">
          <span className="no-filter__text">
            To view the log tracking calendar, configure settings by clicking
            the button below.
          </span>
          <Button color="secondary" onClick={() => setShowFilterSettings(true)}>
            Filter Settings
          </Button>
        </div>
      </Card>
    );
  }

  function noLoggedItemsView() {
    return (
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      <Card title={title}>
        <div className="emission-tracking-calendar__empty">
          <span className="no-filter__text">
            You currently have no logged items.
          </span>
          <Button color="secondary" onClick={navigateToSettings}>
            Add Logged Items
          </Button>
        </div>
      </Card>
    );
  }

  function calendar() {
    if (!loggedItems.length && !isLoading) {
      // isLoading is not log project specific, so this view might flash
      // if calendars for multiple log projects are loading on the page
      // even if there are logged items for this one
      return noLoggedItemsView();
    }
    if (!currentItems.length && !isLoading) {
      return noFilterItemsView();
    }
    return (
      <CalendarCard
        calendarName={calendarName}
        fetchEvents={fetchEvents}
        filterView={filterView()}
        helpTextForEvent={helpTextForEvent}
        tooltipTitle={tooltipTitle}
        menuOptions={menuOptions()}
        defaultView={<NeverEventCalendar calendarTitle={title} />}
        onDateChange={onDateChange}
        onEventClick={handleEventClick}
        selectedFilterOptions={currentItems.map((item) => item.name)}
        title={title}
        widgetId={widgetId}
      />
    );
  }

  return showFilterSettings ? (
    <FilterSettings
      currentItems={currentItems}
      loggedItems={loggedItems}
      onSave={handleFilterSettingsSave}
      onCancel={() => setShowFilterSettings(false)}
    />
  ) : (
    <>
      {calendar()}
      <LogChoiceMenu
        logs={logsToChooseFrom}
        menuAnchor={menuAnchor}
        onClose={() => {
          setMenuAnchor(null);
          setLogsToChooseFrom([]);
        }}
        onEventClick={handleLogClick}
      />
      {showEditLogModal && (
        <EditLogModal
          logDatetime={_get(logToEdit, 'datetime')}
          logProjectId={logProjectId}
          projectId={projectId}
          navigateToSettings={navigateToSettings}
          onClose={handleEditLogModalClose}
          onSave={handleLogSave}
          title={title}
        />
      )}
      {showBulkUploadModal && (
        <LoggedItemValuesUploadModal
          isOpen={showBulkUploadModal}
          logProjectId={logProjectId}
          onClose={() => setShowBulkUploadModal(false)}
          projectId={projectId}
        />
      )}
    </>
  );
}
