import AggregateIcon from '@svg/aggregate.svg';
import FilterIcon from '@svg/filter.svg';
import LimitIcon from '@svg/limit.svg';
import RollingCalcIcon from '@svg/rolling-calc.svg';
import TrashIcon from '@svg/trash.svg';
import { QueryOperationType } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useQuerySteps } from '../../contexts/QueryStepsContext';
import { useSingleQueryStep } from '../../contexts/SingleQueryStepContext';
import { PotentiallyIncompleteQueryStep } from '../../types';
import { AggregationQueryStep } from './AggregationQueryStep';
import { FilterQueryStep } from './FilterQueryStep';
import { LimitQueryStep } from './LimitQueryStep';
import { QueryStepAccordion } from './QueryStepAccordion';
import { RollingCalculationQueryStep } from './RollingCalculationQueryStep';

export function getIconForOperationType(
  operationType: QueryOperationType,
): React.ReactNode {
  switch (operationType) {
    case QueryOperationType.AGGREGATION:
      return <AggregateIcon />;
    case QueryOperationType.FILTER:
      return <FilterIcon />;
    case QueryOperationType.LIMIT:
      return <LimitIcon />;
    case QueryOperationType.ROLLING_CALCULATION:
      return <RollingCalcIcon />;
    case QueryOperationType.SORT:
      throw new Error(`query operation type not implemented ${operationType}`);
    default: {
      const exhaustiveCheck: never = operationType;
      throw new Error(
        `exhaustive check failed: query operation type not allowed ${exhaustiveCheck}`,
      );
    }
  }
}

export function getDisplayNameForOperationType(
  operationType: QueryOperationType,
): string {
  switch (operationType) {
    case QueryOperationType.AGGREGATION:
      return 'Aggregation';
    case QueryOperationType.ROLLING_CALCULATION:
      return 'Rolling Calculation';
    case QueryOperationType.FILTER:
      return 'Filter';
    case QueryOperationType.LIMIT:
      return 'Limit';
    case QueryOperationType.SORT:
      return 'Sort';
    default: {
      const exhaustiveCheck: never = operationType;
      throw new Error(
        `exhaustive check failed: query operation type not allowed ${exhaustiveCheck}`,
      );
    }
  }
}

function getInfoChipsForQueryStep(
  queryStep: PotentiallyIncompleteQueryStep,
): string[] | undefined {
  switch (queryStep.operationType) {
    case QueryOperationType.AGGREGATION:
      return queryStep.operation && 'columns' in queryStep.operation
        ? queryStep.operation.columns
            .filter((info) => !!info)
            .map((info) => info.alias)
        : undefined;
    case QueryOperationType.LIMIT:
      return queryStep.operation && 'name' in queryStep.operation
        ? [queryStep.operation.name]
        : undefined;
    case QueryOperationType.ROLLING_CALCULATION:
      return queryStep.operation && 'alias' in queryStep.operation
        ? [queryStep.operation.alias]
        : undefined;
    default:
      return undefined;
  }
}

export function QueryStepFactory() {
  const { handleDeleteLastQueryStep } = useQuerySteps();
  const { queryStep, isLastStep } = useSingleQueryStep();

  const lastStepActions = useMemo(
    () => [
      {
        altText: `Delete ${getDisplayNameForOperationType(
          queryStep.operationType,
        )} Step`,
        icon: <TrashIcon />,
        onClick: handleDeleteLastQueryStep,
      },
    ],
    [handleDeleteLastQueryStep, queryStep.operationType],
  );

  const getFormForOperationType = (operationType: QueryOperationType) => {
    switch (operationType) {
      case QueryOperationType.FILTER:
        return <FilterQueryStep />;
      case QueryOperationType.LIMIT:
        return <LimitQueryStep />;
      case QueryOperationType.AGGREGATION:
        return <AggregationQueryStep />;
      case QueryOperationType.ROLLING_CALCULATION:
        return <RollingCalculationQueryStep />;
      case QueryOperationType.SORT:
        return <div>{operationType} form content</div>;
      default: {
        const exhaustiveCheck: never = operationType;
        throw new Error(
          `exhaustive check failed: query operation type not allowed ${exhaustiveCheck}`,
        );
      }
    }
  };

  return (
    <QueryStepAccordion
      actions={isLastStep ? lastStepActions : undefined}
      defaultExpanded={isLastStep}
      detailsContent={getFormForOperationType(queryStep.operationType)}
      icon={getIconForOperationType(queryStep.operationType)}
      infoChips={getInfoChipsForQueryStep(queryStep)}
      stepLabel={getDisplayNameForOperationType(queryStep.operationType)}
    />
  );
}
