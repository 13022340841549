import type { UpdateFormFieldsOrder } from '@monorepo/shared/apiClient/forms';
import { updateFormTemplateField } from '@monorepo/shared/apiClient/forms';
import { useReorderableItems } from '@monorepo/shared/componentsV2/dragAndDrop/useReorderableItems';
import { useToast } from '@monorepo/shared/contexts';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { bodySmall } from '@monorepo/shared/styles/text';
import {
  FormTemplateFieldResponse,
  FormTemplateFieldTypes,
  NullableString,
} from 'mapistry-shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditFieldModal } from './EditFieldModal/EditFieldModal';
import { ReorderableFormTemplateFieldsList } from './ReorderableFormTemplateFieldsList';

type EditFormTemplateSectionFieldsProps = {
  fields?: FormTemplateFieldResponse[];
  formTemplateId: string;
  groupId: string;
  updateFieldOrderGroups: (
    fieldOrderGroup: UpdateFormFieldsOrder,
    orderedFields: FormTemplateFieldResponse[],
  ) => void;
};

const Instructions = styled('span')`
  ${bodySmall}
`;

export function EditFormTemplateSectionFields({
  fields,
  formTemplateId,
  groupId,
  updateFieldOrderGroups,
}: EditFormTemplateSectionFieldsProps) {
  const [currentlyOpenField, setCurrentlyOpenField] = useState<
    FormTemplateFieldResponse | undefined
  >();

  const [fieldListForGroup, setFieldListForGroup] = useState<
    [string, FormTemplateFieldResponse[]]
  >(['', []]);

  useEffect(() => {
    if (fields) setFieldListForGroup([groupId, fields]);
  }, [groupId, fields]);

  const fieldGroupId = fieldListForGroup[0];
  const fieldList = fieldListForGroup[1];

  const hasForbiddenHtml = (text: NullableString | undefined): boolean => {
    if (!text) return false;
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const allEl = Array.from(doc.getElementsByTagName('*')).map(
      (e) => e.nodeName,
    );
    // all of the allowed HTML tags, which includes HTML, HEAD, and BODY because of the DOMParser
    const allowedHTMLTags = [
      'HTML',
      'HEAD',
      'BODY',
      'B',
      'STRONG',
      'H1',
      'H2',
      'A',
      'I',
      'P',
    ];
    const allTagsAreAllowed = allEl.every((elem) =>
      allowedHTMLTags.includes(elem),
    );

    return !allTagsAreAllowed;
  };

  /* 
   fieldsDependedOnByConditional returns an array of field ids for fields which are depended on 
   by other conditional fields. This is then passed down the chain so we can disable option editing
   on these fields. 
  */
  const fieldsDependedOnByConditional = fieldList.reduce((acc, field) => {
    if (field?.conditionalFieldId && !acc.includes(field.conditionalFieldId)) {
      acc.push(field.conditionalFieldId);
    }
    return acc;
  }, [] as string[]);

  const {
    orderedItems: orderedFields,
    updateItemOrder: updateFieldOrder,
    orderedItemIds: fieldOrder,
  } = useReorderableItems(fieldList);

  useEffect(() => {
    const fieldsAreNotUpdated = fields?.every(
      (element: unknown, index: number) => element === orderedFields[index],
    );

    if (fieldsAreNotUpdated || fieldGroupId !== groupId) return;

    updateFieldOrderGroups(
      { fieldGroupId: groupId, fieldOrder },
      orderedFields,
    );
  }, [
    fields,
    fieldOrder,
    groupId,
    orderedFields,
    updateFieldOrderGroups,
    fieldListForGroup,
    fieldGroupId,
  ]);

  const { organizationId } = useParams();
  const { showUserFriendlyErrorToast, success: showSuccessToast } = useToast();
  const { confirm } = useModal();

  const closeFieldModal = useCallback(() => {
    setCurrentlyOpenField(undefined);
  }, [setCurrentlyOpenField]);

  const fieldSubmit = useCallback(
    async (updatedField: FormTemplateFieldResponse) => {
      try {
        const originalField = fieldList.find(
          (field) => field.id === updatedField.id,
        );

        if (
          originalField?.type === FormTemplateFieldTypes.EXPLANATION_AREA &&
          hasForbiddenHtml(originalField?.helpText)
        ) {
          const confirmSave = await confirm({
            title: 'Saving will overwrite unsupported styles',
            description: (
              <>
                This explanation area was initially built by the Mapistry
                customer experience team and contains style elements unsupported
                on the mobile app, such as font color, font size, tables, etc.
                If you save your changes, these style elements will be erased.
                Are you sure you want to save your changes?
              </>
            ),
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Save',
            danger: true,
          });

          if (!confirmSave) return;
        }

        if (organizationId) {
          await updateFormTemplateField({
            organizationId,
            id: formTemplateId,
            name: updatedField.name,
            groupId,
            fieldId: updatedField.id,
            isRequired: updatedField.isRequired,
            helpText: updatedField.helpText,
            nameSubtext: updatedField.nameSubtext,
            defaultOption: updatedField.defaultOption,
            options: updatedField.options,
            isOptionsAlphabetized: updatedField.isOptionsAlphabetized,
          });
        }

        const updatedFields = fieldList.map((field) => {
          if (field.id === updatedField.id) {
            return updatedField;
          }
          return field;
        });

        setFieldListForGroup([groupId, updatedFields]);

        closeFieldModal();
        showSuccessToast('Field saved.');
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save field.`, {
          dontAutoHide: true,
        });
      }
    },
    [
      closeFieldModal,
      confirm,
      fieldList,
      formTemplateId,
      groupId,
      organizationId,
      showSuccessToast,
      showUserFriendlyErrorToast,
    ],
  );

  const hasFieldsDependedOnByConditional =
    !!currentlyOpenField &&
    fieldsDependedOnByConditional.includes(currentlyOpenField.id);

  return (
    <div>
      <Instructions>Reorder or edit the fields in this section.</Instructions>
      {fields && (
        <ReorderableFormTemplateFieldsList
          openEditFieldModal={setCurrentlyOpenField}
          sortedFields={orderedFields}
          updateFieldOrder={updateFieldOrder}
        />
      )}
      {!!currentlyOpenField && (
        <EditFieldModal
          isOpen={!!currentlyOpenField}
          onClose={closeFieldModal}
          onSubmit={fieldSubmit}
          field={currentlyOpenField}
          hasFieldsDependedOnByConditional={hasFieldsDependedOnByConditional}
        />
      )}
    </div>
  );
}
