import { SitePickerForOrgField } from '@monorepo/shared/componentsV2/fields/SitePickerForOrgField';
import {
  StyledFormGroup,
  StyledInput,
} from '@monorepo/shared/componentsV2/styled/form';
import { isRequired } from '@monorepo/shared/utils/validators';
import { ResourceResponse, ResourceTypeRefResponse } from 'mapistry-shared';
import React from 'react';

type EditResourceBuiltInFieldsProps = {
  organizationId: string;
  resourceType: ResourceTypeRefResponse;
  existingResource?: ResourceResponse;
};

export function EditResourceBuiltInFields({
  organizationId,
  resourceType,
  existingResource,
}: EditResourceBuiltInFieldsProps) {
  return (
    <StyledFormGroup row>
      <StyledInput<ResourceResponse['name']>
        label="Resource Name"
        name="name"
        placeholder="Name"
        validate={isRequired}
        required
        initialValue={existingResource?.name}
      />
      {resourceType?.isSiteSpecific && (
        <SitePickerForOrgField
          organizationId={organizationId}
          label="Site Association"
          name="projectId"
          validate={isRequired}
          required
          initialValue={existingResource?.project?.id}
        />
      )}
    </StyledFormGroup>
  );
}
