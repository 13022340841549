import MuiAppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import React from 'react';
import styled, { css } from 'styled-components';

const dropShadow = css`
  box-shadow: 0 4px 13px ${({ theme }) => theme.colors.blue24};
`;

const noDropShadow = css`
  border-bottom: 0;
  box-shadow: none;
`;

const bottomBorder = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayddd};
  box-shadow: none;
`;

export type AppBarProps = Omit<
  React.ComponentProps<typeof MuiAppBar>,
  'variant'
> & {
  $size?: 'auto' | 'default' | 'small';
  $variant?: 'noDropShadow' | 'default' | 'bottomBorder';
};

export const AppBar = styled(MuiAppBar)<AppBarProps>`
  z-index: ${({ theme }) => theme.constants.toolbarZIndex};
  height: ${({ $size, theme }) => {
    switch ($size) {
      case 'auto':
        return 'auto';
      case 'small':
        return '2.875rem';
      case 'default':
      case undefined:
        return theme.sizes.appBarHeight;
      default: {
        const exhaustiveCheck: never = $size;
        // eslint-disable-next-line no-console
        console.log(`Invalid option for $size - ${exhaustiveCheck}`);
        return theme.sizes.appBarHeight;
      }
    }
  }};
  background-color: inherit;
  ${({ $variant = 'default' }) => {
    if ($variant === 'noDropShadow') {
      return noDropShadow;
    }
    if ($variant === 'bottomBorder') {
      return bottomBorder;
    }
    return dropShadow;
  }}
`;

export const Bar = styled(MuiToolbar)<{ $size?: AppBarProps['$size'] }>`
  min-height: ${({ $size, theme }) => {
    switch ($size) {
      case 'auto':
        return 'auto';
      case 'small':
        return '2.875rem';
      case 'default':
      case undefined:
        return theme.sizes.appBarHeight;
      default: {
        const exhaustiveCheck: never = $size;
        // eslint-disable-next-line no-console
        console.log(`Invalid option for $size - ${exhaustiveCheck}`);
        return theme.sizes.appBarHeight;
      }
    }
  }};
  color: ${({ theme }) => theme.colors.gray333};
`;

export const LeftActions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0.5rem;
`;

export const CenterActions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 0.5rem;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const RightActions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
