import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { label } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const StyledToolbar = styled(Toolbar)`
  background-color: ${({ theme }) => theme.colors.lightBlue08};
  border-top: solid 1px ${({ theme }) => theme.colors.grayddd};
  border-bottom: solid 1px ${({ theme }) => theme.colors.grayddd};
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  ${label}
  margin-right: 1rem;
  text-transform: uppercase;
`;
