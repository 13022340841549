import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Switch } from '../Switch';

type SwitchFieldProps<ValueType> = Omit<
  React.ComponentProps<typeof Switch>,
  'value' | 'onChange' | 'error' | 'errorText'
> & {
  initialValue?: ValueType;
  name: string;
  validate?: UseFieldConfig<ValueType>['validate'];
};

// the type of the value of this field will always be a boolean since we're using
//  `type: 'checkbox'` and not passing `value`
// https://final-form.org/docs/react-final-form/types/FieldProps#type
// https://final-form.org/docs/react-final-form/types/FieldProps#without-value
export function SwitchField<ValueType extends boolean = boolean>({
  name,
  validate,
  initialValue,
  ...rest
}: SwitchFieldProps<ValueType>) {
  const { input } = useField<ValueType>(name, {
    type: 'checkbox',
    validate,
    initialValue,
  });
  return (
    <Switch
      {...rest}
      checked={input.checked}
      name={name}
      onChange={input.onChange}
    />
  );
}
