import { SelectFormFieldOptions } from 'mapistry-shared';
import React from 'react';
import { useField } from 'react-final-form';
import { SelectableOption } from '../../../../../shared/types/SelectableOption';
import {
  compose,
  hasNoBlankValues,
  hasNoCommasInValues,
  hasUniqueValues,
} from '../../../../../shared/utils/validators';
import { ModalFieldWrapper } from '../styled';
import { AlphabetizeOptions } from './AlphabetizeOptions';
import { EditGenericFieldForm } from './GenericFieldForm/EditGenericFieldForm';
import { ChooseDefaultOptionField } from './shared/ChooseDefaultOptionField';
import { StyledListBuilderField } from './styled';

type EditSelectFieldFormProps = {
  hasFieldsDependedOnByConditional: boolean;
};

// this is rendered within FinalForm so the inputs are managed by it
export const EditSelectFieldForm = (props: EditSelectFieldFormProps) => {
  const { hasFieldsDependedOnByConditional } = props;

  const { input } = useField('options');

  const fieldOptions = input.value as SelectFormFieldOptions;
  const defaultValueOptions: SelectableOption<string>[] = fieldOptions.map(
    (option) => ({ label: option, value: option }),
  );

  const toolTipText = hasFieldsDependedOnByConditional
    ? 'This is not editable because another conditional field references it. Please contact your customer support manager if you need to make changes.'
    : undefined;

  return (
    <>
      <EditGenericFieldForm />
      <ModalFieldWrapper>
        <StyledListBuilderField
          isDisabled={hasFieldsDependedOnByConditional}
          isRequired={!hasFieldsDependedOnByConditional}
          label="Options"
          name="options"
          toolTipText={toolTipText}
          validate={compose(
            hasNoBlankValues,
            hasNoCommasInValues,
            hasUniqueValues,
          )}
        />
      </ModalFieldWrapper>
      <AlphabetizeOptions />
      <ChooseDefaultOptionField defaultValueOptions={defaultValueOptions} />
    </>
  );
};
