import FormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { NullableDate } from 'mapistry-shared';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { bodyMedium } from '../../styles/text';
import { Tooltip } from '../Tooltip';

type CommonPickerProps = Pick<
  KeyboardDatePickerProps,
  | 'autoOk'
  | 'invalidDateMessage'
  | 'variant'
  | 'inputVariant'
  | 'InputProps'
  | 'disableToolbar'
>;

export type MuiPickerPropsToOmitOnWrapper =
  | 'invalidDateMessage'
  | 'inputVariant'
  | 'InputProps'
  | 'onChange';

// styled components & reusable CSS
export const muiPickerCss = css`
  & .MuiInputBase-root {
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray333};
    border: 1px solid ${({ theme }) => theme.colors.grayddd};
    border-radius: 4px;

    &.Mui-error {
      border-color: ${({ theme }) => theme.colors.darkRed};
    }

    &.Mui-focused,
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray333};
    }
  }
`;

const StyledInputLabel = styled(MuiInputLabel)`
  ${bodyMedium}
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.colors.gray333};
  transform: none;

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
` as typeof MuiInputLabel;

const StyledHelperText = styled(FormHelperText)`
  max-width: 26rem;

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

// small function components used in all picker wrappers
export const PickerInputLabel = ({
  error,
  htmlFor,
  label,
  required,
  tooltipText,
}: {
  error: KeyboardDatePickerProps['error'];
  htmlFor: string;
  label: KeyboardDatePickerProps['label'];
  required?: KeyboardDatePickerProps['required'];
  tooltipText?: string;
}) => (
  <LabelContainer>
    <StyledInputLabel required={required} error={error} htmlFor={htmlFor}>
      {label}
    </StyledInputLabel>
    {tooltipText && <Tooltip title={tooltipText} />}
  </LabelContainer>
);

export const PickerHelperText = ({
  error,
  errorText,
  helperText,
}: {
  error: KeyboardDatePickerProps['error'];
  errorText?: string;
  helperText?: KeyboardDatePickerProps['helperText'];
}) => (
  <StyledHelperText error={error}>
    {errorText || helperText || ' '}
  </StyledHelperText>
);

// utility functions
export const getCommonPickerProps = (
  pickerInputElId: string,
): CommonPickerProps => ({
  // saves a click - user can just click out of the picker's popper and the chosen time will be updated
  autoOk: true,
  // the toolbar is a color block at the top fo the picker component
  disableToolbar: true,
  inputVariant: 'standard',
  InputProps: { disableUnderline: true, id: pickerInputElId },
  // this prevents built in helptext element from popping up for a bad date (making components under it jump down);
  //  instead the consumer's validation should check this & pass that in via error & errorText
  invalidDateMessage: '',
  // MUI input variant
  variant: 'inline',
});

export function useOnChangeWrapper<T = string | Date | null>(
  onChangeProp: (event: { target: { value: T } }) => void,
  /**
   * Function for taking the date that is returned from the MUI picker & turning it into the value that consumers
   * of our wrapper components expect
   *
   * There is no default for this argument because of the generic T, but for a simple case where you don't need to
   * transform the date in any way, you simply pass `(date) => date`
   */
  transformDate: (
    date: NullableDate,
    textValue: string | null | undefined,
  ) => T,
) {
  // the onChange prop coming into our picker components is of the form of a typical input's onChange handler
  //  (event: { target: { value }}) => void
  // but the onChange prop of Mui pickers expects a function that just receives the new value
  //  (value: Date, textValue: string) => void
  // so we wrap the prop that comes into our pickers so they get passed the new value in event.target.value
  return useCallback(
    (date: NullableDate, textValue: string | null | undefined) => {
      onChangeProp({ target: { value: transformDate(date, textValue) } });
    },
    [onChangeProp, transformDate],
  );
}
