import { KeyService } from '@monorepo/shared/KeyService';
import React, { RefObject, useCallback } from 'react';
import styled from 'styled-components';
import { focusOutline } from '../../styles/focus';

const StyledClickableHeader = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    ${focusOutline({ $outlineOffset: '2px ' })}
  }
`;

interface ClickableHeaderProps {
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
  titleRef: RefObject<HTMLDivElement>;
}

// keeping it a div instead of a button so the drawer header styling kicks in as is
export const ClickableHeader = ({
  children,
  className,
  onClick,
  titleRef,
}: ClickableHeaderProps) => {
  const onKeyDown = useCallback(
    (e) => {
      if (KeyService.triggersButtonClick(e.keyCode)) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <StyledClickableHeader
      ref={titleRef}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={className}
    >
      {children}
    </StyledClickableHeader>
  );
};
