import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum LogCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  logId: string;
  organizationId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof LogCacheEvents,
  CacheParams
>(LogCacheEvents);

export const invalidateLogCache = cacheRegister.invalidate;
export const logCacheRegister = cacheRegister.register;
