import AccessTime from '@material-ui/icons/AccessTime';
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import { parse } from 'date-fns';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  MuiPickerPropsToOmitOnWrapper,
  PickerHelperText,
  PickerInputLabel,
  getCommonPickerProps,
  muiPickerCss,
  useOnChangeWrapper,
} from './shared';

type TimePickerProps = Omit<
  KeyboardTimePickerProps,
  MuiPickerPropsToOmitOnWrapper | 'ampm' | 'value'
> & {
  errorText?: string;
  // this matches the signature for html input & final form field's onChange
  onChange: (event: { target: { value?: string | null } }) => void;
  name: string;
  // value as a string better represents time than a Date object
  value?: string | null;
};

const StyledTimePicker = styled(KeyboardTimePicker)`
  ${muiPickerCss}
` as typeof KeyboardTimePicker;

export function TimePicker({
  value,
  onChange: onChangeProp,
  label,
  helperText,
  error,
  errorText,
  name,
  required,
  ...rest
}: TimePickerProps) {
  const [idRandomify] = useState(uuidv4);
  const pickerInputElId = `${name}-id-${idRandomify}`;
  const valueAsDate = useMemo(
    () => (value ? parse(value, 'HH:mm', new Date()) : value),
    [value],
  );
  const onChange = useOnChangeWrapper(
    onChangeProp,
    // the MUI picker returns a Date object for time, with the selected time on today's date
    // this transform makes our TimePicker component return a time string (exactly as it appears in the input element)
    (date, textValue) => textValue,
  );
  return (
    <div>
      <PickerInputLabel
        required={required}
        error={error}
        htmlFor={pickerInputElId}
        label={label}
      />
      <StyledTimePicker
        required={required}
        error={error}
        onChange={onChange}
        value={valueAsDate || null} // an unselected date should have value === null
        ampm={false}
        keyboardIcon={<AccessTime />}
        {...getCommonPickerProps(pickerInputElId)}
        {...rest}
      />
      <PickerHelperText
        error={error}
        errorText={errorText}
        helperText={helperText}
      />
    </div>
  );
}
