import { Api } from '@monorepo/shared/apiClient';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useHref, useNavigate } from 'react-router-dom-v5-compat';

interface ViewDetailErrorBoundaryProps {
  children: React.ReactNode;
}

const isErrorResponse = (
  x: Error | Api.ErrorResponse,
): x is Api.ErrorResponse => 'status' in x;

function RedirectToAllViews({ error }: FallbackProps) {
  const { showUserFriendlyErrorToast } = useToast();
  const path = useHref('.');
  const urlSplit = path.split('/views/');
  const viewsUrl = `${urlSplit[0]}/views`;
  const navigate = useNavigate();

  useEffect(() => {
    if (isErrorResponse(error) && error.status === 404) {
      navigate(viewsUrl);
      return;
    }
    showUserFriendlyErrorToast(error);
  }, [error, showUserFriendlyErrorToast, navigate, viewsUrl]);

  return null;
}

export function ViewDetailErrorBoundary({
  children,
}: ViewDetailErrorBoundaryProps) {
  return (
    <ErrorBoundary Fallback={RedirectToAllViews}>{children}</ErrorBoundary>
  );
}
