import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogEntryCache,
  LogEntryCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type UseLogEntryDeleteParams = {
  config?: MutationConfig<Api.DeleteLogEntryResponse, Api.ErrorResponse>;
};

export const useLogEntryDelete = (params?: UseLogEntryDeleteParams) =>
  useMutation(Api.deleteLogEntry, {
    ...params?.config,
    onSuccess: (_, { logId, organizationId, projectId, logEntryId }) => {
      // Invalidate the log entry caches - all pages, all filters for this log id
      const keyParams = { logId, organizationId, projectId, logEntryId };
      invalidateLogEntryCache(LogEntryCacheEvents.DELETE, keyParams);
    },
  });
