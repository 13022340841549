import { DatePickerField } from '@monorepo/shared/componentsV2/fields/DatePickerField';
import { DatetimePickerField } from '@monorepo/shared/componentsV2/fields/DatetimePickerField';
import {
  DatetimeValue,
  DateValue,
  FieldDataType,
  FieldResponse,
  localEquivalentOfUTC,
  SaveDatetimeValue,
  SaveDateValue,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import { FormValues, isNullableDate } from './types';
import {
  getUnexpectedTypeErrorAndLogToSentry,
  getValidateFunction,
} from './utils';

type EditDateLikeFieldValueProps = {
  className?: string;
  field: FieldResponse;
  fieldValue?: DateValue | DatetimeValue;
  isRequired: boolean;
  label: string;
  name: string;
};

export function EditDateLikeValueFactory({
  className,
  field,
  fieldValue,
  isRequired,
  label,
  name,
}: EditDateLikeFieldValueProps) {
  const validate = getValidateFunction(isRequired);

  const transformedDateValue = useMemo(() => {
    if (fieldValue?.value) {
      return localEquivalentOfUTC(new Date(fieldValue.value));
    }
    return undefined;
  }, [fieldValue]);

  const props = {
    className,
    helperText: '',
    label,
    name,
    initialValue: transformedDateValue,
    validate,
    required: isRequired,
  };

  return field.type === FieldDataType.DATE ? (
    <DatePickerField {...props} />
  ) : (
    <DatetimePickerField {...props} />
  );
}

export function getDateLikeFieldValueFromForm(
  field: FieldResponse,
  formValues: FormValues,
) {
  const value = formValues[field.id] || null;
  if (!isNullableDate(value)) {
    throw getUnexpectedTypeErrorAndLogToSentry(field, value);
  }
  const fieldValue: SaveDateValue | SaveDatetimeValue = {
    value: value ? UTCEquivalentOfLocal(value) : value,
  };
  return {
    fieldId: field.id,
    fieldValue,
  };
}
