import { FeatureFlagsContext } from '@monorepo/old-web/js/contexts/FeatureFlagsContext';
import { CalendarName } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchEmissionLoggedItemsAction,
  updateEmissionTrackingCalendarsAction,
} from '../../../actions/air';
import { fetchCalendarEvents } from '../../../actions/calendar';
import {
  getIsFetching,
  getLog,
  getLoggedItems,
  getLogSaved,
} from '../../../selectors/genericLogs';
import { isNullOrUndefined } from '../../../utils';
import withDuplication from '../../withDuplication';
import { GenericLogTrackingCalendar } from './GenericLogTrackingCalendar';

const CALENDAR_NAME = CalendarName.AIR_EMISSIONS_TRACKING;

class GenericLogsCalendarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItems: null,
      viewDate: new Date(),
    };
    this.handleFetchEvents = this.handleFetchEvents.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    const { fetchEmissionLoggedItems, logProjectId, projectId } = this.props;
    // if there are multiple calendars for the same logProjectId on the page, this causes
    // the same request for each one of them unnecessarily, but at the time of leaving this comment
    // it's not an easy fix to change how loading states for loggedItems are being handled in redux
    fetchEmissionLoggedItems(projectId, logProjectId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      logProjectId,
      fetchEvents,
      isLoading,
      loggedItems,
      logSaved,
      projectId,
      savedWidgetSettings,
      savedWidgetSettingsId,
    } = this.props;

    const { currentItems, viewDate } = this.state;
    if (prevState.currentItems !== currentItems && currentItems.length) {
      fetchEvents(
        projectId,
        logProjectId,
        viewDate,
        currentItems,
        savedWidgetSettingsId,
      );
    }

    // `isLoading` is not specific to this log project's logged items, and thus returns incorrect information
    // when there are calendars for multiple log projects on the page (it starts returning true once the _first_ logged items request returned).
    // The Calendar shows a special state for when loggedItems.length is 0, so we can get away with using loggedItems.length for double checking that the logged items are loaded.
    if (
      !currentItems &&
      savedWidgetSettings.loggedItemIds &&
      !isLoading &&
      loggedItems.length
    ) {
      const hydratedSettings = loggedItems.filter((item) =>
        savedWidgetSettings.loggedItemIds.includes(item.id),
      );
      this.setState({ currentItems: hydratedSettings });
    }

    if (currentItems && logSaved && prevState.logSaved !== logSaved) {
      const savedItemIds = Object.keys(logSaved.items).filter(
        (itemId) => !isNullOrUndefined(logSaved.items[itemId].value),
      );
      if (currentItems.some((item) => savedItemIds.includes(item.id))) {
        fetchEvents(
          projectId,
          logProjectId,
          viewDate,
          currentItems,
          savedWidgetSettingsId,
        );
      }
    }
  }

  handleFetchEvents(projectId, calendarName, date) {
    const { fetchEvents, logProjectId, savedWidgetSettingsId } = this.props;
    const { currentItems } = this.state;
    if (currentItems) {
      fetchEvents(
        projectId,
        logProjectId,
        date,
        currentItems,
        savedWidgetSettingsId,
      );
    }
  }

  handleFilterChange(newFilter) {
    const { updateSavedWidgetSettings } = this.props;
    const loggedItemIds = newFilter.map((item) => item.id);
    updateSavedWidgetSettings({ loggedItemIds });
    this.setState({ currentItems: newFilter });
  }

  navigateToSettings() {
    const { logProjectId, projectId } = this.props;
    const url = `/projects/${projectId}/custom-logs/${logProjectId}/settings`;
    window.location.href = url;
  }

  async navigateToList() {
    const { logProjectId, projectId } = this.props;
    const url = `/projects/${projectId}/custom-logs/${logProjectId}`;
    window.location.href = url;
  }

  render() {
    const {
      isLoading,
      loggedItems,
      logProjectId,
      menuOptions,
      projectId,
      savedWidgetSettingsId,
      summaryTitle,
      updateEmissionTrackingCalendars,
    } = this.props;

    const { currentItems } = this.state;

    return (
      <GenericLogTrackingCalendar
        calendarName={CALENDAR_NAME}
        currentItems={currentItems || []}
        fetchEvents={this.handleFetchEvents}
        isLoading={isLoading}
        loggedItems={loggedItems}
        logProjectId={logProjectId}
        additionalMenuOptions={menuOptions}
        navigateToList={() => this.navigateToList()}
        navigateToSettings={() => this.navigateToSettings()}
        onChangeFilter={this.handleFilterChange}
        onDateChange={(date) => this.setState({ viewDate: date })}
        onSaveLog={(logSaved) =>
          updateEmissionTrackingCalendars(logProjectId, logSaved)
        }
        title={summaryTitle}
        projectId={projectId}
        widgetId={savedWidgetSettingsId}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;
  return {
    log: getLog(state, logProjectId),
    // this `isLoading` state is not specific to a logProject, and will thus
    // return true once the first logged items request finished for _any_ log project
    isLoading: getIsFetching(state, 'airEmissionsLoggedItems'),
    loggedItems: getLoggedItems(state, logProjectId),
    logSaved: getLogSaved(state, logProjectId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEmissionLoggedItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLoggedItemsAction(projectId, logProjectId)),
  fetchEvents: (projectId, calendarName, date, limitItems, widgetId) => {
    const parameters = limitItems.map((item) => item.id);
    return dispatch(
      fetchCalendarEvents({
        calendarName,
        date,
        parameters,
        projectId,
        widgetId,
      }),
    );
  },
  updateEmissionTrackingCalendars: (logProjectId, log) =>
    dispatch(updateEmissionTrackingCalendarsAction({ logProjectId, log })),
});

GenericLogsCalendarContainer.propTypes = {
  logProjectId: PropTypes.string.isRequired,
  fetchEmissionLoggedItems: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})),
  logSaved: PropTypes.shape({
    items: PropTypes.shape({}),
  }),
  menuOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  projectId: PropTypes.string.isRequired,
  savedWidgetSettings: PropTypes.shape({
    loggedItemIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  savedWidgetSettingsId: PropTypes.string.isRequired,
  summaryTitle: PropTypes.string.isRequired,
  updateEmissionTrackingCalendars: PropTypes.func.isRequired,
  updateSavedWidgetSettings: PropTypes.func.isRequired,
};

GenericLogsCalendarContainer.defaultProps = {
  loggedItems: [],
  logSaved: null,
};

GenericLogsCalendarContainer.contextType = FeatureFlagsContext;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withDuplication(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GenericLogsCalendarContainer),
  ),
);
