import { FrequencyProvider } from '@monorepo/shared/contexts/FrequencyContext';
import { CUSTOM_FREQUENCY } from '@monorepo/shared/contexts/FrequencyContext/types';
import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import { IntervalFrequencyEnum } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createCalendarFrequencyAction,
  deleteCalendarFrequencyAction,
  fetchCalendarFrequenciesAction,
  resetCalendarErrorStateAction,
} from '../../../../../actions/calendar';
import APP from '../../../../../config';
import { delayedModalClose, isNullOrUndefined } from '../../../../../utils';
import withProvider from '../../../../withProvider';
import InspectionFrequencyModal from './InspectionFrequencyModal';

class InspectionFrequencyModalContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteFrequency = this.handleDeleteFrequency.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.state = {
      customFrequency: null,
      idsToDelete: [],
      saveState: SaveState.CLEAN,
      selectedFrequency: null,
      selectedStartDate: null,
    };
  }

  componentDidMount() {
    const { calendarName, fetchFrequencies, projectId } = this.props;

    fetchFrequencies(projectId, calendarName);
  }

  componentDidUpdate(prevProps) {
    const { reloadEvents, isFetching, serverErrorMessage } = this.props;
    const { saveState } = this.state;

    if (saveState === SaveState.SAVING && prevProps.isFetching && !isFetching) {
      if (serverErrorMessage) {
        this.setState({ saveState: SaveState.DIRTY });
      } else {
        reloadEvents();
        this.setState(
          {
            idsToDelete: [],
            saveState: SaveState.SAVED,
            selectedFrequency: null,
            selectedStartDate: null,
          },
          () => delayedModalClose(this.handleClose),
        );
      }
    }
  }

  handleClose() {
    const { onClose, resetErrorState } = this.props;
    resetErrorState();
    onClose();
  }

  // block app nav?
  handleDeleteFrequency(id) {
    const { idsToDelete } = this.state;
    this.setState({
      idsToDelete: [...idsToDelete, id],
      saveState: SaveState.DIRTY,
    });
  }

  handleFrequencyChange(
    updatedFrequency,
    updatedCustomFrequency,
    updatedStartDate,
  ) {
    const { idsToDelete } = this.state;

    const saveState =
      isNullOrUndefined(updatedFrequency) && !idsToDelete.length
        ? SaveState.CLEAN
        : SaveState.DIRTY;

    this.setState({
      saveState,
      selectedFrequency: updatedFrequency,
      customFrequency: updatedCustomFrequency,
      selectedStartDate: updatedStartDate,
    });
  }

  handleSave() {
    const { calendarName, createFrequency, deleteFrequency, projectId } =
      this.props;
    const {
      customFrequency,
      idsToDelete,
      selectedFrequency,
      selectedStartDate,
    } = this.state;

    this.setState({ saveState: SaveState.SAVING }, () => {
      idsToDelete.map((id) => deleteFrequency(projectId, calendarName, id));
      if (selectedFrequency && selectedStartDate) {
        createFrequency(
          projectId,
          calendarName,
          selectedFrequency,
          selectedStartDate,
          customFrequency,
        );
      }
    });
  }

  frequencyOptions() {
    const frequencies = this.frequenciesWithDeletions();
    const IntervalFrequencyEnumGroup = [CUSTOM_FREQUENCY];
    const options = [
      IntervalFrequencyEnum.NEVER,
      IntervalFrequencyEnum.AS_NEEDED,
      IntervalFrequencyEnum.DAY,
      IntervalFrequencyEnum.WEEK,
      IntervalFrequencyEnum.MONTH,
      IntervalFrequencyEnum.QUARTER,
      IntervalFrequencyEnum.BIANNUAL,
      IntervalFrequencyEnum.YEAR,
      IntervalFrequencyEnumGroup,
    ];

    const latestFrequencyModel = frequencies[frequencies.length - 1];
    if (_get(latestFrequencyModel, 'customFrequency')) {
      return options;
    }
    const latestFrequency = _get(latestFrequencyModel, 'frequency');
    return options.filter((o) => o !== latestFrequency);
  }

  frequenciesWithDeletions() {
    const { frequencies } = this.props;
    const { idsToDelete } = this.state;
    return frequencies.filter((f) => !idsToDelete.includes(f.id));
  }

  render() {
    const { customFrequency, saveState, selectedFrequency, selectedStartDate } =
      this.state;
    const { open, serverErrorMessage, title } = this.props;

    const frequencies = this.frequenciesWithDeletions();

    return (
      <FrequencyProvider
        customFrequency={customFrequency}
        frequencies={frequencies}
        onFrequencyChange={this.handleFrequencyChange}
        onSave={this.handleSave}
        saveState={saveState}
        selectedFrequency={selectedFrequency}
        selectedStartDate={selectedStartDate}
      >
        <InspectionFrequencyModal
          errors={
            serverErrorMessage
              ? ['There was a problem saving. Please try again later.']
              : null
          }
          frequencies={frequencies}
          frequencyOptions={this.frequencyOptions()}
          onClose={this.handleClose}
          onDeleteFrequency={this.handleDeleteFrequency}
          onSave={this.handleSave}
          open={open}
          saveState={saveState}
          title={title}
        />
      </FrequencyProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { calendar } = state;
  return {
    frequencies: _get(calendar.frequencies, ownProps.calendarName, []),
    isFetching: _get(calendar.isFetching, 'calendarFrequencies', false),
    projectId: APP.projectId,
    serverErrorMessage: _get(calendar.errorMessage, 'message'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchFrequencies: (projectId, calendarName) =>
    dispatch(fetchCalendarFrequenciesAction(projectId, calendarName)),
  deleteFrequency: (projectId, calendarName, id) =>
    dispatch(deleteCalendarFrequencyAction(projectId, calendarName, id)),
  createFrequency: (
    projectId,
    calendarName,
    newFrequency,
    newStartDate,
    customFrequency,
  ) =>
    dispatch(
      createCalendarFrequencyAction(
        projectId,
        calendarName,
        newFrequency,
        newStartDate,
        customFrequency,
      ),
    ),
  resetErrorState: () => dispatch(resetCalendarErrorStateAction()),
});

InspectionFrequencyModalContainer.propTypes = {
  calendarName: PropTypes.string.isRequired,
  createFrequency: PropTypes.func.isRequired,
  deleteFrequency: PropTypes.func.isRequired,
  serverErrorMessage: PropTypes.string,
  frequencies: PropTypes.arrayOf(
    PropTypes.shape({
      frequency: PropTypes.string,
      numberOfInspections: PropTypes.number,
      startDate: PropTypes.string,
    }),
  ),
  fetchFrequencies: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  reloadEvents: PropTypes.func.isRequired,
  resetErrorState: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

InspectionFrequencyModalContainer.defaultProps = {
  serverErrorMessage: null,
  frequencies: [],
  open: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InspectionFrequencyModalContainer),
);
