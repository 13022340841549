import { downloadLogEntries } from '@monorepo/shared/apiClient/logs';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { DateRangePicker } from '@monorepo/shared/componentsV2/DateRangePicker';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { useToast } from '@monorepo/shared/contexts';
import { useLog } from '@monorepo/shared/hooks/logs/useLog';
import { useDateRangeFilter } from '@monorepo/shared/hooks/useDateRangeFilter';
import { header3 } from '@monorepo/shared/styles/text';
import {
  NullableDate,
  NUMBER_OF_LOG_ENTRIES_EXPORT_LIMIT,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLogEntryCount } from '../../hooks/useLogEntryCount';

const ModalDescription = styled.h2`
  ${header3}
  margin: 0 0 1.125rem;
`;

const DateRangePickerContainer = styled.div`
  display: flex;
  margin: 0 0 1rem;
`;

const ExportDetailsContainer = styled.div`
  height: 2rem;
`;

type LogEntryExportModalProps = {
  logId: string;
  onClose: () => void;
  open: boolean;
  organizationId: string;
  projectId: string;
};

export function LogEntryExportModal({
  logId,
  onClose,
  open,
  organizationId,
  projectId,
}: LogEntryExportModalProps) {
  const { filter } = useDateRangeFilter();
  const [to, setTo] = useState<NullableDate>(filter?.to || null);
  const [from, setFrom] = useState<NullableDate>(filter?.from || null);

  const { log, isLoading: isLogLoading } = useLog({ logId, organizationId });
  const { count, isLoading: isEntryCountLoading } = useLogEntryCount({
    after: from ? UTCEquivalentOfLocal(from) : undefined,
    before: to ? UTCEquivalentOfLocal(to) : undefined,
    logId,
    organizationId,
    projectId,
  });

  const { showUserFriendlyErrorToast } = useToast();

  const onClickExport = useCallback(() => {
    try {
      downloadLogEntries({
        logId,
        organizationId,
        projectId,
        after: from ? UTCEquivalentOfLocal(from) : undefined,
        before: to ? UTCEquivalentOfLocal(to) : undefined,
      });
      onClose();
    } catch (error) {
      showUserFriendlyErrorToast(error, 'Unable to process data for export.');
    }
  }, [
    from,
    logId,
    onClose,
    organizationId,
    projectId,
    showUserFriendlyErrorToast,
    to,
  ]);

  const hasTooManyEntriesToExport =
    count && count > NUMBER_OF_LOG_ENTRIES_EXPORT_LIMIT;
  const hasNoRowsToExport = count === 0;

  const isExportDisabled =
    isEntryCountLoading ||
    isLogLoading ||
    hasTooManyEntriesToExport ||
    hasNoRowsToExport;

  const getExportDetailsDisplay = (): React.ReactNode => {
    if (isEntryCountLoading) return <Loading size="small" />;
    if (hasTooManyEntriesToExport)
      return `There are too many entries in the selected date range (${count.toLocaleString()}). \
    You can export up to ${NUMBER_OF_LOG_ENTRIES_EXPORT_LIMIT.toLocaleString()} entries at one time.`;
    if (hasNoRowsToExport)
      return 'There are no entries to export in this date range.';
    return `${count?.toLocaleString()} entry rows will be exported.`;
  };

  return (
    <BaseModal
      buttons={[
        <Button color="primary" key="cancel" onClick={onClose} variant="text">
          Cancel
        </Button>,
        <Button
          color="primary"
          disabled={isExportDisabled}
          key="save"
          onClick={onClickExport}
          variant="contained"
        >
          Export
        </Button>,
      ]}
      onClose={onClose}
      open={open}
      title="Export Log Data"
    >
      <ModalDescription>
        Configure your export for <b>{log?.name}</b> Log.
      </ModalDescription>
      <DateRangePickerContainer>
        <DateRangePicker
          to={to}
          setTo={setTo}
          from={from}
          setFrom={setFrom}
          displayOnSingleLine
        />
      </DateRangePickerContainer>
      <ExportDetailsContainer>
        {getExportDetailsDisplay()}
      </ExportDetailsContainer>
    </BaseModal>
  );
}
