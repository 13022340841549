import { StyledSelect } from '@monorepo/shared/componentsV2/styled/form';
import {
  MathUnit,
  NullableNumber,
  NumericFieldResponse,
  SaveFieldValueRequest,
  SaveNumericValue,
  SingleSelectFieldResponse,
  SingleSelectValue,
} from 'mapistry-shared';
import React, { useMemo } from 'react';
import { FormValues } from './types';
import {
  getUnexpectedTypeErrorAndLogToSentry,
  getValidateFunction,
  nullToUndefined,
} from './utils';

function getSingleSelectOptions(
  field: SingleSelectFieldResponse,
  fieldValue?: SingleSelectValue,
) {
  const options = [...field.options];
  if (fieldValue?.value && !options.includes(fieldValue.value)) {
    options.push(fieldValue.value);
  }
  return options.map((opt) => ({
    label: opt,
    value: opt,
  }));
}

type EditSingleSelectFieldValueProps = {
  className?: string;
  field: SingleSelectFieldResponse;
  fieldValue?: SingleSelectValue;
  isRequired: boolean;
  label: string;
  name: string;
};

export function EditSingleSelectFieldValue({
  className,
  field,
  fieldValue,
  isRequired,
  label,
  name,
}: EditSingleSelectFieldValueProps) {
  const options = useMemo(
    () => getSingleSelectOptions(field, fieldValue),
    [field, fieldValue],
  );
  return (
    <StyledSelect
      className={className}
      options={options}
      name={name}
      label={label}
      initialValue={nullToUndefined(fieldValue?.value)}
      showBlankOption={!isRequired}
      validate={getValidateFunction(isRequired)}
      required={isRequired}
    />
  );
}

export function getNumericFieldValueFromForm(
  field: NumericFieldResponse,
  formValues: FormValues,
): SaveFieldValueRequest {
  const value = formValues[field.id] == null ? null : formValues[field.id];
  let parsedValue: NullableNumber;

  if (value === null) {
    parsedValue = value;
  } else if (typeof value === 'string') {
    parsedValue = parseFloat(value);
    if (Number.isNaN(parsedValue)) {
      throw getUnexpectedTypeErrorAndLogToSentry(field, value);
    }
  } else {
    throw getUnexpectedTypeErrorAndLogToSentry(field, value);
  }

  const givenUnits = formValues[`${field.id}_unit`];

  if (
    givenUnits != null &&
    !(typeof givenUnits === 'string' && MathUnit.isValid(givenUnits))
  ) {
    throw new Error(
      `Unexpected value provided for units of ${field.name} (${givenUnits}).`,
    );
  }

  const fieldValue: SaveNumericValue = {
    value: parsedValue,
    givenUnits: givenUnits || null,
  };
  return {
    fieldId: field.id,
    fieldValue,
  };
}
