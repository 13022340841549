import React from 'react';
import { InfoContainer } from './styled';

export function EmptyDatasetList({ includeWorkflows = true }) {
  const examples = includeWorkflows
    ? 'Logs, Resource types or Workflows'
    : 'Logs or Resource types';

  return (
    <InfoContainer>
      There are no data sources available for this organization. They will
      become available once there are {examples} configured.
    </InfoContainer>
  );
}
