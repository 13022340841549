import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createProjectsForUserKey = () => ['projectsForUser'] as const;

type ProjectsForUserFetcher = Api.DataHookQueryFn<
  typeof createProjectsForUserKey,
  typeof Api.fetchProjectsForUser
>;

const fetcher: ProjectsForUserFetcher = () => Api.fetchProjectsForUser();

type UseProjectsForUserProps = {
  config?: QueryConfig<Api.FetchProjectsForUserResponse, Api.ErrorResponse>;
};

export function useProjectsForUser(props?: UseProjectsForUserProps) {
  const key = Api.isDisabledDataHookInput(props)
    ? undefined
    : createProjectsForUserKey();
  const config = props?.config;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    projects: data,
  };
}
