import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  getCommonPickerProps,
  muiPickerCss,
  MuiPickerPropsToOmitOnWrapper,
  PickerHelperText,
  PickerInputLabel,
  useOnChangeWrapper,
} from './shared';

type DatetimePickerProps = Omit<
  KeyboardDateTimePickerProps,
  // if a use case for a custom datetime display format or a 12-hour clock comes up, we can expose
  //  `format` and/or `ampm` as props, but for now this ensures consistency
  MuiPickerPropsToOmitOnWrapper | 'format' | 'ampm'
> & {
  errorText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: { target: { value: any } }) => void;
  name: string; // add name for unique ID
};

const StyledDatetimePicker = styled(KeyboardDateTimePicker)`
  ${muiPickerCss}
`;

export const DATETIME_PICKER_DISPLAY_FORMAT = 'MM/dd/yyyy HH:mm';

export function DatetimePicker({
  value,
  onChange: onChangeProp,
  label,
  helperText,
  error,
  errorText,
  name,
  required,
  ...rest
}: DatetimePickerProps) {
  const [idRandomify] = useState(uuidv4);
  const pickerInputId = `${name}-id-${idRandomify}`;
  const onChange = useOnChangeWrapper(onChangeProp, (date) => date); // no transform on the date needed
  return (
    <div>
      <PickerInputLabel
        required={required}
        error={error}
        htmlFor={pickerInputId}
        label={label}
      />
      <StyledDatetimePicker
        required={required}
        error={error}
        onChange={onChange}
        value={value || null} // an unselected date should have value === null
        format={DATETIME_PICKER_DISPLAY_FORMAT}
        ampm={false}
        initialFocusedDate={new Date()}
        {...getCommonPickerProps(pickerInputId)}
        {...rest}
      />
      <PickerHelperText
        error={error}
        errorText={errorText}
        helperText={helperText}
      />
    </div>
  );
}
