import React from 'react';
import styled from 'styled-components';
import { bodySmall, header4 } from '../styles/text';

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 0 1rem;
  margin: 75px auto 0;
  text-align: center;
`;

const PrimaryText = styled.div`
  ${header4}
`;

const SecondaryText = styled.div`
  ${bodySmall}
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.gray666};
`;

type TextBlockProps = {
  primaryText: string;
  secondaryText?: string;
};

export function TextBlock({ primaryText, secondaryText }: TextBlockProps) {
  return (
    <TextBlockContainer>
      <PrimaryText>{primaryText}</PrimaryText>
      {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
    </TextBlockContainer>
  );
}
