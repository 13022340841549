import { v4 as uuidv4 } from 'uuid';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class Widget {
  wrapper: boolean;

  custom: boolean;

  handlesFilteringItself?: boolean;

  innerClass?: string;

  key: string;

  name: string;

  outerClass?: string;

  renderComponent: (filterQuery?: string) => JSX.Element;

  constructor(
    name: string,
    renderComponent: (filterQuery?: string) => JSX.Element,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-param-last
    custom = false,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-param-last
    wrapper = true,
    innerClass?: string,
    outerClass?: string,
    handlesFilteringItself?: boolean,
  ) {
    this.name = name;
    this.renderComponent = renderComponent;
    this.custom = custom;
    this.wrapper = wrapper;
    this.key = uuidv4();
    this.innerClass = innerClass;
    this.outerClass = outerClass;
    this.handlesFilteringItself = handlesFilteringItself;
  }

  static sortByWidgetName = (widgetA: Widget, widgetB: Widget) =>
    widgetA.name.trim().localeCompare(widgetB.name.trim());
}
