import { Header } from '@monorepo/shared/componentsV2/Header';
import { Search } from '@monorepo/shared/componentsV2/Search';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  max-width: 10rem;
`;

type InspectionsPageHeaderProps = {
  onSearch: (filter: string) => void;
  filter: string;
};

export const InspectionsPageHeader = ({
  onSearch,
  filter,
}: InspectionsPageHeaderProps) => {
  const { project } = useCurrentProject();

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSearch?.(e.target.value),
    [onSearch],
  );

  return (
    <Header
      rightActions={
        <SearchContainer>
          <Search onChange={handleSearch} value={filter} />
        </SearchContainer>
      }
      title="Inspections"
      subtitle={project?.name}
      variant="noDropShadow"
    />
  );
};
