import MuiTabs from '@material-ui/core/Tabs';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NavigationTab } from './NavigationTab';

export const Skeleton = styled(MuiSkeleton)`
  margin: 24px;
  border-radius: 10px;
`;

export const Tabs = styled(MuiTabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

interface NavigationTabOption {
  href: string;
  isLegacy?: boolean;
  label: React.ReactNode | ((props: { isActive: boolean }) => React.ReactNode);
  skeletonWidth?: string | number;
}

type NavigationTabsProps = React.ComponentProps<typeof MuiTabs> & {
  isLoading?: boolean;
  label: string;
  options: NavigationTabOption[];
};

export function NavigationTabs({
  isLoading,
  label,
  options,
  ...rest
}: NavigationTabsProps) {
  const [selected, setSelected] = useState(0);
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const handleChange = (_, newValue: number) => {
    setSelected(newValue);
  };

  const handleDeclareIsActive = (value: number) => (isActive: boolean) => {
    if (isActive) {
      setSelected(value);
    }
  };

  if (isLoading) {
    return (
      <>
        {options.map(({ href, skeletonWidth }) => (
          <Skeleton
            key={href}
            variant="rect"
            height={21}
            width={skeletonWidth} // takes whole width if undefined
          />
        ))}
      </>
    );
  }

  return (
    <nav aria-label={label}>
      <Tabs onChange={handleChange} value={selected} {...rest}>
        {options.map(({ href, isLegacy, label: optionLabel }, index) => (
          <NavigationTab
            declareIsActive={handleDeclareIsActive(index)}
            isLegacy={isLegacy}
            key={href}
            label={optionLabel}
            to={href}
          />
        ))}
      </Tabs>
    </nav>
  );
}
