import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import {
  FieldDataType,
  FieldResponse,
  NumericFieldResponse,
} from 'mapistry-shared';
import {
  validateDayField,
  validateTimeField,
} from '../validationAndParsing/recordValidationHelpers';
import { isEmpty } from '../validationAndParsing/valueValidationHelpers';
import { composeKeyForUnitField } from './getColumnsForFields';

export function validateNumericAndUnitField(
  field: NumericFieldResponse,
  record: FlatfileRecord,
): void {
  const value = record.get(field.id);
  const unitKey = composeKeyForUnitField(field.id);
  const unitValue = record.get(unitKey);

  if (!isEmpty(value) && field.units && !unitValue) {
    record.addError(unitKey, `Field "${field.name}" require units`);
  }
}

/* ----- Parsing and validating field value based on its type ----- */
export function validateFields(
  fields: FieldResponse[],
  record: FlatfileRecord,
): void {
  fields.forEach((field) => {
    const { type } = field;

    if (type === FieldDataType.DATE) {
      validateDayField(field.id, record, { canBeInTheFuture: true });
    } else if (type === FieldDataType.DATETIME) {
      validateDayField(field.id, record, {
        canBeInTheFuture: true,
        withTime: true,
      });
    } else if (type === FieldDataType.NUMERIC) {
      validateNumericAndUnitField(field as NumericFieldResponse, record);
    } else if (type === FieldDataType.TIME) {
      validateTimeField(field.id, record);
    }
  });
}
