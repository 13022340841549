import { SheetConfig } from '@flatfile/api/api';
import { useLogProject } from '@monorepo/genericLogs/src/hooks/useLogProject';
import { useLoggedItems } from '@monorepo/genericLogs/src/hooks/useLoggedItems';
import { Api } from '@monorepo/shared/apiClient';
import { LoggedItem } from '@monorepo/shared/apiClient/types';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  getDateColumnSetting,
  getSingleSelectColumnSetting,
  getTextColumnSetting,
  getTimeColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { uniqueConstraintSetting } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileSettingsHelpers';
import {
  didValidationSucceed,
  validateTimeField,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import { useProjectUsers } from '@monorepo/shared/hooks/useProjectUsers';
import { CalendarName } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import Units from '../../../utils/units';
import {
  LogBulkUploadFromFlatfile,
  SelectTypeOption,
  LogUploadFlatfileKeys as keys,
} from './EditLogModalTypes';
import {
  replaceDateAndTimeFieldsWithOneDatetimeField,
  replaceUserNamesWithUserIdsForLogsOfTypeUser,
} from './logUploadHelpers';
import {
  scanForAlreadyExistingLogs,
  scanLogDateForErrorsAndParseDate,
  scanLogUnitForErrors,
  scanLoggedValueForErrors,
  setDefaultUser,
} from './logUploadParsers';

const UNIT_OPTIONS = Units.getAllForSuite(CalendarName.GENERIC_LOG).map(
  (u: SelectTypeOption) => ({
    label: u.label,
    value: u.value,
  }),
);

interface LoggedItemValuesUploadModalProps {
  isOpen: boolean;
  logProjectId: string;
  onClose: () => void;
  projectId: string;
}

export function LoggedItemValuesUploadModal(
  props: LoggedItemValuesUploadModalProps,
) {
  const { isOpen, logProjectId, projectId, onClose } = props;

  // load relevant data
  const { logProject, isLoading: logProjectIsLoading } = useLogProject({
    id: logProjectId,
  });
  const { users = [], isLoading: projectUsersAreLoading } = useProjectUsers({
    projectId,
    includeArchived: false, // don't allow archived users when bulk uploading
  });
  const { loggedItems = [], isLoading: loggedItemsAreLoading } = useLoggedItems(
    {
      logProjectId,
      projectId,
    },
  );
  const { currentUser, isLoading: currentUserIsLoading } = useCurrentUser();

  const isLoading =
    logProjectIsLoading ||
    projectUsersAreLoading ||
    loggedItemsAreLoading ||
    currentUserIsLoading;

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        label: user.userName,
        value: user.userId,
      })),
    [users],
  );

  const [itemOptions, loggedItemDict] = useMemo(() => {
    const options = [] as { label: string; value: string }[];
    const loggedItemIdToLoggedItem: Record<string, LoggedItem> = {};

    loggedItems.forEach((item) => {
      options.push({
        label: item.name,
        value: item.id,
      });
      loggedItemIdToLoggedItem[item.id] = item;
    });
    return [options, loggedItemIdToLoggedItem];
  }, [loggedItems]);

  const logProjectStartDate = useMemo(
    () => logProject?.startDate && new Date(logProject.startDate),
    [logProject?.startDate],
  );

  const workbook = useMemo<WorkbookConfig>(() => {
    const fields: SheetConfig['fields'] = [
      getDateColumnSetting({
        label: 'Log date',
        key: keys.logDate,
        isRequired: true,
      }),
      getTimeColumnSetting({
        label: 'Log time',
        key: keys.logTime,
        isRequired: true,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Person reporting',
          key: keys.userId,
          description:
            'needs to match the name of a user assigned to this site',
        },
        userOptions,
      ),
      getSingleSelectColumnSetting(
        {
          label: 'Item',
          key: keys.loggedItemId,
          isRequired: true,
          description:
            'needs to match the name of a logged item on this log - check the log settings',
        },
        itemOptions, // only allowing items that are in the current logProject already set in settings
      ),
      getTextColumnSetting({
        label: 'Item value',
        key: keys.logValue,
        isRequired: true,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Units',
          key: keys.units,
          description: 'leave blank or select "unitless" if item has no unit',
        },
        UNIT_OPTIONS,
      ),
    ];

    // declaring as a const before returning for better type checking
    const workbookConfig: WorkbookConfig = {
      name: `Upload Logs`,
      sheets: [
        {
          name: `Logs ${logProject?.name ? `for ${logProject.name}` : ''}`,
          slug: 'upload-logs',
          fields,
          constraints: [
            uniqueConstraintSetting('Entry for date and time and item', [
              keys.logDate,
              keys.logTime,
              keys.loggedItemId,
            ]),
          ],
        },
      ],
    };
    return workbookConfig;
  }, [itemOptions, userOptions, logProject]);

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(
    async (record) => {
      scanLogDateForErrorsAndParseDate(
        keys.logDate,
        record,
        logProjectStartDate,
      );
      validateTimeField(keys.logTime, record);
      setDefaultUser(keys.userId, record, currentUser?.id);

      const loggedItemId = record.get(keys.loggedItemId);
      const loggedItemMatchingThisRecord =
        loggedItemId && typeof loggedItemId === 'string'
          ? loggedItemDict[loggedItemId]
          : undefined;
      if (loggedItemMatchingThisRecord) {
        scanLoggedValueForErrors(
          keys.logValue,
          record,
          userOptions,
          loggedItemMatchingThisRecord,
        );
        scanLogUnitForErrors(keys.units, record, loggedItemMatchingThisRecord);
      } else {
        record.addError(
          keys.loggedItemId,
          "Looks like this item first needs to be added to the Logged Items in this log's settings.",
        );
      }

      // check whether the changed record already has a record submitted at that exact time
      // only check on change, and if date, time, and loggedItem field are
      // 1. filled out
      // 2. don't have errors already
      if (
        record.get(keys.logDate) &&
        didValidationSucceed(keys.logDate, record) &&
        record.get(keys.logTime) &&
        didValidationSucceed(keys.logTime, record) &&
        record.get(keys.loggedItemId) &&
        didValidationSucceed(keys.loggedItemId, record)
      ) {
        await scanForAlreadyExistingLogs(
          keys.logTime,
          record,
          projectId,
          logProjectId,
          record.get(keys.logDate) as string,
          record.get(keys.logTime) as string,
          record.get(keys.loggedItemId) as string,
        );
      }
    },
    [
      currentUser?.id,
      logProjectId,
      logProjectStartDate,
      loggedItemDict,
      projectId,
      userOptions,
    ],
  );

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: LogBulkUploadFromFlatfile[]) => {
      const dataToUploadWithUsersFixed =
        replaceUserNamesWithUserIdsForLogsOfTypeUser(
          results,
          loggedItemDict,
          userOptions,
        );

      const dataToUpload = replaceDateAndTimeFieldsWithOneDatetimeField(
        dataToUploadWithUsersFixed,
      );

      await Api.saveNewLoggedValues(projectId, logProjectId, dataToUpload);

      const savedCount = dataToUpload.length;
      const noun = savedCount === 1 ? 'row' : 'rows';
      return `
        Successfully uploaded ${savedCount} ${noun} of log data!
        Now you can see your data on the "View all logs" page.
    `;
    },
    [projectId, logProjectId, loggedItemDict, userOptions],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ projectId, logProjectId }),
    [logProjectId, projectId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      recordHookCallback={recordHookCallback}
      workbook={workbook}
      showTableForManualInput
    />
  );
}
