import { Api } from '@monorepo/shared/apiClient';
import { useResourceProperties } from './useResourceProperties';
import { useResourceType } from './useResourceType';

type UseResourceTypeWithPropertiesParams = {
  organizationId?: string;
  resourceTypeId?: string;
  // if there's a use case for passing in query config to this hook, this can be expanded
};

type UseResourceTypeWithPropertiesReturn = {
  resourceType: ReturnType<typeof useResourceType>['resourceType'];
  resourceProperties: ReturnType<
    typeof useResourceProperties
  >['resourceProperties'];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  isStale: boolean;
  error: Api.ErrorResponse | null;
};

export function useResourceTypeWithProperties(
  params: UseResourceTypeWithPropertiesParams,
): UseResourceTypeWithPropertiesReturn {
  const {
    resourceType,
    isLoading: isLoadingResourceType,
    isFetching: isFetchingResourceType,
    isStale: isResourceTypeStale,
    isError: isErrorResourceType,
    error: resourceTypeError,
  } = useResourceType(params);
  const {
    resourceProperties,
    isLoading: isLoadingResourceProperties,
    isFetching: isFetchingResourceProperties,
    isStale: isResourcePropertiesStale,
    isError: isErrorResourceProperties,
    error: resourcePropertiesError,
  } = useResourceProperties(params);

  // couldn't find anything in the RQ API that combines two RQ QueryResult objects, so this
  //  function is only returning a few of the pieces of query info that we currently use
  // this object can be expanded as use cases become necessary
  return {
    resourceType,
    resourceProperties,
    isLoading: isLoadingResourceType || isLoadingResourceProperties,
    isFetching: isFetchingResourceType || isFetchingResourceProperties,
    isStale: isResourceTypeStale || isResourcePropertiesStale,
    isError: isErrorResourceType || isErrorResourceProperties,
    error: resourceTypeError || resourcePropertiesError,
  };
}
