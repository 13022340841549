import {
  WizardHeader,
  WizardLink,
} from '@monorepo/shared/componentsV2/fullPageWizard/WizardHeader';
import { NonEmptyArray } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { RouteEditMode, WizardStep, WizardStepUrlPart } from '../types';

interface EditWorkflowHeaderProps {
  canSubmit?: boolean;
  isSubmitting?: boolean;
  onSubmit?: () => unknown;
  wizardStep: WizardStep;
}

export function EditWorkflowHeader({
  canSubmit,
  isSubmitting = false,
  onSubmit,
  wizardStep,
}: EditWorkflowHeaderProps) {
  const { mode = RouteEditMode.NEW, workflowId } = useParams();
  const isEditMode = mode === RouteEditMode.EDIT;
  const workflowExists = !!workflowId;

  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    if (!workflowExists) {
      navigate('../..');
      return;
    }
    const isFirstStep = wizardStep === WizardStep.DETAILS;
    navigate(isFirstStep ? '../../../..' : '../../..');
  }, [navigate, wizardStep, workflowExists]);

  const breadcrumbsLinks = useMemo<NonEmptyArray<WizardLink>>(
    () => [
      {
        completed: workflowExists,
        label: WizardStep.DETAILS,
        to:
          wizardStep === WizardStep.DETAILS
            ? '.'
            : `../${WizardStepUrlPart[WizardStep.DETAILS]}`,
      },
      {
        disabled: !workflowExists,
        label: WizardStep.STEPS,
        to:
          wizardStep === WizardStep.STEPS
            ? '.'
            : `../../${WizardStepUrlPart[WizardStep.STEPS]}`,
      },
    ],
    [wizardStep, workflowExists],
  );

  return (
    <WizardHeader
      canSubmit={canSubmit}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      links={breadcrumbsLinks}
      onClose={handleClose}
      onSubmit={onSubmit}
      title={isEditMode ? 'Edit Workflow' : 'New Workflow'}
      wizardStep={wizardStep}
    />
  );
}
