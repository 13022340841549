import { useToast } from '@monorepo/shared/contexts/ToastContext';
import * as Sentry from '@sentry/browser';
import { CreateLogRequest, UpdateLogRequest } from 'mapistry-shared';
import { useCallback } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useLogCreate } from '../../hooks/useLogCreate';
import { useLogUpdate } from '../../hooks/useLogUpdate';
import { WizardStep, WizardStepUrlPart } from './EditLogHeader';
import { Mode } from './types';

const isUpdateLogRequest = (
  x: CreateLogRequest | UpdateLogRequest,
): x is UpdateLogRequest => 'id' in x;

export function useSubmitLogDetails() {
  const { logId, mode = Mode.new, organizationId } = useParams();
  const navigate = useNavigate();
  const { showUserFriendlyErrorToast, success } = useToast();
  const [creator] = useLogCreate({ config: { throwOnError: true } });
  const [updater] = useLogUpdate({ config: { throwOnError: true } });

  const isCreateNewLogMode = mode === Mode.new;
  const logExists = !!logId;

  const navigateToNextStep = useCallback(
    (savedId: string) => {
      const link = WizardStepUrlPart[WizardStep.FIELDS];
      if (!logExists) {
        navigate(`../../${savedId}/new/${link}`);
      } else {
        navigate(`../${link}`);
      }
    },
    [logExists, navigate],
  );

  const onSubmit = useCallback(
    async (
      values: CreateLogRequest | UpdateLogRequest,
      form: FormRenderProps<CreateLogRequest | UpdateLogRequest>['form'],
    ) => {
      try {
        // If we are still in create new Log flow, user can go back to the first step,
        // and if they don't make any changes, we don't send server request and don't show success toast.
        const { pristine } = form.getState();
        if (pristine) {
          if (!logId) {
            Sentry.captureException(
              '[EditResourceTypePage Error]: Create button is not disabled for pristine form',
            );
            throw new Error('Please, fill in the form before saving');
          }
          navigateToNextStep(logId);
          return;
        }

        if (!organizationId) {
          const errMsg = `Couldn't create log: LogDataSetup page doesn't have organization (${organizationId})`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }
        const updated = isUpdateLogRequest(values)
          ? await updater({ log: values, organizationId })
          : await creator({
              log: values,
              organizationId,
            });
        if (updated) {
          success(
            `${updated.name} has been ${logExists ? 'edited.' : 'created.'}`,
          );

          if (isCreateNewLogMode) {
            navigateToNextStep(updated.id);
          }
        }
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save ${values.name}.`);
      }
    },
    [
      creator,
      isCreateNewLogMode,
      logExists,
      logId,
      navigateToNextStep,
      organizationId,
      success,
      updater,
      showUserFriendlyErrorToast,
    ],
  );

  return onSubmit;
}
