import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import React from 'react';

interface ViewLogsEmptyPageProps {
  onAddNew: () => void;
  organizationId: string;
}

export function ViewLogsEmptyPage({
  onAddNew,
  organizationId,
}: ViewLogsEmptyPageProps) {
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const aboutLogs =
    'Logs allow you to collect, view, and share data that helps you fulfill permit requirements at your site or across multiple sites.';
  const secondaryText = canEditOrgSettings
    ? `Click to create a new Log. ${aboutLogs}`
    : `${aboutLogs} Contact your organization administrator to configure a Log.`;

  return (
    <Main>
      <EmptyState
        actionButtonConfigs={
          canEditOrgSettings
            ? [{ label: 'Create new Log', onClick: onAddNew }]
            : []
        }
        primaryText="There are no Logs configured for this organization."
        secondaryText={secondaryText}
      />
    </Main>
  );
}
