import { Loading } from '@monorepo/shared/components/Loading';
import { Badge } from '@monorepo/shared/componentsV2/Badge';
import { Link } from '@monorepo/shared/componentsV2/Link';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { useLogs } from '@monorepo/shared/hooks/logs/useLogs';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import { getCategoryDisplayString, LogRefResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { FieldCount } from './FieldCount';
import { ViewLogsTableActions } from './ViewLogsTableActions';

function getColumns(
  canEditOrgSettings: boolean,
): TableColumn<LogRefResponse>[] {
  const columns: TableColumn<LogRefResponse>[] = [
    {
      id: 'name',
      header: 'Log Name',
      contents: ({ id, name }) => (
        <Link to={`${id}/`} color="grey">
          {name}
        </Link>
      ),
      width: '40%',
    },
    {
      id: 'status',
      header: 'Status',
      contents: ({ isPublished }) => (
        <Badge
          color={isPublished ? 'primary' : undefined}
          label={isPublished ? 'Published' : 'Unpublished'}
        />
      ),
    },
    {
      id: 'category',
      header: 'Category',
      contents: ({ category }) => getCategoryDisplayString(category),
      align: 'center',
    },
    {
      id: 'numberOfFields',
      header: '# of Fields',
      contents: ({ id }) => <FieldCount logId={id} />,
      align: 'center',
    },
  ];
  if (canEditOrgSettings) {
    columns.push({
      id: 'actions',
      header: 'Actions',
      contents: (log) => <ViewLogsTableActions log={log} />,
      align: 'center',
      width: '10%',
    });
  }
  return columns;
}

const StyledTable = styled(Table)`
  height: 100%;
  padding-bottom: 4rem;
` as typeof Table;

interface ViewLogsTableProps {
  filter: string;
  className?: string;
}

export function ViewLogsTable({ className, filter }: ViewLogsTableProps) {
  const { organizationId } = useParams();
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const { logs, isLoading } = useLogs({ organizationId });
  const sortedLogs = useMemo(
    () =>
      logs
        ? logs.slice().sort((logA, logB) => logA.name.localeCompare(logB.name))
        : [],
    [logs],
  );

  const filterLogsBy = useCallback(
    (log) => lookFor(filter, log, ['name']),
    [filter],
  );

  const columns = useMemo(
    () => getColumns(canEditOrgSettings),
    [canEditOrgSettings],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Main className={className}>
      <StyledTable
        rows={sortedLogs}
        columns={columns}
        ariaLabel="Logs"
        rowFilter={filterLogsBy}
      />
    </Main>
  );
}
