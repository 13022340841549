import { css } from 'styled-components';

export const header1 = css`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
`;

export const header2 = css`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4375rem;
`;

export const header3 = css`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3125rem;
`;

export const header4 = css`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
`;

export const bodyRegular = css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
`;

export const bodyMedium = css`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
`;

export const bodySmall = css`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
`;

export const label = css`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`;

export const buttonText = css`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
`;
