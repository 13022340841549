import { useRef } from 'react';
import { shallowEqual } from 'react-redux';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useStableReference<V>(
  value: V,
  equalityFn: (a: V, b: V) => boolean = shallowEqual,
) {
  const stableValueRef = useRef(value);
  if (!equalityFn(value, stableValueRef.current)) {
    stableValueRef.current = value;
  }
  return stableValueRef.current;
}

export { useStableReference };
