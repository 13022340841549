import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Loading } from '@monorepo/shared/components/Loading';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { Description } from '@monorepo/shared/componentsV2/Description';
import { useReorderableItems } from '@monorepo/shared/componentsV2/dragAndDrop/useReorderableItems';
import {
  Page,
  Content as StyledMain,
  Title,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import * as Sentry from '@sentry/browser';
import { FieldResponse } from 'mapistry-shared';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useResourcePropertiesOrderUpdate } from '../../hooks/useResourcePropertiesOrderUpdate';
import { useResourcePropertyDelete } from '../../hooks/useResourcePropertyDelete';
import { useResourceTypeAssetsOrShowError } from '../../hooks/useResourceTypeAssetsOrShowError';
import {
  EditResourceTypeHeader,
  WizardStep,
} from '../EditResourceTypeHeader/EditResourceTypeHeader';
import { EditResourcePropertyModal } from './EditResourcePropertyModal';
import { ResourcePropertiesList } from './ResourcePropertiesList';

enum Mode {
  edit = 'edit',
  new = 'new',
}

const Content = styled.div`
  max-width: 615px;
`;

const DescriptionStyled = styled(Description)`
  margin-bottom: 0.6rem;
`;

export function EditResourcePropertiesPage() {
  useDisableSubnav();

  const navigate = useNavigate();

  const { organizationId, mode = Mode.new, resourceTypeId } = useParams();
  const { isError, isLoading, resourceProperties, resourceType } =
    useResourceTypeAssetsOrShowError(organizationId, resourceTypeId);

  const [updatePropertiesOrder] = useResourcePropertiesOrderUpdate({
    config: { throwOnError: true },
  });

  const [propertyToEdit, setPropertyToEdit] = useState<
    FieldResponse | undefined
  >(undefined);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [open, setOpen, setClosed] = useOpenClose();
  const openModal = useCallback(
    (property?: FieldResponse) => {
      setPropertyToEdit(property);
      setOpen();
    },
    [setOpen],
  );

  const { confirm } = useModal();
  const [deleter] = useResourcePropertyDelete({
    config: { throwOnError: true },
  });
  const { showUserFriendlyErrorToast, success: showSuccess } = useToast();
  const handleDelete = useCallback(
    async (property: FieldResponse) => {
      const isConfirmed = await confirm({
        title: 'Delete Resource Property',
        description: (
          <>
            Are you sure you want to delete the <b>{property.name}</b> Resource
            property?
          </>
        ),
        cancelButtonText: 'Keep property',
        confirmButtonText: 'Delete property',
        danger: true,
      });

      if (!isConfirmed) return;

      try {
        if (!organizationId || !resourceTypeId) {
          const errMsg = `Couldn't delete Resource property: This page doesn't have Resource type id or organization id`;
          Sentry.captureException(errMsg);
          throw new Error(errMsg);
        }
        await deleter({
          organizationId,
          resourceTypeId,
          resourcePropertyId: property.id,
        });
        showSuccess(`${property.name} has been deleted.`);
      } catch (error) {
        showUserFriendlyErrorToast(
          error,
          `Unable to delete "${property.name}" Resource property.`,
        );
      }
    },
    [
      confirm,
      deleter,
      organizationId,
      resourceTypeId,
      showUserFriendlyErrorToast,
      showSuccess,
    ],
  );

  const {
    hasBeenReordered,
    orderedItems: sortedResourceFields,
    orderedItemIds,
    updateItemOrder: updateResourceFieldOrder,
  } = useReorderableItems(resourceProperties);

  const reorderSubmit = useCallback(async () => {
    if (!organizationId || !resourceTypeId) return;
    setIsSubmitting(true);

    try {
      await updatePropertiesOrder({
        organizationId,
        resourceTypeId,
        resourcePropertiesOrder: orderedItemIds,
      });

      if (mode === Mode.new) {
        // if we are creating, quietly update order and move to next step
        const link = 'add-resources';
        navigate(`../../${link}`);
      } else {
        showSuccess('Properties order updated');
      }
    } catch (err) {
      showUserFriendlyErrorToast(err, `Unable to save properties order.`, {
        dontAutoHide: true,
      });
    }
    setIsSubmitting(false);
  }, [
    mode,
    navigate,
    orderedItemIds,
    organizationId,
    resourceTypeId,
    showSuccess,
    showUserFriendlyErrorToast,
    updatePropertiesOrder,
  ]);

  const canSubmit = useCallback(() => {
    const isNewAndCanSubmit =
      mode === Mode.new && sortedResourceFields?.length > 0;
    const isExistingAndCanSubmit =
      mode === Mode.edit &&
      sortedResourceFields?.length > 0 &&
      hasBeenReordered;

    return isNewAndCanSubmit || isExistingAndCanSubmit;
  }, [hasBeenReordered, mode, sortedResourceFields?.length]);

  return (
    <Page>
      <EditResourceTypeHeader
        wizardStep={WizardStep.PROPERTIES}
        canSubmit={canSubmit()}
        isSubmitting={isSubmitting}
        onSubmit={reorderSubmit}
      />
      <StyledMain>
        <Content>
          <Title id="ResourcePropertiesList">Resource Type Properties</Title>
          <DescriptionStyled>
            These fields will be associated with every Resource you add to this
            Resource type.
          </DescriptionStyled>
          {isLoading && <Loading />}
          {!isLoading && !isError && resourceType && resourceProperties && (
            <ResourcePropertiesList
              isSiteSpecific={resourceType.isSiteSpecific}
              listLabelId="ResourcePropertiesList"
              sortedResourceFields={sortedResourceFields}
              updateResourceFieldOrder={updateResourceFieldOrder}
              onEdit={openModal}
              onDelete={handleDelete}
            />
          )}
          <Button
            color="primary"
            disabled={isLoading || isError}
            onClick={() => openModal()}
          >
            <AddCircleOutlineIcon />
            Add a property
          </Button>
        </Content>
      </StyledMain>
      <EditResourcePropertyModal
        onClose={setClosed}
        open={open}
        property={propertyToEdit}
      />
    </Page>
  );
}
