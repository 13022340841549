import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { ResourceTypeRefResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import { getCache as getResourceTypesCache } from './useResourceTypes';

type KeyParams = Api.FetchResourceTypeParams;
export const createKey = ({ organizationId, resourceTypeId }: KeyParams) =>
  ['org-resource-type', organizationId, resourceTypeId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchResourceType
>;

const apiFetcher: Fetcher = (_, organizationId, resourceTypeId) =>
  Api.fetchResourceType({ organizationId, resourceTypeId });

type UseResourceTypeParams = Partial<Api.FetchResourceTypeParams> & {
  config?: QueryConfig<ResourceTypeRefResponse, Api.ErrorResponse>;
};

export function useResourceType({
  config: inputConfig,
  organizationId,
  resourceTypeId,
}: UseResourceTypeParams) {
  const isEnabled = !!organizationId && !!resourceTypeId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createKey({ organizationId, resourceTypeId })
    : undefined;

  // only get the resourceTypes array if they already exist in the RQ cache
  const resourceTypes =
    organizationId && getResourceTypesCache({ organizationId });

  const fetcher: Fetcher = (...args) => {
    // if useResourcesTypes has already loaded for the org, we can retrieve the resource type we want from that array
    //  instead of going to the server for it
    if (resourceTypes) {
      const resourceTypeListResponse = resourceTypes.find(
        (rt) => rt.id === resourceTypeId,
      );
      if (resourceTypeListResponse) {
        // useResourceType returns a slightly different type from what's in the array from useResourceTypes
        // ResourceType type is just a subset of properties in the ResourceTypeListResponse type
        const { numberOfResources, ...resourceType } = resourceTypeListResponse;
        return Promise.resolve(resourceType);
      }
    }
    // only call our apiFetcher if we don't already have the resource type loaded in the cache
    return apiFetcher(...args);
  };

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    resourceType: data,
  };
}

type QueryCache = ReturnType<typeof useResourceType>['resourceType'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}
