import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateWorkflowCache,
  WorkflowCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/workflowCacheRegister';
import { setCache as setWorkflowCache } from '@monorepo/shared/hooks/workflows/useWorkflow';
import { MutationConfig, useMutation } from 'react-query';

type useWorkflowCreateParams = {
  config?: MutationConfig<Api.CreateWorkflowResponse, Api.ErrorResponse>;
};

export const useWorkflowCreate = (params?: useWorkflowCreateParams) =>
  useMutation(Api.createWorkflow, {
    ...params?.config,
    onSuccess: (createdWorkflow, mutationFuncParams) => {
      const { organizationId } = mutationFuncParams;
      const keyParams = {
        organizationId,
        workflowId: createdWorkflow.id,
      };

      // Set cache for that single Workflow
      setWorkflowCache(keyParams, createdWorkflow);

      // Invalidate any RQ cache that might show a list of existing Workflows
      invalidateWorkflowCache(WorkflowCacheEvents.CREATE, keyParams);

      return params?.config?.onSuccess?.(createdWorkflow, mutationFuncParams);
    },
  });
