import React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { RadioGroup } from '../RadioGroup';

type RadioGroupFieldProps = Omit<
  React.ComponentProps<typeof RadioGroup>,
  'value' | 'onChange' | 'error' | 'errorText'
> & {
  initialValue?: string;
  validate?: UseFieldConfig<string>['validate'];
};

export function RadioGroupField({
  initialValue,
  name,
  validate,
  ...rest
}: RadioGroupFieldProps) {
  // when this field is toggled, we get a console warning to add type: 'radio' to the useField config
  // see https://github.com/final-form/react-final-form/issues/392 for why we don't do this
  const { input, meta } = useField<string>(name, {
    validate,
    initialValue,
  });
  const hasError = !!meta.error && (meta.touched || meta.modified);
  return (
    <RadioGroup
      {...rest}
      value={input.value}
      name={name}
      onChange={input.onChange}
      error={hasError}
      errorText={hasError ? meta.error : undefined}
    />
  );
}
