import {
  EventContentArg,
  EventInput,
  EventSourceFuncArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { useSkipWidgetLink } from '@monorepo/shared/componentsV2/SkipWidgetLink';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useComplianceCalendarEvents } from '@monorepo/shared/hooks/complianceCalendar/useComplianceCalendarEvents';
import { useSubnavResizeListener } from '@monorepo/shared/hooks/subnav/useSubnavResizeListener';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { CalendarEventStatus, ComplianceCalendarEvent } from 'mapistry-shared';
import React, {
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CalendarEvent } from './ComplianceCalendarEventContent';
import {
  ComplianceCalendarHeader,
  getCalendarRange,
} from './ComplianceCalendarHeader';
import {
  convertEventToFullCalendarFormat,
  createLoadingEvents,
  exclusiveToInclusiveRange,
} from './complianceCalendarHelpers';
import { sortEventsWithinDay } from './sortComplianceCalendarEventsHelper';
import { FullCalendarCustomStyles, StyledSkeleton } from './styled';

const headerToolbar = { start: '', center: '', end: '' };
const plugins = [dayGridPlugin];

export function ComplianceCalendar() {
  const calendarRef: RefObject<FullCalendar> = useRef(null);
  const calendarApi = calendarRef?.current?.getApi();

  const { project } = useCurrentProject();
  const navigate = useNavigate();

  const [visibleRangeStart, setVisibleRangeStart] = useState<Date>();
  const [visibleRangeExclusiveEnd, setVisibleRangeExclusiveEnd] =
    useState<Date>();

  const resizeCalendarAfterNavFinishedResizing = useCallback(() => {
    calendarApi?.updateSize();
  }, [calendarApi]);

  useSubnavResizeListener(resizeCalendarAfterNavFinishedResizing);

  const { startDateString, endDateString } = exclusiveToInclusiveRange(
    visibleRangeStart,
    visibleRangeExclusiveEnd,
  );

  const { events: visibleEvents, isLoading } = useComplianceCalendarEvents({
    projectId: project?.id,
    startDate: startDateString,
    endDate: endDateString,
    config: { staleTime: 1000 * 30 },
  });

  const datesSet = useCallback((info: EventSourceFuncArg) => {
    const { start, end } = info;
    setVisibleRangeStart(start);
    setVisibleRangeExclusiveEnd(end);
  }, []);

  const handleEventClick = useCallback(
    (eventInfo) => {
      eventInfo.jsEvent.preventDefault(); // prevent hard refresh from the browser

      // backend returns https://app.mapistry.com/... stuff. React navigation only needs everything after the host name
      if (eventInfo.event.url) {
        const urlObject = new URL(eventInfo.event.url);
        const urlWithoutHost = urlObject.pathname + urlObject.search;
        navigate(urlWithoutHost, { replace: true });
      }
    },
    [navigate],
  );

  // eventDataTransform is incorrectly typed for our use case, even though our use case is officially supported
  // https://github.com/fullcalendar/fullcalendar/issues/5534#issuecomment-649816909
  const convertEventsAvoidingTypeProblems = useCallback(
    (backendEvent: EventInput) =>
      convertEventToFullCalendarFormat(backendEvent as ComplianceCalendarEvent),
    [],
  );

  const getEventContent = useCallback(
    (eventInfo: EventContentArg) =>
      eventInfo.event.extendedProps.isLoadingIndicator ? (
        <>
          <VisuallyHidden>Loading Event...</VisuallyHidden>
          <StyledSkeleton variant="rect" width="90%" />
        </>
      ) : (
        <CalendarEvent
          status={eventInfo.event.extendedProps.status as CalendarEventStatus}
          title={eventInfo.event.title}
        />
      ),
    [],
  );

  const calendarRange = useMemo(() => getCalendarRange(), []);

  const loadingEvents = useMemo(
    () => createLoadingEvents(visibleRangeStart, visibleRangeExclusiveEnd),
    [visibleRangeStart, visibleRangeExclusiveEnd],
  );

  const { SkipLink, SkipLinkTarget } = useSkipWidgetLink();

  return (
    <div>
      <SkipLink linkText="Skip calendar" />
      <ComplianceCalendarHeader
        calendarApi={calendarApi}
        noVisibleEvents={!isLoading && visibleEvents?.length === 0}
      />
      <FullCalendarCustomStyles>
        <FullCalendar
          eventOrder={sortEventsWithinDay}
          moreLinkClassNames={isLoading ? 'more-link-is-loading' : ''}
          datesSet={datesSet}
          dayMaxEvents // decides by itself when to show the +x more button. And without this, there is odd extra white space below events.
          defaultAllDay // prevents us from having to specify start and end time
          eventClick={handleEventClick}
          eventContent={getEventContent}
          eventDataTransform={convertEventsAvoidingTypeProblems}
          eventDisplay="list-item" // show events with a little dot instead of block
          events={isLoading ? loadingEvents : visibleEvents}
          headerToolbar={headerToolbar} // we're displaying custom ones instead
          initialView="dayGridMonth"
          lazyFetching={false} // let us manage the cache in react query manually instead of hooking into full calendar
          plugins={plugins}
          ref={calendarRef}
          showNonCurrentDates={false} // don't show the days from the previous and next month
          timeZone="UTC"
          validRange={calendarRange}
        />
      </FullCalendarCustomStyles>

      <SkipLinkTarget />
    </div>
  );
}
