import {
  TasksCacheEvents,
  tasksCacheRegister,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import {
  LogCacheEvents,
  logCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import {
  LogCalendarEventCacheEvents,
  logCalendarEventCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logCalendarEventCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { ComplianceCalendarEventResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';

type KeyParams = Api.FetchComplianceCalendarEventsParams;

// invalidate cache for all calendar date ranges for a given project (since
// with e.g. recurring tasks, they might affect multiple months)
type InvalidateCacheParams = { projectId?: KeyParams['projectId'] };

export const KEY_START = 'compliance-calendar-events';
export const createKey = ({ projectId, startDate, endDate }: KeyParams) =>
  [KEY_START, projectId, startDate, endDate] as const;

type UseComplianceCalendarEventsParams = {
  config?: QueryConfig<ComplianceCalendarEventResponse, Api.ErrorResponse>;
  projectId?: string;
  startDate?: string;
  endDate?: string;
};

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchComplianceCalendarEvents
>;

const fetcher: Fetcher = (_, projectId, startDate, endDate) =>
  Api.fetchComplianceCalendarEvents({
    projectId,
    startDate,
    endDate,
  });

export function useComplianceCalendarEvents({
  config: inputConfig,
  projectId,
  startDate,
  endDate,
}: UseComplianceCalendarEventsParams) {
  const isEnabled = !!projectId && !!startDate && !!endDate;
  const config = getQueryConfig<ComplianceCalendarEventResponse>(
    inputConfig,
    isEnabled,
  );

  const key = isEnabled
    ? createKey({ projectId, startDate, endDate })
    : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    events: data,
  };
}

async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  const key = [
    KEY_START,
    ...(keyParams.projectId ? [keyParams.projectId] : []),
  ];
  await queryCache.invalidateQueries(key);
}

const cacheRegisterInvalidator = {
  hookName: 'useComplianceCalendarEvents',
  callback: (invalidateKeyParams: InvalidateCacheParams) =>
    invalidateCache(invalidateKeyParams),
};

tasksCacheRegister(
  [TasksCacheEvents.UPSERT, TasksCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

logCacheRegister(
  [LogCacheEvents.UPDATE], // events show the log's name, so if this changes we need to reload
  {
    ...cacheRegisterInvalidator,
    /**
     * There is no overlap between InvalidateCacheParams and CacheParams
     * so we're calling the callback with an empty object to avoid a
     * TypeScript error
     */
    callback: () => cacheRegisterInvalidator.callback({}),
  },
);

logCalendarEventCacheRegister(
  [LogCalendarEventCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);
