import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useLogs } from '@monorepo/shared/hooks/logs/useLogs';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { ViewLogsEmptyPage } from './ViewLogsEmptyPage';
import { ViewLogsHeader } from './ViewLogsHeader';
import { ViewLogsTable } from './ViewLogsTable';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledViewLogsTable = styled(ViewLogsTable)`
  ${fillHeightAndScrollable}
`;

export function ViewLogs() {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error(
      "ViewLogs page was rendered with url that doesn't have organizationId",
    );
  }
  const navigate = useNavigate();
  const { logs, isLoading } = useLogs({ organizationId });
  const [filter, setFilter] = useState('');

  const handleAddNew = useCallback(() => {
    navigate('new');
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <ViewLogsHeader
        onAddNew={handleAddNew}
        onSearch={logs?.length ? setFilter : undefined}
        organizationId={organizationId}
      />
      {logs?.length ? (
        <StyledViewLogsTable filter={filter} />
      ) : (
        <ViewLogsEmptyPage
          onAddNew={handleAddNew}
          organizationId={organizationId}
        />
      )}
    </Page>
  );
}
