import { Button } from '@monorepo/shared/components';
import { UserFriendlyErrorMessage } from '@monorepo/shared/componentsV2/errorDisplays/UserFriendlyErrorMessage';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { InfoContainer } from '../../shared/DatasetPicker/styled';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

export const DatasetErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => (
  <InfoContainer>
    <UserFriendlyErrorMessage
      error={error}
      prependedText="Something went wrong with the data sources."
    />
    <StyledButton onClick={resetErrorBoundary} color="secondary">
      Reload data sources
    </StyledButton>
  </InfoContainer>
);
