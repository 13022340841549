import React, { useCallback, useMemo } from 'react';
import { CalendarName, UnitString } from 'mapistry-shared';
import Units from '../../utils/units';
import { Select } from '../elements';

const ALL_UNITS = Units.getAllForSuite(CalendarName.GENERIC_LOG);

type Value = UnitString;

type Option = {
  label: string;
  value: Value;
};

type AirEmissionUnitSelectProps = {
  baseUnit?: UnitString;
  error?: boolean;
  onChange: (value: Value) => void;
  value: Value;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GenericLogsUnitSelect(props: AirEmissionUnitSelectProps) {
  const { value, onChange, error, baseUnit } = props;
  const unitOptions = useMemo(
    () => (baseUnit ? Units.getRelatedUnits(baseUnit) : ALL_UNITS),
    [baseUnit],
  );
  const handleChange = useCallback(
    (opt: Option) => {
      onChange(opt.value);
    },
    [onChange],
  );
  return (
    <Select
      value={unitOptions.find((o) => o.value === value)}
      onChange={handleChange}
      error={error}
      options={unitOptions}
      isClearable={false}
      isFixed
    />
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsUnitSelect;
