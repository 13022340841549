import { Button } from '@monorepo/shared/componentsV2/Button';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { ModalFormInstructions } from '@monorepo/shared/componentsV2/modals/contents/ModalFormInstructions';
import { useLinkOnSameProject } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameProject';
import { useDatasets } from '@monorepo/shared/hooks/workflows/useDatasets';
import { DatasetPicker } from '@monorepo/workflowDataSetup/src/components/shared/DatasetPicker/DatasetPicker';
import { EmptyDatasetList } from '@monorepo/workflowDataSetup/src/components/shared/DatasetPicker/EmptyDatasetList';
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import {
  createSearchParams,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import styled from 'styled-components';

interface CreateNewViewModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

type FormValues = { datasetId: string };

// giving it some height so the dropdown doesn't look as weird when it gets opened
const ModalContent = styled.div`
  min-height: 15rem;
`;

function CreateViewDatasetPicker() {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error(
      "CreateViewDatasetPickerSection rendered on a page that doesn't have organizationId in the url.",
    );
  }

  const { datasets, isFetching } = useDatasets({
    organizationId,
  });

  if (isFetching) {
    return <Loading size="medium" />;
  }

  if (!datasets || !datasets.length) {
    return <EmptyDatasetList />;
  }

  return (
    <DatasetPicker
      label="Data source"
      datasets={datasets}
      fieldName="datasetId"
      required
    />
  );
}

export function CreateNewViewModal({
  closeModal,
  isOpen,
}: CreateNewViewModalProps) {
  const navigate = useNavigate();
  const linkToNewViewPage = useLinkOnSameProject('/logs/views/new');

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const selectedDatasetId = values.datasetId;
      const queryParams = createSearchParams({ originId: selectedDatasetId });
      const link = `${linkToNewViewPage}?${queryParams}`;
      navigate(link);
    },
    [linkToNewViewPage, navigate],
  );

  return (
    <Form onSubmit={onSubmit} subscription={{ pristine: true }}>
      {({ handleSubmit, pristine }) => (
        <BaseModal
          buttons={[
            <Button
              variant="text"
              color="primary"
              onClick={closeModal}
              key="cancel"
            >
              Cancel
            </Button>,
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={pristine}
              key="save"
            >
              Generate View
            </Button>,
          ]}
          onClose={closeModal}
          open={isOpen}
          showCloseButton
          title="Create new View"
          maxWidth="md"
        >
          <ModalContent>
            <ModalFormInstructions>
              Choose the central data source for your View. Data sources can be
              Log records, Resources, or Workflow results.
            </ModalFormInstructions>
            <CreateViewDatasetPicker />
          </ModalContent>
        </BaseModal>
      )}
    </Form>
  );
}
