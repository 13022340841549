function numberOfDecimals(x: number) {
  const splitString = `${x}`.split('.');
  return splitString[1] !== undefined ? splitString[1].length : 0;
}

interface Options {
  maximumSignificantDigits?: number;
  scientificNotationUpperLimit?: number;
  scientificNotationLowerLimit?: number;
  fixedFractionDigits?: number;
}

export function formatNumber(value: number, options?: Options): string {
  const {
    maximumSignificantDigits = 8,
    scientificNotationUpperLimit = 10 ** 9,
    scientificNotationLowerLimit = 10 ** -5,
    fixedFractionDigits,
  } = options || {};
  if (value == null) return '';
  if (value === 0) {
    return fixedFractionDigits
      ? value.toLocaleString(undefined, {
          minimumFractionDigits: fixedFractionDigits,
        })
      : '0';
  }
  if (
    Math.abs(value) >= scientificNotationLowerLimit &&
    Math.abs(value) < scientificNotationUpperLimit
  ) {
    const opts = fixedFractionDigits
      ? {
          minimumFractionDigits: fixedFractionDigits,
          maximumFractionDigits: fixedFractionDigits,
        }
      : {
          // avoid losing precision by specifying number of decimals.
          minimumFractionDigits: numberOfDecimals(value),
          maximumFractionDigits: numberOfDecimals(value),
          maximumSignificantDigits,
        };
    return value.toLocaleString(undefined, opts);
  }
  const roundedToSigDigits = Number(
    value.toPrecision(maximumSignificantDigits),
  );
  return roundedToSigDigits.toExponential().toString();
}
