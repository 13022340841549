import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { FieldWrapper } from '../elements';
import {
  ChartableGenericLogsResource,
  ResourceKey,
} from '../../types/ts-types';
import GenericLogsUnitSelect from './GenericLogsUnitSelect';

type AirEmissionUnitFieldProps = {
  allResources: ChartableGenericLogsResource[];
  label: string;
  name: string;
  showRelatedUnitsFor?: ResourceKey;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GenericLogsUnitField(props: AirEmissionUnitFieldProps) {
  const { name, label, allResources, showRelatedUnitsFor } = props;
  const resource = useMemo(
    () =>
      showRelatedUnitsFor
        ? // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          allResources.find(
            (r) => r.resourceId === showRelatedUnitsFor.resourceId,
          )!
        : undefined,
    [allResources, showRelatedUnitsFor],
  );
  return (
    <Field name={name}>
      {(field) => (
        <div className="field-wrapper">
          <FieldWrapper label={label}>
            <GenericLogsUnitSelect
              value={field.input.value}
              onChange={field.input.onChange}
              error={Boolean(field.meta.touched && field.meta.error)}
              baseUnit={resource?.entity.units}
            />
          </FieldWrapper>
        </div>
      )}
    </Field>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsUnitField;
