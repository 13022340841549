import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import { bodySmall } from '@monorepo/shared/styles/text';
import React from 'react';
import styled from 'styled-components';

const Card = styled(MuiCard)`
  background-color: ${({ theme }) => theme.colors.green08};
  box-shadow: none;
`;

const CardContent = styled(MuiCardContent)`
  ${bodySmall}
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.gray666};

  &:last-child {
    padding: 0.5rem;
  }
`;

const LightbulbContainer = styled.div`
  padding: 0 0.5rem 0 0;

  & svg {
    width: 1.375rem;
    fill: ${({ theme }) => theme.colors.green};
    transform: rotate(180deg);
  }
`;

type InfoCardProps = React.ComponentProps<typeof MuiCard> & {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactElement<any, any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: React.ReactElement<any, any>;
};

export function InfoCard({ children, icon, ...props }: InfoCardProps) {
  return (
    <Card {...props}>
      <CardContent>
        <LightbulbContainer>
          {icon || <WbIncandescentOutlinedIcon />}
        </LightbulbContainer>
        {children}
      </CardContent>
    </Card>
  );
}
