import { ViewType } from 'mapistry-shared';
import React, { useLayoutEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom-v5-compat';
import { SingleLogPageOutletContext } from '../../SingleLogPage';
import { EditViewPage } from './EditViewPage';

export function EditViewPageFromLogViews() {
  const { logId, organizationId, projectId, viewId } = useParams();
  if (!logId || !organizationId || !projectId) {
    throw new Error(
      "EditViewPageFromLogViews was rendered with a url that doesn't have logId, projectId or organizationId",
    );
  }

  const { hideHeader, hideTabs, showHeader, showTabs } =
    useOutletContext<SingleLogPageOutletContext>();

  useLayoutEffect(() => {
    hideHeader();
    hideTabs();

    return () => {
      showHeader();
      showTabs();
    };
  }, [hideHeader, hideTabs, showHeader, showTabs]);

  return (
    <EditViewPage
      organizationId={organizationId}
      originId={logId}
      projectId={projectId}
      typeForNewViews={ViewType.LOG}
      viewId={viewId}
    />
  );
}
