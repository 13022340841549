import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { page } from '@monorepo/shared/styles/layout';
import styled, { css } from 'styled-components';

const transitionMatchingMuiDrawerTransition = 'cubic-bezier(0, 0, 0.2, 1)';

// The MUI Drawer component variant "permanent" doesn't "squeeze" content to the
// side - so we are doing that ourselves with a margin-left
const addCollapseSubnavTransition = (existingTransition?: string) => css<{
  $isSubnavOpen: boolean;
  $isSubnavDisabled: boolean;
  $isSmoothTransitionPrevented: boolean;
}>`
  margin-left: ${({ $isSubnavOpen, $isSubnavDisabled, theme }) =>
    $isSubnavOpen && !$isSubnavDisabled ? theme.sizes.subnavWidth : 0};
  transition: ${({ $isSubnavDisabled, theme, $isSmoothTransitionPrevented }) =>
    $isSubnavDisabled || $isSmoothTransitionPrevented
      ? 'unset'
      : `margin-left ${theme.constants.subnavTransitionDuration}ms ${transitionMatchingMuiDrawerTransition}, ${existingTransition}`};
`;

// The Subnav Drawer itself has styling from MUI that makes it hard to
// affix elements to it (fixed position, hidden visibility when closed)
// so we're sliding the collapse button manually in sync with the rest
export const CollapseSubnavButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  z-index: ${({ theme }) => theme.constants.subnavZIndex};
  height: 50px;
  padding: 0 1px;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 0 3px 3px 0;
  opacity: 0.4;
  ${addCollapseSubnavTransition(
    'opacity 0.3s ease-in-out, padding 0.3s ease-in-out',
  )}
  transform: translateY(-50%);

  &:hover,
  &:focus-visible,
  &:not(.Mui-disabled):active,
  &:not(.Mui-disabled):focus-visible {
    padding: 0 9px;
    background: ${({ theme }) => theme.colors.blue};
    outline: none;
    opacity: 0.7;
  }

  &:not(.Mui-disabled):active {
    opacity: 0.9;
  }

  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const ContentNextToCollapsibleSubnav = styled.div`
  ${addCollapseSubnavTransition()}
  ${page}
`;
