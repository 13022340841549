import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogCache,
  LogCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import { setCache as setLogCache } from '@monorepo/shared/hooks/logs/useLog';
import {
  getCache as getLogsCache,
  setCache as setLogsCache,
} from '@monorepo/shared/hooks/logs/useLogs';
import { MutationConfig, useMutation } from 'react-query';

type UseLogUpdateParams = {
  config?: MutationConfig<Api.UpdateLogResponse, Api.ErrorResponse>;
};

export const useLogUpdate = (params?: UseLogUpdateParams) =>
  useMutation(Api.updateLog, {
    ...params?.config,
    onSuccess: (updatedLog, updateLogProps) => {
      const { organizationId } = updateLogProps;

      // Update list of logs
      const currentItems = getLogsCache({ organizationId });
      // If there is cache, update it
      if (currentItems) {
        const newItems = currentItems.map((item) =>
          item.id === updatedLog.id ? updatedLog : item,
        );
        setLogsCache({ organizationId }, newItems);
      }

      // Update single log
      setLogCache(
        {
          logId: updatedLog.id,
          organizationId,
        },
        updatedLog,
      );

      // Invalidate any RQ cache that might use information about Logs
      invalidateLogCache(LogCacheEvents.UPDATE, {
        logId: updatedLog.id,
        organizationId,
      });

      return params?.config?.onSuccess?.(updatedLog, updateLogProps);
    },
  });
