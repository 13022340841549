import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum ResourceTypeCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  organizationId: string;
  resourceTypeId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof ResourceTypeCacheEvents,
  CacheParams
>(ResourceTypeCacheEvents);

export const invalidateResourceTypeCache = cacheRegister.invalidate;
export const resourceTypeCacheRegister = cacheRegister.register;
