import { Api } from '@monorepo/shared/apiClient';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { QueryConfig, queryCache, useQuery } from 'react-query';
import {
  LogCacheEvents,
  logCacheRegister,
} from '../../cacheRegisters/data2/logCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '../../cacheRegisters/data2/resourceTypeCacheRegister';
import {
  WorkflowCacheEvents,
  workflowCacheRegister,
} from '../../cacheRegisters/data2/workflowCacheRegister';

type KeyParams = Api.FetchDatasetListParams;

export const createKey = ({ organizationId }: KeyParams) =>
  ['org-datasets', organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchDatasetList
>;

const fetcher: Fetcher = (_, organizationId) =>
  Api.fetchDatasetList({ organizationId });

type UseDatasetsParams = Partial<Api.FetchDatasetListParams> & {
  config?: QueryConfig<Api.FetchDatasetListResponse, Api.ErrorResponse>;
};

export function useDatasets({
  config: inputConfig,
  organizationId,
}: UseDatasetsParams) {
  const isEnabled = !!organizationId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ organizationId }) : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    datasets: data,
  };
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

const cacheRegisterInvalidator = {
  hookName: 'useDatasets',
  callback: (keyParams: KeyParams) => invalidateCache(keyParams),
};

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.UPDATE,
    ResourceTypeCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logCacheRegister(
  [LogCacheEvents.CREATE, LogCacheEvents.UPDATE, LogCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

workflowCacheRegister(
  [WorkflowCacheEvents.CREATE, WorkflowCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);
