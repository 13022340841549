import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { viewsProductGuideUrl } from '@monorepo/shared/externalLinks/productGuide';
import { focusOutline } from '@monorepo/shared/styles/focus';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import { EdpViewResponse, ViewType } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../../contexts/EditViewInfoModalContext';
import {
  QueryStepsProvider,
  useQuerySteps,
} from '../../../contexts/QueryStepsContext';
import { useQueryResults } from '../../../hooks/useQueryResults';
import { useView } from '../../../hooks/useView';
import { ViewResultsTable } from '../ViewResultsTable/ViewResultsTable';
import { EditViewHeader } from './EditViewHeader';
import { QueryStepsAccordionGroup } from './QueryStepsAccordionGroup';
import { QueryStepToolbar } from './QueryStepToolbar';

const Page = styled(Main)`
  ${verticalLayout}
  ${page}
`;

const Content = styled.div`
  ${fillHeightAndScrollable}

  &:focus-visible {
    ${focusOutline({ $outlineOffset: '-2px' })}
  }
`;

const StyledQuerySteps = styled(QueryStepsAccordionGroup)`
  padding-bottom: 3rem;
`;

const StyledEmptyState = styled(EmptyState)`
  margin-bottom: calc(75px - 1rem);
`;

const Header2 = styled.h2`
  ${header4}
  padding: 1rem 1.5rem;
  margin: 0;
`;

const StyledViewResultsTable = styled(ViewResultsTable)`
  height: auto;
`;

const buttonConfig = {
  label: 'Learn about Views',
  onClick: () => window.open(viewsProductGuideUrl, '_blank'),
};

interface EditViewPageContentProps {
  organizationId: string;
  originId: string;
  projectId: string;
  typeForNewViews: ViewType;
  view: EdpViewResponse | undefined;
}

function EditViewPageContent({
  organizationId,
  originId: passedInOriginId,
  projectId,
  view,
  typeForNewViews,
}: EditViewPageContentProps) {
  const { getInProgressQuerySteps, validQuerySteps } = useQuerySteps();

  const originId = view?.originId || passedInOriginId;
  const updatedView = useMemo(
    () => ({ ...view, querySteps: validQuerySteps }),
    [validQuerySteps, view],
  );

  const {
    isIdle: queryIsIdle,
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
    queryResult,
  } = useQueryResults({
    originId,
    organizationId,
    projectId,
    query: { steps: validQuerySteps || [] },
    config: { enabled: !!validQuerySteps },
  });

  const isLoading = queryIsFetching || queryIsLoading || queryIsIdle;

  return (
    <ErrorBoundary>
      <EditViewInfoModalProvider>
        <Page>
          <EditViewHeader
            view={updatedView}
            originId={originId}
            typeForNewViews={typeForNewViews}
          />
          <Content>
            {getInProgressQuerySteps().length ? (
              <>
                <StyledQuerySteps />
                <QueryStepToolbar />
              </>
            ) : (
              <>
                <QueryStepToolbar />
                <StyledEmptyState
                  actionButtonConfigs={buttonConfig}
                  primaryText="Add a query step above to begin transforming your data."
                  secondaryText="Adding query steps will allow you to save relevant views of your data so you can more easily understand the health of your requirements."
                />
              </>
            )}
            <Header2>Data Preview</Header2>
            <StyledViewResultsTable
              isLoading={isLoading}
              queryResult={queryResult}
            />
          </Content>
        </Page>
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}

type EditViewPageProps = {
  organizationId: string;
  projectId: string;
  typeForNewViews: ViewType;
} & ( // for new views, need to define originId, for existing views, can leave it out
  | { viewId: string; originId: string | undefined }
  | { viewId: undefined; originId: string }
);

export function EditViewPage({
  organizationId,
  originId: passedInOriginId,
  projectId,
  typeForNewViews,
  viewId,
}: EditViewPageProps) {
  const { view, isLoading } = useView({ organizationId, projectId, viewId });

  if (isLoading) {
    return <Loading />;
  }

  const originId = view?.originId || passedInOriginId;
  if (!originId) {
    throw new Error(
      'Either we need to be editing an existing view, or the originId needs to be defined in the url.',
    );
  }

  return (
    <QueryStepsProvider
      originId={originId}
      organizationId={organizationId}
      projectId={projectId}
      view={view}
    >
      <EditViewPageContent
        view={view}
        typeForNewViews={typeForNewViews}
        organizationId={organizationId}
        projectId={projectId}
        originId={originId}
      />
    </QueryStepsProvider>
  );
}
