import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom-v5-compat';
import { useLogsAndViewsPageContext } from '../../contexts/LogsAndViewsPageContext';

export function SingleViewPage() {
  const { setIsSearchVisible } = useLogsAndViewsPageContext();

  useEffect(() => setIsSearchVisible(false), [setIsSearchVisible]);

  return <Outlet />;
}
