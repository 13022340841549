import {
  LogFrequencyRequirementCacheEvents,
  logFrequencyRequirementCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFrequencyRequirementCacheRegister';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../../apiClient';

type KeyParams = Api.FetchFrequencyRequirementsParams;

export const createKey = ({ organizationId, logId, projectId }: KeyParams) =>
  ['logFrequencyRequirements', organizationId, logId, projectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchFrequencyRequirements
>;

const fetcher: Fetcher = (_, organizationId, logId, projectId) =>
  Api.fetchFrequencyRequirements({ organizationId, logId, projectId });

type UseFrequencyRequirementsParams =
  Partial<Api.FetchFrequencyRequirementsParams> & {
    config?: QueryConfig<
      Api.FetchFrequencyRequirementsResponse,
      Api.ErrorResponse
    >;
  };

export function useFrequencyRequirements({
  config: inputConfig,
  organizationId,
  logId,
  projectId,
}: UseFrequencyRequirementsParams) {
  const isEnabled = !!organizationId && !!logId && !!projectId;
  const config = getQueryConfig<Api.FetchFrequencyRequirementsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createKey({ organizationId, logId, projectId })
    : undefined;

  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    frequencyRequirements: data,
  };
}

type QueryCache = ReturnType<
  typeof useFrequencyRequirements
>['frequencyRequirements'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}

logFrequencyRequirementCacheRegister(
  [
    LogFrequencyRequirementCacheEvents.CREATE,
    LogFrequencyRequirementCacheEvents.UPDATE,
    LogFrequencyRequirementCacheEvents.DELETE,
  ],
  {
    hookName: 'useFrequencyRequirements',
    callback: (keyParams: KeyParams) => invalidateCache(keyParams),
  },
);
