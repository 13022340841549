import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';
import { UseGenericLogDataInputProps } from './types';

export const createQueryKey = ({
  logProjectId,
  projectId,
}: Api.FetchLoggedItemsProps) =>
  ['LOGGED_ITEMS', projectId, logProjectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLoggedItems
>;

const fetcher: Fetcher = (
  _: string,
  projectId?: string,
  logProjectId?: string,
) => Api.fetchLoggedItems({ logProjectId, projectId });

export const useLoggedItems = ({
  config: inputConfig,
  logProjectId,
  projectId,
}: UseGenericLogDataInputProps<
  Api.FetchLoggedItemsProps,
  Api.LoggedItem[]
>) => {
  const key = createQueryKey({ logProjectId, projectId });
  const config = {
    ...inputConfig,
    enabled: logProjectId && projectId,
  };
  const { data: loggedItems, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    loggedItems,
    ...queryInfo,
  };
};
