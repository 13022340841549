import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { Switch } from '@monorepo/shared/componentsV2/Switch';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import React, { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import styled from 'styled-components';
import { ModalFieldWrapper } from '../../styled';

type ChooseDefaultOptionFieldProps = {
  defaultValueOptions: SelectableOption<string>[];
};

const NO_DEFAULT_VALUE = 'Unselected';

const ModalSelectField = styled(SelectField)`
  width: 50rem;
`;

const hasValidDefaultOption = (test: string | undefined | null) => {
  if (test?.length && test !== NO_DEFAULT_VALUE) return true;
  return false;
};

export const ChooseDefaultOptionField = (
  props: ChooseDefaultOptionFieldProps,
) => {
  const { defaultValueOptions } = props;
  const form = useForm();
  const {
    meta: { initial: initialValue },
  } = useField('defaultOption', {
    subscription: { initial: true },
  });

  const {
    input: { value: optionsValue },
  } = useField('options', {
    subscription: { value: true },
  });

  const [hasDefaultOption, setHasDefaultOption] = useState(
    hasValidDefaultOption(initialValue),
  );

  const changeValue = useCallback(
    (value) => {
      const fieldState = form.getFieldState('defaultOption');
      if (fieldState) fieldState.change(value);
    },
    [form],
  );

  /* 
    when we delete or change an option that is set as default,
    we need this explicit re-update - otherwise, we don't reset the defaultOption,
    and it retains the previously set value
  */
  useEffect(() => {
    if (!optionsValue.includes(initialValue)) {
      setHasDefaultOption(false);
      changeValue(NO_DEFAULT_VALUE);
    }
  }, [optionsValue, changeValue, form, initialValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isSet = event.currentTarget.checked;
      setHasDefaultOption(isSet);
      const currentValue = isSet ? initialValue : NO_DEFAULT_VALUE;
      changeValue(currentValue);
    },
    [initialValue, changeValue],
  );

  return (
    <>
      <ModalFieldWrapper>
        <Switch
          label=""
          uncheckedLabel="Assign default option"
          checkedLabel="Assign default option"
          name="hasDefaultOption"
          checked={hasDefaultOption}
          onChange={handleChange}
        />
      </ModalFieldWrapper>

      {hasDefaultOption && (
        <ModalFieldWrapper>
          <ModalSelectField
            label="Choose default option"
            name="defaultOption"
            options={defaultValueOptions}
            validate={isRequired}
            required
            tooltipText="When users complete this form, this field will be filled out using this default when the user first opens the form."
          />
        </ModalFieldWrapper>
      )}
    </>
  );
};
