import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum ResourcePropertyCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  organizationId: string;
  resourcePropertyId: string;
  resourceTypeId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof ResourcePropertyCacheEvents,
  CacheParams
>(ResourcePropertyCacheEvents);

export const invalidateResourcePropertyCache = cacheRegister.invalidate;
export const resourcePropertyCacheRegister = cacheRegister.register;
