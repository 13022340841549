import { FieldDataTypeWizardModal } from '@monorepo/shared/componentsV2/fieldDataType/wizard/FieldDataTypeWizardModal';
import * as Sentry from '@sentry/browser';
import {
  CreateFieldRequest,
  FieldResponse,
  LogFieldType,
  NonEmptyArray,
  UpdateFieldRequest,
} from 'mapistry-shared';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useLogFieldCreate } from '../../hooks/useLogFieldCreate';
import { useLogFieldUpdate } from '../../hooks/useLogFieldUpdate';

const availableFieldTypes: NonEmptyArray<LogFieldType> = [
  LogFieldType.TEXT,
  LogFieldType.SINGLE_SELECT,
  LogFieldType.NUMERIC,
  LogFieldType.DATE,
  LogFieldType.USER,
  LogFieldType.TIME,
  LogFieldType.BOOLEAN,
  LogFieldType.DATETIME,
  LogFieldType.RESOURCE,
];

interface EditLogFieldModalProps {
  field?: FieldResponse;
  onClose: () => void;
  open: boolean;
}

const isUpdateRequest = (
  x: CreateFieldRequest | UpdateFieldRequest,
): x is UpdateFieldRequest => 'id' in x && x.id != null;

const isCreateRequest = (
  x: CreateFieldRequest | UpdateFieldRequest,
): x is CreateFieldRequest => !isUpdateRequest(x);

export function EditLogFieldModal({
  field,
  onClose,
  open,
}: EditLogFieldModalProps) {
  const { logId, organizationId } = useParams();
  const [creator] = useLogFieldCreate({ config: { throwOnError: true } });
  const [updater] = useLogFieldUpdate({ config: { throwOnError: true } });

  const onSubmit = useCallback(
    async (requestDto: CreateFieldRequest | UpdateFieldRequest) => {
      if (!organizationId || !logId) {
        const errMsg = `Couldn't create Log field: This page doesn't have organization id or a Log id`;
        Sentry.captureException(errMsg);
        throw new Error(errMsg);
      }

      if (isUpdateRequest(requestDto)) {
        await updater({
          logField: requestDto,
          logId,
          organizationId,
        });
      }

      if (isCreateRequest(requestDto)) {
        await creator({
          logField: requestDto,
          logId,
          organizationId,
        });
      }
    },
    [creator, logId, organizationId, updater],
  );

  if (!open) {
    return null;
  }

  return (
    <FieldDataTypeWizardModal
      availableFieldTypes={availableFieldTypes}
      field={field}
      isRequiredHelpText="This field will be required every time any data is submitted to this Log."
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}
