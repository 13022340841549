import { SheetConfig } from '@flatfile/api/api';
import { Api } from '@monorepo/shared/apiClient';
import { SelectOptionsByFieldType } from '@monorepo/shared/apiClient/logs/fieldOptionsForUpload';
import {
  LogEntryCacheEvents,
  invalidateLogEntryCache,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  getDateColumnSetting,
  getTimeColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { parseRecordToFieldValueRequest } from '@monorepo/shared/components/InteractiveFileUpload/helpers/edpFields/parseRecordToFieldValueRequest';
import { validateFields } from '@monorepo/shared/components/InteractiveFileUpload/helpers/edpFields/validateFields';
import { uniqueConstraintSetting } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileSettingsHelpers';
import { validateDateTimeFields } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useLogFieldOptionsForUpload } from '@monorepo/shared/hooks/logs/useLogFieldOptionsForUpload';
import { useLogInfo } from '@monorepo/shared/hooks/logs/useLogInfo';
import {
  LogInfoResponse,
  SaveLogEntryRequest,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { getColumnsForFields } from '../../components/InteractiveFileUpload/helpers/edpFields/getColumnsForFields';

enum uploadFieldKeys {
  logDate = 'logDate',
  logTime = 'logTime',
}

type LogUploadRecord = {
  [uploadFieldKeys.logDate]: string;
  [uploadFieldKeys.logTime]: string;
  [fieldId: string]: string | number | boolean | null | undefined;
};

type LogEntriesUploadModal = {
  logId: string;
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
  projectId: string;
};

const getColumns = (
  logInfo: LogInfoResponse,
  logFieldOptionsForUpload: SelectOptionsByFieldType,
): SheetConfig['fields'] => [
  getDateColumnSetting({
    label: 'Log date',
    key: uploadFieldKeys.logDate,
    isRequired: true,
  }),
  getTimeColumnSetting({
    label: 'Log time',
    key: uploadFieldKeys.logTime,
  }),
  ...getColumnsForFields(logInfo.log.fields, logFieldOptionsForUpload, {
    nothingIsRequired: true,
  }),
];

function parseResultsToSaveLogEntriesRequest(
  results: LogUploadRecord[],
  logInfo: LogInfoResponse | undefined,
): SaveLogEntryRequest[] {
  return results.map((result) => {
    const dateString = result[uploadFieldKeys.logDate];
    const timeString = result[uploadFieldKeys.logTime];
    const logEntry: SaveLogEntryRequest = {
      logDate: UTCEquivalentOfLocal(`${dateString} ${timeString}`),
      logFieldValues: parseRecordToFieldValueRequest(
        logInfo?.log.fields || [],
        result,
      ),
    };
    return logEntry;
  });
}

export function LogEntriesUploadModal({
  logId,
  isOpen,
  onClose,
  organizationId,
  projectId,
}: LogEntriesUploadModal) {
  const {
    error: logInfoLoadError,
    isStale: isLogInfoStale,
    isFetching: isLogInfoFetching,
    logInfo,
  } = useLogInfo({
    config: { enabled: isOpen, useErrorBoundary: false },
    logId,
    organizationId,
  });
  const {
    error: optionsLoadError,
    isLoading: isSelectOptionsLoading,
    logFieldOptionsForUpload,
  } = useLogFieldOptionsForUpload({
    config: { enabled: isOpen, useErrorBoundary: false },
    logInfo,
    projectId,
  });

  const isLoading =
    isLogInfoStale || isLogInfoFetching || isSelectOptionsLoading;
  const loadingError = logInfoLoadError || optionsLoadError;

  const workbook = useMemo<WorkbookConfig>(() => {
    const fields: SheetConfig['fields'] =
      isOpen && logInfo && logFieldOptionsForUpload
        ? getColumns(logInfo, logFieldOptionsForUpload)
        : [];

    const workbookConfig: WorkbookConfig = {
      name: 'Upload Log Data ',
      sheets: [
        {
          name: 'Log Data',
          slug: 'upload-log-entries',
          fields,
          constraints: [
            uniqueConstraintSetting(
              'Log Entry across all fields',
              fields.map((field) => field.key),
            ),
          ],
        },
      ],
    };

    return workbookConfig;
  }, [isOpen, logFieldOptionsForUpload, logInfo]);

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(
    async (record) => {
      validateDateTimeFields(
        uploadFieldKeys.logDate,
        uploadFieldKeys.logTime,
        record,
        {
          canBeInTheFuture: true,
        },
      );

      validateFields(logInfo?.log.fields || [], record);
    },
    [logInfo?.log.fields],
  );

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: LogUploadRecord[]) => {
      const logEntries = parseResultsToSaveLogEntriesRequest(results, logInfo);

      await Api.uploadLogEntries({
        logEntries,
        logId,
        organizationId,
        projectId,
      });

      invalidateLogEntryCache(LogEntryCacheEvents.CREATE, {
        organizationId,
        logId,
        projectId,
      });

      const successCount = logEntries.length;
      const noun = successCount === 1 ? 'Log Entry' : 'Log Entries';
      return `Successfully uploaded ${logEntries.length} ${noun}! The compliance calendar may take a few minutes to reflect these changes.`;
    },
    [logId, logInfo, organizationId, projectId],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ organizationId, projectId, logId }),
    [logId, organizationId, projectId],
  );

  return (
    <InteractiveFileUploadModal
      loadingError={loadingError}
      isLoading={isLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      recordHookCallback={recordHookCallback}
      showTableForManualInput
      workbook={workbook}
    />
  );
}
