import { initCacheInvalidatorRegister } from '../cacheRegisterConstructor';

export enum ResourceCacheEvents {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

interface CacheParams {
  organizationId: string;
  resourceId: string;
  resourceTypeId: string;
}

const cacheRegister = initCacheInvalidatorRegister<
  typeof ResourceCacheEvents,
  CacheParams
>(ResourceCacheEvents);

export const invalidateResourceCache = cacheRegister.invalidate;
export const resourceCacheRegister = cacheRegister.register;
