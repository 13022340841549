import { useContext } from 'react';
import { EditViewInfoModalContext } from '../contexts/EditViewInfoModalContext';

export const useEditViewInfoModal = () => {
  const context = useContext(EditViewInfoModalContext);

  if (context === undefined) {
    throw new Error(
      'useEditViewInfoModal must be used within a EditViewInfoModalProvider',
    );
  }
  return context;
};
