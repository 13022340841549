import { useContext } from 'react';
import { SubnavContext } from '../../contexts/SubnavContext';

export const useSubnav = () => {
  const context = useContext(SubnavContext);

  if (context === undefined) {
    throw new Error('useSubnav must be used within a SubnavProvider');
  }
  return context;
};
