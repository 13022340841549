import { isSameDay } from 'date-fns';
import _isEqual from 'lodash.isequal';
import _uniq from 'lodash.uniq';
import { localEquivalentOfUTC } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ModalContext as ModalContextV2 } from '@monorepo/shared/contexts/ModalContextV2/ModalContext';
import { SaveState } from '@monorepo/shared/types/SaveState';
import APP from '../../../config';
import { delayedModalClose, UTCEquivalentOfLocal } from '../../../utils';
import { ProjectTaskType } from '../../propTypes';
import TasksValidator from '../../views/formSubmissionEditor/validations/TasksValidator';
import RecurringTaskSaveModal from '../RecurringTaskSaveModal';
import TaskEditModal from './TaskEditModal';

const validationErrorMessage =
  'The task cannot be updated. Please review the form and address each issue';

class TaskEditModalContainer extends Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    changedFields: [],
    errorMessage: null,
    originalTask: null,
    isSaveDisabled: false,
    saveModalOpen: false,
    saveState: SaveState.CLEAN,
    task: null,
    validationError: null,
  };

  validator = new TasksValidator();

  componentDidUpdate(prevProps) {
    const { isDeleting, isSaving, serverErrorMessage, taskWithSubtasks } =
      this.props;
    const { saveState, task } = this.state;

    APP.isNavigationBlocked = saveState === SaveState.DIRTY;

    if (!task && taskWithSubtasks) {
      if (taskWithSubtasks.isRecurring) {
        this.setState({
          originalTask: taskWithSubtasks,
          task: {
            ...taskWithSubtasks,
            newFrequency: taskWithSubtasks.frequency,
            newCustomFrequency: taskWithSubtasks.customFrequency,
            newEndDate: taskWithSubtasks.endDate,
          },
        });
      } else {
        this.setState({ task: { ...taskWithSubtasks } });
      }
    }

    if (saveState === SaveState.SAVING && prevProps.isSaving && !isSaving) {
      if (serverErrorMessage) {
        this.setState({ saveState: SaveState.DIRTY });
      } else {
        this.setState({ saveState: SaveState.SAVED }, () => {
          delayedModalClose(this.handleClose);
        });
      }
    }

    if (prevProps.isDeleting && !isDeleting && !serverErrorMessage) {
      this.handleClose();
    }
  }

  handleClose = async () => {
    const { saveState } = this.state;
    const { confirm } = this.context;
    if (saveState === SaveState.DIRTY) {
      const shouldNavigateAnyways = await confirm({
        title: 'Are you sure you want to exit without saving?',
        description:
          "Any updates you've entered since you last saved will be lost if you proceed",
        confirmButtonText: 'Exit without saving',
      });
      if (!shouldNavigateAnyways) return;
    }

    const { onClose } = this.props;
    this.setState({ saveState: SaveState.CLEAN }, () => {
      onClose();
      APP.isNavigationBlocked = false;
    });
  };

  handleChange = (newTask, newErrors) => {
    this.setState({
      saveState: SaveState.DIRTY,
      task: newTask,
      validationError: newErrors,
    });
  };

  handleComplete = () => {
    const hasError = this.validate();
    if (hasError) {
      return;
    }

    const { task } = this.state;
    const today = UTCEquivalentOfLocal(new Date());
    const completedDate = task.markedAsCompletedDate ? null : today;

    const newTask = {
      ...task,
      completedDate,
      markedAsCompletedDate: completedDate,
    };
    this.setState({
      saveState: SaveState.DIRTY,
      task: newTask,
    });
  };

  handleSave = () => {
    const hasError = this.validate();
    if (hasError) {
      return;
    }

    const { taskId } = this.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    const { isRecurring } = this.state.task;

    if (!taskId || !isRecurring) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      this.saveTask(this.state.task);
      return;
    }

    const { taskFields, updatedTask } = this.getUpdatedRecurringTask();

    const {
      allChangedFields,
      completedChanged,
      frequencyChanged,
      isLastTask,
      subTasksChanged,
      subTasksCompletedChanged,
      taskChanged,
    } = this.compareTaskToOriginal(updatedTask);

    if (frequencyChanged) {
      this.saveTask(updatedTask);
    } else if (
      (!taskChanged && !subTasksChanged) ||
      isLastTask ||
      completedChanged ||
      subTasksCompletedChanged
    ) {
      this.saveTask(taskFields);
    } else {
      this.setState({ saveModalOpen: true, changedFields: allChangedFields });
    }
  };

  handleCancelSave = () => {
    this.setState({ saveModalOpen: false });
  };

  handleModalSave = (updateAllFutureTasks) => {
    const { task } = this.state;
    const { subTasks, ...taskFields } = task;

    const updatedTask = {
      ...taskFields,
      updateAllFutureTasks,
      subTasks: subTasks.map((st) => ({ ...st, updateAllFutureTasks })),
    };

    this.saveTask(updatedTask);
  };

  handleDelete = (taskToDelete) => {
    const { deleteTask } = this.props;
    this.setState(
      {
        saveState: SaveState.CLEAN,
      },
      () => deleteTask(taskToDelete),
    );
  };

  handleError = (errorMessage) => {
    this.setState({ errorMessage });
  };

  handleGoToForm = () => {
    const { task } = this.state;
    const {
      formSubmissionCalendarName,
      formSubmissionGroupId,
      formSubmissionSlug,
      formTemplateSlug,
      projectId,
    } = task;
    if (!formSubmissionGroupId || !formSubmissionSlug || !formTemplateSlug) {
      return;
    }

    this.goToFormSubmissionEditorGroup(
      projectId,
      formTemplateSlug,
      formSubmissionSlug,
      formSubmissionGroupId,
      formSubmissionCalendarName,
    );
  };

  // eslint-disable-next-line react/sort-comp
  goToFormSubmissionEditorGroup(
    projectId,
    templateSlug,
    submissionSlug,
    groupId,
    calendarName,
  ) {
    const url = `/projects/${projectId}/form-submissions/${templateSlug}/${submissionSlug}/${calendarName}/groups/${groupId}`;
    window.location.href = url;
  }

  handleSetSaveDisabled = (enabled) => {
    this.setState({ isSaveDisabled: enabled });
  };

  getUpdatedRecurringTask() {
    const { task } = this.state;

    const {
      newFrequency,
      newCustomFrequency,
      newStartDate,
      newEndDate,
      ...taskFields
    } = task;

    return {
      taskFields,
      updatedTask: {
        ...taskFields,
        frequency: newFrequency,
        customFrequency: newCustomFrequency ?? null,
        startDate: newStartDate,
        endDate: newEndDate,
      },
    };
  }

  saveTask(task) {
    const { projectId, saveTask } = this.props;

    this.setState(
      { saveModalOpen: false, errorMessage: null, saveState: SaveState.SAVING },
      () => {
        saveTask(projectId, task);
      },
    );
  }

  compareTaskToOriginal(updatedTask) {
    const { originalTask } = this.state;

    const frequencyFields = ['frequency', 'customFrequency', 'endDate'];
    const frequencyChanged = frequencyFields.some(
      (key) => !_isEqual(updatedTask[key], originalTask[key]),
    );

    const completedChanged =
      updatedTask.completedDate !== originalTask.completedDate;
    const subTasksCompletedChanged = updatedTask.subTasks.some((st) => {
      const originalSubTask = originalTask.subTasks.find((t) => t.id === st.id);
      return (
        originalSubTask && st.completedDate !== originalSubTask.completedDate
      );
    });

    const fields = ['title', 'description', 'assignees', 'notes'];
    const changedFields = fields.filter(
      (field) => !_isEqual(updatedTask[field], originalTask[field]),
    );
    if (updatedTask.attachmentsChanged) {
      changedFields.push('attachments');
    }

    const subTaskChangedFieldsMap = updatedTask.subTasks.reduce(
      (changed, st) => {
        const originalSubTask = originalTask.subTasks.find(
          (t) => t.id === st.id,
        );
        if (st.deleted || !originalSubTask) {
          // eslint-disable-next-line no-param-reassign
          changed['sub task'] = true;
          return changed;
        }
        fields.forEach((field) => {
          if (!_isEqual(st[field], originalSubTask[field])) {
            // eslint-disable-next-line no-param-reassign
            changed[field] = true;
          }
        });
        if (st.attachmentsChanged) {
          // eslint-disable-next-line no-param-reassign
          changed.attachments = true;
        }
        return changed;
      },
      {},
    );
    const subTasksChangedFields = Object.keys(subTaskChangedFieldsMap);

    const isLastTask =
      updatedTask.endDate !== null &&
      isSameDay(
        localEquivalentOfUTC(updatedTask.dueDate),
        localEquivalentOfUTC(updatedTask.endDate),
      );

    return {
      allChangedFields: _uniq(changedFields.concat(subTasksChangedFields)),
      completedChanged,
      frequencyChanged,
      isLastTask,
      subTasksChanged: subTasksChangedFields.length > 0,
      subTasksCompletedChanged,
      taskChanged: changedFields.length > 0,
    };
  }

  validate() {
    const { taskId } = this.props;
    const { task } = this.state;
    const { isRecurring, newFrequency, frequency } = task;

    let updatedTask = task;
    if (taskId && isRecurring && newFrequency !== frequency) {
      ({ updatedTask } = this.getUpdatedRecurringTask());
    }

    const validationError = this.validator.validateTask(null, updatedTask);
    this.setState({
      validationError,
      errorMessage: validationError && validationErrorMessage,
    });
    return !!validationError;
  }

  render() {
    const { isLoading, isOpen, projectId, serverErrorMessage, taskId } =
      this.props;
    const {
      changedFields,
      errorMessage,
      isSaveDisabled,
      saveModalOpen,
      saveState,
      task,
      validationError,
    } = this.state;

    return (
      <>
        <TaskEditModal
          addingTask={!taskId}
          error={errorMessage || serverErrorMessage}
          isLoading={isLoading}
          isOpen={isOpen}
          isSaveDisabled={isSaveDisabled}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onComplete={this.handleComplete}
          onDelete={this.handleDelete}
          onError={this.handleError}
          onOpenInForm={this.handleGoToForm}
          onSave={this.handleSave}
          onSetSaveDisabled={this.handleSetSaveDisabled}
          projectId={projectId}
          saveState={saveState}
          task={task}
          validationError={validationError}
        />
        <RecurringTaskSaveModal
          fields={changedFields}
          isOpen={saveModalOpen}
          onAction={this.handleModalSave}
          onClose={this.handleCancelSave}
        />
      </>
    );
  }
}

TaskEditModalContainer.contextType = ModalContextV2;

TaskEditModalContainer.propTypes = {
  deleteTask: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  saveTask: PropTypes.func.isRequired,
  serverErrorMessage: PropTypes.string,
  taskId: PropTypes.string,
  taskWithSubtasks: ProjectTaskType,
};

TaskEditModalContainer.defaultProps = {
  serverErrorMessage: null,
  taskId: null,
  taskWithSubtasks: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TaskEditModalContainer;
