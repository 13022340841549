import { RadioGroupField } from '@monorepo/shared/componentsV2/fields/RadioGroupField';
import { TimePickerField } from '@monorepo/shared/componentsV2/fields/TimePickerField';
import { StyledInput } from '@monorepo/shared/componentsV2/styled/form';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import {
  BooleanValue,
  DatetimeValue,
  DateValue,
  FieldDataType,
  FieldResponse,
  NullableBoolean,
  NumericFieldResponse,
  NumericValue,
  ResourceFieldResponse,
  ResourceValue,
  SaveFieldValueRequest,
  SingleSelectFieldResponse,
  SingleSelectValue,
  SpecificFieldValue,
  TextValue,
  TimeValue,
  UserValue,
} from 'mapistry-shared';
import React from 'react';
import {
  EditDateLikeValueFactory,
  getDateLikeFieldValueFromForm,
} from './EditDateLikeValueFactory';
import {
  EditNumericFieldValue,
  getNumericFieldValueFromForm,
} from './EditNumericFieldValue';
import {
  EditResourceFieldValue,
  getResourceFieldValueFromForm,
} from './EditResourceFieldValue';
import { EditSingleSelectFieldValue } from './EditSingleSelectFieldValue';
import {
  EditUserFieldValue,
  getUserFieldValueFromForm,
} from './EditUserFieldValue';
import { FormValues, isNullableString } from './types';
import {
  getUnexpectedTypeErrorAndLogToSentry,
  getValidateFunction,
  nullToUndefined,
} from './utils';

type EditFieldValueFactoryProps = {
  className?: string;
  field: FieldResponse;
  fieldValue?: SpecificFieldValue;
  isRequiredOverride?: boolean;
  labelOverride?: string;
  organizationId: string;
  nameOverride?: string;
  projectId: string | undefined;
};

const booleanRadioOptions: SelectableOption[] = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export function EditFieldValueFactory({
  className,
  field,
  fieldValue,
  isRequiredOverride,
  labelOverride,
  nameOverride,
  organizationId,
  projectId,
}: EditFieldValueFactoryProps) {
  const label = labelOverride == null ? field.name : labelOverride;
  const isRequired =
    isRequiredOverride == null ? field.isRequired : isRequiredOverride;
  const validate = getValidateFunction(isRequired);
  const name = nameOverride == null ? field.id : nameOverride;

  switch (field.type) {
    case FieldDataType.TEXT: {
      const textFieldValue = fieldValue as TextValue | undefined;
      return (
        <StyledInput
          className={className}
          label={label}
          name={name}
          initialValue={nullToUndefined(textFieldValue?.value)}
          validate={validate}
          required={isRequired}
        />
      );
    }
    case FieldDataType.NUMERIC:
      return (
        <EditNumericFieldValue
          className={className}
          field={field as NumericFieldResponse}
          fieldValue={fieldValue as NumericValue | undefined}
          isRequired={isRequired}
          label={label}
          name={name}
        />
      );
    case FieldDataType.BOOLEAN: {
      const booleanFieldValue = fieldValue as BooleanValue | undefined;
      const initialValue =
        booleanFieldValue?.value == null
          ? undefined
          : booleanFieldValue.value.toString();
      return (
        <RadioGroupField
          className={className}
          initialValue={initialValue}
          label={label}
          name={name}
          options={booleanRadioOptions}
          required={isRequired}
          row
          validate={validate}
        />
      );
    }
    case FieldDataType.SINGLE_SELECT: {
      return (
        <EditSingleSelectFieldValue
          className={className}
          field={field as SingleSelectFieldResponse}
          fieldValue={fieldValue as SingleSelectValue | undefined}
          isRequired={isRequired}
          label={label}
          name={name}
        />
      );
    }
    case FieldDataType.DATETIME:
    case FieldDataType.DATE: {
      return (
        <EditDateLikeValueFactory
          className={className}
          field={field}
          fieldValue={fieldValue as DateValue | DatetimeValue | undefined}
          isRequired={isRequired}
          label={label}
          name={name}
        />
      );
    }
    case FieldDataType.TIME: {
      const timeFieldValue = fieldValue as TimeValue | undefined;
      return (
        <TimePickerField
          className={className}
          helperText=""
          label={label}
          name={name}
          initialValue={timeFieldValue?.value || undefined}
          validate={validate}
          required={isRequired}
        />
      );
    }
    case FieldDataType.RESOURCE:
      return (
        <EditResourceFieldValue
          className={className}
          field={field as ResourceFieldResponse}
          fieldValue={fieldValue as ResourceValue | undefined}
          isRequired={isRequired}
          label={label}
          name={name}
          organizationId={organizationId}
          projectId={projectId}
        />
      );
    case FieldDataType.USER:
      if (!projectId) {
        throw new Error('Cannot have user field without project context');
      }
      return (
        <EditUserFieldValue
          className={className}
          fieldValue={fieldValue as UserValue | undefined}
          isRequired={isRequired}
          label={label}
          name={name}
          projectId={projectId}
        />
      );
    default: {
      const exhaustiveCheck:
        | never
        | FieldDataType.FORMULA
        | FieldDataType.SIGNATURE
        | FieldDataType.LOCATION
        | FieldDataType.PARAMETER
        | FieldDataType.SUBSTANCE = field.type;
      throw new Error(`Unimplemented field data type - ${exhaustiveCheck}`);
    }
  }
}

export function getFieldValueFromForm(
  field: FieldResponse,
  formValues: FormValues,
): SaveFieldValueRequest {
  switch (field.type) {
    case FieldDataType.SINGLE_SELECT:
    case FieldDataType.TEXT:
    case FieldDataType.TIME: {
      const value = formValues[field.id] || null;
      if (!isNullableString(value)) {
        throw getUnexpectedTypeErrorAndLogToSentry(field, value);
      }
      return {
        fieldId: field.id,
        fieldValue: { value },
      };
    }
    case FieldDataType.BOOLEAN: {
      const formValue = formValues[field.id];
      let parsedValue: NullableBoolean;
      switch (formValue) {
        case 'true':
          parsedValue = true;
          break;
        case 'false':
          parsedValue = false;
          break;
        case null:
        case undefined:
          parsedValue = null;
          break;
        default:
          throw getUnexpectedTypeErrorAndLogToSentry(field, formValue);
      }
      return {
        fieldId: field.id,
        fieldValue: { value: parsedValue },
      };
    }
    case FieldDataType.DATETIME:
    case FieldDataType.DATE: {
      return getDateLikeFieldValueFromForm(field, formValues);
    }
    case FieldDataType.NUMERIC:
      return getNumericFieldValueFromForm(
        field as NumericFieldResponse,
        formValues,
      );
    case FieldDataType.RESOURCE:
      return getResourceFieldValueFromForm(
        field as ResourceFieldResponse,
        formValues,
      );
    case FieldDataType.USER:
      return getUserFieldValueFromForm(field, formValues);
    default: {
      const exhaustiveCheck:
        | never
        | FieldDataType.FORMULA
        | FieldDataType.SIGNATURE
        | FieldDataType.LOCATION
        | FieldDataType.PARAMETER
        | FieldDataType.SUBSTANCE = field.type;
      throw new Error(`Unhandled field data type - ${exhaustiveCheck}`);
    }
  }
}
