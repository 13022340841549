import { getKeyParamsForInvalidation as getKeyParamsForInvalidationShared } from 'mapistry-shared';
import { QueryConfig } from 'react-query';
import { Api } from '../apiClient';

export function getQueryConfig<TResponse>(
  inputConfig: QueryConfig<TResponse, Api.ErrorResponse> | undefined,
  isEnabledOverride: boolean,
): QueryConfig<TResponse, Api.ErrorResponse> {
  return {
    useErrorBoundary: true,
    ...inputConfig,
    enabled:
      inputConfig?.enabled != null
        ? inputConfig.enabled && isEnabledOverride
        : isEnabledOverride,
  };
}

export const getKeyParamsForInvalidation = getKeyParamsForInvalidationShared;
