import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourceTypeCache,
  ResourceTypeCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import { setCache as setResourceTypeCache } from '@monorepo/shared/hooks/resources/useResourceType';
import { ResourceTypeRefResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type useResourceTypeCreateParams = {
  config?: MutationConfig<ResourceTypeRefResponse, Api.ErrorResponse>;
};

export const useResourceTypeCreate = (params?: useResourceTypeCreateParams) =>
  useMutation(Api.createResourceType, {
    ...params?.config,
    onSuccess: (createdResourceType, mutationFuncParams) => {
      const { organizationId } = mutationFuncParams;

      // Set cache for that single Resource Type
      const keyParams = {
        organizationId,
        resourceTypeId: createdResourceType.id,
      };
      setResourceTypeCache(keyParams, createdResourceType);

      // Invalidate any RQ cache that might use information about Resource Types
      invalidateResourceTypeCache(ResourceTypeCacheEvents.CREATE, keyParams);

      return params?.config?.onSuccess?.(
        createdResourceType,
        mutationFuncParams,
      );
    },
  });
