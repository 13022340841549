import { Checkbox } from '@monorepo/shared/components/Checkbox';
import { Tooltip } from '@monorepo/shared/components/Tooltip';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { KeyService } from '@monorepo/shared/KeyService';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Button,
  FormErrors,
  LoadingIndicator,
  Modal,
  SaveButton,
} from '../../elements';
import { FormSaveStateType, ProjectTaskType } from '../../propTypes';
import { DeleteTaskDialog } from '../DeleteTaskDialog';
import {
  NO_COMPLETE_PERMISSION_MSG,
  NO_DELETE_PERMISSION_MSG,
} from '../shared_messaging';
import { TaskForm } from '../TaskForm';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function TaskEditModal(props) {
  const {
    addingTask,
    error,
    isLoading,
    isOpen,
    isSaveDisabled,
    onError,
    onChange,
    onClose,
    onComplete,
    onDelete,
    onOpenInForm,
    onSave,
    onSetSaveDisabled,
    projectId,
    saveState,
    task,
    validationError,
  } = props;

  const [confirmDelete, setConfirmDelete] = useState(false);

  const isCompleted = !!task?.markedAsCompletedDate;

  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(projectId);

  const title = task?.title ? `Editing: ${task.title}` : 'Editing Task';
  const header = task?.formTemplateName ? (
    <div>
      <div
        role="button"
        tabIndex={0}
        className="task-edit-modal__sub-header"
        onClick={onOpenInForm}
        onKeyDown={(e) => {
          if (KeyService.triggersButtonClick(e.keyCode))
            onOpenInForm && onOpenInForm();
        }}
      >
        go to {task.formTemplateName}
      </div>
      <h4>{title}</h4>
    </div>
  ) : (
    <h4>{title}</h4>
  );

  const handleDelete = useCallback(() => {
    setConfirmDelete(true);
  }, [setConfirmDelete]);
  const handleDeleteConfirmation = useCallback(
    (allFuture) => {
      const deleteRequest = {
        dueDate: task.dueDate,
        isRecurring: task.isRecurring,
        projectId: task.projectId,
        id: task.id,
        templateId: task.templateId,
        allFuture,
        isConcrete: !!task.createdAt,
      };
      onDelete(deleteRequest);
    },
    [task, onDelete],
  );

  const deleteTooltipMessage = task?.permissions.isAllowedToDelete
    ? ''
    : NO_DELETE_PERMISSION_MSG;
  const completeTooltipMessage = task?.permissions.isAllowedToComplete
    ? ''
    : NO_COMPLETE_PERMISSION_MSG;

  const taskIsConcrete = !!task?.createdAt;
  const isCreatingRecurringTask = !!task?.isTemplate;
  const canComplete = !isCreatingRecurringTask && hasProjectUpdatePermission;
  const canDelete =
    !isCreatingRecurringTask &&
    hasProjectUpdatePermission &&
    (task?.isRecurring || taskIsConcrete);

  const footer = (
    <div className="task-edit-modal__footer">
      <div className="task-edit-modal__footer-left-side">
        {canComplete && (
          <Tooltip title={completeTooltipMessage} placement="top-start">
            <div className="task-edit-modal__footer-mark-complete">
              <Checkbox
                isChecked={isCompleted}
                label="Mark task complete"
                disabled={!task?.permissions.isAllowedToComplete}
                focusableWhenDisabled
                onChange={onComplete}
              />
            </div>
          </Tooltip>
        )}
        {canDelete && (
          <Tooltip title={deleteTooltipMessage} placement="top">
            <div>
              <Button
                color="danger"
                onClick={handleDelete}
                disabled={!task?.permissions.isAllowedToDelete}
                focusableWhenDisabled
              >
                Delete Task
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <Button color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <SaveButton
        label={addingTask ? 'Create Task' : 'Update Task'}
        onSave={onSave}
        saveState={saveState}
        disabled={isSaveDisabled}
      />
    </div>
  );

  return (
    <Modal
      className={cn('task-edit-modal', {
        'task-edit-modal__slim': !task?.addSubtasks,
      })}
      header={addingTask ? <h4>Add a task(s)</h4> : header}
      footer={footer}
      fullWidth={false}
      maxWidth="md"
      onClose={onClose}
      open={isOpen}
    >
      {error && (
        <div className="task-edit-modal__error">
          <FormErrors errors={[error]} />
        </div>
      )}
      {isLoading && (
        <LoadingIndicator
          className="task-edit-modal__loader"
          isAbsolute={false}
          size={30}
        />
      )}
      {(task || addingTask) && (
        <TaskForm
          addingTask={addingTask}
          canRecur
          errors={validationError ? [validationError] : []}
          folderSlugPrefix="ad-hoc-tasks"
          noHeader
          onChange={(newTasks, newErrors) =>
            onChange(newTasks[0], newErrors && newErrors[0])
          }
          onSetSaveDisabled={onSetSaveDisabled}
          onError={onError}
          projectId={projectId}
          tasks={task ? [task] : []}
        />
      )}
      {confirmDelete && (
        <DeleteTaskDialog
          open={confirmDelete}
          onCancelled={() => setConfirmDelete(false)}
          onConfirmed={handleDeleteConfirmation}
          isRecurring={task.isRecurring}
        />
      )}
    </Modal>
  );
}

TaskEditModal.propTypes = {
  addingTask: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onOpenInForm: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSetSaveDisabled: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  saveState: FormSaveStateType.isRequired,
  task: ProjectTaskType,
  validationError: PropTypes.shape({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    fieldErrors: PropTypes.object,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    subTaskErrors: PropTypes.arrayOf(PropTypes.object),
    taskId: PropTypes.string.isRequired,
  }),
};

TaskEditModal.defaultProps = {
  addingTask: false,
  error: null,
  task: null,
  validationError: null,
};
