import React from 'react';
import { Helmet } from 'react-helmet-async';

type DocumentTitleProps = { title: string | string[] };
type DocumentTitleTemplateProps = { titleTemplate?: string | string[] };

// Put the most identifying/unique part of the page title first, followed by less unique parts.
// The title should usually mostly align with the main h1 of the page.
export function DocumentTitle({ title }: DocumentTitleProps) {
  const tabTitle = Array.isArray(title) ? title.join(' | ') : title;

  return (
    <Helmet>
      <title>{tabTitle}</title>
    </Helmet>
  );
}

/**
 * Used to e.g. include a project name in all titles in part of the page hierarchy.
 * Page title on pages below will be 'specificPageTitle | templateString | Mapistry`
 */
export function DocumentTitleTemplate({
  titleTemplate,
}: DocumentTitleTemplateProps) {
  let titleComponents = Array.isArray(titleTemplate)
    ? titleTemplate
    : [titleTemplate];

  titleComponents = titleComponents.filter((entry) => !!entry);
  titleComponents.push('Mapistry');

  const templateString = titleComponents.join(' | ');

  return (
    <Helmet
      defaultTitle={templateString}
      titleTemplate={`%s | ${templateString}`}
    />
  );
}

export function DefaultDocumentTitle() {
  return <Helmet defaultTitle="Mapistry" titleTemplate="%s | Mapistry" />;
}
