import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { FieldResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';

type KeyParams = Api.FetchResourcePropertiesParams;
export const createKey = ({ organizationId, resourceTypeId }: KeyParams) =>
  ['resource-properties', organizationId, resourceTypeId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchResourceProperties
>;

const fetcher: Fetcher = (_, organizationId, resourceTypeId) =>
  Api.fetchResourceProperties({ organizationId, resourceTypeId });

type UseResourcePropertiesParams =
  Partial<Api.FetchResourcePropertiesParams> & {
    config?: QueryConfig<FieldResponse[], Api.ErrorResponse>;
  };

export function useResourceProperties({
  config: inputConfig,
  organizationId,
  resourceTypeId,
}: UseResourcePropertiesParams) {
  const isEnabled = !!organizationId && !!resourceTypeId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createKey({ organizationId, resourceTypeId })
    : undefined;
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    resourceProperties: data,
  };
}

type QueryCache = ReturnType<
  typeof useResourceProperties
>['resourceProperties'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

export async function invalidateCache(keyParams: KeyParams): Promise<void> {
  await queryCache.invalidateQueries(createKey(keyParams));
}
