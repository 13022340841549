import styled from 'styled-components';
import { bodyRegular } from '../../styles/text';

export const StartDate = styled.div`
  ${bodyRegular}
  display: flex;
  align-items: flex-end;

  /** 
  * In order to line up the baselines of the Header
  * title text and this start date text we're multiplying
  * the title's line-height with it's font-size so that 
  * the height of the start date text element is the
  * same as the height of the title element
  */
  height: calc(1.5rem * 1.15);
  padding-left: 0.375rem;
  line-height: 1.5;
`;
