import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourceListCacheEvents,
  resourceListCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceListCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { QueryConfig, queryCache, usePaginatedQuery } from 'react-query';
import { OptionalKeys } from 'utility-types';
import { Api } from '../../apiClient';

type KeyParams = Api.FetchResourcesParams;

const KEY_START = 'resources';

function getCoreKeyPartsInOrder(
  keyParams: KeyParams,
): [string, string, string, string | undefined];
function getCoreKeyPartsInOrder(
  keyParams: Partial<KeyParams>,
): [string, string | undefined, string | undefined, string | undefined];
function getCoreKeyPartsInOrder(
  keyParams: KeyParams | Partial<KeyParams>,
):
  | [string, string, string, string | undefined]
  | [string, string | undefined, string | undefined, string | undefined] {
  const { organizationId, resourceTypeId, projectId } = keyParams;
  return [
    KEY_START,
    organizationId,
    resourceTypeId,
    // only add this item to the key if projectId exists, so we have a hierarchy (invalidating all resources
    //  in a type will invalidate the specific projects too)
    projectId || undefined,
  ];
}

export const createKey = ({
  nameFilter,
  organizationId,
  resourceTypeId,
  projectId,
  perPage,
  requestedPage,
  sortDirection,
}: KeyParams) =>
  [
    ...getCoreKeyPartsInOrder({ organizationId, resourceTypeId, projectId }),
    { nameFilter, perPage, requestedPage, sortDirection },
  ] as const;

type Fetcher = Api.DataHookQueryFn<typeof createKey, typeof Api.fetchResources>;

const fetcher: Fetcher = (
  _,
  organizationId,
  resourceTypeId,
  projectId,
  { nameFilter, perPage, requestedPage, sortDirection },
) =>
  Api.fetchResources({
    nameFilter,
    organizationId,
    perPage,
    projectId,
    requestedPage,
    resourceTypeId,
    sortDirection,
  });

type UseResourcesParams = Partial<Api.FetchResourcesParams> & {
  config?: QueryConfig<Api.FetchResourcesResponse, Api.ErrorResponse>;
};

export function useResources({
  config: inputConfig,
  nameFilter,
  organizationId,
  perPage,
  projectId,
  requestedPage,
  resourceTypeId,
  sortDirection,
}: UseResourcesParams) {
  const isEnabled = !!organizationId && !!resourceTypeId;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled
    ? createKey({
        nameFilter,
        organizationId,
        perPage,
        projectId,
        requestedPage,
        resourceTypeId,
        sortDirection,
      })
    : undefined;

  const {
    resolvedData: { data: resources = undefined, pagination = undefined } = {},
    ...queryInfo
  } = usePaginatedQuery(key, fetcher, config);

  return {
    ...queryInfo,
    pagination,
    resources,
  };
}

type QueryCache =
  | {
      data: ReturnType<typeof useResources>['resources'];
      pagination: ReturnType<typeof useResources>['pagination'];
    }
  | undefined;
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createKey(keyParams));
}

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}

type OptionalKeyParams = Pick<KeyParams, OptionalKeys<KeyParams>>;
type InvalidateCacheParams = Omit<KeyParams, keyof OptionalKeyParams> & {
  projectId?: string;
};

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation(getCoreKeyPartsInOrder(keyParams)),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useLogEntries',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.UPDATE,
    ResourceCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourceListCacheRegister(
  [ResourceListCacheEvents.CREATE],
  cacheRegisterInvalidator,
);
