import { AlertModal } from '@monorepo/shared/componentsV2/modals/AlertModal';
import {
  MultiChoiceConfirmModalProps,
  SingleChoiceConfirmModalProps,
} from '@monorepo/shared/componentsV2/modals/ConfirmModal';
import { PromptModal } from '@monorepo/shared/componentsV2/modals/PromptModal';
import { createContext } from 'react';

type AlertModalProps = React.ComponentProps<typeof AlertModal>;

type PromptModalProps = React.ComponentProps<typeof PromptModal>;

export type AlertModalOpts = Pick<
  AlertModalProps,
  'buttonText' | 'description' | 'showCloseButton' | 'title'
>;

export type SingleChoiceConfirmModalOpts = Pick<
  SingleChoiceConfirmModalProps,
  | 'cancelButtonText'
  | 'confirmButtonText'
  | 'danger'
  | 'description'
  | 'showCloseButton'
  | 'title'
> &
  Partial<
    Pick<
      SingleChoiceConfirmModalProps,
      'onConfirmAsync' | 'afterConfirmButtonText'
    >
  >;

export type MultiChoiceConfirmModalOpts = Pick<
  MultiChoiceConfirmModalProps,
  | 'cancelButtonText'
  | 'confirmChoices'
  | 'description'
  | 'showCloseButton'
  | 'title'
> &
  Partial<Pick<MultiChoiceConfirmModalProps, 'onConfirmAsync'>>;

export type ConfirmModalOpts =
  | SingleChoiceConfirmModalOpts
  | MultiChoiceConfirmModalOpts;

interface ConfirmFn {
  (opts: SingleChoiceConfirmModalOpts): Promise<boolean>;
  (opts: MultiChoiceConfirmModalOpts): Promise<false | string>;
}

export type PromptModalOpts = Pick<
  PromptModalProps,
  | 'cancelButtonText'
  | 'confirmButtonText'
  | 'danger'
  | 'description'
  | 'initialInputValue'
  | 'multiline'
  | 'showCloseButton'
  | 'title'
  // if desired, we could expose onConfirm; see shared/componentsV2/modals/README.md for details on how this is used
  //  with the confirm modal
>;

interface ModalContextValue {
  alert: (opts: AlertModalOpts) => Promise<void>;
  confirm: ConfirmFn;
  prompt: (opts: PromptModalOpts) => Promise<string | void>;
}

const defaultValue: ModalContextValue = {
  alert: () => Promise.resolve(),
  confirm: (() => Promise.resolve(false)) as ConfirmFn,
  prompt: () => Promise.resolve(),
};

export const ModalContext = createContext(defaultValue);
