import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { getDashboardIdFromPath, getPathToDashboard } from '../routing';
import { useSetLastViewedDashboard } from './useSetLastViewedDashboard';

type WorkbookLoadingError = {
  code: string;
  message?: string;
};

/**
 * See https://quickstarts.sigmacomputing.com/guide/embedding_07_events
 */
export const useHandleSigmaWindowEvents = (
  iframeContentWindow: Window | undefined,
) => {
  const [dashboardLoadingError, setDashboardLoadingError] =
    useState<WorkbookLoadingError>();

  const [embedDashboardId, setEmbedDashboardId] = useState<
    string | undefined
  >();
  const previousEmbedDashboardId = usePrevious(embedDashboardId);

  const [setLastViewedDashboardUserSetting] = useSetLastViewedDashboard();

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname: currentPathname } = location;
  const urlDashboardId = getDashboardIdFromPath(currentPathname);

  // add event listener for Sigma iframe messages
  useEffect(() => {
    const eventListener = (event: MessageEvent) => {
      if (
        event.origin !== 'https://app.sigmacomputing.com' ||
        event.source !== iframeContentWindow
      )
        return;

      const sigmaEvent = event.data;

      // eslint-disable-next-line default-case
      switch (sigmaEvent.type) {
        // the Sigma term for dashboard is "workbook"
        case 'workbook:error':
          setDashboardLoadingError(sigmaEvent);
          break;
        case 'workbook:id:onchange': {
          const workbookId = sigmaEvent.id;
          setEmbedDashboardId(workbookId);
          break;
        }
      }
    };
    window.addEventListener('message', eventListener);

    return () => window.removeEventListener('message', eventListener);
  }, [iframeContentWindow]);

  // keep our app URL synced with the loaded dashboard
  // NOTE: as of 07/23/24, the Sigma embed is affecting the browser history when you navigate
  //  from one dashboard to another, so we currently have duplicated history for any navigation
  //  in analytics (i.e. you have to hit the back button twice to navigate between dashboards /
  //  the history from before you went into analytics)
  useEffect(() => {
    // nothing to do if either:
    //  - our URL and the embed dashboard is already synced
    //  - the embed dashboard hasn't loaded yet
    if (!embedDashboardId || embedDashboardId === urlDashboardId) return;

    // here is where we decide if we should change the url or change the workbook
    // to do this, we have to know which one changed
    if (embedDashboardId !== previousEmbedDashboardId) {
      const isDashboardIdInUrl = !!urlDashboardId;
      // updating the app url to match the dashboard the user navigated to in the Sigma embed
      navigate(getPathToDashboard(embedDashboardId), {
        replace: !isDashboardIdInUrl, // if we were at .../analytics/dashboards (with no dashboardId in the URL), we want to replace the url with the actual dashboard it loaded
      });
      void setLastViewedDashboardUserSetting(embedDashboardId);
    } else {
      // updating the dashboard in the embed to match the changed url (back or forward button was hit)
      // we have to completely reload the page in order to get a fresh embed URL that goes
      //  to the correct dashboard
      window.location.replace(currentPathname);
    }
  }, [
    embedDashboardId,
    previousEmbedDashboardId,
    currentPathname,
    urlDashboardId,
    navigate,
    setLastViewedDashboardUserSetting,
  ]);

  return { dashboardLoadingError };
};
