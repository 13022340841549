import { withPermissions } from '@monorepo/shared/hooks/permissions/withPermissions';
import CogIcon from '@svg/cog.svg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCalendarEvents } from '../../../../actions/calendar';
import APP from '../../../../config';
import { Button } from '../../../elements';
import { FormCalendar } from '../../../propTypes';
import withProvider from '../../../withProvider';
import NeverEventCalendar from '../CalendarCard/NeverEventCalendar';
import FixedFrequencyInspectionCalendarCard from '../FixedFrequencyInspectionCalendarCard';
import InspectionFrequencyModal from './InspectionFrequencyModal';

class InspectionCalendarCard extends Component {
  constructor(props) {
    super(props);
    this.handleFrequencyModalClose = this.handleFrequencyModalClose.bind(this);
    this.handleFrequencyModalOpen = this.handleFrequencyModalOpen.bind(this);
    this.state = {
      currentDate: new Date(),
      frequencyModalOpen: false,
    };
  }

  handleFrequencyModalOpen() {
    this.setState({ frequencyModalOpen: true });
  }

  handleFrequencyModalClose() {
    this.setState({ frequencyModalOpen: false });
  }

  defaultView() {
    const { calendar } = this.props;
    return (
      <NeverEventCalendar
        calendarTitle={calendar.summaryTitle}
        additionalInfo={
          <>
            <span className="inspection-calendar-card__never__event">
              To start recording
              <strong>{` ${calendar.summaryTitle} `}</strong>
              for this time period, please click the button below to set a
              frequency.
            </span>
            <Button onClick={this.handleFrequencyModalOpen}>
              Set inspection frequency
            </Button>
          </>
        }
      />
    );
  }

  menuOptions() {
    const { hasProjectSettingsPermission } = this.props;
    const options = [];
    if (hasProjectSettingsPermission) {
      options.push({
        label: 'Frequency Settings',
        icon: <CogIcon className="m-icon" />,
        onClick: this.handleFrequencyModalOpen,
      });
    }
    return options;
  }

  render() {
    const {
      calendar,
      fetchEvents,
      hasProjectSettingsPermission,
      organizationId,
      projectId,
    } = this.props;
    const { currentDate, frequencyModalOpen } = this.state;

    return (
      <>
        <FixedFrequencyInspectionCalendarCard
          calendar={calendar}
          defaultView={this.defaultView()}
          menuOptions={this.menuOptions()}
          onDateChange={(newDate) => this.setState({ currentDate: newDate })}
          organizationId={organizationId}
          projectId={projectId}
        />
        {hasProjectSettingsPermission && frequencyModalOpen && (
          <InspectionFrequencyModal
            calendarName={calendar.name}
            onClose={this.handleFrequencyModalClose}
            open={frequencyModalOpen}
            reloadEvents={() =>
              fetchEvents(projectId, calendar.name, currentDate)
            }
            title={`${calendar.summaryTitle} Frequency Settings`}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchEvents: (projectId, calendarName, date) =>
    dispatch(
      fetchCalendarEvents({
        calendarName,
        date,
        projectId,
      }),
    ),
});

const mapStateToProps = () => ({
  projectId: APP.projectId,
});

InspectionCalendarCard.propTypes = {
  calendar: FormCalendar.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  hasProjectSettingsPermission: PropTypes.bool,
  organizationId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
};

InspectionCalendarCard.defaultProps = {
  hasProjectSettingsPermission: false,
  organizationId: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withPermissions(InspectionCalendarCard)),
);
