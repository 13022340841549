import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import React from 'react';

interface EmptyListProps {
  onAddNew: () => void;
  organizationId: string;
}

export function EmptyList({ onAddNew, organizationId }: EmptyListProps) {
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);
  const aboutText =
    'Workflows allow you to create relationships between your Logs and Resource types, and transform your data.';
  const secondaryText = canEditOrgSettings
    ? `Click to create a new Workflow. ${aboutText}`
    : `${aboutText} Contact your organization administrator to configure a Workflow.`;

  return (
    <EmptyState
      actionButtonConfigs={
        canEditOrgSettings
          ? [{ label: 'Create new Workflow', onClick: onAddNew }]
          : []
      }
      primaryText="There are no Workflows configured for this organization."
      secondaryText={secondaryText}
    />
  );
}
