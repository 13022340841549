import { CompoundFilter, SingleFilter } from 'mapistry-shared';

export type FormValues = CompoundFilter;

export const isCompoundFilter = (
  x: SingleFilter | CompoundFilter,
): x is CompoundFilter => 'filters' in x;

export const isSingleFilter = (
  x: SingleFilter | CompoundFilter,
): x is SingleFilter => !isCompoundFilter(x);
