// adding this 'material-ui' ThemeProvider in order to account for old-web - Vortex uses the 'styled-components' ThemeProvider
import { ThemeProvider } from '@material-ui/core/styles';
import { InspectionFormTheme } from '@monorepo/old-web/js/components/themes/inspectionFormTheme';
import { RoutesWith404 } from '@monorepo/shared/componentsV2/RoutesWith404';
import React from 'react';
import { Route } from 'react-router-dom-v5-compat';
import { InspectionsPage } from './InspectionsPage';

export function Inspections() {
  return (
    <ThemeProvider theme={InspectionFormTheme}>
      <RoutesWith404>
        <Route index element={<InspectionsPage />} />
      </RoutesWith404>
    </ThemeProvider>
  );
}
