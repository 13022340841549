import {
  StyledFormGroup,
  StyledInput,
  StyledTextArea,
} from '@monorepo/shared/componentsV2/styled/form';
import { CreateWorkflowRequest, WorkflowResponse } from 'mapistry-shared';

import { isRequired } from '@monorepo/shared/utils/validators';
import React from 'react';
import { DatasetSection } from './DatasetSection/DatasetSection';

export type FormValues = Pick<
  CreateWorkflowRequest,
  'name' | 'description' | 'inputDatasetId'
>;

export function pickFormValues(workflow: WorkflowResponse): FormValues {
  return {
    name: workflow.name,
    description: workflow.description,
    inputDatasetId: workflow.inputDataset.id,
  };
}

export function EditWorkflowForm() {
  return (
    <>
      <StyledFormGroup row>
        <StyledInput<FormValues['name']>
          label="Workflow Name"
          name="name"
          validate={isRequired}
          required
        />
      </StyledFormGroup>
      <StyledFormGroup row>
        <StyledTextArea<NonNullable<FormValues['description']>>
          label="Description"
          multiline
          name="description"
        />
      </StyledFormGroup>
      <DatasetSection />
    </>
  );
}
