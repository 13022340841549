import CogIcon from '@svg/cog.svg';
import DuplicateCardsIcon from '@svg/duplicate-cards.svg';
import TrashIcon from '@svg/trash.svg';
import React, { useCallback, useMemo, useState } from 'react';
import WidgetDetailsContext from './WidgetDetailsContext';

type WidgetDetailsContextValue = React.ContextType<typeof WidgetDetailsContext>;

type WidgetDetailsProviderProps = {
  children: NonNullable<React.ReactNode>;
  deleteWidget: WidgetDetailsContextValue['deleteWidget'];
  duplicateWidget: WidgetDetailsContextValue['duplicateWidget'];
  updateWidget: WidgetDetailsContextValue['updateWidget'];
  showRemoveOption: boolean;
  disableDuplicateOption?: boolean;
  widgetId: string;
  widgetSettings?: WidgetDetailsContextValue['widgetSettings'];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function WidgetDetailsProvider(props: WidgetDetailsProviderProps) {
  const {
    children,
    widgetId,
    widgetSettings,
    updateWidget,
    deleteWidget,
    duplicateWidget,
    showRemoveOption,
    disableDuplicateOption,
  } = props;

  const [widgetSettingsModalIsVisible, setWidgetSettingsModalIsVisible] =
    useState(false);
  const toggleSettingsModalVisibility = useCallback(
    () => setWidgetSettingsModalIsVisible((isVisible) => !isVisible),
    [],
  );

  const menuOptions = useMemo(() => {
    const widgetSettingsOption = {
      label: 'Widget Settings',
      icon: <CogIcon className="m-icon" />,
      onClick: toggleSettingsModalVisibility,
    };
    const removeOption = {
      label: 'Remove',
      icon: <TrashIcon className="m-icon" />,
      danger: true,
      onClick: () => deleteWidget(widgetId),
    };
    const duplicateOption = {
      disabled: disableDuplicateOption,
      label: 'Duplicate chart',
      icon: <DuplicateCardsIcon className="m-icon" />,
      onClick: () => duplicateWidget(widgetId),
    };

    if (showRemoveOption) {
      return [widgetSettingsOption, duplicateOption, removeOption];
    }
    return [widgetSettingsOption, duplicateOption];
  }, [
    widgetId,
    disableDuplicateOption,
    showRemoveOption,
    deleteWidget,
    duplicateWidget,
    toggleSettingsModalVisibility,
  ]);
  const value = useMemo(
    () => ({
      widgetId,
      widgetSettings,
      widgetSettingsModalIsVisible,
      toggleSettingsModalVisibility,
      updateWidget,
      deleteWidget,
      duplicateWidget,
      menuOptions,
    }),
    [
      widgetId,
      widgetSettings,
      updateWidget,
      deleteWidget,
      duplicateWidget,
      toggleSettingsModalVisibility,
      widgetSettingsModalIsVisible,
      menuOptions,
    ],
  );

  return (
    <WidgetDetailsContext.Provider value={value}>
      {children}
    </WidgetDetailsContext.Provider>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default WidgetDetailsProvider;
