import {
  WizardHeader,
  WizardLink,
} from '@monorepo/shared/componentsV2/fullPageWizard/WizardHeader';
import { NonEmptyArray } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { ResourceTypeEditMode } from '../routerTypes';

export enum WizardStep {
  DETAILS = 'Basic Info',
  PROPERTIES = 'Properties',
  RESOURCES = 'Add Resources',
}

interface EditResourceTypeHeaderProps {
  canSubmit?: boolean;
  isSubmitting?: boolean;
  onSubmit?: () => unknown;
  wizardStep: WizardStep;
}

export function EditResourceTypeHeader({
  canSubmit,
  isSubmitting = false,
  onSubmit,
  wizardStep,
}: EditResourceTypeHeaderProps) {
  const { mode = ResourceTypeEditMode.NEW, resourceTypeId } = useParams();
  const isEditMode = mode === ResourceTypeEditMode.EDIT;
  const resourceTypeExists = !!resourceTypeId;

  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate(resourceTypeExists ? '../../../..' : '..');
  }, [resourceTypeExists, navigate]);

  const fieldsAreNotReady =
    !isEditMode &&
    (wizardStep === WizardStep.DETAILS ||
      (wizardStep === WizardStep.PROPERTIES && !canSubmit));

  const breadcrumbsLinks = useMemo<NonEmptyArray<WizardLink>>(
    () => [
      {
        completed: resourceTypeExists,
        label: WizardStep.DETAILS,
        to: resourceTypeExists ? '../../basic-info' : `.`,
      },
      {
        completed:
          wizardStep !== WizardStep.DETAILS &&
          wizardStep !== WizardStep.PROPERTIES,
        disabled: !resourceTypeExists,
        label: WizardStep.PROPERTIES,
        to: '../../properties',
      },
      {
        disabled: !resourceTypeExists || fieldsAreNotReady,
        label: WizardStep.RESOURCES,
        to: '../../add-resources',
      },
    ],
    [fieldsAreNotReady, resourceTypeExists, wizardStep],
  );

  return (
    <WizardHeader
      canSubmit={canSubmit}
      isEditMode={isEditMode}
      isSubmitting={isSubmitting}
      links={breadcrumbsLinks}
      onClose={handleClose}
      onSubmit={onSubmit}
      title={isEditMode ? 'Edit Resource Type' : 'New Resource Type'}
      wizardStep={wizardStep}
    />
  );
}
