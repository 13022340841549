import React from 'react';
import { Operator } from 'mapistry-shared';
import { DateFilterCondition } from './DateFilterCondition';

export function DateRangeFilterCondition({ name }: { name: string }) {
  const supportedOperators = [
    Operator.EMPTY,
    Operator.NOT_EMPTY,
    Operator.BEFORE,
    Operator.EQUAL_OR_BEFORE,
    Operator.AFTER,
    Operator.EQUAL_OR_AFTER,
  ];
  return (
    <DateFilterCondition name={name} supportedOperators={supportedOperators} />
  );
}
