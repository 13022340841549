import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogEntryCache,
  LogEntryCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import { setCache as setLogEntryCache } from '@monorepo/shared/hooks/logs/useLogEntry';
import { LogEntryResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type useLogEntryUpdateParams = {
  config?: MutationConfig<LogEntryResponse, Api.ErrorResponse>;
};

export const useLogEntryUpdate = (params?: useLogEntryUpdateParams) =>
  useMutation(Api.updateLogEntry, {
    ...params?.config,
    onSuccess: async (updatedEntry, mutationFuncParams) => {
      const { organizationId, logId, logEntryId } = mutationFuncParams;

      // Set cache for that single log entry
      const keyParams = {
        organizationId,
        logId,
        logEntryId,
        projectId: updatedEntry.projectId,
      };
      setLogEntryCache(keyParams, updatedEntry);

      // Invalidate any cache that includes info about log entries
      invalidateLogEntryCache(LogEntryCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(updatedEntry, mutationFuncParams);
    },
  });
