import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, CardOverlay, FieldWrapper, Select } from '../../elements';

class FilterSettings extends Component {
  constructor(props) {
    super(props);
    const { currentItems } = props;
    this.state = {
      filterValue: this.toOptions(currentItems),
      filterOptions: this.filterOptions(currentItems),
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleFilterChange(newValue) {
    this.setState({
      filterValue: newValue,
      filterOptions: this.filterOptions(newValue),
    });
  }

  handleSave() {
    const { loggedItems, onSave } = this.props;
    const { filterValue } = this.state;
    const ids = filterValue ? filterValue.map((fv) => fv.value) : [];
    onSave(loggedItems.filter((item) => ids.includes(item.id)));
  }

  filterOptions(items) {
    const { loggedItems } = this.props;
    const frequency = items && items.length ? items[0].frequency : null;
    return frequency
      ? loggedItems.filter((item) => item.frequency === frequency)
      : loggedItems;
  }

  toOptions(loggedItems) {
    return loggedItems.map((item) => ({
      label: item.name,
      value: item.id,
      frequency: item.frequency,
    }));
  }

  footer() {
    const { onCancel } = this.props;
    return (
      <>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={this.handleSave}>Apply</Button>
      </>
    );
  }

  render() {
    const { onCancel } = this.props;
    const { filterOptions, filterValue } = this.state;

    return (
      <CardOverlay
        footer={this.footer()}
        onCancel={onCancel}
        title="Filter Settings"
      >
        <FieldWrapper label="Select an item">
          <Select
            isClearable={false}
            isFixed
            isMulti
            options={this.toOptions(filterOptions)}
            noOptionsMessage="There are no more items available with the same frequency"
            value={filterValue}
            onChange={this.handleFilterChange}
          />
        </FieldWrapper>
      </CardOverlay>
    );
  }
}

const LoggedItemType = PropTypes.shape({
  frequency: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
});

FilterSettings.propTypes = {
  loggedItems: PropTypes.arrayOf(LoggedItemType).isRequired,
  currentItems: PropTypes.arrayOf(LoggedItemType).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

FilterSettings.defaultProps = {};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FilterSettings;
