import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourceCache,
  ResourceCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import { setCache as setResourceCache } from '@monorepo/shared/hooks/resources/useResource';
import { ResourceResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';

type useResourceUpdateParams = {
  config?: MutationConfig<ResourceResponse, Api.ErrorResponse>;
};

export const useResourceUpdate = (params?: useResourceUpdateParams) =>
  useMutation(Api.updateResource, {
    ...params?.config,
    onSuccess: async (updatedResource, mutationFuncParams) => {
      const { organizationId, resourceTypeId } = mutationFuncParams;

      // Set cache for that single resource
      const keyParams = {
        organizationId,
        resourceTypeId,
        resourceId: updatedResource.id,
      };
      setResourceCache(keyParams, updatedResource);

      invalidateResourceCache(ResourceCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(updatedResource, mutationFuncParams);
    },
  });
