import { Property } from '@flatfile/api/api';
import { SelectOptionsByFieldType } from '@monorepo/shared/apiClient/logs/fieldOptionsForUpload';
import {
  FieldDataType,
  FieldResponse,
  MathUnit,
  NullableMathUnitName,
  NumericFieldResponse,
  SingleSelectFieldResponse,
} from 'mapistry-shared';
import {
  ColumnInfo,
  SheetOptions,
  getBooleanColumnSetting,
  getDateColumnSetting,
  getDateTimeColumnSetting,
  getNumericColumnSetting,
  getSingleSelectColumnSetting,
  getSingleSelectColumnSettingFromStringOptions,
  getTextColumnSetting,
  getTimeColumnSetting,
} from '../columnSettingHelpers';

function getColumnInfoFromField(field: FieldResponse): ColumnInfo {
  return {
    key: field.id,
    label: field.name,
    isRequired: field.isRequired,
  };
}

function getUserColumnSetting(
  field: FieldResponse,
  optionsByFieldType: SelectOptionsByFieldType,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.Enum {
  const options = optionsByFieldType.users;
  if (!options) {
    throw new Error(
      `Couldn't generate columns for upload: there are no users uploaded for field "${field.name}" with id "${field.id}"`,
    );
  }
  return getSingleSelectColumnSetting(getColumnInfoFromField(field), options, {
    nothingIsRequired,
  });
}

function getResourceColumnSetting(
  field: FieldResponse,
  optionsByFieldType: SelectOptionsByFieldType,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property.Enum {
  if (!('resourceTypeId' in field && field.resourceTypeId)) {
    throw new Error(
      `Couldn't generate columns for upload: Resource field "${field.name}" doesn't have a reference to its Resource type (${field.id})`,
    );
  }
  const options = optionsByFieldType.resources?.[field.resourceTypeId];
  if (!options) {
    throw new Error(
      `Couldn't generate columns for upload: there are no Resources uploaded for field "${field.name}" with id "${field.id}"`,
    );
  }
  return getSingleSelectColumnSetting(getColumnInfoFromField(field), options, {
    nothingIsRequired,
  });
}

export function composeKeyForUnitField(numberColumnKey: string) {
  return `${numberColumnKey}-units`;
}

export function getNumericColumnWithUnitsSetting(
  columnInfo: ColumnInfo & { units?: NullableMathUnitName },
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): [Property.Number] | [Property.Number, Property.Enum] {
  const numberField: Property.Number = getNumericColumnSetting(columnInfo, {
    nothingIsRequired,
  });

  if (!('units' in columnInfo && columnInfo.units)) {
    return [numberField];
  }

  const relatedUnits = MathUnit.getRelatedSelectableUnits(columnInfo.units);
  const unitsField = getSingleSelectColumnSetting(
    {
      key: composeKeyForUnitField(columnInfo.key),
      label: `Units for ${columnInfo.label}`,
      isRequired: columnInfo.isRequired,
    },
    relatedUnits,
    { nothingIsRequired },
  );

  return [numberField, unitsField];
}

/* ----- Generating upload column settings from field configuration ----- */
function getColumnsForField(
  field: FieldResponse,
  optionsByFieldType: SelectOptionsByFieldType,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property[] {
  const columnInfo = getColumnInfoFromField(field);

  switch (field.type) {
    case FieldDataType.BOOLEAN:
      return [getBooleanColumnSetting(columnInfo, { nothingIsRequired })];
    case FieldDataType.DATE:
      return [getDateColumnSetting(columnInfo, { nothingIsRequired })];
    case FieldDataType.DATETIME:
      return [getDateTimeColumnSetting(columnInfo, { nothingIsRequired })];
    case FieldDataType.FORMULA:
      return [];
    case FieldDataType.NUMERIC: {
      const columnInfoWithUnits = {
        ...columnInfo,
        units: (field as NumericFieldResponse).units,
      };
      return getNumericColumnWithUnitsSetting(columnInfoWithUnits, {
        nothingIsRequired,
      });
    }
    case FieldDataType.RESOURCE:
      return [
        getResourceColumnSetting(field, optionsByFieldType, {
          nothingIsRequired,
        }),
      ];
    case FieldDataType.SINGLE_SELECT: {
      const columnInfoWithOptions = {
        ...columnInfo,
        options: (field as SingleSelectFieldResponse).options,
      };
      return [
        getSingleSelectColumnSettingFromStringOptions(columnInfoWithOptions, {
          nothingIsRequired,
        }),
      ];
    }
    case FieldDataType.TEXT:
      return [getTextColumnSetting(columnInfo, { nothingIsRequired })];
    case FieldDataType.TIME:
      return [getTimeColumnSetting(columnInfo, { nothingIsRequired })];
    case FieldDataType.USER:
      return [
        getUserColumnSetting(field, optionsByFieldType, {
          nothingIsRequired,
        }),
      ];
    default:
      throw new Error(
        `Couldn't generate columns for upload: not allowed field type "${field.type}"`,
      );
  }
}

export function getColumnsForFields(
  fields: FieldResponse[],
  optionsByFieldType: SelectOptionsByFieldType,
  { nothingIsRequired }: SheetOptions = { nothingIsRequired: false },
): Property[] {
  return fields.reduce(
    (acc, field) => [
      ...acc,
      ...getColumnsForField(field, optionsByFieldType, {
        nothingIsRequired,
      }),
    ],
    [] as Property[],
  );
}
