import {
  invalidateTasksCache,
  TasksCacheEvents,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import {
  PushMessageTopic as PMT,
  ServerToClientMessages,
} from 'mapistry-shared';
import { useThrottledOnArgsMessageHandler } from '../webSocket';

const onTaskCalendarEventsChanged: ServerToClientMessages[PMT.TaskCalendarEventChanged] =
  async ({ projectId }) => {
    invalidateTasksCache(TasksCacheEvents.UPSERT, {
      projectId,
    });
  };

export function useTaskCalendarMessageHandlers() {
  useThrottledOnArgsMessageHandler(
    PMT.TaskCalendarEventChanged,
    onTaskCalendarEventsChanged,
  );
}
