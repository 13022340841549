import { bodySmall, header4 } from '@monorepo/shared/styles/text';
import styled from 'styled-components';

export const SubTitle = styled.h3`
  ${header4}
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

export const Description = styled.div`
  ${bodySmall}
`;

export const InstructionsInput = styled.div`
  margin-top: 0.5rem;
`;

export const FieldList = styled.ul`
  max-width: 38rem;
  padding: 0;
  margin: 0.25rem 0;
`;

export const Bold = styled.span`
  font-weight: 700;
`;
