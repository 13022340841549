import {
  fetchSelectOptionsByLogFieldType,
  FetchSelectOptionsByLogFieldTypeParams,
  SelectOptionsByFieldType,
} from '@monorepo/shared/apiClient/logs/fieldOptionsForUpload';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourceListCacheEvents,
  resourceListCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceListCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { Api } from '../../apiClient';
import {
  LogCacheEvents,
  logCacheRegister,
} from '../../cacheRegisters/data2/logCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '../../cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '../../cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '../../cacheRegisters/data2/resourceTypeCacheRegister';

type KeyParams = {
  organizationId: string;
  logId: string;
  projectId: string;
};

function getKeyPartsInOrder(
  keyParams: KeyParams,
): [string, string, string, string];
function getKeyPartsInOrder(
  keyParams: Partial<KeyParams>,
): [string, string | undefined, string | undefined, string | undefined];
function getKeyPartsInOrder(
  keyParams: KeyParams | Partial<KeyParams>,
):
  | [string, string, string, string, string]
  | [string, string | undefined, string | undefined, string | undefined] {
  const { organizationId, logId, projectId } = keyParams;
  return ['logFieldOptionsForUpload', organizationId, logId, projectId];
}

export const createKey = (params: KeyParams) =>
  [...getKeyPartsInOrder(params)] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof fetchSelectOptionsByLogFieldType
>;

type UseLogFieldOptionsForUploadParams =
  Partial<FetchSelectOptionsByLogFieldTypeParams> & {
    config?: QueryConfig<SelectOptionsByFieldType, Api.ErrorResponse>;
  };

export const useLogFieldOptionsForUpload = ({
  config: inputConfig = { useErrorBoundary: false },
  logInfo,
  projectId,
}: UseLogFieldOptionsForUploadParams) => {
  const isEnabled = !!logInfo && !!projectId;
  const config = getQueryConfig<SelectOptionsByFieldType>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createKey({
        organizationId: logInfo.log.organizationId,
        logId: logInfo.log.id,
        projectId,
      })
    : undefined;
  const fetcher: Fetcher = async () => {
    if (logInfo && projectId) {
      return fetchSelectOptionsByLogFieldType({ logInfo, projectId });
    }
    return {};
  };
  const { data: logFieldOptionsForUpload, ...queryInfo } = useQuery(
    key,
    fetcher,
    config,
  );
  return {
    logFieldOptionsForUpload,
    ...queryInfo,
  };
};

type InvalidateCacheParams = Pick<KeyParams, 'organizationId'> &
  Partial<Omit<KeyParams, 'organizationId'>>;
async function invalidateCache(
  invalidateCacheParams: InvalidateCacheParams,
): Promise<void> {
  const keyParts = getKeyPartsInOrder(invalidateCacheParams);
  await queryCache.invalidateQueries(getKeyParamsForInvalidation(keyParts));
}

const cacheRegisterInvalidator = {
  hookName: 'useLogFieldOptionsForUpload',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

logCacheRegister(
  [LogCacheEvents.DELETE, LogCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);

logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.UPDATE,
    ResourceTypeCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourcePropertyCacheRegister(
  [
    ResourcePropertyCacheEvents.CREATE,
    ResourcePropertyCacheEvents.UPDATE,
    ResourcePropertyCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.UPDATE,
    ResourceCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

resourceListCacheRegister(
  [ResourceListCacheEvents.CREATE],
  cacheRegisterInvalidator,
);
