import TaskModalOpener from '@monorepo/old-web/js/components/task/TaskModalOpener';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { EditLogEntryModal } from '@monorepo/shared/componentsV2/logs/EditLogEntryModal';
import { LogEntriesUploadModal } from '@monorepo/shared/componentsV2/logs/LogEntriesUploadModal';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import { isToday, startOfDay } from 'date-fns';
import { DateLike, localEquivalentOfUTC } from 'mapistry-shared';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { LogEventsModal } from './LogEventsModal';

export function TasksPageModals() {
  const { project } = useCurrentProject();

  const [query, setQuery] = useSearchParams();
  const logId = query.get('logId');
  const dueDate = query.get('dueDate');
  const taskId = query.get('taskId');

  const [isLogEntryModalOpen, openLogEntryModal, closeLogEntryModal] =
    useOpenClose();
  const [isBulkUploadModalOpen, openBulkUploadModal, closeBulkUploadModal] =
    useOpenClose();

  /**
   * Save off the logId and due date for the upload and entry modals.
   * We need to close the LogEventsModal when we open the other modals
   * otherwise the LogEventsModal continuously steals the focus from
   * the newly opened modals. When we close the LogEventsModal we will
   * lose the logId and dueDate because it navigates to the base tasks page
   */
  const [logIdToAddEntriesTo, setLogIdToAddEntriesTo] = useState<
    string | undefined
  >();
  const [dueDateForLogEvent, setDueDateForLogEvent] = useState<
    DateLike | undefined
  >();

  const getLogDate = () => {
    if (dueDateForLogEvent) {
      const logDate = localEquivalentOfUTC(new Date(dueDateForLogEvent));
      return isToday(logDate) ? new Date() : startOfDay(logDate);
    }
    return undefined;
  };

  useEffect(() => {
    if (logId) {
      setLogIdToAddEntriesTo(logId);
    }
  }, [logId]);

  useEffect(() => {
    if (dueDate) {
      setDueDateForLogEvent(dueDate);
    }
  }, [dueDate]);

  return (
    <>
      {taskId && project && (
        <TaskModalOpener
          taskId={taskId}
          projectId={project.id}
          dueDate={dueDate}
        />
      )}
      {logId && (
        <ErrorBoundary onError={() => setQuery('')}>
          <LogEventsModal
            dueDate={dueDate}
            logId={logId}
            openLogEntryModal={openLogEntryModal}
            openUploadModal={openBulkUploadModal}
          />
        </ErrorBoundary>
      )}
      {isLogEntryModalOpen && logIdToAddEntriesTo && (
        <ErrorBoundary onError={closeLogEntryModal}>
          <EditLogEntryModal
            logDate={getLogDate()}
            logId={logIdToAddEntriesTo}
            onClose={closeLogEntryModal}
            open={isLogEntryModalOpen}
            organizationId={project?.organization_id}
            projectId={project?.id}
          />
        </ErrorBoundary>
      )}
      {logIdToAddEntriesTo && project && (
        <ErrorBoundary onError={closeBulkUploadModal}>
          <LogEntriesUploadModal
            isOpen={isBulkUploadModalOpen}
            logId={logIdToAddEntriesTo}
            onClose={closeBulkUploadModal}
            organizationId={project.organization_id}
            projectId={project.id}
          />
        </ErrorBoundary>
      )}
    </>
  );
}
