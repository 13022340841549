import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';

import {
  DELETE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  MARK_TASK_COMPLETE_FAILURE,
  MARK_TASK_COMPLETE_REQUEST,
  MARK_TASK_COMPLETE_SUCCESS,
} from '.';

const fetchTasksRequest = createAction(FETCH_TASKS_REQUEST);
const fetchTasksSuccess = createAction(FETCH_TASKS_SUCCESS);
const fetchTasksFailure = createAction(FETCH_TASKS_FAILURE);

const markTaskCompleteRequest = createAction(MARK_TASK_COMPLETE_REQUEST);
const markTaskCompleteSuccess = createAction(MARK_TASK_COMPLETE_SUCCESS);
const markTaskCompleteFailure = createAction(MARK_TASK_COMPLETE_FAILURE);

const deleteTaskRequest = createAction(DELETE_TASK_REQUEST);
const deleteTaskSuccess = createAction(DELETE_TASK_SUCCESS);
const deleteTaskFailure = createAction(DELETE_TASK_FAILURE);

export function fetchTasksAction(projectId, filters) {
  return {
    [CALL_API]: {
      data: filters,
      endpoint: `/api/v2/projects/${projectId}/tasks`,
      lifecycleActions: [
        fetchTasksRequest,
        fetchTasksSuccess,
        fetchTasksFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function markTaskCompleteAction(
  projectId,
  taskId,
  markedAsCompletedDate,
  dueDate = null,
) {
  const dueDateParam = dueDate ? `/${dueDate}` : '';
  return {
    [CALL_API]: {
      data: { markedAsCompletedDate },
      endpoint: `/api/v2/projects/${projectId}/tasks/${taskId}${dueDateParam}/complete`,
      lifecycleActions: [
        markTaskCompleteRequest,
        markTaskCompleteSuccess,
        markTaskCompleteFailure,
      ],
      verb: HTTP.Types.PUT,
    },
    taskId,
  };
}

export function deleteTaskAction(
  projectId,
  taskId,
  dueDate = null,
  allFuture = false,
) {
  const recurringParams = dueDate ? `/${dueDate}?allFuture=${allFuture}` : '';
  return {
    [CALL_API]: {
      endpoint: `/api/v2/projects/${projectId}/tasks/${taskId}${recurringParams}`,
      lifecycleActions: [
        deleteTaskRequest,
        deleteTaskSuccess,
        deleteTaskFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}
