import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateLogCache,
  LogCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logCacheRegister';
import { getCache, setCache } from '@monorepo/shared/hooks/logs/useLogs';
import { MutationConfig, useMutation } from 'react-query';

type useLogsCreateParams = {
  config?: MutationConfig<Api.CreateLogResponse, Api.ErrorResponse>;
};

export const useLogCreate = (params?: useLogsCreateParams) =>
  useMutation(Api.createLog, {
    ...params?.config,
    onSuccess: (createdLog, createdLogParams) => {
      const { organizationId } = createdLogParams;
      const currentItems = getCache({ organizationId });
      // If there is cache, update it
      if (currentItems) {
        const newItems = [...currentItems, createdLog];
        setCache({ organizationId }, newItems);
      }

      // Invalidate any RQ cache that might use information about Logs
      invalidateLogCache(LogCacheEvents.CREATE, {
        logId: createdLog.id,
        organizationId,
      });

      return params?.config?.onSuccess?.(createdLog, createdLogParams);
    },
  });
