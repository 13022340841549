import {
  invalidateLogCalendarEventCache,
  LogCalendarEventCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/logCalendarEventCacheRegister';
import {
  PushMessageTopic as PMT,
  ServerToClientMessages,
} from 'mapistry-shared';
import { useThrottledOnArgsMessageHandler } from '../webSocket';

const onLogCalendarEventsChanged: ServerToClientMessages[PMT.LogCalendarEventChanged] =
  ({ projectId }) => {
    invalidateLogCalendarEventCache(LogCalendarEventCacheEvents.UPDATE, {
      projectId,
    });
  };

export function useLogCalendarMessageHandlers() {
  useThrottledOnArgsMessageHandler(
    PMT.LogCalendarEventChanged,
    onLogCalendarEventsChanged,
  );
}
