import { Link } from '@monorepo/shared/componentsV2/Link';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import {
  getCategoryDisplayString,
  LogRefResponse,
  LogWithSiteConfigCount,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { EntryCount } from './EntryCount';
import { LastEntry } from './LastEntry';

const columns: TableColumn<LogRefResponse>[] = [
  {
    id: 'name',
    header: 'Log Name',
    contents: ({ id, name }) => (
      <Link to={`${id}/`} color="grey">
        {name}
      </Link>
    ),
    width: '40%',
  },
  {
    id: 'lastEntry',
    header: 'Last Entry',
    contents: ({ id }) => <LastEntry logId={id} />,
    align: 'center',
  },
  {
    id: 'numOfEntries',
    header: '# of Entries',
    contents: ({ id }) => <EntryCount logId={id} />,
    align: 'right',
  },
  {
    id: 'category',
    header: 'Compliance Area',
    contents: ({ category }) => getCategoryDisplayString(category),
    align: 'center',
  },
];

const StyledTable = styled(Table)`
  padding-bottom: 4rem;
` as typeof Table;

interface LogTableProps {
  filter: string;
  logsWithSiteConfig?: LogWithSiteConfigCount[];
}

export function LogTable({ filter, logsWithSiteConfig }: LogTableProps) {
  const logsToDisplay = useMemo(
    () =>
      (logsWithSiteConfig || [])
        .filter(
          ({ isPublished, frequencyRequirementCount }) =>
            isPublished && frequencyRequirementCount > 0,
        )
        .sort((logA, logB) => logA.name.localeCompare(logB.name)),
    [logsWithSiteConfig],
  );

  const filterLogsBy = useCallback(
    (log) => lookFor(filter, log, ['name']),
    [filter],
  );

  if (!logsWithSiteConfig?.length) return null;

  return (
    <StyledTable
      ariaLabel="All logs"
      columns={columns}
      rowFilter={filterLogsBy}
      rows={logsToDisplay}
      emptyTablePrimaryText="No Logs with frequency requirements at this site."
      emptyTableSecondaryText="Org admins can add a frequency requirement to a Log in the Data Setup tab at the far left. Once there, select a Log and navigate to the Sites tab to configure site-level frequency requirements."
    />
  );
}
