import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateResourceCache,
  ResourceCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import { MutationConfig, useMutation } from 'react-query';

type UseResourceDeleteParams = {
  config?: MutationConfig<void, Api.ErrorResponse>;
};

export const useResourceDelete = (params?: UseResourceDeleteParams) =>
  useMutation(Api.deleteResource, {
    ...params?.config,
    onSuccess: (data, { organizationId, resourceTypeId, resourceId }) => {
      const resourceListKeys = {
        organizationId,
        resourceTypeId,
      };

      invalidateResourceCache(ResourceCacheEvents.DELETE, {
        ...resourceListKeys,
        resourceId,
      });
    },
  });
