import { Input } from '@material-ui/core';
import { Button } from '@monorepo/shared/componentsV2/Button';
import React, { useCallback, useState } from 'react';
import { BaseModal } from './BaseModal';

type PromptModalProps = Omit<
  React.ComponentProps<typeof BaseModal>,
  'buttons' | 'children' | 'onClose'
> & {
  cancelButtonText?: string;
  confirmButtonText?: string;
  danger?: boolean;
  initialInputValue?: string;
  multiline?: boolean;
  onConfirm: (inputValue: string) => void;
  onCancel: () => void;
};

export function PromptModal({
  cancelButtonText = 'OK',
  confirmButtonText = 'Cancel',
  danger,
  description,
  initialInputValue,
  multiline,
  onCancel,
  onConfirm,
  open,
  showCloseButton,
  title,
}: PromptModalProps) {
  const [inputValue, setInputValue] = useState(initialInputValue ?? '');

  const handleConfirm = useCallback(() => {
    onConfirm(inputValue);
  }, [onConfirm, inputValue]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
    },
    [],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleConfirm();
      }
    },
    [handleConfirm],
  );

  return (
    <BaseModal
      open={open}
      title={title}
      description={description}
      buttons={
        <>
          <Button onClick={onCancel}>{cancelButtonText}</Button>
          <Button
            onClick={handleConfirm}
            color={danger ? 'secondary' : 'primary'}
          >
            {confirmButtonText}
          </Button>
        </>
      }
      onClose={onCancel}
      showCloseButton={showCloseButton}
    >
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={multiline ? undefined : handleKeyPress}
        multiline={multiline}
        autoFocus
        fullWidth
      />
    </BaseModal>
  );
}
