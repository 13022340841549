import { Api } from '@monorepo/shared/apiClient';
import {
  LogEntryCacheEvents,
  logEntryCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logEntryCacheRegister';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourceCacheEvents,
  resourceCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ResourceTypeCacheEvents,
  resourceTypeCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourceTypeCacheRegister';
import {
  ViewCacheEvents,
  viewCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, usePaginatedQuery } from 'react-query';
import { OptionalKeys } from 'utility-types';

type KeyParams = Api.FetchViewQueryResultsParams;
const KEY_START = 'viewQueries';

type OptionalKeyParams = Pick<KeyParams, OptionalKeys<KeyParams>>;

export const createQueryKey = ({
  organizationId,
  perPage = 50,
  projectId,
  requestedPage = 0,
  viewId,
}: KeyParams) =>
  [
    KEY_START,
    organizationId,
    projectId,
    viewId,
    { perPage, requestedPage },
  ] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchViewQueryResults
>;

type UseViewQueryResultsParams = Partial<Api.FetchViewQueryResultsParams> & {
  config?: QueryConfig<Api.FetchViewQueryResultsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  projectId: string,
  viewId: string,
  { perPage, requestedPage }: OptionalKeyParams,
) =>
  Api.fetchViewQueryResults({
    organizationId,
    perPage,
    projectId,
    requestedPage,
    viewId,
  });

export const useViewQueryResults = ({
  config: inputConfig,
  organizationId,
  perPage,
  projectId,
  requestedPage,
  viewId,
}: UseViewQueryResultsParams) => {
  const isEnabled = !!organizationId && !!projectId && !!viewId;
  const config = getQueryConfig<Api.FetchViewQueryResultsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({
        organizationId,
        perPage,
        projectId,
        requestedPage,
        viewId,
      })
    : undefined;
  const { resolvedData: queryResult, ...queryInfo } = usePaginatedQuery(
    key,
    fetcher,
    config,
  );
  return {
    queryResult,
    ...queryInfo,
  };
};

type QueryCache =
  | ReturnType<typeof useViewQueryResults>['queryResult']
  | undefined;
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<
  KeyParams,
  keyof OptionalKeyParams | 'projectId' | 'viewId'
> &
  Partial<Pick<KeyParams, 'projectId' | 'viewId'>>;

// allow invalidating all view lists (across projects)
async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.projectId,
      keyParams.viewId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useViewQueryResults',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

viewCacheRegister(
  [ViewCacheEvents.UPDATE, ViewCacheEvents.DELETE],
  cacheRegisterInvalidator,
);

logEntryCacheRegister(
  [
    LogEntryCacheEvents.CREATE,
    LogEntryCacheEvents.UPDATE,
    LogEntryCacheEvents.DELETE,
  ],
  cacheRegisterInvalidator,
);

logFieldCacheRegister(
  [
    LogFieldCacheEvents.CREATE,
    LogFieldCacheEvents.DELETE,
    LogFieldCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceCacheRegister(
  [
    ResourceCacheEvents.CREATE,
    ResourceCacheEvents.DELETE,
    ResourceCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourcePropertyCacheRegister(
  [
    ResourcePropertyCacheEvents.CREATE,
    ResourcePropertyCacheEvents.DELETE,
    ResourcePropertyCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);

resourceTypeCacheRegister(
  [
    ResourceTypeCacheEvents.CREATE,
    ResourceTypeCacheEvents.DELETE,
    ResourceTypeCacheEvents.UPDATE,
  ],
  cacheRegisterInvalidator,
);
