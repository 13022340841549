import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { Search } from '@monorepo/shared/componentsV2/Search';
import { useHasOrgUpdatePermissionOrSuperAdmin } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  max-width: 10rem;
`;

const ButtonContainer = styled.div`
  min-width: 7rem;
`;

interface ViewLogsHeaderProps {
  onAddNew: () => void;
  onSearch?: (search: string) => void;
  organizationId: string;
}

export function ViewLogsHeader({
  onAddNew,
  onSearch,
  organizationId,
}: ViewLogsHeaderProps) {
  const canEditOrgSettings =
    useHasOrgUpdatePermissionOrSuperAdmin(organizationId);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSearch?.(e.target.value),
    [onSearch],
  );

  return (
    <Header
      rightActions={
        <ButtonsGroup>
          {!!onSearch && (
            <SearchContainer>
              <Search onChange={handleSearch} />
            </SearchContainer>
          )}
          {canEditOrgSettings && (
            <ButtonContainer>
              <Button color="primary" onClick={onAddNew} variant="contained">
                Create new Log
              </Button>
            </ButtonContainer>
          )}
        </ButtonsGroup>
      }
      title="All Logs"
      variant="noDropShadow"
    />
  );
}
