import { useResourceRefs } from './useResourceRefs';

// If org id and resource type id are not provided, fetch hook will be disabled
interface UseResourceCountProps {
  organizationId?: string;
  resourceTypeId?: string;
  projectId?: string;
}

export function useResourceCount({
  organizationId,
  resourceTypeId,
  projectId,
}: UseResourceCountProps) {
  const { resourceRefs, isLoading } = useResourceRefs({
    organizationId,
    resourceTypeId,
    projectId,
  });

  const resourceCount = resourceRefs ? resourceRefs.length : undefined;
  const hasResources = !!resourceCount && resourceCount > 0;

  return {
    hasResources,
    isLoading,
    resourceCount,
  };
}
