import React from 'react';
import styled from 'styled-components';
import { header3 } from '../../styles/text';

const Container = styled.div`
  ${header3};
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkRed};
`;

type GenericErrorProps = { message?: string };

export function GenericError({
  message = "We're sorry, but something went wrong.",
}: GenericErrorProps) {
  return <Container>{message}</Container>;
}
