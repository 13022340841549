import { endOfDay, isValid, startOfDay } from 'date-fns';
import { NullableDate } from 'mapistry-shared';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from './dateAndTimePickers';

const Container = styled.div<{ $displayOnSingleLine?: boolean }>`
  display: ${({ $displayOnSingleLine }) =>
    $displayOnSingleLine ? 'flex' : 'block'};
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 2rem;
`;

export function DateRangePicker({
  from,
  to,
  setFrom,
  setTo,
  displayOnSingleLine,
}: {
  from: NullableDate;
  to: NullableDate;
  setFrom: (value: NullableDate) => void;
  setTo: (value: NullableDate) => void;
  displayOnSingleLine?: boolean;
}) {
  return (
    <Container $displayOnSingleLine={displayOnSingleLine}>
      <StyledDatePicker
        label="From"
        name="from"
        onChange={({ target: { value: date } }) =>
          setFrom(date == null || !isValid(date) ? null : startOfDay(date))
        }
        value={from}
      />
      <StyledDatePicker
        label="To"
        name="to"
        onChange={({ target: { value: date } }) =>
          setTo(date == null || !isValid(date) ? null : endOfDay(date))
        }
        value={to}
      />
    </Container>
  );
}
