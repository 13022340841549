import { Accordion } from '@monorepo/shared/componentsV2/Accordion';
import { Checkbox } from '@monorepo/shared/componentsV2/Checkbox';
import { Select, SelectOption } from '@monorepo/shared/componentsV2/Select';
import {
  PhotosAndAttachmentsToIncludeInPdf,
  TasksToIncludeInPdf,
} from 'mapistry-shared';
import React from 'react';

type PdfOptionsSectionProps = {
  includePhotosAndAttachmentsOption: PhotosAndAttachmentsToIncludeInPdf;
  includeRegularFormContent: boolean;
  includeTasksAttachments?: boolean;
  includeTasksOption: TasksToIncludeInPdf;
  onIncludeRegularFormContentChange: (value: boolean) => void;
  onIncludeTaskAttachmentsChange: (value: boolean) => void;
  onPhotoOptionChange: (value: PhotosAndAttachmentsToIncludeInPdf) => void;
  onWithTaskOptionChange: (value: TasksToIncludeInPdf) => void;
};

export function PdfOptionsSection({
  includePhotosAndAttachmentsOption,
  includeRegularFormContent,
  includeTasksAttachments,
  includeTasksOption,
  onIncludeRegularFormContentChange,
  onIncludeTaskAttachmentsChange,
  onPhotoOptionChange,
  onWithTaskOptionChange,
}: PdfOptionsSectionProps) {
  const taskInclusionOptions: SelectOption[] = [
    { label: 'Include all tasks', value: TasksToIncludeInPdf.ALL },
    {
      label: 'Exclude all tasks',
      value: TasksToIncludeInPdf.NONE,
    },
    {
      label: 'Include only Incomplete Tasks',
      value: TasksToIncludeInPdf.INCOMPLETE_ONLY,
    },
    {
      label: 'Include only Completed Tasks',
      value: TasksToIncludeInPdf.COMPLETE_ONLY,
    },
  ];

  const photoInclusionOptions: SelectOption[] = [
    {
      label: 'Include photo log and attached documents',
      value: PhotosAndAttachmentsToIncludeInPdf.ALL,
    },
    {
      label: 'Exclude photo log and attached documents',
      value: PhotosAndAttachmentsToIncludeInPdf.NONE,
    },
  ];

  return (
    <Accordion
      summaryContent={<span>Task and Attachment Options</span>}
      detailsContent={
        <div className="download-inspections__pdf-options">
          <div>
            <h4>Task options</h4>
            <Select
              fullWidth
              name="includeTasks"
              dontSaveSpaceForErrorText
              label="Which tasks should be included in the downloads?"
              options={taskInclusionOptions}
              value={includeTasksOption}
              onChange={(evt) =>
                onWithTaskOptionChange(evt.target.value as TasksToIncludeInPdf)
              }
            />
            {includeTasksOption !== TasksToIncludeInPdf.NONE && (
              <Checkbox
                name="includePhotosAndAttachments"
                label="Include task photos and attachments"
                checked={includeTasksAttachments}
                onChange={(evt) =>
                  onIncludeTaskAttachmentsChange(evt.target.checked)
                }
              />
            )}
          </div>

          <div>
            <h4>Photo and attachment options</h4>
            <Select
              name="photoOptions"
              label="Should the photo log and attachments be included?"
              options={photoInclusionOptions}
              value={includePhotosAndAttachmentsOption}
              onChange={(evt) =>
                onPhotoOptionChange(
                  evt.target.value as PhotosAndAttachmentsToIncludeInPdf,
                )
              }
            />
          </div>

          <div>
            <h4>Options for everything else</h4>
            <Checkbox
              name="includeRegularFormContent"
              label="Include everything else (i.e. not tasks or attachments)"
              checked={includeRegularFormContent}
              onChange={(evt) =>
                onIncludeRegularFormContentChange(evt.target.checked)
              }
            />
          </div>
        </div>
      }
    />
  );
}
