import React from 'react';
import { Field } from 'react-final-form';
import { FieldWrapper, Select } from '../elements';

const CHART_TYPE_OPTIONS = [
  { label: 'Bar', value: 'bar' },
  { label: 'Line', value: 'line' },
];

type AirEmissionChartTypeFieldProps = {
  name: string;
  label: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GenericLogsChartTypeField(props: AirEmissionChartTypeFieldProps) {
  const { name, label } = props;
  return (
    <Field name={name}>
      {(field) => (
        <div className="field-wrapper">
          <FieldWrapper label={label}>
            <Select
              error={Boolean(field.meta.touched && field.meta.error)}
              isFixed
              isClearable={false}
              options={CHART_TYPE_OPTIONS}
              onChange={(opt) => field.input.onChange(opt.value)}
              value={CHART_TYPE_OPTIONS.find(
                (o) => o.value === field.input.value,
              )}
            />
          </FieldWrapper>
        </div>
      )}
    </Field>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogsChartTypeField;
