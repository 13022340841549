import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useSubnav } from '@monorepo/shared/hooks/subnav/useSubnav';
import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import LeftArrowIcon from '@svg/m-arrow-left.svg';
import RightArrowIcon from '@svg/m-arrow-right.svg';
import React from 'react';
import {
  ContentNextToCollapsibleSubnav,
  CollapseSubnavButton as StyledCollapseSubnavButton,
} from './collapsibleSubnav.styled';

interface PageWithCollapsibleSubnavProps {
  children: React.ReactNode;
  subnavComponent: React.ReactNode;
}

const CollapseSubnavButton = ({
  isSmoothTransitionPrevented,
}: {
  isSmoothTransitionPrevented: boolean;
}) => {
  const { isSubnavDisabled, isSubnavOpen, toggleSubnav } = useSubnav();

  const SubnavToggleIcon = isSubnavOpen ? LeftArrowIcon : RightArrowIcon;

  if (isSubnavDisabled) {
    return null;
  }

  return (
    <StyledCollapseSubnavButton
      onClick={toggleSubnav}
      aria-expanded={isSubnavOpen}
      size="small"
      $isSubnavOpen={isSubnavOpen}
      $isSubnavDisabled={isSubnavDisabled}
      $isSmoothTransitionPrevented={isSmoothTransitionPrevented}
    >
      <SubnavToggleIcon aria-hidden fontSize="15px" />
      <VisuallyHidden>
        {isSubnavOpen ? 'Collapse sidebar' : 'Expand sidebar'}
      </VisuallyHidden>
    </StyledCollapseSubnavButton>
  );
};

/**
 * @param props
 * @param props.children 'the page content'
 * @param props.subnavComponent 'should be a Subnav component'
 */
export function PageWithCollapsibleSubnav({
  subnavComponent,
  children,
}: PageWithCollapsibleSubnavProps) {
  const { contentResizingWithSubnavRef, isSubnavDisabled, isSubnavOpen } =
    useSubnav();

  // when going from a page where the subnav is disabled to a page where it's shown
  // do not slowly slide over the content, but immediately show it in place (to match the subnav itself)
  const wasSubnavDisabled = usePrevious(isSubnavDisabled);
  const isSmoothTransitionPrevented = !!(
    wasSubnavDisabled && !isSubnavDisabled
  );

  return (
    <>
      <div>
        {isSubnavDisabled ? null : subnavComponent}
        <CollapseSubnavButton
          isSmoothTransitionPrevented={isSmoothTransitionPrevented}
        />
      </div>
      <ContentNextToCollapsibleSubnav
        $isSubnavOpen={isSubnavOpen}
        $isSubnavDisabled={isSubnavDisabled}
        $isSmoothTransitionPrevented={isSmoothTransitionPrevented}
        ref={contentResizingWithSubnavRef}
      >
        {children}
      </ContentNextToCollapsibleSubnav>
    </>
  );
}
