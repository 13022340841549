import { FieldTypePreviewFactory } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreviewFactory';
import { FieldDataType } from 'mapistry-shared';
import React from 'react';

export function NamePropertyPreview() {
  return (
    <FieldTypePreviewFactory
      field={{
        name: 'Name',
        type: FieldDataType.TEXT,
        isRequired: true,
      }}
      fieldTypeName="property"
      isLocked
    />
  );
}
