import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TextBlock } from './TextBlock';

const TableHead = styled(MuiTableHead)`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const TableCell = styled(MuiTableCell)`
  height: 2rem;
  padding: 0.125rem 1rem;
  white-space: pre-wrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayeee};

  &:first-of-type {
    padding-left: 1.5rem;
  }

  &.MuiTableCell-paddingNone {
    padding: 0;

    /* we still apply left padding for the first column, but it's smaller */
    &:first-of-type {
      padding-left: 1rem;
    }
  }
`;

const TableHeaderCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.gray666};
`;

export enum RowStatus {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export const TableRow = styled(MuiTableRow)<{
  $hideRow: boolean;
  $rowStatus?: RowStatus;
}>`
  ${({ $hideRow }) => ($hideRow ? 'visibility: collapse;' : '')}
  ${({ theme, $rowStatus }) => {
    switch ($rowStatus) {
      case RowStatus.ERROR:
        return `background-color: ${theme.colors.red08};`;
      case RowStatus.WARNING:
        return `background-color: ${theme.colors.yellow08};`;
      case RowStatus.SUCCESS:
        return `background-color: ${theme.colors.green08};`;
      default:
        return '';
    }
  }}

  & img {
    max-height: 1.875rem;
    vertical-align: middle;
  }
`;

type ReactElementOrString = React.ReactElement | string;

type TableRow = { id: string; rowStatus?: RowStatus } & Record<
  string | number | symbol,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

export interface ColumnLayout {
  align?: TableCellProps['align'];
  width?: TableCellProps['width'];
  padding?: TableCellProps['padding'];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableColumn<RowEntity extends { [key: string]: any }> =
  ColumnLayout & {
    id: string | number;
    header: ReactElementOrString;
    contents: keyof RowEntity | ((row: RowEntity) => ReactElementOrString);
  };

function getCellContents<RowEntity extends TableRow>(
  column: TableColumn<RowEntity>,
  row: RowEntity,
): React.ReactNode {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {typeof column.contents === 'function'
        ? column.contents(row)
        : row[column.contents]}
    </>
  );
}

interface TableProps<RowEntity extends TableRow> {
  ariaLabel: string;
  className?: string;
  columns: TableColumn<RowEntity>[];
  emptyTablePrimaryText?: string;
  emptyTableSecondaryText?: string;
  makeTableFocusable?: boolean;
  resultsNotFoundPrimaryText?: string;
  resultsNotFoundSecondaryText?: string;
  rowFilter?: (row: RowEntity) => boolean;
  rows: RowEntity[];
}

export function Table<RowEntity extends TableRow>({
  ariaLabel,
  className,
  columns,
  emptyTablePrimaryText = 'No items to show.',
  emptyTableSecondaryText = '',
  makeTableFocusable = false,
  resultsNotFoundPrimaryText = 'No results found.',
  resultsNotFoundSecondaryText = 'We couldn’t find what you are looking for. Try searching again using different keywords.',
  rowFilter,
  rows,
}: TableProps<RowEntity>) {
  const rowsWithShouldHide = useMemo(
    () =>
      rows.map((row) => ({
        row,
        shouldHide: !!rowFilter && !rowFilter(row),
      })),
    [rowFilter, rows],
  );

  const isHidingAllRows = useMemo(
    () =>
      rowsWithShouldHide.filter((row) => row.shouldHide).length === rows.length,
    [rows.length, rowsWithShouldHide],
  );

  const hasNoRows = !rows.length;

  return (
    <MuiTableContainer
      className={className}
      tabIndex={makeTableFocusable ? 0 : -1}
    >
      <MuiTable aria-label={ariaLabel} stickyHeader>
        <TableHead>
          <MuiTableRow>
            {columns.map((col) => (
              <TableHeaderCell
                align={col.align}
                key={col.id}
                width={col.width}
                padding={col.padding}
              >
                {col.header}
              </TableHeaderCell>
            ))}
          </MuiTableRow>
        </TableHead>
        <MuiTableBody>
          {rowsWithShouldHide.map(({ row, shouldHide }) => (
            // filtered rows still get rendered to the HTML so the table shape (e.g. column widths) won't change based on filtering
            <TableRow
              key={row.id}
              $hideRow={shouldHide}
              $rowStatus={row.rowStatus}
              aria-hidden={shouldHide}
            >
              {columns.map((col) => (
                <TableCell
                  align={col.align}
                  key={col.id}
                  width={col.width}
                  padding={col.padding}
                >
                  {getCellContents<RowEntity>(col, row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </MuiTableBody>
      </MuiTable>
      {(hasNoRows || isHidingAllRows) && (
        <TextBlock
          primaryText={
            hasNoRows ? emptyTablePrimaryText : resultsNotFoundPrimaryText
          }
          secondaryText={
            hasNoRows ? emptyTableSecondaryText : resultsNotFoundSecondaryText
          }
        />
      )}
    </MuiTableContainer>
  );
}
