import APP from '@monorepo/old-web/js/config';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Header } from '@monorepo/shared/componentsV2/Header';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React from 'react';
import styled from 'styled-components';
import { ComplianceCalendarSection } from './ComplianceCalendar/ComplianceCalendarSection';
import { TasksPageModals } from './TasksPageModals';

const StyledMain = styled(Main)`
  ${verticalLayout}
  ${page}
`;

const ScrollableContainer = styled.div`
  ${fillHeightAndScrollable}
`;

export function TasksPage() {
  const { project } = useCurrentProject();

  APP.projectId = project?.id; // Legacy code grabs projectId from the App object

  return (
    <StyledMain>
      <TasksPageModals />
      <Header title="Tasks" subtitle={project?.name} variant="noDropShadow" />
      <ErrorBoundary>
        <ScrollableContainer>
          {project ? <ComplianceCalendarSection /> : <Loading />}
        </ScrollableContainer>
      </ErrorBoundary>
    </StyledMain>
  );
}
